import React from 'react';

import colors from 'tailwindcss/colors';
import { t } from '@e-vo/localization';
import { View, Text } from 'react-native';

export interface ProgressBarProps {
  currentStep: number;
  stepsTotal: number;
}

export const ProgressBar = (props: ProgressBarProps) => {
  return (
    <View
      style={{
        alignItems: 'center',
        backgroundColor: colors.gray['400'],
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <View
        style={{
          backgroundColor: colors.blue['700'],
          height: '100%',
          left: 0,
          position: 'absolute',
          width: `${((props.currentStep + 1) / props.stepsTotal) * 100}%`,
          zIndex: -1,
        }}
      />
      <Text style={{ color: 'white', marginVertical: 6 }}>
        {t('wizard.stepFrom', { currentStep: props.currentStep + 1, totalSteps: props.stepsTotal })}
      </Text>
    </View>
  );
};
