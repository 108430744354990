import { Signature } from './signature-schema';

import { z } from 'zod';
import { IdentificationType, PlugEnum, PowerType } from './enums/tmp.enums';
import { evcoIdSchemaProp } from './general-validators';
import { ChargeDetailRecord } from './charge-details-schema';

const identificationTypeSchema = z.nativeEnum(IdentificationType);
const powerTypeSchema = z.nativeEnum(PowerType);
const plugTypeSchema = z.nativeEnum(PlugEnum);

const addressSchema = z.object({
  city: z.string().optional(),
  country_code: z.string(),
  house_number: z.string().optional(),
  street: z.string().optional(),
  zipcode: z.string().optional(),
});
// export type ChargeDetailRecord = z.infer<typeof chargeDetailRecordSchema>;

const chargeDetailRecordSchema = z.object({
  city: z.string(),
  commissionAmount: z.number(),
  commissionCurrency: z.enum(['EUR', 'CHF']),
  consumedEnergy: z.number(),
  contractIds: z.array(z.string()),
  countryCode: z.string(),
  countryName: z.string(),
  emobilityServiceProviderIdentifier: z.string(),
  emobilityServiceProviderName: z.string(),
  evcoId: evcoIdSchemaProp,
  houseNumber: z.string().nullish(),
  operatorIdentifier: z.string(),
  operatorName: z.string(),
  postalCode: z.string(),
  powerSupply: z.enum(['AC_1_PHASE', 'AC_3_PHASE', 'DC']),
  product: z.string(),
  sessionEnd: z.string().datetime(),
  sessionId: z.string().uuid(),
  sessionStart: z.string().datetime(),
  stationName: z.string(),
  street: z.string(),
  transactionId: z.string().nullish(),
});
// export type ChargeDetailRecord = z.infer<typeof chargeDetailRecordSchema>;

export interface ChargeDetailRecordsStatisticWithTotal extends ChargeDetailRecordsStatistic {
  total: number;
}

export const Provider = z.object({
  name: z.string(),
  token: z.string(),
});

export const Operator = z.object({
  name: z.string(),
  token: z.string(),
});

export type Operator = z.infer<typeof Operator>;

export type Provider = z.infer<typeof Provider>;

const Customer = z.object({
  external_reference: z.string().optional(),
  id: z.string(),
  name: z.string(),
});
export type Customer = z.infer<typeof Customer>;

const Identification = z.object({
  type: identificationTypeSchema,
  value: z.string(),
});
export type Identification = z.infer<typeof Identification>;

const CostSchema = z.object({
  approved_at: z.string(),
  billing_sealed_at: z.string(),
  billing_transaction_id: z.string(),
  energy: z.number(),
  manipulation: z.number(),
  national_calibration_law_compliant_pricing: z.boolean(),
  start: z.number(),
  time: z.number(),
  total: z.number(),
});

const Driver = z.object({
  evco_id: z.string(),
  external_contract_reference: z.string().optional(),
  id: z.string(),
  name: z.string(),
});

const Location = z.object({
  address: addressSchema,
  evse_id: z.string(),
  is_national_calibration_law_compliant: z.boolean(),
  operator: Operator,
  plugs: z.array(plugTypeSchema),
  station_name: z.string(),
  valid_since: z.string(),
});

const Product = z.object({
  external_reference: z.string(),
  id: z.string(),
  internal_name: z.string(),
  sales_name: z.string(),
});

const Vehicle = z.object({
  licensePlateNumber: z.string().optional(),
  typeId: z.string(),
});
export type Vehicle = z.infer<typeof Vehicle>;

const Charge = z.object({
  approvedAt: z.string(),
  consumedEnergy: z.number(),
  cost: CostSchema.optional(),
  customer: Customer,
  customerId: z.string(),
  driver: Driver.optional(),
  identification: Identification,
  location: Location,
  meterValueEnd: z.number(),
  meterValueStart: z.number(),
  power: z.number(),
  powerType: powerTypeSchema,
  product: Product,
  provider: Provider,
  sessionDurationMinutes: z.number(),
  sessionEnd: z.string(),
  sessionStart: z.string(),
  vehicle: Vehicle,
});
export type Charge = z.infer<typeof Charge>;

//old type: replace when statistics is implemented in nestjs
export interface ChargingContractChargeDetailRecord extends ChargeDetailRecord {
  transactionId: 'string';
  customerId: 'string';
  contractId: 'string';
  chargingContractId: 'string';
  contractAlias: 'string';
  productName: 'string';
  authenticationCardUid: 'string';
  chargingPointLocation: 'string';
  chargingPointId: 'string';
  chargingPointName: 'string';
  chargingStationOperator: 'string';
  startTime: Date;
  endTime: Date;
  durationInSeconds: number;
  totalNetCost: number;
  totalGrossCost: number;
  energyNetCost: number;
  energyGrossCost: number;
  timeNetCost: number;
  timeGrossCost: number;
  startNetCost: number;
  startGrossCost: number;
  consumedEnergy: number;
  powerType: 'string';
  authenticationType: 'string';
  authenticationId: 'string';
  vehicleType: 'string';
  vehicleLicensePlate: 'string';
  vehicleAlias: 'string';
  invoiceId: 'string';
  invoiceRecipientName: 'string';
  isShared: boolean;
  evcoId: string;
  signature?: Signature;
}

export interface ChargeDetailRecordsStatistic {
  co2Saving: number;
  consumedEnergy: number;
  totalDurationInSeconds: number;
}

export interface ChargeDetailRecordsStatisticWithTotal extends ChargeDetailRecordsStatistic {
  total: number;
}

export type Driver = z.infer<typeof Driver>;
export type Location = z.infer<typeof Location>;
export type Product = z.infer<typeof Product>;
export interface ChargingContractChargeDetailRecord extends ChargeDetailRecord {
  transactionId: 'string';
  customerId: 'string';
  contractId: 'string';
  chargingContractId: 'string';
  contractAlias: 'string';
  productName: 'string';
  authenticationCardUid: 'string';
  chargingPointLocation: 'string';
  chargingPointId: 'string';
  chargingPointName: 'string';
  chargingStationOperator: 'string';
  startTime: Date;
  endTime: Date;
  durationInSeconds: number;
  totalNetCost: number;
  totalGrossCost: number;
  energyNetCost: number;
  energyGrossCost: number;
  timeNetCost: number;
  timeGrossCost: number;
  startNetCost: number;
  startGrossCost: number;
  consumedEnergy: number;
  powerType: 'string';
  authenticationType: 'string';
  authenticationId: 'string';
  vehicleType: 'string';
  vehicleLicensePlate: 'string';
  vehicleAlias: 'string';
  invoiceId: 'string';
  invoiceRecipientName: 'string';
  isShared: boolean;
  evcoId: string;
  signature?: Signature;
}

export interface ChargeDetailRecordsStatistic {
  co2Saving: number;
  consumedEnergy: number;
  totalDurationInSeconds: number;
}

export interface ChargeDetailRecordsStatisticWithTotal extends ChargeDetailRecordsStatistic {
  total: number;
}

export interface InfrastructureChargingTariffContract {
  authenticationId: string;
  authenticationType: string;
  chargingContractId: string;
  contractAlias: string;
  contractId: string;
  productName: string;
  vehicleAlias: string;
  vehicleLicensePlate: string;
  vehicleType: string;
}

export interface InfrastructureChargeDetailRecord {
  id: string;
  transactionId: string;
  customerId: string;
  contractId: string;
  contractAlias: string;
  productName: string;
  chargingPointLocation: string;
  chargingPointId: string;
  chargingPointName: string;
  chargingStationOperator: string;
  chargingTariffContract?: InfrastructureChargingTariffContract;
  eMobilityServiceProvider: string;
  startTime: Date;
  endTime: Date;
  durationInSeconds: number;
  totalAmount: number;
  consumedEnergy: number;
  powerType: string;
  invoiceId: string;
  invoiceRecipientName: string;
  isShared: true;
  sharingUsers: string[];
}

export type ChargePrice = {
  evseId: string;
  fees: PriceFee[];
  validityToken: string;
};

export type ChargePriceWithDisplay = ChargePrice & {
  displays: PriceDisplay[];
};

export type PriceFee = {
  type: 'ENERGY' | 'MANIPULATION' | 'START' | 'TIME';
  grossValue: number;
  netValue: number;
  after?: number;
  stepLength?: number;
};

export interface PriceDisplay {
  value: number;
  label: string;
}
