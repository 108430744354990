export const vkwGoogleMapLightStyles: google.maps.MapTypeStyle[] = [
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'poi',
    stylers: [
      {
        color: '#eeeeee',
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.attraction',
    stylers: [
      {
        color: '#bababa',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.attraction',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.business',
    stylers: [
      {
        color: '#bababa',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.government',
    stylers: [
      {
        color: '#bababa',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.government',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.medical',
    stylers: [
      {
        color: '#bababa',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.medical',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'poi.park',
    stylers: [
      {
        color: '#afdba4',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.park',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.place_of_worship',
    stylers: [
      {
        color: '#bababa',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.place_of_worship',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.school',
    stylers: [
      {
        color: '#bababa',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.school',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'road',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'road.arterial',
    stylers: [
      {
        color: '#e0e0e0',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'road.arterial',
    stylers: [
      {
        color: '#b8b8b8',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'road.arterial',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'road.arterial',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'road.arterial',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'road.highway',
    stylers: [
      {
        color: '#ecdb6f',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'road.highway',
    stylers: [
      {
        color: '#ecdb6f',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'road.highway',
    stylers: [
      {
        color: '#f7b708',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'road.highway',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'road.highway',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'road.highway',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'road.local',
    stylers: [
      {
        color: '#e0e0e0',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'road.local',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'transit.line',
    stylers: [
      {
        color: '#a7a5a5',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'transit.station',
    stylers: [
      {
        color: '#eeeeee',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'water',
    stylers: [
      {
        color: '#82a8f2',
      },
      {
        visibility: 'on',
      },
    ],
  },
];

export const vkwGoogleMapDarkStyles: google.maps.MapTypeStyle[] = [
  { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi',
    stylers: [
      { color: '#59679d' },
      {
        lightness: -60,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.attraction',
    stylers: [
      {
        color: '#59679d',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.attraction',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.business',
    stylers: [
      {
        color: '#59679d',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.business',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.government',
    stylers: [
      {
        color: '#59679d',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.government',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.medical',
    stylers: [
      {
        color: '#59679d',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.medical',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'poi.park',
    stylers: [
      {
        color: '#263c3f',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.park',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.place_of_worship',
    stylers: [
      {
        color: '#59679d',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.place_of_worship',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi.school',
    stylers: [
      {
        color: '#59679d',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text',
    featureType: 'poi.school',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  {
    elementType: 'geometry',
    featureType: 'road',
    stylers: [{ color: '#38414e' }],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'road',
    stylers: [{ color: '#212a37' }],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'road',
    stylers: [{ color: '#9ca5b3' }],
  },
  {
    elementType: 'geometry',
    featureType: 'road.highway',
    stylers: [{ color: '#746855' }],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'road.highway',
    stylers: [{ color: '#1f2835' }],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'road.highway',
    stylers: [{ color: '#f3d19c' }],
  },
  {
    elementType: 'geometry',
    featureType: 'transit',
    stylers: [{ color: '#2f3948' }],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'transit.station',
    stylers: [{ color: '#d59563' }],
  },
  {
    elementType: 'geometry',
    featureType: 'water',
    stylers: [{ color: '#17263c' }],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'water',
    stylers: [{ color: '#515c6d' }],
  },
  {
    elementType: 'labels.text.stroke',
    featureType: 'water',
    stylers: [{ color: '#17263c' }],
  },
];
