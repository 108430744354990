import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconArrowUp = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;
  const strokewidth = 1;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeWidth={strokewidth}>
        <path d="M12.414 6c-.256 0-.512.098-.707.293L1 17l1.414 1.414 10-10 10 10L23.828 17 13.121 6.293C12.926 6.098 12.67 6 12.414 6z" />
      </g>
    </VkwIcon>
  );
};
