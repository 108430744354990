import React, { Children, cloneElement, ReactElement, ReactNode, useMemo, useRef, useState } from 'react';

import { Backdrop, ButtonBase, Link, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { NavigationDesktopItems } from './NavigationDesktopItems';
import { NavigationMobileItems } from './NavigationMobileItems';
import { VkwNavigationItemConfig } from './VkwNavigationItemConfig';
import { useVkwAssets, VkwNavigationContextProvider } from '../../contexts';
import { getVkwTypographyStyle } from '../../helper';
import { useIFrame } from '../../hooks';
import { VkwIconMenu } from '../../icons';
import { VkwTheme } from '../../themes';

const year = new Date().getFullYear();

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    backdrop: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },

      zIndex: theme.zIndex.appBar - 1,
    },
    copyright: {
      ...getVkwTypographyStyle('text14', theme),
      color: theme.palette.text.secondary,
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(3),
      },

      whiteSpace: 'nowrap',
    },
    expandButton: {
      fontSize: theme.typography.pxToRem(18),
    },
    footerItem: {
      marginLeft: theme.spacing(3),
      whiteSpace: 'nowrap',
    },
    header: {
      display: 'none',

      [theme.breakpoints.up('sm')]: {
        alignItems: 'center',
        color: theme.palette.grey[500],
        display: 'flex',
        fontSize: theme.typography.pxToRem(18),
        height: '80px',
        paddingLeft: theme.spacing(4.25),
        paddingRight: theme.spacing(4.25),
      },
    },
    headerImage: {
      marginLeft: theme.spacing(2),
    },
    items: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'column',
        flexGrow: 2,
      },

      width: '100%',
    },
    main: {
      height: `calc(100% - ${theme.navigationMobileHeight}px)`,
      minWidth: `${theme.minWidth}px`,
      overflowY: 'auto',
      position: 'absolute',
      [theme.breakpoints.up('sm')]: {
        height: '100%',
        marginLeft: `${theme.navigationDesktopWidth}px`,
        width: `calc(100% - ${theme.navigationDesktopWidth}px)`,
      },

      width: '100%',
    },
    mainIFrame: {
      height: `100%`,
      minWidth: `${theme.minWidth}px`,
      overflowY: 'auto',
      position: 'absolute',
      [theme.breakpoints.up('sm')]: {
        height: '100%',
      },

      width: '100%',
    },
    navigation: {
      background: theme.palette.menuBackground,
      bottom: 0,
      boxShadow: '0 -1px 1px 0 rgba(99, 99, 98, 0.2)',
      display: 'flex',
      height: `${theme.navigationMobileHeight}px`,
      minWidth: `${theme.minWidth}px`,
      overflow: 'hidden',
      position: 'absolute',
      [theme.breakpoints.up('sm')]: {
        background: theme.palette.menuBackground,
        borderTop: 'none',
        bottom: 'auto',
        flexDirection: 'column',
        height: 'auto',
        minHeight: '100vh',
        minWidth: '0',
        top: 0,
        transition: theme.transitions.create(['width'], {
          duration: theme.transitions.duration.short,
        }),
        width: `${theme.navigationDesktopWidth}px`,
      },
      width: '100%',

      zIndex: theme.zIndex.appBar,
    },
    navigationDesktop: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    navigationExpanded: {
      [theme.breakpoints.up('sm')]: {
        width: `${theme.navigationDesktopWidthExpanded}px`,
      },
    },
    navigationMenuHidden: {
      display: 'none',
    },
    navigationMobile: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    socialLink: {
      marginRight: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(3),
      },
    },
  }),
  { index: 1 }
);

export type VkwNavigationProps = {
  /**
   * Key der aktiven Route aus `VkwNavigationItemConfig`
   */
  activeRoute: string | null;
  /**
   * Hauptnavigationselemente (Desktop oben; Mobil gleich sichtbar solange Platz vorhanden)
   */
  mainItems: VkwNavigationItemConfig[];
  /**
   * Weitere Navigationselemente (Desktop unten; Mobil im `More` Menü)
   */
  secondaryItems: VkwNavigationItemConfig[];
  /**
   * Footer Navigationselemente (Desktop im Footer; Mobil im Footer des `More` Menü)
   */
  footerItems: VkwNavigationItemConfig[];
  /**
   * Render Prop um einen globalen Header (Desktop und Mobil) zu gestalten -> z.B. für Login Button, User Avatar, ...
   */
  renderHeader: () => ReactNode;
  /**
   * Übergabe Copyright Inhabers -> Jahr und Copyright werden automatisch ordnungsgemäß hinzugefügt
   */
  copyright: string;
  /**
   * Übergabe der Links zu den sozialen Netzwerken
   */
  socialLinks: ReactElement[];
  /**
   * Übergabe der jeweiligen Layouts und Pages der Applikation
   */
  children: ReactNode;
  /**
   * Komplette Navigation verstecken?
   */
  hideMenu?: boolean;
};

export const VkwNavigation = ({
  activeRoute,
  children,
  copyright,
  footerItems,
  hideMenu,
  mainItems,
  renderHeader,
  secondaryItems,
  socialLinks,
}: VkwNavigationProps): ReactElement => {
  const styles = useStyles();
  const [expanded, setExpanded] = useState(false);
  const mainElement = useRef<HTMLElement>(null);
  const assets = useVkwAssets();
  const isIFrame = useIFrame();

  const activeRouteItem =
    useMemo(
      () => [...mainItems, ...secondaryItems, ...footerItems].find(item => item.key === activeRoute),
      [mainItems, secondaryItems, footerItems, activeRoute]
    ) ?? null;

  const navigationClass = clsx(styles.navigation, {
    [styles.navigationExpanded]: expanded,
    [styles.navigationMenuHidden]: hideMenu,
  });

  const headerImageClass = clsx(styles.headerImage, 'h-[32px] max-w-[140px]', {
    block: expanded,
    hidden: !expanded,
  });

  const renderSocialLinks = () => {
    return Children.map(socialLinks, socialLink => {
      return cloneElement(socialLink, {
        className: clsx(styles.socialLink, socialLink.props.className),
      });
    });
  };

  const renderCopyright = () => {
    return (
      <span className={styles.copyright}>
        &copy; {year} {copyright}
      </span>
    );
  };

  const renderFooter = () => {
    return (
      <>
        {footerItems.map(footerItem => (
          <Link
            key={footerItem.key}
            className={styles.footerItem}
            onClick={footerItem.onClick ? footerItem.onClick : undefined}
            target={footerItem.onClick ? undefined : '_blank'}
            href={footerItem.path}
            title={footerItem.name}
            color="textSecondary"
          >
            {footerItem.name}
          </Link>
        ))}
      </>
    );
  };

  return (
    <>
      <aside className={navigationClass}>
        <div className={styles.header}>
          <ButtonBase className={styles.expandButton} onClick={() => setExpanded(!expanded)}>
            <VkwIconMenu size={20} />
          </ButtonBase>
          <img className={headerImageClass} src={assets.logo} alt="" />
        </div>

        <NavigationDesktopItems
          className={styles.navigationDesktop}
          mainItems={mainItems}
          secondaryItems={secondaryItems}
          activeRoute={activeRoute}
          expanded={expanded}
          onItemClicked={() => {
            setExpanded(false);
          }}
        />

        <NavigationMobileItems
          className={styles.navigationMobile}
          mainItems={mainItems}
          secondaryItems={secondaryItems}
          footerItems={footerItems}
          activeRoute={activeRoute}
          renderHeader={renderHeader}
          renderCopyright={renderCopyright}
          renderSocialLinks={renderSocialLinks}
        />
      </aside>
      <main className={isIFrame ? styles.mainIFrame : styles.main} ref={mainElement}>
        <VkwNavigationContextProvider
          navigationProvider={{
            activeRoute: activeRouteItem,
            mainElement: mainElement.current,
            renderCopyright,
            renderFooter,
            renderHeader,
            renderSocialLinks,
          }}
        >
          {children}
        </VkwNavigationContextProvider>
      </main>
      <Backdrop
        className={styles.backdrop}
        open={expanded}
        onClick={() => {
          setExpanded(false);
        }}
      />
    </>
  );
};
