import { AxiosResponse } from 'axios';
import { FormikContextType } from 'formik';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';

import { useVkwFormatMessage, useVkwSnackbar } from '../library';

const lowerCaseFirstChar = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

interface Parameter {
  successSnackbarMessage?: string;
  errorSnackbarMessage?: string;
  tooManyRequestsSnackbarMessage?: string;
  notFoundSnackbarMessage?: string;
  bodyExceededSizeLimitSnackbarMessage?: string;
  unauthorizedSnackbarMessage?: string;
  validationSnackbarMessage?: string;
}

interface Handler {
  formik?: FormikContextType<any>;
  error?: (axiosResponse: AxiosResponse) => void;
  validation?: (axiosResponse: AxiosResponse) => void;
  success?: (axiosResponse: AxiosResponse) => void;
  tooManyRequests?: (axiosResponse: AxiosResponse) => void;
  notFound?: (axiosResponse: AxiosResponse) => void;
  bodyExceededSizeLimit?: (axiosResponse: AxiosResponse) => void;
  unauthorized?: (axiosResponse: AxiosResponse) => void;
}

export const useHandleAxiosResponse = (): ((
  handleAxiosRequest: () => Promise<AxiosResponse>,
  handler: Handler,
  parameter?: Parameter
) => Promise<boolean>) => {
  const router = useRouter();
  const enqueueVkwSnackbar = useVkwSnackbar();
  const formatMessage = useVkwFormatMessage();
  const { status } = useSession();

  return async (handleAxiosRequest, handler, parameter) => {
    const axiosResponse = await handleAxiosRequest();

    if (Math.floor(axiosResponse.status / 100) === 2) {
      handler.success?.(axiosResponse);

      if (typeof handler.formik !== 'undefined') {
        handler.formik.setSubmitting(false);
      }

      if (parameter?.successSnackbarMessage) {
        enqueueVkwSnackbar(parameter.successSnackbarMessage, 'success');
      }

      return true;
    }

    if (axiosResponse.status === 400) {
      handler.validation?.(axiosResponse);

      if (typeof handler.formik !== 'undefined') {
        const { data } = axiosResponse;
        handler.formik.setSubmitting(false);

        if (data && data.errors) {
          Object.keys(data.errors).forEach(key => {
            if (handler.formik) {
              handler.formik.setFieldError(lowerCaseFirstChar(key), data.errors[key][0]);
            }
          });
        }
      }

      if (parameter?.validationSnackbarMessage) {
        enqueueVkwSnackbar(parameter.validationSnackbarMessage, 'error');
      }

      return false;
    }

    if (axiosResponse.status === 401) {
      if (handler.unauthorized === undefined && status === 'unauthenticated') {
        window.location.href = `/auth/signin?callbackUrl=${encodeURIComponent(router.asPath)}`;
      }

      handler.unauthorized?.(axiosResponse);

      if (parameter?.unauthorizedSnackbarMessage) {
        enqueueVkwSnackbar(parameter.unauthorizedSnackbarMessage, 'error');
      }

      return false;
    }

    if (axiosResponse.status === 403) {
      router.push('/403');

      return false;
    }

    if (axiosResponse.status === 404) {
      handler.notFound?.(axiosResponse);

      if (typeof handler.formik !== 'undefined') {
        handler.formik.setSubmitting(false);
      }

      if (parameter?.notFoundSnackbarMessage) {
        enqueueVkwSnackbar(parameter.notFoundSnackbarMessage, 'error');
      }

      return false;
    }

    if (axiosResponse.status === 407) {
      if (typeof handler.formik !== 'undefined') {
        handler.formik.setSubmitting(false);
      }

      enqueueVkwSnackbar(formatMessage('ProxyError'), 'error');

      return false;
    }

    if (axiosResponse.status === 408) {
      router.push('/408');

      return false;
    }

    if (axiosResponse.status === 413) {
      handler.bodyExceededSizeLimit?.(axiosResponse);

      if (typeof handler.formik !== 'undefined') {
        handler.formik.setSubmitting(false);
      }

      if (parameter?.bodyExceededSizeLimitSnackbarMessage) {
        enqueueVkwSnackbar(parameter.bodyExceededSizeLimitSnackbarMessage, 'error');
      }

      return false;
    }

    if (axiosResponse.status === 429) {
      handler.tooManyRequests?.(axiosResponse);

      if (typeof handler.formik !== 'undefined') {
        handler.formik.setSubmitting(false);
      }

      if (parameter?.tooManyRequestsSnackbarMessage) {
        enqueueVkwSnackbar(parameter.tooManyRequestsSnackbarMessage, 'error');
      }

      return false;
    }

    if (axiosResponse.status === 504) {
      router.push('/504');

      return false;
    }

    if (Math.floor(axiosResponse.status / 100) === 5) {
      handler.error?.(axiosResponse);

      if (parameter?.errorSnackbarMessage) {
        enqueueVkwSnackbar(parameter.errorSnackbarMessage, 'error');
      }

      if (handler.error || parameter?.errorSnackbarMessage) {
        return false;
      }

      router.push({ pathname: '/5xx', query: { statusNumber: axiosResponse.status } });

      return false;
    }

    return false;
  };
};
