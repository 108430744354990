import { vkwDayjs } from './VkwDateIo';

export const dateTimeDisplay = (date: Date): string => {
  return `${date.toLocaleDateString(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })} ${date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
};

export const dateDisplay = (date: Date): string => {
  return new Date(date.toString()).toLocaleDateString(undefined, { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const timeDisplay = (date: Date): string => {
  return date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' });
};

export const timeConvert = (seconds: number, addUnit = true): string => {
  const duration = vkwDayjs.duration(seconds, 'seconds');

  const hours = Math.floor(duration.asHours());
  const minutes = Math.round((duration.asHours() - hours) * 60);

  const formattedHours = hours <= 9 ? `0${hours}` : hours.toLocaleString('de');

  return `${formattedHours}:${`0${minutes}`.slice(-2)}${addUnit ? 'h' : ''}`;
};

export const dataSectionDatastring = (datastring: string | null | undefined): string => {
  if (!datastring) {
    return '-';
  }

  if (datastring.length <= 0) {
    return '-';
  }

  return datastring;
};

type JSONPrimitive = string | number | boolean | null;

export const jsonParseDateReviver = (_: string, value: JSONPrimitive): Date | JSONPrimitive => {
  const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

  if (typeof value === 'string' && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
};

export const toPascalCase = (value: string): string => {
  if (value.length === 0) {
    return value;
  }

  const result = value.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());

  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const toFirstUppercase = (value: string): string => {
  if (value.length === 0) {
    return value;
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};
