import { t } from '../../../util/translate';

/**
 * Represents a step in a wizard.
 * @typedef {Object} WizardStep
 * @property {number} position - The position of the step in the wizard.
 * @property {string} title - The title of the step.
 * @property {string} path - The path associated with the step.
 */
export type WizardStep = {
  position: number;
  title: string;
  path: string;
};

/**
 * Represents the available wizard steps for the Help wizard.
 * Each step is defined by a unique key and contains properties such as position, title, and path.
 * The steps are stored in an object with the key being the step key and the value being the step object.
 *
 * @type {{[key: string]: WizardStep}}
 */
export const HelpWizardSteps: {
  [key: string]: WizardStep;
} = {
  avatar: {
    path: '/',
    position: 4,
    title: t('wizard.steps.avatar'),
  },
  chargeDetailRecords: {
    path: '/charge-detail-records',
    position: 0,
    title: t('wizard.steps.chargeDetailRecordsFilter'),
  },
  chargingContracts: {
    path: '/tariffs',
    position: 0,
    title: t('wizard.steps.chargingContracts'),
  },
  infrastructureFilter: {
    path: '/infrastructure',
    position: 0,
    title: t('wizard.steps.infrastructureFilter'),
  },
  invoices: {
    path: '/invoices',
    position: 0,
    title: t('wizard.steps.invoices'),
  },
  mapControls: {
    path: '/',
    position: 5,
    title: t('wizard.steps.mapControls'),
  },
  searchBar: {
    path: '/',
    position: 2,
    title: t('wizard.steps.searchBar.center'),
  },
  searchBarLeft: {
    path: '/',
    position: 1,
    title: t('wizard.steps.searchBar.left'),
  },
  searchBarRight: {
    path: '/',
    position: 3,
    title: t('wizard.steps.searchBar.right'),
  },
  start: {
    path: '/',
    position: 0,
    title: t('wizard.steps.start'),
  },
  supportTicket: {
    path: '/support',
    position: 0,
    title: t('wizard.steps.supportTicket'),
  },
};
