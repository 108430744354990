import React, { ReactElement, ReactNode } from 'react';

import { Box, Grid, makeStyles } from '@material-ui/core';

import { VkwHeader } from '../../components';
import { useVkwNavigation } from '../../contexts';
import { VkwTheme } from '../../themes';
import { VkwBaseLayout } from '../VkwBaseLayout';

const useStyles = makeStyles<VkwTheme, { secondaryContent: boolean }>(
  theme => ({
    container: {
      height: '100%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },

      width: '100%',
    },
    content: {
      [theme.breakpoints.down('xs')]: {
        flexGrow: 1,
      },
    },
    contentWrapper: {
      maxWidth: '100%',
      padding: theme.spacing(3),
    },
    mainContent: {
      flexGrow: 1,
      maxWidth: '100%',

      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(8),
      },
    },
    mainFooter: {
      alignItems: 'center',
      display: 'flex',
      maxWidth: '100%',
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    mainFooterWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      maxWidth: '100%',
      paddingLeft: theme.spacing(3),
    },
    secondaryContent: {
      background: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
      flexGrow: 1,
      paddingTop: theme.spacing(8),

      [theme.breakpoints.down('xs')]: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        display: props => (props.secondaryContent ? 'initial' : 'none'),
        flexGrow: 0,
        paddingTop: 0,
      },
    },
    secondaryFooter: {
      alignItems: 'center',
      background: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    secondaryFooterWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
      maxWidth: '100%',
      paddingRight: theme.spacing(3),
    },
  }),
  { index: 1 }
);

export interface VkwDuoLayoutProps {
  /**
   * Inhalt des `Main` Bereiches auf der linken Seite
   */
  renderMainContent: () => ReactNode;
  /**
   * Inhalt des `Secondary` Bereiches auf der rechten Seite
   */
  renderSecondaryContent?: () => ReactNode;
  /**
   * Wird eine abweichende Überschrift benötigt kann, über dieses render prop, eine übergeben werden.
   */
  renderHeader?: () => ReactNode;
}

export const VkwDuoLayout = ({
  renderHeader,
  renderMainContent,
  renderSecondaryContent,
}: VkwDuoLayoutProps): ReactElement => {
  const styles = useStyles({ secondaryContent: !!renderSecondaryContent });
  const navigation = useVkwNavigation();

  const renderDefaultHeader = () => {
    if (!navigation.activeRoute) {
      return null;
    }

    return <VkwHeader title={navigation.activeRoute.name} />;
  };

  return (
    <VkwBaseLayout>
      <Grid container className={styles.container}>
        <Grid item container direction="column" alignItems="stretch" sm={7} className={styles.content}>
          <Grid item container className={styles.mainContent}>
            <Grid item xs={12} sm={10}>
              <Box className={styles.contentWrapper}>
                {renderHeader ? renderHeader() : renderDefaultHeader()}
                {renderMainContent()}
              </Box>
            </Grid>
            <Grid item xs sm={2} />
          </Grid>
          <Grid item className={styles.mainFooter}>
            <Box className={styles.mainFooterWrapper}>
              {navigation.renderSocialLinks()}
              {navigation.renderCopyright()}
            </Box>
          </Grid>
        </Grid>
        <Grid item container direction="column" alignItems="stretch" sm={5}>
          <Grid item className={styles.secondaryContent}>
            <Box className={styles.contentWrapper}>{renderSecondaryContent ? renderSecondaryContent() : ''}</Box>
          </Grid>
          <Grid item className={styles.secondaryFooter}>
            <Box className={styles.secondaryFooterWrapper}>{navigation.renderFooter()}</Box>
          </Grid>
        </Grid>
      </Grid>
    </VkwBaseLayout>
  );
};
