import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconThreeDots = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill}>
        <circle cx="12" cy="12" fill={fill} r="2" />
        <circle cx="3" cy="12" fill={fill} r="2" />
        <circle cx="21" cy="12" fill={fill} r="2" />
      </g>
    </VkwIcon>
  );
};
