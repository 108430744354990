import * as React from 'react';

import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Alert, AlertTitle } from '@material-ui/lab';

import { useVkwStickyBanner } from '@e-vo/mobility/providers';

export const ChargeAtlasStickyBanner = (): React.ReactElement | null => {
  const [isBannerVisible, setIsBannerVisible] = React.useState(true);
  const { body, show, title } = useVkwStickyBanner();

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  return show && isBannerVisible ? (
    <Alert
      icon={<ExclamationTriangleIcon width={28} height={28} />}
      onClose={handleCloseBanner}
      severity="warning"
      className="absolute left-6 top-20 z-50 mr-6"
    >
      <AlertTitle>{title}</AlertTitle>
      {body}
    </Alert>
  ) : null;
};

interface ChargeAtlasStickyBannerProps {
  title: string;
  body: string;
}

export const ChargeAtlasStickyBannerPreview = ({ body, title }: ChargeAtlasStickyBannerProps): React.ReactElement => {
  return (
    <Alert
      icon={<ExclamationTriangleIcon width={28} height={28} />}
      onClose={() => {
        // dummy onClose so the close button is shown
      }}
      severity="warning"
    >
      <AlertTitle>{title}</AlertTitle>
      {body}
    </Alert>
  );
};
