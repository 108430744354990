import { VkwDataTableFieldConfig } from '.';
import { VkwSortableListItemConfig } from '../VkwSortableList';

const sortSettings = <T>(
  defaultSettings: VkwDataTableFieldConfig<T>[],
  userSettings: VkwSortableListItemConfig[]
): VkwDataTableFieldConfig<T>[] => {
  const userSettingsProps = userSettings.map(item => item.property);

  return defaultSettings.sort((a, b) => {
    if (a.fixed) {
      return 0;
    }
    return userSettingsProps.indexOf(a.property as string) - userSettingsProps.indexOf(b.property as string);
  });
};

export const mergeSettingsForDialog = <T>(
  defaultSettings: VkwDataTableFieldConfig<T>[],
  userSettings?: VkwSortableListItemConfig[]
): VkwSortableListItemConfig[] => {
  if (!userSettings) {
    return defaultSettings.map(item => ({
      active: item.active,
      fixed: item.fixed,
      label: item.label as string,
      property: item.property as string,
    }));
  }

  const defaultSettingsSorted = sortSettings(defaultSettings, userSettings);
  const result: VkwSortableListItemConfig[] = [];

  defaultSettingsSorted.forEach(item => {
    if (item.hidden) {
      return;
    }

    const userItem = userSettings.find(uItem => uItem.property === item.property);
    result.push({
      active: item.fixed ? true : userItem ? userItem.active : item.active,
      fixed: item.fixed,
      label: item.label as string,
      property: item.property as string,
    });
  });

  return result;
};

export const mergeSettingsForTable = <T>(
  defaultSettings: VkwDataTableFieldConfig<T>[],
  userSettings?: VkwSortableListItemConfig[]
): VkwDataTableFieldConfig<T>[] => {
  if (!userSettings) {
    return defaultSettings;
  }
  const result: VkwDataTableFieldConfig<T>[] = [];
  const defaultSettingsSorted = sortSettings(defaultSettings, userSettings);
  defaultSettingsSorted.forEach(item => {
    const userItem = userSettings.find(uItem => uItem.property === item.property);
    if (item.fixed || (!userItem && item.active) || (userItem && userItem.active)) {
      result.push(item);
    }
  });

  return result;
};
