import { I18n } from 'i18n-js';
import { TranslateOptions } from 'i18n-js/src/typing';

import de from './translations/de.json';
import en from './translations/en.json';

export type SupportedLocale = 'de-AT' | 'de' | 'en-US' | 'en';

const i18n = new I18n({ de, 'de-AT': de, en, 'en-US': en });

/**
 * Sets the i18n locale
 *
 * @param locale
 */
export function setLocale(locale: string): void {
  i18n.locale = locale;
}

/**
 * Translates text
 *
 * @param key The i18n key
 * @param options The i18n options
 * @returns The translated text
 *
 * @example
 * Translations:
 *
 * ```en.ts
 * {
 *  "hello": "Hello, {{name}}!"
 * }
 * ```
 *
 * Usage:
 * ```ts
 * import { translate } from "i18n-js"
 *
 * translate("common.ok", { name: "world" })
 * // => "Hello world!"
 * ```
 */
export function t(key: string, options?: TranslateOptions) {
  return i18n.t(key, options);
}
