/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react';

import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { useRouter } from 'next/router';

import { ChargeStationAdministration } from './ChargeStationAdministration';
import { ChargeStationCommissions } from './ChargeStationCommissions';
import { ChargeStationInfrastructureChargeOverview } from './ChargeStationInfrastructureChargeOverview';
import { ChargeStationPricesAndCharge } from './ChargeStationPricesAndCharge';
import { OperatorContracts } from './OperatorContracts';
import { useConfigContext, useUserContext } from '../../contexts';
import { ChargeStation, useMyChargePoints } from '../../hooks';
import {
  VkwCallToAction,
  VkwDataSection,
  VkwIconShare,
  VkwTab,
  VkwTheme,
  getVkwTypographyStyle,
  useVkwFormatMessage,
  toFirstUppercase,
} from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  address: {
    ...getVkwTypographyStyle('text14', theme),
    paddingTop: theme.spacing(1),
  },
  openingTimeText: {
    ...getVkwTypographyStyle('text16', theme),
  },
  pin: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

interface OpeningTimesProps {
  chargeStation: ChargeStation;
}

const OpeningTimes = ({ chargeStation }: OpeningTimesProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();

  if (!chargeStation.openingTimes || chargeStation.openingTimes.length < 1) {
    return (
      <Typography component="span" className={styles.openingTimeText} color="textSecondary">
        {formatMessage('Unknown')}
      </Typography>
    );
  }

  return (
    <>
      {chargeStation.openingTimes.map((openingTime, i) => (
        <Grid container spacing={2} key={`opening-time-${i}`}>
          <Grid item xs={6}>
            <Typography className={styles.openingTimeText} color="textSecondary">
              {formatMessage(`OpeningTimeWhen${toFirstUppercase(openingTime.when.toLowerCase())}`)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {openingTime.periods.map((period, i) => (
              <Typography color="textSecondary" className={styles.openingTimeText} key={`opening-time-periods-${i}`}>
                {period.begin} - {period.end}
              </Typography>
            ))}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

interface ChargeStationDetailsProps {
  chargeStation: ChargeStation;
}

export const ChargeStationDetails = ({ chargeStation }: ChargeStationDetailsProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const userContext = useUserContext();
  const configContext = useConfigContext();
  const router = useRouter();
  const { myChargePoints } = useMyChargePoints(chargeStation.chargePoints);

  const showReportIssue = useMemo(() => {
    if (configContext.featureFlags.enableTicketSystem) {
      return configContext.config.operatorTokens.includes(chargeStation.operatorToken) && userContext.account;
    }
    return null;
  }, [chargeStation]);

  const reportIssue = useCallback(() => {
    let pathname = `/support`;

    if (userContext.account?.info.isCallCenterAgent) {
      pathname = `/support-cca`;
    }

    router.push({
      pathname,
      query: { evseId: chargeStation.chargePoints[0].evseId, subject: 'ChargingStation' },
    });
  }, [chargeStation]);
  const renderOverview = (): ReactNode => {
    return (
      <>
        <VkwDataSection
          rootBoxProps={{ paddingBottom: 5 }}
          headline={formatMessage('OpeningTimes')}
          renderContent={() => <OpeningTimes chargeStation={chargeStation} />}
        />
        <VkwDataSection
          headline={formatMessage('Details')}
          items={[
            {
              renderContent: () => chargeStation.operatorName,
              title: formatMessage('Operator'),
            },
          ]}
        />
        {myChargePoints !== null && <ChargeStationCommissions chargePoints={myChargePoints} />}
        {showReportIssue && (
          <VkwDataSection
            rootBoxProps={{ paddingBottom: 3, paddingTop: 4 }}
            headline={false}
            renderContent={() => (
              <Box>
                <Grid container>
                  <Grid item>
                    <Box paddingRight={1.5}>
                      <VkwIconShare size={16} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <VkwCallToAction
                      iconPosition="right"
                      title={formatMessage('SubmitSupportRequest')}
                      onClick={reportIssue}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          />
        )}
      </>
    );
  };

  const renderPricesAndCharge = (): ReactNode => {
    return <ChargeStationPricesAndCharge chargeStation={chargeStation} />;
  };

  const chargingPointIds = myChargePoints ? myChargePoints.map(chargePoint => chargePoint.evseId) : [];

  const renderInfrastructureChargeOverview = (): ReactNode => {
    return <ChargeStationInfrastructureChargeOverview chargingPointIds={chargingPointIds} />;
  };

  const tabs = [
    {
      label: formatMessage('Overview'),
      renderContent: renderOverview,
    },
  ];

  const showCallCenterAgent = useMemo(() => {
    return !!(
      userContext.account?.info.isCallCenterAgent &&
      configContext.config.operatorTokens.includes(chargeStation.operatorToken)
    );
  }, [userContext.account?.info.isCallCenterAgent, chargeStation]);

  if (!showCallCenterAgent) {
    tabs.push({
      label: userContext.account?.info.hasInfrastructureContract
        ? formatMessage('PricesAndCharge')
        : formatMessage('Charging'),
      renderContent: renderPricesAndCharge,
    });
  }

  if (chargingPointIds.length > 0) {
    tabs.push({
      label: formatMessage('ChargeDetailRecords'),
      renderContent: renderInfrastructureChargeOverview,
    });
  }

  const showOperatorContracts = useMemo(() => {
    if (userContext.account?.info.isCallCenterAgent) {
      return configContext.config.operatorTokens.includes(chargeStation.operatorToken);
    }

    if (userContext.account?.info.hasInfrastructureContract) {
      let found = false;

      chargeStation.chargePoints.forEach(chargePoint => {
        if (chargingPointIds.includes(chargePoint.evseId)) {
          found = true;
        }
      });

      return found;
    }

    return false;
  }, [
    userContext.account?.info.isCallCenterAgent,
    userContext.account?.info.hasInfrastructureContract,
    chargeStation.chargePoints,
    chargingPointIds,
  ]);

  if (showOperatorContracts && chargeStation.chargePoints.some(c => c.isPublic)) {
    const renderOperatorContracts = (): ReactNode => {
      return (
        <OperatorContracts operatorToken={chargeStation.operatorToken} evseId={chargeStation.chargePoints[0].evseId} />
      );
    };

    tabs.push({
      label: formatMessage('OperatorContracts'),
      renderContent: renderOperatorContracts,
    });
  }

  if (
    showCallCenterAgent ||
    (userContext.account?.info.hasInfrastructureContract && myChargePoints !== null && myChargePoints.length > 0)
  ) {
    const renderAdministration = (): ReactNode => {
      return <ChargeStationAdministration chargeStation={chargeStation} />;
    };
    // Administration as first item for CCA
    tabs.unshift({
      label: formatMessage('Administration'),
      renderContent: renderAdministration,
    });
  }

  return <VkwTab tabs={tabs} />;
};
