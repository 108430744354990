import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconApps = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 24 24">
        <g id="VkwMenuIcon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons-/-24-/-Grid" fill={fill} fillRule="nonzero">
            <path
              d="M15,17 L15,23 L9,23 L9,17 L15,17 Z M23,17 L23,22 C23,22.552 22.552,23 22,23 L22,23 L17,23 L17,17 L23,17 Z M7,17 L7,23 L2,23 C1.448,23 1,22.552 1,22 L1,22 L1,17 L7,17 Z M7,9 L7,15 L1,15 L1,9 L7,9 Z M23,9 L23,15 L17,15 L17,9 L23,9 Z M15,9 L15,15 L9,15 L9,9 L15,9 Z M7,1 L7,7 L1,7 L1,2 C1,1.448 1.448,1 2,1 L2,1 L7,1 Z M22,1 C22.552,1 23,1.448 23,2 L23,2 L23,7 L17,7 L17,1 Z M15,1 L15,7 L9,7 L9,1 L15,1 Z"
              id="Combined-Shape"
            />
          </g>
        </g>
      </svg>
    </VkwIcon>
  );
};
