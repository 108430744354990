import React, { ReactElement, useEffect, useState, Dispatch, SetStateAction } from 'react';

import { Box, InputAdornment, InputBase, Typography } from '@material-ui/core';

import { ContractPartnerListItem } from './ContractPartnerListItem';
import { useStyles } from './VkwUserPopoverStyles';
import { useVkwFormatMessage } from '../../hooks';
import { VkwIconSearch } from '../../icons';
import { VkwContractPartner } from '../../types';
import { VkwAppPopoverConfig } from '../VkwAppPopover';

interface ContractPartnerListProps {
  contractPartners: VkwContractPartner[];
  listItemSelectable: boolean;
  setShowContractPartners?: Dispatch<SetStateAction<boolean>>;
  selectedContractPartner?: VkwContractPartner | null;
  onSelectContractPartner?: (data: VkwContractPartner) => void;
  setNewSelectedContractPartner?: (data: VkwContractPartner) => void;
  appConfig?: VkwAppPopoverConfig | undefined;
}

export const ContractPartnerList = ({
  appConfig,
  contractPartners,
  listItemSelectable,
  onSelectContractPartner,
  selectedContractPartner,
  setNewSelectedContractPartner,
  setShowContractPartners,
}: ContractPartnerListProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const [customerNumberInputValue, setCustomerNumberInputValue] = useState<string | undefined>();
  const [filteredContractPartners, setFilteredContractPartner] = useState<VkwContractPartner[]>(contractPartners);
  const [, setAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();

  useEffect(() => {
    if (typeof customerNumberInputValue === 'string') {
      const filteredData = contractPartners.filter(
        contractPartner =>
          contractPartner.name.toLowerCase().includes(customerNumberInputValue.toLowerCase()) ||
          contractPartner.number.toLowerCase().includes(customerNumberInputValue.toLowerCase())
      );
      setFilteredContractPartner(filteredData);
    }
  }, [customerNumberInputValue]);

  const selectContractPartner = (
    contractPartner: VkwContractPartner,
    appConfig: VkwAppPopoverConfig | undefined
  ): void => {
    if (listItemSelectable) {
      setAnchorEl(null);
      if (onSelectContractPartner) {
        onSelectContractPartner(contractPartner);
      }
    }
    if (setNewSelectedContractPartner && setShowContractPartners && appConfig) {
      setNewSelectedContractPartner(contractPartner);
      setShowContractPartners(false);
      window.open(appConfig.url, appConfig.openInTab ? '_blank' : '_self');
    }
  };

  const selectorIndicator = (contractPartner: VkwContractPartner): boolean => {
    if (listItemSelectable) {
      return selectedContractPartner ? selectedContractPartner.number === contractPartner.number : false;
    }

    return (
      contractPartner.identifiedProductCategories === selectedContractPartner?.identifiedProductCategories &&
      contractPartner.number === selectedContractPartner?.number
    );
  };
  return (
    <>
      {contractPartners.length > 5 && (
        <Box>
          <InputBase
            classes={{ input: styles.inputOverride }}
            className={styles.input}
            placeholder={formatMessage('CustomerNumberSearch')}
            startAdornment={
              <InputAdornment className={styles.innerInput} position="start">
                <VkwIconSearch size={16} />
              </InputAdornment>
            }
            onChange={e => setCustomerNumberInputValue(e.target.value.trim())}
          />
        </Box>
      )}
      {filteredContractPartners.length > 0 ? (
        <Box className={styles.data}>
          {filteredContractPartners.map((contractPartner, contractPartnerIndex) => {
            return (
              <ContractPartnerListItem
                selectable={listItemSelectable}
                data={contractPartner}
                key={`contractAccountPartner-${contractPartnerIndex}`}
                selected={selectorIndicator(contractPartner)}
                onClick={() => selectContractPartner(contractPartner, appConfig)}
                disabled={
                  !listItemSelectable &&
                  contractPartner.identifiedProductCategories &&
                  appConfig?.productCategories &&
                  appConfig.productCategories.filter(element =>
                    contractPartner.identifiedProductCategories?.includes(element)
                  ).length === 0
                }
              />
            );
          })}
        </Box>
      ) : (
        <Box className={styles.noCustomerNumberTextBox}>
          <Typography className={styles.noCustomerNumberText}>{formatMessage('NoCustomerNumberFound')}</Typography>
        </Box>
      )}
    </>
  );
};
