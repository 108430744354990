import defaultColors, { DefaultColors } from '../../defaultColors';

const evoColors: DefaultColors = {
  ...defaultColors,
  primary: {
    50: '#BDF0C7',
    100: '#ACECB9',
    200: '#8BE59D',
    300: '#69DD81',
    400: '#48D665',
    500: '#2DC84D',
    600: '#25A33F',
    700: '#1C7D30',
    800: '#145822',
    900: '#0B3213',
    950: '#071F0C',
    dark: '#25A33F',
    default: '#2DC84D',
    light: '#48D665',
  },
  secondary: {
    '50': '#f0f9ff',
    '100': '#e0f2fe',
    '200': '#bae6fd',
    '300': '#7dd3fc',
    '400': '#38bdf8',
    '500': '#0ea5e9',
    '600': '#0284c7',
    '700': '#0369a1',
    '800': '#075985',
    '900': '#0c4a6e',
    '950': '#082f49',
    default: '#0ea5e9',
  },
};

evoColors.navigation = {
  active: evoColors.primary['500'],
  'active-button': evoColors.primary['500'],
  'active-button-dark': evoColors.primary['500'],
  'active-dark': evoColors.white,
};

evoColors['charge-card'] = {
  dark: {
    from: evoColors.primary['500'],
    to: evoColors.primary['700'],
  },
  light: {
    from: evoColors.primary['400'],
    to: evoColors.primary['600'],
  },
};

evoColors['action-button'] = {
  active: evoColors.primary['600'],
  'active-dark': evoColors.primary['700'],
  default: evoColors.primary['500'],
  'default-dark': evoColors.primary['600'],
  disabled: evoColors.neutral['600'],
  'disabled-dark': evoColors.neutral['600'],
  loading: evoColors.primary['700'],
  'loading-dark': evoColors.primary['800'],
  text: evoColors.white,
  'text-dark': evoColors.white,
};

evoColors['action-text'] = {
  active: evoColors.blue['600'],
  'active-dark': evoColors.blue['600'],
  default: evoColors.blue['500'],
  'default-dark': evoColors.blue['500'],
  disabled: evoColors.blue['600'],
  'disabled-dark': evoColors.blue['600'],
  loading: evoColors.blue['700'],
  'loading-dark': evoColors.blue['700'],
};

export default evoColors;
