import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import useStyles from './VkwDateTimePickerStyles';
import { vkwDateIo } from '../../helper/VkwDateIo';
import { useVkwFormatMessage } from '../../hooks';
import { DateTimeToolbar } from '../VkwFilter/VkwDateTimeBetweenFilter/DateTimeToolbar';

export interface VkwDatePickerProps {
  label: string;
  date: Date;
  required?: boolean;
  onChange: (value: Date) => void;
}

const dateFormat = 'DD.MM.YYYY - HH:mm';
const pickerDialogId = 'datePickerId';

export const VkwDateTimePicker = ({ date, label, onChange, required }: VkwDatePickerProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();

  const [newValue, setNewValue] = useState(date);

  useEffect(() => {
    setNewValue(date);
  }, [newValue]);

  const handleValueChange = useCallback((date: MaterialUiPickersDate) => {
    const newDate = vkwDateIo.date(date).toDate();
    setNewValue(newDate);
    onChange(newDate);
  }, []);

  const renderTimeToolbar = useCallback(
    (props: ToolbarComponentProps) => <DateTimeToolbar pickerDialogId={pickerDialogId} toolbarProps={props} />,
    []
  );

  return (
    <KeyboardDatePicker
      DialogProps={{
        PaperProps: {
          classes: {
            root: styles.pickerPaperRoot,
          },
        },
        className: styles.datePicker,
        id: pickerDialogId,
      }}
      required={required}
      variant="dialog"
      onChange={handleValueChange}
      value={newValue}
      placeholder={dateFormat}
      format={dateFormat}
      fullWidth
      invalidDateMessage={formatMessage('InvalidDate')}
      inputVariant="filled"
      label={label}
      InputProps={{
        classes: { focused: styles.inputFocused, input: styles.inputInput, root: styles.inputRoot },
      }}
      InputLabelProps={{ classes: { focused: styles.labelFocused, root: styles.labelRoot } }}
      cancelLabel={formatMessage('Cancel')}
      okLabel={formatMessage('Ok')}
      ToolbarComponent={renderTimeToolbar}
      strictCompareDates
    />
  );
};
