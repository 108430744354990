import React, { ReactChild, ReactElement, ChangeEvent } from 'react';

import { InputBase, makeStyles } from '@material-ui/core';

import { getVkwTypographyStyle } from '../../../helper';
import { VkwTheme } from '../../../themes';

interface IconTextFieldProps {
  placeholder?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  icon?: ReactChild;
  type?: string;
}

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    inputInput: {
      color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
      height: '100%',
    },
    inputRoot: {
      minHeight: '40px',
      ...getVkwTypographyStyle('text14', theme),
    },
  }),
  { index: 1 }
);

export const IconTextField = ({ icon, ...rest }: IconTextFieldProps): ReactElement => {
  const styles = useStyles();
  return (
    <InputBase
      classes={{ input: styles.inputInput, root: styles.inputRoot }}
      {...rest}
      fullWidth
      startAdornment={icon}
      className={styles.input}
    />
  );
};
