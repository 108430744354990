import React, { ReactElement, ReactNode } from 'react';

import { Box, BoxProps, Grid, GridSize, makeStyles, Typography } from '@material-ui/core';

import { getVkwTypographyStyle } from '../../helper';
import { useVkwFormatMessage } from '../../hooks';
import { VkwTheme, useVkwTheme } from '../../themes';
import { VkwButton } from '../VkwButton';
import { VkwCallToAction } from '../VkwCallToAction';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    cancelButton: {
      paddingRight: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        paddingTop: theme.spacing(1),
      },
    },
    editSectionEditMode: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(3),
      minHeight: '44px',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
      },
    },
    editSectionViewMode: {
      display: 'flex',
      marginTop: theme.spacing(3),
      minHeight: '44px',
    },
    headline: {
      ...getVkwTypographyStyle('h9', theme),
    },
    itemContent: {
      ...getVkwTypographyStyle('text14', theme),
    },
    itemTitle: {
      ...getVkwTypographyStyle('text12', theme),
    },
    items: {
      paddingTop: theme.spacing(3),
    },
  }),
  { index: 1 }
);

export interface VkwDataSectionItem {
  title?: string;
  value?: string;
  renderContent?: () => ReactNode;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
}

export interface VkwDataSectionProps {
  headline: string | ReactNode;
  /**
   * DataSection Elemente
   */
  items?: VkwDataSectionItem[];
  /**
   * Render Prop um Content zwischen der `headline` und den `items` zu rendern
   */
  renderContent?: () => ReactNode;
  /**
   * Mit diesem Objekt kann die `root` Box der DataSection angepasst werden ... Alle Properties der Material-UI Box sind übergeben
   */
  rootBoxProps?: BoxProps;
  /**
   * Wird der Komponente `editMode` übergeben, so werden Anstatt dem CallToAction Button die Save und Cancel Buttons angezeigt. Gilt nur wenn auch ein `ctaLabel` Übergeben wird!
   */
  editMode?: boolean;
  /**
   * Rückmeldung wenn der User auf den Save Button klickt
   */
  saveInProgress?: boolean;
  /**
   * Wird ein Label für den CallToAction Button übergeben, wird dieser angezeigt. Somit wird es möglich in den Editiermodus umzuschalten.
   */
  ctaLabel?: string;
  onCtaClick?: () => void;
  onCancelClick?: () => void;
  onSaveClick?: () => void;
}

export const VkwDataSection = ({
  ctaLabel,
  editMode,
  headline,
  items = [],
  onCancelClick,
  onCtaClick,
  onSaveClick,
  renderContent,
  rootBoxProps,
  saveInProgress,
}: VkwDataSectionProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();
  const themes = useVkwTheme();

  const renderHeadline = (): ReactNode => {
    if (typeof headline === 'string') {
      return <Typography className={styles.headline}>{headline}</Typography>;
    }

    return headline;
  };

  return (
    <Box {...rootBoxProps}>
      {renderHeadline()}
      {renderContent && <Box paddingTop={1}>{renderContent()}</Box>}
      {items.length > 0 && (
        <Grid className={styles.items} container spacing={2}>
          {items.map((item, i) => (
            <Grid key={i} item xs={item.xs ?? 12} sm={item.sm ?? 6} md={item.md} lg={item.lg}>
              <Box minHeight={themes.spacing(7)}>
                {item.title && (
                  <Typography className={styles.itemTitle} color="textSecondary">
                    {item.title}
                  </Typography>
                )}
                <Box className={styles.itemContent}>{item.renderContent ? item.renderContent() : item.value}</Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      {ctaLabel && (
        <>
          {!editMode && (
            <Box className={styles.editSectionViewMode}>
              <VkwCallToAction title={ctaLabel} onClick={onCtaClick} iconPosition="right" />
            </Box>
          )}
          {editMode && (
            <Box className={styles.editSectionEditMode}>
              <Box className={styles.cancelButton}>
                <VkwButton variant="contained" color="default" onClick={onCancelClick} fullWidth>
                  {formatMessage('Cancel')}
                </VkwButton>
              </Box>
              <Box>
                <VkwButton
                  variant="contained"
                  color={themes.palette.mainButtonColor}
                  onClick={onSaveClick}
                  fullWidth
                  isSubmitting={saveInProgress}
                >
                  {formatMessage('Save')}
                </VkwButton>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
