import axios from 'axios';

import { processDataCacheState } from './processDataCacheState';
import { DataCacheState } from './UserContext';

export type CacheState = 'cold' | 'locked' | 'warm';

interface CacheStatusItem {
  customerId: string | null;
  lastUpdate: Date | null;
  status: CacheState;
}

export interface CacheStatusResponse {
  chargingTariffContractData: CacheStatusItem;
  chargingTariffChargeDetailRecordsPreload: CacheStatusItem[];
  chargingTariffChargeDetailRecordsFullData: CacheStatusItem[];
  infrastructureContractData: CacheStatusItem;
  infrastructureChargeDetailRecordsPreload: CacheStatusItem[];
  infrastructureChargeDetailRecordsFullData: CacheStatusItem[];
  invoiceData: CacheStatusItem;
}

export const getDataCacheState = async (customerId: string): Promise<DataCacheState> => {
  const response = await axios.get<CacheStatusResponse>('/api/v1/userInfo/CacheStatus', { params: { customerId } });

  if (response.status !== 200) {
    return {
      chargingTariffChargeDetailRecordsLastUpdate: null,
      chargingTariffChargeDetailRecordsLocked: false,
      infrastructureChargeDetailRecordsLastUpdate: null,
      infrastructureChargeDetailRecordsLocked: false,
    };
  }

  return processDataCacheState(response.data);
};
