import React, { ReactElement, useState } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { useConfigContext, useUserContext } from '../../contexts';
import { Assets } from '../../globals/configureAssets';
import { getVkwTypographyStyle, useVkwAssets, useVkwFormatMessage, VkwReminderDialog, VkwTheme } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  infoText: {
    ...getVkwTypographyStyle('text16', theme),
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
  infoTextBox: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0`,

    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(8)}px 0`,
    },
  },
}));

export const CompleteProfileDialog = (): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const styles = useStyles();
  const userContext = useUserContext();
  const configContext = useConfigContext();
  const [open, setOpen] = useState(true);
  const assets = useVkwAssets<Assets>();

  return (
    <VkwReminderDialog
      headerImg={assets.invoices}
      headerText={formatMessage('DialogCompleteProfileTitle')}
      buttonText={formatMessage('LinkCustomerData')}
      onSkipClick={hideCompleteProfileInfo => {
        if (hideCompleteProfileInfo) {
          userContext.setHideCompleteProfileInfo();
        }
        setOpen(prev => !prev);
      }}
      onButtonClick={() => {
        window.location.href = configContext.envUrls.addCustomerUrl || '';
        setOpen(false);
      }}
      isOpen={open}
    >
      <Box className={styles.infoTextBox}>
        <Typography className={styles.infoText}>{formatMessage('DialogCompleteProfileInfo')}</Typography>
      </Box>
    </VkwReminderDialog>
  );
};
