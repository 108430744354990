import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const InfrastructureStoreModel = types
  .model('InfrastructureStore')
  .props({
    chargeStationDetails: types.maybe(types.string),
  })
  .actions(store => ({
    setChargeStationDetails(chargeStationDetails: string) {
      store.chargeStationDetails = chargeStationDetails;
    },
  }));

export type InfrastructureStore = Instance<typeof InfrastructureStoreModel>;
export type InfrastructureStoreSnapshot = SnapshotOut<typeof InfrastructureStoreModel>;
