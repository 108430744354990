import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconDownload = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M3 15v6h18v-6h2v8H1v-8h2zM13 1v12.585l5-5L19.414 10 12 17.414 4.586 10 6 8.586l5 4.999V1h2z"
          fill={fill}
        />
      </g>
    </VkwIcon>
  );
};
