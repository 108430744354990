import React, { ChangeEvent, ReactElement, ReactNode } from 'react';

import {
  FormControlLabel,
  FormControl,
  makeStyles,
  Typography,
  FormControlLabelProps,
  Checkbox as MuiCheckbox,
} from '@material-ui/core';

import { getVkwTypographyStyle } from '../../helper';
import { VkwIconCheckbox, VkwIconCheckboxSelected, VkwIconCheckboxError, VkwIconCheckboxDisabled } from '../../icons';
import { VkwTheme } from '../../themes';

const getFillColor = (theme: VkwTheme, error?: boolean, disabled?: boolean): string => {
  if (error) {
    return theme.palette.error.main;
  }
  if (disabled) {
    return 'transparent';
  }
  return theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.common.white;
};

const getStrokeColor = (theme: VkwTheme, disabled?: boolean): string => {
  if (disabled) {
    return theme.palette.type === 'light' ? theme.palette.grey[400] : theme.palette.grey[500];
  }
  return theme.palette.type === 'light' ? theme.palette.common.white : theme.palette.grey[600];
};

const useStyles = makeStyles<VkwTheme, { error?: boolean; disabled?: boolean; checked?: boolean }>(
  (theme: VkwTheme) => ({
    checkBoxLabel: {
      ...getVkwTypographyStyle('text16', theme),
      [theme.breakpoints.up('sm')]: {
        ...getVkwTypographyStyle('text14', theme),
      },
    },
    root: props => ({
      '& svg g path': {
        stroke: getStrokeColor(theme, props.disabled),
      },
      '& svg g rect': {
        fill: getFillColor(theme, props.error, props.disabled),
        stroke: getStrokeColor(theme, props.disabled),
      },
      '&.Mui-focusVisible svg': {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '5px',
      },
    }),
  }),
  { index: 1 }
);

export interface VkwCheckboxProps {
  label?: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  name?: string;
  error?: boolean;
  value?: string;
  fullWidth?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  formControlLabelProps?: Partial<FormControlLabelProps>;
}

export const VkwCheckbox = ({
  checked,
  disabled,
  error,
  formControlLabelProps,
  fullWidth,
  label,
  name,
  onChange,
  value,
}: VkwCheckboxProps): ReactElement => {
  const classes = useStyles({ checked, disabled, error });
  const formLabel =
    typeof label === 'string' ? <Typography className={classes.checkBoxLabel}>{label}</Typography> : label;
  return (
    <FormControl fullWidth={fullWidth} disabled={disabled} error={error}>
      <FormControlLabel
        label={formLabel ?? ''}
        control={
          <MuiCheckbox
            className={classes.root}
            color="primary"
            icon={error ? <VkwIconCheckboxError /> : <VkwIconCheckbox />}
            checkedIcon={disabled ? <VkwIconCheckboxDisabled /> : <VkwIconCheckboxSelected />}
            checked={checked}
            value={value}
            onChange={onChange}
            name={name}
          />
        }
        {...formControlLabelProps}
      />
    </FormControl>
  );
};
