import { makeStyles } from '@material-ui/core';

import { VkwNotificationType } from './VkwNotification';
import { getVkwTypographyStyle } from '../../helper';
import { VkwTheme } from '../../themes';

const getColorForNotificationType = (type: VkwNotificationType, theme: VkwTheme): string => {
  if (type === 'error') {
    return theme.palette.error.main;
  }
  if (type === 'success') {
    return theme.palette.success.main;
  }
  if (type === 'warning') {
    return theme.palette.warning.main;
  }
  return theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.grey[500];
};

export const useStyles = makeStyles<VkwTheme, { type: VkwNotificationType }>(
  theme => ({
    box: {
      border: props => `1px solid ${getColorForNotificationType(props.type, theme)}`,
      borderLeft: 'none',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(6),
      position: 'relative',
    },
    icon: {
      color: props => getColorForNotificationType(props.type, theme),
      left: theme.spacing(2),
      position: 'absolute',
      top: theme.spacing(1.3),
    },
    indicator: {
      backgroundColor: props => getColorForNotificationType(props.type, theme),
      bottom: -1,
      left: 0,
      position: 'absolute',
      top: -1,
      width: 3,
    },
    message: {
      ...getVkwTypographyStyle('text14', theme),
      color: theme.palette.text.primary,
    },
    title: {
      ...getVkwTypographyStyle('link14', theme),
      color: theme.palette.text.primary,
    },
  }),
  { index: 1 }
);
