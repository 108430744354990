import React, { ReactElement, useCallback } from 'react';

import { Typography, makeStyles } from '@material-ui/core';

import { Commission } from '../../contexts';
import { VkwTheme, getVkwTypographyStyle, useVkwFormatMessage } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  text: {
    ...getVkwTypographyStyle('text14', theme),
  },
}));

interface CommissionDetailProps {
  commission: Commission;
}

export const CommissionDetail = ({ commission }: CommissionDetailProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const amount = Math.abs(commission.amount);
  const currency = commission.currency === 'EUR' ? '€' : commission.currency;
  const styles = useStyles();

  const getDetails = useCallback(() => {
    if (commission.unitType === 'TIME' && commission.unitSize > 60) {
      return `${currency} ${amount} / ${formatMessage('CommissionUnitTypeTimeMin', {
        amount,
        unitSize: commission.unitSize / 60,
      })}`;
    }
    if (commission.unitType === 'TIME') {
      return `${currency} ${amount} /${formatMessage('CommissionUnitTypeTimeSec', {
        amount,
        unitSize: commission.unitSize,
      })}`;
    }
    if (commission.unitType === 'KWH') {
      return `${currency} ${amount} / kWh`;
    }
    if (commission.unitType === 'START') {
      return `${currency} ${amount} / ${formatMessage('CommissionUnitTypeStart')}`;
    }
    return `${currency} ${amount} / ${formatMessage('CommissionUnitTypeReminder')}`;
  }, [commission]);

  return <Typography className={styles.text}>{getDetails()}</Typography>;
};
