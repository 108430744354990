import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';

import { Alert } from '@material-ui/lab';
import Axios from 'axios';
import { FormikContextType, useFormik } from 'formik';
import { useRouter } from 'next/router';
import * as Yup from 'yup';

import { FIELD_MAX_LENGTH, PaymentTypes, POSSIBLE_PAYMENT_TYPES, POSSIBLE_SUBJECTS, TicketSubject } from './const';
import { FileUploadControl, FileUploadError, Form } from '../../components';
import { useUserContext } from '../../contexts';
import { useHandleAxiosResponse } from '../../hooks';
import {
  useVkwFormatMessage,
  VkwAutocomplete,
  VkwAutocompleteAdditionalOption,
  VkwAutocompleteFreeSolo,
  VkwButton,
  VkwSelect,
  VkwSelectItem,
  VkwTextField,
} from '../../library';
import { Attachment, createFileAttachments } from '../../util/Files';

const getInitalTicketData = (presets: NewTicketDataPresets): NewTicketData => {
  return {
    ...presets,
    attachments: null,
    authenticationCardUid: '',
    otherProviderInfo: '',
    paymentType: 'null',
  };
};

export interface NewTicketDataPresets {
  subject: TicketSubject | 'null';
  description: string;
  evseId: string;
}

export interface NewTicketData extends NewTicketDataPresets {
  paymentType: PaymentTypes | 'null';
  authenticationCardUid: string;
  otherProviderInfo: string;
  attachments: Attachment[] | null;
}

export const NewTicket = (): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const handleAxiosResponse = useHandleAxiosResponse();
  const router = useRouter();
  const userContext = useUserContext();

  const presetData = router.query;
  const [presets] = useState<NewTicketDataPresets>({
    description: presetData?.description ? (presetData.description as string) : '',
    evseId: presetData?.evseId ? (presetData.evseId as string) : '',
    subject: presetData?.subject ? (presetData?.subject as TicketSubject) : 'null',
  });

  const [files, setFiles] = useState<FileList | null>(null);
  const [evseIdNotFoundError, setEvseIdNotFoundError] = useState<boolean | null>(null);
  const [attachmenterror, setAttachmentError] = useState<FileUploadError | null>(null);

  useEffect(() => {
    if (presets.subject === 'null') {
      return;
    }

    router.replace({
      pathname: router.pathname,
      query: {},
    });
  }, [presets.subject]);

  const handleCloseNewTicket = (): void => {
    setAttachmentError(null);
    setFiles(null);
  };

  const handleCreate = async (formik: FormikContextType<any>): Promise<void> => {
    formik.setSubmitting(true);

    const { values } = formik;
    const attachments = await createFileAttachments(files);
    setAttachmentError(null);

    const newTicketData: NewTicketData = {
      attachments,
      authenticationCardUid: values.authenticationCardUid,
      description: values.description,
      evseId: values.evseId ? values.evseId.substring(0, values.evseId.indexOf('-') - 1) : null,
      otherProviderInfo: values.otherProviderInfo.trim() !== '' ? values.otherProviderInfo : null,
      paymentType: values.paymentType !== 'null' ? values.paymentType : null,
      subject: values.subject,
    };

    await handleAxiosResponse(
      () =>
        Axios.post('/api/support/tickets', newTicketData, {
          headers: {
            'Accept-Language': 'de',
          },
          params: { customerId: userContext.selectedCustomer?.id },
        }),
      {
        bodyExceededSizeLimit: () => {
          setAttachmentError({
            message: formatMessage('FileAttachmentsExceededSizeLimitMessage'),
            title: formatMessage('FileAttachmentsExceededSizeLimitTitle'),
          });
        },
        formik,
        success: () => {
          formik.setStatus({
            msg: formatMessage('SupportSuccessMessage'),
            sent: true,
          });

          handleCloseNewTicket();
        },
        validation: response => {
          if (response.data.attachments) {
            setAttachmentError({
              message: formatMessage('InvalidFileAttachmentsMessage'),
              title: formatMessage('InvalidFileAttachmentsTitle'),
            });
          }
        },
      },
      {
        bodyExceededSizeLimitSnackbarMessage: formatMessage('SupportMailSendingError'),
        errorSnackbarMessage: formatMessage('SupportMailSendingError'),
        successSnackbarMessage: formatMessage('SuccessfullySaved'),
        tooManyRequestsSnackbarMessage: formatMessage('SupportMailSendingError'),
      }
    );
  };

  const formik = useFormik({
    initialValues: getInitalTicketData(presets),
    onReset: () => {
      handleCloseNewTicket();
    },
    onSubmit: async () => {
      await handleCreate(formik);
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      authenticationCardUid: Yup.string().optional(),
      description: Yup.string().required(),
      evseId: Yup.string().optional(),
      otherProviderInfo: Yup.string().optional(),
      paymentType: Yup.string().when('subject', {
        is: 'ChargingCard',
        then: Yup.string().required(),
      }),
      subject: Yup.string().notOneOf(['null']),
    }),
  });

  const getOptionsEvseId = useCallback((searchString: string): Promise<string[]> => {
    return new Promise(resolve => {
      if (searchString.length < 1) {
        resolve([]);
        return;
      }

      handleAxiosResponse(
        () =>
          Axios.get('/api/v1/ChargeStations/SearchInEvseIdAddressStationName', {
            params: {
              limit: 5,
              searchTerm: searchString,
            },
          }),
        {
          success: response => {
            if (response.data.length === 0) {
              setEvseIdNotFoundError(true);
            } else {
              resolve(response.data);
              setEvseIdNotFoundError(false);
            }
          },
        }
      );
    });
  }, []);

  const getOptionsAuthCardUid = useCallback((searchString: string): Promise<string[]> => {
    return new Promise(resolve => {
      if (searchString.length < 1) {
        resolve([]);
        return;
      }

      handleAxiosResponse(
        () =>
          Axios.get('/api/v1/ChargingTariffContracts/SearchAuthenticationCardUid', {
            params: {
              authenticationCardUid: searchString,
              customerId: userContext.selectedCustomer?.id,
              limit: 5,
            },
          }),
        { success: response => resolve(response.data) }
      );
    });
  }, []);

  useEffect(() => {
    async function setEvseIdFromApi() {
      if (formik.values.evseId && formik.values.evseId !== '') {
        formik.setFieldValue('evseId', (await getOptionsEvseId(formik.values.evseId))[0]);
      }
    }

    setEvseIdFromApi();
  }, []);

  return formik.status && formik.status.msg && formik.status.sent ? (
    <>
      <p>{formik.status.msg}</p>
      <VkwButton
        variant="contained"
        color="default"
        onClick={() => {
          formik.resetForm();
        }}
      >
        {formatMessage('SupportNewRequest')}
      </VkwButton>
    </>
  ) : (
    <Form formik={formik} submitButtonText={formatMessage('CreateTicket')}>
      <VkwSelect
        name="subject"
        value={formik.values.subject}
        label={formatMessage('SelectTicketSubject')}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        chooseOptionValue="null"
        error={formik.errors.subject && formik.touched.subject ? formatMessage(formik.errors.subject) : undefined}
        items={POSSIBLE_SUBJECTS.map((item): VkwSelectItem => {
          return { label: formatMessage(`TicketSubject${item}`), value: item };
        })}
      />
      {['Charging', 'ChargingCard', 'Payment', 'Other'].includes(formik.values.subject as TicketSubject) && (
        <>
          <VkwSelect
            name="paymentType"
            value={formik.values.paymentType}
            label={formatMessage('SelectPaymentType')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            chooseOptionValue="null"
            error={
              formik.errors.paymentType && formik.touched.paymentType
                ? formatMessage(formik.errors.paymentType)
                : undefined
            }
            items={POSSIBLE_PAYMENT_TYPES.map((item): VkwSelectItem => {
              return { label: formatMessage(`PaymentType${item}`), value: item };
            })}
          />
          {formik.values.paymentType === 'ChargingCard' && (
            <VkwAutocompleteFreeSolo
              name="authenticationCardUid"
              label={formatMessage('AuthenticationCardUid')}
              fullWidth
              value={formik.values.authenticationCardUid}
              setValue={async value => {
                await formik.setFieldValue(
                  'authenticationCardUid',
                  (value as VkwAutocompleteAdditionalOption)?.valueAdditionalEntry ?? value ?? ''
                );
              }}
              getOptions={getOptionsAuthCardUid}
              onBlur={formik.handleBlur}
              maxLength={FIELD_MAX_LENGTH.AUTHENTICATION_CARD_UID}
              error={!!(formik.errors.authenticationCardUid && formik.touched.authenticationCardUid)}
              helperText={
                formik.errors.authenticationCardUid ? formatMessage(formik.errors.authenticationCardUid) : undefined
              }
            />
          )}
          {formik.values.paymentType === 'OtherTypes' && (
            <VkwTextField
              name="otherProviderInfo"
              value={formik.values.otherProviderInfo}
              label={formatMessage('OtherProviderInfo')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              error={!!(formik.errors.otherProviderInfo && formik.touched.otherProviderInfo)}
              helperText={formik.errors.otherProviderInfo ? formatMessage(formik.errors.otherProviderInfo) : undefined}
            />
          )}
        </>
      )}
      {['Charging', 'ChargingStation', 'Payment', 'Other'].includes(formik.values.subject as TicketSubject) &&
        evseIdNotFoundError && (
          <Alert severity="error" variant="outlined" className="ml-px">
            {formatMessage('EvseIdNotFoundForTicket')}
          </Alert>
        )}
      {['Charging', 'ChargingStation', 'Payment', 'Other'].includes(formik.values.subject as TicketSubject) && (
        <VkwAutocomplete
          name="evseId"
          label={formatMessage('EvseIdOrLocation')}
          fullWidth
          value={formik.values.evseId}
          setValue={value => formik.setFieldValue('evseId', value ?? '')}
          getOptions={getOptionsEvseId}
          error={!!(formik.errors.evseId && formik.touched.evseId)}
          helperText={formik.errors.evseId ? formatMessage(formik.errors.evseId) : undefined}
          onBlur={formik.handleBlur}
        />
      )}
      {formik.values.subject && (
        <VkwTextField
          name="description"
          value={formik.values.description ?? ''}
          label={formatMessage('YourDescriptionForUs')}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!(formik.errors.description && formik.touched.description)}
          helperText={formik.errors.description ? formatMessage(formik.errors.description) : undefined}
          maxLength={FIELD_MAX_LENGTH.DESCRIPTION}
          multiline
          fullWidth
        />
      )}
      {formik.values.subject && (
        <FileUploadControl
          onChange={(event: ChangeEvent<HTMLInputElement>) => setFiles(event.target.files)}
          onDelete={() => {
            setFiles(null);
          }}
          files={files}
          error={attachmenterror}
        />
      )}
    </Form>
  );
};
