import React, { ReactElement } from 'react';

import { NoSymbolIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import clsx, { ClassValue } from 'clsx';

import { ChargePointStatusEnum } from '@e-vo/types';

import { ChargeStation } from '../../hooks';
import { getChargePointsData } from '../../util/charge-stations';

type ChargePointStatusWithNotAllowed = ChargePointStatusEnum | 'NOT_ALLOWED';

interface MaxChargePowerIconProps {
  maxChargePower: number;
}

const MaxChargePowerIcon = ({ maxChargePower }: MaxChargePowerIconProps): ReactElement => {
  return (
    <span
      className={clsx(
        'absolute -top-3 flex h-4 items-center justify-center rounded-2xl bg-white font-medium text-gray-700 drop-shadow-lg',
        maxChargePower.toString().length >= 3 ? '-right-2 w-12' : '-right-1 w-10'
      )}
    >
      {maxChargePower} kW
    </span>
  );
};

interface MarkerIconProps {
  chargeStation: ChargeStation;
  isActive: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const MarkerOverlayView = ({ chargeStation, isActive, onClick }: MarkerIconProps): ReactElement => {
  const { available, chargePointsCount, chargeStationStatus, maxChargePower } = getChargePointsData(chargeStation);

  const markerColor: Record<ChargePointStatusWithNotAllowed, ClassValue[]> = {
    AVAILABLE: ['bg-green-600 group-hover:bg-green-700', isActive && 'bg-green-700'],
    NOT_ALLOWED: ['bg-gray-300 group-hover:bg-gray-400', isActive && 'bg-gray-400'],
    OCCUPIED: ['bg-yellow-600 group-hover:bg-yellow-700', isActive && 'bg-yellow-700'],
    OUT_OF_SERVICE: ['bg-red-600 group-hover:bg-red-700', isActive && 'bg-red-700'],
    RESERVED: ['bg-blue-600 group-hover:bg-blue-700', isActive && 'bg-blue-700'],
    UNKNOWN: ['bg-gray-500 group-hover:bg-gray-600', isActive && 'bg-gray-600'],
  };

  return (
    <div
      className={clsx(
        'group relative origin-bottom transition ease-in hover:z-20 hover:scale-125',
        chargeStationStatus === 'NOT_ALLOWED' && 'z-0 scale-75',
        chargeStationStatus !== 'NOT_ALLOWED' && 'z-10',
        isActive && 'z-30 !scale-150'
      )}
      onClick={onClick}
      role="presentation"
    >
      <div
        className={clsx(
          'relative rotate-45 rounded-t-[50%] rounded-bl-[50%] text-sm text-white drop-shadow-sm transition ease-in group-hover:ring-0',
          isActive && '',
          markerColor[chargeStationStatus]
        )}
      >
        <div className={clsx('flex h-8 w-8 -rotate-45 items-center justify-center')}>
          {chargeStationStatus === 'NOT_ALLOWED' ? (
            <span className="font-medium">
              <NoSymbolIcon className="h-6 w-6 text-gray-500" />
            </span>
          ) : chargeStationStatus === 'OUT_OF_SERVICE' ? (
            <span className="font-medium">
              <WrenchScrewdriverIcon className="h-4 w-4" />
            </span>
          ) : (
            <>
              <span className="font-bold">{chargeStationStatus === 'UNKNOWN' ? '?' : available}</span>
              <span className={clsx('opacity-50', chargePointsCount >= 10 && 'text-xs', available >= 10 && 'hidden')}>
                /{chargePointsCount}
              </span>
            </>
          )}
        </div>
      </div>
      {isActive && (
        <span
          className={clsx(
            'absolute top-0 -z-10 inline-flex h-full w-full animate-ping rounded-full opacity-75',
            markerColor[chargeStationStatus]
          )}
        />
      )}
      {chargeStationStatus !== 'NOT_ALLOWED' && maxChargePower >= 50 && (
        <MaxChargePowerIcon maxChargePower={maxChargePower} />
      )}
      <span className="bg-gradient-radial absolute -bottom-2 right-1 -z-10 h-1 w-6 rounded-full from-black/40 via-black/10 to-transparent" />
    </div>
  );
};
