import React, { ReactElement, useMemo } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import { ErrorIcon, ErrorIcon403, ErrorIcon404, ErrorIcon408 } from './Icons';
import { VkwCallToAction } from '../../components';
import { useVkwAssets } from '../../contexts';
import { getVkwTypographyStyle } from '../../helper';
import { useVkwFormatMessage } from '../../hooks';
import { VkwDefaultLayout } from '../../layouts';
import { useVkwTheme, VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    grid: {
      flexDirection: 'row',
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      paddingTop: theme.spacing(6),

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    helpfulTitle: {
      ...getVkwTypographyStyle('h6', theme),
      color: theme.palette.text.primary,
    },
    logo: {
      maxWidth: '115px',
    },
    logoWrapper: {
      paddingLeft: theme.spacing(6),
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },

      width: '100%',
    },
    pageDescription: {
      paddingTop: theme.spacing(3),
      ...getVkwTypographyStyle('text20', theme),
      color: theme.palette.text.primary,
    },
    pageHeader: {
      ...getVkwTypographyStyle('h2', theme),
      color: theme.palette.text.primary,
    },
    pageText: {
      paddingTop: theme.spacing(3),
      ...getVkwTypographyStyle('h6', theme),
      color: theme.palette.text.primary,
      wordBreak: 'break-word',
    },
    preHeaderBlurb: {
      paddingTop: theme.spacing(3),
      ...getVkwTypographyStyle('h2', theme),
      color: theme.palette.text.primary,
    },
    reloadLink: {
      paddingTop: theme.spacing(3),
    },
  }),
  { index: 1 }
);

export interface VkwErrorViewProps {
  /**
   * Fehlercode des angezeigten Fehlers (z.B. 404, 500, etc)
   */
  errorNumber: number;
  /**
   * URL der Seite die geladen wurde als der Fehler aufgetreten ist
   */
  actualUrl: string;
  /**
   * Links, die unter "Diese Seiten könnten hilfreich sein" angezeigt werden
   */
  helpfulLinks?: { title: string; href?: string; onClick?: () => void }[];
  /**
   * Event-Listener für wenn der Refresh Link geklickt wird
   */
  onRefreshLink?: () => void;
}

export const VkwErrorView = ({
  actualUrl,
  errorNumber,
  helpfulLinks,
  onRefreshLink,
}: VkwErrorViewProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const assets = useVkwAssets();
  const theme = useVkwTheme();
  const styles = useStyles();

  const { UsedErrorIcon, additionalHeaderText, errorPageDescription, errorPageHeader, errorPageText } = useMemo(() => {
    let UsedErrorIcon: () => ReactElement;
    let errorPageHeader = '';
    let errorPageText = '';
    let errorPageDescription = '';
    let additionalHeaderText = '';
    switch (errorNumber) {
      case 403:
        UsedErrorIcon = ErrorIcon403;
        errorPageHeader = 'Error403PageHeader';
        errorPageText = 'Error403PageText';
        errorPageDescription = 'Error403PageDescription';
        additionalHeaderText = 'Error403PreHeaderBlurb';
        break;
      case 404:
        UsedErrorIcon = ErrorIcon404;
        errorPageHeader = 'Error404PageHeader';
        errorPageText = 'Error404PageText';
        errorPageDescription = 'Error404PageDescription';
        break;
      case 408:
        UsedErrorIcon = ErrorIcon408;
        errorPageHeader = 'Error408PageHeader';
        errorPageText = 'Error408PageText';
        errorPageDescription = 'Error408PageDescription';
        break;
      case 504:
        UsedErrorIcon = ErrorIcon;
        errorPageHeader = 'Error504PageHeader';
        errorPageText = 'Error504PageText';
        errorPageDescription = 'Error504PageDescription';
        break;
      default:
        UsedErrorIcon = ErrorIcon;
        errorPageHeader = 'ErrorPageHeader';
        errorPageText = 'ErrorPageText';
        errorPageDescription = 'ErrorPageDescription';
        additionalHeaderText = 'ErrorPagePreHeaderBlurb';
        break;
    }

    return { UsedErrorIcon, additionalHeaderText, errorPageDescription, errorPageHeader, errorPageText };
  }, [errorNumber]);

  return (
    <VkwDefaultLayout>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box className={styles.logoWrapper}>
          <img className={styles.logo} src={assets.logo} alt="logo" />
        </Box>
        <Box display="flex" flexDirection="column" maxWidth="750px">
          <Box pt={4} textAlign="center" color={theme.palette.text.secondary}>
            <UsedErrorIcon />
          </Box>
          {additionalHeaderText !== '' && (
            <Typography align="center" className={styles.preHeaderBlurb}>
              {formatMessage(additionalHeaderText)}
            </Typography>
          )}
          <Typography align="center" className={styles.pageHeader}>
            {formatMessage(errorPageHeader, { errorNumber })}
          </Typography>
          <Typography align="center" className={styles.pageText}>
            {formatMessage(errorPageText, { url: actualUrl })}
          </Typography>
          <Typography align="center" className={styles.pageDescription}>
            {formatMessage(errorPageDescription)}
          </Typography>

          {onRefreshLink !== undefined && (
            <Box className={styles.reloadLink} alignSelf="center">
              <VkwCallToAction title={formatMessage('ReloadThisPage')} iconPosition="right" onClick={onRefreshLink} />
            </Box>
          )}
        </Box>
        <Grid container className={styles.grid}>
          <Grid item xs={12} sm={6} />
          <Grid item xs={12} sm={6}>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {helpfulLinks && (
                <Typography className={styles.helpfulTitle}>{formatMessage('ErrorPageHelpfulTitle')}</Typography>
              )}
              {helpfulLinks &&
                helpfulLinks.map(item => {
                  return (
                    <Box key={item.title} pt={3}>
                      <VkwCallToAction
                        title={item.title}
                        href={item.href}
                        iconPosition="right"
                        onClick={item.onClick}
                      />
                    </Box>
                  );
                })}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </VkwDefaultLayout>
  );
};
