import { useTheme } from '@material-ui/core';

import {
  VkwTheme,
  createVkwTheme,
  VkwThemeCustomOptions,
  VkwTypographyVariant,
  VkwThemeCustomOptionsTypography,
} from './createVkwTheme';

export const useVkwTheme = (): VkwTheme => useTheme<VkwTheme>();

export type { VkwTheme, VkwThemeCustomOptions, VkwTypographyVariant, VkwThemeCustomOptionsTypography };
export { createVkwTheme };
