import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconContractOutline = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize <= 16) {
          return (
            <g fill={fill} fillRule="evenodd" transform="scale(1.5)">
              <circle cx="2.75" cy="2.25" r="1.75" />
              <circle cx="13.25" cy="2.25" r="1.75" />
              <path d="M13.5 5c-1.401.13-2.673.874-3.474 2.032-.266.315-.56.606-.879.868.368.43.492 1.016.331 1.558.583-.321 1.1-.752 1.522-1.267V13c0 .552.448 1 1 1h3c.552 0 1-.448 1-1V7.5C16 6.12 14.88 5 13.5 5zM7.8 8.25c-.693 0-1.144-.463-1.823-1.218C5.176 5.874 3.903 5.13 2.5 5 1.12 5 0 6.12 0 7.5V13c0 .552.448 1 1 1h3c.552 0 1-.448 1-1V8.191c.65.916 1.68 1.489 2.8 1.559.414 0 .75-.336.75-.75s-.336-.75-.75-.75z" />
            </g>
          );
        }

        return (
          <g fill={fill}>
            <path
              d="M4 9c1.649 0 2.64.596 4.112 2.087l.38.392c.782.809 1.22 1.174 1.714 1.37l.115.042.947.321-.642 1.894-.947-.321c-.88-.298-1.484-.757-2.42-1.705l-.373-.385C5.644 11.407 5.02 11 4 11a2.001 2.001 0 0 0-1.995 1.85L2 13v8h4v-6h2v8H0V13c0-2.21 1.79-4 4-4zm16 0a4 4 0 0 1 3.995 3.8L24 13v10h-8v-8h2v6h4v-8a2.001 2.001 0 0 0-1.85-1.995L20 11c-.965 0-1.577.366-2.695 1.499l-.36.37c-.984 1.02-1.596 1.527-2.44 1.85l-.184.066-.947.321-.642-1.894.947-.321c.466-.158.858-.443 1.49-1.069l.506-.516C17.268 9.655 18.273 9 20 9zM4.5 1a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7zm15 0a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7zm-15 2a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 4.5 3zm15 0a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 19.5 3z"
              fill={fill}
              fillRule="evenodd"
            />
          </g>
        );
      }}
    </VkwIcon>
  );
};
