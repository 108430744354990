import React, { ReactElement, ReactNode } from 'react';

import { Box, CircularProgress, FormControl, makeStyles } from '@material-ui/core';
import { FormikContextType } from 'formik';

import { VkwButton, VkwTheme, useVkwTheme, useVkwFormatMessage } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  cancelButton: {
    paddingRight: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
      paddingTop: theme.spacing(1),
    },
  },
  formControl: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

interface FormProps {
  submitInProgress?: boolean;
  disableSubmit?: boolean;
  submitButtonText?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  formik?: FormikContextType<any>;
  children: ReactNode;
}

export const Form = ({
  children,
  disableSubmit,
  formik,
  onCancel,
  onSubmit,
  submitButtonText = '',
  submitInProgress,
}: FormProps): ReactElement => {
  const styles = useStyles();
  const theme = useVkwTheme();
  const formatMessage = useVkwFormatMessage();
  return (
    <form
      role="presentation"
      onSubmit={formik?.handleSubmit}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
    >
      <FormControl fullWidth className={styles.formControl}>
        {children}
      </FormControl>
      <Box className={styles.buttons}>
        <Box className={styles.cancelButton}>
          <VkwButton
            variant="contained"
            color="default"
            fullWidth
            onClick={() => {
              if (onCancel) {
                onCancel();
              } else if (formik?.resetForm) {
                formik.resetForm();
              }
            }}
          >
            {formatMessage('Cancel')}
          </VkwButton>
        </Box>
        <div>
          <VkwButton
            variant="contained"
            color={theme.palette.mainButtonColor}
            disabled={disableSubmit || (formik && (formik.isSubmitting || !formik.isValid))}
            fullWidth
            type="submit"
            onClick={
              formik
                ? () => {
                    // do nothing
                  }
                : onSubmit
            }
          >
            {(submitInProgress || formik?.isSubmitting) && (
              <Box marginRight={0.75}>
                <CircularProgress color="inherit" size={14} />
              </Box>
            )}
            {submitButtonText}
          </VkwButton>
        </div>
      </Box>
    </form>
  );
};
