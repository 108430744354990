import React, { ReactElement, ReactNode } from 'react';

import { Box, Menu, MenuItem, makeStyles } from '@material-ui/core';

import { VkwTheme, getVkwTypographyStyle } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  icon: {
    color: theme.palette.grey[500],
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  label: {
    ...getVkwTypographyStyle('text14', theme),
  },
  spacer: {
    borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

interface MoreMenuItem {
  icon?: ReactNode;
  label: string;
  onClick: () => void;
  type: 'item';
}

interface MoreMenuItemSpacer {
  type: 'spacer';
}

export type MoreMenuItems = (MoreMenuItem | MoreMenuItemSpacer)[];

interface MoreMenuProps {
  anchorEl: Element | ((element: Element) => Element);
  onClose: () => void;
  items: MoreMenuItems;
}

export const MoreMenu = ({ anchorEl, items, onClose }: MoreMenuProps): ReactElement => {
  const styles = useStyles();

  return (
    <Menu
      id="more-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      open={Boolean(anchorEl)}
      onClose={onClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {items.map((item, i) => {
        if (item.type === 'spacer') {
          return <div className={styles.spacer} key={i} />;
        }

        return (
          <MenuItem onClick={item.onClick} key={i}>
            <Box display="flex" alignItems="center">
              <span className={styles.icon}>{item.icon}</span>
              <span className={styles.label}>{item.label}</span>
            </Box>
          </MenuItem>
        );
      })}
    </Menu>
  );
};
