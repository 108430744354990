import React, { ReactElement, ReactNode } from 'react';

import { Drawer, makeStyles, Box, Typography, Backdrop } from '@material-ui/core';

import { getVkwTypographyStyle } from '../../helper';
import { VkwIconClose } from '../../icons';
import { VkwTheme } from '../../themes';
import { VkwIconButton } from '../VkwIconButton';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    content: {
      flexGrow: 1,
      overflow: 'auto',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    drawerBackdrop: {
      zIndex: theme.zIndex.drawer - 1,
    },
    drawerPaper: {
      backgroundColor: theme.palette.contentBackground,
      display: 'flex',
      flexDirection: 'column',

      maxHeight: '100vh',
      maxWidth: `calc(100vw - ${theme.navigationDesktopWidthExpanded}px)`,

      [theme.breakpoints.down('xs')]: {
        maxHeight: `calc(100% - ${theme.navigationMobileHeight}px)`,
        maxWidth: '100vw',
        width: '100vw',
      },
    },
    header: {
      alignItems: 'center',
      borderBottom: `2px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
      display: 'flex',
      flexWrap: 'nowrap',

      padding: theme.spacing(2),
    },
    headerText: {
      flexGrow: 1,
      ...getVkwTypographyStyle('h9', theme),
      paddingRight: theme.spacing(1),
    },
  }),
  { index: 1 }
);

export interface VkwDrawerProps {
  headerText: string;
  open: boolean;
  onClose: () => void;
  /**
   * Content des VkwDrawer
   */
  children: ReactNode;
}

export const VkwDrawer = ({ children, headerText, onClose, open }: VkwDrawerProps): ReactElement => {
  const styles = useStyles();

  return (
    <>
      <Drawer
        PaperProps={{ className: styles.drawerPaper }}
        onClose={onClose}
        open={open}
        anchor="right"
        variant="persistent"
      >
        <Box className={styles.header}>
          <Typography color="textPrimary" className={styles.headerText} align="center">
            {headerText}
          </Typography>
          <VkwIconButton onClick={onClose}>
            <VkwIconClose size={24} />
          </VkwIconButton>
        </Box>
        <Box className={styles.content}>{children}</Box>
      </Drawer>
      <Backdrop open={open} onClick={onClose} className={styles.drawerBackdrop} />
    </>
  );
};
