import React, { ReactElement, useState, useEffect, FocusEvent } from 'react';

import { makeStyles } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { findAll } from 'highlight-words-core';
import { useDebounce } from 'use-debounce';

import { getVkwTypographyStyle } from '../../helper';
import { useVkwFormatMessage } from '../../hooks';
import { VkwTheme } from '../../themes';
import { VkwTextField } from '../VkwTextField';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    clearIndicator: {
      marginRight: theme.spacing(5),
    },
    optionsChunk: {
      color: theme.palette.text.secondary,
    },
    optionsChunkHighlight: {
      ...getVkwTypographyStyle('h9', theme),
      color: theme.palette.text.primary,
    },
    paper: {
      boxShadow: theme.shadows[8],
    },
  }),
  { index: 1 }
);

export type VkwAutocompleteAdditionalOption = {
  optionAdditionalEntry: string;
  valueAdditionalEntry: string;
  isLoadingOption?: boolean;
};

export interface VkwAutocompleteFreeSoloProps<T> {
  /**
   * Name des Eingabefeldes -> Notwendig als Referenz z.B. für Formik etc.
   */
  name: string;
  /**
   * Beschriftung des Eingabefeldes für den User
   */
  label: string;
  value: T | VkwAutocompleteAdditionalOption | null;
  setValue: (value: VkwAutocompleteAdditionalOption | T | null) => void;
  /**
   * Methode um dem Autocomplete die Auswahloptionen bereitzustellen (Dies ist auch abhängig von der Eingabe des Users möglich, kann aber auch intern erledigt werden -> `filterInOptions`)
   */
  getOptions: (searchString: string) => Promise<T[]>;
  fullWidth?: boolean;
  /**
   * Error status des Eingabefeldes aktivieren z.B. bei einem Validierungsfehler
   */
  error?: boolean;
  /**
   * Hilfstext für den User wenn `error` gesetzt wird
   */
  helperText?: string;
  /**
   * Überschreiben des Textes der dargestellt werden soll, wenn keine Optionen verfügbar sind.
   */
  noOptionsText?: string;
  /**
   * Überschreiben des Textes der dargestellt werden soll, während die Daten geladen werden.
   */
  loadingText?: string;
  /**
   * Soll das Autocomplete je nach Eingabe des Users selbstständig die möglichen Optionen filtern?
   */
  filterInOptions?: boolean;
  /**
   * Sind die möglichen Options ein Objekt, kann über diese Methode dem Autocomplete übergeben werden, welches Feld des Objekts den String des Users beinhaltet
   */
  getOptionLabel?: (option: T) => string;
  /**
   * Methode um die einzelnen möglichen Optionen im Dropdown in ihrer Darstellung anzupassen
   */
  renderOption?: (option: T) => string;
  /**
   * Wenn die Optionen Objekte sind, kann über diese Methode ein Vergleicher übergeben werden bei dem entschieden wird ob eine Option selektiert wird oder nicht
   */
  getOptionSelected?: (option: T, value: T) => boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  /**
   * Besteht beim Freitext eine Längenbegrenzung, kann diese hier angegeben werden. Der User bekommt dann eine dementsprechende Rückmeldung.
   */
  maxLength?: number;
}

interface AutocompleteFreeSoloState<T> {
  items: T[];
  loading: boolean;
}

// eslint-disable-next-line react/function-component-definition
export function VkwAutocompleteFreeSolo<T>({
  error,
  filterInOptions,
  fullWidth,
  getOptionLabel,
  getOptionSelected,
  getOptions,
  helperText,
  label,
  loadingText,
  maxLength,
  name,
  noOptionsText,
  onBlur,
  renderOption,
  setValue,
  value,
}: VkwAutocompleteFreeSoloProps<T>): ReactElement {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();

  type InternalOption = T | VkwAutocompleteAdditionalOption;
  const [options, setOptions] = useState<AutocompleteFreeSoloState<InternalOption>>({
    items: [],
    loading: false,
  });
  const [inputValue, setInputValue] = useState<string>('');
  const [searchString] = useDebounce(inputValue, 250);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const filter = createFilterOptions<InternalOption>();

  useEffect(() => {
    let active = true;
    if (dialogOpen) {
      setOptions({
        items: [],
        loading: true,
      });
      getOptions(searchString).then(options => {
        if (!active) {
          return;
        }
        setOptions({
          items: options,
          loading: false,
        });
      });
    }

    return () => {
      active = false;
    };
  }, [searchString, getOptions]);

  return (
    <Autocomplete
      classes={{ clearIndicator: error ? styles.clearIndicator : undefined, paper: styles.paper }}
      options={options.items}
      value={value}
      inputValue={inputValue}
      onInputChange={(_event, newValue) => {
        if (maxLength && newValue.length > maxLength) {
          return;
        }

        setInputValue(newValue);
      }}
      onChange={(event, newValue) => {
        if (event.type === 'keydown' && typeof newValue === 'string') {
          const newValueTrim = newValue.trim();

          if (newValueTrim === '') {
            setValue(null);

            return;
          }

          const additionalEntry = {
            optionAdditionalEntry: formatMessage('AutocompleteAdd', {
              value: newValueTrim,
            }),
            valueAdditionalEntry: newValueTrim,
          };

          setValue(additionalEntry);

          return;
        }

        setValue(newValue as any);
      }}
      renderInput={params => (
        <VkwTextField
          name={name}
          label={label}
          error={error}
          helperText={helperText}
          maxLength={maxLength}
          actualLength={inputValue.length}
          onBlur={onBlur}
          {...params}
        />
      )}
      fullWidth={fullWidth}
      selectOnFocus
      clearOnBlur
      freeSolo
      handleHomeEndKeys
      loading={options.loading}
      getOptionLabel={option => {
        const optionTemp = option as VkwAutocompleteAdditionalOption;

        if (optionTemp.valueAdditionalEntry) {
          return optionTemp.valueAdditionalEntry;
        }

        if (typeof option === 'string') {
          return option;
        }

        if (getOptionLabel) {
          return getOptionLabel(option as T);
        }

        throw new Error('Object options need "getOptionLabel" provided');
      }}
      filterOptions={(filteroptions, params) => {
        const filtered = filterInOptions ? filter(filteroptions, params) : filteroptions;

        const inputValue = params.inputValue.trim();

        if (inputValue !== '') {
          filtered.push({
            optionAdditionalEntry: formatMessage('AutocompleteAdd', {
              value: inputValue,
            }),
            valueAdditionalEntry: inputValue,
          });
        }

        if (options.loading) {
          filtered.unshift({
            isLoadingOption: true,
            optionAdditionalEntry: loadingText ?? `${formatMessage('Loading')}...`,
            valueAdditionalEntry: '',
          });
        }

        return filtered;
      }}
      getOptionDisabled={option => (option as VkwAutocompleteAdditionalOption).isLoadingOption ?? false}
      renderOption={option => {
        let optionString: string;

        const optionTemp = option as VkwAutocompleteAdditionalOption;

        if (optionTemp.optionAdditionalEntry) {
          optionString = optionTemp.optionAdditionalEntry;

          return <div key={optionString}>{optionString}</div>;
        }

        if (renderOption) {
          optionString = renderOption(option as T);
        } else if (typeof option === 'string') {
          optionString = option;
        } else {
          throw new Error('Object options need "renderOption" provided');
        }

        const chunks = findAll({
          autoEscape: true,
          caseSensitive: false,
          searchWords: inputValue.trim().split(' '),
          textToHighlight: optionString,
        });

        return (
          <div key={optionString}>
            {chunks.map((chunk, index) => {
              const { end, highlight, start } = chunk;
              const text = optionString.substr(start, end - start);

              if (highlight) {
                return (
                  <span key={index} className={styles.optionsChunkHighlight}>
                    {text}
                  </span>
                );
              }

              return (
                <span key={index} className={styles.optionsChunk}>
                  {text}
                </span>
              );
            })}
          </div>
        );
      }}
      getOptionSelected={(option, value) => {
        const optionTemp = option as VkwAutocompleteAdditionalOption;
        const valueTemp = value as VkwAutocompleteAdditionalOption;
        if (valueTemp.valueAdditionalEntry !== undefined) {
          if (optionTemp.valueAdditionalEntry !== undefined) {
            return valueTemp.valueAdditionalEntry === optionTemp.valueAdditionalEntry;
          }

          if (typeof option === 'string') {
            return valueTemp.valueAdditionalEntry === option;
          }
        }

        if (typeof optionTemp.valueAdditionalEntry === 'string' && typeof value === 'string') {
          return optionTemp.valueAdditionalEntry === value;
        }

        if (getOptionSelected) {
          return getOptionSelected(option as T, value as T);
        }

        if (typeof option !== 'string' || typeof value !== 'string') {
          throw new Error('Object options need "getOptionSelected" provided');
        }

        return option === value;
      }}
      noOptionsText={noOptionsText ?? formatMessage('NoOptions')}
      loadingText={loadingText ?? `${formatMessage('Loading')}...`}
      onOpen={() => {
        setDialogOpen(true);
      }}
      onClose={() => {
        setDialogOpen(false);
      }}
    />
  );
}
