import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Box, Hidden, Typography, makeStyles } from '@material-ui/core';
import Axios from 'axios';

import { TicketRow } from './TicketRow';
import { useHandleAxiosResponse } from '../../hooks';
import { VkwAccordion, VkwLoader, VkwTheme, getVkwTypographyStyle, useVkwFormatMessage } from '../../library';
import { TicketResponse } from '../../views';

const useStyles = makeStyles<VkwTheme>(theme => ({
  header: {
    ...getVkwTypographyStyle('h8', theme),
    color: theme.palette.text.primary,
  },
  noResultRow: {
    alignItems: 'center',
    display: 'flex',
    height: '65px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
    },

    width: '100%',
  },
  noResultText: {
    ...getVkwTypographyStyle('link14', theme),
    color: theme.palette.text.primary,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      height: '274px',
      paddingBottom: theme.spacing(5),
    },

    width: '100%',
  },
}));

interface TicketOverviewCcaSectionProps {
  type: 'CustomerId' | 'EvseId' | 'AuthenticationCardUid';
  searchString?: string;
  onRowClick?: (data: TicketResponse) => void;
}

export const TicketOverviewCcaSection = ({
  onRowClick,
  searchString,
  type,
}: TicketOverviewCcaSectionProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();
  const handleAxiosResponse = useHandleAxiosResponse();

  const [tickets, setTickets] = useState<TicketResponse[] | null>(null);

  useEffect(() => {
    let active = true;

    setTickets(null);

    if (searchString) {
      handleAxiosResponse(
        () =>
          Axios.get('/api-secured/v1/TicketsSecured/CallCenterAgent', {
            params: {
              filter: searchString,
              limit: 3,
              type,
            },
          }),
        {
          success: response => {
            if (active) {
              setTickets(response.data);
            }
          },
        }
      );
    }

    return () => {
      active = false;
    };
  }, [type, searchString]);

  const renderSectionContent = (): ReactNode => {
    return (
      <>
        {searchString && !tickets && <VkwLoader />}
        {searchString && tickets && tickets.length <= 0 ? (
          <Box className={styles.noResultRow}>
            <Typography className={styles.noResultText}>{formatMessage('NoResult')}</Typography>
          </Box>
        ) : undefined}
        {tickets?.map((item, index) => (
          <TicketRow key={index} data={item} onClick={onRowClick} />
        ))}
      </>
    );
  };

  return (
    <>
      <Hidden implementation="css" xsDown>
        <Box className={styles.section} sx={{ display: { xl: 'block', xs: 'none' } }}>
          <Box pb={2}>
            <Typography className={styles.header}>{formatMessage(`TicketsFrom${type}`)}</Typography>
          </Box>
          {renderSectionContent()}
        </Box>
      </Hidden>

      <Hidden implementation="css" smUp>
        <VkwAccordion title={formatMessage(`TicketsFrom${type}`)}>
          <Box className={styles.section}>{renderSectionContent()}</Box>
        </VkwAccordion>
      </Hidden>
    </>
  );
};
