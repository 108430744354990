import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPrivacy = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize <= 12) {
          return (
            <path
              fill={fill}
              fillRule="evenodd"
              transform="scale(2)"
              d="M6.332 0h-.164l-4.5.75c-.241.04-.418.249-.418.493v6c0 2.762 2.239 5 5 5s5-2.238 5-5v-6c0-.244-.177-.453-.418-.493L6.332 0zM6.25 1l4 .667v5.576c0 2.21-1.79 4-4 4l-.2-.005c-2.116-.104-3.8-1.853-3.8-3.995V1.666l4-.666z"
            />
          );
        }
        if (fontSize <= 16) {
          return (
            <path
              fill={fill}
              fillRule="evenodd"
              transform="scale(1.5)"
              d="M7.791.001l.082.01 6 1c.362.06.627.372.627.739v8c0 3.728-3.022 6.75-6.75 6.75S1 13.478 1 9.75v-8c0-.367.265-.68.627-.74l6-1c.081-.013.165-.013.246 0zM7.75 1.51l-5.25.875V9.75c0 2.827 2.234 5.132 5.034 5.246L7.75 15c2.9 0 5.25-2.35 5.25-5.25V2.385L7.75 1.51zm0 1.49c1.463 0 2.658 1.142 2.745 2.582l.005.168V7h.25c.38 0 .693.282.743.648l.007.102v4c0 .414-.336.75-.75.75h-6c-.414 0-.75-.336-.75-.75v-4c0-.414.336-.75.75-.75H5V5.75C5 4.231 6.231 3 7.75 3zM10 8.5H5.5V11H10V8.5zm-2.25-4c-.647 0-1.18.492-1.244 1.122L6.5 5.75V7H9V5.75c0-.69-.56-1.25-1.25-1.25z"
            />
          );
        }

        return (
          <g fill={fill} stroke={fill} strokeLinecap="square" strokeWidth={strokewidth}>
            <rect height="5" width="8" fill="none" x="8" y="12" />
            <path d="M10,12V9a2.006,2.006,0,0,1,2-2h0a2.006,2.006,0,0,1,2,2v3" fill="none" />
            <path d="M21,14A9,9,0,0,1,3,14V4l9-2,9,2Z" fill="none" stroke={fill} />
          </g>
        );
      }}
    </VkwIcon>
  );
};
