import { useState, useEffect } from 'react';

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const useVkwIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setIsMobile(window.navigator.userAgent.includes('Mobi'));
  }, [setIsMobile, isMobile]);

  return isMobile;
};
