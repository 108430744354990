import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconFilter = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize <= 16) {
          return (
            <path
              d="M15,13 C17.2386515,13 19.1336803,14.4712239 19.7709542,16.4995396 L21.5882353,16.5 C22.3679314,16.5 23,17.1715729 23,18 C23,18.7692538 22.4550021,19.4032607 21.7528769,19.4899084 L21.5882353,19.5 L19.7712708,19.4994525 C19.134308,21.5282887 17.2390223,23 15,23 C12.2385763,23 10,20.7614237 10,18 C10,15.2385763 12.2385763,13 15,13 Z M15,15.5 C13.6192881,15.5 12.5,16.6192881 12.5,18 C12.5,19.3807119 13.6192881,20.5 15,20.5 C16.3807119,20.5 17.5,19.3807119 17.5,18 C17.5,16.6192881 16.3807119,15.5 15,15.5 Z M7.58823529,16.5 C8.36793141,16.5 9,17.1715729 9,18 C9,18.7692538 8.45500208,19.4032607 7.75287688,19.4899084 L7.58823529,19.5 L2.41176471,19.5 C1.63206859,19.5 1,18.8284271 1,18 C1,17.2307462 1.54499792,16.5967393 2.24712312,16.5100916 L2.41176471,16.5 L7.58823529,16.5 Z M9,1 C11.7614237,1 14,3.23857625 14,6 C14,8.76142375 11.7614237,11 9,11 C6.76097771,11 4.865692,9.52828873 4.22872923,7.49945254 L2.41176471,7.5 C1.63206859,7.5 1,6.82842712 1,6 C1,5.23074624 1.54499792,4.59673926 2.24712312,4.5100916 L2.41176471,4.5 L4.22904576,4.49953964 C4.86631969,2.47122391 6.76134847,1 9,1 Z M8.85294118,3.5 C7.4722293,3.5 6.35294118,4.61928813 6.35294118,6 C6.35294118,7.38071187 7.4722293,8.5 8.85294118,8.5 C10.2336531,8.5 11.3529412,7.38071187 11.3529412,6 C11.3529412,4.61928813 10.2336531,3.5 8.85294118,3.5 Z M21.5882353,4.5 C22.3679314,4.5 23,5.17157288 23,6 C23,6.76925376 22.4550021,7.40326074 21.7528769,7.4899084 L21.5882353,7.5 L16.4117647,7.5 C15.6320686,7.5 15,6.82842712 15,6 C15,5.23074624 15.5449979,4.59673926 16.2471231,4.5100916 L16.4117647,4.5 L21.5882353,4.5 Z"
              fillRule="nonzero"
            />
          );
        }

        return (
          <g fill={fill} stroke={fill} strokeLinecap="square" strokeWidth={strokewidth}>
            <line fill="none" stroke={fill} x1="14" x2="23" y1="4" y2="4" />
            <line fill="none" stroke={fill} x1="1" x2="4" y1="4" y2="4" />
            <line fill="none" x1="22" x2="23" y1="12" y2="12" />
            <line fill="none" x1="1" x2="12" y1="12" y2="12" />
            <line fill="none" stroke={fill} x1="14" x2="23" y1="20" y2="20" />
            <line fill="none" stroke={fill} x1="1" x2="4" y1="20" y2="20" />
            <circle cx="7" cy="4" fill="none" r="3" stroke={fill} />
            <circle cx="15" cy="12" fill="none" r="3" />
            <circle cx="7" cy="20" fill="none" r="3" stroke={fill} />
          </g>
        );
      }}
    </VkwIcon>
  );
};
