import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconCardsOutline = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize <= 16) {
          return (
            <g fill={fill}>
              <rect height="17" width="14" fill={fill} rx="1" ry="1" />
              <path d="M22.859,7.168,16.3,5.393l-.522,1.931,5.6,1.514L17.979,21.386,6.395,18.255l-.521,1.931,12.55,3.393a1,1,0,0,0,1.226-.705L23.563,8.394A1,1,0,0,0,22.859,7.168Z" />
            </g>
          );
        }

        return (
          <g fill={fill} stroke={fill} strokeLinecap="square" strokeWidth={strokewidth}>
            <rect height="15" width="12" fill="none" stroke={fill} x="1" y="1" />
            <polyline fill="none" points="17.001 6.619 22.598 8.132 18.684 22.613 7.099 19.481" />
          </g>
        );
      }}
    </VkwIcon>
  );
};
