import React, { ReactElement } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import {
  useVkwFormatMessage,
  VkwDateIoDateType,
  VkwIconButton,
  VkwLoader,
  VkwTheme,
  getVkwTypographyStyle,
  dateDisplay,
  VkwIconRefresh,
  vkwDateIo,
} from '../../library';
import { StatisticTimespanSelector } from '../StatisticTimespanSelector';

export const calculateTimespanTextElements = (
  min: Date,
  max: Date,
  values: Date[]
): { dateFrom: string; dateTo: string; days: number } => {
  const value0 = values.length >= 1 ? values[0] : min;
  const value1 = values.length >= 2 ? values[1] : max;

  return {
    dateFrom: dateDisplay(value0),
    dateTo: dateDisplay(value1),
    days: Math.abs(vkwDateIo.date(value0).diff(value1, 'days')) + 1, // Add a day because of beginning day 00:00 and end day 23:59
  };
};

const useStyles = makeStyles<VkwTheme>(theme => ({
  cache: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  text: {
    ...getVkwTypographyStyle('text14', theme),

    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  timespan: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

interface DataCacheStateProps {
  timespanValues: Date[];
  minTimespanValue: Date;
  maxTimespanValue: Date;
  changeTimespan: (value: Date[]) => void;
  cacheLocked?: boolean;
  cacheLastUpdate?: VkwDateIoDateType | null;
  updateCache: () => void;
}

export const TimespanCacheEdit = ({
  cacheLastUpdate,
  cacheLocked,
  changeTimespan,
  maxTimespanValue,
  minTimespanValue,
  timespanValues,
  updateCache,
}: DataCacheStateProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();

  return (
    <Box className={styles.root}>
      <Box className={styles.timespan}>
        <StatisticTimespanSelector
          values={timespanValues}
          onChange={changeTimespan}
          min={minTimespanValue}
          max={maxTimespanValue}
        />
        <Box pb={1} />
        <Typography className={styles.text}>
          {formatMessage(
            'StatisticTimespanLabel',
            calculateTimespanTextElements(minTimespanValue, maxTimespanValue, timespanValues)
          )}
        </Typography>
      </Box>
      <Box pb={1} />
      <Box className={styles.cache}>
        {cacheLocked && (
          <Box pr={1}>
            <VkwLoader />
          </Box>
        )}
        <Typography className={styles.text}>
          {cacheLocked
            ? formatMessage('MoreDataLoading')
            : formatMessage('LastDataLoading', {
                date: cacheLastUpdate?.format?.('LT [-] L'),
              })}
        </Typography>
        <Box pr={1} />
        <VkwIconButton disabled={cacheLocked} onClick={updateCache}>
          <VkwIconRefresh size={24} />
        </VkwIconButton>
      </Box>
    </Box>
  );
};
