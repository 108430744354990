import defaultColors, { DefaultColors } from '../../defaultColors';

const vkwColors: DefaultColors = {
  ...defaultColors,
  primary: {
    50: '#276EC8',
    100: '#2468BE',
    200: '#205CA8',
    300: '#1C5093',
    400: '#18457D',
    500: '#143968',
    600: '#113057',
    700: '#0D2646',
    800: '#0A1D35',
    900: '#071324',
    950: '#050F1B',
    dark: '#18457D',
    default: '#143968',
    light: '#1C5093',
  },
  secondary: {
    50: '#FC8E94',
    100: '#FC7C84',
    200: '#FB5963',
    300: '#FA3742',
    400: '#F91421',
    500: '#E30613',
    600: '#C50511',
    700: '#A7040E',
    800: '#8A040C',
    900: '#6C0309',
    950: '#5D0208',
    default: '#E30613',
  },
};

vkwColors.navigation = {
  active: vkwColors.primary['500'],
  'active-button': vkwColors.primary['500'],
  'active-button-dark': vkwColors.primary['500'],
  'active-dark': vkwColors.white,
};

vkwColors['charge-card'] = {
  dark: {
    from: vkwColors.primary['500'],
    to: vkwColors.primary['700'],
  },
  light: {
    from: vkwColors.primary['100'],
    to: vkwColors.primary['300'],
  },
};

vkwColors['action-button'] = {
  active: vkwColors.primary['300'],
  'active-dark': vkwColors.primary['400'],
  default: vkwColors.primary['200'],
  'default-dark': vkwColors.primary['300'],
  disabled: vkwColors.neutral['600'],
  'disabled-dark': vkwColors.neutral['600'],
  loading: vkwColors.primary['400'],
  'loading-dark': vkwColors.primary['500'],
  text: vkwColors.white,
  'text-dark': vkwColors.white,
};

vkwColors['action-text'] = {
  active: vkwColors.primary['200'],
  'active-dark': vkwColors.primary['200'],
  default: vkwColors.primary['100'],
  'default-dark': vkwColors.primary['100'],
  disabled: vkwColors.neutral['600'],
  'disabled-dark': vkwColors.neutral['600'],
  loading: vkwColors.primary['300'],
  'loading-dark': vkwColors.primary['300'],
};

export default vkwColors;
