import React, { forwardRef, useCallback, useEffect, useState, ChangeEvent } from 'react';

import { Box, TextField, TextFieldProps, Typography } from '@material-ui/core';
import { useDebouncedCallback } from 'use-debounce';

import { useStyles } from './VkwTextFieldStyles';
import { VkwIconError } from '../../icons';

const VALUE_UPDATE_DELAY = 400;

export interface VkwTextFieldProps {
  /**
   * Maximallänge des Textes übergeben um den Text in der Länge zu begrenzen und einen Zeichenzähler anzuzeigen.
   */
  maxLength?: number;
  /**
   * Übergabe der aktuellen länge des Textes -> Nur für Spezialfälle notwendig, wie z.B. VkwAutocompleteFreeSolo
   */
  actualLength?: number;
}

// eslint-disable-next-line react/display-name
export const VkwTextField = forwardRef<HTMLInputElement, TextFieldProps & VkwTextFieldProps>(
  (
    {
      InputLabelProps,
      InputProps,
      actualLength,
      error,
      helperText,
      maxLength,
      multiline,
      onBlur,
      onChange,
      value,
      variant,
      ...rest
    },
    ref
  ) => {
    const styles = useStyles({ multiline });
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
      if (typeof value === 'undefined' || value === null) {
        setInputValue('');
      } else {
        setInputValue(value as string);
      }
    }, [value]);

    const debouncedHandleOnChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
      onChange?.(event);
    }, VALUE_UPDATE_DELAY);

    const handleOnChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
      event.persist();
      const newValue = event.currentTarget.value;
      setInputValue(newValue);
      debouncedHandleOnChange(event);
    }, []);

    return (
      <Box className={styles.wrapper}>
        <TextField
          InputProps={{
            ...InputProps,
            classes: { input: styles.inputRootInput, root: styles.inputRoot },
          }}
          inputProps={{ maxLength }}
          InputLabelProps={{
            ...InputLabelProps,
            className: styles.label,
            classes: { root: styles.labelRoot },
          }}
          variant={variant ?? 'filled'}
          multiline={multiline}
          error={error}
          value={inputValue}
          onChange={handleOnChange}
          onBlur={event => {
            debouncedHandleOnChange.flush();
            onBlur?.(event);
          }}
          helperText={error ? helperText : undefined}
          FormHelperTextProps={{ className: styles.errorMessage }}
          ref={ref}
          {...rest}
        />
        {!error && maxLength && (typeof inputValue === 'string' || typeof actualLength !== 'undefined') && (
          <Box className={styles.maxLength}>
            <Typography className={styles.maxLengthText}>{`${
              actualLength ?? inputValue.length
            }/${maxLength}`}</Typography>
          </Box>
        )}
        {error && (
          <Box className={styles.errorIcon}>
            <VkwIconError size={16} />
          </Box>
        )}
      </Box>
    );
  }
);
