import React, { ReactElement, useCallback } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { Ticket } from './const';
import { TicketStatusBadge } from '../../components';
import { VkwTheme, dateDisplay, getVkwTypographyStyle } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  date: {
    ...getVkwTypographyStyle('text12', theme),
    paddingBottom: theme.spacing(1),
  },
  description: {
    ...getVkwTypographyStyle('text14', theme),
  },
  icon: {
    ...getVkwTypographyStyle('text12', theme),
    background: theme.palette.grey[500],
    borderRadius: '50%',
    color: theme.palette.getContrastText(theme.palette.grey[500]),
    height: '32px',
    lineHeight: '32px',
    textAlign: 'center',
    width: '32px',
  },
  title: {
    ...getVkwTypographyStyle('h8', theme),
  },
}));

interface TicketMobileItemProps {
  ticket: Ticket;
  onClick: (ticket: Ticket) => void;
}

export const TicketMobileItem = ({ onClick, ticket }: TicketMobileItemProps): ReactElement => {
  const styles = useStyles();
  const handleOnClick = useCallback(() => onClick(ticket), [ticket, onClick]);
  const description =
    ticket.description && ticket.description.length > 20 ? (
      <>{ticket.description.slice(0, 19)}&hellip;</>
    ) : (
      ticket.description
    );

  return (
    <Box display="flex" alignItems="center" paddingY={2} onClick={handleOnClick} role="button">
      <div>
        <div className={styles.icon}>{ticket.ticketUser ? ticket.ticketUser.slice(0, 1).toUpperCase() : 'U'}</div>
      </div>
      <Box paddingX={2} flexGrow={1}>
        <div className={styles.title}>{ticket.subject}</div>
        <div className={styles.description}>{description}</div>
      </Box>
      <Box display="flex" flexDirection="column" textAlign="right">
        <div className={styles.date}>{ticket.creationDate ? dateDisplay(ticket.creationDate) : '-'}</div>
        {ticket.status && (
          <div>
            <TicketStatusBadge status={ticket.status} />
          </div>
        )}
      </Box>
    </Box>
  );
};
