import React, { ReactElement } from 'react';

import { Box, Tooltip, makeStyles } from '@material-ui/core';

import { ChargeDetailRecord } from '../../hooks';
import { VkwIconUsers, VkwBaseTableCell, VkwTheme, useVkwFormatMessage } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  sharedIconWrapper: {
    color: theme.palette.grey[500],
  },
}));

interface ChargingLocationTableCellProps {
  dataEntry: ChargeDetailRecord;
}

export const ChargingLocationTableCell = ({ dataEntry }: ChargingLocationTableCellProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();

  return (
    <VkwBaseTableCell>
      <Box pr={dataEntry.isShared ? 2 : 0}>{dataEntry.chargingPointLocation}</Box>
      {dataEntry.isShared && (
        <Tooltip
          title={formatMessage('SharedFrom', { sharingUsers: dataEntry.sharingUsers.join('; ') })}
          arrow
          placement="bottom"
        >
          <Box className={styles.sharedIconWrapper}>
            <VkwIconUsers size={16} />
          </Box>
        </Tooltip>
      )}
    </VkwBaseTableCell>
  );
};
