import React, { ReactElement } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { FormattedDate } from 'react-intl';

import { VkwTheme, getVkwTypographyStyle } from '../../library';
import { TicketResponse } from '../../views';
import { TicketStatusBadge } from '../TicketStatusBadge';

const useStyles = makeStyles<VkwTheme, { clickable: boolean }>(theme => ({
  date: {
    ...getVkwTypographyStyle('text14', theme),
    color: theme.palette.text.primary,
  },
  row: props => {
    let cssProps = {};

    if (props.clickable) {
      cssProps = {
        '&:active': {
          backgroundColor: theme.palette.action.active,
        },

        '&:hover': {
          backgroundColor: theme.palette.action.hover,
          cursor: 'pointer',
        },
      };
    }

    return {
      ...cssProps,

      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
      display: 'flex',
      height: '65px',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        '&:last-child': {
          border: 'none',
        },
      },

      width: '100%',
    };
  },
  subject: {
    ...getVkwTypographyStyle('link14', theme),
    color: theme.palette.text.primary,
  },
}));

interface TicketRowProps {
  data: TicketResponse;
  onClick?: (data: TicketResponse) => void;
}

export const TicketRow = ({ data, onClick }: TicketRowProps): ReactElement => {
  const styles = useStyles({ clickable: !!onClick });

  return (
    <Box className={styles.row} onClick={() => onClick?.(data)}>
      <Box minWidth="60px">{data.status && <TicketStatusBadge status={data.status} />}</Box>
      <Box flexGrow="1" paddingRight={1}>
        <Typography className={styles.date}>
          {data.creationDate && (
            <FormattedDate
              value={data.creationDate}
              year="numeric"
              month="2-digit"
              day="2-digit"
              hour="2-digit"
              minute="2-digit"
            />
          )}
        </Typography>
        <Typography className={styles.subject}>{data.subject}</Typography>
      </Box>
    </Box>
  );
};
