import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconUnlock = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 16 16">
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="key">
            <g>
              <path
                d="M12.3954976,8.8817842e-16 L6.85026296,5.54523465 C4.50944267,4.85307068 1.99736175,5.8373203 0.749792238,7.93543872 C-0.497777276,10.0335571 -0.162809809,12.7107001 1.56324502,14.436755 C3.28929986,16.1628098 5.96644287,16.4977773 8.06456128,15.2502078 C10.1626797,14.0026382 11.1469293,11.4905573 10.4547653,9.14973704 L12.0053538,7.59914856 L12.0053538,5.32619489 L14.2783075,5.32619489 L16,3.60450239 L16,8.8817842e-16 L12.3954976,8.8817842e-16 Z M5.34761023,12.6497129 C4.24451915,12.6497129 3.35028714,11.7554809 3.35028714,10.6523898 C3.35028714,9.5492987 4.24451915,8.65506669 5.34761023,8.65506669 C6.4507013,8.65506669 7.34493331,9.5492987 7.34493331,10.6523898 C7.34493331,11.7554809 6.4507013,12.6497129 5.34761023,12.6497129 Z"
                id="Shape"
                fill={fill}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </svg>
    </VkwIcon>
  );
};
