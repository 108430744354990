import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Badge, Box, ButtonBase, CircularProgress, Fab, Hidden, makeStyles, useScrollTrigger } from '@material-ui/core';
import _ from 'lodash';

import { mergeSettingsForDialog, mergeSettingsForTable } from './mergeSettings';
import { SettingsDialog } from './SettingsDialog';
import { SupportedLocale, useOptionalVkwNavigation, useVkwIntl } from '../../contexts';
import { dateDisplay, dateTimeDisplay, timeConvert } from '../../helper';
import { useVkwFormatMessage, useVkwIsSmallestBreakpoint } from '../../hooks';
import { VkwIconFilter, VkwIconMore, VkwIconSort, VkwIconSortDown, VkwIconSortUp } from '../../icons';
import { VkwTheme } from '../../themes';
import {
  VkwBaseTable,
  VkwBaseTableCell,
  VkwBaseTableFooter,
  VkwBaseTableHeadCell,
  VkwBaseTableHeadRow,
  VkwBaseTableRow,
  VkwBaseTableToolbar,
} from '../VkwBaseTable';
import { VkwFilterOption, VkwFilters } from '../VkwFilters';
import { VkwIconButton } from '../VkwIconButton';
import { VkwLoader } from '../VkwLoader';
import { VkwSortableListItemConfig } from '../VkwSortableList';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    fabButton: {
      '&& g': {
        fill: '#111111',
      },
      bottom: theme.spacing(10),
      color: theme.palette.grey[100],
      position: 'fixed',
      right: theme.spacing(3),
      zIndex: theme.zIndex.speedDial,
    },
    spinner: {
      display: 'flex',
      height: 40,
      justifyContent: 'center',
      margin: theme.spacing(3),
    },
  }),
  { index: 1 }
);

type Converter = 'date' | 'dateTime' | 'time' | 'localeNumber';

const convertValue = (converter: Converter, value: unknown, locale: SupportedLocale): ReactNode => {
  switch (converter) {
    case 'date':
      if (!(value instanceof Date)) {
        throw new Error('Date expected');
      }

      return dateDisplay(value);
    case 'dateTime':
      if (!(value instanceof Date)) {
        throw new Error('Date expected');
      }

      return dateTimeDisplay(value as Date);
    case 'time':
      if (typeof value !== 'number') {
        throw new Error('number expected');
      }

      return timeConvert(value);
    case 'localeNumber':
      if (typeof value !== 'number') {
        throw new Error('number expected');
      }

      return value.toLocaleString(locale);
    default:
      throw new Error(`Unknown converter ${converter} given`);
  }
};

export interface VkwRowAction {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  icon: ReactNode;
}

export interface VkwDataTableFieldConfig<T> {
  property?: string;
  label?: string;
  fixed?: boolean;
  active?: boolean;
  converter?: Converter;
  renderCustomCell?: (dataEntry: T) => ReactNode;
  sortColumn?: string;
  bold?: boolean;
  hidden?: boolean;
}

export interface VkwDataTableSettingsProvider {
  settings: VkwSortableListItemConfig[];
  updateSettings: (settings: VkwSortableListItemConfig[]) => void;
}

export interface VkwDataTableProps<T> {
  /**
   * Erste Initialisierung der Tabellendaten abgeschlossen
   */
  initialized: boolean;
  /**
   * Dies muss gesetzt werden immer wenn Daten z.B. vom Server geladen werden (Seite wird gewechselt, Infinity Scroll nachladen, Filter wurde gesetzt etc.)
   */
  loading: boolean;
  /**
   * Wird gesetzt sobald die erste Seite fertig geladen wurde und wird erst zurückgesetzt wenn sich z.B. ein Filter ändert usw.
   */
  loadAdditionalEntries: boolean;
  page: number;
  pageSize: number;
  pages: number;
  data: T[];
  totalRecords: number;
  /**
   * Sind noch mehr Einträge vorhanden? Für Infinity Scrolling wichtig damit die Logik weiß ob nochmal nachgeladen werden muss
   */
  hasMore: boolean;
  /**
   * Wird eine neue Seite vom Grid angefordert, wird diese Methode aufgerufen
   */
  setPage: (page: number) => void;
  /**
   * Wenn sich die Anforderung, ob Desktop oder Mobileansicht, ändert wird diese Methode aufgerufen
   */
  setIsMobile: (isMobile: boolean) => void;
  /**
   * Text wenn keine Einträge vorhanden sind aber kein Filter aktiv ist oder `noResultsWithActiveFilterDisplayText` nicht gesetzt ist
   */
  noResultsDisplayText: string;
  /**
   * Übergabe der Konfiguration für die Tabellenfelder
   */
  tableFieldsConfig: VkwDataTableFieldConfig<T>[];
  /**
   * Render Prop um das Render Element für die mobile Ansicht dynamisch zu übergeben
   */
  renderMobileItem?: (dataEntry: T, index: number) => ReactNode;
  /**
   * Render Prop um zusätzliche Buttons in die "Toolbar actions" zu rendern
   */
  renderButtons?: () => ReactNode;
  /**
   * Eventhandler, der aktiviert wird wenn auf eine Zeile geklickt wird
   */
  onRowClick?: (dataEntry: T, event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  /**
   * Braucht die Tabellenzeile z.B. ein "More" Menü, so kann dies über dieses Render Prop übergeben werden
   */
  getRowActions?: (dataEntry: T) => VkwRowAction[];
  /**
   * Soll in jeder Tabellenzeile an erster Position ein Icon dargestellt werden, dann wird es hier übergeben
   */
  renderIcon?: (dataEntry: T) => ReactNode;
  /**
   * Die Tabelle kann wenn dieses Prop übergeben wird, dem User ein Settings Dialog bereitstellen. In diesem Dialog kann der User Spalten ein-, ausblenden und umsortieren.
   */
  settingsProvider?: VkwDataTableSettingsProvider;
  /**
   * Aktive Filter
   */
  filters?: Map<string, string[] | number[] | Date[]>;
  /**
   * Verfügbare Filtermöglichkeiten
   */
  filterOptions?: VkwFilterOption[];
  /**
   * Wenn Änderungen an Filtern vorgenommen werden, wird diese Methode aufgerufen
   */
  onChangeFilter?: (name: string, value: string[] | number[] | Date[]) => void;
  /**
   * Methode, um alle Filter zurück zu setzen
   */
  onClearFilters?: () => void;
  /**
   * Text wenn mit den aktuellen Filtern keine Einträge vorhanden sind.
   */
  noResultsWithActiveFilterDisplayText?: string;
  /**
   * Wieviele verschiedene Filter werden von Anfang an angezeigt
   */
  defaultFiltersCount?: number;
  defaultTableFieldsConfig?: VkwDataTableFieldConfig<T>[];
  /**
   * In welcher Spalte wird sortiert. String ident zum jeweiligen string in der VkwTabelFieldsConfig
   */
  sortColumn?: string;
  sortDirection?: 'asc' | 'desc';
  setSortColumn?: (sortColumn: string) => void;
}

// eslint-disable-next-line react/function-component-definition
export function VkwDataTable<T>({
  data,
  defaultFiltersCount = 5,
  defaultTableFieldsConfig,
  filterOptions,
  filters,
  getRowActions,
  hasMore,
  initialized,
  loadAdditionalEntries,
  loading,
  noResultsDisplayText,
  noResultsWithActiveFilterDisplayText,
  onChangeFilter,
  onClearFilters,
  onRowClick,
  page,
  pageSize,
  pages,
  renderButtons,
  renderIcon,
  renderMobileItem,
  setIsMobile,
  setPage,
  setSortColumn,
  settingsProvider,
  sortColumn,
  sortDirection,
  tableFieldsConfig,
  totalRecords,
}: VkwDataTableProps<T>): ReactElement {
  const formatMessage = useVkwFormatMessage();
  const styles = useStyles();
  const navigation = useOptionalVkwNavigation();
  const intl = useVkwIntl();

  const scrollTarget = navigation?.mainElement ?? undefined;

  const backToTopVisible = useScrollTrigger({
    disableHysteresis: true,
    target: scrollTarget,
  });

  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);

  const observer = useRef<IntersectionObserver>();

  const isMobile = useVkwIsSmallestBreakpoint();

  const activeFilterCount = (): number => {
    if (filterOptions && filterOptions.length > 0 && filters) {
      const actualFilterColumns = filterOptions
        .filter(filterOption => filterOption.hidden !== true)
        .map(filterOption => filterOption.column);

      return Array.from(filters.keys()).reduce<number>(
        (accumulator, filterKey) => (actualFilterColumns.includes(filterKey) ? accumulator + 1 : accumulator),
        0
      );
    }
    return 0;
  };

  useEffect(() => {
    setIsMobile(isMobile);
  }, [isMobile]);

  const columnCount = useMemo(() => {
    let { length } = tableFieldsConfig;

    if (getRowActions) {
      length++;
    }

    if (renderIcon) {
      length++;
    }

    const hiddenFieldsLength = tableFieldsConfig.filter(tableFieldConfig => tableFieldConfig.hidden === true).length;

    return length - hiddenFieldsLength;
  }, [getRowActions, renderIcon, tableFieldsConfig.length]);

  const lastPageElementRef = useCallback(
    (node: any) => {
      if (loading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(page + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, hasMore]
  );

  const getEmptyMessage = (): string => {
    if (activeFilterCount() > 0 && noResultsWithActiveFilterDisplayText !== undefined) {
      return noResultsWithActiveFilterDisplayText;
    }

    return noResultsDisplayText;
  };

  const renderRows = (fieldsConfig: VkwDataTableFieldConfig<T>[]): ReactElement[] => {
    const rows: ReactElement[] = [];

    if (loading) {
      rows.push(
        <VkwBaseTableRow key="loader">
          <VkwBaseTableCell colSpan={columnCount}>
            <VkwLoader />
          </VkwBaseTableCell>
        </VkwBaseTableRow>
      );
    } else if (totalRecords === 0) {
      rows.push(
        <VkwBaseTableRow key="empty">
          <VkwBaseTableCell colSpan={columnCount}>{getEmptyMessage()}</VkwBaseTableCell>
        </VkwBaseTableRow>
      );
    } else {
      data.forEach((dataEntry, rowIndex) => {
        if (!dataEntry) {
          return;
        }
        rows.push(
          <VkwBaseTableRow
            key={rowIndex}
            onClick={
              onRowClick
                ? (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => onRowClick?.(dataEntry, event)
                : undefined
            }
            ref={isMobile && data.length === rowIndex + 1 ? lastPageElementRef : undefined}
          >
            {renderIcon && (
              <VkwBaseTableCell key="icon" width="40">
                {renderIcon(dataEntry)}
              </VkwBaseTableCell>
            )}
            {fieldsConfig.map((tableCellConfig, cellIndex) => {
              if (tableCellConfig.hidden) {
                return null;
              }

              if (tableCellConfig.renderCustomCell) {
                return tableCellConfig.renderCustomCell(dataEntry);
              }

              if (!tableCellConfig.property) {
                throw Error('No property given');
              }

              const value = _.get(dataEntry as Record<string, unknown>, tableCellConfig.property);

              return (
                <VkwBaseTableCell key={cellIndex} bold={tableCellConfig.bold}>
                  {tableCellConfig.converter
                    ? convertValue(tableCellConfig.converter, value, intl.locale)
                    : (value as ReactNode)}
                </VkwBaseTableCell>
              );
            })}
            {getRowActions && (
              <VkwBaseTableCell width="40">
                {getRowActions(dataEntry).map((rowAction, i) => (
                  <VkwIconButton key={i} onClick={rowAction.onClick}>
                    {rowAction.icon}
                  </VkwIconButton>
                ))}
              </VkwBaseTableCell>
            )}
          </VkwBaseTableRow>
        );
      });
    }

    let emptyRowCount = 0;

    while (rows.length < pageSize) {
      emptyRowCount++;

      rows.push(
        <VkwBaseTableRow key={`empty-row-${emptyRowCount}`}>
          <VkwBaseTableCell colSpan={columnCount}>&nbsp;</VkwBaseTableCell>
        </VkwBaseTableRow>
      );
    }

    return rows;
  };

  const renderSortIcon = (newSortColumn: string | undefined): ReactElement | null => {
    if (!sortColumn || !newSortColumn) {
      return null;
    }
    const icon =
      newSortColumn !== sortColumn ? (
        <VkwIconSort size={16} />
      ) : sortDirection === 'desc' ? (
        <VkwIconSortDown size={16} />
      ) : (
        <VkwIconSortUp size={16} />
      );

    return <Box paddingLeft={1}>{icon}</Box>;
  };

  const renderHeaderCell = (key: number | string, label: string | undefined, sortColumn?: string): ReactElement => {
    if (!label) {
      return <VkwBaseTableHeadCell key={key} />;
    }

    const onClick = sortColumn && setSortColumn ? () => setSortColumn(sortColumn) : undefined;

    const headerCellContent = (
      <>
        <div>{label}</div>
        {renderSortIcon(sortColumn)}
      </>
    );

    return (
      <VkwBaseTableHeadCell key={key}>
        {onClick ? <ButtonBase onClick={onClick}>{headerCellContent}</ButtonBase> : headerCellContent}
      </VkwBaseTableHeadCell>
    );
  };

  const renderHeader = (fieldsConfig: VkwDataTableFieldConfig<T>[]): ReactElement => (
    <VkwBaseTableHeadRow>
      {renderIcon ? renderHeaderCell('icon', undefined) : null}
      {fieldsConfig.map(
        (tableCellConfig, index) =>
          !tableCellConfig.hidden && renderHeaderCell(index, tableCellConfig.label, tableCellConfig.sortColumn)
      )}
      {getRowActions ? renderHeaderCell('actions', undefined) : null}
    </VkwBaseTableHeadRow>
  );

  const renderFooter = (): ReactNode => {
    if (pages > 1) {
      return (
        <Hidden xsDown implementation="css">
          <VkwBaseTableFooter page={page} onPageChange={setPage} count={pages} />
        </Hidden>
      );
    }

    return null;
  };

  const renderFilters = (): ReactElement | null => {
    if (!filters || !filterOptions || filterOptions.length <= 0 || !onChangeFilter || !onClearFilters) {
      return null;
    }

    return (
      <Box marginBottom={2}>
        <VkwFilters
          onChangeFilter={onChangeFilter}
          filters={filters}
          filterOptions={filterOptions}
          onClearFilters={onClearFilters}
          defaultFiltersCount={defaultFiltersCount}
          showMobileFilter={showMobileFilter}
          onMobileFilterClose={() => setShowMobileFilter(false)}
        />
      </Box>
    );
  };

  const renderFilterButton = (): ReactElement | null => {
    if (!filters || !filterOptions || filterOptions.length <= 0 || !onChangeFilter || !onClearFilters) {
      return null;
    }

    return (
      <Hidden implementation="css" smUp>
        <VkwIconButton onClick={() => setShowMobileFilter(true)} title={formatMessage('Filter')}>
          <Badge badgeContent={activeFilterCount()} color="primary">
            <VkwIconFilter />
          </Badge>
        </VkwIconButton>
      </Hidden>
    );
  };

  const renderSettingsButton = (): ReactElement | null => {
    if (!settingsProvider) {
      return null;
    }

    const button = (
      <VkwIconButton title={formatMessage('Settings')} onClick={() => setShowSettingsDialog(true)}>
        <VkwIconMore />
      </VkwIconButton>
    );

    if (!renderMobileItem) {
      return button;
    }

    return (
      <Hidden implementation="css" xsDown>
        {button}
      </Hidden>
    );
  };

  const renderToolbarActions = (): ReactElement => (
    <>
      {renderButtons?.()}
      {renderFilterButton()}
      {renderSettingsButton()}
    </>
  );

  const renderToolbarTitle = (): string => {
    if (loading && !loadAdditionalEntries) {
      return '';
    }

    return formatMessage('Entries', { value: totalRecords });
  };

  const renderToolbar = (): ReactElement => {
    return (
      <VkwBaseTableToolbar
        numSelected={0}
        count={0}
        renderToolbarTitle={renderToolbarTitle}
        renderToolbarActions={renderToolbarActions}
      />
    );
  };

  const renderMobile = (): ReactNode => {
    if (!renderMobileItem) {
      return null;
    }

    if (loading && !loadAdditionalEntries) {
      return <VkwLoader key="loader" />;
    }

    if (totalRecords === 0) {
      return getEmptyMessage();
    }

    return (
      <>
        {data.map((dataEntry, i) => {
          if (data.length === i + 1) {
            return (
              <div ref={lastPageElementRef} key={i}>
                {renderMobileItem(dataEntry, i)}
              </div>
            );
          }

          return <div key={i}>{renderMobileItem(dataEntry, i)}</div>;
        })}
      </>
    );
  };

  const fieldsConfig = mergeSettingsForTable(
    tableFieldsConfig,
    settingsProvider ? settingsProvider.settings : undefined
  );

  if (!initialized) {
    return <VkwLoader />;
  }

  return (
    <>
      {settingsProvider && showSettingsDialog && (
        <SettingsDialog
          settingsProvider={settingsProvider}
          initialItems={mergeSettingsForDialog(tableFieldsConfig, settingsProvider.settings)}
          defaultSettings={defaultTableFieldsConfig ? mergeSettingsForDialog(defaultTableFieldsConfig, []) : undefined}
          onClose={() => setShowSettingsDialog(false)}
        />
      )}
      {renderFilters()}
      <VkwBaseTable
        renderHeader={() => renderHeader(fieldsConfig)}
        renderFooter={renderFooter}
        renderToolbar={renderToolbar}
        renderMobile={renderMobileItem ? renderMobile : undefined}
      >
        {renderRows(fieldsConfig)}
      </VkwBaseTable>

      <Hidden smUp implementation="css">
        {hasMore && <Box className={styles.spinner}>{loading && loadAdditionalEntries && <CircularProgress />}</Box>}

        {backToTopVisible && (
          <Fab
            size="medium"
            className={styles.fabButton}
            aria-label="scroll-to-top"
            onClick={() => {
              if (scrollTarget) {
                scrollTarget.scrollTo({ behavior: 'smooth', top: 0 });

                return;
              }

              window.scrollTo({ behavior: 'smooth', top: 0 });
            }}
          >
            <VkwIconSortUp size={48} />
          </Fab>
        )}
      </Hidden>
    </>
  );
}
