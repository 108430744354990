import React, { ReactNode } from 'react';

import { useSnackbar, VariantType } from 'notistack';

import { VkwIconButton } from '../components/VkwIconButton';
import { VkwIconClose } from '../icons';

export const useVkwSnackbar = (): ((message: ReactNode, variant: VariantType, persist?: boolean) => void) => {
  try {
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();

    const renderButton = (key: string | number | undefined): ReactNode => (
      <VkwIconButton
        color="inherit"
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <VkwIconClose size={20} />
      </VkwIconButton>
    );

    return (message, variant, persist = false): void => {
      enqueueSnackbar(message, {
        action: renderButton,
        persist,
        variant,
      });
    };
  } catch {
    throw new Error('Use "VkwSnackbarProvider" somewhere before you use "useVkwSnackbar"');
  }
};
