import { ChargeSessionStatus } from '@prisma/client';
import { z } from 'zod';

const ChargingSessionStatusSchema = z.nativeEnum(ChargeSessionStatus);

const ChargingSessionInfrastructureSchema = z.object({
  consumedEnergy: z.number().optional(),
  currentPower: z.number().optional(),
  emobilityServiceProviderIdentifier: z.string().optional(),
  emobilityServiceProviderName: z.string().optional(),
  evseId: z.string(),
  lastUpdatedAt: z.string(),
  meterValueEnd: z.number().optional(),
  meterValueStart: z.number().optional(),
  operatorIdentifier: z.string(),
  operatorName: z.string(),
  roamingSessionId: z.string().optional(),
  sessionEnd: z.string().optional(),
  sessionId: z.string(),
  sessionStart: z.string().optional(),
  stateOfCharge: z.number().optional(),
  status: ChargingSessionStatusSchema,
  tenantKey: z.string(),
});

export type InfrastructureChargingSession = z.infer<typeof ChargingSessionInfrastructureSchema>;
