import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconWarning = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = '#000';

  return (
    <VkwIcon size={size}>
      <g fill={fill} fillRule="evenodd">
        <path
          fill={fill}
          d="M 22.824219 20.292969 L 13.324219 2.480469 C 12.757812 1.421875 11.242188 1.421875 10.675781 2.480469 L 1.175781 20.292969 C 0.644531 21.292969 1.367188 22.5 2.5 22.5 L 21.5 22.5 C 22.632812 22.5 23.355469 21.292969 22.824219 20.292969 Z M 22.824219 20.292969 "
        />
        <path
          fill={secondaryfill}
          d="M 12 16.5 C 12.828125 16.5 13.5 17.171875 13.5 18 C 13.5 18.828125 12.828125 19.5 12 19.5 C 11.171875 19.5 10.5 18.828125 10.5 18 C 10.5 17.171875 11.171875 16.5 12 16.5 Z M 13.5 9 L 13.5 15 L 10.5 15 L 10.5 9 Z M 13.5 9 "
        />
      </g>
    </VkwIcon>
  );
};
