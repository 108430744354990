import { SupportedLocale } from '@e-vo/localization';
import { getLocales } from 'expo-localization';
import { useColorScheme as nativewindUseColorScheme } from 'nativewind';
import { ReactNode, createContext, useState, useEffect, useContext } from 'react';
import { ColorSchemeName, Appearance } from 'react-native';

import { useStores } from '../../models/helpers/useStores';

interface UserSettingsContextProps {
  colorScheme: ColorSchemeName;
  darkMode: boolean;
  isSystemColorScheme: boolean;
  setColorScheme: (newTheme: ColorSchemeName) => void;
  useSystemColorScheme: () => void;
  locale: SupportedLocale;
  isSystemLocale: boolean;
  setUserLocale: (newLocale: SupportedLocale) => void;
  useSystemLocale: () => void;
}

export const UserSettingsContext = createContext<UserSettingsContextProps>({
  colorScheme: 'light',
  darkMode: false,
  isSystemColorScheme: false,
  isSystemLocale: false,
  locale: 'en',
  setColorScheme: () => {
    return null;
  },
  setUserLocale: () => {
    return null;
  },
  useSystemColorScheme: () => {
    return null;
  },
  useSystemLocale: () => {
    return null;
  },
});

interface UserSettingsProviderProps {
  children: ReactNode;
}

export const UserSettingsProvider = ({ children }: UserSettingsProviderProps) => {
  const {
    settingsStore: { isSystemLocale: isSystemLocaleStore, isSystemTheme, locale, setLocale, setTheme, theme },
  } = useStores();

  const defaultColorScheme: ColorSchemeName = 'light';
  const { setColorScheme: setNativewindColorScheme } = nativewindUseColorScheme();
  const systemColorScheme = Appearance.getColorScheme();
  const systemLocale = getLocales()[0].languageCode as SupportedLocale;
  const [currentColorScheme, setCurrentColorScheme] = useState<ColorSchemeName>(
    (theme as unknown as ColorSchemeName) ?? systemColorScheme ?? defaultColorScheme
  );
  const [isSystemColorScheme, setIsSystemColorScheme] = useState<boolean>(isSystemTheme ?? systemColorScheme === theme);
  const [currentLocale, setCurrentLocale] = useState<SupportedLocale>((systemLocale as SupportedLocale) || 'de');
  const [isSystemLocale, setIsSystemLocale] = useState<boolean>(isSystemLocaleStore ?? systemLocale === locale);

  useEffect(() => {
    setCurrentColorScheme(
      isSystemColorScheme ? systemColorScheme : (theme as ColorSchemeName) ?? systemColorScheme ?? defaultColorScheme
    );
    setCurrentLocale(isSystemLocale ? systemLocale : (locale as SupportedLocale) ?? systemLocale ?? 'en');
  }, []);

  useEffect(() => {
    if (systemColorScheme && isSystemColorScheme) {
      setNativewindColorScheme(systemColorScheme);
      setCurrentColorScheme(systemColorScheme);
      setTheme(systemColorScheme ?? defaultColorScheme, true);
    }
  }, [systemColorScheme, isSystemColorScheme]);

  useEffect(() => {
    setNativewindColorScheme(currentColorScheme ?? defaultColorScheme);
  }, [currentColorScheme]);

  const setColorScheme = (newTheme: ColorSchemeName): void => {
    setCurrentColorScheme(newTheme);

    setTheme(newTheme ?? systemColorScheme ?? defaultColorScheme);
    Appearance.setColorScheme(newTheme);
    setIsSystemColorScheme(false);
  };

  const useSystemColorScheme = () => {
    setCurrentColorScheme(systemColorScheme);

    setTheme(systemColorScheme ?? defaultColorScheme, true);
    setIsSystemColorScheme(true);
  };

  const setUserLocale = (newLocale: SupportedLocale): void => {
    setCurrentLocale(newLocale);

    setLocale(newLocale);
    setIsSystemLocale(false);
  };

  const useSystemLocale = () => {
    setCurrentLocale(systemLocale as SupportedLocale);

    setLocale(systemLocale, true);
    setIsSystemLocale(true);
  };

  return (
    <UserSettingsContext.Provider
      value={{
        colorScheme: currentColorScheme,
        darkMode: currentColorScheme === 'dark',
        isSystemColorScheme,
        isSystemLocale,
        locale: currentLocale,
        setColorScheme,
        setUserLocale,
        useSystemColorScheme,
        useSystemLocale,
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export const useUserSettingsContext = (): UserSettingsContextProps => {
  const userSettings = useContext(UserSettingsContext);

  if (!userSettings) {
    throw new Error('useUserSettingsContext must be used within a UserSettingsProvider');
  }

  return userSettings;
};
