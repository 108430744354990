import { CacheStatusResponse } from './getDataCacheState';
import { DataCacheState } from './UserContext';
import { vkwDateIo } from '../../library';

export const processDataCacheState = (axiosResponseData: CacheStatusResponse): DataCacheState => {
  const dataCacheState: DataCacheState = {
    chargingTariffChargeDetailRecordsLastUpdate: null,
    chargingTariffChargeDetailRecordsLocked: false,
    infrastructureChargeDetailRecordsLastUpdate: null,
    infrastructureChargeDetailRecordsLocked: false,
  };

  axiosResponseData.chargingTariffChargeDetailRecordsFullData.forEach(element => {
    if (element.status !== 'warm') {
      dataCacheState.chargingTariffChargeDetailRecordsLocked = true;
    }
  });

  axiosResponseData.chargingTariffChargeDetailRecordsPreload.forEach(element => {
    if (element.status !== 'warm') {
      dataCacheState.chargingTariffChargeDetailRecordsLocked = true;
    }

    if (
      element.lastUpdate &&
      (!dataCacheState.chargingTariffChargeDetailRecordsLastUpdate ||
        dataCacheState.chargingTariffChargeDetailRecordsLastUpdate.isAfter(element.lastUpdate))
    ) {
      dataCacheState.chargingTariffChargeDetailRecordsLastUpdate = vkwDateIo.date(element.lastUpdate);
    }
  });

  axiosResponseData.infrastructureChargeDetailRecordsFullData.forEach(element => {
    if (element.status !== 'warm') {
      dataCacheState.infrastructureChargeDetailRecordsLocked = true;
    }
  });

  axiosResponseData.infrastructureChargeDetailRecordsPreload.forEach(element => {
    if (element.status !== 'warm') {
      dataCacheState.infrastructureChargeDetailRecordsLocked = true;
    }

    if (
      element.lastUpdate &&
      (!dataCacheState.infrastructureChargeDetailRecordsLastUpdate ||
        dataCacheState.infrastructureChargeDetailRecordsLastUpdate.isAfter(element.lastUpdate))
    ) {
      dataCacheState.infrastructureChargeDetailRecordsLastUpdate = vkwDateIo.date(element.lastUpdate);
    }
  });

  return dataCacheState;
};
