import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconSortUp = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill}>
        <path d="M12 4h.02c.023 0 .046.002.07.004L12 4c.05 0 .1.004.149.011l.052.01a.762.762 0 0 1 .065.015l.046.014.06.021.051.023.061.03.037.022a1.2 1.2 0 0 1 .074.05l.018.014c.007.005.01.007.012.01l.082.073 4 4a1 1 0 0 1-1.32 1.497l-.094-.083L13 7.414V20a1 1 0 0 1-1.993.117L11 20V7.414L8.707 9.707a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1-.083-1.32l.083-.094 4-4 .082-.073.008-.007-.09.08a.927.927 0 0 1 .112-.097c.024-.018.049-.034.074-.05l.037-.021c.02-.011.04-.022.06-.031l.053-.023.06-.021.045-.014.065-.016.053-.009.058-.007.072-.004H12z" />
      </g>
    </VkwIcon>
  );
};
