import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { Badge, Box, Hidden, Toolbar } from '@material-ui/core';
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';

import { getAxisStyles, getGraphStyles, useStyles } from './InfrastructureChargingStationsPerformanceDetailStyles';
import {
  calculateTimespanTextElements,
  InfrastructureChargeStationsPerformance,
  InfrastructureChargingStationsPerformanceType,
  StatisticTimespanSelector,
} from '../../components';
import { InfrastructureChargeStationDataStore, useInfrastructureChargeStationDataStore } from '../../hooks';
import {
  vkwDateIo,
  timeConvert,
  useVkwFormatMessage,
  useVkwTheme,
  VkwFilters,
  VkwIconButton,
  VkwIconFilter,
  toFirstUppercase,
} from '../../library';

interface ChartDatapoint {
  x: Date;
  y: number;
}

const activeFilterCount = (dataGridProvider: InfrastructureChargeStationDataStore): number => {
  const actualFilterColumns = dataGridProvider.filterOptions.map(filterOption => filterOption.column);

  return Array.from(dataGridProvider.filters.keys()).reduce<number>(
    (accumulator, filterKey) => (actualFilterColumns.includes(filterKey) ? accumulator + 1 : accumulator),
    0
  );
};

const getChartRawArray = (startDate: Date, endDate: Date): ChartDatapoint[] => {
  const result: ChartDatapoint[] = [];

  for (let dt = new Date(startDate); dt < endDate; dt.setDate(dt.getDate() + 1)) {
    result.push({ x: new Date(dt), y: 0 });
  }

  return result;
};

const getUnitByType = (type: InfrastructureChargingStationsPerformanceType): string => {
  if (type === 'totalDurationInHours') {
    return 'h';
  }
  if (type === 'totalCo2Saving') {
    return 'kg';
  }
  if (type === 'totalConsumedEnergy') {
    return 'kWh';
  }
  return '';
};

const formatValueByType = (value: number, type: InfrastructureChargingStationsPerformanceType): string => {
  if (type === 'totalDurationInHours') {
    return timeConvert(value * 3600);
  }
  if (type === 'totalCo2Saving' || type === 'totalConsumedEnergy') {
    return `${value.toLocaleString('DE-at', { maximumFractionDigits: 2, minimumFractionDigits: 2 })} ${getUnitByType(
      type
    )}`;
  }

  return `${Math.round(value)}`;
};

export interface InfrastructureChargingStationsPerformanceDetailProps {
  type: InfrastructureChargingStationsPerformanceType;
}

export const InfrastructureChargingStationsPerformanceDetail: React.FC<
  InfrastructureChargingStationsPerformanceDetailProps
> = ({ type }) => {
  const store = useInfrastructureChargeStationDataStore({ url: '/api/v1/InfrastructureChargeStations' });
  const [showMobileFilter, setShowMobileFilter] = useState(false);
  const formatMessage = useVkwFormatMessage();
  const theme = useVkwTheme();
  const styles = useStyles();
  const [width, setWidth] = useState<number>(500);

  const updateScreenSize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenSize);
    setWidth(window.innerWidth);
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, []);

  const renderFilterButton = (): ReactNode => {
    return (
      <Hidden implementation="css" smUp>
        <VkwIconButton onClick={() => setShowMobileFilter(true)} title={formatMessage('Filter')}>
          <Badge badgeContent={activeFilterCount(store)} color="primary">
            <VkwIconFilter />
          </Badge>
        </VkwIconButton>
      </Hidden>
    );
  };

  const filterOption = store.filterOptions.find(item => item.column === 'chargeDateRange');

  let minPossibleDate = new Date();
  let maxPossibleDate = new Date();
  let values: Date[] = [];

  if (filterOption) {
    minPossibleDate = filterOption.configuration.min ? (filterOption.configuration.min as Date) : new Date();
    maxPossibleDate = filterOption.configuration.max ? (filterOption.configuration.max as Date) : new Date();
    values = (store.filters.get(filterOption.column) as Date[]) ?? [];
  }

  const performanceValues = useMemo<{ chartArray: ChartDatapoint[]; yMax: number }>(() => {
    const chartArray = getChartRawArray(values[0] ?? minPossibleDate, values[1] ?? maxPossibleDate);

    let yMax = 0;
    const data: ChartDatapoint[] = [];

    store.performance.forEach(item => {
      let y = 0;

      if (type === 'totalDurationInHours') {
        y = item.totalDurationInSeconds / 3600;
      } else {
        y = item[type];
      }

      data.push({ x: new Date(item.date), y });

      if (y > yMax) {
        yMax = y;
      }
    });

    for (let i = 0; i < chartArray.length; i++) {
      const foundData = data.find(
        d => d.x.setHours(0, 0, 0, 0).valueOf() === chartArray[i].x.setHours(0, 0, 0, 0).valueOf()
      );

      if (foundData) {
        chartArray[i] = { x: foundData.x, y: foundData.y };
      }
    }

    return {
      chartArray: chartArray.length === 1 ? [chartArray[0], chartArray[0]] : chartArray,
      yMax: yMax ? yMax * 1.2 : 1,
    };
  }, [store.performance, type]);

  return (
    <>
      {filterOption && (
        <Box className={styles.timespanFilter}>
          <StatisticTimespanSelector
            values={values}
            onChange={values => store.changeFilter(filterOption.column, values)}
            min={minPossibleDate}
            max={maxPossibleDate}
          />
        </Box>
      )}
      {store.initialized && store.totalRecords > 0 && store.performance && (
        <InfrastructureChargeStationsPerformance performance={store.performance} active={type} />
      )}
      <Box className={styles.filterBox} paddingTop={3} paddingBottom={3}>
        <VkwFilters
          onChangeFilter={store.changeFilter}
          filters={store.filters}
          filterOptions={store.filterOptions}
          onClearFilters={store.clearFilters}
          defaultFiltersCount={5}
          showMobileFilter={showMobileFilter}
          onMobileFilterClose={() => setShowMobileFilter(false)}
        />
        {renderFilterButton()}
      </Box>

      <Toolbar className={styles.chartTitle}>
        {formatMessage(`${toFirstUppercase(type)}DetailBar`)} (
        {`${formatMessage(
          'StatisticTimespanLabel',
          calculateTimespanTextElements(minPossibleDate, maxPossibleDate, values)
        )}`}
        )
      </Toolbar>

      <div className={styles.chartWrapper}>
        {performanceValues.chartArray.length > 0 ? (
          <VictoryChart
            maxDomain={{ y: performanceValues.yMax }}
            width={width}
            height={600}
            domainPadding={{ x: [0, 30], y: [0, 30] }}
            containerComponent={<VictoryVoronoiContainer />}
          >
            <VictoryAxis
              fixLabelOverlap
              tickFormat={(t: any) => `${vkwDateIo.date(t).format('DD.MM.YYYY')}`}
              style={getAxisStyles(theme)}
            />
            <VictoryAxis
              dependentAxis
              style={getAxisStyles(theme)}
              label={getUnitByType(type)}
              axisLabelComponent={<VictoryLabel y={20} x={40} />}
            />
            <VictoryArea
              data={performanceValues.chartArray}
              style={{ data: getGraphStyles(theme) }}
              animate={{
                duration: 1000,
                onLoad: { duration: 1000 },
              }}
            />
            <VictoryScatter
              data={performanceValues.chartArray}
              samples={25}
              size={7}
              animate={{
                duration: 1000,
                onLoad: { duration: 1000 },
              }}
              // \s*\/\/\s*@ts-expect-error broken vkw library
              style={{
                data: {
                  // \s*\/\/\s*@ts-expect-error broken vkw library
                  fill: ({ active }) =>
                    active
                      ? theme.palette.type === 'dark'
                        ? theme.palette.common.black
                        : theme.palette.common.white
                      : 'transparent',
                  // \s*\/\/\s*@ts-expect-error broken vkw library
                  stroke: ({ active }) => (active ? theme.palette.detailGraphColor : 'transparent'),
                  strokeWidth: 2,
                },
              }}
              labels={({ datum }: { datum: { x: Date; y: number } }) => [
                `${formatValueByType(datum.y, type)}\n`,
                `${vkwDateIo.date(datum.x).format('DD.MM.YYYY')}`,
              ]}
              labelComponent={
                <VictoryTooltip
                  flyoutWidth={95}
                  cornerRadius={5}
                  pointerLength={5}
                  dy={-10}
                  flyoutStyle={{
                    fill: theme.palette.common.white,
                    stroke: theme.palette.type === 'dark' ? theme.palette.grey[200] : theme.palette.grey[700],
                    strokeWidth: 2,
                  }}
                  style={[
                    {
                      fill: theme.palette.common.black,
                      fontFamily: 'Inter',
                      fontSize: 14,
                      fontWeight: 'bold',
                      paddingBottom: 20,
                      textAnchor: 'middle',
                    },
                    {
                      fill: theme.palette.common.black,
                      fontFamily: 'Inter',
                      fontSize: 14,
                      fontWeight: 'normal',
                      textAnchor: 'middle',
                    },
                  ]}
                />
              }
            />
          </VictoryChart>
        ) : (
          <div />
        )}
      </div>
    </>
  );
};
