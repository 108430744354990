import { darken, lighten, createTheme, TypographyStyle, Theme } from '@material-ui/core';
import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints';
import { Palette } from '@material-ui/core/styles/createPalette';
import { Typography } from '@material-ui/core/styles/createTypography';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { applyVkwThemeSettings } from './applyVkwThemeSettings';
import { setTypography } from './setTypography';
import { themeSpacing } from './themeSpacing';

const FONT_SIZE = 14;
const BASE_FONT_SIZE = 16;

const SLIDER_THUMB_SIZE_BIG = 32;
const SLIDER_THUMB_MARGIN_TOP_BIG = -14;
const SLIDER_THUMB_MARGIN_LEFT_BIG = -16;
const SLIDER_THUMB_SIZE_SMALL = 24;
const SLIDER_THUMB_MARGIN_TOP_SMALL = -10;
const SLIDER_THUMB_MARGIN_LEFT_SMALL = -12;

interface ButtonColors {
  background: string;
  text: string;
}

interface TabColors {
  indicator: string;
  text: string;
}

interface GreyPalette {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  A100: string;
  A200: string;
  A400: string;
  A700: string;
}

export type VkwTypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'h8'
  | 'h9'
  | 'h10'
  | 'text20'
  | 'text18'
  | 'text16'
  | 'text14'
  | 'text12'
  | 'text10'
  | 'link18'
  | 'link16'
  | 'link14'
  | 'link10';

export type VkwThemeCustomOptionsTypography = Record<
  VkwTypographyVariant,
  {
    fontFamily: CSSProperties['fontFamily'];
    fontSize: CSSProperties['fontSize'];
    fontWeight: CSSProperties['fontWeight'];
    lineHeight: CSSProperties['lineHeight'];
    letterSpacing?: CSSProperties['letterSpacing'];
  }
>;

export interface VkwThemeCustomOptions {
  project: string;
  type: 'light' | 'dark';
  mainButtonColor: 'primary' | 'secondary';
  mainSliderColor: 'primary' | 'secondary';
  mainCounterColor: string;
  headerColor?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  colorPrimary: string;
  colorSecondary: string;
  errorColor: string;
  warningColor: string;
  successColor: string;
  greyPalette: GreyPalette;
  black: string;
  white: string;
  googleMapsStyles?: google.maps.MapTypeStyle[];
  activeColor: string;
  contentBackground: string;
  menuBackground: string;
  focusVisible: string;
  primaryButton: ButtonColors;
  activeTab: TabColors;
  checkedColorSwitchThumb: string;
  performanceGraphGoodColor: string;
  detailGraphColor: string;
  globalFontFamily: CSSProperties['fontFamily'];
  fontFace: CSSProperties['@font-face'];
  breakpointValues?: BreakpointValues;
  labelTextColor?: string;
}

interface CustomPalette extends Palette {
  activeColor: string;
  contentBackground: string;
  menuBackground: string;
  checkedColorSwitchThumb: string;
  mainButtonColor: 'primary' | 'secondary';
  mainSliderColor: 'primary' | 'secondary';
  mainCounterColor: string;
  headerColor?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  performanceGraphGoodColor: string;
  detailGraphColor: string;
  labelTextColor: string;
}

export type VkwTypography = Typography & Record<VkwTypographyVariant, TypographyStyle>;

export interface VkwTheme extends Theme {
  project: string;
  googleMapsStyles?: google.maps.MapTypeStyle[];
  minWidth: number;
  navigationDesktopWidth: number;
  navigationDesktopWidthExpanded: number;
  navigationMobileHeight: number;
  palette: CustomPalette;
  typography: VkwTypography;
}

const overlayColor = (themeType: 'light' | 'dark' | undefined, factor: number) => {
  return themeType === 'light' ? `rgba(0, 0, 0, ${factor})` : `rgba(255, 255, 255, ${factor})`;
};

export const COLOR_ACTIVE_FACTOR = 0.2;
export const COLOR_HOVER_FACTOR = 0.1;
const COLOR_SELECTED_FACTOR = 0.2;
const COLOR_DISABLED_FACTOR = 0.4;
const HOVER_OPACITY = 0.1;

export const createVkwTheme = (
  customOptions: VkwThemeCustomOptions,
  getTypography: (theme: Theme) => VkwThemeCustomOptionsTypography
): VkwTheme => {
  const COLOR_ACTIVE = overlayColor(customOptions.type, COLOR_ACTIVE_FACTOR);
  const COLOR_HOVER = overlayColor(customOptions.type, COLOR_HOVER_FACTOR);
  const COLOR_SELECTED = overlayColor(customOptions.type, COLOR_SELECTED_FACTOR);
  const COLOR_DISABLED = overlayColor(customOptions.type, COLOR_DISABLED_FACTOR);

  const theme = createTheme({
    breakpoints: {
      values: customOptions.breakpointValues ?? {
        lg: 1280,
        md: 960,
        sm: 900,
        xl: 1920,
        xs: 0,
      },
    },
    overrides: {
      MuiButton: {
        contained: {
          '&:active': {
            backgroundColor:
              customOptions.type === 'light'
                ? darken(customOptions.greyPalette[300], COLOR_ACTIVE_FACTOR)
                : lighten(customOptions.greyPalette[600], COLOR_ACTIVE_FACTOR),
          },

          '&:hover': {
            backgroundColor:
              customOptions.type === 'light'
                ? darken(customOptions.greyPalette[300], COLOR_HOVER_FACTOR)
                : lighten(customOptions.greyPalette[600], COLOR_HOVER_FACTOR),
          },

          backgroundColor:
            customOptions.type === 'light' ? customOptions.greyPalette[300] : customOptions.greyPalette[600],

          color: customOptions.type === 'light' ? customOptions.black : customOptions.white,
        },
        containedPrimary: {
          '&:active': {
            backgroundColor:
              customOptions.type === 'light'
                ? darken(customOptions.colorPrimary, COLOR_ACTIVE_FACTOR)
                : lighten(customOptions.colorPrimary, COLOR_ACTIVE_FACTOR),
          },

          '&:hover': {
            backgroundColor:
              customOptions.type === 'light'
                ? darken(customOptions.colorPrimary, COLOR_HOVER_FACTOR)
                : lighten(customOptions.colorPrimary, COLOR_HOVER_FACTOR),
          },
        },
        containedSecondary: {
          '&:active': {
            backgroundColor:
              customOptions.type === 'light'
                ? darken(customOptions.colorSecondary, COLOR_ACTIVE_FACTOR)
                : lighten(customOptions.colorSecondary, COLOR_ACTIVE_FACTOR),
          },

          '&:hover': {
            backgroundColor:
              customOptions.type === 'light'
                ? darken(customOptions.colorSecondary, COLOR_HOVER_FACTOR)
                : lighten(customOptions.colorSecondary, COLOR_HOVER_FACTOR),
          },
        },
        label: {
          textTransform: 'none',
        },
        root: {
          '&$focusVisible': {
            border: `2px solid ${customOptions.focusVisible}`,
          },
          border: '2px solid transparent',
          fontFamily: customOptions.globalFontFamily,

          fontWeight: 'bold',
        },
      },
      MuiCssBaseline: {
        '@global': {
          '@font-face': customOptions.fontFace,
          body: {
            backgroundColor: customOptions.contentBackground,
            fontFamily: customOptions.globalFontFamily,
          },
        },
      },
      MuiFilledInput: {
        root: {
          '&$focused': {
            border: `2px solid ${customOptions.type === 'light' ? customOptions.colorPrimary : customOptions.white}`,
            margin: '0px',
          },
          '&.Mui-error': {
            border: `2px solid ${customOptions.errorColor}`,
            margin: '0px',
          },
          backgroundColor:
            customOptions.type === 'light' ? customOptions.greyPalette[50] : customOptions.greyPalette[700],
          border: `1px solid ${
            customOptions.type === 'light' ? customOptions.greyPalette[300] : customOptions.greyPalette[700]
          }`,

          borderRadius: '2px',
          margin: '1px',
        },
        underline: {
          '&:after': {
            display: `none`,
          },
          '&:before': {
            display: `none`,
          },
        },
      },
      MuiFormControlLabel: {
        root: {
          marginLeft: '0px !important',
          marginRight: '0px !important',
        },
      },
      MuiIconButton: {
        root: {
          color: customOptions.type === 'light' ? customOptions.greyPalette[700] : customOptions.greyPalette[200],
        },
      },
      MuiInputLabel: {
        root: {
          zIndex: 1,
        },
      },
      MuiMenuItem: {
        root: {
          '&:hover': {
            backgroundColor:
              customOptions.type === 'light' ? customOptions.greyPalette[100] : customOptions.greyPalette[700],
          },
        },
      },
      MuiOutlinedInput: {
        input: {
          backgroundColor:
            customOptions.type === 'light' ? customOptions.greyPalette[50] : customOptions.greyPalette[700],
          borderRadius: '2px',
          height: '100%',
          minHeight: '24px',
          padding: `${themeSpacing(1)}px ${themeSpacing(2)}px`,
        },
        notchedOutline: {
          borderColor: customOptions.type === 'light' ? customOptions.greyPalette[300] : customOptions.greyPalette[700],
        },
        root: {
          '&$focused .MuiOutlinedInput-notchedOutline': {
            borderColor: customOptions.type === 'light' ? customOptions.colorPrimary : customOptions.white,
          },

          minHeight: '40px',
        },
      },
      MuiPopover: {
        paper: {
          backgroundColor: customOptions.type === 'light' ? customOptions.white : customOptions.greyPalette[800],
          borderRadius: '6px',
        },
      },
      MuiRadio: {
        root: {
          '&.MuiRadio-colorPrimary.Mui-checked': {
            color: customOptions.type === 'light' ? customOptions.colorPrimary : customOptions.greyPalette[200],
          },
          color: customOptions.type === 'light' ? customOptions.colorPrimary : customOptions.greyPalette[200],
        },
      },
      MuiSlider: {
        mark: {
          display: 'none',
        },
        rail: {
          borderRadius: 0,
          height: 4,
        },
        root: {
          height: 4,
          marginLeft: 16,
          marginTop: 1,
          width: 'calc(100% - 32px)',
        },
        thumb: {
          '&$disabled': {
            height: SLIDER_THUMB_SIZE_SMALL,
            marginLeft: SLIDER_THUMB_MARGIN_LEFT_SMALL,
            marginTop: SLIDER_THUMB_MARGIN_TOP_SMALL,
            width: SLIDER_THUMB_SIZE_SMALL,
          },
          backgroundColor: customOptions.white,
          border: `2px solid ${customOptions.greyPalette[200]}`,
          height: SLIDER_THUMB_SIZE_SMALL,
          marginLeft: SLIDER_THUMB_MARGIN_LEFT_SMALL,
          marginTop: SLIDER_THUMB_MARGIN_TOP_SMALL,

          width: SLIDER_THUMB_SIZE_SMALL,
        },
        thumbColorPrimary: {
          '&$active': {
            backgroundColor: customOptions.colorPrimary,
            border: `2px solid ${customOptions.colorPrimary}`,
            boxShadow: 'none',
            height: SLIDER_THUMB_SIZE_BIG,
            marginLeft: SLIDER_THUMB_MARGIN_LEFT_BIG,
            marginTop: SLIDER_THUMB_MARGIN_TOP_BIG,
            width: SLIDER_THUMB_SIZE_BIG,
          },

          '&:focus': {
            border: `2px solid ${customOptions.colorPrimary}`,
            boxShadow: 'none',
          },

          '&:hover': {
            boxShadow: 'none',
            height: SLIDER_THUMB_SIZE_BIG,
            marginLeft: SLIDER_THUMB_MARGIN_LEFT_BIG,
            marginTop: SLIDER_THUMB_MARGIN_TOP_BIG,
            width: SLIDER_THUMB_SIZE_BIG,
          },
        },
        thumbColorSecondary: {
          '&$active': {
            backgroundColor: customOptions.colorSecondary,
            border: `2px solid ${customOptions.colorSecondary}`,
            boxShadow: 'none',
            height: SLIDER_THUMB_SIZE_BIG,
            marginLeft: SLIDER_THUMB_MARGIN_LEFT_BIG,
            marginTop: SLIDER_THUMB_MARGIN_TOP_BIG,
            width: SLIDER_THUMB_SIZE_BIG,
          },

          '&:focus': {
            border: `2px solid ${customOptions.colorSecondary}`,
            boxShadow: 'none',
          },

          '&:hover': {
            boxShadow: 'none',
            height: SLIDER_THUMB_SIZE_BIG,
            marginLeft: SLIDER_THUMB_MARGIN_LEFT_BIG,
            marginTop: SLIDER_THUMB_MARGIN_TOP_BIG,
            width: SLIDER_THUMB_SIZE_BIG,
          },
        },
        track: {
          borderRadius: 0,
          height: 4,
        },
      },
      MuiSwitch: {
        colorPrimary: {
          '&&$checked': {
            '&&:hover + $track': {
              backgroundColor:
                customOptions.type === 'light'
                  ? darken(customOptions.colorPrimary, COLOR_HOVER_FACTOR)
                  : lighten(customOptions.colorPrimary, COLOR_HOVER_FACTOR),
            },
          },
        },
        colorSecondary: {
          '&&$checked': {
            '&&:hover + $track': {
              backgroundColor:
                customOptions.type === 'light'
                  ? darken(customOptions.colorSecondary, COLOR_HOVER_FACTOR)
                  : lighten(customOptions.colorSecondary, COLOR_HOVER_FACTOR),
            },
          },
        },
        root: {
          height: 26,
          padding: 0,
          width: 42,
        },
        switchBase: {
          '&&$checked': {
            '&& + $track': {
              opacity: 1,
            },
            color: customOptions.checkedColorSwitchThumb,

            transform: 'translateX(16px)',
          },
          '&&$disabled': {
            color: COLOR_DISABLED,
          },

          '&&:hover + $track': {
            backgroundColor:
              customOptions.type === 'light'
                ? darken(customOptions.greyPalette[500], COLOR_HOVER_FACTOR)
                : lighten(customOptions.greyPalette[500], COLOR_HOVER_FACTOR),
          },

          color: customOptions.white,

          padding: 1,
        },
        thumb: {
          height: 24,
          width: 24,
        },
        track: {
          backgroundColor: customOptions.greyPalette[500],
          borderRadius: 13,
          opacity: 1,
        },
      },
      MuiTab: {
        root: {
          '&:hover': {
            backgroundColor: COLOR_HOVER,
          },
          minWidth: '0px !important',
          paddingLeft: themeSpacing(1.5),
          paddingRight: themeSpacing(1.5),

          textTransform: 'none',
        },
      },
      MuiTabs: {
        root: {
          borderBottom: `1px solid ${
            customOptions.type === 'light' ? customOptions.greyPalette[200] : customOptions.greyPalette[700]
          }`,
        },
      },
      MuiTooltip: {
        arrow: {
          color: customOptions.type === 'light' ? customOptions.black : customOptions.white,
        },
        tooltip: {
          backgroundColor: customOptions.type === 'light' ? customOptions.black : customOptions.white,
          color: customOptions.type === 'light' ? customOptions.white : customOptions.black,
        },
        tooltipPlacementBottom: {
          margin: '7px 0 !important',
        },
      },
    },
    palette: {
      action: {
        active: COLOR_ACTIVE,
        disabled: COLOR_DISABLED,
        hover: COLOR_HOVER,
        hoverOpacity: HOVER_OPACITY,
        selected: COLOR_SELECTED,
      },
      background: {
        default: customOptions.contentBackground,
      },
      common: {
        black: customOptions.black,
        white: customOptions.white,
      },
      error: { main: customOptions.errorColor },
      grey: customOptions.greyPalette,
      primary: {
        main: customOptions.colorPrimary,
      },
      secondary: {
        main: customOptions.colorSecondary,
      },
      success: { main: customOptions.successColor },
      text: {
        primary: customOptions.type === 'light' ? customOptions.greyPalette[700] : customOptions.greyPalette[200],
      },
      type: customOptions.type,
      warning: { main: customOptions.warningColor },
    },
    props: {
      MuiButton: {
        disableElevation: true,
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiOutlinedInput: {
        notched: false,
      },
    },
    shape: {
      borderRadius: 2,
    },
    spacing: themeSpacing,
    typography: {
      fontFamily: customOptions.globalFontFamily,
      fontSize: FONT_SIZE,
      htmlFontSize: BASE_FONT_SIZE,
    },
    zIndex: {
      appBar: 1250,
    },
  });

  const vkwTheme: VkwTheme = {
    ...theme,
    googleMapsStyles: customOptions.googleMapsStyles,
    minWidth: 320,
    navigationDesktopWidth: 88,
    navigationDesktopWidthExpanded: 240,
    navigationMobileHeight: 70,
    palette: {
      ...theme.palette,
      activeColor: customOptions.activeColor,
      checkedColorSwitchThumb: customOptions.checkedColorSwitchThumb,
      contentBackground: customOptions.contentBackground,
      detailGraphColor: customOptions.detailGraphColor,
      headerColor: customOptions.headerColor,
      labelTextColor: customOptions.labelTextColor ? customOptions.labelTextColor : customOptions.colorPrimary,
      mainButtonColor: customOptions.mainButtonColor,
      mainCounterColor: customOptions.mainCounterColor,
      mainSliderColor: customOptions.mainSliderColor,
      menuBackground: customOptions.menuBackground,
      performanceGraphGoodColor: customOptions.performanceGraphGoodColor,
    },
    project: customOptions.project,
    typography: setTypography(getTypography(theme), theme),
  };

  return applyVkwThemeSettings(vkwTheme);
};
