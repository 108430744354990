import React, { ReactElement, ReactNode } from 'react';

import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import Axios, { AxiosResponse } from 'axios';
import { useDebounce } from 'use-debounce';

import { useHandleAxiosResponse } from '../../hooks';
import { useVkwFormatMessage } from '../../library';

interface EvseIdAutocompleteProps {
  onEvseIdChanged: (evseId: string | null) => void;
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
}

export const EvseIdAutocomplete = ({ onEvseIdChanged, renderInput }: EvseIdAutocompleteProps): ReactElement => {
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [searchString] = useDebounce(inputValue, 250);
  const [options, setOptions] = React.useState<string[] | undefined>();
  const formatMessage = useVkwFormatMessage();
  const handleAxiosResponse = useHandleAxiosResponse();

  React.useEffect(() => {
    let active = true;

    if (searchString.length < 1) {
      return undefined;
    }

    setOptions(undefined);
    setLoading(true);

    handleAxiosResponse(
      () =>
        Axios.get('/api/v1/chargeStations/searchEvseId', {
          params: {
            evseId: searchString,
            limit: 5,
          },
        }),
      {
        success: (response: AxiosResponse) => {
          if (active) {
            setOptions(response.data);
            setLoading(false);
          }
        },
      }
    );

    return () => {
      active = false;
    };
  }, [searchString]);

  return (
    <Autocomplete
      onInputChange={(_event, value) => {
        setInputValue(value);
      }}
      options={options ?? []}
      loading={loading}
      onChange={(_event: unknown, value: string | null) => {
        onEvseIdChanged(value);
      }}
      renderInput={renderInput}
      noOptionsText={options !== undefined ? formatMessage('NoOptions') : <>{formatMessage('StartTyping')}&hellip;</>}
      loadingText={<>{formatMessage('Loading')}&hellip;</>}
    />
  );
};
