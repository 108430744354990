import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconSupportOutline = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize <= 16) {
          return (
            <path
              fill={fill}
              fillRule="evenodd"
              transform="scale(1.5)"
              d="M6 0C2.692 0 0 2.692 0 6v3.75C0 10.99 1.01 12 2.25 12H3c.415 0 .75-.336.75-.75V7.5c0-.414-.335-.75-.75-.75H1.5V6c0-2.482 2.018-4.5 4.5-4.5s4.5 2.018 4.5 4.5v.75H9c-.415 0-.75.336-.75.75v3.75c0 .414.335.75.75.75h.75C10.99 12 12 10.99 12 9.75V6c0-3.308-2.692-6-6-6z"
            />
          );
        }

        return (
          <g fill={fill} stroke={fill} strokeLinecap="square" strokeWidth={strokewidth}>
            <path d="M14,23h5 c1.657,0,3-1.343,3-3v-3" fill="none" />

            <path d="M7,12H2v5 c0,1.105,0.895,2,2,2h3V12z" fill="none" stroke={fill} />

            <path d="M22,12h-5v7h3 c1.105,0,2-0.895,2-2V12z" fill="none" stroke={fill} />

            <path d="M22,12v-1 c0-5.523-4.477-10-10-10h0C6.477,1,2,5.477,2,11v1" fill="none" stroke={fill} />
          </g>
        );
      }}
    </VkwIcon>
  );
};
