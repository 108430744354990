import React, { ReactElement } from 'react';

import { Box, Tooltip } from '@material-ui/core';

import { useVkwFormatMessage } from '../hooks';
import {
  VkwIconIdentificationError,
  VkwIconIdentificationOk,
  VkwIconIdentificationPending,
  VkwIconIdentificationUnknown,
  VkwIconSize,
} from '../icons';
import { VkwContractPartnerPasswordRequestedState } from '../types';

export const getVkwIdentificationIcon = (
  contractPartnerPasswordRequestedState: VkwContractPartnerPasswordRequestedState,
  size: VkwIconSize
): ReactElement => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formatMessage = useVkwFormatMessage();

  switch (contractPartnerPasswordRequestedState) {
    case 'RequestAllowed':
      return (
        <Tooltip title={formatMessage('Linked')} arrow placement="bottom">
          <Box>
            <VkwIconIdentificationUnknown size={size} />
          </Box>
        </Tooltip>
      );
    case 'Requested':
    case 'RequestAllowedAgain':
      return (
        <Tooltip title={formatMessage('Pending')} arrow placement="bottom">
          <Box>
            <VkwIconIdentificationPending size={size} />
          </Box>
        </Tooltip>
      );
    case 'Blocked':
      return (
        <Tooltip title={formatMessage('Blocked')} arrow placement="bottom">
          <Box>
            <VkwIconIdentificationError size={size} />{' '}
          </Box>
        </Tooltip>
      );
    case 'Completed':
      return (
        <Tooltip title={formatMessage('SuccessfullyLinked')} arrow placement="bottom">
          <Box>
            <VkwIconIdentificationOk size={size} />
          </Box>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={formatMessage('Linked')} arrow placement="bottom">
          <Box>
            <VkwIconIdentificationUnknown size={size} />
          </Box>
        </Tooltip>
      );
  }
};
