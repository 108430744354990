import React, { ReactElement, useEffect, ChangeEvent } from 'react';

import { ChargingTariffContract } from '../../contexts';
import { useBaseDataStore } from '../../hooks';
import { VkwLoader, VkwSelect, VkwSelectItem, useVkwFormatMessage } from '../../library';

interface ChargeStationPricesAndChargeContractSelectionProps {
  onSelectionChange: (contract: ChargingTariffContract | null) => void;
  selectedContract: ChargingTariffContract | null;
}

export const ChargeStationPricesAndChargeContractSelection = ({
  onSelectionChange,
  selectedContract,
}: ChargeStationPricesAndChargeContractSelectionProps): ReactElement | null => {
  const store = useBaseDataStore<ChargingTariffContract>({
    pageSize: 100,
    url: `/api/v1/ChargingTariffContracts`,
    useQueryFilterParams: false,
  });

  const formatMessage = useVkwFormatMessage();
  const chargingTariffContracts = store.data.filter(
    tariff => !tariff.contractValidTo || new Date() < tariff.contractValidTo
  );

  useEffect(() => {
    if (chargingTariffContracts.length === 1) {
      onSelectionChange(chargingTariffContracts[0]);
    }
  }, [chargingTariffContracts]);

  const handleSelectChange = (event: ChangeEvent<{ name?: string; value: unknown }>): void => {
    const selectedContract = chargingTariffContracts.find(
      chargingTariffContract => chargingTariffContract.contractId === event.target.value
    ) as ChargingTariffContract;
    onSelectionChange(selectedContract);
  };

  if (store.loading) {
    return <VkwLoader />;
  }

  if (chargingTariffContracts.length <= 0) {
    return null;
  }

  return (
    <VkwSelect
      disabled={chargingTariffContracts.length <= 1}
      name="contractId"
      value={selectedContract ? selectedContract.contractId : 'null'}
      label={formatMessage('Tariff')}
      onChange={handleSelectChange}
      chooseOptionValue="null"
      items={chargingTariffContracts.map((item): VkwSelectItem => {
        const chargingTariffContract = item as ChargingTariffContract;

        const vehicleLicensePlateLabel = chargingTariffContract.vehicleLicensePlate
          ? `(${chargingTariffContract.vehicleLicensePlate})`
          : '';

        return {
          label: `${
            chargingTariffContract.contractAlias ?? chargingTariffContract.productName ?? chargingTariffContract.id
          } ${vehicleLicensePlateLabel}`,
          value: chargingTariffContract.contractId,
        };
      })}
    />
  );
};
