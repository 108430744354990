import React, { ReactElement, ReactNode, useEffect } from 'react';

import { ArrowLeftOnRectangleIcon, ArrowRightOnRectangleIcon, BoltIcon, ClockIcon } from '@heroicons/react/24/outline';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { useRouter } from 'next/router';

import { Money } from '../../components';
import { useConfigContext } from '../../contexts';
import { useBaseDataStore } from '../../hooks';
import { VkwLoader, useVkwFormatMessage, timeConvert, dateDisplay, timeDisplay } from '../../library';
import { InfrastructureChargeDetailRecord } from '../InfrastructureChargeDetailRecords';

type OutlineIconName = 'ArrowLeftOnRectangleIcon' | 'ArrowRightOnRectangleIcon' | 'BoltIcon' | 'ClockIcon';
interface IconTextProps {
  children: ReactNode;
  icon: OutlineIconName;
}

const IconText = ({ children, icon }: IconTextProps): ReactElement => {
  const iconClass = 'h-5 w-5 opacity-50';

  const icons: Record<OutlineIconName, ReactElement<{ className: string }>> = {
    ArrowLeftOnRectangleIcon: <ArrowLeftOnRectangleIcon className={iconClass} />,
    ArrowRightOnRectangleIcon: <ArrowRightOnRectangleIcon className={iconClass} />,
    BoltIcon: <BoltIcon className={iconClass} />,
    ClockIcon: <ClockIcon className={iconClass} />,
  };

  const outlineIcon: ReactElement<{ className: string }> = icons[icon];

  return (
    <div className="flex items-center gap-1">
      {outlineIcon}
      {children}
    </div>
  );
};

const RenderItem = (dataEntry: InfrastructureChargeDetailRecord): ReactElement => {
  const { config } = useConfigContext();
  const formatMessage = useVkwFormatMessage();

  return (
    <div className="group flex items-center justify-between overflow-hidden rounded-2xl bg-gray-50 text-xs transition ease-in hover:bg-gray-100 dark:bg-gray-600 dark:text-white dark:hover:bg-gray-500">
      <div className="flex items-center">
        <div className="grid w-32 gap-1 bg-gray-100 py-2 pl-3 transition ease-in group-hover:bg-gray-200 dark:bg-gray-700 dark:group-hover:bg-gray-600">
          <IconText icon="BoltIcon">
            <span className="font-bold">
              {dataEntry.consumedEnergy} {formatMessage('KilowattHourAbbreviation')}
            </span>
          </IconText>
          <IconText icon="ClockIcon">
            {timeConvert(dataEntry.durationInSeconds, false)} {formatMessage('HoursAbbreviation')}
          </IconText>
        </div>
        <div className="grid gap-1 py-2 pl-3">
          <IconText icon="ArrowLeftOnRectangleIcon">
            {dateDisplay(dataEntry.startTime)}
            <span className="font-bold">{timeDisplay(dataEntry.startTime)}</span>
          </IconText>
          <IconText icon="ArrowRightOnRectangleIcon">
            {dateDisplay(dataEntry.endTime)}
            <span className="font-bold">{timeDisplay(dataEntry.endTime)}</span>
          </IconText>
        </div>
      </div>
      {!config.hideCommission && (
        <div className="grid h-full w-[4.5rem] place-content-center justify-end gap-2 border-l border-black/5 pr-3 dark:border-black/20">
          <span className="text-[10px] font-normal opacity-70">{formatMessage('Compensation')}</span>
          <span className="text-right font-bold">
            <Money value={dataEntry.totalAmount} />
          </span>
        </div>
      )}
    </div>
  );
};

interface ChargeStationInfrastructureChargeOverviewProps {
  chargingPointIds: string[];
}

export const ChargeStationInfrastructureChargeOverview = ({
  chargingPointIds,
}: ChargeStationInfrastructureChargeOverviewProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const router = useRouter();

  const store = useBaseDataStore<InfrastructureChargeDetailRecord>({
    defaultStartLoading: false,
    url: '/api/v1/InfrastructureChargeDetailRecords',
    useQueryFilterParams: false,
  });

  useEffect(() => {
    const chargingPointIdFilter = store.filters.get('chargingPointId');

    if (!chargingPointIdFilter || chargingPointIdFilter.length !== chargingPointIds.length) {
      store.changeFilter('chargingPointId', chargingPointIds);
    } else if (chargingPointIds.find(value => !Array.prototype.includes.call(chargingPointIdFilter, value))) {
      store.changeFilter('chargingPointId', chargingPointIds);
    }
  }, [chargingPointIds]);

  useEffect(() => {
    store.startLoading();
  }, []);

  if (!store.initialized) {
    return <VkwLoader />;
  }

  const additionalChargingDetails = (): ReactElement => (
    <button
      className="group flex cursor-pointer items-center rounded-2xl bg-gray-100 py-2 pl-4 pr-2 text-xs font-medium transition ease-in hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600"
      type="button"
      onClick={() => {
        router.push({
          pathname: '/charge-detail-records',
          query: {
            filters: JSON.stringify({ chargingPointId: chargingPointIds }),
            view: 'infrastructure',
          },
        });
      }}
    >
      {formatMessage('AdditionalChargingDetails')}
      <span className="ml-1 flex h-5 items-center pl-0 pr-1 transition-all ease-in group-hover:pl-1 group-hover:pr-0">
        <ChevronRightIcon className="h-4 w-4 opacity-75" />
      </span>
    </button>
  );

  return (
    <>
      {store.data.length <= 0 && <span className="font-bold">{formatMessage('NoResultsForChargings')}</span>}
      {store.data.length > 0 && (
        <div className="flex items-center justify-between">
          <span className="font-bold">{formatMessage('LastChargings', { value: store.data.length })}</span>
          {additionalChargingDetails()}
        </div>
      )}
      <div className="grid gap-y-3 py-4">{store.data.map(dataEntry => RenderItem(dataEntry))}</div>
      {store.pages > 1 && <div className="flex items-center justify-end">{additionalChargingDetails()}</div>}
    </>
  );
};
