import React, { Fragment, ReactElement, useState } from 'react';

import { Backdrop, Divider, List, ListItem, makeStyles, Popover, Typography } from '@material-ui/core';

import { AppPopoverListItem } from './AppPopoverListItem';
import { getVkwTypographyStyle } from '../../helper';
import { useVkwFormatMessage } from '../../hooks';
import { VkwDynamicIcon, VkwIconApps } from '../../icons';
import { VkwTheme } from '../../themes';
import { VkwContractPartner, VkwPopoverProps, VkwHeaderPanelLink } from '../../types';
import { VkwIconButton } from '../VkwIconButton';
import { VkwListItem } from '../VkwListItem';
import { ContractPartnerList } from '../VkwUserPopover/ContractPartnerList';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    appPopoverBackdrop: {
      zIndex: theme.zIndex.modal - 1,
    },
    buttonIcon: {
      display: 'flex',
    },
    container: {
      marginTop: '5px',
      maxWidth: '100%',
      width: 360,
    },
    contractPartners: {
      '&::-webkit-scrollbar': {
        height: '194px',
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey[400],
        borderRadius: '4px',
      },
      maxHeight: '240px',
      overflow: 'auto',
    },
    headerText: {
      ...getVkwTypographyStyle('h10', theme),
      color: theme.palette.text.primary,
    },
    list: {
      overflow: 'hidden',
    },
  }),
  { index: 1 }
);
export interface VkwAppPopoverConfig extends VkwHeaderPanelLink {
  active?: boolean;
  selected?: boolean;
}

export interface VkwAppPopoverProps {
  /**
   * Überschrift des Menüs
   */
  title: string;
  /**
   * Konfiguration der Menüeinträge im Popover
   */
  appPopoverConfigs: VkwAppPopoverConfig[];
  /**
   * Werden hier die Daten übergeben, werden ganz oben im Popover die Kundendaten angezeigt
   */
  selectedContractPartner?: VkwContractPartner | null;
  /**
   * Ausgewählte Kundendaten speichern
   */
  setNewSelectedContractPartner: (data: VkwContractPartner) => void;
  /**
   * Alle verfügbaren Kundennummern, unabhängig von der Kategorie
   */
  contractPartners: VkwContractPartner[];
  popoverProps?: VkwPopoverProps;
}

export const VkwAppPopover = ({
  appPopoverConfigs,
  contractPartners,
  popoverProps,
  selectedContractPartner,
  setNewSelectedContractPartner,
  title,
}: VkwAppPopoverProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [showContractPartners, setShowContractPartners] = useState(false);
  const [selectedApp, setSelectedApp] = useState<VkwAppPopoverConfig | null>(
    appPopoverConfigs.find(item => item.selected) || null
  );

  const handleOnClickAppItem = (appConfig: VkwAppPopoverConfig) => {
    let tempShowContractPartners = false;
    setSelectedApp(appConfig);

    const selectedContractPartnerHasNoMatchingCategory = !appConfig?.productCategories?.find(category =>
      selectedContractPartner?.identifiedProductCategories?.includes(category)
    );

    const otherContractPartnersHaveMatchingCategories =
      contractPartners.length > 1 &&
      !!contractPartners.find(contractPartner => {
        return contractPartner.identifiedProductCategories?.find(productCategory => {
          return appConfig.productCategories?.includes(productCategory);
        });
      });

    if (selectedContractPartnerHasNoMatchingCategory && otherContractPartnersHaveMatchingCategories) {
      tempShowContractPartners = true;
    } else {
      window.open(appConfig.url, appConfig.openInTab ? '_blank' : '_self');
    }

    setShowContractPartners(tempShowContractPartners);
  };

  return (
    <div>
      <VkwIconButton
        shadow
        onClick={event => {
          if (event.currentTarget) {
            setAnchorEl(event.currentTarget);
            if (popoverProps && popoverProps.onOpen) {
              popoverProps.onOpen(event);
            }
          }
        }}
      >
        <Typography color="textSecondary" className={styles.buttonIcon}>
          <VkwIconApps size={24} />
        </Typography>
      </VkwIconButton>
      <Popover
        open={!!anchorEl}
        onClose={(event, reason) => {
          setAnchorEl(null);
          setShowContractPartners(false);
          if (popoverProps && popoverProps.onClose) {
            popoverProps?.onClose(event, reason);
          }
        }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={anchorEl}
        className={styles.container}
        disablePortal={popoverProps?.disablePortal}
      >
        <List className={styles.list}>
          {!selectedContractPartner && (
            <ListItem>
              <Typography className={styles.headerText}>{title}</Typography>
            </ListItem>
          )}
          {selectedContractPartner && <AppPopoverListItem data={selectedContractPartner} />}
          <Divider />
          {appPopoverConfigs.map((appConfig, index): ReactElement | null => {
            if (!appConfig.active) {
              return null;
            }

            return (
              <Fragment key={`list-item${index}`}>
                <VkwListItem
                  icon={<VkwDynamicIcon size={36} type={appConfig.icon} />}
                  isSelected={appConfig.selected}
                  onClick={() => handleOnClickAppItem(appConfig)}
                  isActive={appConfig === selectedApp && showContractPartners}
                >
                  {appConfig.displayText.text ?? formatMessage(appConfig.displayText.key)}
                </VkwListItem>
                {appConfig === selectedApp && showContractPartners && contractPartners.length > 0 && (
                  <ContractPartnerList
                    selectedContractPartner={selectedContractPartner}
                    setShowContractPartners={setShowContractPartners}
                    contractPartners={contractPartners}
                    listItemSelectable={false}
                    setNewSelectedContractPartner={setNewSelectedContractPartner}
                    appConfig={appConfig}
                  />
                )}
              </Fragment>
            );
          })}
        </List>
      </Popover>
      <Backdrop open={!!anchorEl} onClick={() => setAnchorEl(null)} className={styles.appPopoverBackdrop} />
    </div>
  );
};
