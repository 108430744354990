import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconRefresh = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(3.000000, 3.000000)">
          <path
            d="M17,12.6 C15.6315213,15.7788 12.5061039,18 8.86894825,18 C3.97062813,18 0,13.9707 0,9 C0,4.0293 3.97062813,0 8.86894825,0 C11.2866235,0 13.4790275,0.9819 15.0789858,2.574"
            stroke={fill}
            strokeWidth="2"
          />
          <polygon stroke={fill} strokeWidth="2" strokeLinecap="square" points="13 4.16666667 17.1666667 0 18 5" />
          <polygon fill={fill} fillRule="nonzero" points="13 4.16666667 17.1666667 0 18 5" />
        </g>
      </g>
    </VkwIcon>
  );
};
