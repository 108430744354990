import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconGear = ({ size }: VkwIconProps): ReactElement => (
  <VkwIcon size={size}>
    <g>
      <path d="M24,14V10l-3.338-.417a8.934,8.934,0,0,0-.826-2L21.9,4.929,19.071,2.1,16.418,4.164a8.934,8.934,0,0,0-2-.826L14,0H10L9.583,3.338a8.934,8.934,0,0,0-2,.826L4.929,2.1,2.1,4.929,4.164,7.582a8.934,8.934,0,0,0-.826,2L0,10v4l3.338.417a8.934,8.934,0,0,0,.826,2L2.1,19.071,4.929,21.9l2.653-2.063a8.934,8.934,0,0,0,2,.826L10,24h4l.417-3.338a8.934,8.934,0,0,0,2-.826L19.071,21.9,21.9,19.071l-2.063-2.653a8.934,8.934,0,0,0,.826-2ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Z" />{' '}
    </g>
  </VkwIcon>
);
