import React, { ReactElement, ReactNode } from 'react';

import { Box, ButtonBase, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { getVkwTypographyStyle } from '../../helper';
import { VkwIconArrowRight, VkwIconCheck } from '../../icons';
import { VkwTheme } from '../../themes';

const BORDER_WIDTH = 3;

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    content: {
      display: 'flex',
      ...getVkwTypographyStyle('h10', theme),
      alignItems: 'center',
      minWidth: '0',
      overflow: 'hidden',
      padding: theme.spacing(1.125),
      textOverflow: 'ellipsis',
      width: '100%',
    },
    icon: {
      alignItems: 'center',
      color: theme.palette.text.secondary,
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1.125, 1),
    },
    iconSelected: {
      color: theme.palette.activeColor,
    },
    item: {
      borderLeft: `${BORDER_WIDTH}px solid transparent`,
      display: 'flex',
      width: '100%',
    },
    itemGrey: {
      backgroundColor: theme.palette.action.hover,
    },
    itemHover: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderRadius: 0,
        width: '100%',
      },
      cursor: 'pointer',
    },
    selected: {
      borderLeft: `${BORDER_WIDTH}px solid ${theme.palette.secondary.main}`,
    },
  }),
  { index: 1 }
);

export interface VkwListItemProps {
  /**
   * Inhalt des VkwListItem
   */
  children: ReactNode;
  isSelected?: boolean;
  /**
   * Wird ein Icon übergeben, erscheint dieses am linken Rand
   */
  icon?: ReactElement;
  /**
   * Soll das VkwListItem als Link verwendet werden so müssen hier die Properties `url` und `openInTab` übergeben werden.
   */
  link?: {
    url: string;
    openInTab?: boolean;
  };
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  /**
   * Soll am rechten Rand ein `VkwIconArrowRight` Icon dargestellt werden? (Signalisiert dem User einen Absprung / Link)
   */
  hasArrowIcon?: boolean;
  /**
   * Mit diesem Property kann für das Item das "active" styling gesetzt werden.
   */
  isActive?: boolean;
}

export const VkwListItem = ({
  children,
  hasArrowIcon = false,
  icon,
  isActive = false,
  isSelected = false,
  link,
  onClick,
}: VkwListItemProps): ReactElement => {
  const styles = useStyles();

  const stylesListItem = clsx(
    styles.item,
    isSelected && styles.selected,
    (!!link || !!onClick) && styles.itemHover,
    isActive && styles.itemGrey
  );

  return (
    <ButtonBase
      className={stylesListItem}
      href={link ? link.url : ''}
      onClick={e => onClick && onClick(e)}
      disabled={!link && !onClick}
      target={link && link.openInTab ? '_blank' : undefined}
      rel={link && link.openInTab ? 'noopener' : undefined}
    >
      {icon && <Box className={clsx(styles.icon, isSelected && styles.iconSelected)}>{icon}</Box>}
      <Box className={styles.content}>{children}</Box>
      {!isSelected && hasArrowIcon && (
        <Box className={styles.icon}>
          <VkwIconArrowRight size={12} />
        </Box>
      )}
      {isSelected && (
        <Box className={clsx(styles.icon, isSelected && styles.iconSelected)}>
          <VkwIconCheck size={12} />
        </Box>
      )}
    </ButtonBase>
  );
};
