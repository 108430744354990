import React, { ReactElement, ReactNode } from 'react';

import { Box, Typography } from '@material-ui/core';

import { useStyles } from './VkwNotificationStyles';
import { VkwIconError, VkwIconCheckRound, VkwIconInfo, VkwIconWarning } from '../../icons';

export type VkwNotificationType = 'warning' | 'information' | 'success' | 'error';
export interface VkwNotificationProps {
  type: VkwNotificationType;
  title?: string;
  message?: string;
}

const renderIcon = (type: VkwNotificationType): ReactNode => {
  if (type === 'error') {
    return <VkwIconError size={16} />;
  }
  if (type === 'success') {
    return <VkwIconCheckRound size={16} />;
  }
  if (type === 'warning') {
    return <VkwIconWarning size={16} />;
  }

  return <VkwIconInfo size={16} />;
};

export const VkwNotification = ({ message, title, type }: VkwNotificationProps): ReactElement => {
  const styles = useStyles({ type });

  return (
    <Box className={styles.box}>
      <div className={styles.indicator} />
      <div className={styles.icon}>{renderIcon(type)}</div>
      {title && <Typography className={styles.title}>{title}</Typography>}
      {message && <Typography className={styles.message}>{message}</Typography>}
    </Box>
  );
};
