import { makeStyles } from '@material-ui/core';

import { getVkwTypographyStyle } from '../../helper';
import { VkwTheme } from '../../themes';

export const useStyles = makeStyles<VkwTheme, { multiline?: boolean }>(
  theme => ({
    errorIcon: {
      color: theme.palette.error.main,
      height: theme.typography.pxToRem(15),
      position: 'absolute',
      right: theme.spacing(3),
      top: theme.spacing(2.5),
      width: theme.typography.pxToRem(15),
    },
    errorMessage: {
      ...getVkwTypographyStyle('link14', theme),
      marginLeft: 0,
    },
    inputRoot: props => ({
      ...getVkwTypographyStyle('text14', theme),
      '& textarea': {
        color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
        minHeight: props.multiline ? '150px' : undefined,
        paddingBottom: props.multiline ? theme.spacing(1) : undefined,
        paddingLeft: theme.spacing(2),
        paddingRight: props.multiline ? theme.spacing(1) : undefined,
        resize: props.multiline ? 'vertical' : undefined,
      },
      boxSizing: props.multiline ? 'content-box' : undefined,
      paddingBottom: props.multiline ? '0px' : undefined,
      paddingLeft: 0,

      paddingRight: props.multiline ? '0px' : undefined,
    }),
    inputRootInput: {
      color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
      paddingLeft: theme.spacing(2),
      position: 'relative',
    },

    label: {
      '&.Mui-focused': {
        color: theme.palette.grey[500],
      },
    },

    labelRoot: {
      ...getVkwTypographyStyle('text12', theme),
      color: theme.palette.grey[500],
      paddingLeft: theme.spacing(1),
    },
    maxLength: {
      height: theme.typography.pxToRem(15),
      position: 'absolute',
      right: theme.spacing(3),
      top: theme.spacing(2.5),
    },
    maxLengthText: {
      ...getVkwTypographyStyle('text12', theme),
      color: theme.palette.text.secondary,
    },
    wrapper: {
      position: 'relative',
    },
  }),
  { index: 1 }
);
