import { cast, Instance, SnapshotOut, types, IAnyModelType } from 'mobx-state-tree';

type DateRangeType = { from?: Date; to?: Date };
const DateRange: IAnyModelType = types.model({
  from: types.maybe(types.Date),
  to: types.maybe(types.Date),
});

type NumberRangeType = { from?: number; to?: number };
const NumberRange: IAnyModelType = types.model({
  from: types.maybe(types.number),
  to: types.maybe(types.number),
});

export const FiltersModel = types.model({
  activeFilter: types.maybe(types.number),
  chargingContractIdFilter: types.maybe(types.array(types.string)),
  costFilter: types.maybe(NumberRange),
  durationFilter: types.maybe(NumberRange),
  energyFilter: types.maybe(NumberRange),
  lastFilterUpdate: types.maybe(types.string),
  periodFilter: types.maybe(DateRange),
});

export const ChargesStoreModel = types
  .model('ChargesStore')
  .props({
    filters: types.map(FiltersModel),
  })
  .actions(store => ({
    addFilter(key: string, filter: typeof FiltersModel) {
      store.filters.set(key, cast(filter));

      return store.filters.get(key);
    },

    calculateActiveFilters(filter: Instance<typeof FiltersModel>) {
      let count = 0;
      count += filter.chargingContractIdFilter ? 1 : 0;
      count += filter.costFilter ? 1 : 0;
      count += filter.durationFilter ? 1 : 0;
      count += filter.energyFilter ? 1 : 0;
      count += filter.periodFilter ? 1 : 0;
      return count;
    },

    getFilter(key: string) {
      let filter = store.filters.get(key);

      if (!filter) {
        filter = FiltersModel.create({});
        store.filters.set(key, filter);
      }

      return filter;
    },

    removeFilter(key: string) {
      store.filters.delete(key);
    },

    setChargingContractIdFilter(filterKey: string, value?: string[] | undefined, setLastChargesStoreUpdate = true) {
      this.setGenericFilter(
        filterKey,
        'chargingContractIdFilter',
        value ? cast(value) : undefined,
        setLastChargesStoreUpdate
      );
    },

    setCostFilter(filterKey: string, value?: NumberRangeType, setLastChargesStoreUpdate = true) {
      this.setGenericFilter(filterKey, 'costFilter', value ? cast(value) : undefined, setLastChargesStoreUpdate);
    },

    setDurationFilter(filterKey: string, value?: NumberRangeType, setLastChargesStoreUpdate = true) {
      this.setGenericFilter(filterKey, 'durationFilter', value ? cast(value) : undefined, setLastChargesStoreUpdate);
    },

    setEnergyFilter(filterKey: string, value?: NumberRangeType, setLastChargesStoreUpdate = true) {
      this.setGenericFilter(filterKey, 'energyFilter', value ? cast(value) : undefined, setLastChargesStoreUpdate);
    },

    setFilter(
      filterKey: string,
      chargingContractIdFilter?: string[],
      periodFilter?: DateRangeType,
      energyFilter?: NumberRangeType,
      durationFilter?: NumberRangeType,
      costFilter?: NumberRangeType
    ) {
      this.setGenericFilter(filterKey, 'chargingContractIdFilter', cast(chargingContractIdFilter), false);
      this.setGenericFilter(filterKey, 'periodFilter', periodFilter, false);
      this.setGenericFilter(filterKey, 'energyFilter', energyFilter, false);
      this.setGenericFilter(filterKey, 'durationFilter', durationFilter, false);
      this.setGenericFilter(filterKey, 'costFilter', costFilter, false);

      this.setFilterUpdate(filterKey);
    },

    setFilterUpdate(filterKey: string) {
      const filter = store.filters.get(filterKey);
      if (filter) {
        filter.lastFilterUpdate = new Date().toISOString();
        // Update the activeFilter count here if needed
        filter.activeFilter = this.calculateActiveFilters(filter);
      }
    },

    setGenericFilter<K extends keyof typeof FiltersModel.properties>(
      filterKey: string,
      filterType: K,
      value?: (typeof FiltersModel.properties)[K]['Type'],
      setFilterUpdate = true
    ) {
      const filter = store.filters.get(filterKey) || FiltersModel.create({});
      if (value !== undefined) {
        filter[filterType] = cast(value);
      } else {
        filter[filterType] = undefined;
      }
      store.filters.set(filterKey, filter);

      if (setFilterUpdate) {
        this.setFilterUpdate(filterKey);
      }
    },

    setPeriodFilter(filterKey: string, value?: DateRangeType, setLastChargesStoreUpdate = true) {
      this.setGenericFilter(filterKey, 'periodFilter', value ? cast(value) : undefined, setLastChargesStoreUpdate);
    },

    updateFilter(key: string, filter: typeof FiltersModel) {
      if (store.filters.has(key)) {
        store.filters.set(key, cast(filter));
      }
    },
  }));

export type ChargesStore = Instance<typeof ChargesStoreModel>;
export type ChargesStoreSnapshot = SnapshotOut<typeof ChargesStoreModel>;
