import { z } from 'zod';

export const evcoIdSchemaProp = z
  .string()
  .regex(new RegExp(/^[A-Za-z]{2}-?[A-Za-z0-9]{3}-?C[A-Za-z0-9]{8}-?[\d|A-Za-z]$/))
  .describe('This is a evco id with a custom regex validation');
export const evcoIdSchema = z.object({ evcoId: evcoIdSchemaProp });
export type EvcoId = z.infer<typeof evcoIdSchema>;

export const evseIdSchemaProp = z
  .string()
  .regex(
    new RegExp(/^(([A-Za-z]{2}\*?[A-Za-z0-9]{3}\*?E[A-Za-z0-9*]{1,30})|(\+?[0-9]{1,3}\*[0-9]{3,6}\*[0-9*]{1,32}))$/)
  )
  .describe('This is a evse id with a custom regex validation');
export const evseIdSchema = z.object({
  evseId: evseIdSchemaProp,
});
export type EvseId = z.infer<typeof evseIdSchema>;
