import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconIdentificationPending = ({ size }: VkwIconProps): ReactElement => {
  return (
    <VkwIcon size={size}>
      <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons-/-24-/-Identifikation-/-I1-/-Pending">
          <g id="Path" fill="#9C9C9B">
            <path d="M24,12 C23.9975449,10.3146512 23.0219449,8.78233588 21.495949,8.06703992 C22.0711018,6.4820265 21.6767746,4.70735196 20.484515,3.51506807 C19.2922554,2.32278419 17.517617,1.92844899 15.9326358,2.50361347 C15.2190644,0.976150147 13.6856482,0 11.9997558,0 C10.3138633,0 8.78044713,0.976150147 8.06687574,2.50361347 C6.48189457,1.92844899 4.70725615,2.32278419 3.51499653,3.51506807 C2.32273691,4.70735196 1.92840974,6.4820265 2.50356251,8.06703992 C0.97613028,8.78062583 0,10.3140732 0,12 C0,13.6859268 0.97613028,15.2193742 2.50356251,15.9329601 C1.92840974,17.5179735 2.32273691,19.292648 3.51499653,20.4849319 C4.70725615,21.6772158 6.48189457,22.071551 8.06687574,21.4963865 C8.78044713,23.0238499 10.3138633,24 11.9997558,24 C13.6856482,24 15.2190644,23.0238499 15.9326358,21.4963865 C17.517617,22.071551 19.2922554,21.6772158 20.484515,20.4849319 C21.6767746,19.292648 22.0711018,17.5179735 21.495949,15.9329601 C23.0219449,15.2176641 23.9975449,13.6853488 24,12 Z" />
          </g>
          <path
            d="M17.25,7.5 C17.7,7.5 18,7.2 18,6.75 C18,6.3 17.7,6 17.25,6 L6.75,6 C6.3,6 6,6.3 6,6.75 C6,7.2 6.3,7.5 6.75,7.5 L8.5,7.5 C8.575,9.3 9.1,10.875 10,12 C9.1,13.05 8.5,14.7 8.5,16.5 L6.75,16.5 C6.3,16.5 6,16.8 6,17.25 C6,17.7 6.3,18 6.75,18 L17.25,18 C17.7,18 18,17.7 18,17.25 C18,16.8 17.7,16.5 17.25,16.5 L15.5,16.5 C15.425,14.625 14.9,13.05 14,12 C14.975,10.875 15.5,9.3 15.5,7.5 L17.25,7.5 Z"
            id="Shape"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </VkwIcon>
  );
};
