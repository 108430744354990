import React, { LegacyRef, ReactElement, useEffect, useRef } from 'react';

import { View } from 'react-native';

import { useHelpWizardContext } from './HelpWizardContext';
import { WizardStep } from './helpWizardSteps';

/**
 * HelpWizardStep is a component that represents a step in a help wizard.
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {ReactElement} props.children - The content of the step.
 * @param {WizardStep} props.step - The information for the step.
 *
 * @return {ReactElement} The rendered HelpWizardStep component.
 */
export const HelpWizardStep = ({
  children,
  rounded,
  step,
}: {
  children: ReactElement;
  step: WizardStep;
  rounded?: boolean;
}) => {
  const { addWizardStep } = useHelpWizardContext();
  const childRef = useRef();

  useEffect(() => {
    if (childRef.current) {
      const stepx = {
        path: step.path,
        position: step.position,
        ref: childRef,
        title: step.title,
      };
      addWizardStep(stepx);
    }
  }, [childRef, step]);

  return (
    <View
      ref={childRef as unknown as LegacyRef<View>}
      style={{
        borderRadius: rounded ? 180 : 0,
        padding: rounded ? 4 : 0,
      }}
    >
      {children}
    </View>
  );
};
