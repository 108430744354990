import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const TabBarStoreModel = types
  .model('TabBarStore')
  .props({
    chargeSessionsBadgeCount: types.maybe(types.number),
    notificationsBadgeCount: types.maybe(types.number),
  })
  .actions(store => ({
    setChargeSessionsBadgeCount(chargeSessionsBadgeCount: number) {
      store.chargeSessionsBadgeCount = chargeSessionsBadgeCount;
    },
    setNotificationsBadgeCount(notificationsBadgeCount: number) {
      store.notificationsBadgeCount = notificationsBadgeCount;
    },
  }));

export type TabBarStore = Instance<typeof TabBarStoreModel>;
export type TabBarStoreSnapshot = SnapshotOut<typeof TabBarStoreModel>;
