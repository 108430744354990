import React, { ReactElement } from 'react';

import { TableCell, TableCellProps } from '@material-ui/core';

import useStyles from './VkwBaseTableStyles';

export const VkwBaseTableHeadCell = ({ children, ...rest }: TableCellProps): ReactElement => {
  const styles = useStyles();

  return (
    <TableCell component="th" className={styles.headerCell} {...rest}>
      <div className={styles.tableHeadCellInner}>{children}</div>
    </TableCell>
  );
};
