import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconEnergyPortal = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 48 48" version="1.1">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="nonzero">
          <g id="phone-charging-3">
            <g transform="translate(10.000000, 3.000000)">
              <path
                d="M24.46875,0 L4.53125,0 C2.02995183,0.00301862796 0.00299616793,2.04516886 0,4.56521739 L0,37.4347826 C0.00299616793,39.9548311 2.02995183,41.9969814 4.53125,42 L24.46875,42 C26.9700482,41.9969814 28.9970038,39.9548311 29,37.4347826 L29,4.56521739 C28.9970038,2.04516886 26.9700482,0.00301862796 24.46875,0 Z M27.1875,34.6956522 C27.1875,35.1999122 26.7817581,35.6086957 26.28125,35.6086957 L2.71875,35.6086957 C2.21824195,35.6086957 1.8125,35.1999122 1.8125,34.6956522 L1.8125,5.47826087 C1.8125,4.97400088 2.21824195,4.56521739 2.71875,4.56521739 L26.28125,4.56521739 C26.7817581,4.56521739 27.1875,4.97400088 27.1875,5.47826087 L27.1875,34.6956522 Z"
                id="Shape"
                fill={fill}
                fillRule="nonzero"
              />
              <path d="M22,16 L16,34 L16,16 L22,16 Z M14,6 L14,24 L8,24 L14,6 Z" id="Combined-Shape" fill={fill} />
            </g>
          </g>
        </g>
      </svg>
    </VkwIcon>
  );
};
