import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconIdentificationOk = ({ size }: VkwIconProps): ReactElement => {
  return (
    <VkwIcon size={size}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons-/-24-/-Identifikation" fill="#00757A">
          <path
            d="M24,12 C23.9975449,10.3146512 23.0219449,8.78233588 21.495949,8.06703992 C22.0711018,6.4820265 21.6767746,4.70735196 20.484515,3.51506807 C19.2922554,2.32278419 17.517617,1.92844899 15.9326358,2.50361347 C15.2190644,0.976150147 13.6856482,0 11.9997558,0 C10.3138633,0 8.78044713,0.976150147 8.06687574,2.50361347 C6.48189457,1.92844899 4.70725615,2.32278419 3.51499653,3.51506807 C2.32273691,4.70735196 1.92840974,6.4820265 2.50356251,8.06703992 C0.97613028,8.78062583 0,10.3140732 0,12 C0,13.6859268 0.97613028,15.2193742 2.50356251,15.9329601 C1.92840974,17.5179735 2.32273691,19.292648 3.51499653,20.4849319 C4.70725615,21.6772158 6.48189457,22.071551 8.06687574,21.4963865 C8.78044713,23.0238499 10.3138633,24 11.9997558,24 C13.6856482,24 15.2190644,23.0238499 15.9326358,21.4963865 C17.517617,22.071551 19.2922554,21.6772158 20.484515,20.4849319 C21.6767746,19.292648 22.0711018,17.5179735 21.495949,15.9329601 C23.0219449,15.2176641 23.9975449,13.6853488 24,12 Z"
            id="Path"
          />
        </g>
        <g id="Icons-/-12-/-Check-1" transform="translate(4.000000, 4.000000)" fill="#FFFFFF">
          <path
            d="M6.0757325,11.7727996 L2.17572869,7.87279577 C1.94142377,7.63849086 1.94142377,7.25859205 2.17572869,7.0242637 L3.02423733,6.17573162 C3.25854224,5.94140326 3.63846449,5.94140326 3.87276941,6.17573162 L6.5,8.80293731 L12.1272306,3.17572869 C12.3615355,2.94142377 12.7414578,2.94142377 12.9757627,3.17572869 L13.8242713,4.02426077 C14.0585762,4.25856568 14.0585762,4.63846449 13.8242713,4.87279284 L6.92426457,11.772823 C6.68993622,12.0071279 6.31003741,12.0071279 6.0757325,11.7727996 L6.0757325,11.7727996 Z"
            id="Path"
          />
        </g>
      </g>
    </VkwIcon>
  );
};
