import React, { ReactElement, ReactNode } from 'react';

import { Box, Grid, makeStyles } from '@material-ui/core';

import { useVkwAssets } from '../../contexts';
import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme, { splitScreenImage?: string }>(
  theme => ({
    contentWrapper: {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
    },
    image: {
      backgroundImage: props => `url(${props.splitScreenImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      minHeight: '100vh',
      width: '100%',
    },
    logo: {
      width: 180,
    },
    logoWrapper: {
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(12),
        paddingTop: theme.spacing(9),
      },
    },
  }),
  { index: 1 }
);

export interface VkwSplitScreenLayoutProps {
  children: ReactNode;
}

export const VkwSplitScreenLayout = ({ children }: VkwSplitScreenLayoutProps): ReactElement => {
  const assets = useVkwAssets();
  const styles = useStyles({ splitScreenImage: assets.vkwSplitScreenLayoutImage });

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} xl={6}>
            <Box className={styles.contentWrapper}>
              <Box className={styles.logoWrapper}>
                <img src={assets.logo} className={styles.logo} alt="logo" />
              </Box>
              {children}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={false} md={6}>
        <div className={styles.image} />
      </Grid>
    </Grid>
  );
};
