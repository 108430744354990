import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';
import { View, Text } from 'react-native';

import { t } from '@e-vo/localization';
import { colors } from '@e-vo/mobility/colors';
import { Button, Modal, ProgressBar } from '@e-vo/ui';

import { useHelpWizardContext } from './HelpWizardContext';
import { useUserContext } from '../../../contexts';
import { VkwIconInfo } from '../../../library';

/**
 * Represents the Help Wizard Button Overlay component.
 *
 * @returns {React.Component} The HelpWizardButtonOverlay component.
 */
export const HelpWizardButtonOverlay = () => {
  const { resetWizardStep, setWizardOpen, wizardOpen, wizardStepRefs } = useHelpWizardContext();
  const userContext = useUserContext();
  const router = useRouter();
  const [currentStep, setCurrentStep] = useState(0);
  const stepRefsForPath = wizardStepRefs(router.pathname);
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    if (currentStep <= stepRefsForPath.length - 1) {
      const currentStepRef = getStepRefAtPosition(currentStep)?.ref.current;

      resetWizardStepsIfNeeded();

      if (currentStepRef) {
        currentStepRef.style.position = 'relative';
        currentStepRef.style.zIndex = 10001;
        currentStepRef.style.backgroundColor = colors.white;
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (router.route === '/') {
      setPageTitle(' | Map');
    } else {
      setPageTitle('');
    }
  }, [router.route]);

  useEffect(() => {
    setCurrentStep(0);
  }, [wizardOpen]);

  const resetWizardStepsIfNeeded = () => {
    if (currentStep !== 0) {
      resetWizardStep(router.route, currentStep - 1);
    }

    if (currentStep < stepRefsForPath.length) {
      resetWizardStep(router.route, currentStep + 1);
    }
  };

  const getStepRefAtPosition = (position: number) => {
    return stepRefsForPath.find(r => r.position === position) ?? stepRefsForPath[0];
  };

  const previousStep = () => {
    setCurrentStep(value => value - 1);
  };

  const nextStep = () => {
    setCurrentStep(value => value + 1);
  };

  const cancel = () => {
    resetWizardStep(router.route);
    setWizardOpen(false);
  };

  return (
    <View>
      {userContext.account && (
        <Modal isOpen={wizardOpen} onClose={() => setWizardOpen(false)}>
          <Modal.Header>
            <View className="flex flex-row items-center gap-2">
              <VkwIconInfo />
              <Text className="ml-2">{t('wizard.title') + pageTitle}</Text>
            </View>
          </Modal.Header>
          <Modal.Body>
            <View className="flex flex-col space-y-4">
              <Text style={{ lineHeight: 24 }}>{stepRefsForPath[currentStep]?.title}</Text>
              {stepRefsForPath.length > 1 && (
                <ProgressBar currentStep={currentStep} stepsTotal={stepRefsForPath.length} />
              )}
            </View>
          </Modal.Body>
          <Modal.Footer>
            <View className="flex flex-row justify-between align-middle">
              <Button title={t('common.abort')} onPress={cancel} type="light" />
              <View className="flex flex-row gap-2">
                <Button disabled={currentStep === 0} onPress={previousStep} title={t('common.back')} type="text" />
                {currentStep < stepRefsForPath.length - 1 ? (
                  <Button onPress={nextStep} title={t('common.continue')} type="primary" />
                ) : (
                  <Button onPress={cancel} title={t('common.exit')} type="primary" />
                )}
              </View>
            </View>
          </Modal.Footer>
        </Modal>
      )}
    </View>
  );
};
