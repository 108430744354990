import React, { ReactElement, useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { ChargePoint } from '@e-vo/types';

import { ChargeStationPricesAndChargeContractSelection } from './ChargeStationPricesAndChargeContractSelection';
import { ChargingTariffContract, useUserContext } from '../../contexts';
import { Assets } from '../../globals/configureAssets';
import { ChargeStation } from '../../hooks';
import { toFirstUppercase, useVkwAssets, useVkwFormatMessage, VkwCallToAction } from '../../library';
import { ChargePointTile } from '../../views';

interface ChargeStationPricesAndChargeProps {
  chargeStation: ChargeStation;
}

export const ChargeStationPricesAndCharge = ({ chargeStation }: ChargeStationPricesAndChargeProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const [contract, setContract] = useState<ChargingTariffContract | null>(null);
  const [chargePoints, setChargePoints] = useState<ChargePoint[]>([]);
  const assets = useVkwAssets<Assets>();
  const userContext = useUserContext();

  const onSelectionChange = (selectedContract: ChargingTariffContract | null): void => {
    setContract(selectedContract);
  };

  const isNumeric = (value: any) => {
    return /^-?\d+$/.test(value);
  };

  const numericCompareTo = (a: number, b: number) => {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }

    return 0;
  };

  useEffect(() => {
    setChargePoints(
      chargeStation.chargePoints.sort((a, b) => {
        if (a.evseId === null && b.evseId === null) {
          return 0;
        }

        if (a.evseId === null) {
          return -1;
        }

        if (b.evseId === null) {
          return 1;
        }

        const aValues = a.evseId.split('*');
        const bValues = b.evseId.split('*');

        const iMin = Math.min(aValues.length, bValues.length);

        let compareValue = 0;

        for (let i = 0; i < iMin; i++) {
          const aValue = aValues[i];
          const bValue = bValues[i];

          compareValue =
            isNumeric(aValue) && isNumeric(bValue)
              ? numericCompareTo(parseInt(aValue, 10), parseInt(bValue, 10))
              : aValue.localeCompare(bValue);

          if (compareValue !== 0) {
            break;
          }
        }

        if (compareValue === 0 && aValues.length !== bValues.length) {
          return numericCompareTo(aValues.length, bValues.length);
        }

        return compareValue;
      })
    );
  }, [chargeStation.chargePoints]);

  return (
    <>
      {userContext.account?.info.hasChargingTariffContract && (
        <ChargeStationPricesAndChargeContractSelection
          onSelectionChange={onSelectionChange}
          selectedContract={contract}
        />
      )}

      <Box paddingY={2}>
        <Grid container spacing={1}>
          {chargePoints.map((chargePoint, i) => (
            <Grid item xs={12} key={i}>
              <ChargePointTile chargePoint={chargePoint} contract={contract} />
            </Grid>
          ))}
        </Grid>
      </Box>

      {!userContext.account?.info.hasChargingTariffContract &&
        assets.links.priceInfo &&
        chargeStation.operatorToken.includes('VKW') && (
          <Box paddingBottom={2}>
            <Typography variant="body2">{formatMessage('PriceLinkPreface')}</Typography>
            <VkwCallToAction
              title={assets.links.priceInfo.replace('https://', '')}
              href={assets.links.priceInfo}
              iconPosition="none"
              target="_blank"
            />
          </Box>
        )}
      {userContext.account?.info.hasChargingTariffContract && (
        <Typography variant="body2">{formatMessage(`PriceInfoText${toFirstUppercase(assets.project)}`)}</Typography>
      )}
    </>
  );
};
