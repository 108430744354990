/**
 * Set the class used for the Tailwind CSS dark mode
 */
export const darkMode = (enabled: boolean): void => {
  if (!document) {
    return;
  }

  if (enabled) {
    document.documentElement.classList.add('dark');
  } else {
    document.documentElement.classList.remove('dark');
  }
};
