import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const EvoIconAdmin = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize <= 16) {
          return (
            <path
              fill={fill}
              fillRule="evenodd"
              transform="scale(1.5)"
              d="M6 0C2.692 0 0 2.692 0 6v3.75C0 10.99 1.01 12 2.25 12H3c.415 0 .75-.336.75-.75V7.5c0-.414-.335-.75-.75-.75H1.5V6c0-2.482 2.018-4.5 4.5-4.5s4.5 2.018 4.5 4.5v.75H9c-.415 0-.75.336-.75.75v3.75c0 .414.335.75.75.75h.75C10.99 12 12 10.99 12 9.75V6c0-3.308-2.692-6-6-6z"
            />
          );
        }

        return (
          <g fill={fill}>
            <path
              d="M12,0C5.935,0,1,4.935,1,11v6c0,1.654,1.346,3,3,3h3c0.552,0,1-0.448,1-1v-7c0-0.552-0.448-1-1-1H3 c0-4.962,4.038-9,9-9s9,4.038,9,9h-4c-0.552,0-1,0.448-1,1v7c0,0.552,0.448,1,1,1h3c0.352,0,0.686-0.072,1-0.184 C21,21.261,20.004,22,19,22h-5c-0.552,0-1,0.448-1,1s0.448,1,1,1h5c2.206,0,4-1.794,4-4v-9C23,4.935,18.065,0,12,0z"
              fill={fill}
            />
          </g>
        );
      }}
    </VkwIcon>
  );
};
