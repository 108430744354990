import React, { ReactElement, ReactNode } from 'react';

import { Box } from '@material-ui/core';
import { useRouter } from 'next/router';

import { InfrastructureChargeStationIcon } from './InfrastructureChargeStationIcon';
import { InfrastructureChargeStationMobileItem } from './InfrastructureChargeStationMobileItem';
import { InfrastructureChargeStationsPerformance, TimespanCacheEdit } from '../../components';
import { Config, useConfigContext, useUserContext } from '../../contexts';
import { InfrastructureChargeStation, useInfrastructureChargeStationDataStore } from '../../hooks';
import {
  FormatMessage,
  useVkwFormatMessage,
  VkwBaseTableCell,
  VkwDataTable,
  VkwDataTableFieldConfig,
  VkwPerformanceGraph,
} from '../../library';

const renderPerformanceCell = (dataEntry: InfrastructureChargeStation): ReactNode => {
  const totalChargesValues = dataEntry.performance?.map(item => item.totalCharges) ?? [];

  if (totalChargesValues.length === 0) {
    totalChargesValues.push(0, 0);
  } else if (totalChargesValues.length === 1) {
    totalChargesValues.push(...totalChargesValues);
  }

  return (
    <VkwBaseTableCell style={{ paddingTop: '0' }} key="performance">
      <Box width="70px" height="42px">
        <VkwPerformanceGraph values={totalChargesValues} />
      </Box>
    </VkwBaseTableCell>
  );
};

const getTableFieldsConfig = (
  formatMessage: FormatMessage,
  config: Config
): VkwDataTableFieldConfig<InfrastructureChargeStation>[] => [
  {
    active: true,
    bold: true,
    label: formatMessage('ChargingPointName'),
    property: 'stationName',
    sortColumn: 'stationName',
  },
  {
    active: true,
    label: formatMessage('Location'),
    property: 'address',
    sortColumn: 'address',
  },
  {
    active: true,
    label: formatMessage('TotalCharges'),
    property: 'totalCharges',
    sortColumn: 'totalCharges',
  },
  {
    active: true,
    label: formatMessage('Performance'),
    property: 'performance',
    renderCustomCell: renderPerformanceCell,
  },
  {
    active: true,
    bold: true,
    hidden: config.hideCommission,
    label: formatMessage('AverageCompensation'),
    property: 'averageCompensation',
    sortColumn: 'averageCompensation',
  },
];

export const InfrastructureChargingStations = (): ReactElement => {
  const { config } = useConfigContext();
  const router = useRouter();
  const store = useInfrastructureChargeStationDataStore({ url: '/api/v1/InfrastructureChargeStations' });
  const formatMessage = useVkwFormatMessage();
  const user = useUserContext();

  const handleRedirect = (chargeStation: InfrastructureChargeStation): void => {
    router.push(`/infrastructure/[id]`, `/infrastructure/${chargeStation.chargePoints[0].evseId}`);
  };

  const filterOption = store.filterOptions.find(filterOption => filterOption.column === 'chargeDateRange');

  let min = new Date();
  let max = new Date();
  let values: Date[] = [];

  if (filterOption) {
    min = filterOption.configuration.min ? (filterOption.configuration.min as Date) : new Date();
    max = filterOption.configuration.max ? (filterOption.configuration.max as Date) : new Date();
    values = (store.filters.get(filterOption.column) as Date[]) ?? [];
  }

  return (
    <>
      {filterOption && (
        <TimespanCacheEdit
          maxTimespanValue={max}
          minTimespanValue={min}
          timespanValues={values}
          changeTimespan={values => store.changeFilter(filterOption.column, values)}
          cacheLocked={user.dataCacheState?.infrastructureChargeDetailRecordsLocked}
          cacheLastUpdate={user.dataCacheState?.infrastructureChargeDetailRecordsLastUpdate}
          updateCache={user.updateDataCache}
        />
      )}
      {store.initialized && store.totalRecords > 0 && store.performance && (
        <InfrastructureChargeStationsPerformance performance={store.performance} />
      )}
      <VkwDataTable
        initialized={store.initialized}
        loading={store.loading}
        loadAdditionalEntries={store.loadAdditionalEntries}
        page={store.page}
        pageSize={store.pageSize}
        pages={store.pages}
        data={store.data}
        totalRecords={store.totalRecords}
        hasMore={store.hasMore}
        setPage={store.setPage}
        setIsMobile={store.setIsMobile}
        tableFieldsConfig={getTableFieldsConfig(formatMessage, config)}
        noResultsDisplayText={formatMessage('NoResultsForInvoices')}
        noResultsWithActiveFilterDisplayText={formatMessage('NoResultsWithActiveFilter')}
        renderIcon={dataEntry => <InfrastructureChargeStationIcon infrastructureChargeStation={dataEntry} />}
        onRowClick={dataEntry => handleRedirect(dataEntry)}
        renderMobileItem={(dataEntry, i) => (
          <InfrastructureChargeStationMobileItem
            key={i}
            infrastructureChargeStation={dataEntry}
            onClick={() => handleRedirect(dataEntry)}
          />
        )}
        filters={store.filters}
        filterOptions={store.filterOptions}
        onChangeFilter={store.changeFilter}
        onClearFilters={store.clearFilters}
        sortColumn={store.sortColumn}
        sortDirection={store.sortDirection}
        setSortColumn={store.setSortColumn}
      />
    </>
  );
};
