import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconChargingstationOutline = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 32 32" fill={fill}>
        <path d="M 4.364 0.004 C 1.972 0.004 0.003 1.973 0.003 4.365 L 0.003 31.986 L 20.355 31.986 L 20.355 21.81 L 23.263 21.81 L 23.263 27.625 C 23.263 30.016 25.232 31.986 27.624 31.986 C 30.015 31.986 31.985 30.016 31.985 27.625 L 31.985 12.814 C 31.985 11.656 31.53 10.589 30.713 9.772 L 23.852 2.911 L 21.809 4.955 L 25.943 9.09 C 24.382 9.754 23.263 11.293 23.263 13.088 C 23.263 15.478 25.232 17.449 27.624 17.449 C 28.135 17.449 28.618 17.341 29.078 17.175 L 29.078 27.625 C 29.078 28.448 28.447 29.079 27.624 29.079 C 26.801 29.079 26.17 28.448 26.17 27.625 L 26.17 21.81 C 26.17 20.219 24.853 18.903 23.263 18.903 L 20.355 18.903 L 20.355 4.365 C 20.355 1.973 18.385 0.004 15.994 0.004 L 4.364 0.004 Z M 4.364 2.911 L 15.994 2.911 C 16.817 2.911 17.448 3.542 17.448 4.365 L 17.448 8.726 L 17.448 10.18 L 17.448 13.088 L 17.448 18.903 L 17.448 29.079 L 2.91 29.079 L 2.91 18.903 L 2.91 13.088 L 2.91 10.18 L 2.91 8.726 L 2.91 4.365 C 2.91 3.542 3.541 2.911 4.364 2.911 Z M 10.31 8.087 L 6.777 15.179 L 8.086 15.864 L 9.685 16.633 L 7.403 21.17 L 10.048 22.448 L 13.581 15.355 L 12.271 14.672 L 10.674 13.902 L 12.955 9.364 L 10.31 8.087 Z M 27.624 11.634 C 28.441 11.634 29.078 12.271 29.078 13.088 C 29.078 13.905 28.441 14.541 27.624 14.541 C 26.805 14.541 26.17 13.905 26.17 13.088 C 26.17 12.271 26.805 11.634 27.624 11.634 Z" />
      </svg>
    </VkwIcon>
  );
};
