import { AtRule } from 'csstype';

export const interRegular: AtRule.FontFace = {
  fontDisplay: 'swap',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 'normal',
  src: `
    url('/fonts/Inter-Regular.woff2') format('woff2'),
    url('/fonts/Inter-Regular.woff') format('woff')
  `,
};

export const interSemiBold: AtRule.FontFace = {
  fontDisplay: 'swap',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 'bold',
  src: `
    url('/fonts/Inter-SemiBold.woff2') format('woff2'),
    url('/fonts/Inter-SemiBold.woff') format('woff')
  `,
};

export const helveticaNowDisplayLight: AtRule.FontFace = {
  fontDisplay: 'swap',
  fontFamily: 'Helvetica Now Display',
  fontStyle: 'normal',
  fontWeight: '200',
  src: `
    url('/fonts/HelveticaNowDisplayLight.woff2') format('woff2'),
    url('/fonts/HelveticaNowDisplayLight.woff') format('woff')
  `,
};

export const helveticaNowDisplayMedium: AtRule.FontFace = {
  fontDisplay: 'swap',
  fontFamily: 'Helvetica Now Display',
  fontStyle: 'normal',
  fontWeight: '500',
  src: `
    url('/fonts/HelveticaNowDisplayMedium.woff2') format('woff2'),
    url('/fonts/HelveticaNowDisplayMedium.woff') format('woff')
  `,
};

export const helveticaNowTextBold: AtRule.FontFace = {
  fontDisplay: 'swap',
  fontFamily: 'Helvetica Now Text',
  fontStyle: 'normal',
  fontWeight: '700',
  src: `
    url('/fonts/HelveticaNowTextBold.woff2') format('woff2'),
    url('/fonts/HelveticaNowTextBold.woff') format('woff')
  `,
};

export const helveticaNowTextBoldItalic: AtRule.FontFace = {
  fontDisplay: 'swap',
  fontFamily: 'Helvetica Now Text',
  fontStyle: 'italic',
  fontWeight: '700',
  src: `
    url('/fonts/HelveticaNowTextBoldItalic.woff2') format('woff2'),
    url('/fonts/HelveticaNowTextBoldItalic.woff') format('woff')
  `,
};

export const helveticaNowTextItalic: AtRule.FontFace = {
  fontDisplay: 'swap',
  fontFamily: 'Helvetica Now Text',
  fontStyle: 'italic',
  fontWeight: '400',
  src: `
    url('/fonts/HelveticaNowTextItalic.woff2') format('woff2'),
    url('/fonts/HelveticaNowTextItalic.woff') format('woff')
  `,
};

export const helveticaNowTextRegular: AtRule.FontFace = {
  fontDisplay: 'swap',
  fontFamily: 'Helvetica Now Text',
  fontStyle: 'normal',
  fontWeight: '400',
  src: `
    url('/fonts/HelveticaNowTextRegular.woff2') format('woff2'),
    url('/fonts/HelveticaNowTextRegular.woff') format('woff')
  `,
};

export const helveticaNowTextMedium: AtRule.FontFace = {
  fontDisplay: 'swap',
  fontFamily: 'Helvetica Now Text',
  fontStyle: 'normal',
  fontWeight: '500',
  src: `
    url('/fonts/HelveticaNowTextMedium.woff2') format('woff2'),
    url('/fonts/HelveticaNowTextMedium.woff') format('woff')
  `,
};
