import DateIoAdapter from '@date-io/dayjs';
// eslint-disable-next-line import/no-unresolved
import { DateType } from '@date-io/type';
import defaultDayjs, { ConfigType } from 'dayjs';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat';

import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/it';

defaultDayjs.extend(customParseFormatPlugin);
defaultDayjs.extend(localizedFormatPlugin);
defaultDayjs.extend(isBetweenPlugin);
defaultDayjs.extend(duration);

export type VkwDateIoConfigType = ConfigType;
export type VkwDateIoDateType = DateType;

export const VkwDateIo = DateIoAdapter;

export const vkwDayjs = defaultDayjs;
export const vkwDateIo = new DateIoAdapter({ instance: defaultDayjs, locale: 'de' });
