import React, { ReactElement } from 'react';

import { Box, Typography, makeStyles, Tooltip } from '@material-ui/core';

import { ChargeStationRouteLink } from './ChargeStationRouteLink';
import { ChargeStationDetails, ChargeStationFavoriteAction } from '../../containers';
import { ChargeStation, useChargeStationPin } from '../../hooks';
import { VkwOverlay, VkwTheme, getVkwTypographyStyle, useVkwFormatMessage } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  address: {
    ...getVkwTypographyStyle('text14', theme),
    paddingTop: theme.spacing(1),
  },
  openingTimeText: {
    ...getVkwTypographyStyle('text16', theme),
  },
  pin: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

interface ChargeStationDetailsOverlayProps {
  chargeStation: ChargeStation;
  currentPosition: google.maps.LatLngLiteral;
  onClose: () => void;
}

export const ChargeStationDetailsOverlay = ({
  chargeStation,
  currentPosition,
  onClose,
}: ChargeStationDetailsOverlayProps): ReactElement => {
  const styles = useStyles();
  const chargeStationPin = useChargeStationPin(chargeStation.chargePoints, 'small');
  const formatMessage = useVkwFormatMessage();
  return (
    <VkwOverlay onClose={onClose} open actions={<ChargeStationFavoriteAction chargeStation={chargeStation} />}>
      <Box display="flex" flexWrap="nowrap" justifyContent="space-between" paddingBottom={4}>
        <Box display="flex" flexDirection="column" alignItems="left" paddingRight={1}>
          <Box display="flex">
            <Box maxWidth="275px">
              <Typography variant="h6" style={{ overflowWrap: 'break-word' }}>
                {chargeStation.stationNameOriginal}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Tooltip title={formatMessage(`ChargeStationStatus${chargeStationPin.status}`)} arrow placement="bottom">
                <img className={styles.pin} src={chargeStationPin.url} alt="" />
              </Tooltip>
            </Box>
          </Box>
          <Typography component="span" className={styles.address} color="textSecondary">
            {chargeStation.address}
          </Typography>
        </Box>
        <Box paddingLeft={1}>
          <ChargeStationRouteLink chargeStation={chargeStation} currentPosition={currentPosition} />
        </Box>
      </Box>
      <ChargeStationDetails chargeStation={chargeStation} />
    </VkwOverlay>
  );
};
