import { ParsedUrlQuery } from 'querystring';

import React, { ReactElement, useEffect, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';
import { GoogleMap, LoadScriptProps, useLoadScript } from '@react-google-maps/api';
import { useRouter } from 'next/router';
import { View } from 'react-native';

import { DEFAULT_ZOOM } from './const';
import { Controls } from './Controls';
import { Markers } from './Markers';
import { HelpWizardStep } from '../../components/rn';
import { HelpWizardSteps } from '../../components/rn/HelpWizard/helpWizardSteps';
import { useConfigContext } from '../../contexts';
import { Assets } from '../../globals/configureAssets';
import { useChargeAtlasStore, useGeoLocation } from '../../hooks';
import {
  useIFrame,
  useVkwFormatMessage,
  useVkwTheme,
  VkwButton,
  VkwTheme,
  VkwLoaderV2,
  useVkwAssets,
} from '../../library';
import { useIsRenderedInIFrame } from '../../library/hooks/useIsRenderedInIFrame';

const useStyles = makeStyles<VkwTheme>(theme => ({
  buttonControl: {
    boxShadow: `0 -2px 4px 0 rgba(0, 0, 0, 0.2)`,
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },

    zIndex: theme.zIndex.speedDial,
  },
  mapContainer: {
    flexGrow: 1,
  },
}));

const options: google.maps.MapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  minZoom: 2,
  streetViewControl: false,
  zoomControl: false,
  zoomControlOptions: {
    position: 8,
  },
};

const libraries: LoadScriptProps['libraries'] = ['places'];

export const ChargeAtlas = (): ReactElement => {
  const theme = useVkwTheme();
  const assets = useVkwAssets<Assets>();
  const styles = useStyles();
  const router = useRouter();
  const isIFrame = useIFrame();
  const hideLogin = useIsRenderedInIFrame();

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [showNearbyStations, setShowNearbyStations] = useState(false);
  const [manual, setManual] = useState(false);

  const configContext = useConfigContext();
  const geoLocation = useGeoLocation();

  const formatMessage = useVkwFormatMessage();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: configContext.googleMapsApiKey,
    libraries,
  });

  const store = useChargeAtlasStore({
    defaultPosition: geoLocation.coords
      ? { lat: geoLocation.coords.latitude, lng: geoLocation.coords.longitude }
      : configContext.chargeAtlasDefaultPosition,
    url: '/api/v1/chargeStations',
  });

  const updateUrl = (): void => {
    if (!map) {
      return;
    }

    const center = map.getCenter();

    if (!center) {
      return;
    }

    const query: ParsedUrlQuery = {
      lat: `${center.lat()}`,
      lng: `${center.lng()}`,
      zoom: `${map.getZoom() ?? DEFAULT_ZOOM}`,
    };

    if (store && store.selectedChargeStation?.chargePoints) {
      query.evseId = store.selectedChargeStation.chargePoints[0].evseId;
    }

    if (isIFrame) {
      query.iframe = 'true';
    }

    if (hideLogin) {
      query.hideLogin = 'true';
    }

    router.replace(
      {
        pathname: '/',
        query,
      },
      undefined,
      { shallow: true }
    );
  };

  useEffect(() => {
    if (!geoLocation.coords) {
      return;
    }

    store.setCurrentPosition({
      lat: geoLocation.coords.latitude,
      lng: geoLocation.coords.longitude,
    });
  }, [geoLocation.coords]);

  const handleIdle = (): void => {
    if (!map) {
      return;
    }

    const center = map.getCenter();

    if (!center) {
      return;
    }

    if (center.equals(new google.maps.LatLng(store.currentPosition))) {
      setManual(false);
    } else {
      setManual(true);
    }

    const bounds = map.getBounds();

    if (!bounds) {
      return;
    }

    store.changeBounds(bounds, map.getZoom() ?? DEFAULT_ZOOM);

    updateUrl();
  };

  if (loadError) {
    throw loadError;
  }

  if (!isLoaded) {
    return (
      <Box display="flex" flexDirection="column" height="100%" width="100%" alignItems="center" justifyContent="center">
        <VkwLoaderV2 />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <HelpWizardStep step={HelpWizardSteps.start}>
        <View />
      </HelpWizardStep>
      <GoogleMap
        options={{
          ...options,
          gestureHandling: assets.mapGestureHandling,
          styles: theme.googleMapsStyles,
        }}
        mapContainerClassName={styles.mapContainer}
        zoom={12}
        center={manual ? map?.getCenter() : store.currentPosition}
        onIdle={handleIdle}
        onLoad={map => setMap(map)}
      >
        <Controls store={store} showNearbyStations={showNearbyStations} />
        <Markers
          chargeStations={store.chargeStations}
          currentPosition={store.currentPosition}
          searchPosition={store.searchPosition}
          onChargeStationClick={store.setSelectedChargeStation}
          onChargeStationListClick={store.setSelectedChargeStationList}
          selectedChargeStation={store.selectedChargeStation}
          selectedChargeStationList={store.selectedChargeStationList}
        />
      </GoogleMap>

      {!isIFrame && (
        <Box className={styles.buttonControl}>
          <VkwButton fullWidth onClick={() => setShowNearbyStations(!showNearbyStations)}>
            {formatMessage(showNearbyStations ? 'ShowAtlas' : 'ShowList')}
          </VkwButton>
        </Box>
      )}
    </Box>
  );
};
