import { Instance, SnapshotOut, types } from 'mobx-state-tree';

export const UserStoreModel = types
  .model('UserStore')
  .props({
    email: types.maybe(types.string),
    favoriteChargePoints: types.array(types.string),
    hasChargingContracts: false,
    hasInfrastructureContracts: false,
    name: types.maybe(types.string),
    role: types.maybe(types.string),
    selectedCustomerNumber: types.maybe(types.string),
    theme: types.maybe(types.string),
  })
  .actions(store => ({
    resetUserStore() {
      store.name = undefined;
      store.email = undefined;
      store.selectedCustomerNumber = undefined;
      store.hasChargingContracts = false;
      store.hasInfrastructureContracts = false;
      store.theme = undefined;
      store.role = undefined;
      store.favoriteChargePoints = types.array(types.string).create([]);
    },
    setCustomerNumber(customerNumber: string) {
      store.selectedCustomerNumber = customerNumber;
    },
    setFavoriteChargePoints(favoriteChargePoints: string[]) {
      store.favoriteChargePoints.replace(favoriteChargePoints);
    },
    setInfo(hasChargingContracts: boolean, hasInfrastructureContracts: boolean) {
      store.hasChargingContracts = hasChargingContracts;
      store.hasInfrastructureContracts = hasInfrastructureContracts;
    },
    setProfile(name: string, email: string) {
      store.name = name;
      store.email = email;
    },
  }));

export type UserStore = Instance<typeof UserStoreModel>;
export type UserStoreSnapshot = SnapshotOut<typeof UserStoreModel>;
