import axios, { AxiosResponse } from 'axios';

import { getEnvWebapiBaseUrl } from '../util/helpers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendWebApiGetRequest = async <T = any>(
  path: string,
  accessToken?: string,
  queryParams?: Record<string, string | undefined>
): Promise<AxiosResponse<T>> => {
  const headers: HeadersInit = {};

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  }

  const url = `${getEnvWebapiBaseUrl()}${path}`;

  return axios.get(url, {
    headers,
    params: queryParams,
  });
};
