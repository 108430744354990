import { ParsedUrlQueryInput } from 'querystring';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import axios, { AxiosResponse } from 'axios';
import { useRouter } from 'next/router';

import { useHandleAxiosResponse } from '..';
import { useUserContext } from '../../contexts';
import { jsonParseDateReviver, useVkwIsSmallestBreakpoint, VkwFilterOption } from '../../library';
import { deleteFilterCookie, getFilterCookie, hasFilterCookie, setFilterCookie } from '../../util/cookies';

interface BaseDataStoreProps {
  url: string;
  pageSize?: number;
  defaultStartLoading?: boolean;
  defaultSortColumn?: string;
  defaultSortDirection?: 'asc' | 'desc';
  processCustomData?: (response?: AxiosResponse) => void;
  processAxiosRequestParams?: (axiosRequestParams: AxiosRequestParams) => Record<string, unknown>;
  useQueryFilterParams?: boolean;
  filterCookieKey?: string;
}

interface AxiosRequestParams {
  sortBy: string | undefined;
  sortDirection: 'asc' | 'desc' | undefined;
  page: number;
  filter: string | undefined;
  pageSize: number;
  customerId: string | undefined;
}

interface BaseDataStore<T> {
  reload: () => void;
  filterOptions: VkwFilterOption[];
  filters: Map<string, string[] | Date[] | number[]>;
  clearFilters: () => void;
  changeFilter: (name: string, value: string[] | Date[] | number[]) => void;
  sortColumn: string | undefined;
  sortDirection: 'asc' | 'desc';
  setSortColumn: (sortColumn?: string) => void;
  pageSize: number;
  pages: number;
  page: number;
  setPage: (page: number) => void;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  data: T[];
  totalRecords: number;
  hasMore: boolean;
  initialized: boolean;
  loading: boolean;
  loadAdditionalEntries: boolean;
  startLoading: () => void;
  axiosRequestParams: AxiosRequestParams;
}

export const useBaseDataStore = <T>({
  defaultSortColumn,
  defaultSortDirection = 'asc',
  defaultStartLoading = true,
  filterCookieKey,
  pageSize = 10,
  processAxiosRequestParams,
  processCustomData,
  url,
  useQueryFilterParams = true,
}: BaseDataStoreProps): BaseDataStore<T> => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  });

  let filterCookie;

  if (filterCookieKey && hasFilterCookie(filterCookieKey)) {
    filterCookie = new Map(Object.entries(JSON.parse(getFilterCookie(filterCookieKey) as string)));
  }

  const handleAxiosResponse = useHandleAxiosResponse();
  const router = useRouter();
  const user = useUserContext();
  const vkwIsSmallestBreakpoint = useVkwIsSmallestBreakpoint();

  const [startLoading, setStartLoading] = useState(defaultStartLoading);
  const [sortColumn, setSortColumn] = useState(defaultSortColumn);
  const [isMobile, setIsMobile] = useState(vkwIsSmallestBreakpoint);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(defaultSortDirection);
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [page, setPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState<VkwFilterOption[]>([]);
  const [filters, setFilters] = useState(new Map<string, string[] | Date[] | number[]>(filterCookie ?? new Map()));
  const [loadAdditionalEntries, setLoadAdditionalEntries] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [data, setData] = useState<T[]>([]);

  const axiosRequestParams: AxiosRequestParams = useMemo(() => {
    return {
      customerId: user.selectedCustomer?.id,
      filter: filters.size >= 1 ? JSON.stringify(Object.fromEntries(filters)) : undefined,
      page,
      pageSize,
      sortBy: sortColumn,
      sortDirection: sortColumn ? sortDirection : undefined,
    };
  }, [sortColumn, sortDirection, page, filters, pageSize, user.selectedCustomer?.id]);

  const pages = useMemo(() => Math.ceil(totalRecords / pageSize), [totalRecords, pageSize]);

  const load = useCallback((): void => {
    setLoading(true);

    handleAxiosResponse(
      () =>
        axios.get(url, {
          params: processAxiosRequestParams ? processAxiosRequestParams(axiosRequestParams) : axiosRequestParams,
        }),
      {
        success: (response: AxiosResponse) => {
          if (!mounted.current) {
            return;
          }

          const responseData = response.data.items ?? response.data.data;
          const responseTotal = response.data.total ?? response.data.meta.total;

          if (isMobile && loadAdditionalEntries) {
            setData(prevData => [...prevData, ...responseData]);
          } else {
            setData(responseData);
          }

          setFilterOptions(response.data.filterOptions ? response.data.filterOptions : []);
          setTotalRecords(responseTotal);
          setHasMore(page < Math.ceil(responseTotal / pageSize));

          processCustomData?.(response);

          if (!initialized) {
            setInitialized(true);
          }

          setLoading(false);

          if (!loadAdditionalEntries) {
            setLoadAdditionalEntries(true);
          }
        },
      }
    );
  }, [
    url,
    processAxiosRequestParams,
    axiosRequestParams,
    mounted.current,
    isMobile,
    loadAdditionalEntries,
    page,
    pageSize,
    processCustomData,
    initialized,
    loadAdditionalEntries,
  ]);

  const reload = useCallback((): void => {
    if (loading) {
      return;
    }

    setData([]);
    processCustomData?.();

    if (page !== 1) {
      setPage(1);
    }

    load();
  }, [loading, page, load]);

  const clearFilters = useCallback(() => {
    const unhiddenFilterColumns = filterOptions
      .filter(filterOption => filterOption.hidden !== true)
      .map(filterOption => filterOption.column);

    const unhiddenFilters = Array.from(filters.keys()).filter(filterKey => unhiddenFilterColumns.includes(filterKey));

    const tempFilters = new Map(filters);

    unhiddenFilters.forEach(filter => tempFilters.delete(filter));

    setFilters(tempFilters);
  }, [filterOptions, filters]);

  const changeFilter = useCallback(
    (name: string, value: string[] | Date[] | number[]): void => {
      const tempFilters = new Map(filters);

      if (value.length < 1) {
        tempFilters.delete(name);

        setFilters(tempFilters);

        return;
      }

      tempFilters.set(name, value);

      setFilters(tempFilters);
    },
    [filters]
  );

  const setSortColumnCallback = useCallback(
    (newSortColumn: string | undefined): void => {
      if (newSortColumn === sortColumn) {
        setSortDirection(prevSortDirection => (prevSortDirection === 'asc' ? 'desc' : 'asc'));
      } else {
        setSortDirection('asc');
      }

      setSortColumn(newSortColumn);
    },
    [sortColumn]
  );

  const setPageCallback = useCallback(
    (newPage: number): void => {
      setPage(Math.max(1, Math.min(newPage, pages)));
    },
    [pages]
  );

  useEffect(() => {
    if (router.query.filters && useQueryFilterParams) {
      const filters = JSON.parse(router.query.filters as string, jsonParseDateReviver);

      setFilters(new Map(Object.entries(filters)));
    }
  }, []);

  useEffect(() => {
    if (useQueryFilterParams) {
      const query: ParsedUrlQueryInput = { ...router.query };

      if (axiosRequestParams.filter) {
        query.filters = axiosRequestParams.filter;

        if (filterCookieKey) {
          setFilterCookie(filterCookieKey, axiosRequestParams.filter);
        }
      } else {
        delete query.filters;

        if (filterCookieKey) {
          deleteFilterCookie(filterCookieKey);
        }
      }

      router.replace(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true }
      );
    }
  }, [axiosRequestParams.filter]);

  useEffect(() => {
    if (startLoading) {
      setData([]);
      processCustomData?.();
      setLoadAdditionalEntries(false);
      setPage(1);
    }
  }, [sortDirection, sortColumn, filters, user.selectedCustomer?.id]);

  useEffect(() => {
    if (startLoading && page !== 1) {
      setData([]);
      processCustomData?.();
      setLoadAdditionalEntries(false);
      setPage(1);
    }
  }, [isMobile]);

  useEffect(() => {
    if (defaultStartLoading) {
      setStartLoading(true);
    }
  }, []);

  useEffect(() => {
    if (startLoading) {
      load();
    }
  }, [startLoading, filters, sortColumn, sortDirection, page, user.selectedCustomer?.id]);

  return {
    axiosRequestParams,
    changeFilter,
    clearFilters,
    data,
    filterOptions,
    filters,
    hasMore,
    initialized,
    isMobile,
    loadAdditionalEntries,
    loading,
    page,
    pageSize,
    pages,
    reload,
    setIsMobile,
    setPage: setPageCallback,
    setSortColumn: setSortColumnCallback,
    sortColumn,
    sortDirection,
    startLoading: () => setStartLoading(true),
    totalRecords,
  };
};
