import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconChargingOutline = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g>
        <path
          fill={fill}
          d="M12 0c6.617 0 12 5.383 12 12 0 3.336-1.336 6.436-3.764 8.727l-.727.687-1.373-1.455.727-.687C20.886 17.363 22 14.78 22 12c0-5.514-4.486-10-10-10S2 6.486 2 12c0 5.177 3.954 9.447 9 9.95V19h2v5h-1C5.383 24 0 18.617 0 12S5.383 0 12 0zm2 5c.513 0 .936.386.993.883L15 6v2h2c.513 0 .936.386.993.883L18 9v2.857C18 15.244 15.32 18 12 18s-6-2.756-6-6.143V9c0-.552.448-1 1-1h2V6c0-.552.448-1 1-1 .513 0 .936.386.993.883L11 6v2h2V6c0-.552.448-1 1-1zm2 5H8v1.857c0 2.225 1.69 4.03 3.8 4.138L12 16c2.203 0 4-1.849 4-4.143V10z"
        />
      </g>
    </VkwIcon>
  );
};
