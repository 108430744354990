import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugCeeBlau = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M12 0c6.63 0 12 5.376 12 12s-5.37 12-12 12S0 18.624 0 12 5.37 0 12 0zm0 15a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 0-5zM7.5 7a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 0-5zm9 0a2.5 2.5 0 0 0 0 5 2.5 2.5 0 0 0 0-5z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </VkwIcon>
  );
};
