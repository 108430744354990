import deepmerge from 'deepmerge';
import { setLocale } from 'yup';
import {
  ArrayLocale,
  BooleanLocale,
  DateLocale,
  MixedLocale,
  NumberLocale,
  ObjectLocale,
  StringLocale,
} from 'yup/lib/locale';

interface LocaleObject {
  mixed?: MixedLocale;
  string?: StringLocale;
  number?: NumberLocale;
  date?: DateLocale;
  boolean?: BooleanLocale;
  object?: ObjectLocale;
  array?: ArrayLocale;
}

export const configureVkwForm = (customLocale?: LocaleObject): void => {
  const defaultLocale: LocaleObject = {
    mixed: {
      notOneOf: 'Validation_Invalid',
      oneOf: 'Validation_Invalid',
      required: 'Validation_Required',
    },
    number: {
      max: 'Validation_TooHigh',
      min: 'Validation_TooLow',
    },
    string: {
      email: 'Validation_InvalidEmail',
      matches: 'Validation_Invalid',
      max: 'Validation_TooLong',
      min: 'Validation_TooShort',
    },
  };

  setLocale(deepmerge<LocaleObject>(defaultLocale, customLocale ?? {}));
};
