import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import Axios from 'axios';
import { FormikContextType, useFormik } from 'formik';
import * as Yup from 'yup';

import { POSSIBLE_RESPONSE_CHANNELS } from '.';
import {
  FIELD_MAX_LENGTH,
  PaymentTypes,
  PHONE_REGEX,
  POSSIBLE_PAYMENT_TYPES,
  POSSIBLE_SUBJECTS_CCA,
  ResponseChannels,
  TicketStatus,
  TicketSubjectCca,
} from './const';
import {
  ChargePointColorCodePicker,
  ColorCode,
  FileUploadControl,
  FileUploadError,
  Form,
  FormSectionHeader,
} from '../../components';
import { useUserContext } from '../../contexts';
import { useHandleAxiosResponse } from '../../hooks';
import {
  useVkwFormatMessage,
  VkwAutocomplete,
  VkwButton,
  VkwDateTimePicker,
  VkwSelect,
  VkwSelectItem,
  VkwTextField,
} from '../../library';
import { Attachment, createFileAttachments } from '../../util/Files';

export interface NewTicketCcaPresetData {
  subject: TicketSubjectCca | 'null';
  evseId: string | null;
}

export interface NewTicketCcaFormData extends NewTicketCcaPresetData {
  customer: string;
  eMailAddress: string;
  feedbackSms: string;
  description: string;
  paymentType: PaymentTypes | 'null';
  authenticationCardUid: string | null;
  otherProviderInfo: string;
  chargePointColorCode: ColorCode[];
  closingComment: string;
  status: TicketStatus;
  files: FileList | null;
  incidentDate: Date;
  responseChannel: ResponseChannels;
  vehicleType: string;
}

interface NewTicketCcaData {
  customer: string;
  eMailAddress: string | null;
  feedbackSms: string | null;
  subject: TicketSubjectCca;
  description: string | null;
  paymentType: PaymentTypes | null;
  authenticationCardUid: string | null;
  evseId: string | null;
  otherProviderInfo: string | null;
  chargePointColorCode: string | null;
  closingComment: string | null;
  status: TicketStatus;
  attachments: Attachment[] | null;
  incidentDate: Date;
  responseChannel: ResponseChannels;
  vehicleType: string;
}

const getInitDescriptionPayment = () => {
  return 'PayPal\n\tName:\n\tDatum/Uhrzeit:\n\tTransaktionsnummer:\n\tEmail-Addresse:\nKreditkarte\n\tName:\n\tDatum/Uhrzeit:\n\tLetzte 4 Stellen der Kreditkartennummer:\n\tKreditkartenanbieter:';
};
const initDate = new Date();

const getDefaultFormDataNewTicketCca = (presetValues?: NewTicketCcaPresetData): NewTicketCcaFormData => ({
  authenticationCardUid: null,
  chargePointColorCode: ['undefined', 'undefined', 'undefined', 'undefined'],
  closingComment: '',
  customer: '',
  description:
    (presetValues?.subject && (presetValues.subject as TicketSubjectCca)) === 'Payment'
      ? getInitDescriptionPayment()
      : '',
  eMailAddress: '',
  evseId: presetValues?.evseId ? (presetValues.evseId as string) : null,
  feedbackSms: '',
  files: null,
  incidentDate: initDate,
  otherProviderInfo: '',
  paymentType: 'null',
  responseChannel: 'No',
  status: 'Created',
  subject: presetValues?.subject ? (presetValues.subject as TicketSubjectCca) : 'null',
  vehicleType: '',
});

interface NewTicketCcaProps {
  presetValues: NewTicketCcaPresetData;
}

export const NewTicketCca = ({ presetValues }: NewTicketCcaProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const handleAxiosResponse = useHandleAxiosResponse();
  const userContext = useUserContext();
  const [attachmentError, setAttachmentError] = useState<FileUploadError | null>(null);

  const handleCloseNewTicket = (): void => {
    setAttachmentError(null);
  };

  const handleCreate = async (formik: FormikContextType<NewTicketCcaFormData>): Promise<void> => {
    formik.setSubmitting(true);
    setAttachmentError(null);
    const { values } = formik;
    const attachments = await createFileAttachments(values.files);

    const newTicketData: NewTicketCcaData = {
      attachments,
      authenticationCardUid: values.authenticationCardUid,
      chargePointColorCode: values.chargePointColorCode.join(','),
      closingComment: values.closingComment.length > 0 ? values.closingComment : null,
      customer: values.customer,
      description: values.description.length > 0 ? values.description : null,
      eMailAddress: values.eMailAddress.length > 0 ? values.eMailAddress : null,
      evseId: values.evseId ? values.evseId.substring(0, values.evseId.indexOf('-') - 1) : null,
      feedbackSms: values.feedbackSms.length > 0 ? values.feedbackSms : null,
      incidentDate: values.incidentDate,
      otherProviderInfo: values.otherProviderInfo.length > 0 ? values.otherProviderInfo : null,
      paymentType: values.paymentType !== 'null' ? values.paymentType : null,
      responseChannel:
        values.responseChannel === 'Phone' || values.responseChannel === 'EMail' ? values.responseChannel : 'No',
      status: values.status,
      subject: values.subject !== 'null' ? values.subject : 'Request',
      vehicleType: values.vehicleType,
    };

    await handleAxiosResponse(
      () =>
        Axios.post('/api/support/tickets-cca', newTicketData, {
          headers: {
            'Accept-Language': 'de',
          },
          params: { customerId: userContext.selectedCustomer?.id },
        }),
      {
        bodyExceededSizeLimit: () => {
          setAttachmentError({
            message: formatMessage('FileAttachmentsExceededSizeLimitMessage'),
            title: formatMessage('FileAttachmentsExceededSizeLimitTitle'),
          });
        },
        formik,
        success: () => {
          formik.setStatus({
            msg: formatMessage('SupportSuccessMessage'),
            sent: true,
          });

          handleCloseNewTicket();
        },
        validation: response => {
          if (response.data.attachments) {
            setAttachmentError({
              message: formatMessage('InvalidFileAttachmentsMessage'),
              title: formatMessage('InvalidFileAttachmentsTitle'),
            });
          }
        },
      },
      {
        bodyExceededSizeLimitSnackbarMessage: formatMessage('SupportMailSendingError'),
        errorSnackbarMessage: formatMessage('SupportMailSendingError'),
        successSnackbarMessage: formatMessage('SuccessfullySaved'),
        tooManyRequestsSnackbarMessage: formatMessage('SupportMailSendingError'),
      }
    );
  };

  const formik = useFormik({
    initialValues: getDefaultFormDataNewTicketCca(presetValues),
    onReset: () => {
      handleCloseNewTicket();
    },
    onSubmit: async () => {
      await handleCreate(formik);
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      closingComment: Yup.string().when('status', {
        is: (status: string) => status === 'closed',
        otherwise: Yup.string(),
        then: Yup.string().required(),
      }),
      customer: Yup.string().required(),
      description: Yup.string().required(),
      eMailAddress: Yup.lazy(value => (!value ? Yup.string() : Yup.string().email())),
      feedbackSms: Yup.lazy(value =>
        !value
          ? Yup.string().required()
          : Yup.string().matches(PHONE_REGEX, formatMessage('PhoneNumberErrorMessage')).required()
      ),
      subject: Yup.string().notOneOf(['null']),
    }),
  });

  const getOptionsEvseId = useCallback((searchString: string): Promise<string[]> => {
    return new Promise(resolve => {
      if (searchString.length < 1) {
        resolve([]);
        return;
      }

      handleAxiosResponse(
        () =>
          Axios.get('/api/v1/ChargeStations/SearchInEvseIdAddressStationName', {
            params: {
              limit: 5,
              searchTerm: searchString,
            },
          }),
        {
          success: response => resolve(response.data),
        }
      );
    });
  }, []);

  useEffect(() => {
    async function setEvseIdFromApi() {
      if (formik.values.evseId && formik.values.evseId !== '') {
        formik.setFieldValue('evseId', (await getOptionsEvseId(formik.values.evseId))[0]);
      }
    }

    setEvseIdFromApi();
  }, []);

  return formik.status && formik.status.msg && formik.status.sent ? (
    <>
      <p>{formik.status.msg}</p>
      <VkwButton
        variant="contained"
        color="default"
        onClick={() => {
          formik.resetForm();
        }}
      >
        {formatMessage('SupportNewRequest')}
      </VkwButton>
    </>
  ) : (
    <Form formik={formik} submitButtonText={formatMessage('CreateTicket')}>
      <VkwSelect
        name="subject"
        value={formik.values.subject}
        label={formatMessage('SelectTicketSubject')}
        onChange={e => {
          formik.handleChange(e);
          if (e.target.value === 'Payment') {
            if (!formik.touched.description) {
              formik.setFieldValue('description', getInitDescriptionPayment());
            }
          } else if (!formik.touched.description) {
            formik.setFieldValue('description', '');
          }
        }}
        onBlur={formik.handleBlur}
        error={formik.errors.subject && formik.touched.subject ? formatMessage(formik.errors.subject) : undefined}
        chooseOptionValue="null"
        items={POSSIBLE_SUBJECTS_CCA.map((item): VkwSelectItem => {
          return { label: formatMessage(`TicketSubjectCca${item}`), value: item };
        })}
      />

      <VkwDateTimePicker
        date={formik.values.incidentDate}
        label={formatMessage('DateTimeOfEvent')}
        onChange={date => formik.setFieldValue('incidentDate', date)}
      />

      <VkwTextField
        name="customer"
        value={formik.values.customer}
        label={formatMessage('Customer')}
        error={!!(formik.errors.customer && formik.touched.customer)}
        onChange={formik.handleChange}
        fullWidth
        onBlur={formik.handleBlur}
      />

      <VkwTextField
        name="eMailAddress"
        value={formik.values.eMailAddress}
        label={formatMessage('EMail')}
        type="email"
        error={!!(formik.errors.eMailAddress && formik.touched.eMailAddress)}
        helperText={formik.errors.eMailAddress ? formatMessage(formik.errors.eMailAddress) : undefined}
        onChange={formik.handleChange}
        fullWidth
        maxLength={FIELD_MAX_LENGTH.EMAIL_ADDRESS}
        onBlur={formik.handleBlur}
      />

      <VkwTextField
        name="feedbackSms"
        id="feedbackSms"
        value={formik.values.feedbackSms}
        label={formatMessage('PhoneNumber')}
        error={!!(formik.errors.feedbackSms && formik.touched.feedbackSms)}
        helperText={formik.errors.feedbackSms ? formatMessage('PhoneNumberErrorMessage') : undefined}
        onChange={formik.handleChange}
        fullWidth
        onBlur={formik.handleBlur}
      />

      <VkwSelect
        name="responseChannel"
        value={formik.values.responseChannel}
        label={formatMessage('FeedbackDesired')}
        onChange={formik.handleChange}
        items={POSSIBLE_RESPONSE_CHANNELS.map((item): VkwSelectItem => {
          return { label: formatMessage(item), value: item };
        })}
      />

      <VkwTextField
        name="description"
        value={formik.values.description}
        label={formatMessage('YourDescriptionForUs')}
        error={!!(formik.errors.description && formik.touched.description)}
        helperText={formik.errors.description ? formatMessage(formik.errors.description) : undefined}
        onChange={formik.handleChange}
        multiline
        fullWidth
        maxLength={FIELD_MAX_LENGTH.DESCRIPTION}
        onBlur={formik.handleBlur}
      />

      <FileUploadControl
        onChange={event => {
          const { files } = event.target;

          formik.setFieldValue('files', files);
        }}
        onDelete={() => formik.setFieldValue('files', null)}
        files={formik.values.files}
        error={attachmentError}
      />

      <FormSectionHeader>{formatMessage('ChargePoint')}</FormSectionHeader>

      <VkwAutocomplete
        name="evseId"
        label={formatMessage('EvseIdOrLocation')}
        fullWidth
        value={formik.values.evseId}
        setValue={async value => {
          await formik.setFieldValue('evseId', value);
        }}
        getOptions={getOptionsEvseId}
      />

      <ChargePointColorCodePicker
        name="chargePointColorCode"
        selectValues={formik.values.chargePointColorCode}
        onChange={changeValues => formik.setFieldValue('chargePointColorCode', changeValues)}
      />

      <FormSectionHeader>{formatMessage('PaymentType')}</FormSectionHeader>

      <VkwSelect
        name="paymentType"
        value={formik.values.paymentType}
        label={formatMessage('SelectPaymentType')}
        onChange={formik.handleChange}
        chooseOptionValue="null"
        items={POSSIBLE_PAYMENT_TYPES.map((item): VkwSelectItem => {
          return { label: formatMessage(`PaymentType${item}`), value: item };
        })}
      />

      <VkwTextField
        name="authenticationCardUid"
        label={formatMessage('AuthenticationCardUid')}
        fullWidth
        value={formik.values.authenticationCardUid}
      />

      {formik.values.paymentType === 'OtherTypes' && (
        <VkwTextField
          name="otherProviderInfo"
          value={formik.values.otherProviderInfo}
          label={formatMessage('OtherProviderInfo')}
          onChange={formik.handleChange}
          fullWidth
          onBlur={formik.handleBlur}
        />
      )}

      <VkwTextField
        name="vehicleType"
        value={formik.values.vehicleType}
        label={formatMessage('VehicleModel')}
        error={!!(formik.errors.vehicleType && formik.touched.vehicleType)}
        helperText={formik.errors.vehicleType ? formatMessage(formik.errors.vehicleType) : undefined}
        onChange={formik.handleChange}
        multiline
        fullWidth
        maxLength={FIELD_MAX_LENGTH.DESCRIPTION}
      />

      <FormSectionHeader>{formatMessage('Ticket')}</FormSectionHeader>

      <VkwSelect
        name="status"
        value={formik.values.status}
        label={formatMessage('TicketStatus')}
        onChange={formik.handleChange}
        items={[
          { label: formatMessage('TicketStatusCreated'), value: 'Created' },
          { label: formatMessage('TicketStatusClosed'), value: 'Closed' },
        ]}
      />

      {formik.values.status === 'Closed' && (
        <VkwTextField
          name="closingComment"
          value={formik.values.closingComment}
          error={!!(formik.errors.closingComment && formik.touched.closingComment)}
          helperText={formik.errors.closingComment ? formatMessage(formik.errors.closingComment) : undefined}
          label={formatMessage('YourSolution')}
          onChange={formik.handleChange}
          multiline
          fullWidth
          maxLength={FIELD_MAX_LENGTH.SOLUTION}
          onBlur={formik.handleBlur}
        />
      )}
    </Form>
  );
};
