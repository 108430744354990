import { useCallback, useEffect, useMemo, useState } from 'react';

import axios from 'axios';
import fileDownload from 'js-file-download';

import { useBaseDataStore } from './useBaseDataStore';
import { useUserContext } from '../../contexts';
import { vkwDateIo, VkwFilterOption } from '../../library';
import { useHandleAxiosResponse } from '../useHandleAxiosResponse';

export interface ChargeDetailRecord {
  id: string;
  isShared: boolean;
  chargingPointLocation: string;
  sharingUsers: string[];
}

export interface ChargeDetailRecordsStatistic {
  co2Saving: number;
  consumedEnergy: number;
  totalDurationInSeconds: number;
}

interface ChargeDetailRecordsDataStoreProps {
  url: string;
  pageSize?: number;
  defaultSortColumn?: string;
  defaultSortDirection?: 'asc' | 'desc';
  csvFileName?: string;
  filterCookieKey?: string;
}

interface ChargeDetailRecordsDataStore<T> {
  reload: () => void;
  filterOptions: VkwFilterOption[];
  filters: Map<string, string[] | Date[] | number[]>;
  clearFilters: () => void;
  changeFilter: (name: string, value: string[] | Date[] | number[]) => void;
  sortColumn: string | undefined;
  sortDirection: 'asc' | 'desc';
  setSortColumn: (sortColumn?: string) => void;
  pageSize: number;
  pages: number;
  page: number;
  setPage: (page: number) => void;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  data: T[];
  totalRecords: number;
  hasMore: boolean;
  initialized: boolean;
  loading: boolean;
  loadAdditionalEntries: boolean;
  chargeDetailRecordsStatistic: ChargeDetailRecordsStatistic | null;
  downloadCSV: () => Promise<boolean>;
}

interface ExportCSVRequestParams {
  sortBy: string | undefined;
  sortDirection: 'desc' | 'asc' | undefined;
  filter: string | undefined;
}

export const useChargeDetailRecordsDataStore = <T extends ChargeDetailRecord>({
  csvFileName,
  defaultSortColumn = 'startTime',
  defaultSortDirection = 'desc',
  filterCookieKey,
  pageSize = 10,
  url,
}: ChargeDetailRecordsDataStoreProps): ChargeDetailRecordsDataStore<T> => {
  const handleAxiosResponse = useHandleAxiosResponse();
  const user = useUserContext();

  const [chargeDetailRecordsStatistic, setChargeDetailRecordsStatistic] = useState<ChargeDetailRecordsStatistic | null>(
    null
  );

  const store = useBaseDataStore<T>({
    defaultSortColumn,
    defaultSortDirection,
    defaultStartLoading: false,
    filterCookieKey,
    pageSize,
    processCustomData: response => {
      if (response) {
        setChargeDetailRecordsStatistic(response.data.chargingStatistic);
      } else {
        setChargeDetailRecordsStatistic(null);
      }
    },
    url,
  });

  const exportCSVRequestParams: ExportCSVRequestParams = useMemo(() => {
    return {
      customerId: user.selectedCustomer?.id,
      filter: store.axiosRequestParams.filter,
      sortBy: store.axiosRequestParams.sortBy,
      sortDirection: store.axiosRequestParams.sortDirection,
    };
  }, [
    store.axiosRequestParams.sortBy,
    store.axiosRequestParams.sortDirection,
    store.axiosRequestParams.filter,
    user.selectedCustomer?.id,
  ]);

  const downloadCSV = useCallback(() => {
    if (!csvFileName) {
      throw new Error('CSV Filename is not provided');
    }

    return handleAxiosResponse(
      () =>
        axios.get(`${url}/ExportCSV`, {
          headers: {
            Accept: 'text/csv',
          },
          params: exportCSVRequestParams,
        }),
      {
        success: response => {
          const date = new Date();

          fileDownload(
            response.data,
            `${csvFileName + date.getFullYear() + (date.getMonth() + 1) + date.getDate()}.csv`,
            'text/csv',
            '\ufeff' // write BOM so Excel interprets the file correctly
          );
        },
      }
    );
  }, [url, csvFileName, exportCSVRequestParams]);

  useEffect(() => {
    store.changeFilter('chargeDateRange', [
      vkwDateIo.date().subtract(29, 'days').startOf('day').toDate(), // Only 29 days because of start day 00:00 and end day 23:59 are 30 days timerange
      vkwDateIo.date().endOf('day').toDate(),
    ]);

    store.startLoading();
  }, []);

  const roundFilter = (filterOption: VkwFilterOption) => {
    const newFilterOption = filterOption;
    if (typeof newFilterOption.configuration.max === 'number') {
      newFilterOption.configuration.max = Math.round(newFilterOption.configuration.max * 100) / 100;
    }
    return newFilterOption;
  };

  return {
    changeFilter: store.changeFilter,
    chargeDetailRecordsStatistic,
    clearFilters: store.clearFilters,
    data: store.data,
    downloadCSV,
    filterOptions: store.filterOptions.map(x => (x.column === 'totalGrossCost' ? roundFilter(x) : x)),
    filters: store.filters,
    hasMore: store.hasMore,
    initialized: store.initialized,
    isMobile: store.isMobile,
    loadAdditionalEntries: store.loadAdditionalEntries,
    loading: store.loading,
    page: store.page,
    pageSize: store.pageSize,
    pages: store.pages,
    reload: store.reload,
    setIsMobile: store.setIsMobile,
    setPage: store.setPage,
    setSortColumn: store.setSortColumn,
    sortColumn: store.sortColumn,
    sortDirection: store.sortDirection,
    totalRecords: store.totalRecords,
  };
};
