import { z } from 'zod';
import { evcoIdSchemaProp } from './general-validators';

const chargeDetailRecordSchema = z.object({
  city: z.string(),
  commissionAmount: z.number(),
  commissionCurrency: z.enum(['EUR', 'CHF']),
  consumedEnergy: z.number(),
  contractIds: z.array(z.string()),
  countryCode: z.string(),
  countryName: z.string(),
  emobilityServiceProviderIdentifier: z.string(),
  emobilityServiceProviderName: z.string(),
  evcoId: evcoIdSchemaProp,
  houseNumber: z.string().nullish(),
  operatorIdentifier: z.string(),
  operatorName: z.string(),
  postalCode: z.string(),
  powerSupply: z.enum(['AC_1_PHASE', 'AC_3_PHASE', 'DC']),
  product: z.string(),
  sessionEnd: z.string().datetime(),
  sessionId: z.string().uuid(),
  sessionStart: z.string().datetime(),
  stationName: z.string(),
  street: z.string(),
  transactionId: z.string().nullish(),
});
export type ChargeDetailRecord = z.infer<typeof chargeDetailRecordSchema>;
