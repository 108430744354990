import React, { ReactElement, ReactNode } from 'react';

import { Box, Button as MuiButton, CircularProgress, makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    button: {
      '&&': {
        padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
      },
    },
    dangerButton: {
      '&&': {
        '&.Mui-disabled': {
          opacity: 0.4,
        },
        background: theme.palette.error.main,
        color: theme.palette.common.white,
      },
    },
    hover: {
      [theme.breakpoints.up('md')]: {
        '&:hover': {
          transform: 'translateY(-2px)',
        },
      },
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),
    },
  }),
  { index: 1 }
);

export interface VkwButtonProps {
  /**
   * Inhalt des Buttons
   */
  children: ReactNode;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'secondary' | 'danger' | 'default';
  /**
   * Wird ein `title` angegeben so wird dem Button ein Tooltip mit diesem Text hinzugefügt
   */
  title?: string;
  disabled?: boolean;
  /**
   * Button nimmt die volle Breite des Parent Elementes an
   */
  fullWidth?: boolean;
  /**
   *  Button type für die Anwendung in klassischen Forms
   */
  type?: 'button' | 'reset' | 'submit';
  /**
   * Über `isSubmitting` wird der Button deaktiviert und ein Spinner angezeigt damit der User das Feedback bekommt, dass er bereits geklickt hat
   */
  isSubmitting?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const VkwButton = ({
  children,
  color = 'default',
  disabled,
  fullWidth,
  isSubmitting,
  onClick,
  title,
  type = 'button',
  variant = 'text',
}: VkwButtonProps): ReactElement => {
  const styles = useStyles();

  return (
    <div className={clsx(!disabled && !isSubmitting && styles.hover)}>
      <Tooltip title={title ?? ''} arrow placement="bottom">
        <span>
          <MuiButton
            className={clsx(styles.button, {
              [styles.dangerButton]: color === 'danger',
            })}
            color={color === 'danger' ? undefined : color}
            variant={color === 'danger' ? 'contained' : variant}
            fullWidth={fullWidth}
            disabled={disabled || isSubmitting}
            type={type}
            onClick={onClick}
          >
            {isSubmitting && (
              <Box marginRight={0.75}>
                <CircularProgress color="inherit" size={14} />
              </Box>
            )}
            {children}
          </MuiButton>
        </span>
      </Tooltip>
    </div>
  );
};
