import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconSelfService = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 44 33">
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icon_Zusammenfassung" transform="translate(-1274.000000, -3242.000000)">
            <g id="self-service" transform="translate(1272.000000, 3232.000000)">
              <g transform="translate(2.000000, 10.000000)">
                <g id="laptop-1">
                  <g>
                    <path
                      d="M5.5,3.66666667 L38.5,3.66666667 L38.5,23.8333333 L40.3333333,23.8333333 L40.3333333,3.66666667 C40.3333333,1.64162258 38.6917107,0 36.6666667,0 L7.33333333,0 C5.30828925,0 3.66666667,1.64162258 3.66666667,3.66666667 L3.66666667,23.8333333 L5.5,23.8333333 L5.5,3.66666667 Z"
                      id="Path"
                      fill={fill}
                      fillRule="nonzero"
                    />
                    <path
                      d="M28.4166667,25.6666667 L28.4166667,28.4166667 L15.5833333,28.4166667 L15.5833333,25.6666667 L0,25.6666667 C0,29.7167548 3.28324517,33 7.33333333,33 L36.6666667,33 C40.7167548,33 44,29.7167548 44,25.6666667 L28.4166667,25.6666667 Z"
                      id="Path"
                      fill={fill}
                      fillRule="nonzero"
                    />
                  </g>
                </g>
                <g id="headset" transform="translate(14.000000, 5.000000)" fill={fill} fillRule="nonzero">
                  <path
                    d="M8.5,0 C3.81340909,0 0,3.906875 0,8.70833333 L0,13.4583333 C0,14.76775 1.04009091,15.8333333 2.31818182,15.8333333 L4.63636364,15.8333333 C5.06290909,15.8333333 5.40909091,15.4786667 5.40909091,15.0416667 L5.40909091,9.5 C5.40909091,9.063 5.06290909,8.70833333 4.63636364,8.70833333 L1.54545455,8.70833333 C1.54545455,4.78008333 4.66572727,1.58333333 8.5,1.58333333 C12.3342727,1.58333333 15.4545455,4.78008333 15.4545455,8.70833333 L12.3636364,8.70833333 C11.9370909,8.70833333 11.5909091,9.063 11.5909091,9.5 L11.5909091,15.0416667 C11.5909091,15.4786667 11.9370909,15.8333333 12.3636364,15.8333333 L14.6818182,15.8333333 C14.9538182,15.8333333 15.2119091,15.7763333 15.4545455,15.6876667 C15.4545455,16.831625 14.6849091,17.4166667 13.9090909,17.4166667 L10.0454545,17.4166667 C9.61890909,17.4166667 9.27272727,17.7713333 9.27272727,18.2083333 C9.27272727,18.6453333 9.61890909,19 10.0454545,19 L13.9090909,19 C15.6137273,19 17,17.57975 17,15.8333333 L17,8.70833333 C17,3.906875 13.1865909,0 8.5,0 Z"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </VkwIcon>
  );
};
