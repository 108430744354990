import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import axios, { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';

import { getDataCacheState } from './getDataCacheState';
import { useHandleAxiosResponse } from '../../hooks';
import { useVkwFormatMessage, VkwContractPartnerPasswordRequestedState, VkwProductCategory } from '../../library';
import { darkMode } from '../../util/tailwind';
import { useConfigContext } from '../ConfigContext';

export type AccountInfoThemeMode = 'default' | 'dark' | 'light';

export interface AccountInfo {
  hasCalConnection: boolean;
  hasChargingTariffContract: boolean;
  hasInfrastructureContract: boolean;
  hasSharedChargingTariffChargeDetailRecords: boolean;
  hasSharedInfrastructureChargeDetailRecords: boolean;
  hideCompleteProfileInfo: boolean;
  isCallCenterAgent: boolean;
  isAdmin: boolean;
  seesBackofficeLink: boolean;
  theme: AccountInfoThemeMode;
}

export interface Account {
  emailAddress: string;
  info: AccountInfo;
}

export interface Customer {
  id: string;
  displayText: string;
  identifiedProductCategories?: VkwProductCategory[];
  passwordRequestedState: VkwContractPartnerPasswordRequestedState;
  identificationLevel?: string;
}

export interface DataCacheState {
  infrastructureChargeDetailRecordsLocked: boolean;
  infrastructureChargeDetailRecordsLastUpdate: null | Dayjs;
  chargingTariffChargeDetailRecordsLocked: boolean;
  chargingTariffChargeDetailRecordsLastUpdate: null | Dayjs;
}

interface UserContextProps {
  account: Account | null;
  customers: Customer[];
  selectedCustomer: Customer | null;
  loadAccountInfo: () => Promise<boolean>;
  updateSelectedCustomer: (newSelectedCustomer: Customer) => Promise<boolean>;
  setHideCompleteProfileInfo: () => Promise<boolean>;
  setTheme: (mode: AccountInfoThemeMode) => Promise<boolean>;
  showCompleteProfileDialog: boolean;
  showNoMobilityContractDialog: boolean;
  dataCacheState: DataCacheState | null;
  updateDataCache: () => void;
}

const UserContext = createContext<UserContextProps | null>(null);

interface UserContextProviderProps {
  account: Account | null;
  customers: Customer[];
  selectedCustomer: Customer | null;
  dataCacheState: DataCacheState | null;
  children: ReactNode;
}

export const UserContextProvider = ({
  account,
  children,
  customers,
  dataCacheState,
  selectedCustomer,
}: UserContextProviderProps): ReactElement => {
  const handleAxiosResponse = useHandleAxiosResponse();
  const configContext = useConfigContext();
  const formatMessage = useVkwFormatMessage();

  const settingsUrl = '/api/v1/UserSettings';

  const settingsKey = 'selectedCustomer';

  const [firstPageLoad, setFirstPageLoad] = useState(true);
  const [activePollingInterval, setActivePollingInterval] = useState<NodeJS.Timeout | null>(null);

  const [internalAccount, setInternalAccount] = useState(account);
  const [internalCustomers] = useState(customers);
  const [internalSelectedCustomer, setInternalSelectedCustomer] = useState(selectedCustomer);
  const [internalDataCacheState, setInternalDataCacheState] = useState(dataCacheState);

  const loadAccountInfo = useCallback(async (): Promise<boolean> => {
    if (!internalAccount) {
      return true;
    }

    const success = await handleAxiosResponse(
      () =>
        axios.get('/api/v1/userInfo', {
          params: { customerId: internalSelectedCustomer?.id },
        }),
      {
        success: (response: AxiosResponse<AccountInfo>) => {
          setInternalAccount(prevState => {
            if (prevState) {
              return {
                ...prevState,
                info: { ...response.data, seesBackofficeLink: response.data.isCallCenterAgent },
              };
            }

            return prevState;
          });
        },
      }
    );

    axios
      .get(`api/next/v1/customers/${internalSelectedCustomer?.id}/user-info`, {
        params: { customerId: internalSelectedCustomer?.id },
      })
      .then(response => {
        setInternalAccount(prevState => {
          if (prevState) {
            return {
              ...prevState,
              info: {
                ...prevState.info,
                isAdmin: response.data.isAdmin,
              },
            };
          }

          return prevState;
        });
      });

    return success;
  }, [internalSelectedCustomer?.id, internalAccount]);

  const updateSelectedCustomer = useCallback(
    (newSelectedCustomer: Customer): Promise<boolean> => {
      return handleAxiosResponse(
        () =>
          axios.request({
            data: {
              key: settingsKey,
              value: JSON.stringify(newSelectedCustomer),
            },
            method: 'POST',
            url: settingsUrl,
          }),
        {
          success: () => {
            setInternalSelectedCustomer(newSelectedCustomer);
          },
        }
      );
    },
    [settingsUrl, loadAccountInfo]
  );

  const updateDataCache = useCallback(() => {
    handleAxiosResponse(
      () =>
        axios.post('/api/v1/userInfo/updateChargeDetailRecordsData', undefined, {
          params: {
            customerId: internalSelectedCustomer?.id,
          },
        }),
      {
        success: () => {
          setInternalDataCacheState(prevState =>
            prevState
              ? {
                  ...prevState,
                  chargingTariffChargeDetailRecordsLocked: true,
                  infrastructureChargeDetailRecordsLocked: true,
                }
              : {
                  chargingTariffChargeDetailRecordsLastUpdate: null,
                  chargingTariffChargeDetailRecordsLocked: true,
                  infrastructureChargeDetailRecordsLastUpdate: null,
                  infrastructureChargeDetailRecordsLocked: true,
                }
          );
        },
      }
    );
  }, [internalSelectedCustomer]);

  const setHideCompleteProfileInfo = useCallback((): Promise<boolean> => {
    if (!internalAccount) {
      throw new Error('No Account');
    }

    const oldValue = internalAccount.info.hideCompleteProfileInfo;

    setInternalAccount(prevState => {
      if (prevState) {
        return { ...prevState, info: { ...prevState.info, hideCompleteProfileInfo: true } };
      }

      return prevState;
    });

    return handleAxiosResponse(
      () =>
        axios.put('/api/v1/userInfo', {
          customerId: internalSelectedCustomer?.id,
          hideCompleteProfileInfo: true,
        }),
      {
        error: () => {
          setInternalAccount(prevState => {
            if (prevState) {
              return { ...prevState, info: { ...prevState.info, hideCompleteProfileInfo: oldValue } };
            }

            return prevState;
          });
        },
      },
      { successSnackbarMessage: formatMessage('SuccessfullySaved') }
    );
  }, [internalAccount, internalSelectedCustomer?.id]);

  const setTheme = useCallback(
    (mode: AccountInfoThemeMode): Promise<boolean> => {
      if (!internalAccount) {
        throw new Error('No Account');
      }

      const oldValue = internalAccount.info.theme;

      // Set Tailwind Theme
      darkMode(mode === 'dark');

      setInternalAccount(prevState => {
        if (prevState) {
          return { ...prevState, info: { ...prevState.info, theme: mode } };
        }

        return prevState;
      });

      return handleAxiosResponse(
        () =>
          axios.put('/api/v1/userInfo', {
            theme: mode,
          }),
        {
          error: () => {
            setInternalAccount(prevState => {
              if (prevState) {
                return { ...prevState, info: { ...prevState.info, theme: oldValue } };
              }

              return prevState;
            });
          },
        },
        { successSnackbarMessage: formatMessage('SuccessfullySaved') }
      );
    },
    [internalAccount]
  );

  useEffect(() => {
    const defaultSelectedCustomer = customers.length >= 1 ? customers[0] : null;

    if (defaultSelectedCustomer && internalSelectedCustomer) {
      updateSelectedCustomer(customers.find(c => c.id === internalSelectedCustomer?.id) || defaultSelectedCustomer);
    } else if (!internalSelectedCustomer && defaultSelectedCustomer) {
      updateSelectedCustomer(defaultSelectedCustomer);
    }

    if (!firstPageLoad) {
      if (internalSelectedCustomer) {
        loadAccountInfo();
        getDataCacheState(internalSelectedCustomer.id).then(dataCacheState =>
          setInternalDataCacheState(dataCacheState)
        );
      }
    } else {
      setFirstPageLoad(false);
    }
  }, [internalSelectedCustomer]);

  const { showCompleteProfileDialog, showNoMobilityContractDialog } = useMemo(() => {
    if (!internalAccount || !configContext.envUrls.addCustomerUrl || internalAccount.info.hideCompleteProfileInfo) {
      return { showCompleteProfileDialog: false, showNoMobilityContractDialog: false };
    }

    if (internalCustomers.length >= 1) {
      return { showCompleteProfileDialog: false, showNoMobilityContractDialog: false };
    }

    return { showCompleteProfileDialog: true, showNoMobilityContractDialog: false };
  }, [internalAccount, internalCustomers]);

  useEffect(() => {
    if (
      !activePollingInterval &&
      (!internalDataCacheState ||
        internalDataCacheState.chargingTariffChargeDetailRecordsLocked ||
        internalDataCacheState.infrastructureChargeDetailRecordsLocked)
    ) {
      setActivePollingInterval(
        setInterval(async () => {
          const tempState = await getDataCacheState(internalSelectedCustomer?.id || '');

          try {
            if (
              internalDataCacheState?.chargingTariffChargeDetailRecordsLocked ===
                tempState.chargingTariffChargeDetailRecordsLocked &&
              internalDataCacheState?.chargingTariffChargeDetailRecordsLastUpdate?.isSame(
                tempState.chargingTariffChargeDetailRecordsLastUpdate
              ) &&
              internalDataCacheState?.infrastructureChargeDetailRecordsLocked ===
                tempState.chargingTariffChargeDetailRecordsLocked &&
              internalDataCacheState?.infrastructureChargeDetailRecordsLastUpdate?.isSame(
                tempState.infrastructureChargeDetailRecordsLastUpdate
              )
            ) {
              return;
            }
            setInternalDataCacheState(tempState);
          } catch (e) {
            console.error(e);
          }
        }, 10000)
      );
    }

    if (
      activePollingInterval &&
      internalDataCacheState &&
      !internalDataCacheState.chargingTariffChargeDetailRecordsLocked &&
      !internalDataCacheState.infrastructureChargeDetailRecordsLocked
    ) {
      clearInterval(activePollingInterval);
      setActivePollingInterval(null);
    }
  }, [
    internalDataCacheState?.chargingTariffChargeDetailRecordsLocked,
    internalDataCacheState?.infrastructureChargeDetailRecordsLocked,
    activePollingInterval,
  ]);

  useEffect(() => {
    return () => {
      if (activePollingInterval) {
        clearInterval(activePollingInterval);
        setActivePollingInterval(null);
      }
    };
  }, [activePollingInterval]);

  const value = useMemo<UserContextProps>(
    () => ({
      account: internalAccount,
      customers: internalCustomers,
      dataCacheState: internalDataCacheState,
      loadAccountInfo,
      selectedCustomer: internalSelectedCustomer,
      setHideCompleteProfileInfo,
      setTheme,
      showCompleteProfileDialog,
      showNoMobilityContractDialog,
      updateDataCache,
      updateSelectedCustomer,
    }),
    [
      internalAccount,
      internalCustomers,
      internalSelectedCustomer,
      loadAccountInfo,
      updateSelectedCustomer,
      updateDataCache,
      setHideCompleteProfileInfo,
      setTheme,
      showCompleteProfileDialog,
      showNoMobilityContractDialog,
      internalDataCacheState,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContextProps => {
  const user = useContext(UserContext);

  if (!user) {
    throw new Error('Use UserContextProvider somewhere!!');
  }

  return user;
};
