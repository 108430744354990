export enum ChargingContractSelfServiceOperation {
  CHANGE_IDENTIFICATION = 'CHANGE_IDENTIFICATION',
}

export interface ChargingContract {
  id: string;
  productName: string | null;
  productMonthlyFee: number | null;
  vehicleAlias: string | null;
  vehicleType: string | null;
  vehicleLicensePlate: string | null;
  contractAlias: string | null;
  contractId: string;
  contractInvoiceRecipientName: string | null;
  contractIban: string | null;
  contractValidTo: Date | null;
  authenticationCardUid: string | null;
  authenticationEvcoId: string | null;
  customerId: string | null;
  authorizationGroups: string[];
  allowedSelfServiceOperations: ChargingContractSelfServiceOperation[];
}
