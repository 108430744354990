import React, { ReactElement, useState, useMemo, ReactNode } from 'react';

import { makeStyles, Portal, Typography, Box } from '@material-ui/core';

import { NavigationItem } from './NavigationItem';
import { NavigationMenuItem } from './NavigationMenuItem';
import { VkwNavigationItemConfig } from './VkwNavigationItemConfig';
import { useVkwFormatMessage } from '../../hooks';
import { VkwIconMore } from '../../icons';
import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    items: {
      display: 'flex',
      height: '100%',
      width: '100%',
    },
    menuContainer: {
      background: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100% - ${theme.navigationMobileHeight}px)`,
      minWidth: `${theme.minWidth}px`,
      overflowY: 'auto',
      position: 'absolute',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
      width: '100%',

      zIndex: theme.zIndex.appBar,
    },
    menuHeader: {
      alignItems: 'center',
      borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),

      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
  }),
  { index: 1 }
);

interface NavigationMobileItemsProps {
  mainItems: VkwNavigationItemConfig[];
  secondaryItems: VkwNavigationItemConfig[];
  footerItems: VkwNavigationItemConfig[];
  activeRoute: string | null;
  renderHeader: () => ReactNode;
  renderCopyright: () => ReactNode;
  renderSocialLinks: () => ReactNode;
  className?: string;
}

const MAX_VISIBLE_ITEMS = 4;

export const NavigationMobileItems = ({
  activeRoute,
  className,
  footerItems,
  mainItems,
  renderCopyright,
  renderHeader,
  renderSocialLinks,
  secondaryItems,
}: NavigationMobileItemsProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const styles = useStyles();
  const [showMore, setShowMore] = useState(false);

  const { expandableItems, visibleItems } = useMemo(() => {
    if (mainItems.length <= MAX_VISIBLE_ITEMS) {
      return {
        expandableItems: [...secondaryItems, ...footerItems],
        visibleItems: mainItems,
      };
    }

    return {
      expandableItems: [...mainItems.slice(MAX_VISIBLE_ITEMS), ...secondaryItems, ...footerItems],
      visibleItems: mainItems.slice(0, MAX_VISIBLE_ITEMS),
    };
  }, [mainItems, secondaryItems, footerItems]);

  const isMoreItemActive = useMemo(
    () => expandableItems.some(item => item.key === activeRoute),
    [expandableItems, activeRoute]
  );

  const renderMenuItem = (item: VkwNavigationItemConfig): ReactNode => {
    return (
      <NavigationMenuItem
        key={item.key}
        onClose={() => setShowMore(false)}
        item={item}
        active={activeRoute === item.key}
      />
    );
  };

  const toggleShowMore = () => setShowMore(s => !s);

  return (
    <Box className={className} display="flex" flexDirection="column" flexGrow={1} width="100%">
      {showMore && (
        <Portal>
          <div className={styles.menuContainer}>
            <div className={styles.menuHeader}>
              <Typography variant="h5">{formatMessage('More')}</Typography>
              <div>{renderHeader()}</div>
            </div>
            <Box flexGrow={2}>{expandableItems.map(item => renderMenuItem(item))}</Box>
            <Box display="flex" justifyContent="space-between" paddingX={3} paddingY={2}>
              <div>{renderSocialLinks()}</div>
              <div>{renderCopyright()}</div>
            </Box>
          </div>
        </Portal>
      )}
      <div className={styles.items}>
        {visibleItems.map(item => {
          const handleItemClick = () => {
            setShowMore(false);
            if (!item.onClick) {
              return;
            }

            item.onClick();
          };

          return (
            <NavigationItem
              key={item.key}
              item={{ ...item, onClick: handleItemClick }}
              active={activeRoute === item.key}
              showName
            />
          );
        })}
        <NavigationItem
          key="more"
          item={{
            activeIcon: VkwIconMore,
            icon: VkwIconMore,
            key: 'more',
            name: formatMessage('More'),
            onClick: toggleShowMore,
            path: '/',
          }}
          active={isMoreItemActive}
          showName
        />
      </div>
    </Box>
  );
};
