import React, { ReactElement, useState } from 'react';

import { Transition } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import clsx, { ClassValue } from 'clsx';

import { ChargeStationRouteLink } from './ChargeStationRouteLink';
import { ChargeStationDetails } from '../../containers';
import { ChargeStation, useChargeStationPin } from '../../hooks';
import { VkwOverlay, VkwTheme, getVkwTypographyStyle, useVkwFormatMessage } from '../../library';
import { ChargePointStatusWithNotAllowed, getChargePointsData } from '../../util/charge-stations';

const useStyles = makeStyles<VkwTheme>(theme => ({
  address: {
    ...getVkwTypographyStyle('text14', theme),
    paddingTop: theme.spacing(1),
  },
  openingTimeText: {
    ...getVkwTypographyStyle('text16', theme),
  },
  pin: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

interface BackButtonProps {
  show: boolean;
  onClick: () => void;
}

const BackButton = ({ onClick, show }: BackButtonProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();

  return (
    <div>
      <Transition
        show={show}
        enter="transition-opacity duration-150"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <button
          className="flex gap-x-2 rounded-2xl p-2 pr-4 font-medium transition ease-in hover:bg-gray-200"
          type="button"
          onClick={onClick}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          {formatMessage('Back')}
        </button>
      </Transition>
    </div>
  );
};

interface ChargeStationTileProps {
  chargeStation: ChargeStation;
  onClick: () => void;
}

const ChargeStationTile = ({ chargeStation, onClick }: ChargeStationTileProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();

  const { available, chargePointsCount, chargeStationStatus, maxChargePower } = getChargePointsData(chargeStation);

  const bgColor: Record<ChargePointStatusWithNotAllowed, ClassValue[]> = {
    AVAILABLE: ['bg-green-600 hover:bg-green-700'],
    NOT_ALLOWED: ['bg-gray-300 hover:bg-gray-400'],
    OCCUPIED: ['bg-yellow-600 hover:bg-yellow-700'],
    OUT_OF_SERVICE: ['bg-red-600 hover:bg-red-700'],
    RESERVED: ['bg-blue-600 hover:bg-blue-700'],
    UNKNOWN: ['bg-gray-500 hover:bg-gray-600'],
  };

  const getChargeStationStatusFormatMessageId = (): string => {
    const statusArr = chargeStationStatus.split('_');

    return `ChargeStationStatus${statusArr
      .map(value => value[0].toUpperCase() + value.toLowerCase().slice(1))
      .join('')}`;
  };

  return (
    <div
      className={clsx('group text-white transition ease-in hover:cursor-pointer', bgColor[chargeStationStatus])}
      onClick={onClick}
      role="presentation"
    >
      <div className="flex items-center justify-between p-4">
        <div>
          <span className="block text-base font-medium">{chargeStation.stationNameOriginal}</span>
          <span className="opacity-75">{chargeStation.address}</span>
        </div>
        <div className="text-xl">
          <span className="font-bold">{available}</span>
          <span className="opacity-50">/{chargePointsCount}</span>
        </div>
      </div>
      <div className="flex items-center justify-between bg-black/10 p-4 text-xs font-medium opacity-75">
        <span>
          {maxChargePower !== 0 && (
            <>
              {formatMessage('MaxPower')} {maxChargePower} kW
            </>
          )}
        </span>
        <span>{formatMessage(getChargeStationStatusFormatMessageId())}</span>
      </div>
    </div>
  );
};

interface ChargeStationListOverlayProps {
  chargeStationList: ChargeStation[];
  currentPosition: google.maps.LatLngLiteral;
  onClose: () => void;
}

export const ChargeStationListOverlay = ({
  chargeStationList,
  currentPosition,
  onClose,
}: ChargeStationListOverlayProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();
  const [selectedChargeStation, setSelectedChargeStation] = useState<ChargeStation>(chargeStationList[0]);
  const [isShowing, setIsShowing] = useState(false);

  const chargeStationPin = useChargeStationPin(selectedChargeStation.chargePoints, 'small');

  const showChargeStation = (chargeStation: ChargeStation): void => {
    setSelectedChargeStation(chargeStation);
    setIsShowing(true);
  };

  return (
    <VkwOverlay onClose={onClose} open actions={<BackButton show={isShowing} onClick={() => setIsShowing(false)} />}>
      <div className="relative">
        <Transition
          show={!isShowing}
          enter="transform transition ease-in-out duration-300"
          enterFrom="-translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="transform transition ease-in-out duration-300"
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="-translate-x-full opacity-0"
        >
          <div className="flex flex-col space-y-3">
            {chargeStationList.map(chargeStation => (
              <ChargeStationTile
                key={chargeStation.id}
                chargeStation={chargeStation}
                onClick={() => showChargeStation(chargeStation)}
              />
            ))}
          </div>
        </Transition>
        <Transition
          show={isShowing}
          enter="transform transition ease-in-out duration-300 absolute top-0 w-full"
          enterFrom="translate-x-full opacity-0"
          enterTo="translate-x-0 opacity-100"
          leave="transform transition ease-in-out duration-300 absolute top-0 w-full"
          leaveFrom="translate-x-0 opacity-100"
          leaveTo="translate-x-full opacity-0"
        >
          {selectedChargeStation && (
            <>
              <Box display="flex" flexWrap="nowrap" justifyContent="space-between" paddingBottom={4}>
                <Box display="flex" flexDirection="column" alignItems="left" paddingRight={1}>
                  <Box display="flex">
                    <Box maxWidth="275px">
                      <Typography variant="h6" style={{ overflowWrap: 'break-word' }}>
                        {selectedChargeStation.stationNameOriginal}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center">
                      <Tooltip
                        title={formatMessage(`ChargeStationStatus${chargeStationPin.status}`)}
                        arrow
                        placement="bottom"
                      >
                        <img className={styles.pin} src={chargeStationPin.url} alt="" />
                      </Tooltip>
                    </Box>
                  </Box>
                  <Typography component="span" className={styles.address} color="textSecondary">
                    {selectedChargeStation.address}
                  </Typography>
                </Box>
                <Box paddingLeft={1}>
                  <ChargeStationRouteLink chargeStation={selectedChargeStation} currentPosition={currentPosition} />
                </Box>
              </Box>
              <ChargeStationDetails chargeStation={selectedChargeStation} />
            </>
          )}
        </Transition>
      </div>
    </VkwOverlay>
  );
};
