import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconUsers = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const strokewidth = 1;

  return (
    <VkwIcon size={size}>
      <g stroke="none" fill={fill} strokeLinecap="square" strokeWidth={strokewidth} fillRule="nonzero">
        <path
          d="M8.66666667,11.6666667 L8.99866667,11.6733333 C13.092,11.848 16.3333333,15.2226667 16.3333333,19.3333333 L16.3333333,20.3333333 L1,20.3333333 L1,19.3333333 C1,15.0986667 4.432,11.6666667 8.66666667,11.6666667 Z M8.64533333,13.6666667 L8.38266667,13.6733333 C5.74933333,13.804 3.588,15.732 3.10266667,18.256 L3.088,18.3333333 L14.244,18.3333333 L14.2306667,18.256 C13.76,15.8133333 11.7213333,13.92 9.20533333,13.6906667 L8.93466667,13.672 L8.64533333,13.6666667 L8.64533333,13.6666667 Z M18,10.3333287 C20.672,10.3333287 22.8546667,12.4293333 22.9933333,15.0666667 L23,15.3333287 L23,17.6666667 L18,17.6666667 L18,15.6666667 L20.9986667,15.6653333 L21,15.3333287 C21,13.7466667 19.7666667,12.4453333 18.204,12.34 L17.9986667,12.3333287 C17.492,12.3333287 16.9986667,12.46 16.5573333,12.7026667 L16.3413333,12.832 L15.2346667,11.168 C16.0533333,10.6213333 17.0173333,10.332 18,10.3333287 L18,10.3333287 Z M8.66666667,1 C11.428,1 13.6666667,3.23866667 13.6666667,6 C13.6666667,8.76133333 11.428,11 8.66666667,11 C5.90533333,11 3.66666667,8.76133333 3.66666667,6 C3.66666667,3.23866667 5.90533333,1 8.66666667,1 Z M18,2.33333333 C20.0253333,2.33333333 21.6666667,3.97466667 21.6666667,6 C21.6666667,8.02533333 20.0253333,9.66666667 18,9.66666667 C15.9746667,9.66666667 14.3333333,8.02533333 14.3333333,6 C14.3333333,3.97466667 15.9746667,2.33333333 18,2.33333333 Z M8.66666667,3 C7.00933333,3 5.66666667,4.34266667 5.66666667,6 C5.66666667,7.65733333 7.00933333,9 8.66666667,9 C10.324,9 11.6666667,7.65733333 11.6666667,6 C11.6666667,4.34266667 10.324,3 8.66666667,3 Z M18,4.33333333 C17.08,4.33333333 16.3333333,5.08 16.3333333,6 C16.3333333,6.92 17.08,7.66666667 18,7.66666667 C18.92,7.66666667 19.6666667,6.92 19.6666667,6 C19.6666667,5.08 18.92,4.33333333 18,4.33333333 Z"
          id="user-shape"
        />
      </g>
    </VkwIcon>
  );
};
