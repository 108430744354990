import React, { ReactElement, useState } from 'react';

import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';

import { TimePicker } from './TimePicker';
import { vkwDateIo } from '../../../helper';
import { useVkwFormatMessage } from '../../../hooks';
import { VkwTab } from '../../VkwTab';

const getCalendarViewFromPickerDialogId = (pickerId: string): Element => {
  const calendarClassName = 'MuiPickersBasePicker-pickerView';
  const pickerDialogElement = document.getElementById(pickerId);
  const calendarViewElement = pickerDialogElement?.getElementsByClassName(calendarClassName)[0];

  if (calendarViewElement === undefined) {
    return new Element();
  }

  return calendarViewElement;
};

interface DateTimeToolbarProps {
  pickerDialogId: string;
  toolbarProps: ToolbarComponentProps;
}

export const DateTimeToolbar = ({ pickerDialogId, toolbarProps }: DateTimeToolbarProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const [tab, setTab] = useState(0);

  return (
    <VkwTab
      value={tab}
      indicatorColor="primary"
      tabs={[
        {
          label: formatMessage('Date'),
          renderContent: () => {
            return null;
          },
        },
        {
          label: formatMessage('Time'),
          renderContent: () => {
            return (
              <TimePicker
                dateValue={toolbarProps.date ? toolbarProps.date.toDate() : vkwDateIo.date().toDate()}
                onHoursChange={value => {
                  Object.assign(toolbarProps.date ?? {}, toolbarProps.date?.set('hour', value));
                }}
                onMinutesChange={value => {
                  Object.assign(toolbarProps.date ?? {}, toolbarProps.date?.set('minute', value));
                }}
              />
            );
          },
        },
      ]}
      onChange={(event, value) => {
        const calendarView = getCalendarViewFromPickerDialogId(pickerDialogId);
        if (value === 0) {
          toolbarProps.setOpenView('date');
          calendarView.setAttribute('style', '');
        }
        if (value === 1) {
          calendarView.setAttribute('style', 'display: none');
        }
        setTab(value);
      }}
      fullWidth
    />
  );
};
