export interface Attachment {
  filename: string;
  contentType: string;
  content: string;
  encoding: string;
}

const readUploadedFileAsBase64 = (inputFile: Blob): Promise<string> => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    temporaryFileReader.onload = () => {
      resolve(btoa(temporaryFileReader.result as string));
    };

    temporaryFileReader.readAsBinaryString(inputFile);
  });
};

export const createFileAttachments = async (uploadFileList: FileList | null): Promise<Attachment[] | null> => {
  const attachments: Attachment[] = [];

  if (uploadFileList) {
    for (let i = 0; uploadFileList[i]; i++) {
      attachments.push({
        // eslint-disable-next-line no-await-in-loop
        content: await readUploadedFileAsBase64(uploadFileList[i]),
        contentType: uploadFileList[i].type,
        encoding: 'base64',
        filename: uploadFileList[i].name,
      });
    }
  }

  return attachments.length > 0 ? attachments : null;
};
