import React, { forwardRef, ReactElement } from 'react';

import { Switch } from '@headlessui/react';

export interface ToggleProps {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Toggle = forwardRef<HTMLButtonElement, ToggleProps>(function Toggle(
  { checked, disabled, onChange }: ToggleProps,
  ref
): ReactElement {
  return (
    <Switch
      ref={ref}
      checked={checked}
      onChange={onChange}
      className={`${checked ? 'bg-teal-500 hover:bg-teal-600' : 'bg-gray-400 hover:bg-gray-500'} ${
        disabled ? 'opacity-50' : ''
      }
          relative inline-flex h-[28px] w-[52px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-100 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        data-disabled={disabled}
        className={`${checked ? 'translate-x-6' : 'translate-x-0'}
            pointer-events-none inline-block h-[24px] w-[24px] rounded-full bg-white shadow-lg ring-0 transition duration-100 ease-in-out`}
      />
    </Switch>
  );
});
