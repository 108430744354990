import React, { ReactElement, ReactNode, ElementType } from 'react';

import { vkwDarkTheme, vkwTheme } from '../../../themes';
import { useVkwAssets } from '../../contexts';
import { useVkwTheme } from '../../themes';

interface VkwHeadProps {
  publisher: string;
  copyright: string;
  sitename: string;
  title: string;
  description: string;
  url: string;
  /**
   * Möglichkeit um eine eigene Head Komponente mitzugeben. Dies ist z.B. bei Next.js notwendig
   */
  component?: ElementType;
  /**
   * Möglichkeit um eigene Komponenten in den Head zu platzieren
   */
  children?: ReactNode;
}

export const VkwHead = ({
  children,
  component,
  copyright,
  description,
  publisher,
  sitename,
  title,
  url,
}: VkwHeadProps): ReactElement => {
  const assets = useVkwAssets();
  const theme = useVkwTheme();

  const HeadComponent = component ?? 'head';

  return (
    <HeadComponent>
      <title>{title}</title>
      <meta charSet="UTF-8" />

      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="de_AT" />
      <meta property="og:image" content={assets.ogImage} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={sitename} />
      <meta property="og:url" content={url} />

      <meta property="DC.Title" content={title} />
      <meta property="DC.Publisher" content={publisher} />
      <meta property="DC.Copyright" content={copyright} />

      <meta property="twitter:card" content="summary" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={assets.ogImage} />

      <meta name="viewport" content="minimum-scale=1.0, initial-scale=1.0, width=device-width" />

      <link rel="icon" type="image/png" href={assets.favIconPng16} sizes="16x16" />
      <link rel="icon" type="image/png" href={assets.favIconPng32} sizes="32x32" />
      <link rel="icon" type="image/png" href={assets.favIconPng96} sizes="96x96" />
      <link rel="icon" type="image/png" href={assets.favIconPng192} sizes="192x192" />
      <link rel="icon" href={assets.favIconIco} />

      {(theme.typography.fontFamily === vkwTheme.typography.fontFamily ||
        theme.typography.fontFamily === vkwDarkTheme.typography.fontFamily) && (
        <>
          <link
            rel="preload"
            href="/fonts/HelveticaNowDisplayLight.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/fonts/HelveticaNowDisplayMedium.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/fonts/HelveticaNowTextBold.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/fonts/HelveticaNowTextBoldItalic.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/fonts/HelveticaNowTextItalic.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/fonts/HelveticaNowTextMedium.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
          <link
            rel="preload"
            href="/fonts/HelveticaNowTextRegular.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
          />
        </>
      )}
      {children}
    </HeadComponent>
  );
};
