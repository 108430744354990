import React, { ReactElement, ReactNode, useState, ChangeEvent } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { useVkwFormatMessage } from '../../hooks';
import { useVkwTheme, VkwTheme } from '../../themes';
import { VkwButton } from '../VkwButton';
import { VkwCallToAction } from '../VkwCallToAction';
import { VkwCheckbox } from '../VkwCheckbox';
import { VkwDialog } from '../VkwDialog';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    imageWrapper: {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
      display: 'flex',
      height: '218px',
      justifyContent: 'center',
      maxWidth: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(4),
      },
    },
  }),
  { index: 1 }
);

export interface ReminderDialogProps {
  /**
   * Bild, welches oben angezeigt wird
   */
  headerImg: string;
  /**
   * Titel des Dialogs
   */
  headerText: string;
  /**
   * Text des Buttons
   */
  buttonText: string;
  /**
   * Diese Funktion wird aufgerufen, wenn der Button geklickt wird.
   */
  onButtonClick: () => void;
  /**
   * Diese Funktion wird aufgerufen, wenn auf Skip geklickt wird.
   */
  onSkipClick: (doNotShowAgain: boolean) => void;
  /**
   * Dialog geöffnet oder nicht
   */
  isOpen: boolean;
  /**
   * Inhalt des Dialogfeldes
   */
  children?: ReactNode;
}

export const VkwReminderDialog = ({
  buttonText,
  children,
  headerImg,
  headerText,
  isOpen,
  onButtonClick,
  onSkipClick,
}: ReminderDialogProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const theme = useVkwTheme();
  const styles = useStyles();

  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const handleDoNotShowAgainChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setDoNotShowAgain(event.target.checked);
  };

  return (
    <VkwDialog
      open={isOpen}
      renderCustomHeader={() => {
        return (
          <Box className={styles.imageWrapper}>
            <img src={headerImg} alt="contracts" />
          </Box>
        );
      }}
      renderContent={() => {
        return (
          <Box maxWidth="547px">
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="center" pt={4}>
                <Typography variant="h5" align="center">
                  {headerText}
                </Typography>
              </Box>
              {children}
              <Box display="flex" justifyContent="center" pt={6}>
                <VkwButton variant="contained" color={theme.palette.mainButtonColor} onClick={onButtonClick}>
                  {buttonText}
                </VkwButton>
              </Box>
              <Box display="flex" justifyContent="center" pt={6}>
                <VkwCheckbox
                  label={formatMessage('DialogCompleteProfileDoNotShowAgain')}
                  checked={doNotShowAgain}
                  onChange={handleDoNotShowAgainChange}
                />
              </Box>
              <Box display="flex" justifyContent="center" pt={2} pb={2}>
                <VkwCallToAction
                  title={formatMessage('Skip')}
                  iconPosition="none"
                  onClick={() => onSkipClick(doNotShowAgain)}
                />
              </Box>
            </Box>
          </Box>
        );
      }}
    />
  );
};
