import React, { ReactElement } from 'react';

import { ButtonBase, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { getVkwTypographyStyle } from '../../helper';
import { VkwIconArrowLeft, VkwIconArrowRight } from '../../icons';
import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme, { iconPosition: 'left' | 'right' | 'none' }>(
  theme => ({
    cta: {
      alignItems: 'baseline',
      color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.text.primary,
      display: 'inline-flex',
    },
    ctaDisabled: {
      opacity: 0.4,
    },
    ctaNoIcon: {
      textDecoration: 'underline',
    },
    text: props => ({
      ...getVkwTypographyStyle('link14', theme),

      marginLeft: props.iconPosition === 'left' ? theme.spacing(0.25) : 0,
      marginRight: props.iconPosition === 'right' ? theme.spacing(0.25) : 0,
    }),
  }),
  { index: 1 }
);

export interface VkwCallToActionProps {
  title: string;
  onClick?: () => void;
  iconPosition?: 'left' | 'right' | 'none';
  href?: string;
  /**
   * Ziel für `href` z.B. "_blank" für einen neuen Tab
   */
  target?: string;
  disabled?: boolean;
}

export const VkwCallToAction = ({
  disabled = false,
  href = '',
  iconPosition = 'left',
  onClick,
  target,
  title,
}: VkwCallToActionProps): ReactElement => {
  const styles = useStyles({ iconPosition });

  const buttonClass = clsx(styles.cta, {
    [styles.ctaDisabled]: disabled,
    [styles.ctaNoIcon]: iconPosition === 'none',
  });

  return (
    <ButtonBase
      className={buttonClass}
      onClick={onClick}
      href={href}
      target={target}
      title={href !== '' ? title : undefined}
      disabled={disabled}
    >
      {iconPosition === 'left' && <VkwIconArrowLeft size={12} />}
      <Typography className={styles.text} color="inherit" component="span">
        {title}
      </Typography>
      {iconPosition === 'right' && <VkwIconArrowRight size={12} />}
    </ButtonBase>
  );
};
