import React, { ReactElement, ReactNode } from 'react';

import { Dialog, DialogContent, DialogActions, Typography, makeStyles } from '@material-ui/core';

import { getVkwTypographyStyle } from '../../helper';
import { useVkwFormatMessage } from '../../hooks';
import { VkwIconClose } from '../../icons';
import { useVkwTheme, VkwTheme } from '../../themes';
import { VkwButton } from '../VkwButton';
import { VkwIconButton } from '../VkwIconButton';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    actions: {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
    },
    header: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    headline: {
      ...getVkwTypographyStyle('h7', theme),
      color: theme.palette.text.primary,
    },
    paper: {
      background: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.common.white,
      border: theme.palette.type === 'dark' ? `1px solid ${theme.palette.grey[700]}` : `none`,
      borderRadius: '6px !important',
      maxWidth: '100%',
      minWith: '320px',
    },
  }),
  { index: 1 }
);

export interface VkwDialogProps {
  open: boolean;
  /**
   * Render Prop, um dem Dialog einen Header oberhalb des Content-Bereiches mitzugeben
   */
  renderCustomHeader?: () => ReactNode;
  /**
   * Überschrift im Content Bereich des Dialoges
   */
  headline?: string;
  renderContent?: () => ReactNode;
  onClose?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  /**
   * Label für den "Save" Button. Wird nichts angegeben so wird "Save" dementsprechend lokalisiert.
   */
  saveButtonLabel?: string;
  saveInProgress?: boolean;
  disableSaveButton?: boolean;
  /**
   * Soll das X zum Schließen angezeigt werden?
   */
  showCloseIcon?: boolean;
}

export const VkwDialog = ({
  disableSaveButton,
  headline,
  onCancel,
  onClose,
  onSave,
  open,
  renderContent,
  renderCustomHeader,
  saveButtonLabel,
  saveInProgress,
  showCloseIcon,
}: VkwDialogProps): ReactElement => {
  const theme = useVkwTheme();
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ className: styles.paper }}>
      {showCloseIcon ? (
        <div className={styles.header}>
          <VkwIconButton type="button" onClick={onClose}>
            <VkwIconClose size={16} />
          </VkwIconButton>
        </div>
      ) : null}
      {renderCustomHeader && <div>{renderCustomHeader()}</div>}
      <DialogContent>
        {headline && <Typography className={styles.headline}>{headline}</Typography>}
        {renderContent?.()}
      </DialogContent>
      {(onSave || onCancel) && (
        <DialogActions className={styles.actions}>
          {onCancel && (
            <VkwButton color="default" variant="contained" onClick={onCancel}>
              {formatMessage('Cancel')}
            </VkwButton>
          )}
          {onSave && (
            <VkwButton
              color={theme.palette.mainButtonColor}
              variant="contained"
              onClick={onSave}
              disabled={disableSaveButton}
              isSubmitting={saveInProgress}
            >
              {saveButtonLabel ?? formatMessage('Save')}
            </VkwButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};
