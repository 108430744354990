import React, { ReactElement, ReactNode, useState, useEffect, useCallback, ChangeEvent } from 'react';

import { Tabs, Tab, makeStyles } from '@material-ui/core';

import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    tabContent: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
  }),
  { index: 1 }
);

export type VkwTabItem = {
  label: string;
  disabled?: boolean;
  renderContent: () => ReactNode;
};

export interface VkwTabProps {
  tabs: VkwTabItem[];
  indicatorColor?: 'primary' | 'secondary';
  preselectedTab?: number;
  value?: number;
  onChange?: (event: ChangeEvent<Record<string, unknown>>, newValue: number) => void;
  fullWidth?: boolean;
}

export const VkwTab = ({
  fullWidth,
  indicatorColor = 'secondary',
  onChange,
  preselectedTab,
  tabs,
  value,
}: VkwTabProps): ReactElement => {
  const styles = useStyles();
  const getPreselectedIndex = useCallback(
    (preselectedTab?: number): number => {
      if (!preselectedTab) {
        return 0;
      }
      if (preselectedTab >= 0 && preselectedTab < tabs.length) {
        return preselectedTab;
      }
      return 0;
    },
    [tabs.length]
  );

  const [internalValue, setInternalValue] = useState<number>(getPreselectedIndex(preselectedTab));

  useEffect(() => {
    setInternalValue(getPreselectedIndex(preselectedTab));
  }, [preselectedTab, getPreselectedIndex]);

  const handleChangeInternal = (_: ChangeEvent<Record<string, unknown>>, newValue: number) => {
    setInternalValue(newValue);
  };

  const currentIndex = value !== undefined ? value : internalValue;
  const activeTab = tabs?.[currentIndex];
  const activeTabContent = activeTab?.renderContent();

  return (
    <div className={styles.root}>
      <Tabs
        value={currentIndex}
        onChange={onChange ?? handleChangeInternal}
        scrollButtons="auto"
        variant={fullWidth ? 'fullWidth' : 'scrollable'}
        indicatorColor={indicatorColor}
      >
        {tabs.map(({ disabled = false, label }, index) => (
          <Tab value={index} label={label} key={index} disabled={disabled} />
        ))}
      </Tabs>
      {activeTabContent != null && <div className={styles.tabContent}>{activeTabContent}</div>}
    </div>
  );
};
