import { ChargeStation } from '@e-vo/types';
import { Dispatch, MutableRefObject, SetStateAction, createContext } from 'react';
import MapView from 'react-native-maps';

type MapContextType = {
  map: MutableRefObject<MapView | null> | null;
  selectedChargeStation: ChargeStation | null;
  setSelectedChargeStation: Dispatch<SetStateAction<ChargeStation | null>>;
  debouncedSearchTerm: string;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  currentSearchTerm: string;
  setCurrentSearchTerm: Dispatch<SetStateAction<string>>;
  showSearchOverlay: boolean;
  setShowSearchOverlay: Dispatch<SetStateAction<boolean>>;
};

const MapControlContext = createContext<MapContextType>({
  currentSearchTerm: '',
  debouncedSearchTerm: '',
  map: null,
  selectedChargeStation: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentSearchTerm: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDebouncedSearchTerm: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSelectedChargeStation: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setShowSearchOverlay: () => {},
  showSearchOverlay: false,
});

export default MapControlContext;
