import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugCcsCombo2 = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path d="M16.5,17 C18.4329966,17 20,18.5670034 20,20.5 C20,22.4329966 18.4329966,24 16.5,24 L7.5,24 C5.56700338,24 4,22.4329966 4,20.5 C4,18.5670034 5.56700338,17 7.5,17 L16.5,17 Z M8,18.5 C6.8954305,18.5 6,19.3954305 6,20.5 C6,21.6045695 6.8954305,22.5 8,22.5 C9.1045695,22.5 10,21.6045695 10,20.5 C10,19.3954305 9.1045695,18.5 8,18.5 Z M16,18.5 C14.8954305,18.5 14,19.3954305 14,20.5 C14,21.6045695 14.8954305,22.5 16,22.5 C17.1045695,22.5 18,21.6045695 18,20.5 C18,19.3954305 17.1045695,18.5 16,18.5 Z" />
        <path d="M22,7.16590833 C22,12.6862196 17.5254555,17.1659083 12,17.1659083 C6.4745445,17.1659083 2,12.6862196 2,7.16590833 C2,4.35748525 3.15810188,1.81839616 5.02350537,0.00147338839 L18.9764946,0.00147338839 C20.8418981,1.81839616 22,4.35748525 22,7.16590833 Z M12,5.66590833 C10.6192881,5.66590833 9.5,6.78519645 9.5,8.16590833 C9.5,9.5466202 10.6192881,10.6659083 12,10.6659083 C13.3807119,10.6659083 14.5,9.5466202 14.5,8.16590833 C14.5,6.78519645 13.3807119,5.66590833 12,5.66590833 Z M9.08333333,1.49924166 C8.16285875,1.49924166 7.41666667,2.24543375 7.41666667,3.16590833 C7.41666667,4.08638291 8.16285875,4.83257499 9.08333333,4.83257499 C10.0038079,4.83257499 10.75,4.08638291 10.75,3.16590833 C10.75,2.24543375 10.0038079,1.49924166 9.08333333,1.49924166 Z M14.9166667,1.49924166 C13.9961921,1.49924166 13.25,2.24543375 13.25,3.16590833 C13.25,4.08638291 13.9961921,4.83257499 14.9166667,4.83257499 C15.8371412,4.83257499 16.5833333,4.08638291 16.5833333,3.16590833 C16.5833333,2.24543375 15.8371412,1.49924166 14.9166667,1.49924166 Z" />
      </g>
    </VkwIcon>
  );
};
