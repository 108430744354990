import React, { ReactElement, useMemo } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { VictoryArea } from 'victory';

import { Assets, AssetsColors } from '../../globals/configureAssets';
import { VkwTheme, getVkwTypographyStyle, timeConvert, useVkwAssets } from '../../library';

const useStyles = makeStyles<VkwTheme, { colors: AssetsColors; cursor: 'pointer' | 'default'; inactive?: boolean }>(
  theme => ({
    root: props => ({
      backgroundColor: props.colors.statisticTileBackground,
      borderRadius: '4px',
      cursor: props.cursor,
      height: '160px',
      opacity: props.inactive ? 0.2 : 1,
      position: 'relative',
      width: '274px',
    }),
    title: props => ({
      ...getVkwTypographyStyle('text14', theme),
      color: props.colors.statisticTileText,
      padding: '16px 0 0 16px',
    }),
    total: props => ({
      ...getVkwTypographyStyle('h3', theme),
      color: props.colors.statisticTileText,
      padding: '4px 0 0 16px',
    }),
  })
);

interface PerformanceStatisticTileProps {
  title: string;
  variant?: 'number' | 'time';
  values: number[];
  animate?: boolean;
  onClick?: () => void;
  inactive?: boolean;
}

export const PerformanceStatisticTile = ({
  animate,
  inactive,
  onClick,
  title,
  values,
  variant = 'number',
}: PerformanceStatisticTileProps): ReactElement => {
  const assets = useVkwAssets<Assets>();
  const styles = useStyles({ colors: assets.colors, cursor: onClick ? 'pointer' : 'default', inactive });

  const { newValues, total, yMax, yMin } = useMemo(() => {
    const newValues: number[] = [...values];

    let total = 0;
    let yMin = Number.MAX_SAFE_INTEGER;
    let yMax = Number.MIN_SAFE_INTEGER;

    if (newValues.length === 0) {
      newValues.push(0, 0);
    } else if (newValues.length === 1) {
      newValues.push(...values);
    }

    values.forEach(value => {
      total += value;
    });

    newValues.forEach(value => {
      if (value < yMin) {
        yMin = value;
      }

      if (value > yMax) {
        yMax = value;
      }
    });

    return {
      newValues,
      total,
      yMax: yMax > 0 ? yMax * 1.1 : 1,
      yMin: yMin * 0.9,
    };
  }, [values]);

  return (
    <Box className={styles.root} onClick={onClick}>
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.total}>
        {variant === 'number' ? Math.round(total).toLocaleString('de') : timeConvert(total, false)}
      </Typography>
      <svg viewBox="0 0 274 80">
        <VictoryArea
          domain={{ x: [0, newValues.length - 1], y: [yMin, yMax] }}
          data={newValues}
          animate={
            animate
              ? {
                  duration: 2000,
                  onLoad: { duration: 1000 },
                }
              : undefined
          }
          standalone={false}
          width={274}
          height={80}
          style={{
            data: {
              fillOpacity: 0,
              stroke: assets.colors.statisticTileText,
              strokeWidth: 2,
            },
          }}
          padding={{ bottom: 16, left: 16, right: 16, top: 16 }}
        />
      </svg>
    </Box>
  );
};
