import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconChargingstation = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 32 32" fill={fill}>
        <path d="M 4.3632812 0.00390625 C 1.9712813 0.00390625 0.00390625 1.9732344 0.00390625 4.3652344 L 0.00390625 31.986328 L 20.355469 31.986328 L 20.355469 21.810547 L 23.263672 21.810547 L 23.263672 27.625 C 23.263672 30.016 25.231047 31.986328 27.623047 31.986328 C 30.014047 31.986328 31.984375 30.016 31.984375 27.625 L 31.984375 12.814453 C 31.984375 11.656453 31.529891 10.588484 30.712891 9.7714844 L 23.851562 2.9101562 L 21.808594 4.9550781 L 25.943359 9.0898438 C 24.382359 9.7538437 23.263672 11.292891 23.263672 13.087891 C 23.263672 15.477891 25.231047 17.449219 27.623047 17.449219 C 28.134047 17.449219 28.618125 17.341781 29.078125 17.175781 L 29.078125 27.625 C 29.078125 28.448 28.446047 29.078125 27.623047 29.078125 C 26.800047 29.078125 26.169922 28.448 26.169922 27.625 L 26.169922 21.810547 C 26.169922 20.219547 24.853672 18.902344 23.263672 18.902344 L 20.355469 18.902344 L 20.355469 4.3652344 C 20.355469 1.9732344 18.385141 0.00390625 15.994141 0.00390625 L 4.3632812 0.00390625 z M 10.320312 8.0722656 L 12.964844 9.3496094 L 10.683594 13.888672 L 12.28125 14.658203 L 13.591797 15.341797 L 10.058594 22.433594 L 7.4121094 21.15625 L 9.6953125 16.619141 L 8.0957031 15.849609 L 6.7871094 15.164062 L 10.320312 8.0722656 z M 27.623047 11.634766 C 28.440047 11.634766 29.078125 12.270891 29.078125 13.087891 C 29.078125 13.904891 28.440047 14.541016 27.623047 14.541016 C 26.804047 14.541016 26.169922 13.904891 26.169922 13.087891 C 26.169922 12.270891 26.804047 11.634766 27.623047 11.634766 z " />
      </svg>
    </VkwIcon>
  );
};
