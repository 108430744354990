import React, { createContext, useContext, useEffect, useState, ReactNode, ReactElement } from 'react';

import { PaletteType } from '@material-ui/core';

import { useVkwTheme } from '../../themes';

export interface VkwAssetsContextProps {
  logo: string;
  ogImage: string;
  project: string;
  favIconIco: string;
  favIconPng16: string;
  favIconPng32: string;
  favIconPng96: string;
  favIconPng192: string;
  vkwSplitScreenLayoutImage?: string;
}

const VkwAssetsContext = createContext<any | null>(null);

export interface VkwAssetsContextProviderProps<T> {
  generateAssetsObject: (project: string, mode: PaletteType) => T;
  project: string;
  children: ReactNode;
}

export const VkwAssetsContextProvider = <T extends VkwAssetsContextProps>({
  children,
  generateAssetsObject,
  project,
}: VkwAssetsContextProviderProps<T>): ReactElement => {
  const {
    palette: { type: mode },
  } = useVkwTheme();

  const [assets, setAssets] = useState(generateAssetsObject(project, mode));

  useEffect(() => {
    setAssets(generateAssetsObject(project, mode));
  }, [setAssets, generateAssetsObject, project, mode]);

  return <VkwAssetsContext.Provider value={assets}>{children}</VkwAssetsContext.Provider>;
};

export const useVkwAssets = <T extends VkwAssetsContextProps>(): T => {
  const assets = useContext<T>(VkwAssetsContext);

  if (!assets) {
    throw new Error('Use "VkwAssetsContextProvider" somewhere');
  }

  return assets;
};
