import { z } from 'zod';

export const signatureValueSchema = z.object({
  type: z.string(),
  value: z.string(),
});
export const signatureSchema = z.object({
  additionalVerificationInstructions: z.string(),
  calibrationLawCertificateId: z.string(),
  encodingMethod: z.string(),
  meteringSignatureUrl: z.string(),
  publicKey: z.string(),
  values: z.array(signatureValueSchema),
});
export type Signature = z.infer<typeof signatureSchema>;
