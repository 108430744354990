import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconMobilityPortal = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 1000 1000">
        <g transform="matrix(1 0 0 -1 0 1920)" fill={fill}>
          <path d="M10,950.4v40.8c0,11.2,4,20.8,12,28.8s17.6,12,28.8,12v735c0,33.8,12,62.6,35.9,86.6c24,24,52.8,35.9,86.6,35.9H500c33.8,0,62.6-12,86.6-35.9c24-24,35.9-52.8,35.9-86.6v-326.7h81.7c11.2,0,20.8-4,28.8-12s12-17.6,12-28.8v-204.2h81.7v326.7L745,1644.6v40.8h245v-40.8l-81.7-122.5v-367.5c0-11.2-4-20.8-12-28.8c-8-8-17.6-12-28.8-12H704.2c-11.2,0-20.8,4-28.8,12s-12,17.6-12,28.8v204.2h-40.8v-326.7c11.2,0,20.8-4,28.8-12c8-8,12-17.6,12-28.8v-40.8H10z M785.8,1726.3v122.5h40.8v-122.5H785.8z M908.3,1726.3v122.5h40.8v-122.5H908.3z M173.3,1522.1H500v245H173.3V1522.1z" />
        </g>
      </svg>
    </VkwIcon>
  );
};
