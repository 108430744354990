import React, { ReactElement } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import { Assets, AssetsColors } from '../../globals/configureAssets';
import { VkwTheme, getVkwTypographyStyle, useVkwAssets } from '../../library';

const useStyles = makeStyles<VkwTheme, { clickable: boolean; colors: AssetsColors }>(theme => ({
  logo: {
    bottom: '13px',
    height: 'auto',
    maxHeight: '25px',
    maxWidth: '65px',
    position: 'absolute',
    right: '13px',
    width: 'auto',
  },
  productName: {
    margin: '10px',
    ...getVkwTypographyStyle('h9', theme),
    color: theme.palette.text.primary,
  },
  root: props => ({
    backgroundColor: props.colors.productTileBackground,
    border: `1px solid ${props.colors.productTileBorder}`,
    borderRadius: '4px',
    cursor: props.clickable ? 'pointer' : 'default',
    height: '98px',
    minHeight: '98px',
    minWidth: '235px',
    overflow: 'hidden',
    position: 'relative',
    width: '235px',
  }),
}));

interface ProductTileProps {
  productName: string | null;
  onClick?: () => void;
}

export const ProductTile = ({ onClick, productName }: ProductTileProps): ReactElement => {
  const assets = useVkwAssets<Assets>();
  const styles = useStyles({ clickable: !!onClick, colors: assets.colors });

  return (
    <Box className={styles.root} onClick={onClick}>
      <Typography className={styles.productName}>{productName}</Typography>
      <img className={styles.logo} src={assets.logo} alt="Logo" />
    </Box>
  );
};
