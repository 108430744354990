import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { useGoogleMap } from '@react-google-maps/api';
import { useDebounce } from 'use-debounce';

import { VkwIconPin, useVkwFormatMessage } from '../../library';

const googleMapServices = {
  autocomplete: null as google.maps.places.AutocompleteService | null,
  places: null as google.maps.places.PlacesService | null,
};

interface GoogleMapAutoCompleteProps {
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
  onLocationChanged: (location: google.maps.LatLng | null) => void;
}

export const GoogleMapAutoComplete = ({ onLocationChanged, renderInput }: GoogleMapAutoCompleteProps): ReactElement => {
  const map = useGoogleMap();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [inputValue, setInputValue] = React.useState('');
  const [searchString] = useDebounce(inputValue, 250);
  const [options, setOptions] = useState<google.maps.places.AutocompletePrediction[] | null>(null);
  const formatMessage = useVkwFormatMessage();

  useEffect(() => {
    if (!googleMapServices.autocomplete && google.maps) {
      googleMapServices.autocomplete = new google.maps.places.AutocompleteService();
    }

    if (!googleMapServices.places && map) {
      googleMapServices.places = new google.maps.places.PlacesService(map);
    }
  }, [map]);

  const handleValueChanged = (_event: unknown, value: google.maps.places.AutocompletePrediction | null): void => {
    if (!value || !googleMapServices.places) {
      setValue(null);
      onLocationChanged(null);

      return;
    }

    googleMapServices.places.getDetails(
      {
        fields: ['geometry'],
        placeId: value.place_id,
      },
      place => {
        onLocationChanged(place?.geometry?.location ?? null);
        setOptions(options ? [value, ...options] : [value]);
        setValue(value);
      }
    );
  };

  React.useEffect(() => {
    let active = true;

    if (searchString.length < 1) {
      return undefined;
    }

    setOptions(null);
    setLoading(true);

    googleMapServices.autocomplete?.getPlacePredictions({ input: searchString }, result => {
      if (active) {
        setOptions(result);
        setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [searchString]);

  return (
    <Autocomplete
      onInputChange={(_event, value) => {
        setInputValue(value);
      }}
      value={value}
      options={options ?? []}
      loading={loading}
      onChange={handleValueChanged}
      getOptionSelected={(a, b) => a.place_id === b.place_id}
      getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
      renderInput={renderInput}
      noOptionsText={options !== undefined ? formatMessage('NoOptions') : <>{formatMessage('StartTyping')}&hellip;</>}
      loadingText={<>{formatMessage('Loading')}&hellip;</>}
      filterOptions={options => options}
      renderOption={option => {
        return (
          <Grid container alignItems="center">
            <Grid item>
              <Box paddingRight={1}>
                <VkwIconPin size={16} />
              </Box>
            </Grid>
            <Grid item xs>
              {option.structured_formatting.main_text}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
