import React, { ReactElement, ReactNode } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { useVkwNavigation } from '../../contexts';
import { VkwTheme } from '../../themes';

export interface VkwBaseLayoutProps {
  /**
   * Inhalt des Layout
   */
  children: ReactNode;
}

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    content: {
      flexBasis: '1440px',
      flexGrow: 1,
      maxWidth: '100%',

      [theme.breakpoints.up('lg')]: {
        maxWidth: `1440px`,
      },
    },
    header: {
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(2),
      position: 'absolute',
      right: 0,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },

      top: 0,
    },
    rightGap: {
      background: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
      flexGrow: 1,
    },
  }),
  { index: 1 }
);

export const VkwBaseLayout = ({ children }: VkwBaseLayoutProps): ReactElement => {
  const styles = useStyles();
  const navigation = useVkwNavigation();

  return (
    <Box minHeight="100%" width="100%" position="relative" display="flex">
      <Box className={styles.content}>{children}</Box>
      <Box className={styles.rightGap} />
      <Box className={styles.header}>{navigation.renderHeader()}</Box>
    </Box>
  );
};
