import React, { Fragment, ReactElement, ReactNode } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';

export interface ModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  closable?: boolean;
}

const ModalRoot = ({ children, closable, isOpen, onClose }: ModalProps): ReactElement => {
  const closeModal = (): void => (closable ? onClose(false) : undefined);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/50 backdrop-blur-[2px] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-90"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full max-w-xl rounded-2xl bg-white text-left shadow-xl transition-all sm:my-8 dark:bg-gray-900">
                {closable && (
                  <Transition.Child
                    as={Fragment}
                    enter="delay-100 ease-out duration-200"
                    enterFrom="opacity-0 scale-90 rotate-180"
                    enterTo="opacity-200 scale-100 rotate-0"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100 rotate-0"
                    leaveTo="opacity-0 scale-90 rotate-180"
                  >
                    <button
                      type="button"
                      className="absolute -right-2 -top-2 flex rounded-full bg-gray-200 p-1 transition-all hover:scale-105 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700"
                      onClick={closeModal}
                    >
                      <XMarkIcon className="h-6 w-6 text-gray-500" />
                    </button>
                  </Transition.Child>
                )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export interface HeaderProps {
  children: ReactNode;
}

const Header = ({ children }: HeaderProps): ReactElement => {
  return (
    <div className="border-b border-gray-100 p-5 text-lg font-bold text-gray-900 dark:border-gray-800 dark:text-white">
      {children}
    </div>
  );
};

export interface BodyProps {
  children: ReactNode;
}

const Body = ({ children }: BodyProps): ReactElement => {
  return <div className="p-5">{children}</div>;
};

export interface FooterProps {
  children: ReactNode;
}

const Footer = ({ children }: FooterProps): ReactElement => {
  return <div className="border-t border-gray-100 p-5 dark:border-gray-800">{children}</div>;
};

export const Modal = Object.assign(ModalRoot, { Body, Footer, Header });
