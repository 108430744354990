import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconMoneyOutline = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize <= 16) {
          return (
            <g fill="none" fillRule="evenodd" transform="scale(1.5)">
              <path
                fill={fill}
                d="M9.633 0v1.584c.98.123 1.928.377 2.618.708l.217.112.876.482-.964 1.752-.876-.482c-.427-.235-1.121-.437-1.87-.553v3.749c2.21.811 3.098 1.591 3.098 3.38 0 1.994-1.196 3.233-3.098 3.628L9.633 16h-2v-1.515c-1.07-.058-2.222-.29-3.328-.654l-.364-.126-.941-.34.679-1.881.94.34c1.004.361 2.065.592 3.015.657L7.632 8.78C5.06 7.875 3.957 6.966 3.957 4.86c0-2.04 1.495-3.135 3.676-3.33V0h2zm0 9.506l.002 2.79c.75-.255 1.097-.745 1.097-1.564 0-.517-.261-.849-1.099-1.226zM5.957 4.86c0 .818.407 1.27 1.675 1.783V3.542c-1.142.134-1.675.564-1.675 1.319z"
              />
            </g>
          );
        }

        return (
          <g fill={fill} stroke={fill} strokeLinecap="square" strokeWidth={strokewidth}>
            <rect height="22" width="18" fill="none" stroke={fill} x="3" y="1" />

            <line fill="none" x1="12" x2="12" y1="5" y2="19" />

            <path
              d="M15.375,7.219c-1.621-.891-6.727-1.556-6.727,1.513,0,3.674,6.484,2.377,6.484,5.619S10.918,17.349,8,16.3"
              fill="none"
            />
          </g>
        );
      }}
    </VkwIcon>
  );
};
