import React, { ReactElement } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import { Assets, AssetsColors } from '../../globals/configureAssets';
import { VkwTheme, getVkwTypographyStyle, useVkwAssets } from '../../library';

const useStyles = makeStyles<VkwTheme, { colors: AssetsColors }>(theme => ({
  root: props => ({
    backgroundColor: props.colors.statisticTileBackground,
    borderRadius: '4px',
    height: '104px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      width: '254px',
    },
    width: '274px',
  }),
  title: props => ({
    ...getVkwTypographyStyle('text14', theme),
    color: props.colors.statisticTileText,
    padding: '16px 0 0 16px',
  }),
  value: props => ({
    ...getVkwTypographyStyle('h2', theme),
    color: props.colors.statisticTileText,
    padding: '12px 0 0 16px',
  }),
}));

interface StatisticTileProps {
  title: string;
  value: string | number;
}

export const StatisticTile = ({ title, value }: StatisticTileProps): ReactElement => {
  const assets = useVkwAssets<Assets>();
  const styles = useStyles({ colors: assets.colors });

  return (
    <Box className={styles.root}>
      <Typography className={styles.title}>{title}</Typography>
      <Typography className={styles.value}>{value.toLocaleString('de')}</Typography>
    </Box>
  );
};
