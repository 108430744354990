import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree';

import { SearchResult } from '../features/map/components/MapSearchResults';

const RecentSearchTermsModel = types.model('RecentSearchTerms').props({
  displayName: types.string,
  id: types.identifier,
  secondaryText: types.maybe(types.string),
  type: types.string,
});

export const MapStoreModel = types
  .model('MapStore')
  .props({
    activeFilter: 1,
    chargingPowerFilter: types.maybe(types.array(types.number)),
    commonFilter: 'operatorStations',
    lastMapStoreUpdate: types.maybe(types.string),
    plugFilter: types.maybe(types.array(types.string)),
    recentSearchTerms: types.optional(types.array(RecentSearchTermsModel), []),
  })
  .actions(store => ({
    addRecentSearchTerm(recentSearchResult: SearchResult) {
      let recentSearchTerms = [...store.recentSearchTerms];

      //if the term is already in the list, remove it, and add it to the beginning,
      //it needs to be the same element otherwise mobx doesn't like it (cannot just recreate an identical element)
      const index = recentSearchTerms.findIndex(term => term.id === recentSearchResult.id);
      if (index > -1) {
        const element = recentSearchTerms.splice(index, 1);
        recentSearchTerms.unshift(element[0]);
      } else {
        //else, add it to the beginning
        recentSearchTerms.unshift(cast(recentSearchResult));
      }

      //if the list is longer than 5, remove the last element
      if (recentSearchTerms.length > 5) {
        recentSearchTerms = recentSearchTerms.slice(0, 5);
      }

      store.recentSearchTerms = cast(recentSearchTerms);
    },
    clearRecentSearchTerms() {
      store.recentSearchTerms = cast([]);
    },
    setFilter(plugFilter?: string[], chargingPowerFilter?: number[], commonFilter?: string) {
      console.log('setFilter', plugFilter, chargingPowerFilter, commonFilter);
      store.plugFilter = cast(plugFilter);
      store.chargingPowerFilter = cast(chargingPowerFilter);
      store.commonFilter = commonFilter ?? 'allStations';
      store.lastMapStoreUpdate = Date.now().toString();

      store.activeFilter = 0;

      if (store.plugFilter && store.plugFilter.length > 0) {
        console.log('store.plugFilter', store.plugFilter);
        store.activeFilter++;
      }

      if (store.chargingPowerFilter && store.chargingPowerFilter.length > 0) {
        console.log('store.chargingPowerFilter', store.chargingPowerFilter);
        store.activeFilter++;
      }

      if (store.commonFilter && store.commonFilter !== 'allStations') {
        console.log('store.commonFilter', store.commonFilter);
        store.activeFilter++;
      }

      console.log('activeFilter', store.activeFilter);
    },
  }));

export type MapStore = Instance<typeof MapStoreModel>;
export type MapStoreSnapshot = SnapshotOut<typeof MapStoreModel>;
