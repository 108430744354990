import React, { ReactElement } from 'react';

import clsx from 'clsx';

export interface ButtonProps {
  type?: 'default' | 'danger' | 'primary' | 'light' | 'text';
  icon?: ReactElement;
  fullWidth?: boolean;
  title?: string;
  onPress?: () => void;
}

export const Button = (props: ButtonProps) => {
  const getColors = () => {
    if (!props.type) {
      return 'bg-gray-300 text-gray-800 ring-gray-300';
    }

    switch (props.type) {
      case 'danger':
        return 'bg-red-500 text-red-50 ring-red-500';
      case 'primary':
        return 'bg-blue-700 text-white ring-blue-700';
      case 'light':
        return 'bg-gray-200 text-gray-800 ring-gray-200';
      case 'text':
        return 'bg-transparent text-gray-600';
      default:
        return 'bg-gray-500 text-gray-100 ring-gray-500';
    }
  };

  return (
    <button
      className={clsx(
        'cursor-pointer rounded-xl px-5 py-3 font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
        props.fullWidth && 'block text-center',
        getColors()
      )}
      type="button"
      onClick={() => props.onPress?.()}
    >
      {props.icon || props.title}
    </button>
  );
};
