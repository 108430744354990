import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconHomeOutlined = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = 'currentColor';
  const strokewidth = '2';

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeWidth={strokewidth}>
        <polyline fill="none" points="10 23 10 17 14 17 14 23" stroke={fill} strokeLinecap="butt" />

        <polyline fill="none" points="1 11 12 2 23 11" strokeLinecap="butt" />

        <polyline fill="none" points="4 13 4 23 20 23 20 13" stroke={fill} />

        <rect height="2" width="2" fill={fill} stroke="none" x="11" y="11" />

        <rect height="2" width="2" fill="none" stroke={fill} x="11" y="11" />
      </g>
    </VkwIcon>
  );
};
