import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconSort = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path d="M17 5a1 1 0 0 1 .993.883L18 6v13.585l2.293-2.292a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 .083 1.32l-.083.094-4 4a.97.97 0 0 1-.09.08l.09-.08a.927.927 0 0 1-.112.097c-.024.018-.049.034-.074.05l-.037.021c-.02.011-.04.022-.06.031l-.053.023-.06.021-.045.014-.065.016-.053.009c-.02.003-.04.006-.062.007a.47.47 0 0 1-.053.003L17 23h-.032l-.054-.004L17 23c-.05 0-.1-.004-.149-.011l-.052-.01a.762.762 0 0 1-.065-.015l-.046-.014-.06-.021-.051-.023-.061-.03-.037-.022a1.2 1.2 0 0 1-.074-.05l-.018-.014a.974.974 0 0 1-.094-.083l-4-4a1 1 0 0 1 1.32-1.497l.094.083L16 19.585V6a1 1 0 0 1 1-1zM7 1h.02c.023 0 .046.002.07.004L7 1c.05 0 .1.004.149.011l.052.01a.762.762 0 0 1 .065.015l.046.014.06.021.051.023.061.03.037.022a1.2 1.2 0 0 1 .074.05l.018.014c.007.005.01.007.012.01l.082.073 4 4a1 1 0 0 1-1.32 1.497l-.094-.083L8 4.414V17a1 1 0 0 1-1.993.117L6 17V4.414L3.707 6.707a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1-.083-1.32l.083-.094 4-4 .082-.073.008-.007-.09.08a.927.927 0 0 1 .112-.097c.024-.018.049-.034.074-.05l.037-.021c.02-.011.04-.022.06-.031l.053-.023.06-.021.045-.014.065-.016.053-.009.058-.007L6.982 1H7z" />{' '}
      </g>
    </VkwIcon>
  );
};
