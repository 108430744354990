// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { ReactElement, useMemo, useState } from 'react';

import { ArrowRightIcon, ScaleIcon } from '@heroicons/react/24/solid';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import Axios, { AxiosResponse } from 'axios';
import clsx, { ClassValue } from 'clsx';

import { ChargePoint, StartChargingSession, StartChargingSessionResult } from '@e-vo/types';

import { PlugTooltip } from './PlugTooltip';
import { Money } from '../../components';
import { ChargingTariffContract, useConfigContext, useUserContext } from '../../contexts';
import { Pricing, useHandleAxiosResponse, usePricings } from '../../hooks';
import {
  getVkwTypographyStyle,
  useVkwFormatMessage,
  useVkwSnackbar,
  VkwDialog,
  VkwLoader,
  VkwTheme,
} from '../../library';
import { ChargePointStatusWithNotAllowed } from '../../util/charge-stations';
import { getPlugIcon } from '../../util/getPlugIcon';

const useStyles = makeStyles<VkwTheme>(theme => ({
  chargePoint: {
    position: 'relative',
  },
  evseId: {
    ...getVkwTypographyStyle('text16', theme),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  evseIdLine: {
    ...getVkwTypographyStyle('text14', theme),
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
  },
  header: {
    ...getVkwTypographyStyle('text14', theme),
    alignItems: 'center',
    display: 'flex',
  },
  plug: {
    paddingRight: theme.spacing(0.5),
  },
  plugs: {
    paddingRight: theme.spacing(0.5),
  },
  price: {
    ...getVkwTypographyStyle('h10', theme),
  },
  status: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: '20px',
    color: 'rgba(255,255,255,0.8)',
    fontSize: '12px',
    marginTop: '-2px',
    padding: '6px 12px',
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  statusAvailable: {
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  statusError: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  statusReserved: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  statusUnknown: {
    background: theme.palette.grey[500],
    color: theme.palette.common.white,
  },
  suffix: {
    ...getVkwTypographyStyle('text14', theme),
    opacity: 0.6,
  },
  unauthorized: {
    opacity: 0.6,
  },
}));

export const Spinner = (): ReactElement => {
  return (
    <svg
      className="ml-2 h-5 w-5 animate-spin text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};

export const Pricings = ({ pricings }: PricingsProps): ReactElement => {
  const styles = useStyles();

  if (pricings.loading) {
    return <VkwLoader />;
  }

  return (
    <table>
      <tbody>
        {pricings.entries.map((entry, i) => (
          <tr key={i} className={styles.price}>
            <td>
              <Money value={entry.value} />
            </td>
            <td>/</td>
            <td className={styles.suffix}>{entry.label}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

interface ChargeStationPointProps {
  chargePoint: ChargePoint;
  contract: ChargingTariffContract | null;
}

export const ChargePointTile = ({ chargePoint, contract }: ChargeStationPointProps): ReactElement => {
  const configContext = useConfigContext();
  const formatMessage = useVkwFormatMessage();
  const enqueueVkwSnackbar = useVkwSnackbar();
  const handleAxiosResponse = useHandleAxiosResponse();
  const userContext = useUserContext();

  const [open, setOpen] = useState(false);
  const [startingChargingSession, setStartingChargingSession] = useState<boolean>(false);

  const styles = useStyles();
  const status = useMemo(() => {
    if (!contract) {
      return 'unknown';
    }

    for (const authorizationGroup of chargePoint.authorizationGroups) {
      if (contract.authorizationGroups.includes(authorizationGroup)) {
        return 'authorized';
      }
    }

    return 'unauthorized';
  }, [contract, chargePoint]);

  const pricings = usePricings(chargePoint.evseId, contract?.contractId ?? null, contract?.authenticationEvcoId);

  const bgColor: Record<ChargePointStatusWithNotAllowed, ClassValue[]> = {
    AVAILABLE: ['bg-green-600'],
    NOT_ALLOWED: ['bg-gray-300'],
    OCCUPIED: ['bg-yellow-600'],
    OUT_OF_SERVICE: ['bg-red-600'],
    RESERVED: ['bg-blue-600'],
    UNKNOWN: ['bg-gray-500'],
  };

  const rootClasses = clsx(styles.chargePoint, bgColor[chargePoint.status], 'text-white', {
    [styles.unauthorized]: status === 'unauthorized',
  });

  const getChargePointStatusFormatMessageId = (): string => {
    const statusArr = chargePoint.status.split('_');

    return `ChargeStationStatus${statusArr
      .map(value => value[0].toUpperCase() + value.toLowerCase().slice(1))
      .join('')}`;
  };

  const onClickConfirmStartChargingSession = () => {
    setStartingChargingSession(true);
    setOpen(false);
    handleAxiosResponse(
      () =>
        Axios.post(`/api/next/v1/charge-points/${chargePoint.evseId}/charging-sessions/start`, {
          customerId: userContext.selectedCustomer?.id,
          evcoId: contract?.authenticationEvcoId,
          pricingValidityToken: pricings.validityToken,
        }),
      {
        error: () => {
          setStartingChargingSession(false);
        },
        notFound: () => {
          setStartingChargingSession(false);
        },
        success: (response: AxiosResponse<StartChargingSession>) => {
          const { data } = response;

          if (data.result === StartChargingSessionResult.SUCCESS) {
            enqueueVkwSnackbar(formatMessage('ChargingSessionStartSuccessful'), 'success');
          } else {
            enqueueVkwSnackbar(formatMessage('ChargingSessionStartFailed'), 'error');
          }

          setStartingChargingSession(false);
        },
        tooManyRequests: () => {
          setStartingChargingSession(false);
        },
        unauthorized: () => {
          setStartingChargingSession(false);
        },
      },
      {
        errorSnackbarMessage: formatMessage('ChargingSessionStartFailed'),
        notFoundSnackbarMessage: formatMessage('ChargingSessionStartFailed'),
        tooManyRequestsSnackbarMessage: formatMessage('ChargingSessionStartFailed'),
        unauthorizedSnackbarMessage: formatMessage('ChargingSessionStartFailed'),
      }
    );
  };

  const startChargingSessionAllowed =
    contract &&
    status === 'authorized' &&
    chargePoint.status !== 'OUT_OF_SERVICE' &&
    chargePoint.isChargingAllowed &&
    configContext.featureFlags.enableStartChargingSessions;

  return (
    <>
      <div key={chargePoint.evseId} className={rootClasses}>
        <div className="p-4">
          <div className={styles.header}>
            <div className={styles.plugs}>
              {chargePoint.plugs.map((plug, i) => {
                const PlugIcon = getPlugIcon(plug);
                return (
                  <span className={styles.plug} key={i}>
                    <PlugTooltip plug={plug}>
                      <PlugIcon />
                    </PlugTooltip>
                  </span>
                );
              })}
            </div>
            <span className={styles.status}>{formatMessage(getChargePointStatusFormatMessageId())}</span>
            <div className={styles.suffix}>
              {formatMessage('MaxPower')} {Math.round(chargePoint.maxPower)} kW
            </div>
          </div>
          <div className={styles.evseIdLine}>
            <div className={styles.evseId} title={chargePoint.evseId.length > 28 ? chargePoint.evseId : ''}>
              {chargePoint.evseId}
            </div>
            {chargePoint.isNationalCalibrationLawCompliant && (
              <Tooltip title={formatMessage('NationalCalibrationLawCompliant')} arrow placement="bottom">
                <ScaleIcon className="h-5 w-5" />
              </Tooltip>
            )}
          </div>
          {contract && status === 'authorized' && (
            <Box paddingTop={1}>
              <Pricings pricings={pricings} />
            </Box>
          )}
          {contract && status === 'unauthorized' && (
            <Box paddingTop={1}>{formatMessage('ChargeStationStatusContractUnauthorized')}</Box>
          )}
        </div>
        {startChargingSessionAllowed && (
          <button
            type="button"
            className="flex w-full cursor-pointer items-center justify-end bg-black/20 p-4 font-semibold text-white transition ease-in hover:bg-black/30"
            onClick={() => (!startingChargingSession ? setOpen(true) : null)}
          >
            {startingChargingSession && (
              <>
                {formatMessage('ChargingSessionStarting')}
                <Spinner />
              </>
            )}
            {!startingChargingSession && (
              <>
                {formatMessage('ChargingSessionStart')} <ArrowRightIcon className="ml-2 h-5 w-5" />
              </>
            )}
          </button>
        )}
      </div>

      {open && startChargingSessionAllowed && (
        <VkwDialog
          open={open}
          onSave={onClickConfirmStartChargingSession}
          onCancel={() => setOpen(false)}
          onClose={() => setOpen(false)}
          headline={formatMessage('ChargingSessionConfirm')}
          showCloseIcon
          saveButtonLabel={formatMessage('ChargingSessionStart')}
          renderContent={() => {
            return (
              <Box pt={1} pb={3.75}>
                <Typography className={styles.infoText}>{formatMessage('ChargingSessionConfirmQuestion')}</Typography>
              </Box>
            );
          }}
        />
      )}
    </>
  );
};

interface PricingsProps {
  pricings: Pricing;
}
