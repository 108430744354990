import React, { ReactElement } from 'react';

import { useRouter } from 'next/router';

import { TariffCard } from '../../components';
import { ChargingTariffContract } from '../../contexts';
import { useBaseDataStore } from '../../hooks';
import { useVkwFormatMessage, VkwDataGrid } from '../../library';

const FILTER_COOKIE_KEY = 'tariffs';

export const Tariffs = (): ReactElement => {
  const router = useRouter();
  const formatMessage = useVkwFormatMessage();

  const store = useBaseDataStore<ChargingTariffContract>({
    defaultSortColumn: 'contractValidTo',
    defaultSortDirection: 'desc',
    filterCookieKey: FILTER_COOKIE_KEY,
    pageSize: 8,
    url: '/api/v1/ChargingTariffContracts',
  });

  return (
    <VkwDataGrid
      initialized={store.initialized}
      loading={store.loading}
      loadAdditionalEntries={store.loadAdditionalEntries}
      page={store.page}
      pages={store.pages}
      data={store.data}
      totalRecords={store.totalRecords}
      hasMore={store.hasMore}
      setPage={store.setPage}
      setIsMobile={store.setIsMobile}
      noResultsDisplayText={formatMessage('NoResultsForChargings')}
      noResultsWithActiveFilterDisplayText={formatMessage('NoResultsWithActiveFilter')}
      renderElement={(dataEntry: ChargingTariffContract) => {
        const {
          authenticationCardUid,
          contractAlias,
          contractId,
          contractValidTo,
          id,
          productName,
          vehicleLicensePlate,
          vehicleType,
        } = dataEntry;

        return (
          <TariffCard
            contractAlias={contractAlias ?? productName}
            vehicleType={vehicleType}
            vehicleLicensePlate={vehicleLicensePlate}
            contractId={contractId}
            authenticationCardUid={authenticationCardUid}
            onClick={() => router.push(`/tariffs/[id]`, `/tariffs/${id}`)}
            cancellationDate={contractValidTo}
          />
        );
      }}
      filters={store.filters}
      filterOptions={store.filterOptions}
      onChangeFilter={store.changeFilter}
      onClearFilters={store.clearFilters}
    />
  );
};
