import React, { ReactElement } from 'react';

import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { VkwTheme } from '../../themes';

export interface VkwStatusTextProps {
  type: 'warning' | 'success' | 'error';
  label: string;
}

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    root: {
      borderRadius: 2,
      display: 'inline-block',
      fontSize: '12px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      fontWeight: 500,
      letterSpacing: '-0.17px',
      lineHeight: 'normal',
      padding: `${theme.spacing(0.25)}px ${theme.spacing(0.5)}px `,
    },
    success: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  }),
  { index: 1 }
);

export const VkwStatusText = ({ label, type }: VkwStatusTextProps): ReactElement => {
  const styles = useStyles();

  const itemClass = clsx(styles.root, {
    [styles.error]: type === 'error',
    [styles.success]: type === 'success',
    [styles.warning]: type === 'warning',
  });

  return <div className={itemClass}>{label}</div>;
};
