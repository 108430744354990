import React, { ReactElement } from 'react';

import { Link } from '@material-ui/core';

import { Assets } from '../../globals/configureAssets';
import { VkwIconFacebookBlock, VkwIconInstagram, VkwIconTwitterBlock, useVkwAssets } from '../../library';

export const useSocialLinks = (): ReactElement[] => {
  const assets = useVkwAssets<Assets>();

  const socialLinks = [];
  if (assets.links.facebook) {
    socialLinks.push(
      <Link
        key="facebook"
        color="textSecondary"
        href={assets.links.facebook}
        title={assets.links.facebook}
        target="_blank"
        rel="noopener"
      >
        <VkwIconFacebookBlock size={16} />
      </Link>
    );
  }

  if (assets.links.twitter) {
    socialLinks.push(
      <Link
        key="twitter"
        color="textSecondary"
        href={assets.links.twitter}
        title={assets.links.twitter}
        target="_blank"
        rel="noopener"
      >
        <VkwIconTwitterBlock size={16} />
      </Link>
    );
  }

  if (assets.links.instagram) {
    socialLinks.push(
      <Link
        key="instagram"
        color="textSecondary"
        href={assets.links.instagram}
        title={assets.links.instagram}
        target="_blank"
        rel="noopener"
      >
        <VkwIconInstagram size={16} />
      </Link>
    );
  }

  return socialLinks;
};
