import React, { ReactElement, ReactNode, useMemo, useState } from 'react';

import { Box, Hidden, Tooltip } from '@material-ui/core';

import { Signature } from '@e-vo/types';

import { ChargingTariffChargeDetailsRecordDetail } from './ChargingTariffChargeDetailsRecordDetail';
import {
  ChargeDetailRecordStatistic,
  ChargeDetailRecordSummary,
  ChargingLocationTableCell,
  Money,
  TimespanCacheEdit,
} from '../../components';
import { Config, useConfigContext, useUserContext } from '../../contexts';
import { ChargeDetailRecord, useChargeDetailRecordsDataStore, useSortableListSettings } from '../../hooks';
import {
  FormatMessage,
  useVkwFormatMessage,
  VkwBaseTableCell,
  VkwDataTable,
  VkwDataTableFieldConfig,
  VkwDialog,
  VkwIconButton,
  VkwIconCharging,
  VkwIconCheck,
  VkwIconDownload,
  VkwIconInfo,
  VkwOverlay,
} from '../../library';
import { VkwIconCopy } from '../../library/icons/VkwIconCopy';

const renderChargingLocation = (dataEntry: ChargingTariffChargeDetailRecord): ReactNode => {
  return <ChargingLocationTableCell key="charging-location" dataEntry={dataEntry} />;
};

const getTableFieldsConfig = (
  formatMessage: FormatMessage,
  config: Config
): VkwDataTableFieldConfig<ChargingTariffChargeDetailRecord>[] => [
  {
    active: true,
    label: formatMessage('ChargingLocation'),
    renderCustomCell: renderChargingLocation,
  },
  {
    active: true,
    converter: 'time',
    label: formatMessage('ChargingDuration'),
    property: 'durationInSeconds',
    sortColumn: 'durationInSeconds',
  },
  {
    active: true,
    converter: 'dateTime',
    label: formatMessage('ChargingStartTime'),
    property: 'startTime',
    sortColumn: 'startTime',
  },
  {
    active: true,
    converter: 'dateTime',
    label: formatMessage('ChargingEndTime'),
    property: 'endTime',
  },
  {
    active: true,
    converter: 'localeNumber',
    label: formatMessage('ConsumedEnergy'),
    property: 'consumedEnergy',
  },
  {
    active: true,
    hidden: config.hidePrice,
    label: formatMessage('TotalGrossCost'),
    property: 'totalGrossCost',
    renderCustomCell: function renderTotalCost(dataEntry) {
      return (
        <VkwBaseTableCell key="totalCost">
          <Money value={dataEntry.totalGrossCost} />
        </VkwBaseTableCell>
      );
    },
    sortColumn: 'totalGrossCost',
  },
  {
    active: false,
    label: formatMessage('VehicleLicensePlate'),
    property: 'vehicleLicensePlate',
  },
  {
    active: false,
    label: formatMessage('ChargingContractDesignation'),
    property: 'contractAlias',
  },
  {
    active: false,
    label: formatMessage('ProductName'),
    property: 'productName',
  },
  {
    active: false,
    label: formatMessage('ChargingPointName'),
    property: 'chargingPointName',
  },
  {
    active: false,
    label: formatMessage('ChargingPointId'),
    property: 'chargingPointId',
  },

  {
    active: false,
    hidden: config.hidePrice,
    label: formatMessage('SumEnergyCosts'),
    property: 'sumEnergyCosts',
    renderCustomCell: function renderTotalCost(dataEntry) {
      return (
        <VkwBaseTableCell key="energyCosts">
          <Money value={dataEntry.energyGrossCost} />
        </VkwBaseTableCell>
      );
    },
  },
  {
    active: false,
    hidden: config.hidePrice,
    label: formatMessage('SumTimeCosts'),
    property: 'sumTimeCosts',
    renderCustomCell: function renderTotalCost(dataEntry) {
      return (
        <VkwBaseTableCell key="timeCosts">
          <Money value={dataEntry.timeGrossCost} />
        </VkwBaseTableCell>
      );
    },
  },
  {
    active: false,
    hidden: config.hidePrice,
    label: formatMessage('SumStartCosts'),
    property: 'sumStartCosts',
    renderCustomCell: function renderTotalCost(dataEntry) {
      return (
        <VkwBaseTableCell key="startCosts">
          <Money value={dataEntry.startGrossCost} />
        </VkwBaseTableCell>
      );
    },
  },
  {
    active: false,
    label: formatMessage('InvoiceId'),
    property: 'invoiceId',
  },
  {
    active: false,
    label: formatMessage('InvoiceRecipientName'),
    property: 'invoiceRecipientName',
  },
  {
    active: false,
    label: formatMessage('PowerType'),
    property: 'powerType',
  },
  {
    active: false,
    label: formatMessage('ChargingStationOperator'),
    property: 'chargingStationOperator',
  },
  {
    active: false,
    label: formatMessage('ChargingContractUid'),
    property: 'contractId',
  },
  {
    active: false,
    label: formatMessage('VehicleAlias'),
    property: 'vehicleAlias',
  },
  {
    active: false,
    label: formatMessage('VehicleType'),
    property: 'vehicleType',
  },
  {
    active: false,
    label: formatMessage('AuthenticationType'),
    property: 'authenticationType',
  },
  {
    active: false,
    label: formatMessage('NationalCalibrationLawInformation'),
    property: 'signature.publicKey',
    renderCustomCell: value => {
      return <SignatureTableCell signature={value.signature} />;
    },
  },
  {
    active: false,
    label: formatMessage('EvcoId'),
    property: 'evcoId',
  },
];

const SignatureTableCell = ({ signature }: { signature?: Signature }) => {
  const [open, setOpen] = useState(false);
  const [clipboardCopied, setClipboardCopied] = useState(false);
  const formatMessage = useVkwFormatMessage();

  function copyToClipboard() {
    if (signature) {
      navigator.clipboard.writeText(signature.publicKey);
      setClipboardCopied(true);
    }
  }

  return (
    <VkwBaseTableCell className="max-w-[80px]">
      {signature?.publicKey && (
        <>
          <span className="truncate">{signature.publicKey}</span>
          <VkwIconButton onClick={() => setOpen(!open)}>
            <VkwIconInfo size={16} />
          </VkwIconButton>
          <VkwDialog
            open={open}
            key={signature.publicKey}
            onClose={() => setOpen(false)}
            showCloseIcon
            renderContent={() => (
              <>
                <h2>
                  <a
                    target="_blank"
                    href="https://www.safe-ev.de/de/transparenzsoftware.php"
                    className="font-medium text-blue-500 underline"
                    rel="noreferrer"
                  >
                    {formatMessage('NationalCalibrationLawTransparencySoftware')}
                  </a>
                </h2>
                <div className="grid grid-cols-3 gap-1 px-2 py-4">
                  {signature.publicKey && (
                    <>
                      <p className="font-bold">{formatMessage('ChargePointPublicKey')}</p>
                      <span className="col-span-2">
                        {signature.publicKey}
                        <Tooltip key={signature.publicKey} title={formatMessage('CopyToClipboard')}>
                          <VkwIconButton
                            onClick={() => copyToClipboard()}
                            color={clipboardCopied ? 'primary' : 'default'}
                          >
                            {clipboardCopied ? <VkwIconCheck size={16} /> : <VkwIconCopy size={16} />}
                          </VkwIconButton>
                        </Tooltip>
                      </span>
                    </>
                  )}
                  {signature.meteringSignatureUrl && (
                    <>
                      <p className="font-bold">{formatMessage('ChargePointSignedMeterValue')}</p>
                      <span className="col-span-2">{signature.meteringSignatureUrl}</span>
                    </>
                  )}
                  {signature.encodingMethod && (
                    <>
                      <p className="font-bold">{formatMessage('EncodingMethod')}</p>
                      <span className="col-span-2">{signature.encodingMethod}</span>
                    </>
                  )}
                  {signature.additionalVerificationInstructions && (
                    <>
                      <p className="font-bold">{formatMessage('AdditionalVerificationInstructions')}</p>
                      <span className="col-span-2">{signature.additionalVerificationInstructions}</span>
                    </>
                  )}
                  {signature.calibrationLawCertificateId && (
                    <>
                      <p className="font-bold">{formatMessage('NationalCalibrationLawCertificateId')}</p>
                      <span className="col-span-2">{signature.calibrationLawCertificateId}</span>
                    </>
                  )}
                  {signature.values && (
                    <>
                      <p className="font-bold">Werte</p>
                      <div className="col-span-2">
                        {signature.values.map(val => (
                          <div key={val.type}>
                            <div className="flex items-center space-x-2">
                              <span className="font-medium">{formatMessage('Type')}:</span>
                              <span>{val.type}</span>
                            </div>
                            <div className="flex items-center space-x-2">
                              <span className="font-medium">{formatMessage('Value')}:</span>
                              <span>{val.value}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          />
        </>
      )}
    </VkwBaseTableCell>
  );
};

export interface ChargingTariffChargeDetailRecord extends ChargeDetailRecord {
  transactionId: 'string';
  customerId: 'string';
  contractId: 'string';
  chargingContractId: 'string';
  contractAlias: 'string';
  productName: 'string';
  authenticationCardUid: 'string';
  chargingPointLocation: 'string';
  chargingPointId: 'string';
  chargingPointName: 'string';
  chargingStationOperator: 'string';
  startTime: Date;
  endTime: Date;
  durationInSeconds: number;
  totalNetCost: number;
  totalGrossCost: number;
  energyNetCost: number;
  energyGrossCost: number;
  timeNetCost: number;
  timeGrossCost: number;
  startNetCost: number;
  startGrossCost: number;
  consumedEnergy: number;
  powerType: 'string';
  authenticationType: 'string';
  authenticationId: 'string';
  vehicleType: 'string';
  vehicleLicensePlate: 'string';
  vehicleAlias: 'string';
  invoiceId: 'string';
  invoiceRecipientName: 'string';
  isShared: boolean;
  evcoId: string;
  signature?: Signature;
}

export interface ChargingTariffChargeDetailRecordsProps {
  initialFilters?: { [key: string]: string[] | number[] | Date[] };
}

const FILTER_COOKIE_KEY = 'charge-detail-records';

export const ChargingTariffChargeDetailRecords = ({
  initialFilters,
}: ChargingTariffChargeDetailRecordsProps): ReactElement => {
  const { config } = useConfigContext();
  const formatMessage = useVkwFormatMessage();
  const tableFieldsConfig = getTableFieldsConfig(formatMessage, config);
  const defaultTableFieldsConfig = getTableFieldsConfig(formatMessage, config);
  const user = useUserContext();
  const store = useChargeDetailRecordsDataStore<ChargingTariffChargeDetailRecord>({
    csvFileName: formatMessage('ChargingTariffCDRExportFilename'),
    filterCookieKey: FILTER_COOKIE_KEY,
    url: '/api/v1/ChargingTariffChargeDetailRecords',
  });

  useMemo(() => {
    setTimeout(() => {
      if (initialFilters) {
        for (const key in initialFilters) {
          if (Object.prototype.hasOwnProperty.call(initialFilters, key)) {
            store.changeFilter(key, initialFilters[key]);
          }
        }
      }
    }, 100);
  }, []);

  const [selectedChargeDetailRecord, setSelectedChargeDetailRecord] = useState<ChargingTariffChargeDetailRecord | null>(
    null
  );

  const renderMobileItem = (dataEntry: ChargingTariffChargeDetailRecord): ReactNode => {
    return (
      <ChargeDetailRecordSummary
        key={dataEntry.id}
        onClick={() => setSelectedChargeDetailRecord(dataEntry)}
        data={{
          amount: dataEntry.totalGrossCost,
          chargingPointLocation: dataEntry.chargingPointLocation,
          displayName: dataEntry.contractAlias ?? dataEntry.productName,
          durationInSeconds: dataEntry.durationInSeconds,
          startTime: dataEntry.startTime,
        }}
      />
    );
  };

  const sortableListSettings = useSortableListSettings('charging-tariff-charge-details-records-table');

  const filterOption = store.filterOptions.find(filterOption => filterOption.column === 'chargeDateRange');

  let min = new Date();
  let max = new Date();
  let values: Date[] = [];

  if (filterOption) {
    min = filterOption.configuration.min ? (filterOption.configuration.min as Date) : new Date();
    max = filterOption.configuration.max ? (filterOption.configuration.max as Date) : new Date();
    values = (store.filters.get(filterOption.column) as Date[]) ?? [];
  }

  return (
    <>
      {filterOption && (
        <TimespanCacheEdit
          maxTimespanValue={max}
          minTimespanValue={min}
          timespanValues={values}
          changeTimespan={values => store.changeFilter(filterOption.column, values)}
          cacheLocked={user.dataCacheState?.chargingTariffChargeDetailRecordsLocked}
          cacheLastUpdate={user.dataCacheState?.chargingTariffChargeDetailRecordsLastUpdate}
          updateCache={user.updateDataCache}
        />
      )}
      {store.initialized && store.totalRecords > 0 && store.chargeDetailRecordsStatistic && (
        <ChargeDetailRecordStatistic statistic={store.chargeDetailRecordsStatistic} totalRecords={store.totalRecords} />
      )}
      <Box marginTop={1}>
        <VkwDataTable
          initialized={store.initialized}
          loading={store.loading}
          loadAdditionalEntries={store.loadAdditionalEntries}
          page={store.page}
          pageSize={store.pageSize}
          pages={store.pages}
          data={store.data}
          totalRecords={store.totalRecords}
          hasMore={store.hasMore}
          setPage={store.setPage}
          setIsMobile={store.setIsMobile}
          tableFieldsConfig={tableFieldsConfig}
          noResultsDisplayText={formatMessage('NoResultsForChargings')}
          noResultsWithActiveFilterDisplayText={formatMessage('NoResultsWithActiveFilter')}
          renderButtons={() => (
            <VkwIconButton title={formatMessage('ExportAsCSV')} onClick={store.downloadCSV}>
              <VkwIconDownload />
            </VkwIconButton>
          )}
          renderIcon={() => <VkwIconCharging size={16} />}
          defaultFiltersCount={6}
          defaultTableFieldsConfig={defaultTableFieldsConfig}
          filters={store.filters}
          filterOptions={store.filterOptions}
          onChangeFilter={store.changeFilter}
          onClearFilters={store.clearFilters}
          renderMobileItem={renderMobileItem}
          settingsProvider={sortableListSettings}
          sortColumn={store.sortColumn}
          sortDirection={store.sortDirection}
          setSortColumn={store.setSortColumn}
        />
      </Box>
      {selectedChargeDetailRecord && (
        <Hidden smUp>
          <VkwOverlay open onClose={() => setSelectedChargeDetailRecord(null)}>
            <ChargingTariffChargeDetailsRecordDetail chargeDetailRecord={selectedChargeDetailRecord} />
          </VkwOverlay>
        </Hidden>
      )}
    </>
  );
};
