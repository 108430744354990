import { useEffect, useState } from 'react';

export interface GeoLocation {
  coords: GeolocationCoordinates | null;
  error: string | null;
}

export const useGeoLocation = (): GeoLocation => {
  const [coords, setCoords] = useState<GeolocationCoordinates | null>(null);
  const [error, setError] = useState<string | null>(null);

  const onChange = ({ coords }: { coords: GeolocationCoordinates }): void => {
    setCoords(coords);
  };

  const onError = (error: GeolocationPositionError): void => {
    setError(error.message);
  };

  useEffect(() => {
    let isActive = true;

    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        position => {
          if (!isActive) {
            return;
          }

          onChange(position);
        },
        error => {
          if (!isActive) {
            return;
          }

          onError(error);
        }
      );
    } else {
      setError('Geolocation is not supported');
    }

    return () => {
      isActive = false;
    };
  }, []);

  return {
    coords,
    error,
  };
};
