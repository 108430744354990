import { ChargePointStatus } from '@e-vo/types';

import { Assets, PinType } from '../globals/configureAssets';
import { useVkwAssets } from '../library';

export interface ChargeStationPin {
  url: string;
  status: string;
}

export const useChargeStationPin = (
  chargePoints: { status: ChargePointStatus; isChargingAllowed?: boolean }[],
  type: PinType
): ChargeStationPin => {
  const assets = useVkwAssets<Assets>();

  let available = 0;
  let occupied = 0;
  let outOfService = 0;
  let reserved = 0;
  let allowed = 0;

  chargePoints?.forEach(chargePoint => {
    if (typeof chargePoint.isChargingAllowed === 'undefined' || chargePoint.isChargingAllowed) {
      allowed++;
    }

    switch (chargePoint.status) {
      case 'AVAILABLE':
        available++;
        break;
      case 'OCCUPIED':
        occupied++;
        break;
      case 'RESERVED':
        reserved++;
        break;
      case 'OUT_OF_SERVICE':
        outOfService++;
        break;
      default:
        break;
    }
  });

  let iconUrl = assets.pins.unknown(type);
  let chargeStationStatus = 'Unknown';
  if (available > 0) {
    iconUrl = assets.pins.available(type);
    chargeStationStatus = 'Available';
  } else if (occupied > 0 || reserved > 0) {
    iconUrl = assets.pins.occupied(type);
    chargeStationStatus = 'Occupied';
  } else if (outOfService > 0) {
    iconUrl = assets.pins.outOfService(type);
    chargeStationStatus = 'OutOfService';
  }

  if (allowed <= 0) {
    iconUrl = assets.pins.notAllowed(type);
  }

  return { status: chargeStationStatus, url: iconUrl };
};
