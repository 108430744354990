import { cast, Instance, SnapshotOut, types } from 'mobx-state-tree';

export const SettingsStoreModel = types
  .model('SettingsStore')
  .props({
    favoriteChargeStations: types.maybe(types.array(types.string)),
    isSystemLocale: types.maybe(types.boolean),
    isSystemTheme: types.maybe(types.boolean),
    locale: types.maybe(types.string),
    theme: types.maybe(types.string),
  })
  .actions(store => ({
    addFavoriteChargeStation(station: string) {
      if (!store.favoriteChargeStations) {
        store.favoriteChargeStations = cast([station]);
      } else {
        store.favoriteChargeStations.push(station);
      }
    },
    removeFavoriteChargeStation(station: string) {
      if (store.favoriteChargeStations) {
        store.favoriteChargeStations.remove(station);
      }
    },
    setLocale(locale: string, system = false) {
      store.locale = locale;
      store.isSystemLocale = system;
    },
    setTheme(theme: string, system = false) {
      store.theme = theme;
      store.isSystemTheme = system;
    },
  }));

export type SettingsStore = Instance<typeof SettingsStoreModel>;
export type SettingsStoreSnapshot = SnapshotOut<typeof SettingsStoreModel>;
