import { v4 as uuidV4 } from 'uuid';

import { getCookie, deleteCookie, hasCookie, setCookie } from './cookies';

export const defaultCookieConsentCookieName = '__Host-cookie-consent';

export type CookieConsentCategory = 'analytics' | 'marketing' | 'necessary' | 'tools';

export const necessaryCookieConsentCategories: CookieConsentCategory[] = ['necessary'];
export const optionalCookieConsentCategories: CookieConsentCategory[] = [];

export interface CookieConsentCookieData {
  categories: CookieConsentCategory[];
  date: string;
  last_update: string;
  uuid: string;
}

/**
 * Returns whether the consent cookie was set
 */
export const hasCookieConsent = (): boolean => {
  return hasCookie(defaultCookieConsentCookieName);
};

/**
 * Returns the consent cookie
 */
export const getCookieConsent = (): CookieConsentCookieData | undefined => {
  return hasCookieConsent()
    ? (getCookie(defaultCookieConsentCookieName) as unknown as CookieConsentCookieData)
    : undefined;
};

/**
 * Returns the data for the consent cookie
 */
const getCookieConsentCookieData = (categories: CookieConsentCategory[]): CookieConsentCookieData => {
  const currentConsentCookie = getCookieConsent();

  return {
    categories,
    date: currentConsentCookie?.date ?? new Date().toJSON(),
    last_update: new Date().toJSON(),
    uuid: currentConsentCookie?.uuid ?? uuidV4(),
  };
};

/**
 * Set the consent cookie
 */
export const setCookieConsent = (allowAll = true, categories: CookieConsentCategory[] = []) => {
  return setCookie(
    defaultCookieConsentCookieName,
    getCookieConsentCookieData(
      necessaryCookieConsentCategories.concat(allowAll ? optionalCookieConsentCategories : categories)
    ),
    {
      path: '/',
      sameSite: 'lax',
      secure: true,
    }
  );
};

/**
 * Reset the consent cookie
 */
export const resetCookieConsent = (): void => {
  deleteCookie(defaultCookieConsentCookieName);
};
