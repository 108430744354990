import React, { ReactElement } from 'react';

import { Marker, useGoogleMap, OverlayView, OverlayViewF } from '@react-google-maps/api';
import { isArray } from 'lodash';

import { ClusterOverlayView } from './ClusterOverlayView';
import { DEFAULT_ZOOM } from './const';
import { ListMarkerOverlayView } from './ListMarkerOverlayView';
import { MarkerOverlayView } from './MarkerOverlayView';
import { useConfigContext } from '../../contexts';
import { Assets } from '../../globals/configureAssets';
import { ChargeStation, useChargeStationPin } from '../../hooks';
import { useVkwAssets } from '../../library';

interface ChargeStationMarkerProps {
  chargeStation: ChargeStation;
  onChargeStationClick: (chargeStation: ChargeStation) => void;
  selectedChargeStation: ChargeStation | null;
}

const ChargeStationMarker = ({
  chargeStation,
  onChargeStationClick,
  selectedChargeStation,
}: ChargeStationMarkerProps): ReactElement => {
  const configContext = useConfigContext();
  const isActive: boolean = chargeStation.id === selectedChargeStation?.id;

  const chargeStationPin = useChargeStationPin(
    chargeStation.chargePoints,
    selectedChargeStation?.id === chargeStation.id ? 'selected' : 'default'
  ).url;

  const location = {
    lat: chargeStation.location.latitude,
    lng: chargeStation.location.longitude,
  };

  const handleChargeStationClick = (): void => {
    onChargeStationClick(chargeStation);
  };

  const handleMarkerClick = (e: google.maps.MapMouseEvent): void => {
    e.stop();
    handleChargeStationClick();
  };

  return configContext.featureFlags.enableDynamicMarkerIcons ? (
    <OverlayViewF
      position={location}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width: number, height: number) => ({
        x: -(width / 2),
        y: -height - 10,
      })}
    >
      <MarkerOverlayView chargeStation={chargeStation} isActive={isActive} onClick={handleChargeStationClick} />
    </OverlayViewF>
  ) : (
    <Marker
      onClick={handleMarkerClick}
      key={chargeStation.id}
      position={location}
      icon={chargeStationPin}
      zIndex={isActive ? -20 : -30}
    />
  );
};

interface ChargeStationListMarkerProps {
  chargeStationList: ChargeStation[];
  onChargeStationListClick: (chargeStations: ChargeStation[]) => void;
  selectedChargeStationList: ChargeStation[] | null;
}

const ChargeStationListMarker = ({
  chargeStationList,
  onChargeStationListClick,
  selectedChargeStationList,
}: ChargeStationListMarkerProps): ReactElement => {
  const isActive: boolean = chargeStationList === selectedChargeStationList;

  let totalLat = 0;
  let totalLng = 0;

  chargeStationList.forEach(chargeStation => {
    totalLat += chargeStation.location.latitude;
    totalLng += chargeStation.location.longitude;
  });

  const location = {
    lat: totalLat / chargeStationList.length,
    lng: totalLng / chargeStationList.length,
  };

  const handleChargeStationListClick = (): void => {
    onChargeStationListClick(chargeStationList);
  };

  return (
    <OverlayViewF
      position={location}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width: number, height: number) => ({
        x: -(width / 2),
        y: -height - 10,
      })}
    >
      <ListMarkerOverlayView
        chargeStationList={chargeStationList}
        isActive={isActive}
        onClick={handleChargeStationListClick}
      />
    </OverlayViewF>
  );
};

interface ClusterMarkerProps {
  chargeStation: ChargeStation;
}

const ClusterMarker = ({ chargeStation }: ClusterMarkerProps): ReactElement => {
  const map = useGoogleMap() as google.maps.Map;

  const location = {
    lat: chargeStation.location.latitude,
    lng: chargeStation.location.longitude,
  };

  const handleClusterClick = (): void => {
    map.setCenter(location);
    map.setZoom((map.getZoom() ?? DEFAULT_ZOOM) + 2);
  };

  return (
    <OverlayViewF
      position={location}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={(width: number, height: number) => ({
        x: -(width / 2),
        y: -(height / 2),
      })}
    >
      <ClusterOverlayView count={chargeStation.count} onClick={handleClusterClick} />
    </OverlayViewF>
  );
};

interface MarkersProps {
  chargeStations: (ChargeStation | ChargeStation[])[];
  currentPosition: google.maps.LatLng | google.maps.LatLngLiteral;
  searchPosition: google.maps.LatLng | google.maps.LatLngLiteral | null;
  onChargeStationClick: (chargeStation: ChargeStation) => void;
  onChargeStationListClick: (chargeStations: ChargeStation[]) => void;
  selectedChargeStation: ChargeStation | null;
  selectedChargeStationList: ChargeStation[] | null;
}

export const Markers = ({
  chargeStations,
  currentPosition,
  onChargeStationClick,
  onChargeStationListClick,
  searchPosition,
  selectedChargeStation,
  selectedChargeStationList,
}: MarkersProps): ReactElement => {
  const assets = useVkwAssets<Assets>();

  return (
    <>
      <Marker key="current-position" position={currentPosition} icon={assets.pins.location} zIndex={-100} />
      {searchPosition && <Marker key="search-position" position={searchPosition} zIndex={-100} />}
      {chargeStations.map(chargeStation => {
        if (isArray(chargeStation)) {
          return (
            <ChargeStationListMarker
              key={chargeStation[0].id}
              chargeStationList={chargeStation}
              onChargeStationListClick={onChargeStationListClick}
              selectedChargeStationList={selectedChargeStationList}
            />
          );
        }

        if (chargeStation.type === 'cluster') {
          return <ClusterMarker key={chargeStation.key} chargeStation={chargeStation} />;
        }

        return (
          <ChargeStationMarker
            key={chargeStation.id}
            chargeStation={chargeStation}
            onChargeStationClick={onChargeStationClick}
            selectedChargeStation={selectedChargeStation}
          />
        );
      })}
    </>
  );
};
