import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';

import { Box, Button, Grid, Popover } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import clsx from 'clsx';

import { useStyles } from './StatisticTimespanSelectorStyles';
import {
  vkwDateIo,
  VkwDateIoDateType,
  useVkwFormatMessage,
  useVkwTheme,
  VkwButton,
  VkwIconArrowDown,
  VkwIconArrowUp,
  dateDisplay,
} from '../../library';

interface StatisticTimespanSelectorProps {
  values: Date[];
  onChange: (value: Date[]) => void;
  min: Date;
  max: Date;
}

const dateFormat = 'DD.MM.YYYY';

export const StatisticTimespanSelector = ({
  max,
  min,
  onChange,
  values,
}: StatisticTimespanSelectorProps): ReactElement => {
  const theme = useVkwTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();

  const propValues = useMemo(
    () =>
      values.map((value, index) => {
        switch (index) {
          case 0:
            return vkwDateIo.date(value).startOf('day').toDate();
          case 1:
            return vkwDateIo.date(value).endOf('day').toDate();
          default:
            return vkwDateIo.date(value).toDate();
        }
      }),
    [values]
  );
  const propMin = useMemo(() => vkwDateIo.date(min).startOf('day').toDate(), [min]);
  const propMax = useMemo(() => vkwDateIo.date(max).endOf('day').toDate(), [max]);

  const [internalValues, setInternalValues] = useState([propValues[0] ?? propMin, propValues[1] ?? propMax]);

  useEffect(() => {
    setInternalValues([propValues[0] ?? propMin, propValues[1] ?? propMax]);
  }, [propValues, anchorEl]);

  const handleMinValueChange = (value: VkwDateIoDateType | null): void => {
    if (!value || value.isBefore(propMin)) {
      setInternalValues(prevValues => [propMin, prevValues[1]]);
    } else if (value.isAfter(propMax)) {
      setInternalValues(prevValues => [propMax, prevValues[1]]);
    } else {
      setInternalValues(prevValues => [vkwDateIo.date(value).startOf('day').toDate(), prevValues[1]]);
    }
  };

  const handleMaxValueChange = (value: VkwDateIoDateType | null): void => {
    if (!value || value.isAfter(propMax)) {
      setInternalValues(prevValues => [prevValues[0], propMax]);
    } else if (value.isBefore(propMin)) {
      setInternalValues(prevValues => [prevValues[0], propMin]);
    } else {
      setInternalValues(prevValues => [prevValues[0], vkwDateIo.date(value).endOf('day').toDate()]);
    }
  };

  function renderMenu(): ReactNode {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <KeyboardDatePicker
              DialogProps={{
                className: styles.datePicker,
              }}
              variant="dialog"
              onChange={handleMinValueChange}
              value={internalValues[0]}
              minDate={propMin}
              maxDate={propMax}
              placeholder={dateFormat}
              format={dateFormat}
              disableToolbar
              fullWidth
              invalidDateMessage={formatMessage('InvalidDate')}
              inputVariant="filled"
              label={formatMessage('DateFilterStart')}
              maxDateMessage={formatMessage('DateFilterOverMax', { MaxDate: dateDisplay(max) })}
              minDateMessage={formatMessage('DateFilterUnderMin', { MinDate: dateDisplay(min) })}
              InputProps={{ className: styles.input }}
              InputLabelProps={{ className: styles.label }}
              cancelLabel={formatMessage('Cancel')}
              okLabel={formatMessage('Ok')}
            />
          </Grid>
          <Grid item xs={12}>
            <KeyboardDatePicker
              DialogProps={{
                className: styles.datePicker,
              }}
              variant="dialog"
              onChange={handleMaxValueChange}
              value={internalValues[1]}
              minDate={propMin}
              maxDate={propMax}
              placeholder={dateFormat}
              format={dateFormat}
              fullWidth
              disableToolbar
              invalidDateMessage={formatMessage('InvalidDate')}
              inputVariant="filled"
              label={formatMessage('DateFilterEnd')}
              maxDateMessage={formatMessage('DateFilterOverMax', { MaxDate: dateDisplay(max) })}
              minDateMessage={formatMessage('DateFilterUnderMin', { MinDate: dateDisplay(min) })}
              InputProps={{ className: styles.input }}
              InputLabelProps={{ className: styles.label }}
              cancelLabel={formatMessage('Cancel')}
              okLabel={formatMessage('Ok')}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  const handleFilterChange = (): void => {
    if (vkwDateIo.date(propMin).isSame(internalValues[0]) && vkwDateIo.date(propMax).isSame(internalValues[1])) {
      onChange([]);
    } else {
      onChange(internalValues);
    }

    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const disableReset =
    vkwDateIo.date(propMin).isSame(internalValues[0]) && vkwDateIo.date(propMax).isSame(internalValues[1]);
  const disableSave = vkwDateIo.date(internalValues[0]).isAfter(internalValues[1]);

  const itemClass = clsx(styles.button, {
    [styles.buttonActive]: values.length > 0,
    [styles.buttonOpen]: open,
  });

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={event => setAnchorEl(event.currentTarget)}
        className={itemClass}
      >
        {formatMessage('ChooseStatisticTimespan')}
        <Box width={theme.spacing(2)} />
        {open ? <VkwIconArrowUp size={12} /> : <VkwIconArrowDown size={12} />}
      </Button>
      <Box width={theme.spacing(2)} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setInternalValues([propValues[0] ?? propMin, propValues[1] ?? propMax]);
          setAnchorEl(null);
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        <div className={styles.filterContainer}>
          <div className={styles.menu}>{renderMenu()}</div>
          <div className={styles.footer}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <VkwButton
                  onClick={() => setInternalValues([propMin, propMax])}
                  color="default"
                  fullWidth
                  variant="contained"
                  disabled={disableReset}
                >
                  {formatMessage('Reset')}
                </VkwButton>
              </Grid>
              <Grid item xs={6}>
                <VkwButton
                  onClick={handleFilterChange}
                  fullWidth
                  variant="contained"
                  color={theme.palette.mainButtonColor}
                  disabled={disableSave}
                >
                  {formatMessage('Save')}
                </VkwButton>
              </Grid>
            </Grid>
          </div>
        </div>
      </Popover>
    </>
  );
};
