import { useCallback, useEffect, useRef, useState } from 'react';

import Axios, { AxiosResponse, Canceler } from 'axios';

export interface HandleAxiosResponseHandler {
  success: (axiosResponse: AxiosResponse) => void;
}

export interface InfinityScrollingValues<T> {
  items: T[];
  loading: boolean;
  lastPageElementRef: (node: any) => void;
}

export const useVkwInfinityScrolling = <T>(
  path: string,
  pageSize: number,
  handleAxiosResponse: (
    handleAxiosResponse: () => Promise<AxiosResponse>,
    handler: HandleAxiosResponseHandler
  ) => Promise<void | boolean>
): InfinityScrollingValues<T> => {
  const [hasMore, setHasMore] = useState(false);
  const [loadAdditionalEntries, setLoadAdditionalEntries] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const observer = useRef<IntersectionObserver>();
  const [items, setItems] = useState<T[]>([]);

  useEffect(() => {
    let totalRecords;
    let cancel: Canceler;
    setLoading(true);

    handleAxiosResponse(
      () =>
        Axios.get(path, {
          cancelToken: new Axios.CancelToken(c => {
            cancel = c;
          }),
          params: {
            page: pageNumber,
            pageSize,
          },
        }),
      {
        success: response => {
          if (loadAdditionalEntries) {
            setItems(items => {
              return [...items, ...response.data.items];
            });
          } else {
            setItems(response.data.items);
            setLoadAdditionalEntries(true);
          }
          totalRecords = response.data.total;
          setHasMore(pageNumber < Math.ceil(totalRecords / pageSize));
          setLoading(false);
        },
      }
    );
    return () => cancel();
  }, [pageNumber]);

  const lastPageElementRef = useCallback(
    (node: any) => {
      if (loading) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, hasMore]
  );

  return { items, lastPageElementRef, loading };
};
