import { PaletteType } from '@material-ui/core';

import { VkwAssetsContextProps, VkwTheme } from '../library';
import { getDefaultMapGestureHandeling, getDefaultThemeMode } from '../themes';
import { EnvUrls } from '../util/helpers';

export type PinType = 'default' | 'small' | 'selected';

const getPinTypeSuffix = (type: PinType): string => {
  if (type === 'default') {
    return '';
  }

  return `_${type}`;
};

type Links = {
  corporateWebsite: string;
  impressum?: string;
  privacyPolicy?: string;
  termsOfUse?: string;
  facebook?: string;
  twitter?: string;
  instagram?: string;
  priceInfo?: string;
};

export type AssetsColors = {
  markerLabel: string;
  productTileBackground: string;
  productTileBorder: string;
  statisticTileBackground: string;
  statisticTileText: string;
};

export interface Assets extends VkwAssetsContextProps {
  logo: string;
  logoMonoWhite: string;
  pins: {
    available: (type: PinType) => string;
    unknown: (type: PinType) => string;
    occupied: (type: PinType) => string;
    outOfService: (type: PinType) => string;
    notAllowed: (type: PinType) => string;
    location: string;
  };
  clusterer: string;
  card: string;
  contract: string;
  invoices: string;
  links: Links;
  defaultThemeMode: 'dark' | 'light';
  mapGestureHandling: 'greedy' | 'cooperative' | null;
  colors: AssetsColors;
}

const getLinks = (envUrls: EnvUrls): Links => {
  return {
    corporateWebsite: envUrls.corporateWebsiteUrl,
    facebook: envUrls.facebookUrl ?? undefined,
    impressum: envUrls.impressumUrl ?? undefined,
    instagram: envUrls.instagramUrl ?? undefined,
    priceInfo: envUrls.priceInfoUrl ?? undefined,
    privacyPolicy: envUrls.privacyPolicyUrl ?? undefined,
    termsOfUse: envUrls.termsOfUseUrl ?? undefined,
    twitter: envUrls.twitterUrl ?? undefined,
  };
};

export const generateAssetsObject = (project: string, envUrls: EnvUrls, mode: PaletteType, theme: VkwTheme): Assets => {
  return {
    card: `/media/${project}/${mode}/card.svg`,
    clusterer: `/media/${project}/clusterer.svg`,
    colors: {
      markerLabel: project === 'moon' ? theme.palette.primary.main : theme.palette.common.white,
      productTileBackground:
        theme.palette.type === 'light'
          ? theme.palette.grey[100]
          : project === 'moon'
          ? theme.palette.grey[700]
          : theme.palette.primary.main,
      productTileBorder:
        theme.palette.type === 'light'
          ? theme.palette.grey[200]
          : project === 'moon'
          ? theme.palette.grey[700]
          : theme.palette.primary.main,
      statisticTileBackground:
        theme.palette.type === 'light'
          ? theme.palette.primary.main
          : project === 'moon'
          ? theme.palette.secondary.main
          : theme.palette.primary.main,
      statisticTileText:
        theme.palette.type === 'light'
          ? theme.palette.common.white
          : project === 'moon'
          ? theme.palette.primary.main
          : theme.palette.common.white,
    },
    contract: `/media/${project}/contract.png`,
    defaultThemeMode: getDefaultThemeMode(project),
    favIconIco: `/media/${project}/favicon.ico`,
    favIconPng16: `/media/${project}/favicon-16x16.png`,
    favIconPng32: `/media/${project}/favicon-32x32.png`,
    favIconPng96: `/media/${project}/favicon-96x96.png`,
    favIconPng192: `/media/${project}/favicon-192x192.png`,
    invoices: `/media/${project}/invoices.png`,
    links: getLinks(envUrls),
    logo: `/media/${project}/${mode}/logo.svg`,
    logoMonoWhite: `/media/${project}/logo_mono_white.svg`,
    mapGestureHandling: getDefaultMapGestureHandeling(project),
    ogImage: `/media/${project}/${mode}/logo.svg`,
    pins: {
      available: (type: PinType) => `/media/${project}/pin_green${getPinTypeSuffix(type)}.svg`,
      location: '/media/pin_location.svg',
      notAllowed: (type: PinType) => `/media/pin_light_gray${getPinTypeSuffix(type)}.svg`,
      occupied: (type: PinType) => `media/${project}/pin_yellow${getPinTypeSuffix(type)}.svg`,
      outOfService: (type: PinType) =>
        project === 'evn'
          ? `/media/${project}/${mode}/pin_gray${getPinTypeSuffix(type)}.svg`
          : `/media/${project}/pin_red${getPinTypeSuffix(type)}.svg`,
      unknown: (type: PinType) => `/media/${project}/${mode}/pin_gray${getPinTypeSuffix(type)}.svg`,
    },
    project,
    vkwSplitScreenLayoutImage: `/media/${project}/${mode}/split-screen.jpg`,
  };
};
