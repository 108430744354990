import React, { ChangeEvent, ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { IconTextField } from './IconTextField';
import { useVkwFormatMessage } from '../../../hooks';
import { VkwIconSearch } from '../../../icons';
import { VkwTheme } from '../../../themes';
import { VkwBaseFilter } from '../../VkwBaseFilter';
import { VkwCheckbox } from '../../VkwCheckbox';

export interface VkwMultiSelectOption {
  value: string;
  title: string;
}

export interface VkwMultiSelectFilterProps {
  title: string;
  options: VkwMultiSelectOption[];
  values: string[];
  onChange: (values: string[]) => void;
  /**
   * Platzhaltertext im Suchfeld des Filters
   */
  searchPlaceholder: string;
  mode: 'desktop' | 'mobile';
}

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    activeOptions: {
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      marginBottom: '5px',
      paddingBottom: '3px',
    },
    checkbox: {
      '&:hover': {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
      },
    },
    options: {
      flexShrink: 1,
      maxHeight: '450px',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  }),
  { index: 1 }
);

export const VkwMultiSelectFilter = ({
  mode,
  onChange,
  options,
  searchPlaceholder,
  title,
  /**
   * defines a value for each element.
   */
  values,
}: VkwMultiSelectFilterProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();

  const [searchString, setSearchString] = useState('');
  const [newValues, setNewValues] = useState(values);

  const optionsForDisplay = useMemo(() => {
    const topOptions: VkwMultiSelectOption[] = [];
    const defaultOptions: VkwMultiSelectOption[] = options.slice();
    if (options && options.length >= 10) {
      options.forEach(option => {
        if (values.findIndex(value => value === option.value) >= 0) {
          topOptions.push(option);
          defaultOptions.splice(defaultOptions.indexOf(option), 1);
        }
      });
    }

    return {
      defaultOptions,
      topOptions,
    };
  }, [values, options]);

  useEffect(() => {
    setNewValues(values);
  }, [values]);

  const handleChange = (): void => {
    onChange(newValues);
    setSearchString('');
  };

  const renderOption = (option: VkwMultiSelectOption): ReactNode => {
    const index = searchString ? option.title.toLowerCase().indexOf(searchString.toLowerCase()) : undefined;
    if (index === -1) {
      return null;
    }

    const checked = newValues.findIndex((value: string) => value === option.value) >= 0;

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
      let newValuesResult = [...newValues];
      if (event.target.checked) {
        newValuesResult.push(event.target.value);
      } else {
        newValuesResult = newValuesResult.filter(value => value !== event.target.value);
      }

      setNewValues(newValuesResult);
    };

    return (
      <div key={option.value} className={styles.checkbox}>
        <VkwCheckbox
          fullWidth
          checked={checked}
          label={option.title}
          value={option.value}
          onChange={handleCheckboxChange}
        />
      </div>
    );
  };

  const createOptionsDom = (options: VkwMultiSelectOption[]): ReactNode[] => {
    const optionsDom: ReactNode[] = [];

    options.forEach(option => {
      const optionDom = renderOption(option);
      if (!optionDom) {
        return;
      }

      optionsDom.push(optionDom);
    });

    return optionsDom;
  };

  const renderMenu = (): ReactNode => {
    const topOptionsDom = createOptionsDom(optionsForDisplay.topOptions);
    const defaultOptionsDom = createOptionsDom(optionsForDisplay.defaultOptions);

    return (
      <>
        <div>
          <IconTextField
            placeholder={searchPlaceholder}
            value={searchString}
            onChange={event => setSearchString(event.currentTarget.value)}
            icon={
              <Box mr={1.5} ml={0.5}>
                <VkwIconSearch size={16} />
              </Box>
            }
            type="search"
          />
        </div>
        <div className={styles.options}>
          {topOptionsDom.length > 0 || defaultOptionsDom.length > 0 ? (
            <>
              {topOptionsDom.length > 0 && (
                <div className={defaultOptionsDom.length > 0 ? styles.activeOptions : undefined}>{topOptionsDom}</div>
              )}
              {defaultOptionsDom}
            </>
          ) : (
            <i>{`${formatMessage('NoResult')}\u2026`}</i>
          )}
        </div>
      </>
    );
  };

  const showSave = useMemo(() => JSON.stringify(newValues) !== JSON.stringify(values), [newValues, values]);

  return (
    <VkwBaseFilter
      active={values.length > 0}
      renderMenu={renderMenu}
      onReset={newValues.length > 0 ? () => setNewValues([]) : undefined}
      onSave={showSave ? handleChange : undefined}
      onClose={() => {
        setNewValues(values);
      }}
      title={title}
      mode={mode}
    />
  );
};
