import React, { ReactElement, ReactNode } from 'react';

import { makeStyles, IconButton, Tooltip, lighten, darken } from '@material-ui/core';
import clsx from 'clsx';

import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    iconButton: {
      '&&': {
        minHeight: 48,
        minWidth: 48,
        padding: 0,

        [theme.breakpoints.up('md')]: {
          minHeight: 40,
          minWidth: 40,
        },
      },
    },
    shadow: {
      '&:active': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? lighten(theme.palette.background.default, 0.2)
            : darken(theme.palette.background.default, 0.2),
      },
      '&:hover': {
        backgroundColor:
          theme.palette.type === 'dark'
            ? lighten(theme.palette.background.default, 0.1)
            : darken(theme.palette.background.default, 0.1),
      },

      backgroundColor: theme.palette.background.default,

      boxShadow: theme.shadows[5],
    },
  }),
  { index: 1 }
);

export interface VkwIconButtonProps {
  /**
   * Hier wird das Icon übergeben
   */
  children: ReactNode;
  color?: 'primary' | 'secondary' | 'default' | 'inherit';
  /**
   * Wird ein `title` angegeben so wird dem Button ein Tooltip mit diesem Text hinzugefügt
   */
  title?: string;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  shadow?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const VkwIconButton = ({
  children,
  color,
  disabled,
  onClick,
  shadow,
  title,
  type,
}: VkwIconButtonProps): ReactElement => {
  const styles = useStyles();
  const className = clsx(styles.iconButton, { [styles.shadow]: shadow });

  return (
    <Tooltip title={title && !disabled ? title : ''} arrow placement="bottom">
      <IconButton className={className} disabled={!!disabled} color={color} type={type} onClick={onClick}>
        {children}
      </IconButton>
    </Tooltip>
  );
};
