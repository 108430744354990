import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconFavoriteOutline = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeWidth={strokewidth}>
        <polygon
          fill="none"
          points="12 2.489 15.09 8.751 22 9.755 17 14.629 18.18 21.511 12 18.261 5.82 21.511 7 14.629 2 9.755 8.91 8.751 12 2.489"
          stroke={fill}
        />
      </g>
    </VkwIcon>
  );
};
