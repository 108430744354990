import React, { ReactElement } from 'react';

import { Tooltip } from '@material-ui/core';

import { InfrastructureChargeStation, useChargeStationPin } from '../../hooks';
import { useVkwFormatMessage } from '../../library';

interface InfrastructureChargeStationIconProps {
  infrastructureChargeStation: InfrastructureChargeStation;
}

export const InfrastructureChargeStationIcon = ({
  infrastructureChargeStation,
}: InfrastructureChargeStationIconProps): ReactElement => {
  const pin = useChargeStationPin(infrastructureChargeStation.chargePoints, 'small');
  const formatMessage = useVkwFormatMessage();
  return (
    <Tooltip title={formatMessage(`ChargeStationStatus${pin.status}`)} arrow placement="top">
      <img src={pin.url} alt="" />
    </Tooltip>
  );
};
