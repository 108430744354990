import React, { ReactElement } from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';

import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    root: {
      color: theme.palette.text.primary,
    },
  }),
  { index: 1 }
);

export const VkwLoaderV2 = (): ReactElement => {
  const styles = useStyles();

  return <CircularProgress className={styles.root} size="3rem" />;
};
