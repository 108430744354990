import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconCheckboxDisabled = ({ size }: VkwIconProps): ReactElement => {
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      <g fill="none" fillRule="evenodd" strokeWidth={strokewidth}>
        <rect width="22.5" height="22.5" x=".75" y=".75" stroke="#F0F0F0" rx="2" />
        <path
          fillRule="nonzero"
          stroke="#000"
          strokeLinecap="square"
          d="M6 11.581L10.507 16.088 18.728 7.867"
          opacity=".2"
        />
      </g>
    </VkwIcon>
  );
};
