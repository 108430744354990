import { useEffect, useState } from 'react';

import { ChargePoint } from '@e-vo/types';

import { InfrastructureContract, InfrastructureContractChargePoint, useContractsContext } from '../contexts';

interface MyChargePoints {
  myChargePoints: null | InfrastructureContractChargePoint[];
}

const intersectChargePoints = (
  allChargePoints: ChargePoint[],
  infrastructureContracts: InfrastructureContract[]
): null | InfrastructureContractChargePoint[] => {
  if (allChargePoints.length > 0 && infrastructureContracts.entries.length > 0) {
    return null;
  }
  let infrastructureChargePoints: InfrastructureContractChargePoint[] = [];
  infrastructureContracts.forEach(infrastructureContract => {
    if (infrastructureContract.chargePoints !== null && infrastructureContract.chargePoints.length > 0) {
      infrastructureChargePoints = infrastructureChargePoints.concat(infrastructureContract.chargePoints);
    }
  });

  const intersect: InfrastructureContractChargePoint[] = [];
  allChargePoints.forEach(chargePoint => {
    infrastructureChargePoints.forEach(infrastructureChargePoint => {
      if (chargePoint.evseId === infrastructureChargePoint.evseId) {
        intersect.push(infrastructureChargePoint);
      }
    });
  });

  return intersect;
};

export const useMyChargePoints = (allChargePoints: ChargePoint[]): MyChargePoints => {
  const [myChargePoints, setMyChargePoints] = useState<null | InfrastructureContractChargePoint[]>(null);
  const { infrastructureContracts } = useContractsContext();

  useEffect(() => {
    if (!infrastructureContracts.loading) {
      setMyChargePoints(intersectChargePoints(allChargePoints, infrastructureContracts.entries));
    }
  }, [allChargePoints, infrastructureContracts]);

  return { myChargePoints };
};
