import React, { ReactElement, createContext, ReactNode, useContext } from 'react';

import { VkwNavigationItemConfig } from '../../components';

export interface VkwNavigationContextProps {
  renderHeader: () => ReactNode;
  renderCopyright: () => ReactNode;
  renderSocialLinks: () => ReactNode;
  renderFooter: () => ReactNode;
  mainElement: HTMLElement | null;
  activeRoute: VkwNavigationItemConfig | null;
}

const VkwNavigationContext = createContext<VkwNavigationContextProps | null>(null);

export interface VkwNavigationContextProviderProps {
  navigationProvider: VkwNavigationContextProps;
  children: ReactNode;
}

export const VkwNavigationContextProvider = ({
  children,
  navigationProvider,
}: VkwNavigationContextProviderProps): ReactElement => {
  return <VkwNavigationContext.Provider value={navigationProvider}>{children}</VkwNavigationContext.Provider>;
};

export const useVkwNavigation = (): VkwNavigationContextProps => {
  const context = useContext(VkwNavigationContext);

  if (!context) {
    throw new Error('Use "VkwNavigationContextProvider" somewhere');
  }

  return context;
};

export const useOptionalVkwNavigation = (): VkwNavigationContextProps | null => {
  return useContext(VkwNavigationContext);
};
