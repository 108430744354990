import React, { ReactElement, useEffect, useState } from 'react';

import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import { Toggle } from '@e-vo/ui';

import { useVkwFormatMessage } from '../../library';
import { CookieConsentCategory, necessaryCookieConsentCategories } from '../../util/cookie-consent';
import { CookieConsentModalDisclosureTable } from '../CookieConsentModalDisclosureTable';

export interface CookieConsentModalDisclosureProps {
  onChange?: (consentCategories: CookieConsentCategory[]) => void;
}

export const CookieConsentModalDisclosure = ({ onChange }: CookieConsentModalDisclosureProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();

  const [consentCategories, setConsentCategories] = useState<CookieConsentCategory[]>(necessaryCookieConsentCategories);

  const isActiveConsentCategory = (category: CookieConsentCategory): boolean => consentCategories.includes(category);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleActiveConsentCategory = (category: CookieConsentCategory): void =>
    setConsentCategories(
      isActiveConsentCategory(category)
        ? consentCategories.filter(i => i !== category)
        : [...consentCategories, category]
    );

  useEffect(() => {
    if (onChange) {
      onChange(consentCategories);
    }
  }, [consentCategories]);

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button
            as="div"
            className={`${
              open ? 'bg-gray-200 dark:bg-gray-700' : 'bg-gray-100 dark:bg-gray-800'
            } flex w-full cursor-pointer justify-between p-5 text-sm transition-all hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-300/75 dark:hover:bg-gray-700`}
          >
            <span className="self-center font-medium text-gray-900 dark:text-white">
              {formatMessage('CookieConsentNecessaryCookiesTitle')}
            </span>
            <div className="flex justify-between space-x-4">
              <Toggle checked={isActiveConsentCategory('necessary')} disabled />
              <div className="flex h-7 w-7 items-center justify-center rounded-full border border-gray-300 dark:border-gray-600">
                <ChevronDownIcon
                  className={`${
                    open ? 'rotate-180' : ''
                  } h-5 w-5 text-gray-500 transition-all duration-200 ease-in dark:text-gray-400`}
                />
              </div>
            </div>
          </Disclosure.Button>
          <Transition
            show={open}
            className="scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-400 dark:scrollbar-track-gray-700 dark:scrollbar-thumb-gray-600 overflow-y-auto transition-all"
            enter="duration-100 ease-in"
            enterFrom="max-h-0"
            enterTo="max-h-48"
            leave="duration-100 ease-out"
            leaveFrom="max-h-48"
            leaveTo="max-h-0"
          >
            <Disclosure.Panel static className="bg-gray-100 text-sm text-gray-500 dark:bg-gray-800 dark:text-gray-400">
              <div className="p-5">{formatMessage('CookieConsentNecessaryCookiesDescription')}</div>
              <CookieConsentModalDisclosureTable>
                <CookieConsentModalDisclosureTable.Entry name="__Secure-next-auth.session-token" maxAge="30d">
                  {formatMessage('CookieConsentSessionTokenDescription')}
                </CookieConsentModalDisclosureTable.Entry>
                <CookieConsentModalDisclosureTable.Entry name="__Secure-next-auth.callback-url" maxAge="Session">
                  {formatMessage('CookieConsentCallbackUrlDescription')}
                </CookieConsentModalDisclosureTable.Entry>
                <CookieConsentModalDisclosureTable.Entry name="__Host-next-auth.csrf-token" maxAge="Session">
                  {formatMessage('CookieConsentCsrfTokenDescription')}
                </CookieConsentModalDisclosureTable.Entry>
                <CookieConsentModalDisclosureTable.Entry name="__Host-filter" maxAge="Session">
                  {formatMessage('CookieConsentFilterDescription')}
                </CookieConsentModalDisclosureTable.Entry>
                <CookieConsentModalDisclosureTable.Entry name="__Host-cookie-consent" maxAge="Session">
                  {formatMessage('CookieConsentCookieConsentDescription')}
                </CookieConsentModalDisclosureTable.Entry>
              </CookieConsentModalDisclosureTable>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};
