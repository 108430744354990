import React, { ReactElement } from 'react';

import { Box, Link, Typography, makeStyles } from '@material-ui/core';

import { ChargeStation } from '../../hooks';
import { VkwIconNavigateRoute, VkwTheme, getVkwTypographyStyle, useVkwFormatMessage } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  distance: {
    ...getVkwTypographyStyle('text12', theme),
    paddingTop: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
}));

const getMapLink = (chargeStation: ChargeStation): string => {
  return `https://www.google.at/maps/dir//${chargeStation.location.latitude},${chargeStation.location.longitude}`;
};

interface ChargeStationRouteLinkProps {
  chargeStation: ChargeStation;
  currentPosition: google.maps.LatLngLiteral;
}

const ChargeStationRouteLink = ({ chargeStation, currentPosition }: ChargeStationRouteLinkProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();
  // Currently disabled
  // const distance = useChargeStationDistance(currentPosition, chargeStation);

  return (
    <Link
      color="textPrimary"
      className={styles.googleMapLink}
      href={getMapLink(chargeStation)}
      target="_blank"
      rel="noopener"
    >
      <Box textAlign="center" display="flex" flexDirection="column" alignItems="center">
        <VkwIconNavigateRoute size={24} />
        <Typography className={styles.distance}>{formatMessage('RoutePlanner')}</Typography>
      </Box>
    </Link>
  );
};

export { ChargeStationRouteLink };
