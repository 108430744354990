import React, { ReactElement, useState } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import Axios from 'axios';

import { useUserContext } from '../../contexts';
import { ChargeStation, useHandleAxiosResponse } from '../../hooks';
import { getVkwTypographyStyle, useVkwFormatMessage, VkwButton, VkwDialog, VkwLoader, VkwTheme } from '../../library';
import { ChargePointItemWithActions } from '../../views';

const useStyles = makeStyles<VkwTheme>(theme => ({
  infoText: {
    boxSizing: 'border-box',
    height: theme.spacing(6),
    position: 'relative',
    ...getVkwTypographyStyle('text14', theme),
  },
  loader: {
    '&.div': {
      color: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    paddingRight: theme.spacing(2),
    position: 'absolute',
    right: '0',
  },
  title: {
    ...getVkwTypographyStyle('h9', theme),
    fontWeight: 500,
  },
  warning: {
    ...getVkwTypographyStyle('text18', theme),
  },
}));

interface ChargeStationAdministrationProps {
  chargeStation: ChargeStation;
}

export const ChargeStationAdministration = ({ chargeStation }: ChargeStationAdministrationProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const handleAxiosResponse = useHandleAxiosResponse();
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const [restarting, setRestarting] = useState(false);
  const userContext = useUserContext();

  const onClickConfirmRestart = () => {
    setRestarting(true);
    setOpen(false);
    handleAxiosResponse(
      () =>
        Axios.post(
          '/api-secured/v1/ChargeStationsSecured/ExecuteResetCommand',
          {},
          {
            headers: {
              'Content-Type': 'application/json',
            },
            params: {
              customerId: userContext.selectedCustomer?.id,
              evseId: chargeStation.chargePoints[0].evseId,
            },
          }
        ),
      {
        error: () => {
          setRestarting(false);
        },
        notFound: () => {
          setRestarting(false);
        },
        success: () => {
          setRestarting(false);
        },
        tooManyRequests: () => {
          setRestarting(false);
        },
      },
      {
        errorSnackbarMessage: formatMessage('RestartFailed'),
        notFoundSnackbarMessage: formatMessage('RestartFailed'),
        successSnackbarMessage: formatMessage('RestartSuccessful'),
        tooManyRequestsSnackbarMessage: formatMessage('MaxNumberSupportFunctions'),
      }
    );
  };

  return (
    <>
      <Typography className={styles.title}>{formatMessage('SupportFunctions')}</Typography>
      <Box pt={2} />
      <Typography className={styles.infoText}>{formatMessage('InfoSupportFunctions')}</Typography>
      <Box pt={2} pb={3.5}>
        <Grid container spacing={1}>
          {chargeStation.chargePoints.map((chargePoint, i) => (
            <Grid item xs={12} key={i}>
              <ChargePointItemWithActions chargePoint={chargePoint} />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Typography className={styles.title}>{formatMessage('Restart')}</Typography>
      <Typography className={styles.infoText}>{formatMessage('RestartChargingStation')}</Typography>
      <Box pt={1} width="100%">
        <VkwButton
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
          disabled={restarting && true}
        >
          {formatMessage('Restart')}
        </VkwButton>
      </Box>
      {restarting && (
        <Box pt={1} width="100%">
          <VkwButton fullWidth variant="contained" color="secondary" disabled>
            {formatMessage('WaitingRestart')}
            <Box className={styles.loader}>
              <VkwLoader />
            </Box>
          </VkwButton>
        </Box>
      )}

      {open && (
        <VkwDialog
          open={open}
          onSave={onClickConfirmRestart}
          onCancel={() => setOpen(false)}
          onClose={() => setOpen(false)}
          headline={formatMessage('ConfirmRestart')}
          showCloseIcon
          saveButtonLabel={formatMessage('Restart')}
          renderContent={() => {
            return (
              <Box pt={1} pb={3.75}>
                <Typography className={styles.infoText}>{formatMessage('ConfirmRestartQuestion')}</Typography>
                <Box pt={1} />
                <Typography className={styles.warning}>{formatMessage('WarningRestartChargingStation')}</Typography>
              </Box>
            );
          }}
        />
      )}
    </>
  );
};
