import { ComponentType } from 'react';

import NiceModal from '@ebay/nice-modal-react';
import { useRouter } from 'next/router';

import { t } from '@e-vo/localization';

import { CookieConsentSettingsModalId } from '../../components/CookieConsentModal';
import { useHelpWizardContext } from '../../components/rn';
import { AccountInfo, Customer } from '../../contexts';
import { Assets } from '../../globals/configureAssets';
import {
  useVkwAssets,
  VkwIconCards,
  VkwIconCardsOutline,
  VkwIconCharging,
  VkwIconChargingOutline,
  VkwIconChargingstation,
  VkwIconChargingstationOutline,
  VkwIconContract,
  VkwIconContractOutline,
  VkwIconDocuments,
  VkwIconGear,
  VkwIconGearOutline,
  VkwIconHome,
  VkwIconHomeOutlined,
  VkwIconMoney,
  VkwIconMoneyOutline,
  VkwIconPin,
  VkwIconPinOutline,
  VkwIconPrivacy,
  VkwIconSupport,
  VkwIconSupportOutline,
} from '../../library';
import { IconCookie } from '../../library/icons/IconCookie';

export interface RouteConfig {
  type: 'next' | 'link' | 'function';
  key: string;
  area?: 'main' | 'secondary' | 'footer';
  path: string;
  translationKey: string;
  public: boolean;
  showForUser?: (accountInfo: AccountInfo, selectedCustomer: Customer | null) => boolean;
  onClick?: () => void;
  icon?: ComponentType;
  activeIcon?: ComponentType;
}

export const useRoutes = (): RouteConfig[] => {
  const assets = useVkwAssets<Assets>();
  const router = useRouter();
  const { setWizardOpen, wizardStepRefs } = useHelpWizardContext();

  const routerConfig: RouteConfig[] = [
    {
      activeIcon: VkwIconPin,
      area: 'main',
      icon: VkwIconPinOutline,
      key: 'charge-atlas',
      path: '/',
      public: true,
      translationKey: 'ChargeAtlas',
      type: 'next',
    },
    {
      activeIcon: VkwIconChargingstation,
      area: 'main',
      icon: VkwIconChargingstationOutline,
      key: 'infrastructure',
      path: '/infrastructure',
      public: false,
      showForUser: accountInfo => accountInfo.hasInfrastructureContract,
      translationKey: 'Infrastructure',
      type: 'next',
    },
    {
      activeIcon: VkwIconCharging,
      area: 'main',
      icon: VkwIconChargingOutline,
      key: 'charge-detail-records',
      path: '/charge-detail-records',
      public: false,
      showForUser: accountInfo =>
        accountInfo.hasChargingTariffContract ||
        accountInfo.hasSharedChargingTariffChargeDetailRecords ||
        accountInfo.hasInfrastructureContract ||
        accountInfo.hasSharedInfrastructureChargeDetailRecords,
      translationKey: 'ChargeDetailRecords',
      type: 'next',
    },
    {
      activeIcon: VkwIconCards,
      area: 'main',
      icon: VkwIconCardsOutline,
      key: 'tariffs',
      path: '/tariffs',
      public: false,
      showForUser: accountInfo => accountInfo.hasChargingTariffContract,
      translationKey: 'ChargingContracts',
      type: 'next',
    },
    {
      activeIcon: VkwIconContract,
      area: 'main',
      icon: VkwIconContractOutline,
      key: 'contracts',
      path: '/contracts',
      public: false,
      showForUser: accountInfo => accountInfo.hasInfrastructureContract,
      translationKey: 'Contracts',
      type: 'next',
    },
    {
      activeIcon: VkwIconMoney,
      area: 'main',
      icon: VkwIconMoneyOutline,
      key: 'invoices',
      path: '/invoices',
      public: false,
      showForUser: accountInfo => accountInfo.hasInfrastructureContract || accountInfo.hasChargingTariffContract,
      translationKey: 'Invoices',
      type: 'next',
    },
    {
      activeIcon: VkwIconSupport,
      area: 'main',
      icon: VkwIconSupportOutline,
      key: 'support',
      path: '/support',
      public: false,
      showForUser: accountInfo => !accountInfo.isCallCenterAgent,
      translationKey: 'Support',
      type: 'next',
    },
    {
      activeIcon: VkwIconGear,
      area: 'main',
      icon: VkwIconGearOutline,
      key: 'admin',
      path: '/admin',
      public: false,
      showForUser: accountInfo => accountInfo.isAdmin,
      translationKey: 'Admin',
      type: 'next',
    },
    {
      activeIcon: VkwIconSupport,
      area: 'main',
      icon: VkwIconSupportOutline,
      key: 'support-cca',
      path: '/support-cca',
      public: false,
      showForUser: accountInfo => accountInfo.isCallCenterAgent,
      translationKey: 'Support',
      type: 'next',
    },
    {
      activeIcon: IconCookie,
      area: 'secondary',
      icon: IconCookie,
      key: 'cookie-settings',
      onClick: () => NiceModal.show(CookieConsentSettingsModalId),
      path: '/cookie-settings',
      public: true,
      translationKey: 'Cookies',
      type: 'function',
    },
    // {
    //   activeIcon: VkwIconInfo,
    //   area: 'secondary',
    //   icon: VkwIconInfo,
    //   key: 'wizard',
    //   onClick: () => setWizardOpen(true),
    //   path: '#',
    //   public: true,
    //   showForUser: () => wizardStepRefs(router.route).length > 0,
    //   translationKey: t('wizard.title'),
    //   type: 'function',
    // },
    {
      activeIcon: VkwIconGear,
      area: 'secondary',
      icon: VkwIconGearOutline,
      key: 'settings',
      path: '/settings',
      public: false,
      showForUser: () => true,
      translationKey: 'Settings',
      type: 'next',
    },
  ];

  if (assets.links.impressum) {
    routerConfig.push({
      activeIcon: VkwIconHome,
      area: 'footer',
      icon: VkwIconHomeOutlined,
      key: 'impressum',
      path: assets.links.impressum,
      public: true,
      translationKey: 'Impressum',
      type: 'link',
    });
  }

  if (assets.links.privacyPolicy) {
    routerConfig.push({
      activeIcon: VkwIconPrivacy,
      area: 'footer',
      icon: VkwIconPrivacy,
      key: 'privacy-policy',
      path: assets.links.privacyPolicy,
      public: true,
      translationKey: 'PrivacyPolicy',
      type: 'link',
    });
  }

  if (assets.links.termsOfUse) {
    routerConfig.push({
      activeIcon: VkwIconDocuments,
      area: 'footer',
      icon: VkwIconDocuments,
      key: 'terms-of-use',
      path: assets.links.termsOfUse,
      public: true,
      translationKey: 'TermsOfUse',
      type: 'link',
    });
  }

  return routerConfig;
};
