import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconHome = ({ size }: VkwIconProps): ReactElement => (
  <VkwIcon size={size}>
    <g>
      <path d="M23.633,10.226l-11-9a1,1,0,0,0-1.266,0l-11,9a1,1,0,0,0,1.266,1.548L3,10.656V22a1,1,0,0,0,1,1h6V18h4v5h6a1,1,0,0,0,1-1V10.656l1.367,1.118a1,1,0,1,0,1.266-1.548ZM14,14H10V10h4Z" />
    </g>
  </VkwIcon>
);
