import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Box, Divider, Link, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { getVkwTypographyStyle } from '../../helper';
import { VkwIconArrowRight, VkwIconDragIndicator } from '../../icons';
import { VkwTheme } from '../../themes';
import { VkwCheckbox } from '../VkwCheckbox';
import { VkwIconButton } from '../VkwIconButton';

const useStyles = makeStyles<VkwTheme, { withFrame: boolean }>(
  theme => ({
    arrowButton: {
      '& span': {
        color: theme.palette.grey[500],
      },
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: theme.spacing(1),
      width: '100%',
    },
    checkboxLabel: {
      cursor: 'grab',
    },
    content: {
      ...getVkwTypographyStyle('h10', theme),
      minWidth: '0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
    },
    divider: {
      marginLeft: theme.spacing(1),
    },
    fixedListItemRoot: props => ({
      border: props.withFrame ? `1px solid ${theme.palette.grey[200]}` : 'none',
      paddingLeft: props.withFrame ? theme.spacing(3.6) : theme.spacing(2.5),
    }),
    icon: {
      color: theme.palette.grey[500],
      padding: theme.spacing(1.125),
    },
    listItemRoot: {
      '&.dragging-helper-class': {
        background: theme.palette.contentBackground,
        backgroundColor: `${theme.palette.grey[100]}`,
        borderRadius: 2,
        boxShadow: '0 1px 4px 0 rgba(0,0,0,0.05), 0 2px 6px 0 rgba(0,0,0,0.3)',
        cursor: 'grabbing',
        pointerEvents: 'auto !important',
        zIndex: theme.zIndex.modal + 1,
      },
      '&.dragging-helper-class $checkboxLabel': {
        cursor: 'grabbing',
      },
      '&:hover': {
        backgroundColor: `${theme.palette.grey[100]}`,
      },
      alignItems: 'center',
      cursor: 'grab',
      display: 'flex',

      flexDirection: 'row',

      paddingLeft: theme.spacing(1),

      userSelect: 'none',
    },
    listItemRootWithFrame: {
      '&:first-child': {
        borderTop: `1px solid ${theme.palette.grey[200]}`,
      },
      border: `1px solid ${theme.palette.grey[200]}`,
      borderTop: '0px',
      color: theme.palette.grey[500],
    },
  }),
  { index: 1 }
);

const reorder = (list: VkwSortableListItemConfig[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const SortableItem = SortableElement(({ className, value }: { value: ReactNode; className: string }) => (
  <div className={className}>{value}</div>
));

const SortableList = SortableContainer(({ children }: { children: ReactNode }) => <div>{children}</div>);

export interface VkwSortableListItemConfig {
  property: string;
  label: string;
  active?: boolean;
  fixed?: boolean;
  href?: string;
  icon?: ReactElement;
}

export interface VkwSortableListProps {
  items: VkwSortableListItemConfig[];
  /**
   * Soll ein Rahmen dargestellt werden?
   */
  withFrame?: boolean;
  onChange?: (items: VkwSortableListItemConfig[]) => void;
}

export const VkwSortableList = ({ ...props }: VkwSortableListProps): ReactElement | null => {
  const [items, setItems] = useState<VkwSortableListItemConfig[]>([]);
  const styles = useStyles({ withFrame: props.withFrame || false });

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  useEffect(() => {
    props.onChange?.(items);
  }, [items, props.onChange]);

  const onSortEnd = ({ newIndex, oldIndex }: { newIndex: number; oldIndex: number }) => {
    document.body.style.cursor = 'default';
    const data = reorder(items, oldIndex, newIndex);
    setItems(data);
  };

  const onCheck = (index: number, active: boolean) => {
    const data = items.slice();
    data[index].active = active;
    setItems(data);
  };

  if (items.length) {
    return (
      <SortableList helperClass="dragging-helper-class" onSortEnd={onSortEnd}>
        {items
          .filter(item => item.fixed)
          .map((item, index) => (
            <div className={styles.fixedListItemRoot} key={`fixed_${index}`}>
              <VkwCheckbox label={item.label} fullWidth disabled checked={item.active || false} />
            </div>
          ))}
        {items.map((item, index) => {
          if (item.fixed) {
            return null;
          }

          return (
            <SortableItem
              key={item.property}
              index={index}
              className={clsx(styles.listItemRoot, props.withFrame && styles.listItemRootWithFrame)}
              value={
                <>
                  <VkwIconDragIndicator size={12} />
                  {props.withFrame && <Divider orientation="vertical" flexItem className={styles.divider} />}
                  {item.icon ? (
                    <>
                      <Box className={styles.icon}>{item.icon}</Box>
                      <Typography component="span" className={styles.content}>
                        {item.label}
                      </Typography>
                    </>
                  ) : (
                    <VkwCheckbox
                      label={item.label}
                      fullWidth
                      checked={item.active || false}
                      key={`moveable_${index}`}
                      onChange={event => onCheck(index, event.target.checked)}
                      formControlLabelProps={{ classes: { root: styles.checkboxLabel } }}
                    />
                  )}
                  {item.href && (
                    <Link key={`link-item-${index}`} href={item.href} underline="none">
                      <Box className={styles.arrowButton}>
                        <VkwIconButton>
                          <VkwIconArrowRight size={12} />
                        </VkwIconButton>
                      </Box>
                    </Link>
                  )}
                </>
              }
            />
          );
        })}
      </SortableList>
    );
  }

  return null;
};
