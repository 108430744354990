import React, { ReactElement, useState } from 'react';

import { Box, Slider, Typography, makeStyles } from '@material-ui/core';

import { getVkwTypographyStyle } from '../../../helper';
import { useVkwFormatMessage } from '../../../hooks';
import { useVkwTheme, VkwTheme } from '../../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    timeDisplayBox: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px',
      display: 'flex',
      height: '65px',
      justifyContent: 'center',
      width: '65px',
    },
    timeDisplayOuterBox: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: theme.spacing(2),
    },
    timeDisplayText: {
      ...getVkwTypographyStyle('h1', theme),
      color: 'white',
    },
    timePickerOuterBox: {
      display: 'flex',
      flexDirection: 'column',
      height: '257px',
      width: '310px',
    },
    timeSeperatorBox: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    timeSeperatorText: {
      ...getVkwTypographyStyle('h1', theme),
      color: theme.palette.primary.main,
      lineHeight: 1.5,
    },
    timeSliderBox: {
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    timeSliderLabelBox: {
      paddingLeft: theme.spacing(1),
    },
  }),
  { index: 1 }
);

interface TimePickerProps {
  dateValue: Date;
  onHoursChange: (value: number) => void;
  onMinutesChange: (value: number) => void;
}

export const TimePicker = ({ dateValue, onHoursChange, onMinutesChange }: TimePickerProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const theme = useVkwTheme();
  const [minutes, setMinutes] = useState(dateValue.getMinutes());
  const [hours, setHours] = useState(dateValue.getHours());
  const styles = useStyles();

  return (
    <Box className={styles.timePickerOuterBox}>
      <Box className={styles.timeDisplayOuterBox}>
        <Box className={styles.timeDisplayBox}>
          <Typography className={styles.timeDisplayText}>
            {hours.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
          </Typography>
        </Box>
        <Box className={styles.timeSeperatorBox}>
          <Typography className={styles.timeSeperatorText}>:</Typography>
        </Box>
        <Box className={styles.timeDisplayBox}>
          <Typography className={styles.timeDisplayText}>
            {minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })}
          </Typography>
        </Box>
      </Box>
      <Box className={styles.timeSliderLabelBox}>
        <Typography>{formatMessage('Hours')}:</Typography>
      </Box>
      <Box className={styles.timeSliderBox}>
        <Slider
          value={hours}
          max={23}
          min={0}
          step={1}
          onChange={(_event, value) => {
            setHours(value as number);
            onHoursChange(value as number);
          }}
          color={theme.palette.mainSliderColor}
        />
      </Box>
      <Box className={styles.timeSliderLabelBox}>
        <Typography>{formatMessage('Minutes')}:</Typography>
      </Box>
      <Box className={styles.timeSliderBox}>
        <Slider
          value={minutes}
          max={55}
          min={0}
          step={5}
          onChange={(_event, value) => {
            setMinutes(value as number);
            onMinutesChange(value as number);
          }}
          color={theme.palette.mainSliderColor}
        />
      </Box>
    </Box>
  );
};
