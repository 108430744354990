import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';
import { FormattedDate } from 'react-intl';

import { CommissionDetail } from '../../containers/ChargeStationDetails/CommissionDetail';
import { Commission, InfrastructureContract, InfrastructureContractChargePoint } from '../../contexts';
import {
  VkwDataSectionItem,
  VkwDataSection,
  VkwTextField,
  dataSectionDatastring,
  useVkwFormatMessage,
} from '../../library';

const MAX_LENGTH = 30;

interface ContractDetailProps {
  contractData: InfrastructureContract;
  onSave: (contractData: InfrastructureContract) => Promise<boolean>;
}

const ChargePointCommissions = (chargePoint: InfrastructureContractChargePoint): ReactNode => {
  const formatMessage = useVkwFormatMessage();

  return (
    <div className={clsx('overflow-hidden rounded-2xl border', 'dark:border-gray-700')}>
      <div
        className={clsx(
          'bg-gray-50 p-4 font-medium',
          chargePoint.commissions?.length > 0 && 'border-b',
          'dark:border-gray-700 dark:bg-gray-800'
        )}
      >
        {chargePoint.evseId}
      </div>
      {chargePoint.commissions?.length > 0 && (
        <div className="p-4">
          <span className="mb-1 block text-xs opacity-60">{formatMessage('ChargingStationCommissionsTitle')}</span>
          <div>{renderCommissions(chargePoint.commissions)}</div>
        </div>
      )}
    </div>
  );
};

const renderCommissions = (commissions: Commission[]): ReactNode => {
  return (
    <>
      {commissions.map((commission, key) => (
        <CommissionDetail key={key} commission={commission} />
      ))}
    </>
  );
};

export const ContractDetail = ({ contractData, onSave }: ContractDetailProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const [editAlias, setEditAlias] = useState(false);
  const [newContractAlias, setNewContractAlias] = useState('');

  useEffect(() => {
    setNewContractAlias(contractData.infrastructureContractAlias ?? '');
  }, [contractData.infrastructureContractAlias]);

  const contractDataItems: VkwDataSectionItem[] = useMemo(() => {
    const contractDataItems: VkwDataSectionItem[] = [];

    if (editAlias) {
      contractDataItems.push({
        lg: 12,
        md: 12,
        renderContent: function vehicleAliasInput() {
          return (
            <VkwTextField
              name="newContractAlias"
              value={newContractAlias}
              label={formatMessage('ContractAlias')}
              onChange={event => {
                if (event.target.value.length > MAX_LENGTH) {
                  return;
                }

                setNewContractAlias(event.target.value);
              }}
              maxLength={MAX_LENGTH}
              fullWidth
            />
          );
        },
        sm: 12,
        xs: 12,
      });
    } else {
      contractDataItems.push({
        lg: 12,
        md: 12,
        renderContent: () => dataSectionDatastring(contractData.infrastructureContractAlias),
        sm: 12,
        title: formatMessage('ContractAlias'),
        xs: 12,
      });
    }

    contractDataItems.push({
      renderContent: () => dataSectionDatastring(contractData.contractId),
      title: formatMessage('ContractNumber'),
    });

    contractDataItems.push({
      renderContent: function creationDate() {
        if (contractData.contractValidTo) {
          return <FormattedDate value={contractData.contractValidTo} year="numeric" month="2-digit" day="2-digit" />;
        }

        return '-';
      },
      title: formatMessage('ValidTo'),
    });

    return contractDataItems;
  }, [contractData, editAlias, newContractAlias]);

  const handleSaveEditAlias = async (): Promise<void> => {
    const newContractData = {
      ...contractData,
      infrastructureContractAlias: newContractAlias.length > 0 ? newContractAlias : null,
    };

    const saved = await onSave(newContractData);
    if (saved) {
      setEditAlias(false);
    }
  };

  return (
    <>
      <VkwDataSection
        headline={formatMessage('ContractData')}
        items={contractDataItems}
        ctaLabel={formatMessage('FormEdit', { section: formatMessage('ContractData') })}
        editMode={editAlias}
        onCtaClick={() => setEditAlias(true)}
        onCancelClick={() => setEditAlias(false)}
        onSaveClick={handleSaveEditAlias}
      />
      <div className="pb-7 pt-8 text-base font-medium">{formatMessage('ChargePoints')}</div>
      <div className="grid grid-cols-2 items-start gap-3 xl:grid-cols-3">
        {contractData.chargePoints?.map(chargePoint => ChargePointCommissions(chargePoint))}
      </div>
    </>
  );
};
