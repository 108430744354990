import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconError = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;
  const strokewidth = 1;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeWidth={strokewidth}>
        <path d="M11.94 0.92c-6.07 0-11.02 4.95-11.02 11.02s4.95 11.02 11.02 11.02 11.02-4.95 11.02-11.02-4.95-11.02-11.02-11.02z m1.51 4.59l-0.59 8.27h-1.84l-0.55-8.27h2.98z m-1.51 13.78a1.84 1.84 0 1 1 0-3.68 1.84 1.84 0 0 1 0 3.68z" />
      </g>
    </VkwIcon>
  );
};
