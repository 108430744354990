import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconArrowLeft = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;
  const strokewidth = 1;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeWidth={strokewidth}>
        <path d="M17,23.414L6.293,12.707c-0.391-0.391-0.391-1.023,0-1.414L17,0.586L18.414,2l-10,10l10,10L17,23.414z" />
      </g>
    </VkwIcon>
  );
};
