import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconCheckbox = ({ size }: VkwIconProps): ReactElement => {
  const strokewidth = 2;
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <rect
        width="22.5"
        height="22.5"
        x=".75"
        y=".75"
        fill="none"
        fillRule="evenodd"
        stroke={fill}
        strokeWidth={strokewidth}
        rx="2"
      />
    </VkwIcon>
  );
};
