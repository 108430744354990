import React, { ReactElement } from 'react';

import { makeStyles } from '@material-ui/core';

import { useUserContext, useUserSettingsContext } from '../../contexts';
import { ChargeStation } from '../../hooks';
import { VkwIconButton, VkwIconFavorite, VkwIconFavoriteOutline, VkwTheme } from '../../library';

interface ChargeStationFavoriteActionProps {
  chargeStation: ChargeStation;
}

const useStyles = makeStyles<VkwTheme>(theme => ({
  favoriteicon: {
    color: `${theme.palette.type === 'light' ? 'inherit' : 'yellow'}`,
  },
}));

export const ChargeStationFavoriteAction = ({
  chargeStation,
}: ChargeStationFavoriteActionProps): ReactElement | null => {
  const styles = useStyles();
  const userContext = useUserContext();
  const userSettingsContext = useUserSettingsContext();

  if (!userContext.account) {
    return null;
  }

  const evseIds = chargeStation.chargePoints.map(chargePoint => chargePoint.evseId);

  let isFavorite = false;
  evseIds.forEach(evseId => {
    if (userSettingsContext.chargePointFavorites.includes(evseId)) {
      isFavorite = true;
    }
  });

  const handleAddFavorite = (): void => {
    userSettingsContext.addChargePointFavorite(chargeStation.chargePoints[0].evseId);
  };

  const handleRemoveFavorite = (): void => {
    userSettingsContext.removeChargePointFavorite(evseIds);
  };

  const IconComponent = isFavorite ? VkwIconFavorite : VkwIconFavoriteOutline;

  return (
    <VkwIconButton onClick={isFavorite ? handleRemoveFavorite : handleAddFavorite}>
      <div className={styles.favoriteicon}>
        <IconComponent size={16} />
      </div>
    </VkwIconButton>
  );
};
