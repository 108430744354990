import React, { ReactElement, ChangeEvent, useRef } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import { useConfigContext } from '../../contexts';
import {
  VkwButton,
  VkwIconButton,
  VkwIconTrash,
  VkwTheme,
  getVkwTypographyStyle,
  useVkwFormatMessage,
  VkwNotification,
} from '../../library';

const generateDashBorder = (theme: VkwTheme): string => {
  return `url("data:image/svg+xml,%3csvg width='100%25' \
            height='100%25' \
            xmlns='http://www.w3.org/2000/svg'%3e%3crect \
            width='100%25' \
            height='100%25' \
            fill='none' \
            rx='1' \
            ry='1' \
            stroke='%23${
              theme.palette.type === 'light'
                ? theme.palette.grey[300].replace('#', '')
                : theme.palette.grey[700].replace('#', '')
            }' \
            stroke-width='4' \
            stroke-dasharray='10 10' \
            stroke-dashoffset='0' \
            stroke-linecap='square'/%3e%3c/svg%3e")`;
};

const useStyles = makeStyles<VkwTheme>(theme => ({
  filesControl: {
    '&:hover': {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[700],
    },
    alignItems: 'center',
    backgroundColor: 'transparent',
    backgroundImage: generateDashBorder(theme),
    borderRadius: 2,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column-reverse',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    outline: 'none',
    padding: `${theme.spacing(2)}px ${theme.spacing(3.5)}px`,
    position: 'relative',

    width: '100%',
  },
  filesInput: {
    cursor: 'pointer',
    height: '100%',
    opacity: 0,
    position: 'absolute',
    width: '100%',
  },
  text14: {
    ...getVkwTypographyStyle('text14', theme),
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
}));

export interface FileUploadError {
  title: string;
  message: string;
}

interface FileUploadControlProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  files: FileList | null;
  error: FileUploadError | null;
}

export const FileUploadControl = ({ error, files, onChange, onDelete }: FileUploadControlProps): ReactElement => {
  const styles = useStyles();
  const configContext = useConfigContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const formatMessage = useVkwFormatMessage();
  return (
    <>
      <label className={styles.filesControl} htmlFor="files">
        <Box display="flex" alignItems="center" width="100%" height="100%" justifyContent="center">
          <Typography className={styles.text14}>{formatMessage('DragAndDropFile')}</Typography>
          <input
            onChange={onChange}
            type="file"
            name="files"
            accept={configContext.config.ticketAcceptedMimeTypes.toString()}
            multiple
            className={styles.filesInput}
            ref={fileInputRef}
          />
          <Box marginLeft={0.25} marginRight={0.25}>
            <VkwButton variant="contained" onClick={() => fileInputRef.current?.click()}>
              {formatMessage('AddFile')}
            </VkwButton>
          </Box>
        </Box>
        {files && files.length > 0 && (
          <Box display="flex" alignItems="center" paddingBottom={1}>
            <Box display="flex" flexDirection="column" paddingRight={1}>
              {(() => {
                const elementArray: ReactElement[] = [];

                for (let i = 0; files[i]; i++) {
                  elementArray.push(<Typography className={styles.text14}>{files[i].name}</Typography>);
                }

                return elementArray;
              })()}
            </Box>
            <VkwIconButton onClick={onDelete}>
              <VkwIconTrash />
            </VkwIconButton>
          </Box>
        )}
      </label>
      {error && <VkwNotification type="error" title={error.title} message={error.message} />}
    </>
  );
};
