import React, { ReactElement, useEffect, useState } from 'react';

import Axios from 'axios';

import { ChargePoint } from '@e-vo/types';

import { useUserContext } from '../../contexts';
import { useHandleAxiosResponse } from '../../hooks';
import { useVkwFormatMessage, VkwDialog } from '../../library';

export const ChargePointItemDetailsOverlay = ({
  detailsOpen,
  evseId,
  setDetailsOpen,
}: {
  evseId: string;
  detailsOpen: boolean;
  setDetailsOpen: React.Dispatch<React.SetStateAction<any>>;
}): ReactElement => {
  const handleAxiosResponse = useHandleAxiosResponse();
  const userContext = useUserContext();
  const formatMessage = useVkwFormatMessage();
  const [chargePointDetails, setChargePointDetails] = useState<ChargePoint | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleAxiosResponse(
      () =>
        Axios.get(`/api/next/v1/charge-points/${evseId}`, {
          headers: {
            'Content-Type': 'application/json',
            customerId: userContext.selectedCustomer?.id,
          },
        }),
      {
        error: error => {
          // eslint-disable-next-line no-console
          console.warn(error);
        },
        success: result => {
          setChargePointDetails(result.data);
          setOpen(true);
        },
      }
    );
  }, []);

  return (
    <VkwDialog
      open={open}
      onClose={() => setDetailsOpen(false)}
      showCloseIcon
      renderContent={() => (
        <div className="mb-1 grid min-w-[500px] max-w-lg grid-cols-2 gap-1 p-4">
          <span>{formatMessage('HardwareManufacturer')}:</span>
          <span className="font-bold">{chargePointDetails?.hardwareManufacturer}</span>
          <span>{formatMessage('HardwareModel')}:</span>
          <span className="font-bold">{chargePointDetails?.hardwareModel}</span>
          <span>{formatMessage('InOperationSince')}:</span>
          <span className="font-bold">
            {chargePointDetails && new Date(chargePointDetails.hardwareInOperationSince).toLocaleDateString('de-AT')}
          </span>
          <span>{formatMessage('OperatorProduct')}:</span>
          <span className="font-bold">{chargePointDetails?.operatorProduct}</span>
          {userContext.account?.info.isCallCenterAgent && (
            <>
              <span>{formatMessage('AuthorizationGroup')}:</span>
              <span className="font-bold">{chargePointDetails?.authorizationGroups}</span>
              <span>{formatMessage('ServiceLevelAgreement')}:</span>
              <span className="font-bold">{chargePointDetails?.serviceLevelAgreement}</span>
            </>
          )}
        </div>
      )}
    />
  );
};
