import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconContract = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M4 9c1.649 0 2.64.596 4.112 2.087l.38.392c.782.809 1.22 1.174 1.714 1.37l.115.042.947.321-.642 1.894-.947-.321c-.587-.199-1.146-.536-1.679-1.012V23H0V13c0-2.21 1.79-4 4-4zm16 0a4 4 0 0 1 3.995 3.8L24 13v10h-8v-9.216l-.163.14c-.443.365-.85.61-1.331.795l-.185.066-.947.321-.642-1.894.947-.321c.466-.158.858-.443 1.49-1.069l.506-.516C17.268 9.655 18.273 9 20 9zM4.5 1a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7zm15 0a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </VkwIcon>
  );
};
