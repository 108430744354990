import React, { ReactElement, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import {
  Badge,
  Box,
  CircularProgress,
  Fab,
  Grid,
  GridJustification,
  GridSize,
  Hidden,
  makeStyles,
  useScrollTrigger,
} from '@material-ui/core';

import { useOptionalVkwNavigation } from '../../contexts';
import { useVkwFormatMessage, useVkwIsSmallestBreakpoint } from '../../hooks';
import { VkwIconFilter, VkwIconSortUp } from '../../icons';
import { VkwTheme } from '../../themes';
import { VkwBaseTableFooter, VkwBaseTableToolbar } from '../VkwBaseTable';
import { VkwFilterOption, VkwFilters } from '../VkwFilters';
import { VkwIconButton } from '../VkwIconButton';
import { VkwLoader } from '../VkwLoader';

const useStyles = makeStyles<VkwTheme, { justifyContent?: GridJustification }>(
  theme => ({
    fabButton: {
      '&& g': {
        fill: '#111111',
      },
      bottom: theme.spacing(10),
      color: theme.palette.grey[100],
      position: 'fixed',
      right: theme.spacing(3),
      zIndex: theme.zIndex.speedDial,
    },
    footer: {
      border: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
    },
    grid: {
      justifyContent: props => props.justifyContent ?? 'flex-start',

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
    spinner: {
      display: 'flex',
      height: 40,
      justifyContent: 'center',
      margin: theme.spacing(3),
    },
  }),
  { index: 1 }
);

export interface VkwDataGridProps<T> {
  /**
   * Ist die erste Initialisierung der Tabellendaten abgeschlossen?
   */
  initialized: boolean;
  /**
   * Dies muss gesetzt werden immer wenn Daten z.B. vom Server geladen werden (Seite wird gewechselt, Infinity Scroll nachladen, Filter wurde gesetzt etc.)
   */
  loading: boolean;
  /**
   * Wird gesetzt sobald die erste Seite fertig geladen wurde und wird erst zurückgesetzt wenn sich z.B. ein Filter ändert usw.
   */
  loadAdditionalEntries: boolean;
  page: number;
  pages: number;
  data: T[];
  totalRecords: number;
  /**
   * Sind noch mehr Einträge vorhanden? Für Infinity Scrolling wichtig damit die Logik weiß ob nochmal nachgeladen werden muss
   */
  hasMore: boolean;
  /**
   * Wird eine neue Seite vom Grid angefordert, wird diese Methode aufgerufen
   */
  setPage: (page: number) => void;
  /**
   * Wenn sich die Anforderung, ob Desktop oder Mobileansicht, ändert wird diese Methode aufgerufen
   */
  setIsMobile: (isMobile: boolean) => void;
  /**
   * Text, wenn keine Einträge vorhanden sind, aber kein Filter aktiv ist oder `noResultsWithActiveFilterDisplayText` nicht gesetzt ist
   */
  noResultsDisplayText: string;
  /**
   * Render Prop um das Render Element dynamisch zu übergeben
   */
  renderElement: (dataEntry: T) => ReactElement;
  /**
   * Aktive Filter
   */
  filters?: Map<string, string[] | number[] | Date[]>;
  /**
   * Welche Filtermöglichkeiten gibt es
   */
  filterOptions?: VkwFilterOption[];
  /**
   * Wenn Änderungen an Filtern vorgenommen werden, wird diese Methode aufgerufen
   */
  onChangeFilter?: (name: string, value: string[] | number[] | Date[]) => void;
  /**
   * Methode, um alle Filter zurück zu setzen
   */
  onClearFilters?: () => void;
  /**
   * Text wenn mit den aktuellen Filtern keine Einträge vorhanden sind.
   */
  noResultsWithActiveFilterDisplayText?: string;
  /**
   * Wieviele verschiedene Filter werden von Anfang an angezeigt
   */
  defaultFiltersCount?: number;
  /**
   * Definiert wie breit (Grid Slots) das Element im jeweiligen Breakpoint sein soll 'auto' | 1 | ... | 12
   */
  gridItemProps?: {
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    lg?: GridSize;
  };
  /**
   * Hiermit kann der Content im Grid (Desktop Ansicht) Positioniert werden, ansonsten ist das Stanardverhalten "flex-start"
   */
  justifyContent?: GridJustification;
  /**
   * Anstatt eines einfachen Textes kann optional über dieses Render Prop ein eigenes Element dargestellt werden wenn keine Addressen vorhanden sind.
   */
  noResultElement?: (
    filterActive: boolean,
    noResultDisplayText: string,
    noResultsWithActiveFilterDisplayText: string
  ) => ReactElement;
}

// eslint-disable-next-line react/function-component-definition
export function VkwDataGrid<T>({
  data,
  defaultFiltersCount = 5,
  filterOptions,
  filters,
  gridItemProps,
  hasMore,
  initialized,
  justifyContent,
  loadAdditionalEntries,
  loading,
  noResultElement,
  noResultsDisplayText,
  noResultsWithActiveFilterDisplayText,
  onChangeFilter,
  onClearFilters,
  page,
  pages,
  renderElement,
  setIsMobile,
  setPage,
  totalRecords,
}: VkwDataGridProps<T>): ReactElement {
  const formatMessage = useVkwFormatMessage();

  const styles = useStyles({ justifyContent });
  const navigation = useOptionalVkwNavigation();

  const scrollTarget = navigation?.mainElement ?? undefined;

  const backToTopVisible = useScrollTrigger({
    disableHysteresis: true,
    target: scrollTarget,
  });

  const activeFilterCount = (): number => {
    if (filterOptions && filterOptions.length > 0 && filters) {
      const actualFilterColumns = filterOptions
        .filter(filterOption => filterOption.hidden !== true)
        .map(filterOption => filterOption.column);

      return Array.from(filters.keys()).reduce<number>(
        (accumulator, filterKey) => (actualFilterColumns.includes(filterKey) ? accumulator + 1 : accumulator),
        0
      );
    }
    return 0;
  };

  const [showMobileFilter, setShowMobileFilter] = useState(false);

  const observer = useRef<IntersectionObserver>();

  const isMobile = useVkwIsSmallestBreakpoint();

  useEffect(() => {
    setIsMobile(isMobile);
  }, [isMobile]);

  const lastPageElementRef = useCallback(
    (node: any) => {
      if (loading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(page + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading, hasMore]
  );

  const getEmptyMessage = (): string => {
    if (activeFilterCount() > 0 && noResultsWithActiveFilterDisplayText !== undefined) {
      return noResultsWithActiveFilterDisplayText;
    }

    return noResultsDisplayText;
  };

  const renderElements = (): ReactNode => {
    const elements: ReactElement[] = [];

    if (loading && (!loadAdditionalEntries || !isMobile)) {
      elements.push(<VkwLoader key="loader" />);
    } else if (totalRecords === 0 && noResultElement) {
      return noResultElement(
        activeFilterCount() > 0,
        noResultsDisplayText,
        noResultsWithActiveFilterDisplayText ?? noResultsDisplayText
      );
    } else if (totalRecords === 0) {
      elements.push(<Box key="empty">{getEmptyMessage()}</Box>);
    } else {
      data.forEach(dataEntry => {
        elements.push(renderElement(dataEntry));
      });
    }

    return (
      <Box p={1} width="100%">
        <Grid container direction="row" spacing={4} className={styles.grid}>
          {elements.map((item, index) => {
            if (isMobile && data.length === index + 1) {
              return (
                <Grid ref={lastPageElementRef} item key={index} {...gridItemProps}>
                  {item}
                </Grid>
              );
            }

            return (
              <Grid item key={index} {...gridItemProps}>
                {item}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const renderFooter = (): ReactElement | null => {
    if (pages > 1) {
      return (
        <Hidden xsDown implementation="css">
          <Box className={styles.footer}>
            <VkwBaseTableFooter page={page} onPageChange={setPage} count={pages} />
          </Box>
        </Hidden>
      );
    }

    return null;
  };

  const renderFilters = (): ReactElement | null => {
    if (!filters || !filterOptions || filterOptions.length <= 0 || !onChangeFilter || !onClearFilters) {
      return null;
    }
    return (
      <Box marginBottom={2}>
        <VkwFilters
          onChangeFilter={onChangeFilter}
          filters={filters}
          filterOptions={filterOptions}
          onClearFilters={onClearFilters}
          defaultFiltersCount={defaultFiltersCount}
          showMobileFilter={showMobileFilter}
          onMobileFilterClose={() => setShowMobileFilter(false)}
        />
      </Box>
    );
  };

  const renderFilterButton = (): ReactNode => {
    if (!filters || !filterOptions || filterOptions.length <= 0 || !onChangeFilter || !onClearFilters) {
      return null;
    }

    return (
      <Hidden implementation="css" smUp>
        <VkwIconButton onClick={() => setShowMobileFilter(true)} title={formatMessage('Filter')}>
          <Badge badgeContent={activeFilterCount()} color="primary">
            <VkwIconFilter />
          </Badge>
        </VkwIconButton>
      </Hidden>
    );
  };

  const renderToolbarTitle = (): string => {
    if (loading && !loadAdditionalEntries) {
      return '';
    }

    return formatMessage('Entries', { value: totalRecords });
  };

  if (!initialized) {
    return <VkwLoader />;
  }
  return (
    <>
      {renderFilters()}
      <VkwBaseTableToolbar
        numSelected={0}
        count={0}
        renderToolbarTitle={renderToolbarTitle}
        renderToolbarActions={renderFilterButton}
      />
      {renderElements()}
      <Hidden smUp implementation="css">
        {hasMore && <Box className={styles.spinner}>{loading && loadAdditionalEntries && <CircularProgress />}</Box>}
        {backToTopVisible && (
          <Fab
            size="medium"
            className={styles.fabButton}
            aria-label="scroll-to-top"
            onClick={() => {
              if (scrollTarget) {
                scrollTarget.scrollTo({ behavior: 'smooth', top: 0 });

                return;
              }

              window.scrollTo({ behavior: 'smooth', top: 0 });
            }}
          >
            <VkwIconSortUp size={48} />
          </Fab>
        )}
      </Hidden>
      {renderFooter()}
    </>
  );
}
