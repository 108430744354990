import de from './de.json';
import en from './en.json';
import it from './it.json';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  de,
  en,
  it,
};
