export const evnGoogleMapLightStyles: google.maps.MapTypeStyle[] = [
  {
    elementType: 'labels.icon',
    featureType: 'landscape',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'landscape.man_made',
    stylers: [
      {
        hue: '#f2f1f0',
      },
    ],
  },
  {
    elementType: 'all',
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'poi',
    stylers: [
      {
        color: '#aaaaaa',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'poi.park',
    stylers: [
      {
        hue: '#c9ef99',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'road.highway',
    stylers: [
      {
        color: '#bebebe',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'road.highway',
    stylers: [
      {
        color: '#aaaaaa',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'road.arterial',
    stylers: [
      {
        color: '#bebebe',
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'road.arterial',
    stylers: [
      {
        color: '#aaaaaa',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'water',
    stylers: [
      {
        hue: '#aadaff',
      },
    ],
  },
];

export const evnGoogleMapDarkStyles: google.maps.MapTypeStyle[] = [
  {
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: -25,
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 15,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'administrative',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'administrative',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 15,
      },
      {
        weight: 1,
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'landscape',
    stylers: [
      {
        color: '#23272a',
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'poi',
    stylers: [
      {
        color: '#171a1c',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'road.arterial',
    stylers: [
      {
        color: '#171a1c',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'road.arterial',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'road.highway',
    stylers: [
      {
        color: '#171a1c',
      },
      {
        lightness: -40,
      },
    ],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'road.highway',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'road.local',
    stylers: [
      {
        color: '#171a1c',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'transit',
    stylers: [
      {
        color: '#000000',
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    elementType: 'labels.icon',
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        color: '#0a2a3f',
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    elementType: 'geometry',
    featureType: 'water',
    stylers: [
      {
        color: '#171a1c',
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'water',
    stylers: [
      {
        color: '#171a1c',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    featureType: 'water',
    stylers: [
      {
        color: '#757575',
      },
      {
        weight: 4,
      },
    ],
  },
];
