import { VkwTheme } from './createVkwTheme';
import { getVkwTypographyStyle } from '../helper/getVkwTypographyStyle';

export const applyVkwThemeSettings = (theme: VkwTheme): VkwTheme => {
  const tempTheme = { ...theme };

  tempTheme.typography.button = {
    ...tempTheme.typography.button,
    ...getVkwTypographyStyle('link16', tempTheme),

    [tempTheme.breakpoints.up('sm')]: {
      ...getVkwTypographyStyle('link14', tempTheme),
    },
  };

  if (tempTheme.overrides) {
    tempTheme.overrides.MuiFormControlLabel = {
      ...tempTheme.overrides.MuiFormControlLabel,
      label: {
        ...tempTheme.overrides.MuiFormControlLabel?.label,

        fontSize: tempTheme.typography.pxToRem(16),
        fontWeight: tempTheme.typography.fontWeightMedium,
        lineHeight: 1.5,

        [tempTheme.breakpoints.up('sm')]: {
          fontSize: tempTheme.typography.pxToRem(14),
          lineHeight: 1.43,
        },
      },
    };

    tempTheme.overrides.MuiInputBase = {
      ...tempTheme.overrides.MuiInputBase,
      input: {
        ...tempTheme.overrides.MuiInputBase?.input,
        color: tempTheme.palette.common.black,

        fontSize: tempTheme.typography.pxToRem(16),
        lineHeight: 1.5,

        [tempTheme.breakpoints.up('sm')]: {
          fontSize: tempTheme.typography.pxToRem(14),
          lineHeight: 1.43,
        },
      },
    };

    tempTheme.overrides.MuiTab = {
      ...tempTheme.overrides.MuiTab,
      root: {
        ...tempTheme.overrides.MuiTab?.root,
        fontSize: tempTheme.typography.pxToRem(16),
        fontWeight: 'bold',
        lineHeight: 1.5,

        [tempTheme.breakpoints.up('sm')]: {
          fontSize: tempTheme.typography.pxToRem(14),
          lineHeight: 1.43,
        },
      },
    };

    tempTheme.overrides.MuiPopover = {
      ...tempTheme.overrides.MuiPopover,
      paper: {
        ...tempTheme.overrides.MuiPopover?.paper,
        border: tempTheme.palette.type === 'light' ? 'none' : `1px solid ${tempTheme.palette.grey[700]}`,

        [tempTheme.breakpoints.up('sm')]: {
          width: '400px',
        },

        width: '100%',
      },
    };

    tempTheme.overrides.MuiSwitch = {
      ...tempTheme.overrides.MuiSwitch,
      track: {
        ...tempTheme.overrides.MuiSwitch?.track,
        transition: tempTheme.transitions.create(['background-color', 'border']),
      },
    };

    tempTheme.overrides.MuiSlider = {
      ...tempTheme.overrides.MuiSlider,
      markLabel: {
        ...tempTheme.overrides.MuiSlider?.markLabel,
        ...getVkwTypographyStyle('text12', tempTheme),
        color: tempTheme.palette.text.secondary,
        marginTop: 2,
      },
      markLabelActive: {
        ...tempTheme.overrides.MuiSlider?.markLabelActive,
        ...getVkwTypographyStyle('text12', tempTheme),
        color: tempTheme.palette.text.secondary,
        marginTop: 2,
      },
    };
  }

  return tempTheme;
};
