import { ChargeStation } from '@e-vo/types';
import React, { ReactNode, useContext, useRef, useState } from 'react';
import MapView from 'react-native-maps';

import MapControlContext from './contexts/MapControlContext';

export function useMap() {
  return useContext(MapControlContext);
}

interface MapProviderProps {
  children: ReactNode;
}

export const MapControlProvider: React.FC<MapProviderProps> = ({ children }) => {
  const [selectedChargeStation, setSelectedChargeStation] = useState<ChargeStation | null>(null);
  const [showSearchOverlay, setShowSearchOverlay] = useState<boolean>(false);

  const map = useRef<MapView | null>(null);

  //search term that is a bit delayed, so the search is only executed after the user stopped typing
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

  const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('');

  return (
    <MapControlContext.Provider
      value={{
        currentSearchTerm,
        debouncedSearchTerm,
        map,
        selectedChargeStation,
        setCurrentSearchTerm,
        setDebouncedSearchTerm,
        setSelectedChargeStation,
        setShowSearchOverlay,
        showSearchOverlay,
      }}
    >
      {children}
    </MapControlContext.Provider>
  );
};

export default MapControlProvider;
