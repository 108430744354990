import { z } from 'zod';
import { evseIdSchemaProp } from './general-validators';
import { ChargePointStatusEnum } from './chargePoint';
import { ChargeSession } from '@prisma/client';

const ChannelName = z.enum(['charge-point-status', 'charge-session', 'notification']);
export type ChannelName = z.infer<typeof ChannelName>;

const Channel = z.object({
  customerNumber: z.string().optional(),
  name: ChannelName,
  secured: z.boolean(),
  token: z.string().optional(),
});
export type Channel = z.infer<typeof Channel>;

const ChargePointStatusUpdateEventData = z.object({
  chargePoint: z.nullable(
    z.object({
      evseId: evseIdSchemaProp,
      status: z.nativeEnum(ChargePointStatusEnum),
    })
  ),
  reload: z.boolean(),
});
export type ChargePointStatusUpdateEventData = z.infer<typeof ChargePointStatusUpdateEventData>;

export type ChargeSessionUpdateEventData = ChargeSession;
