import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconCheckRound = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize >= 48) {
          return (
            <path
              fill={fill}
              fillRule="evenodd"
              transform="scale(0.5)"
              d="M24 0C10.748 0 0 10.748 0 24s10.748 24 24 24 24-10.748 24-24S37.252 0 24 0zm13.252 16.383L20.557 33.078c-.21.209-.418.313-.73.313-.314 0-.523-.104-.731-.313l-8.348-8.348c-.418-.417-.418-1.043 0-1.46.417-.418 1.043-.418 1.46 0l7.618 7.617 15.965-15.965c.418-.418 1.044-.418 1.461 0 .418.417.418 1.043 0 1.46z"
            />
          );
        }

        return (
          <g fill={secondaryfill}>
            <path
              d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0ZM10,17.414,4.586,12,6,10.586l4,4,8-8L19.414,8Z"
              fill={fill}
            />
          </g>
        );
      }}
    </VkwIcon>
  );
};
