import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Box, Hidden } from '@material-ui/core';
import Axios from 'axios';

import { TicketResponse } from './const';
import { TicketDetail } from './TicketDetail';
import { TicketOverviewCcaSection } from '../../components';
import { useHandleAxiosResponse } from '../../hooks';
import { VkwDialog, VkwTab, useVkwFormatMessage } from '../../library';

interface TicketOverviewCcaProps {
  customer?: string;
  authenticationCardUid?: string;
  evseId?: string;
}

export const TicketOverviewCca = ({
  authenticationCardUid,
  customer,
  evseId,
}: TicketOverviewCcaProps): ReactElement => {
  const [ticketInternalId, setTicketInternalId] = useState<string | null>(null);
  const [ticketData, setTicketData] = useState<TicketResponse | null>(null);
  const [ticketDetailOpen, setTicketDetailOpen] = useState(false);

  const formatMessage = useVkwFormatMessage();
  const handleAxiosResponse = useHandleAxiosResponse();

  useEffect(() => {
    let active = true;

    setTicketData(null);

    if (ticketInternalId) {
      handleAxiosResponse(() => Axios.get(`/api-secured/v1/TicketsSecured/${ticketInternalId}`), {
        success: response => {
          if (active) {
            setTicketData(response.data);
          }
        },
      });
    }

    return () => {
      active = false;
    };
  }, [ticketInternalId]);

  const renderInfoTabContent = (): ReactNode => {
    return (
      <>
        <Hidden implementation="css" smUp={!customer}>
          <TicketOverviewCcaSection
            type="CustomerId"
            searchString={customer}
            onRowClick={data => {
              setTicketInternalId(data.ticketInternalId);
              setTicketDetailOpen(true);
            }}
          />
        </Hidden>
        <Hidden implementation="css" smUp={!evseId}>
          <TicketOverviewCcaSection
            type="EvseId"
            searchString={evseId ? evseId.substring(0, evseId.indexOf('-') - 1) : undefined}
            onRowClick={data => {
              setTicketInternalId(data.ticketInternalId);
              setTicketDetailOpen(true);
            }}
          />
        </Hidden>
        <Hidden implementation="css" smUp={!authenticationCardUid}>
          <TicketOverviewCcaSection
            type="AuthenticationCardUid"
            searchString={authenticationCardUid}
            onRowClick={data => {
              setTicketInternalId(data.ticketInternalId);
              setTicketDetailOpen(true);
            }}
          />
        </Hidden>

        <VkwDialog
          open={ticketDetailOpen && !!ticketData}
          onClose={() => setTicketDetailOpen(false)}
          headline={ticketData?.subject ?? undefined}
          showCloseIcon
          renderContent={() => (
            <Box pt={2} pb={2}>
              <TicketDetail ticketData={ticketData} />
            </Box>
          )}
        />
      </>
    );
  };

  return (
    <>
      <Hidden implementation="css" xsDown>
        <VkwTab tabs={[{ label: formatMessage('Info'), renderContent: renderInfoTabContent }]} />
      </Hidden>
      <Hidden implementation="css" smUp>
        {renderInfoTabContent()}
      </Hidden>
    </>
  );
};
