import { ParsedUrlQuery } from 'querystring';

import React, { ReactElement, useEffect, useState } from 'react';

import { Button as MuiButton, ButtonGroup, darken, lighten, makeStyles } from '@material-ui/core';
import { useGoogleMap } from '@react-google-maps/api';
import clsx from 'clsx';
import { useRouter } from 'next/router';

import ChargeAtlasSearch from './ChargeAtlasSearch';
import { ChargeStationDetailsOverlay } from './ChargeStationDetailsOverlay';
import { ChargeStationListOverlay } from './ChargeStationListOverlay';
import { ChargeStationsNearby } from './ChargeStationsNearby';
import { DEFAULT_ZOOM } from './const';
import { FilterSidebar } from './FilterSidebar';
import { HelpWizardStep } from '../../components/rn';
import { HelpWizardSteps } from '../../components/rn/HelpWizard/helpWizardSteps';
import { ChargeAtlasStore } from '../../hooks';
import { VkwIconCenterLocation, VkwIconMinus, VkwIconPlus, VkwTheme } from '../../library';

interface MapState {
  lat: number;
  lng: number;
  zoom?: number;
  evseId?: string;
}

const getMapStateFromRouterQuery = (query: ParsedUrlQuery): MapState | null => {
  return {
    evseId: query.evseId as string,
    lat: parseFloat(query.lat as string),
    lng: parseFloat(query.lng as string),
    zoom: parseInt(query.zoom as string, 10),
  };
};

const useStyles = makeStyles<VkwTheme>(theme => ({
  desktop: {
    height: '16px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    top: '-3px',

    width: '16px',
  },
  mapControlButtons: {
    '&:active': {
      backgroundColor:
        theme.palette.type === 'light'
          ? darken(theme.palette.common.white, 0.2)
          : lighten(theme.palette.common.black, 0.2),
    },
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'light'
          ? darken(theme.palette.common.white, 0.1)
          : lighten(theme.palette.common.black, 0.1),
    },
    backgroundColor: theme.palette.type === 'light' ? theme.palette.common.white : theme.palette.common.black,
    color: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[500],

    minWidth: 0,

    padding: `${theme.spacing(0.75)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1.5)}px`,
    },
  },
  mapControlPositionButton: {
    boxShadow: theme.shadows[1],
  },
  mapControlZoomGroup: {
    boxShadow: theme.shadows[1],
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  mapControls: {
    bottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: '20px',

    [theme.breakpoints.up('md')]: {
      transform: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      bottom: '50%',
      transform: 'translateY(50%)',
    },
  },
  mobile: {
    height: '20px',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    top: '-3px',
    width: '20px',
  },
}));

interface ControlsProps {
  store: ChargeAtlasStore;
  showNearbyStations: boolean;
}

export const Controls = ({ showNearbyStations, store }: ControlsProps): ReactElement => {
  const styles = useStyles();
  const router = useRouter();
  const map = useGoogleMap() as google.maps.Map;
  const [filterSidebarOpen, setFilterSidebarOpen] = useState(false);
  const positionButtonStyle = clsx(styles.mapControlButtons, styles.mapControlPositionButton);

  useEffect(() => {
    const mapState = getMapStateFromRouterQuery(router.query);
    if (mapState && mapState.evseId) {
      store.loadAndSetSelectedChargeStation(mapState.evseId);
    } else if (mapState?.lat && mapState?.lng) {
      map.setCenter(new google.maps.LatLng(mapState.lat, mapState.lng));
    }
    map.setZoom(mapState?.zoom ? mapState.zoom : 12);
  }, []);

  useEffect(() => {
    if (!store.selectedChargeStation && !store.selectedChargeStationList) {
      return;
    }

    if (store.selectedChargeStation) {
      map.panTo(
        new google.maps.LatLng(
          store.selectedChargeStation.location.latitude,
          store.selectedChargeStation.location.longitude
        )
      );
    }
  }, [store.selectedChargeStation, store.selectedChargeStationList]);

  const handleLocationChanged = (location: google.maps.LatLng | null): void => {
    if (location) {
      map.setCenter(location);
    }

    store.setSearchPosition(location ? location.toJSON() : null);
  };

  return (
    <>
      <div className={styles.mapControls}>
        <HelpWizardStep step={HelpWizardSteps.mapControls}>
          <>
            <MuiButton
              variant="contained"
              onClick={() => map.setCenter(store.currentPosition)}
              className={positionButtonStyle}
              disableElevation
              disableRipple
            >
              <div className={styles.mobile}>
                <VkwIconCenterLocation size={20} />
              </div>
              <div className={styles.desktop}>
                <VkwIconCenterLocation size={16} />
              </div>
            </MuiButton>
            <ButtonGroup
              component="div"
              orientation="vertical"
              disableElevation
              disableRipple
              classes={{ root: styles.mapControlZoomGroup }}
            >
              <MuiButton
                variant="contained"
                onClick={() => map.setZoom((map.getZoom() ?? DEFAULT_ZOOM) + 1)}
                className={styles.mapControlButtons}
                disableElevation
                disableRipple
              >
                <VkwIconPlus size={16} />
              </MuiButton>
              <MuiButton
                variant="contained"
                onClick={() => map.setZoom((map.getZoom() ?? DEFAULT_ZOOM) - 1)}
                className={styles.mapControlButtons}
                disableElevation
                disableRipple
              >
                <VkwIconMinus size={16} />
              </MuiButton>
            </ButtonGroup>
          </>
        </HelpWizardStep>
      </div>
      {!store.selectedChargeStation && !store.selectedChargeStationList && (
        <ChargeAtlasSearch
          onLocationChanged={handleLocationChanged}
          onEvseIdChanged={store.loadAndSetSelectedChargeStation}
          onFilterClick={() => setFilterSidebarOpen(!filterSidebarOpen)}
          activeFilterCount={store.activeFilterCount}
        />
      )}
      {store.selectedChargeStation && (
        <ChargeStationDetailsOverlay
          chargeStation={store.selectedChargeStation}
          currentPosition={store.currentPosition}
          onClose={() => store.setSelectedChargeStation(null)}
        />
      )}
      {store.selectedChargeStationList && (
        <ChargeStationListOverlay
          chargeStationList={store.selectedChargeStationList}
          currentPosition={store.currentPosition}
          onClose={() => store.setSelectedChargeStationList(null)}
        />
      )}
      {showNearbyStations && <ChargeStationsNearby store={store} />}
      {filterSidebarOpen && <FilterSidebar store={store} onClose={() => setFilterSidebarOpen(false)} />}
    </>
  );
};
