import React, { ReactElement } from 'react';

import { Box, FormControlLabel, Radio, RadioGroup, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { useUserContext, AccountInfoThemeMode } from '../../contexts';
import { Assets } from '../../globals/configureAssets';
import { VkwTheme, getVkwTypographyStyle, useVkwAssets, useVkwTheme, useVkwFormatMessage } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  control: {
    margin: '0',
    maxWidth: '45%',
  },
  darkLabel: {
    backgroundColor: '#262626',

    border: `solid ${theme.palette.type === 'dark' ? '2px' : '1px'} ${
      theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.grey[300]
    }`,
  },
  label: {
    borderRadius: '6px',
    marginTop: theme.spacing(1),
    maxWidth: '255px',
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(3),
    width: '100%',
  },
  lightLabel: {
    backgroundColor: '#fff',

    border: `solid ${theme.palette.type === 'light' ? '2px' : '1px'} ${
      theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.grey[300]
    }`,
  },
  radio: {
    '&&': {
      color: theme.palette.type === 'dark' ? theme.palette.common.white : '',
    },
  },
  text14: {
    ...getVkwTypographyStyle('text14', theme),
  },
  themeSwitcher: {
    justifyContent: 'space-evenly',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    width: '100%',
  },
  themeSwitcherHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const Settings = (): ReactElement => {
  const theme = useVkwTheme();
  const styles = useStyles();
  const userContext = useUserContext();
  const assets = useVkwAssets<Assets>();
  const formatMessage = useVkwFormatMessage();
  const darkLabel = clsx(styles.label, styles.darkLabel);
  const lightLabel = clsx(styles.label, styles.lightLabel);

  return (
    <Box display="flex" flexDirection="column">
      <Box className={styles.themeSwitcherHeader}>
        <Typography variant="h6">{formatMessage('ThemeSwitcherHeader')}</Typography>
      </Box>
      <Box paddingTop={`${theme.spacing(2)}px`}>
        <Typography className={styles.text14}>{formatMessage('ThemeSwitcherMessage')}</Typography>
      </Box>
      <RadioGroup
        name="themeSwitcher"
        row
        value={theme.palette.type}
        onChange={event => {
          userContext.setTheme(event.target.value as AccountInfoThemeMode);
        }}
        className={styles.themeSwitcher}
      >
        <FormControlLabel
          value="dark"
          control={<Radio color="primary" className={styles.radio} />}
          labelPlacement="top"
          label={
            <>
              <Typography align="center" className={styles.text14} display="block" color="textPrimary">
                <b>{formatMessage('ThemeSwitcherDark')}</b>
                {assets.defaultThemeMode === 'dark' && (
                  <>
                    {' ('}
                    {formatMessage('default')})
                  </>
                )}
              </Typography>
              <Box className={darkLabel}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 175 124">
                  <g fill="#050505" fillRule="evenodd">
                    <path d="M0 0H23V124H0zM35 0H175V16H35zM35 28H175V124H35z" />
                  </g>
                </svg>
              </Box>
            </>
          }
          className={styles.control}
        />
        <FormControlLabel
          value="light"
          control={<Radio color="primary" />}
          labelPlacement="top"
          label={
            <>
              <Typography align="center" className={styles.text14} display="block" color="textPrimary">
                <b>{formatMessage('ThemeSwitcherLight')}</b>
                {assets.defaultThemeMode === 'light' && (
                  <>
                    {' ('}
                    {formatMessage('default')})
                  </>
                )}
              </Typography>
              <Box className={lightLabel}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 175 124">
                  <g fill="#D9D9D9">
                    <path d="M0 0H23V124H0zM35 0H175V16H35zM35 28H175V124H35z" />
                  </g>
                </svg>
              </Box>
            </>
          }
          className={styles.control}
        />
      </RadioGroup>
    </Box>
  );
};
