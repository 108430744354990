import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPresent = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={fill} fillRule="nonzero">
          <g transform="translate(1.000000, 1.000000)">
            <path
              d="M21.0833333,4.50030721 L18.8879167,4.50030721 C19.1088523,4.07157082 19.2323893,3.60087142 19.25,3.1207014 C19.1838328,1.3546896 17.6883035,-0.0334695575 15.8885833,0.000614424395 C13.7890625,0.0893928201 11.9099408,1.30601887 11,3.16569833 C10.0997251,1.31119558 8.23202232,0.0944334575 6.14166667,0.000614424395 C6.10683333,0.000614424395 6.072,0.000614424395 6.03716667,0.000614424395 C4.26501876,0.00340063036 2.81261962,1.38197742 2.75,3.1207014 L2.75,3.18009735 C2.76788366,3.63955579 2.88637713,4.08984001 3.09741667,4.50030721 L0.916666667,4.50030721 C0.410405646,4.50030721 0,4.90322343 0,5.40024577 L0,8.10006144 C0,8.59708378 0.410405646,9 0.916666667,9 L21.0833333,9 C21.5895944,9 22,8.59708378 22,8.10006144 L22,5.40024577 C22,4.90322343 21.5895944,4.50030721 21.0833333,4.50030721 Z M15.917,1.80049154 C16.6943273,1.78069442 17.3522911,2.35992339 17.4166667,3.1207014 C17.3896721,3.88606378 16.7529504,4.4944966 15.9729167,4.50030721 L12.3548333,4.50030721 C12.925,3.36728457 14.0589167,1.80049154 15.917,1.80049154 Z M4.58333333,3.15039938 C4.63207084,2.38562301 5.28236281,1.79229132 6.06283333,1.80049154 C7.95941667,1.80769105 9.07041667,3.35828518 9.647,4.50030721 L6.072,4.50030721 C5.29085157,4.50608064 4.63938532,3.91533766 4.58333333,3.15039938 Z"
              id="Shape"
            />
            <path d="M13,11 L13,22 L17.375,22 C18.8247475,22 20,20.7687831 20,19.25 L20,11 L13,11 Z" id="Path" />
            <path d="M9,11 L2,11 L2,19.25 C2,20.7687831 3.17525253,22 4.625,22 L9,22 L9,11 Z" id="Path" />
          </g>
        </g>
      </g>
    </VkwIcon>
  );
};
