import { Commission, InfrastructureContractChargePoint } from '../../contexts';

const mergeCommssions = (commissions: { [key: string]: Commission[] }): { [key: string]: Commission[] } => {
  const keys = Object.keys(commissions);
  if (keys.length === 0 || keys.length === 1) {
    return commissions;
  }
  const map = new Map();

  Object.keys(commissions).forEach(key => {
    if (map.has(JSON.stringify(commissions[key]))) {
      const value = `${map.get(JSON.stringify(commissions[key]))}; ${key}`;
      map.set(JSON.stringify(commissions[key]), value);
    } else {
      map.set(JSON.stringify(commissions[key]), key);
    }
  });

  const result: { [key: string]: Commission[] } = {};
  map.forEach((value, key) => {
    result[value] = JSON.parse(key);
  });

  return result;
};

export const getCommissionsFromInfrastructureContractChargePoints = (
  chargePoints: InfrastructureContractChargePoint[]
): {
  [key: string]: Commission[];
} => {
  const result: { [key: string]: Commission[] } = {};
  chargePoints?.forEach(chargePoint => {
    const refunds = chargePoint.commissions?.filter(commission => commission.commissionType === 'REFUND');
    if (refunds?.length > 0) {
      result[chargePoint.evseId] = refunds;
    }
  });

  const orderedResult: { [key: string]: Commission[] } = {};

  Object.keys(result)
    .sort()
    .forEach(key => {
      orderedResult[key] = result[key];
    });

  return mergeCommssions(orderedResult);
};
