import React, { ReactElement, ReactNode } from 'react';

import { Typography, makeStyles } from '@material-ui/core';

import { VkwTheme, getVkwTypographyStyle } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  sectionHeader: {
    ...getVkwTypographyStyle('h8', theme),
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1),
  },
}));

interface FormSectionHeaderProps {
  children: ReactNode;
}
export const FormSectionHeader = ({ children }: FormSectionHeaderProps): ReactElement => {
  const styles = useStyles();

  return <Typography className={styles.sectionHeader}>{children}</Typography>;
};
