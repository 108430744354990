import React, { ReactElement } from 'react';

import { DocumentTextIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { FormattedDate } from 'react-intl';

import { Assets } from '../../globals/configureAssets';
import { useVkwAssets, useVkwFormatMessage, VkwIconChargingstationOutline } from '../../library';

interface ContractTileProps {
  contractAlias: string | null;
  contractId: string | null;
  chargePointsCount: number | null;
  validTo: Date | null;
  onClick?: () => void;
}

export const ContractTile = ({
  chargePointsCount,
  contractAlias,
  contractId,
  onClick,
  validTo,
}: ContractTileProps): ReactElement => {
  const assets = useVkwAssets<Assets>();
  const formatMessage = useVkwFormatMessage();

  return (
    <>
      <div
        className={clsx('group relative h-56 w-56 cursor-pointer rounded-2xl')}
        onClick={() => onClick?.()}
        role="presentation"
      >
        <div
          className={clsx(
            'absolute inset-0 h-full w-full overflow-hidden rounded-2xl border bg-gray-50 transition ease-in',
            'group-hover:scale-105 group-hover:bg-gray-100 group-hover:drop-shadow-xl',
            'dark:border-gray-700 dark:bg-gray-800 dark:group-hover:border-gray-600 dark:group-hover:bg-gray-700'
          )}
        >
          <div
            className={clsx(
              'absolute -top-7 right-5 h-20 w-full -skew-y-6 scale-[1.3] bg-gradient-to-r from-gray-600 to-gray-400 transition-all ease-in',
              'group-hover:-right-6'
            )}
          />
        </div>
        <img
          src={assets.logoMonoWhite}
          className="absolute left-4 top-4 max-h-[22px] max-w-[96px]"
          alt="ContractCardLogo"
        />
        <div
          className={clsx(
            'absolute right-4 top-6 flex h-16 w-16 items-center justify-center rounded-full border bg-white text-gray-800 transition ease-in',
            'group-hover:scale-105',
            'dark:text-gray-800'
          )}
        >
          <DocumentTextIcon
            className={clsx(
              'absolute left-4 h-8 w-8 transition-all ease-in',
              'group-hover:left-7 group-hover:opacity-0'
            )}
          />
          <ArrowLeftOnRectangleIcon
            className={clsx(
              'absolute left-0 h-8 w-8 rotate-180 opacity-0 transition-all ease-in',
              'group-hover:left-4 group-hover:opacity-100'
            )}
          />
        </div>
        <div className="absolute bottom-0 w-full">
          <div className="px-4 py-3">
            <span className={clsx('text-xs text-gray-500', ' dark:text-gray-400')}>
              {contractAlias ? formatMessage('ContractAlias') : formatMessage('ContractNumber')}
            </span>
            <span className="block text-base font-bold">{contractAlias ?? contractId}</span>
          </div>
          <div className={clsx('relative flex items-center px-4 py-3 font-medium', '')}>
            <div
              className={clsx(
                'absolute left-0 top-0 h-[1px] w-full bg-gray-200 transition ease-in',
                'group-hover:scale-105',
                'dark:bg-gray-700 dark:group-hover:bg-gray-600'
              )}
            />
            <VkwIconChargingstationOutline size={16} />
            <span className="ml-2">{formatMessage('ChargePointsCount', { count: chargePointsCount })}</span>
          </div>
        </div>
      </div>
      {validTo && (
        <div className={clsx('px-4 pt-2 text-right text-xs text-gray-500', 'dark:bg-gray-300 dark:text-gray-800')}>
          {formatMessage('ValidTo')} <FormattedDate value={validTo} year="numeric" month="2-digit" day="2-digit" />
        </div>
      )}
    </>
  );
};
