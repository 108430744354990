import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPercent = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 16 16">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={fill} fillRule="nonzero">
            <g transform="translate(2.000000, 2.000000)">
              <polygon
                id="Rectangle"
                transform="translate(6.159746, 6.500000) rotate(-56.310000) translate(-6.159746, -6.500000) "
                points="-1.06563471 5.62003644 13.3851268 5.62003644 13.3851268 7.37996356 -1.06563471 7.37996356"
              />
              <path
                d="M6,3 C6,1.34314575 4.65685425,0 3,0 C1.34314575,0 0,1.34314575 0,3 C0,4.65685425 1.34314575,6 3,6 C3.79564947,6 4.55871121,5.68392948 5.12132034,5.12132034 C5.68392948,4.55871121 6,3.79564947 6,3 Z M2,3 C2,2.44771525 2.44771525,2 3,2 C3.55228475,2 4,2.44771525 4,3 C4,3.55228475 3.55228475,4 3,4 C2.44771525,4 2,3.55228475 2,3 Z"
                id="Shape"
              />
              <path
                d="M9,7 C7.34314575,7 6,8.34314575 6,10 C6,11.6568542 7.34314575,13 9,13 C10.6568542,13 12,11.6568542 12,10 C12,8.34314575 10.6568542,7 9,7 Z M9,11 C8.44771525,11 8,10.5522847 8,10 C8,9.44771525 8.44771525,9 9,9 C9.55228475,9 10,9.44771525 10,10 C10,10.5522847 9.55228475,11 9,11 Z"
                id="Shape"
              />
            </g>
          </g>
        </g>
      </svg>
    </VkwIcon>
  );
};
