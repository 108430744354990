import React, { ReactElement, ChangeEvent, ReactNode } from 'react';

import { Box, FormControl, InputLabel, MenuItem, Select, SvgIcon, Typography, makeStyles } from '@material-ui/core';

import { VkwTheme, getVkwTypographyStyle, useVkwTheme, useVkwFormatMessage } from '../../library';

export type ColorCode = 'undefined' | 'red' | 'orange' | 'yellow' | 'green' | 'blue';

const useStyles = makeStyles<VkwTheme>(theme => ({
  colorBox: {
    border: 'solid 1px rgba(0, 0, 0, 0.24)',
    borderRadius: '2px',
    height: '32px',
    width: '32px',
  },
  errorIcon: {
    color: theme.palette.error.main,
    height: theme.typography.pxToRem(15),
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(2),
    width: theme.typography.pxToRem(15),
  },
  errorMessage: {
    ...getVkwTypographyStyle('link14', theme),
    color: theme.palette.error.main,
    paddingTop: theme.spacing(0.5),
  },
  formControl: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
  },
  input: {
    boxSizing: 'border-box',
    color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
    height: theme.spacing(6),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    ...getVkwTypographyStyle('text14', theme),
  },
  label: {
    '&.Mui-focused': {
      color: theme.palette.grey[500],
    },
    '&.MuiFormLabel-root': {
      ...getVkwTypographyStyle('text12', theme),
      color: theme.palette.grey[500],
    },
    left: theme.spacing(2),
    top: -theme.spacing(1.5),
  },
  popover: {
    marginTop: theme.spacing(0),
    width: '100%',
  },
  select: {
    boxSizing: 'border-box',
    height: theme.spacing(6),
    position: 'relative',
    ...getVkwTypographyStyle('text14', theme),
  },
}));

const useChargePointStyle = makeStyles<VkwTheme>(theme => ({
  header: {
    ...getVkwTypographyStyle('text12', theme),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
}));

interface VkwIconError2Props {
  className: string;
}

const VkwIconError2 = ({ className }: VkwIconError2Props): ReactElement => {
  return (
    <SvgIcon viewBox="0 0 16 16" className={className}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
      />
    </SvgIcon>
  );
};

interface ColorSelectProps {
  name: string;
  onChange?: (event: ChangeEvent<{ name?: string; value: unknown }>, child: ReactNode) => void;
  value?: unknown;
  error?: string;
}

const ColorSelect = ({ error, name, ...rest }: ColorSelectProps): ReactElement => {
  const styles = useStyles();
  const theme = useVkwTheme();

  const possibleColors: { id: ColorCode; color: string }[] = [
    { color: 'transparent', id: 'undefined' },
    { color: theme.palette.error.main, id: 'red' },
    { color: '#ff8811', id: 'orange' },
    { color: theme.palette.warning.main, id: 'yellow' },
    { color: theme.palette.success.main, id: 'green' },
    { color: 'blue', id: 'blue' },
  ];

  return (
    <FormControl className={styles.formControl} fullWidth>
      <InputLabel shrink={false} htmlFor={`select-${name}`} className={styles.label} />
      <Select
        fullWidth
        variant="filled"
        className={styles.select}
        name={name}
        error={!!error}
        defaultValue=""
        inputProps={{
          className: styles.input,
          id: `select-${name}`,
          name,
        }}
        {...rest}
        MenuProps={{
          PopoverClasses: { root: styles.popover },
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          getContentAnchorEl: null,
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
        }}
      >
        {possibleColors.map((item, index) => {
          return (
            <MenuItem key={index} value={item.id} alignItems="center">
              <Box bgcolor={item.color} className={styles.colorBox} />
            </MenuItem>
          );
        })}
      </Select>
      {error && <VkwIconError2 className={styles.errorIcon} />}
      {error && <Box className={styles.errorMessage}>{error}</Box>}
    </FormControl>
  );
};

interface ChargePointColorCodePickerProps {
  name: string;
  selectValues: ColorCode[];
  onChange: (changedValues: ColorCode[]) => void;
}

export const ChargePointColorCodePicker = ({
  name,
  onChange,
  selectValues,
}: ChargePointColorCodePickerProps): ReactElement => {
  const styles = useChargePointStyle();
  const formatMessage = useVkwFormatMessage();
  return (
    <>
      <Typography className={styles.header}>{formatMessage('ChargePointInfoColorcode')}</Typography>
      <Box display="flex">
        {selectValues.map((item, index) => {
          return (
            <ColorSelect
              name={`${name}${index}`}
              key={index}
              value={item}
              onChange={(event: ChangeEvent<{ name?: string; value: unknown }>) => {
                const newValue = [...selectValues];
                newValue[index] = event.target.value as ColorCode;
                onChange(newValue);
              }}
            />
          );
        })}
      </Box>
    </>
  );
};
