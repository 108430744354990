import { TranslateOptions } from 'i18n-js/src/typing';

import { setLocale, t as translate } from '@e-vo/localization';

/**
 * Translates text using the e-vo localization library
 *
 * @param key
 * @param options
 */
export function t(key: string, options?: TranslateOptions) {
  setLocale('de');

  return translate(key, options);
}
