import { Attachment } from '../../util/Files';

export type PaymentTypes = 'ChargingCard' | 'Intercharge' | 'OtherTypes' | 'vehicleId';
export type ResponseChannels = 'No' | 'Phone' | 'EMail';
export type TicketStatus = 'Created' | 'InProgress' | 'OnHold' | 'Finished' | 'Closed' | 'Canceled';
export type TicketSubject = 'Charging' | 'ChargingStation' | 'ChargingCard' | 'Portal' | 'Payment' | 'Other';
export type TicketSubjectCca = 'ChargingStation' | 'ChargingCard' | 'Request' | 'Critique' | 'Portal' | 'Payment';

export const POSSIBLE_PAYMENT_TYPES: PaymentTypes[] = ['ChargingCard', 'Intercharge', 'OtherTypes'];
export const POSSIBLE_RESPONSE_CHANNELS: ResponseChannels[] = ['No', 'Phone', 'EMail'];
export const POSSIBLE_SUBJECTS: TicketSubject[] = [
  'Charging',
  'ChargingStation',
  'ChargingCard',
  'Portal',
  'Payment',
  'Other',
];

export const POSSIBLE_SUBJECTS_CCA: TicketSubjectCca[] = [
  'ChargingStation',
  'ChargingCard',
  'Request',
  'Critique',
  'Portal',
  'Payment',
];

export const FIELD_MAX_LENGTH = {
  AUTHENTICATION_CARD_UID: 20,
  CUSTOMER: 100,
  DESCRIPTION: 1000,
  EMAIL_ADDRESS: 50,
  SOLUTION: 500,
};

export const PHONE_REGEX = /^(\+|0)(?:[0-9]⋅?){6,14}[0-9]$/;

export interface Ticket {
  id: string;
  subject: TicketSubject | null;
  description: string | null;
  paymentType: PaymentTypes | null;
  authenticationCardUid: string | null;
  evseId: string | null;
  otherProviderInfo: string | null;
  creationDate: Date | null;
  modificationDate: Date | null;
  ticketId: string | null;
  ticketInternalId: string | null;
  ticketUser: string | null;
  status: TicketStatus | null;
  attachments: Attachment[] | null;
  firstName: string | null;
  lastName: string | null;
}

export interface TicketResponse {
  subject: string | null;
  description: string | null;
  paymentType: PaymentTypes | null;
  authenticationCardUid: string | null;
  evseId: string | null;
  otherProviderInfo: string | null;
  creationDate: Date | null;
  modificationDate: Date | null;
  ticketId: string | null;
  ticketInternalId: string | null;
  ticketUser: string | null;
  status: TicketStatus | null;
  attachments: Attachment[] | null;
  firstName: string | null;
  lastName: string | null;
}
