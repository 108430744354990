import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugCeeRot = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M12 0c6.63 0 12 5.376 12 12s-5.37 12-12 12S0 18.624 0 12 5.37 0 12 0zm0 16a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 16zm-6-5a2 2 0 1 0-.001 3.999A2 2 0 0 0 6 11zm12 0a2 2 0 1 0-.001 3.999A2 2 0 0 0 18 11zM8 4a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 4zm8 0a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 4z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </VkwIcon>
  );
};
