import { makeStyles, lighten, darken } from '@material-ui/core';

import { getVkwTypographyStyle } from '../../../helper';
import { VkwTheme } from '../../../themes';

const hackyStyles = (theme: VkwTheme) => ({
  '& .MuiButton-textPrimary': {
    '&:active': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? lighten(
              theme.palette.mainButtonColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
              0.2
            )
          : darken(
              theme.palette.mainButtonColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
              0.2
            ),
    },
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? lighten(
              theme.palette.mainButtonColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
              0.1
            )
          : darken(
              theme.palette.mainButtonColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
              0.1
            ),
    },
    backgroundColor:
      theme.palette.mainButtonColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,

    color:
      theme.palette.mainButtonColor === 'primary'
        ? theme.palette.primary.contrastText
        : theme.palette.secondary.contrastText,

    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
  },

  '& .MuiButton-textPrimary:nth-child(1)': {
    '&:active': {
      backgroundColor:
        theme.palette.type === 'dark' ? lighten(theme.palette.grey[600], 0.2) : darken(theme.palette.grey[300], 0.2),
    },
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark' ? lighten(theme.palette.grey[600], 0.1) : darken(theme.palette.grey[300], 0.1),
    },
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[600] : theme.palette.grey[300],

    color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,

    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
  },

  '& .MuiPickersCalendar-week .MuiIconButton-root': {
    borderRadius: '2px',
  },

  '& .MuiPickersCalendarHeader-iconButton': {
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark' ? lighten(theme.palette.grey[800], 0.1) : darken(theme.palette.common.white, 0.1),
    },

    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.common.white,
  },

  '& .MuiPickersDay-current': {
    color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
  },

  '& .MuiPickersDay-daySelected': {
    backgroundColor:
      theme.palette.mainSliderColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
    color:
      theme.palette.mainSliderColor === 'primary'
        ? theme.palette.primary.contrastText
        : theme.palette.secondary.contrastText,
  },
});

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    datePicker: hackyStyles(theme),
    inputFocused: {
      paddingRight: 8,
    },
    inputInput: {
      color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
    },
    inputRoot: {
      ...getVkwTypographyStyle('text14', theme),
      paddingRight: 10,
    },
    label: {
      '&.Mui-focused': {
        color: theme.palette.grey[500],
      },
    },
    labelRoot: {
      ...getVkwTypographyStyle('text12', theme),
      color: theme.palette.grey[500],
    },
    pickerPaperRoot: {
      background: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.common.white,
      border: theme.palette.type === 'dark' ? `1px solid ${theme.palette.grey[700]}` : `none`,
    },
  }),
  { index: 1 }
);

export default useStyles;
