import React, { Fragment, ReactElement, useEffect, useState } from 'react';

import NiceModal from '@ebay/nice-modal-react';
import { Transition } from '@headlessui/react';

import { useVkwFormatMessage } from '../../library';
import { hasCookieConsent, setCookieConsent } from '../../util/cookie-consent';
import { Button } from '../Button';
import { CookieConsentSettingsModalId } from '../CookieConsentModal';

export const CookieConsentPopup = (): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const cookieConsentActive = hasCookieConsent();

  const [isOpen, setIsOpen] = useState(false);

  const openCookieConsentSettings = () => {
    setIsOpen(false);
    NiceModal.show(CookieConsentSettingsModalId);
  };

  const handleCookieConsent = (allowAll = true) => {
    setCookieConsent(allowAll);
    setIsOpen(false);
  };

  useEffect(() => {
    if (!cookieConsentActive) {
      setIsOpen(true);
    }
  }, [cookieConsentActive]);

  return (
    <Transition
      // The appear option is not working with the latest Headless UI version.
      // appear
      show={isOpen}
      as={Fragment}
      enter="ease-out duration-400"
      enterFrom="opacity-0 -bottom-10 sm:scale-90"
      enterTo="opacity-100 bottom-0 sm:scale-100"
      leave="ease-in duration-400"
      leaveFrom="opacity-100 bottom-0 sm:scale-100"
      leaveTo="opacity-0 -bottom-10 sm:scale-90"
    >
      <div className="xs:mx-5 fixed bottom-0 z-[9998] mx-2 max-w-full rounded-t-2xl bg-white/90 p-7 shadow-2xl backdrop-blur transition-all sm:bottom-10 sm:right-10 sm:mx-0 sm:max-w-md sm:rounded-2xl dark:bg-gray-900/90">
        <div className="mb-2 text-lg font-bold text-gray-900 dark:text-white">
          {formatMessage('CookieConsentPopupTitle')}
        </div>
        <div className="text-gray-600 dark:text-gray-300">
          {formatMessage('CookieConsentPopupDescription', {
            // eslint-disable-next-line react/no-unstable-nested-components
            settingsButton: (chunks: any) => (
              <button
                type="button"
                className="font-medium text-gray-900 underline-offset-4 outline-0 hover:underline dark:text-white"
                onClick={openCookieConsentSettings}
              >
                {chunks}
              </button>
            ),
          })}
        </div>
        <div className="mt-5 grid grid-cols-2 gap-4">
          <Button
            fullWidth
            onPress={() => handleCookieConsent(true)}
            title={formatMessage('CookieConsentRejectOptional')}
          />
          <Button
            fullWidth
            onPress={() => handleCookieConsent(true)}
            title={formatMessage('CookieConsentAllowAll')}
            type="primary"
          />
        </div>
      </div>
    </Transition>
  );
};
