import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconDocuments = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize <= 12) {
          return (
            <path
              fill={fill}
              fillRule="evenodd"
              transform="scale(2)"
              d="M7.5 0c.133 0 .26.053.354.146l3 3c.093.094.146.221.146.354v8c0 .276-.224.5-.5.5h-9c-.276 0-.5-.224-.5-.5V.5c0-.276.224-.5.5-.5zm-.207 1H2v10h8V3.707L7.293 1zM8.5 7c.276 0 .5.224.5.5 0 .245-.177.45-.41.492L8.5 8h-5c-.276 0-.5-.224-.5-.5 0-.245.177-.45.41-.492L3.5 7h5zm-1-3c.276 0 .5.224.5.5 0 .245-.177.45-.41.492L7.5 5h-4c-.276 0-.5-.224-.5-.5 0-.245.177-.45.41-.492L3.5 4h4z"
            />
          );
        }
        if (fontSize <= 16) {
          return (
            <path
              fill={fill}
              fillRule="evenodd"
              transform="scale(1.5)"
              d="M11.5 2.75c.414 0 .75.336.75.75v12c0 .414-.336.75-.75.75h-10c-.414 0-.75-.336-.75-.75v-12c0-.414.336-.75.75-.75zm-.75 1.5h-8.5v10.5h8.5V4.25zm3.75-4.5c.38 0 .693.282.743.648L15.25.5v13c0 .414-.336.75-.75.75-.38 0-.693-.282-.743-.648l-.007-.102V1.25H3.5c-.38 0-.693-.282-.743-.648L2.75.5c0-.38.282-.693.648-.743L3.5-.25h11zm-6 12c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007h-4c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743l.102-.007h4zm0-3c.414 0 .75.336.75.75 0 .38-.282.693-.648.743l-.102.007h-4c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L4.5 8.75h4zm0-3c.414 0 .75.336.75.75 0 .38-.282.693-.648.743L8.5 7.25h-4c-.414 0-.75-.336-.75-.75 0-.38.282-.693.648-.743L4.5 5.75h4z"
            />
          );
        }

        return (
          <g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeWidth={strokewidth}>
            <rect height="18" width="16" fill="none" stroke={fill} x="2" y="5" />
            <polyline fill="none" points=" 5,1 22,1 22,21 " />
            <line fill="none" stroke={fill} x1="6" x2="14" y1="10" y2="10" />
            <line fill="none" stroke={fill} x1="6" x2="14" y1="14" y2="14" />
            <line fill="none" stroke={fill} x1="6" x2="10" y1="18" y2="18" />
          </g>
        );
      }}
    </VkwIcon>
  );
};
