import React, { ReactElement, ReactNode } from 'react';

import { useVkwFormatMessage } from '../../library';

export interface TableProps {
  children: ReactNode;
}

const TableRoot = ({ children }: TableProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse text-left text-[11px] text-gray-500 dark:border-gray-600">
        <thead className="bg-gray-200 text-[12px] text-gray-700 dark:bg-gray-600 dark:text-gray-300">
          <tr>
            <th scope="col" className="border border-gray-100 px-5 py-1 !font-light dark:border-gray-700">
              {formatMessage('Name')}
            </th>
            <th scope="col" className="border border-gray-100 px-4 py-1 !font-light dark:border-gray-700">
              {formatMessage('Domain')}
            </th>
            <th scope="col" className="border border-gray-100 px-4 py-1 !font-light dark:border-gray-700">
              {formatMessage('Duration')}
            </th>
            <th scope="col" className="border border-gray-100 px-5 py-1 !font-light dark:border-gray-700">
              {formatMessage('Purpose')}
            </th>
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export interface EntryProps {
  name: string;
  maxAge: string;
  children: ReactNode;
  domain?: string;
}

const Entry = ({ children, domain, maxAge, name }: EntryProps): ReactElement => {
  return (
    <tr className="border text-gray-500 dark:text-gray-400">
      <td className="border px-5 py-2 dark:border-gray-700">{name}</td>
      <td className="border px-4 py-2 dark:border-gray-700">{domain ?? window.location.hostname}</td>
      <td className="border px-4 py-2 dark:border-gray-700">{maxAge}</td>
      <td className="border px-5 py-2 dark:border-gray-700">{children}</td>
    </tr>
  );
};

export const CookieConsentModalDisclosureTable = Object.assign(TableRoot, { Entry });
