import { makeStyles } from '@material-ui/core';

import { VkwTheme } from '../..';

export const useStyles = makeStyles<VkwTheme>(
  theme => ({
    '@global': {
      '.slick-arrow.slick-hidden': {
        display: 'none',
      },
      '.slick-initialized .slick-slide': {
        display: 'inline-block',
      },
      '.slick-list': {
        display: 'block',

        margin: 0,
        overflow: 'hidden',

        padding: 0,
        position: 'relative',
      },
      '.slick-list.dragging': {
        cursor: 'hand',
      },
      '.slick-list:focus': {
        outline: 'none',
      },
      '.slick-loading .slick-slide': {
        visibility: 'hidden',
      },
      '.slick-loading .slick-track': {
        visibility: 'hidden',
      },
      '.slick-next': { right: '-40px' },
      '.slick-prev': { left: '-40px' },
      '.slick-prev,\n.slick-next': {
        WebkitTransform: 'translate(0, -50%)',
        background: 'transparent',
        border: 'none',
        color: 'transparent',
        cursor: 'pointer',
        display: 'block',
        fontSize: '0',
        lineHeight: 0,
        msTransform: 'translate(0, -50%)',
        outline: 'none',
        padding: '0',
        position: 'absolute',
        top: '50%',
        transform: 'translate(0, -50%)',
        zIndex: 2,
      },
      '.slick-prev.slick-disabled,.slick-next.slick-disabled': {
        cursor: 'default',
      },
      '.slick-prev:hover,\n.slick-prev:focus,\n.slick-next:hover,\n.slick-next:focus': {
        background: 'transparent',
        color: 'transparent',
        outline: 'none',
      },
      '.slick-slide': {
        display: 'inline-block',

        height: '100%',
        margin: '0 auto',
        minHeight: '1px',
      },
      '.slick-slide img': {
        display: 'block',
      },
      '.slick-slide.dragging img': {
        pointerEvents: 'none',
      },
      '.slick-slide.slick-loading img': {
        display: 'none',
      },
      '.slick-slider': {
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-touch-action': 'pan-y',
        '-ms-user-select': 'none',
        '-webkit-tap-highlight-color': 'transparent',
        '-webkit-touch-callout': 'none',

        '-webkit-user-select': 'none',
        boxSizing: 'border-box',
        display: 'block',
        marginBottom: '30px',

        marginLeft: '25px',
        marginRight: '25px',
        position: 'relative',
        'touch-action': 'pan-y',
        'user-select': 'none',
      },
      '.slick-slider .slick-track, .slick-slider .slick-list': {
        '-moz-transform': 'translate3d(0, 0, 0)',
        '-ms-transform': 'translate3d(0, 0, 0)',
        '-o-transform': 'translate3d(0, 0, 0)',
        '-webkit-transform': 'translate3d(0, 0, 0)',
        transform: 'translate3d(0, 0, 0)',
      },
      '.slick-track': {
        display: 'block',
        left: 0,
        marginLeft: 'auto',

        marginRight: 'auto',
        position: 'relative',
        top: 0,
        whiteSpace: 'nowrap',
      },
      '.slick-track:after': {
        clear: 'both',
      },
      '.slick-track:before, .slick-track:after': {
        content: '""',

        display: 'table',
      },
      '.slick-vertical .slick-slide': {
        border: '1px solid transparent',

        display: 'block',

        height: 'auto',
      },
      '[dir="rtl"] .slick-slide': {
        float: 'right',
      },
      "[dir='rtl'] .slick-next": { left: '-25px', right: 'auto' },
      "[dir='rtl'] .slick-prev": { left: 'auto', right: '-25px' },
    },

    element: {
      '&:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    elementWrapper: {
      outline: 'none',
    },
    sliderDots: {
      '& li': {
        cursor: 'pointer',

        display: 'inline-block',

        height: '10px',
        margin: '0 4px',
        padding: '0',
        position: 'relative',

        width: '10px',
      },
      '& li button': {
        background: 'transparent',
        border: 0,

        color: 'transparent',
        cursor: 'pointer',

        display: 'block',
        fontSize: 0,
        height: '10px',

        lineHeight: 0,

        outline: 'none',
        padding: '5px',
        position: 'relative',
        width: '10px',
      },

      '& li button::before': {
        '-moz-osx-font-smoothing': 'grayscale',
        '-webkit-font-smoothing': 'antialiased',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[400],

        borderRadius: '5px',
        content: '""',
        height: '10px',

        left: 0,
        position: 'absolute',

        textAlign: 'center',

        top: 0,
        width: '10px',
      },

      '& li button:hover, & li button:focus': {
        outline: 'none',
      },
      '& li.slick-active button:before': {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[600],
      },
      bottom: '-20px',

      display: 'block',

      listStyle: 'none',

      margin: 0,
      padding: 0,
      position: 'absolute',
      textAlign: 'center',
      width: '100%',
    },
  }),
  { index: 1 }
);
