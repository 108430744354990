import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import axios from 'axios';

import { ChargePoint } from '@e-vo/types';

import { useHandleAxiosResponse } from '..';
import { useConfigContext, useUserContext } from '../../contexts';
import { clusterChargeStations } from '../../util/charge-stations';
import { getFilterCookie, hasFilterCookie, setFilterCookie } from '../../util/cookies';
import { MIN_CHARGE_POWER_STEPS } from '../../views';

export interface Period {
  begin: string;
  end: string;
}

export interface OpeningTime {
  when: string;
  periods: Period[];
}

export interface ChargeStation {
  id: string;
  stationId: string;
  location: {
    latitude: number;
    longitude: number;
  };
  count: number;
  key: string;
  type: string;
  stationNameOriginal: string;
  address: string;
  isOpen24Hours: boolean;
  openingTimes: null | OpeningTime[];
  operatorName: string;
  operatorToken: string;
  chargePoints: ChargePoint[];
}

export type ChargeStationsFilter =
  | 'allStations'
  | 'ownStations'
  | 'favoriteStations'
  | 'operatorStations'
  | 'isNationalCalibrationLawCompliant';

interface ChargeAtlasStoreProps {
  url: string;
  defaultPosition: google.maps.LatLngLiteral;
}

export interface ChargeAtlasStore {
  selectedChargeStation: ChargeStation | null;
  selectedChargeStationList: ChargeStation[] | null;
  chargeStations: (ChargeStation | ChargeStation[])[];
  loading: boolean;
  onlyAvailableFilter: boolean;
  chargeStationsFilter: ChargeStationsFilter;
  plugsFilter: string[];
  minChargePowerFilter: number;
  chargeStationsNearby: ChargeStation[];
  currentPosition: google.maps.LatLngLiteral;
  searchPosition: google.maps.LatLngLiteral | null;
  activeFilterCount: number;
  setCurrentPosition: (currentPosition: google.maps.LatLngLiteral) => void;
  setSearchPosition: (searchPosition: google.maps.LatLngLiteral | null) => void;
  loadAndSetSelectedChargeStation: (evseId: string | null) => void;
  setSelectedChargeStation: (selectedChargeStation: ChargeStation | null) => void;
  setSelectedChargeStationList: (selectedChargeStationList: ChargeStation[] | null) => void;
  setOnlyAvailableFilter: (onlyAvailableFilter: boolean) => void;
  setChargeStationsFilter: (chargeStationsFilter: ChargeStationsFilter) => void;
  setPlugsFilter: (filter: string[]) => void;
  setMinChargePowerFilter: (power: number) => void;
  changeBounds: (bounds: google.maps.LatLngBounds, zoom: number) => void;
}

export interface ChargeAtlasFilterCookie {
  chargeStationsFilter: ChargeStationsFilter;
  plugsFilter: string[];
  minChargePowerFilter: number;
}

const FILTER_COOKIE_KEY = 'charge-atlas';

export const useChargeAtlasStore = ({ defaultPosition, url }: ChargeAtlasStoreProps): ChargeAtlasStore => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  });

  const handleAxiosResponse = useHandleAxiosResponse();
  const user = useUserContext();
  const config = useConfigContext();

  let filterCookie: ChargeAtlasFilterCookie | undefined;

  if (hasFilterCookie(FILTER_COOKIE_KEY)) {
    filterCookie = JSON.parse(getFilterCookie(FILTER_COOKIE_KEY) as string) as ChargeAtlasFilterCookie;
  }

  const [selectedChargeStation, setSelectedChargeStation] = useState<ChargeStation | null>(null);
  const [selectedChargeStationList, setSelectedChargeStationList] = useState<ChargeStation[] | null>(null);
  const [chargeStations, setChargeStations] = useState<(ChargeStation | ChargeStation[])[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingNearby, setLoadingNearby] = useState(false);
  const [onlyAvailableFilter, setOnlyAvailableFilter] = useState(false);
  const [chargeStationsFilter, setChargeStationsFilter] = useState<ChargeStationsFilter>(
    filterCookie?.chargeStationsFilter ?? 'operatorStations'
  );
  const [plugsFilter, setPlugsFilter] = useState<string[]>(filterCookie?.plugsFilter ?? []);
  const [minChargePowerFilter, setMinChargePowerFilter] = useState(
    filterCookie?.minChargePowerFilter ?? MIN_CHARGE_POWER_STEPS[0].value
  );
  const [chargeStationsNearby, setChargeStationsNearby] = useState<ChargeStation[]>([]);
  const [currentPosition, setCurrentPosition] = useState(defaultPosition);
  const [searchPosition, setSearchPosition] = useState<google.maps.LatLngLiteral | null>(null);
  const [nationalCalibrationLaw] = useState(false);

  const [bounds, setBounds] = useState({
    bottomRightLat: 0,
    bottomRightLong: 0,
    topLeftLat: 0,
    topLeftLong: 0,
    zoom: 0,
  });

  const activeFilterCount = useMemo(() => {
    let activeFilterCount = 0;

    if (onlyAvailableFilter) {
      activeFilterCount++;
    }

    if (chargeStationsFilter !== 'allStations') {
      activeFilterCount++;
    }

    if (plugsFilter.length > 0) {
      activeFilterCount++;
    }

    if (minChargePowerFilter > MIN_CHARGE_POWER_STEPS[0].value) {
      activeFilterCount++;
    }

    if (nationalCalibrationLaw) {
      activeFilterCount++;
    }

    return activeFilterCount;
  }, [onlyAvailableFilter, chargeStationsFilter, plugsFilter, minChargePowerFilter, nationalCalibrationLaw]);

  const loadAndSetSelectedChargeStation = useCallback(
    (evseId: string | null): void => {
      if (!evseId) {
        setSelectedChargeStation(null);

        return;
      }

      handleAxiosResponse(
        () =>
          axios.get(`${url}/${evseId}`, {
            params: {
              customerId: user.selectedCustomer?.id,
            },
          }),
        {
          success: response => {
            setSelectedChargeStation(response.data);
          },
        }
      );
    },
    [url]
  );

  const changeBounds = useCallback((bounds: google.maps.LatLngBounds, zoom: number): void => {
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    if (ne.lat() === sw.lat() || ne.lng() === sw.lng()) {
      return;
    }

    setBounds({
      bottomRightLat: sw.lat(),
      bottomRightLong: ne.lng(),
      topLeftLat: ne.lat(),
      topLeftLong: sw.lng(),
      zoom,
    });
  }, []);

  useEffect(() => {
    if (!bounds.zoom || bounds.topLeftLat < bounds.bottomRightLat || bounds.bottomRightLong < bounds.topLeftLong) {
      return;
    }

    setLoading(true);

    handleAxiosResponse(
      () =>
        axios.get(`${url}/chargeAtlas`, {
          params: {
            bottomRightLat: bounds.bottomRightLat,
            bottomRightLong: bounds.bottomRightLong,
            chargeStationsFilter,
            customerId: user.selectedCustomer?.id,
            minPower: minChargePowerFilter,
            plugs: plugsFilter,
            status: onlyAvailableFilter ? 'AVAILABLE' : undefined,
            topLeftLat: bounds.topLeftLat,
            topLeftLong: bounds.topLeftLong,
            zoom: bounds.zoom,
          },
        }),
      {
        success: response => {
          if (!mounted.current) {
            return;
          }

          setChargeStations(clusterChargeStations(response.data, 50));

          setLoading(false);
        },
      }
    );
  }, [
    bounds,
    url,
    onlyAvailableFilter,
    plugsFilter,
    minChargePowerFilter,
    chargeStationsFilter,
    user.selectedCustomer?.id,
  ]);

  useEffect(() => {
    if (!currentPosition) {
      return;
    }

    setLoadingNearby(true);

    handleAxiosResponse(
      () =>
        axios.get(url, {
          params: {
            chargeStationsFilter,
            minPower: minChargePowerFilter,
            plugs: plugsFilter,
            positionLat: currentPosition.lat,
            positionLng: currentPosition.lng,
            status: onlyAvailableFilter ? 'AVAILABLE' : undefined,
          },
        }),
      {
        success: response => {
          if (!mounted.current) {
            return;
          }

          setChargeStationsNearby(response.data);

          setLoadingNearby(false);
        },
      }
    );
  }, [currentPosition, url, onlyAvailableFilter, plugsFilter, minChargePowerFilter, chargeStationsFilter]);

  useEffect(() => {
    const filterCookieContent: ChargeAtlasFilterCookie = {
      chargeStationsFilter,
      minChargePowerFilter,
      plugsFilter: [...plugsFilter],
    };

    setFilterCookie(FILTER_COOKIE_KEY, filterCookieContent);
  }, [plugsFilter, minChargePowerFilter, chargeStationsFilter]);

  return {
    activeFilterCount,
    changeBounds,
    chargeStations,
    chargeStationsFilter,
    chargeStationsNearby,
    currentPosition,
    loadAndSetSelectedChargeStation,
    loading: loading || loadingNearby,
    minChargePowerFilter,
    onlyAvailableFilter,
    plugsFilter,
    searchPosition,
    selectedChargeStation,
    selectedChargeStationList,
    setChargeStationsFilter,
    setCurrentPosition,
    setMinChargePowerFilter,
    setOnlyAvailableFilter,
    setPlugsFilter,
    setSearchPosition,
    setSelectedChargeStation,
    setSelectedChargeStationList,
  };
};
