import React, { ReactElement, useMemo } from 'react';

import { VictoryArea } from 'victory';

import { useVkwTheme, VkwTheme } from '../../themes';

interface VkwPerformanceGraphDataPoint {
  x: number;
  y: number;
  y0: number;
}

const getColor = (theme: VkwTheme, trend: 'good' | 'neutral' | 'bad') => {
  if (trend === 'good') {
    return theme.palette.performanceGraphGoodColor;
  }
  if (trend === 'bad') {
    return theme.palette.error.main;
  }
  return theme.palette.warning.main;
};

export interface VkwPerformanceGraphProps {
  values: number[];
  /**
   * Farbe des Trends
   */
  trend?: 'good' | 'neutral' | 'bad';
  /**
   * Soll der Trend animiert aufgebaut werden?
   */
  animate?: boolean;
}

export const VkwPerformanceGraph = ({ animate, trend = 'good', values }: VkwPerformanceGraphProps): ReactElement => {
  const theme = useVkwTheme();
  const color = getColor(theme, trend);

  const { data, yMax } = useMemo(() => {
    const tmpData: VkwPerformanceGraphDataPoint[] = [];
    let maxValue = 0;
    values.forEach((value, index) => {
      tmpData.push({ x: index, y: value, y0: 0 });
      if (value > maxValue) {
        maxValue = value;
      }
    });

    return {
      data: tmpData,
      yMax: maxValue > 0 ? maxValue * 1.1 : 1,
    };
  }, [values]);

  return (
    <VictoryArea
      domain={{ x: [0, values.length - 1], y: [0, yMax] }}
      interpolation="monotoneX"
      animate={
        animate
          ? {
              duration: 2000,
              onLoad: { duration: 1000 },
            }
          : undefined
      }
      style={{
        data: {
          fill: color,
          fillOpacity: 0.3,
          stroke: color,
          strokeWidth: 16,
        },
      }}
      data={data}
    />
  );
};
