import React, { ReactElement, useCallback, useMemo } from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { ChargePointStatus, Plug } from '@e-vo/types';

import { ChargeStation } from '../../hooks';
import { getVkwTypographyStyle, VkwTheme } from '../../library';
import { getPlugIcon } from '../../util/getPlugIcon';

const useStyles = makeStyles<VkwTheme>(theme => ({
  chargePoint: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  chargePointAvailable: {
    color: theme.palette.success.main,
  },
  chargePointDefault: {
    color: theme.palette.grey[500],
  },
  chargePointOccupied: {
    color: theme.palette.warning.main,
  },
  chargePointOutOfService: {
    color: theme.palette.error.main,
  },
  icon: {
    display: 'flex',
    paddingRight: theme.spacing(1),
  },
  maxPower: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    ...getVkwTypographyStyle('text12', theme),
    color: theme.palette.grey[500],
    display: 'flex',
  },
}));

interface ChargeStationPlugsProps {
  chargeStation: ChargeStation;
}

interface PlugInfos {
  maxPower: number;
  plug: Plug;
  available: number;
  occupied: number;
  outOfService: number;
  reserved: number;
  unknown: number;
}

const updatePlugInfos = (plugInfos: PlugInfos, status: ChargePointStatus): PlugInfos => {
  const updatedPlugInfos = { ...plugInfos };

  if (status === 'AVAILABLE') {
    updatedPlugInfos.available++;
  } else if (status === 'OCCUPIED') {
    updatedPlugInfos.occupied++;
  } else if (status === 'OUT_OF_SERVICE') {
    updatedPlugInfos.outOfService++;
  } else if (status === 'RESERVED') {
    updatedPlugInfos.reserved++;
  } else if (status === 'UNKNOWN') {
    updatedPlugInfos.unknown++;
  }
  return updatedPlugInfos;
};

export const ChargeStationPlugs = ({ chargeStation }: ChargeStationPlugsProps): ReactElement => {
  const styles = useStyles();

  const allPlugs = useMemo<PlugInfos[]>(() => {
    const result: { [key: string]: PlugInfos } = {};
    chargeStation.chargePoints?.forEach(chargePoint => {
      const maxPower = Math.round(chargePoint.maxPower);
      chargePoint.plugs.forEach(plug => {
        const selector = `${plug}-${maxPower}`;

        let plugInfos: PlugInfos | undefined = result[selector];
        if (!plugInfos) {
          plugInfos = { available: 0, maxPower, occupied: 0, outOfService: 0, plug, reserved: 0, unknown: 0 };
        }
        result[selector] = updatePlugInfos(plugInfos, chargePoint.status);
      });
    });
    return Object.values(result);
  }, [chargeStation]);

  const getStyleForPlugInfos = useCallback((plugInfos: PlugInfos) => {
    if (plugInfos.available > 0) {
      return styles.chargePointAvailable;
    }
    if (plugInfos.occupied > 0 || plugInfos.reserved > 0) {
      return styles.chargePointOccupied;
    }
    if (plugInfos.outOfService > 0) {
      return styles.chargePointOutOfService;
    }
    return styles.chargePointDefault;
  }, []);

  return (
    <Grid container>
      {allPlugs.map((plugInfos, idx) => {
        const PlugIcon = getPlugIcon(plugInfos.plug);
        return (
          <Grid item key={idx} className={clsx(styles.chargePoint, getStyleForPlugInfos(plugInfos))}>
            <PlugIcon size={16} />
            <Typography className={styles.maxPower}>{plugInfos.maxPower} kW</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};
