import React, { ReactElement, useState } from 'react';

import NextHead from 'next/head';

import { Assets } from '../../globals/configureAssets';
import { VkwHead, useVkwFormatMessage, useVkwAssets, toFirstUppercase, useVkwTheme } from '../../library';
import { moonDarkTheme, moonTheme } from '../../themes';
import { getEnvUrls } from '../../util/helpers';

interface HeadProps {
  pageTranslationKey: string;
  description?: boolean;
  url?: string;
}

export const Head = ({ description, pageTranslationKey, url }: HeadProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const assets = useVkwAssets<Assets>();
  const theme = useVkwTheme();

  const [ogUrl] = useState<string>(() => {
    if (url) {
      return url;
    }

    if (typeof window !== 'undefined') {
      return `${window.location.protocol}//${window.location.host}/auth/signin`;
    }

    return `${getEnvUrls().baseUrl}auth/signin`;
  });

  return (
    <VkwHead
      copyright={formatMessage(`Project_${toFirstUppercase(assets.project)}_Copyright`)}
      description={
        description
          ? formatMessage(`MobilityPortalPageDescription${pageTranslationKey}`, {
              project: formatMessage(`BrandName${toFirstUppercase(assets.project)}`),
            })
          : ''
      }
      publisher={formatMessage(`Project_${toFirstUppercase(assets.project)}_Publisher`)}
      sitename={formatMessage(`Project_${toFirstUppercase(assets.project)}_Sitename`, {
        siteName: formatMessage('MobilityPortalSitename'),
      })}
      title={formatMessage(`Project_${toFirstUppercase(assets.project)}_Title`, {
        page: formatMessage(pageTranslationKey),
      })}
      component={NextHead}
      url={ogUrl}
    >
      {(theme.typography.fontFamily === moonTheme.typography.fontFamily ||
        theme.typography.fontFamily === moonDarkTheme.typography.fontFamily) && (
        <>
          <link rel="preload" href="/fonts/Inter-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
          <link rel="preload" href="/fonts/Inter-SemiBold.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        </>
      )}
    </VkwHead>
  );
};
