import { Theme } from '@material-ui/core';

import { VkwThemeCustomOptionsTypography, VkwTypography } from './createVkwTheme';

export const setTypography = (typography: VkwThemeCustomOptionsTypography, theme: Theme): VkwTypography => {
  const themeTypographyEntries = Object.entries(theme.typography);

  const mergedTypographyEntries = Object.fromEntries(
    Object.entries(typography).map(([key, value]) => {
      const valueFromDefaultTheme = themeTypographyEntries.find(element => element[0] === key);

      if (valueFromDefaultTheme?.[1]) {
        return [key, { ...valueFromDefaultTheme[1], ...value }];
      }

      return [key, value];
    })
  ) as VkwThemeCustomOptionsTypography;

  return {
    ...theme.typography,
    ...mergedTypographyEntries,
  };
};
