import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugTyp3A = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M20 8v8a8 8 0 0 1-16 0V8a8.001 8.001 0 0 1 7-7.938v1.856h2l.001-1.856A8.002 8.002 0 0 1 20 8zm-8 11a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5zm-4-5.5a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 8 13.5zm8 0a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 16 13.5zM8 7a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 8 7zm8 0a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 16 7zM8.5 3a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1zm7 0a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </VkwIcon>
  );
};
