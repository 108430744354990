export const MIN_CHARGE_POWER_STEPS = [
  {
    label: '3.7',
    scaledValue: 3.7,
    value: 10,
  },
  {
    label: '7.4',
    scaledValue: 7.4,
    value: 20,
  },
  {
    label: '11',
    scaledValue: 11,
    value: 30,
  },
  {
    label: '22',
    scaledValue: 22,
    value: 40,
  },
  {
    label: '43',
    scaledValue: 43,
    value: 50,
  },
  {
    label: '50',
    scaledValue: 50,
    value: 60,
  },
  {
    label: '100',
    scaledValue: 100,
    value: 70,
  },
  {
    label: '150',
    scaledValue: 150,
    value: 80,
  },
];

export const DEFAULT_ZOOM = 12;
