import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet';
import { Component } from 'react';
import { Platform } from 'react-native';

import { UserSettingsProvider } from './contexts/UserSettingsContext';

/**
 * Provider only used in iOS and Android project
 */
class MobileProvider extends Component<{ children: React.ReactNode }> {
  render() {
    const { children } = this.props;

    if (Platform.OS === 'web') {
      return <>{children}</>;
    }

    return (
      <UserSettingsProvider>
        <BottomSheetModalProvider>
          <ActionSheetProvider>{children}</ActionSheetProvider>
        </BottomSheetModalProvider>
      </UserSettingsProvider>
    );
  }
}

/**
 * Provider used in web and mobile project
 */
export class MobilityProvider extends Component<{ children: React.ReactNode }> {
  render() {
    const { children } = this.props;
    return <MobileProvider>{children}</MobileProvider>;
  }
}
