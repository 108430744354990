import React, { ReactElement } from 'react';

import { Box } from '@material-ui/core';

import { NavigationItem } from './NavigationItem';
import { VkwNavigationItemConfig } from './VkwNavigationItemConfig';

interface NavigationDesktopItemsProps {
  mainItems: VkwNavigationItemConfig[];
  secondaryItems: VkwNavigationItemConfig[];
  activeRoute: string | null;
  expanded: boolean;
  className?: string;
  onItemClicked?: () => void;
}

export const NavigationDesktopItems = ({
  activeRoute,
  className,
  expanded,
  mainItems,
  onItemClicked,
  secondaryItems,
}: NavigationDesktopItemsProps): ReactElement => {
  return (
    <Box className={className} display="flex" flexDirection="column" flexGrow={1} width="100%">
      <Box display="flex" width="100%" flexGrow={2} flexDirection="column">
        {mainItems.map(mainItem => (
          <NavigationItem
            key={mainItem.key}
            item={mainItem}
            active={activeRoute === mainItem.key}
            showName={expanded}
            onItemClicked={onItemClicked}
          />
        ))}
      </Box>
      <Box display="flex" width="100%" flexDirection="column" flexGrow={0} marginBottom={0.5}>
        {secondaryItems.map(secondaryItem => (
          <NavigationItem
            key={secondaryItem.key}
            item={secondaryItem}
            active={activeRoute === secondaryItem.key}
            showName={expanded}
            onItemClicked={onItemClicked}
          />
        ))}
      </Box>
    </Box>
  );
};
