import { useEffect, useState } from 'react';

import { ChargePointStatus } from '@e-vo/types';

import { useBaseDataStore } from './useBaseDataStore';
import { vkwDateIo, VkwFilterOption } from '../../library';

export interface InfrastructureChargeStationPerformanceElement {
  date: Date;
  totalCharges: number;
  totalDurationInSeconds: number;
  totalConsumedEnergy: number;
  totalCo2Saving: number;
}

export interface InfrastructureChargeStation {
  id: string;
  chargePoints: [
    {
      evseId: string;
      status: ChargePointStatus;
    }
  ];
  stationName: string;
  address: string;
  totalCharges: number;
  performance: null | InfrastructureChargeStationPerformanceElement[];
  compensation: number;
}

interface InfrastructureChargeStationDataStoreProps {
  url: string;
  pageSize?: number;
  defaultSortColumn?: string;
  defaultSortDirection?: 'asc' | 'desc';
}

export interface InfrastructureChargeStationDataStore {
  reload: () => void;
  filterOptions: VkwFilterOption[];
  filters: Map<string, string[] | Date[] | number[]>;
  clearFilters: () => void;
  changeFilter: (name: string, value: string[] | Date[] | number[]) => void;
  sortColumn: string | undefined;
  sortDirection: 'asc' | 'desc';
  setSortColumn: (sortColumn?: string) => void;
  pageSize: number;
  pages: number;
  page: number;
  setPage: (page: number) => void;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  data: InfrastructureChargeStation[];
  totalRecords: number;
  hasMore: boolean;
  initialized: boolean;
  loading: boolean;
  loadAdditionalEntries: boolean;
  performance: InfrastructureChargeStationPerformanceElement[];
}

const FILTER_COOKIE_KEY = 'infrastructure';

export const useInfrastructureChargeStationDataStore = ({
  defaultSortColumn = 'stationName',
  defaultSortDirection = 'asc',
  pageSize = 10,
  url,
}: InfrastructureChargeStationDataStoreProps): InfrastructureChargeStationDataStore => {
  const [performance, setPerformance] = useState<InfrastructureChargeStationPerformanceElement[]>([]);

  const store = useBaseDataStore<InfrastructureChargeStation>({
    defaultSortColumn,
    defaultSortDirection,
    defaultStartLoading: false,
    filterCookieKey: FILTER_COOKIE_KEY,
    pageSize,
    processCustomData: response => {
      if (response) {
        setPerformance(response.data.performance);
      } else {
        setPerformance([]);
      }
    },
    url,
  });

  useEffect(() => {
    store.changeFilter('chargeDateRange', [
      vkwDateIo.date().subtract(29, 'days').startOf('day').toDate(), // Only 29 days because of start day 00:00 and end day 23:59 are 30 days timerange
      vkwDateIo.date().endOf('day').toDate(),
    ]);

    store.startLoading();
  }, []);

  return {
    changeFilter: store.changeFilter,
    clearFilters: store.clearFilters,
    data: store.data,
    filterOptions: store.filterOptions,
    filters: store.filters,
    hasMore: store.hasMore,
    initialized: store.initialized,
    isMobile: store.isMobile,
    loadAdditionalEntries: store.loadAdditionalEntries,
    loading: store.loading,
    page: store.page,
    pageSize: store.pageSize,
    pages: store.pages,
    performance,
    reload: store.reload,
    setIsMobile: store.setIsMobile,
    setPage: store.setPage,
    setSortColumn: store.setSortColumn,
    sortColumn: store.sortColumn,
    sortDirection: store.sortDirection,
    totalRecords: store.totalRecords,
  };
};
