import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Toolbar } from '@material-ui/core';

import useStyles from './VkwBaseTableStyles';
import { VkwIconRemove, VkwIconSearch } from '../../icons';
import { VkwCheckbox } from '../VkwCheckbox';
import { VkwIconButton } from '../VkwIconButton';
import { VkwTextField } from '../VkwTextField';

export interface VkwBaseTableToolbarProps {
  /**
   * Anzahl selektierter Elemente
   */
  numSelected: number;
  /**
   * Anzahl Elemente gesamt
   */
  count: number;
  noBorderBottom?: boolean;
  /**
   * Platzhaltertext im globalen Suchfeld
   */
  searchPlaceholder?: string;
  /**
   * Soll es möglich sein alle Elemente über die Toolbar zu selektieren bzw. zu deselektieren müssen die onSelect... Methoden hier übergeben werden
   */
  checkbox?: {
    onSelectAll: () => void;
    onSelectNone: () => void;
  };
  /**
   * Render Prop für den Toolbar Titel
   */
  renderToolbarTitle?: (props: { numSelected: number; count: number }) => string | ReactElement;
  /**
   * Render Prop um Toolbar Actions zu übergeben z.B. Icon Button für weitere Tabelleneinstellungen etc.
   */
  renderToolbarActions?: () => ReactNode;
  /**
   * Wird diese Methode verwendet wird die globale Suche eingeblendet und der Suchtext bei jeder Änderung bereitgestellt. (onChange)
   */
  onChangeSearchText?: (searchText: string) => void;
}

export const VkwBaseTableToolbar = ({
  checkbox,
  count,
  noBorderBottom = false,
  numSelected,
  onChangeSearchText,
  renderToolbarActions,
  renderToolbarTitle,
  searchPlaceholder,
}: VkwBaseTableToolbarProps): ReactElement => {
  const styles = useStyles();
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');

  const onChangeSearch = (search: string) => {
    setSearchText(search);
  };

  useEffect(() => {
    onChangeSearchText?.(searchText);
  }, [searchText, onChangeSearchText]);

  const checked = numSelected > 0;
  const handleCheckboxChange = () => {
    if (!checkbox) {
      return;
    }

    if (checked) {
      checkbox.onSelectNone();
    } else {
      checkbox.onSelectAll();
    }
  };

  return (
    <Toolbar className={noBorderBottom ? styles.noBorderBottomToolbar : styles.toolbar}>
      {checkbox && <VkwCheckbox checked={checked} onChange={handleCheckboxChange} />}
      <div className={styles.toolbarTitle}>{renderToolbarTitle?.({ count, numSelected })}</div>
      {!showSearch && (
        <>
          {onChangeSearchText && (
            <VkwIconButton
              type="button"
              color="primary"
              onClick={() => {
                setShowSearch(true);
              }}
            >
              <VkwIconSearch size={20} />
            </VkwIconButton>
          )}
          {renderToolbarActions && renderToolbarActions?.()}
        </>
      )}
      {showSearch && (
        <>
          <VkwTextField
            placeholder={searchPlaceholder}
            value={searchText}
            onChange={e => onChangeSearch(e.target.value)}
            variant="outlined"
          />
          <VkwIconButton
            type="button"
            color="primary"
            onClick={() => {
              setShowSearch(false);
              setSearchText('');
            }}
          >
            <VkwIconRemove size={20} />
          </VkwIconButton>
        </>
      )}
    </Toolbar>
  );
};
