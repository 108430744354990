import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconNavigateRoute = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill}>
        <path d="M21,8l-9-7v6H4C3.448,7,3,7.448,3,8v15h2V9h7v6L21,8z" fill={fill} />
      </g>
    </VkwIcon>
  );
};
