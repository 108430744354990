import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import axios, { AxiosResponse } from 'axios';

import { useUserContext } from './UserContext';
import { useHandleAxiosResponse } from '../hooks';
import { useVkwFormatMessage, VkwSortableListItemConfig } from '../library';

const CHARGE_POINT_FAVORITES_SETTINGS_KEY = 'charge-point-favorites';
const URL = '/api/v1/UserSettings';

interface UserSettingsContextProps {
  chargePointFavorites: string[];
  sortableListSettings: Map<string, VkwSortableListItemConfig[]>;
  loadChargePointFavorites: () => Promise<boolean>;
  addChargePointFavorite: (evseId: string | string[]) => Promise<boolean>;
  removeChargePointFavorite: (evseId: string | string[]) => Promise<boolean>;
  loadSortableListSettings: (key: string) => Promise<boolean>;
  updateSortableListSettings: (key: string, settings: VkwSortableListItemConfig[]) => Promise<boolean>;
}

const UserSettingsContext = createContext<UserSettingsContextProps | null>(null);

interface UserSettingsContextProviderProps {
  children: ReactNode;
}

export const UserSettingsContextProvider = ({ children }: UserSettingsContextProviderProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const userContext = useUserContext();
  const handleAxiosResponse = useHandleAxiosResponse();

  const [internalChargePointFavorites, setInternalChargePointFavorites] = useState<string[]>([]);
  const [internalSortableListSettings, setInternalSortableListSettings] = useState(
    new Map<string, VkwSortableListItemConfig[]>()
  );

  const loadChargePointFavorites = useCallback(async (): Promise<boolean> => {
    if (!userContext.account) {
      return false;
    }

    return handleAxiosResponse(() => axios.get(`${URL}/${CHARGE_POINT_FAVORITES_SETTINGS_KEY}`), {
      success: (response: AxiosResponse<{ key: string; value: string }>) => {
        if (response.status === 200) {
          setInternalChargePointFavorites(JSON.parse(response.data.value));
        } else {
          setInternalChargePointFavorites([]);
        }
      },
    });
  }, [userContext.account]);

  const addChargePointFavorite = useCallback(
    (evseId: string | string[]): Promise<boolean> => {
      let tempChargePointFavorites: string[] = [];

      if (typeof evseId === 'string') {
        tempChargePointFavorites = [...internalChargePointFavorites, evseId];
      } else {
        tempChargePointFavorites = [...internalChargePointFavorites, ...evseId];
      }

      const oldValue = [...internalChargePointFavorites];

      setInternalChargePointFavorites(tempChargePointFavorites);

      return handleAxiosResponse(
        () =>
          axios.post(URL, {
            key: CHARGE_POINT_FAVORITES_SETTINGS_KEY,
            value: JSON.stringify(tempChargePointFavorites),
          }),
        {
          error: () => {
            setInternalChargePointFavorites(oldValue);
          },
        },
        { successSnackbarMessage: formatMessage('SuccessfullySaved') }
      );
    },
    [internalChargePointFavorites]
  );

  const removeChargePointFavorite = useCallback(
    (evseId: string | string[]): Promise<boolean> => {
      let tempChargePointFavorites: string[] = [];

      if (typeof evseId === 'string') {
        tempChargePointFavorites = internalChargePointFavorites.filter(value => value !== evseId);
      } else {
        tempChargePointFavorites = internalChargePointFavorites.filter(value => !evseId.includes(value));
      }

      const oldValue = [...internalChargePointFavorites];

      return handleAxiosResponse(
        () =>
          axios.post(URL, {
            key: CHARGE_POINT_FAVORITES_SETTINGS_KEY,
            value: tempChargePointFavorites.length < 1 ? JSON.stringify('') : JSON.stringify(tempChargePointFavorites),
          }),
        {
          error: () => {
            setInternalChargePointFavorites(oldValue);
          },
          success: () => {
            setInternalChargePointFavorites(tempChargePointFavorites);
          },
        },
        { successSnackbarMessage: formatMessage('SuccessfullySaved') }
      );
    },
    [internalChargePointFavorites]
  );

  const loadSortableListSettings = useCallback(async (key: string): Promise<boolean> => {
    if (!userContext.account) {
      return false;
    }

    return handleAxiosResponse(() => axios.get(`${URL}/${key}`), {
      success: (response: AxiosResponse) => {
        if (response.status === 200) {
          setInternalSortableListSettings(prevState => {
            const newMap = new Map(prevState);

            newMap.set(key, JSON.parse(response.data.value));

            return newMap;
          });
        } else {
          setInternalSortableListSettings(prevState => {
            const newMap = new Map(prevState);

            newMap.set(key, []);

            return newMap;
          });
        }
      },
    });
  }, []);

  const updateSortableListSettings = useCallback(
    async (key: string, settings: VkwSortableListItemConfig[]): Promise<boolean> => {
      if (!userContext.account) {
        return false;
      }

      const oldValue = internalSortableListSettings.get(key);

      const newMap = new Map(internalSortableListSettings);

      newMap.set(key, settings);

      setInternalSortableListSettings(newMap);

      return handleAxiosResponse(() => axios.post(URL, { key, value: JSON.stringify(settings) }), {
        error: () => {
          setInternalSortableListSettings(prevState => {
            const newMap = new Map(prevState);

            if (oldValue) {
              newMap.set(key, oldValue);
            } else {
              newMap.delete(key);
            }

            return newMap;
          });
        },
      });
    },
    []
  );

  useEffect(() => {
    loadChargePointFavorites();
  }, []);

  const value = useMemo(
    () => ({
      addChargePointFavorite,
      chargePointFavorites: internalChargePointFavorites,
      loadChargePointFavorites,
      loadSortableListSettings,
      removeChargePointFavorite,
      sortableListSettings: internalSortableListSettings,
      updateSortableListSettings,
    }),
    [
      internalChargePointFavorites,
      internalSortableListSettings,
      loadChargePointFavorites,
      addChargePointFavorite,
      removeChargePointFavorite,
      loadSortableListSettings,
      updateSortableListSettings,
    ]
  );

  return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>;
};

export const useUserSettingsContext = (): UserSettingsContextProps => {
  const config = useContext(UserSettingsContext);

  if (!config) {
    throw new Error('Use ConfigContextProvider somewhere!!');
  }

  return config;
};
