import React, { ReactElement, ReactNode, useMemo, useState } from 'react';

import { Box, Hidden } from '@material-ui/core';

import { InfrastructureChargeDetailsRecordDetail } from './InfrastructureChargeDetailsRecordDetail';
import {
  ChargeDetailRecordStatistic,
  ChargeDetailRecordSummary,
  ChargingLocationTableCell,
  Money,
  TimespanCacheEdit,
} from '../../components';
import { AccountInfo, Config, useConfigContext, useUserContext } from '../../contexts';
import { useChargeDetailRecordsDataStore, useSortableListSettings } from '../../hooks';
import {
  FormatMessage,
  useVkwFormatMessage,
  VkwBaseTableCell,
  VkwDataTable,
  VkwDataTableFieldConfig,
  VkwIconButton,
  VkwIconCharging,
  VkwIconDownload,
  VkwOverlay,
} from '../../library';

export interface InfrastructureChargingTariffContract {
  authenticationId: string;
  authenticationType: string;
  chargingContractId: string;
  contractAlias: string;
  contractId: string;
  productName: string;
  vehicleAlias: string;
  vehicleLicensePlate: string;
  vehicleType: string;
}

export interface InfrastructureChargeDetailRecord {
  id: string;
  transactionId: string;
  customerId: string;
  contractId: string;
  contractAlias: string;
  productName: string;
  chargingPointLocation: string;
  chargingPointId: string;
  chargingPointName: string;
  chargingStationOperator: string;
  chargingTariffContract?: InfrastructureChargingTariffContract;
  eMobilityServiceProvider: string;
  startTime: Date;
  endTime: Date;
  durationInSeconds: number;
  totalAmount: number;
  consumedEnergy: number;
  powerType: string;
  invoiceId: string;
  invoiceRecipientName: string;
  isShared: true;
  sharingUsers: string[];
}

const renderChargingLocation = (dataEntry: InfrastructureChargeDetailRecord): ReactNode => {
  return <ChargingLocationTableCell key="charging-location" dataEntry={dataEntry} />;
};

const getTableFieldsConfig = (
  formatMessage: FormatMessage,
  config: Config,
  accountInfo?: AccountInfo
): VkwDataTableFieldConfig<InfrastructureChargeDetailRecord>[] => [
  {
    active: true,
    label: formatMessage('ChargingPointName'),
    property: 'chargingPointName',
  },
  {
    active: true,
    converter: 'time',
    label: formatMessage('ChargingDuration'),
    property: 'durationInSeconds',
    sortColumn: 'durationInSeconds',
  },
  {
    active: true,
    converter: 'dateTime',
    label: formatMessage('ChargingStartTime'),
    property: 'startTime',
    sortColumn: 'startTime',
  },
  {
    active: true,
    converter: 'dateTime',
    label: formatMessage('ChargingEndTime'),
    property: 'endTime',
  },
  {
    active: true,
    converter: 'localeNumber',
    label: formatMessage('ConsumedEnergy'),
    property: 'consumedEnergy',
  },
  {
    active: true,
    hidden: config.hideCommission,
    label: formatMessage('CompensationNet'),
    property: 'totalAmount',
    renderCustomCell: function renderTotalAmount(dataEntry) {
      return (
        <VkwBaseTableCell key="totalAmount">
          <Money value={dataEntry.totalAmount} />
        </VkwBaseTableCell>
      );
    },
    sortColumn: 'totalAmount',
  },
  {
    active: false,
    label: formatMessage('ChargingLocation'),
    renderCustomCell: renderChargingLocation,
  },
  {
    active: false,
    label: formatMessage('ChargingPointId'),
    property: 'chargingPointId',
  },
  {
    active: false,
    label: formatMessage('InvoiceId'),
    property: 'invoiceId',
  },
  {
    active: false,
    label: formatMessage('InvoiceRecipientName'),
    property: 'invoiceRecipientName',
  },
  {
    active: false,
    label: formatMessage('ContractAlias'),
    property: 'contractAlias',
  },
  {
    active: false,
    label: formatMessage('ProductName'),
    property: 'productName',
  },
  {
    active: false,
    label: formatMessage('PowerType'),
    property: 'powerType',
  },
  {
    active: false,
    label: formatMessage('ChargingStationOperator'),
    property: 'chargingStationOperator',
  },
  {
    active: false,
    label: formatMessage('ChargingTariffProvider'),
    property: 'eMobilityServiceProvider',
  },
  {
    active: false,
    label: formatMessage('ChargingContractDesignation'),
    property: 'chargingContractAlias',
  },
  {
    active: false,
    label: formatMessage('ContractNumber'),
    property: 'contractId',
  },
];

export interface InfrastructureChargeDetailRecordsProps {
  initialFilters?: { [key: string]: string[] | number[] | Date[] };
}

const FILTER_COOKIE_KEY = 'infrastructure-charge-detail-records';

export const InfrastructureChargeDetailsRecords = ({
  initialFilters,
}: InfrastructureChargeDetailRecordsProps): ReactElement => {
  const { config } = useConfigContext();
  const formatMessage = useVkwFormatMessage();
  const user = useUserContext();

  const tableFieldsConfig = getTableFieldsConfig(formatMessage, config, user.account?.info);
  const defaultTableFieldsConfig = getTableFieldsConfig(formatMessage, config, user.account?.info);

  const store = useChargeDetailRecordsDataStore<InfrastructureChargeDetailRecord>({
    csvFileName: formatMessage('InfrastructureCDRExportFilename'),
    filterCookieKey: FILTER_COOKIE_KEY,
    url: '/api/v1/InfrastructureChargeDetailRecords',
  });

  useMemo(() => {
    setTimeout(() => {
      if (initialFilters) {
        for (const key in initialFilters) {
          if (Object.prototype.hasOwnProperty.call(initialFilters, key)) {
            store.changeFilter(key, initialFilters[key]);
          }
        }
      }
    }, 100);
  }, []);

  const [selectedChargeDetailRecord, setSelectedChargeDetailRecord] = useState<InfrastructureChargeDetailRecord | null>(
    null
  );

  const renderMobileItem = (dataEntry: InfrastructureChargeDetailRecord): ReactNode => {
    return (
      <ChargeDetailRecordSummary
        key={dataEntry.id}
        onClick={() => setSelectedChargeDetailRecord(dataEntry)}
        data={{
          amount: dataEntry.totalAmount,
          chargingPointLocation: dataEntry.chargingPointLocation,
          displayName: dataEntry.contractAlias ?? dataEntry.contractId,
          durationInSeconds: dataEntry.durationInSeconds,
          startTime: dataEntry.startTime,
        }}
      />
    );
  };

  const sortableListSettings = useSortableListSettings('infrastructure-charge-details-records-table');

  const filterOption = store.filterOptions.find(filterOption => filterOption.column === 'chargeDateRange');

  let min = new Date();
  let max = new Date();
  let values: Date[] = [];

  if (filterOption) {
    min = filterOption.configuration.min ? (filterOption.configuration.min as Date) : new Date();
    max = filterOption.configuration.max ? (filterOption.configuration.max as Date) : new Date();
    values = (store.filters.get(filterOption.column) as Date[]) ?? [];
  }

  return (
    <>
      {filterOption && (
        <TimespanCacheEdit
          maxTimespanValue={max}
          minTimespanValue={min}
          timespanValues={values}
          changeTimespan={values => store.changeFilter(filterOption.column, values)}
          cacheLocked={user.dataCacheState?.infrastructureChargeDetailRecordsLocked}
          cacheLastUpdate={user.dataCacheState?.infrastructureChargeDetailRecordsLastUpdate}
          updateCache={user.updateDataCache}
        />
      )}
      {store.initialized && store.totalRecords > 0 && store.chargeDetailRecordsStatistic && (
        <ChargeDetailRecordStatistic statistic={store.chargeDetailRecordsStatistic} totalRecords={store.totalRecords} />
      )}
      <Box marginTop={1}>
        <VkwDataTable
          initialized={store.initialized}
          loading={store.loading}
          loadAdditionalEntries={store.loadAdditionalEntries}
          page={store.page}
          pageSize={store.pageSize}
          pages={store.pages}
          data={store.data}
          totalRecords={store.totalRecords}
          hasMore={store.hasMore}
          setPage={store.setPage}
          setIsMobile={store.setIsMobile}
          tableFieldsConfig={tableFieldsConfig}
          noResultsDisplayText={formatMessage('NoResultsForChargings')}
          noResultsWithActiveFilterDisplayText={formatMessage('NoResultsWithActiveFilter')}
          renderButtons={() => (
            <VkwIconButton title={formatMessage('ExportAsCSV')} onClick={store.downloadCSV}>
              <VkwIconDownload />
            </VkwIconButton>
          )}
          renderIcon={() => <VkwIconCharging size={16} />}
          defaultFiltersCount={6}
          defaultTableFieldsConfig={defaultTableFieldsConfig}
          renderMobileItem={renderMobileItem}
          settingsProvider={sortableListSettings}
          filters={store.filters}
          filterOptions={store.filterOptions}
          onChangeFilter={store.changeFilter}
          onClearFilters={store.clearFilters}
          sortColumn={store.sortColumn}
          sortDirection={store.sortDirection}
          setSortColumn={store.setSortColumn}
        />
      </Box>
      {selectedChargeDetailRecord && (
        <Hidden smUp>
          <VkwOverlay open onClose={() => setSelectedChargeDetailRecord(null)}>
            <InfrastructureChargeDetailsRecordDetail chargeDetailRecord={selectedChargeDetailRecord} />
          </VkwOverlay>
        </Hidden>
      )}
    </>
  );
};
