import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugSchuko = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M7 10a2 2 0 1 0-.001 3.999A2 2 0 0 0 7 10zm10 0a2 2 0 1 0-.001 3.999A2 2 0 0 0 17 10zm5 8.594a11.995 11.995 0 0 1-8.938 5.319l-.062.005v-2h-2v2l-.062-.005a11.994 11.994 0 0 1-8.939-5.32v-4.635L0 13.959v-4l1.999-.001V5.325A11.994 11.994 0 0 1 11.001 0L11 1.918h2V0a11.992 11.992 0 0 1 9 5.324v4.594h2v4h-2v4.676z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </VkwIcon>
  );
};
