import React, { ReactElement } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';

import { VkwButton } from '../../components';
import { getVkwTypographyStyle } from '../../helper';
import { useVkwFormatMessage } from '../../hooks';
import { VkwSplitScreenLayout } from '../../layouts';
import { useVkwTheme, VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    text14: {
      ...getVkwTypographyStyle('text14', theme),
    },
  }),
  { index: 1 }
);

export interface VkwLogoutSuccessViewProps {
  /**
   * Übergabe der URL (Redirect) oder eines Event Handlers, für den "Zurück" Button
   */
  returnTo: string | ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void);
}

export const VkwLogoutSuccessView = ({ returnTo }: VkwLogoutSuccessViewProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const theme = useVkwTheme();
  const styles = useStyles();

  return (
    <VkwSplitScreenLayout>
      <Typography variant="h1">{formatMessage('LogoutSuccessHeadline')}</Typography>
      <Box paddingTop={4}>
        <Typography className={styles.text14}>{formatMessage('LogoutSuccessText')}</Typography>
      </Box>
      <Box paddingTop={4}>
        <VkwButton
          variant="contained"
          color={theme.palette.mainButtonColor}
          onClick={event => {
            if (typeof returnTo === 'string') {
              window.location.href = returnTo;
            } else {
              returnTo(event);
            }
          }}
        >
          {formatMessage('Back')}
        </VkwButton>
      </Box>
    </VkwSplitScreenLayout>
  );
};
