import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconDragIndicator = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g id="Drag-Indicator" fill={fill} fillRule="nonzero" transform="translate(4.000000, 0.000000)">
        <path
          d="M6,21 C6,22.65 4.65,24 3,24 C1.35,24 0,22.65 0,21 C0,19.35 1.35,18 3,18 C4.65,18 6,19.35 6,21 Z"
          id="Path"
        />
        <path
          d="M3,9 C1.35,9 0,10.35 0,12 C0,13.65 1.35,15 3,15 C4.65,15 6,13.65 6,12 C6,10.35 4.65,9 3,9 Z"
          id="Path"
        />
        <path d="M3,0 C1.35,0 0,1.35 0,3 C0,4.65 1.35,6 3,6 C4.65,6 6,4.65 6,3 C6,1.35 4.65,0 3,0 Z" id="Path" />
        <path
          d="M12,6 C13.65,6 15,4.65 15,3 C15,1.35 13.65,0 12,0 C10.35,0 9,1.35 9,3 C9,4.65 10.35,6 12,6 Z"
          id="Path"
        />
        <path
          d="M12,9 C10.35,9 9,10.35 9,12 C9,13.65 10.35,15 12,15 C13.65,15 15,13.65 15,12 C15,10.35 13.65,9 12,9 Z"
          id="Path"
        />
        <path
          d="M12,18 C10.35,18 9,19.35 9,21 C9,22.65 10.35,24 12,24 C13.65,24 15,22.65 15,21 C15,19.35 13.65,18 12,18 Z"
          id="Path"
        />
      </g>
    </VkwIcon>
  );
};
