import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { VkwTheme, VkwTypographyVariant } from '../themes';

export const getVkwTypographyStyle = (
  variant: VkwTypographyVariant,
  theme: VkwTheme
): {
  fontFamily?: CSSProperties['fontFamily'];
  fontWeight?: CSSProperties['fontWeight'];
  fontSize?: CSSProperties['fontSize'];
  lineHeight?: CSSProperties['lineHeight'];
  letterSpacing?: CSSProperties['letterSpacing'];
} => {
  return theme.typography[variant];
};
