import { moonGoogleMapLightStyles } from './moonGoogleMapStyles';
import { interRegular, interSemiBold } from '../fonts/fontConfig';
import { createVkwTheme, VkwThemeCustomOptions } from '../library/themes/createVkwTheme';

const PRIMARY = '#001C2F';
const SECONDARY = '#9DFDB4';
const WHITE = '#fff';
const BLACK = '#000';

const greyPalette = {
  50: '#f9fafa',
  100: '#f1f3f4',
  200: '#e3e6e8',
  300: '#d5dadd',
  400: '#929ea5',
  500: '#7f8d96',
  600: '#5a666d',
  700: '#23272a',
  800: '#171a1c',
  900: '#0c0d0e',
  A100: '#cecece',
  A200: '#aaaaaa',
  A400: '#303030',
  A700: '#616161',
};

const options: VkwThemeCustomOptions = {
  activeColor: PRIMARY,
  activeTab: { indicator: SECONDARY, text: PRIMARY },
  black: BLACK,
  checkedColorSwitchThumb: WHITE,
  colorPrimary: PRIMARY,
  colorSecondary: SECONDARY,
  contentBackground: WHITE,
  detailGraphColor: PRIMARY,
  errorColor: '#e30613',
  focusVisible: PRIMARY,
  fontFace: [interRegular, interSemiBold],
  globalFontFamily: 'Inter, Helvetica, Arial',
  googleMapsStyles: moonGoogleMapLightStyles,
  greyPalette,
  headerColor: 'primary',
  mainButtonColor: 'secondary',
  mainCounterColor: PRIMARY,
  mainSliderColor: 'primary',
  menuBackground: greyPalette[100],
  performanceGraphGoodColor: SECONDARY,
  primaryButton: { background: SECONDARY, text: PRIMARY },
  project: 'moon',
  successColor: SECONDARY,
  type: 'light',
  warningColor: '#ffd400',
  white: WHITE,
};

export const moonTheme = createVkwTheme(options, theme => ({
  h1: {
    fontFamily: 'Inter',
    fontSize: 42,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.18,
  },
  h2: {
    fontFamily: 'Inter',
    fontSize: 42,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.18,
  },

  h3: {
    fontFamily: 'Inter',
    fontSize: 34,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.18,
  },
  h4: {
    fontFamily: 'Inter',
    fontSize: 30,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
  },
  h5: {
    fontFamily: 'Inter',
    fontSize: 30,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.33,
  },
  h6: {
    fontFamily: 'Inter',
    fontSize: 26,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.23,
  },
  h7: {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.33,
  },
  h8: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.33,
  },
  h9: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,
  },
  h10: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.43,
  },
  link10: {
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.14,
  },
  link14: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.43,
  },
  link16: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,
  },
  link18: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.56,
  },
  text10: {
    fontFamily: 'Inter',
    fontSize: 10,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.6,
  },
  text12: {
    fontFamily: 'Inter',
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.5,
  },
  text14: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.43,
  },
  text16: {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.63,
  },
  text18: {
    fontFamily: 'Inter',
    fontSize: 18,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.56,
  },
  text20: {
    fontFamily: 'Inter',
    fontSize: 20,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.6,
  },
}));
