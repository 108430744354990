import { useEffect, useState } from 'react';

import Axios, { AxiosResponse } from 'axios';

import { useHandleAxiosResponse } from './useHandleAxiosResponse';
import { useUserContext } from '../contexts';
import { FormatMessage, useVkwFormatMessage } from '../library';

export interface Pricing {
  entries: PriceEntry[];
  validityToken?: string | null;
  loading: boolean;
}

export interface PriceEntry {
  value: number;
  label: string;
}

const createEntries = (fees: Fee[], formatMessage: FormatMessage): PriceEntry[] => {
  const entries: PriceEntry[] = [];

  fees.forEach(fee => {
    if (fee.type === 'START') {
      entries.push({
        label: formatMessage('PricingStart'),
        value: fee.grossValue,
      });
    }

    if (fee.type === 'TIME') {
      let label = '';
      if (fee.stepLength) {
        const stepLengthInMin = fee.stepLength / 60;

        if (stepLengthInMin > 1.0) {
          label += `${stepLengthInMin}/min`;
        } else {
          label += 'min';
        }
      }

      if (fee.after) {
        label += ` ab ${fee.after / 60} min`;
      }

      if (fee.grossValue > 0) {
        entries.push({
          label,
          value: fee.grossValue,
        });
      }
    }

    if (fee.type === 'ENERGY') {
      let label = 'kWh';
      if (fee.after) {
        label += ` ab ${fee.after}kWh`;
      }

      entries.push({
        label,
        value: fee.grossValue,
      });
    }

    if (fee.type === 'MANIPULATION') {
      entries.push({
        label: formatMessage('PricingServiceFee'),
        value: fee.grossValue,
      });
    }
  });

  return entries;
};

type ChargePrice = {
  evseId: string;
  fees: Fee[];
  validityToken: string;
};

type Fee = {
  type: 'ENERGY' | 'MANIPULATION' | 'START' | 'TIME';
  grossValue: number;
  netValue: number;
  after?: number;
  stepLength?: number;
};

export const usePricings = (evseId: string, contractId: string | null, evcoId: string | null | undefined): Pricing => {
  const [entries, setEntries] = useState<PriceEntry[]>([]);
  const [loading, setLoading] = useState(!!contractId);
  const [validityToken, setValidityToken] = useState<string | null>(null);
  const formatMessage = useVkwFormatMessage();
  const handleAxiosResponse = useHandleAxiosResponse();
  const userContext = useUserContext();

  useEffect(() => {
    let active = true;

    if (!contractId) {
      setEntries([]);
    } else {
      setLoading(true);

      handleAxiosResponse(
        () =>
          Axios.get('/api/v1/ChargePricings', {
            params: {
              contractId,
              customerId: userContext.selectedCustomer?.id,
              evcoId,
              evseIds: [evseId],
            },
          }),
        {
          success: (response: AxiosResponse<ChargePrice[]>) => {
            if (active) {
              const { data } = response;

              const chargePrice = data.find(entry => entry.evseId === evseId);

              if (chargePrice) {
                setEntries(createEntries(chargePrice.fees, formatMessage));
              }

              setLoading(false);
              setValidityToken(chargePrice?.validityToken ?? null);
            }
          },
        }
      );
    }

    return () => {
      active = false;
    };
  }, [contractId, evseId]);

  return {
    entries,
    loading,
    validityToken,
  };
};
