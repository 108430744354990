import React, { ReactElement } from 'react';

import { Box, ButtonBase, Grid, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { getVkwIdentificationIcon, getVkwTypographyStyle } from '../../helper';
import { VkwIconCheck } from '../../icons';
import { VkwTheme } from '../../themes';
import { VkwContractPartner } from '../../types';

const BORDER_WIDTH = 3;
const useStyles = makeStyles<VkwTheme, { selectable: boolean }>(
  theme => ({
    content: {
      display: 'flex',
      ...getVkwTypographyStyle('h10', theme),
      alignItems: 'center',
      minWidth: '0',
      overflow: 'hidden',
      padding: theme.spacing(1.125),
      textOverflow: 'ellipsis',
      width: '100%',
    },
    disabled: {
      opacity: 0.5,
    },
    entry: {
      ...getVkwTypographyStyle('text12', theme),
      color: theme.palette.grey[500],
    },
    icon: {
      alignItems: 'center',
      color: theme.palette.text.secondary,
      display: 'flex',
      justifyContent: 'flex-end',
      padding: theme.spacing(1.125, 1),
    },
    iconSelected: {
      color: theme.palette.activeColor,
    },
    item: props => ({
      borderLeft: props.selectable ? `${BORDER_WIDTH}px solid transparent` : 0,
      display: 'flex',
      width: '100%',
    }),

    itemHover: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        borderRadius: 0,
        width: '100%',
      },
      cursor: 'pointer',
    },
    items: {
      flexDirection: 'column',
      minWidth: '100%',
      paddingLeft: theme.spacing(1),
    },
    selected: props => ({
      borderLeft: props.selectable ? `${BORDER_WIDTH}px solid ${theme.palette.secondary.main}` : 0,
    }),
    textBoldSmall: {
      ...getVkwTypographyStyle('text14', theme),
      fontWeight: 'bold',
    },
  }),
  { index: 1 }
);

interface ContractPartnerListItemProps {
  data: VkwContractPartner;
  selected?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void | undefined;
  disabled?: boolean;
  selectable: boolean;
}

export const ContractPartnerListItem = ({
  data,
  disabled,
  onClick,
  selectable,
  selected,
}: ContractPartnerListItemProps): ReactElement => {
  const styles = useStyles({ selectable });

  const stylesListItem = clsx(styles.item, selected && styles.selected, !!onClick && styles.itemHover);

  return (
    <ButtonBase className={stylesListItem} onClick={e => onClick && onClick(e)} disabled={disabled}>
      <Box className={styles.content}>
        <Grid container className={clsx(styles.items, disabled && styles.disabled)}>
          <Grid item xs={12} sm={12} className={styles.item}>
            <Typography className={styles.textBoldSmall} noWrap>
              {`${data.name}`}
            </Typography>
            <Box display="flex" alignItems="center" pl={1}>
              {getVkwIdentificationIcon(data.contractPartnerPasswordRequestedState, 16)}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} className={styles.item}>
            <Typography className={styles.entry} noWrap>
              {data.number}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {selected && (
        <Box className={clsx(styles.icon, selected && styles.iconSelected)}>
          <VkwIconCheck size={12} />
        </Box>
      )}
    </ButtonBase>
  );
};
