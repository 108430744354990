import React, { ReactElement, ReactNode, useEffect } from 'react';

import { Box } from '@material-ui/core';
import { useRouter } from 'next/router';

import { RouteConfig, useRoutes } from './useRoutes';
import { useSocialLinks } from './useSocialLinks';
import { HelpWizardStep } from '../../components/rn';
import { HelpWizardSteps } from '../../components/rn/HelpWizard/helpWizardSteps';
import { AccountInfo, Customer, useConfigContext, useUserContext } from '../../contexts';
import {
  FormatMessage,
  toFirstUppercase,
  useVkwAssets,
  useVkwFormatMessage,
  VkwContractPartner,
  VkwLoader,
  VkwNavigation,
  VkwNavigationItemConfig,
  VkwUserPopover,
} from '../../library';
import { deleteFilterCookies } from '../../util/cookies';
import { CompleteProfileDialog } from '../CompleteProfileDialog';
import { NoMobilityContractDialog } from '../NoMobilityContractDialog';

function showRoute(route: RouteConfig, accountInfo: AccountInfo | null, selectedCustomer: Customer | null): boolean {
  if (!route.public && !accountInfo) {
    return false;
  }

  if (route.showForUser) {
    if (!accountInfo) {
      return false;
    }

    return route.showForUser(accountInfo, selectedCustomer);
  }

  return true;
}

type Configuration = {
  mainItems: VkwNavigationItemConfig[];
  secondaryItems: VkwNavigationItemConfig[];
  footerItems: VkwNavigationItemConfig[];
  activeRouteConfig: RouteConfig | null;
};

const getConfiguration = (
  routes: RouteConfig[],
  currentPath: string,
  accountInfo: AccountInfo | null,
  selectedCustomer: Customer | null,
  formatMessage: FormatMessage,
  onClick: (route: RouteConfig) => void
): Configuration => {
  const mainItems: VkwNavigationItemConfig[] = [];
  const secondaryItems: VkwNavigationItemConfig[] = [];
  const footerItems: VkwNavigationItemConfig[] = [];
  let activeRouteConfig: RouteConfig | null = null;

  routes.forEach(route => {
    if (currentPath === route.path || (route.path.length > 1 && currentPath.indexOf(route.path) === 0)) {
      activeRouteConfig = route;
    }

    if (!showRoute(route, accountInfo, selectedCustomer)) {
      return;
    }

    const { activeIcon, icon } = route;

    if (!icon) {
      throw new Error('Route has no icon defined');
    }

    if (!activeIcon) {
      throw new Error('Route has no activeIcon defined');
    }

    const item: VkwNavigationItemConfig = {
      activeIcon,
      icon,
      key: route.key,
      name: formatMessage(route.translationKey),
      onClick: route.type === 'next' ? () => onClick(route) : route.onClick ?? undefined,
      path: route.path,
    };

    switch (route.area) {
      case 'main':
        mainItems.push(item);
        break;
      case 'secondary':
        secondaryItems.push(item);
        break;
      case 'footer':
        footerItems.push(item);
        break;
      default:
        break;
    }
  });

  return {
    activeRouteConfig,
    footerItems,
    mainItems,
    secondaryItems,
  };
};

interface NavigationProps {
  hideNavigationBar?: boolean;
  children: ReactNode;
}

export const Navigation = ({ children, hideNavigationBar }: NavigationProps): ReactElement => {
  const routes = useRoutes();
  const configContext = useConfigContext();
  const { envUrls, featureFlags } = configContext;
  const filteredRoutes = routes.filter(route => {
    if (!featureFlags.enableTicketSystem && (route.key === 'support' || route.key === 'support-cca')) {
      return false;
    }

    if (!featureFlags.enableCookieConsentManager && route.key === 'cookie-settings') {
      return false;
    }

    return !(!featureFlags.enableInvoices && route.key === 'invoices');
  });
  const socialLinks = useSocialLinks();
  const userContext = useUserContext();
  const router = useRouter();
  const formatMessage = useVkwFormatMessage();
  const assets = useVkwAssets();

  // TODO: Remove when unnecessary
  const isLagEvnOrEbm = assets.project === 'lag' || assets.project === 'evn' || assets.project === 'ebm';

  const selectedContractPartner: VkwContractPartner | null = userContext.selectedCustomer
    ? {
        contractPartnerPasswordRequestedState: userContext.selectedCustomer.passwordRequestedState,
        identifiedProductCategories: userContext.selectedCustomer.identifiedProductCategories,
        name: userContext.selectedCustomer.displayText,
        number: userContext.selectedCustomer.id,
      }
    : null;

  const renderMenuHeader = (): ReactNode => {
    if (!hideNavigationBar) {
      return (
        <Box display="flex">
          {/* <UserPanel />*/}

          <HelpWizardStep step={HelpWizardSteps.avatar} rounded>
            <VkwUserPopover
              login="/api/next/auth/login"
              logout="/api/next/auth/logout"
              email={userContext.account?.emailAddress}
              profileUrl={envUrls.profileUrl || undefined}
              backofficeUrl={
                userContext.account?.info.seesBackofficeLink && configContext.backofficeUrl && !isLagEvnOrEbm
                  ? configContext.backofficeUrl
                  : undefined
              }
              addContractPartner={envUrls.addCustomerUrl && !isLagEvnOrEbm ? envUrls.addCustomerUrl : undefined}
              onSelectContractPartner={newSelectedContractPartner => {
                userContext.updateSelectedCustomer({
                  displayText: newSelectedContractPartner.name,
                  id: newSelectedContractPartner.number,
                  identifiedProductCategories: newSelectedContractPartner.identifiedProductCategories,
                  passwordRequestedState: newSelectedContractPartner.contractPartnerPasswordRequestedState,
                });

                if (router.route !== '/') {
                  router.push('/');
                }

                deleteFilterCookies();
              }}
              selectedContractPartner={selectedContractPartner}
              contractPartners={userContext.customers.map(customer => ({
                contractPartnerPasswordRequestedState: customer.passwordRequestedState,
                identifiedProductCategories: customer.identifiedProductCategories,
                name: customer.displayText,
                number: customer.id,
              }))}
              userPanelLinks={configContext.userPanelLinks}
            />
          </HelpWizardStep>
        </Box>
      );
    }

    return null;
  };

  const configuration = getConfiguration(
    filteredRoutes,
    router.pathname,
    userContext.account?.info || null,
    userContext.selectedCustomer,
    formatMessage,
    (route: RouteConfig) => router.push(route.path)
  );

  const { activeRouteConfig } = configuration;

  const privateRouteNoUser = activeRouteConfig && !activeRouteConfig.public && !userContext.account;

  const privateRouteWrongUser =
    activeRouteConfig &&
    !activeRouteConfig.public &&
    userContext.account &&
    !activeRouteConfig.showForUser?.(userContext.account.info, userContext.selectedCustomer);

  useEffect(() => {
    if (privateRouteNoUser) {
      window.location.href = `/auth/signin?returnTo=${encodeURIComponent(router.asPath)}`;
    }

    if (privateRouteWrongUser) {
      router.push('/403');
    }
  }, [privateRouteNoUser, privateRouteWrongUser]);

  return (
    <>
      <VkwNavigation
        activeRoute={configuration.activeRouteConfig ? configuration.activeRouteConfig.key : null}
        mainItems={configuration.mainItems}
        secondaryItems={configuration.secondaryItems}
        footerItems={configuration.footerItems}
        renderHeader={renderMenuHeader}
        copyright={formatMessage(`Project_${toFirstUppercase(assets.project)}_Publisher`)}
        socialLinks={socialLinks}
        hideMenu={hideNavigationBar}
      >
        {privateRouteNoUser || privateRouteWrongUser ? <VkwLoader /> : children}
      </VkwNavigation>
      {userContext.showCompleteProfileDialog && <CompleteProfileDialog />}
      {userContext.showNoMobilityContractDialog && <NoMobilityContractDialog />}
    </>
  );
};
