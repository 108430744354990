import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const IconCookie = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 560 560">
        <g fill={fill}>
          <path
            fillRule="evenodd"
            d="m280 560c-75.9 0-147-29.7-200.2-83.8-52.7-53.6-81-124.4-79.8-199.4 0.1-5.6 2.2-10.9 5.8-15 3.1-3.6 7.5-6.4 12.1-7.5 20.6-5.2 38.1-19.2 47.9-38.3 5.4-10.5 17.9-15.3 29-11.1 51.5 19.6 100.6-19.7 100.6-69.3l-0.4-4.1-0.5-3.9c-0.7-6.5 1.3-12.9 5.6-17.8 4.2-4.9 10.3-7.8 16.8-8 33.4-1.2 62.1-24.6 69.8-57 5.9-24.9 31.5-40.3 57.2-34.4 127.2 29.6 216.1 141.1 216.1 271 0 153.6-125.6 278.6-280 278.6zm-233-266.4c2.9 56.4 26 109.2 66.1 149.9 44.3 45.1 103.6 69.8 166.9 69.8 128.7 0 233.3-104 233.3-231.9 0-108.1-74-200.9-179.9-225.5l-1.6 0.3c-10.4 44.6-46 78.9-90.1 89.1-5 62.3-57.2 111.4-120.7 111.4q-12 0-24.1-2.6c-12.8 17.4-30.1 31-49.9 39.5z"
          />
          <path d="m373.3 198.3c0 19.4-15.6 35-35 35-19.3 0-35-15.6-35-35 0-19.3 15.7-35 35-35 19.4 0 35 15.7 35 35z" />
          <path d="m210 338.3c0 19.4-15.7 35-35 35-19.3 0-35-15.6-35-35 0-19.3 15.7-35 35-35 19.3 0 35 15.7 35 35z" />
          <path d="m396.7 385c0 19.3-15.7 35-35 35-19.4 0-35-15.7-35-35 0-19.3 15.6-35 35-35 19.3 0 35 15.7 35 35z" />
          <path d="m303.3 280c0 12.9-10.4 23.3-23.3 23.3-12.9 0-23.3-10.4-23.3-23.3 0-12.9 10.4-23.3 23.3-23.3 12.9 0 23.3 10.4 23.3 23.3z" />
          <path d="m256.7 420c0 12.9-10.5 23.3-23.4 23.3-12.9 0-23.3-10.4-23.3-23.3 0-12.9 10.4-23.3 23.3-23.3 12.9 0 23.4 10.4 23.4 23.3z" />
          <path d="m233.3 23.3c0 12.9-10.4 23.4-23.3 23.4-12.9 0-23.3-10.5-23.3-23.4 0-12.9 10.4-23.3 23.3-23.3 12.9 0 23.3 10.4 23.3 23.3z" />
          <path d="m140 128.3c0 19.4-15.7 35-35 35-19.3 0-35-15.6-35-35 0-19.3 15.7-35 35-35 19.3 0 35 15.7 35 35z" />
          <path d="m443.3 280c0 12.9-10.4 23.3-23.3 23.3-12.9 0-23.3-10.4-23.3-23.3 0-12.9 10.4-23.3 23.3-23.3 12.9 0 23.3 10.4 23.3 23.3z" />
        </g>
      </svg>
    </VkwIcon>
  );
};
