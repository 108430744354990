import { ReactNode } from 'react';

import { PrimitiveType, FormatXMLElementFn } from 'intl-messageformat';
import { useIntl } from 'react-intl';

type FormatMessageString = (
  id: string,
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
) => string;

type FormatMessageNode = (id: string, values?: Record<string, PrimitiveType | ReactNode | JSX.Element>) => ReactNode;

export type FormatMessage = FormatMessageString & FormatMessageNode;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useVkwFormatMessage = () => {
  const intl = useIntl();

  function formatMessage(
    id: string,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>
  ): string;

  // eslint-disable-next-line no-redeclare
  function formatMessage<T>(
    id: string,
    values?: Record<string, PrimitiveType | T | FormatXMLElementFn<T>>
  ): string | T | (T | string)[];

  // eslint-disable-next-line no-redeclare
  function formatMessage(id: string, values?: Record<string, PrimitiveType | ReactNode | JSX.Element>): ReactNode;
  // eslint-disable-next-line no-redeclare
  function formatMessage(id: string, values: any): any {
    return intl.formatMessage({ id }, values);
  }

  return formatMessage;
};
