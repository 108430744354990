import React, { createContext, ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { MessageFormatElement } from '@formatjs/icu-messageformat-parser';
import { CustomFormats, IntlProvider } from 'react-intl';

import { vkwDateIo } from '../../helper';
import messages from '../../messages';

const formats: CustomFormats = {
  number: {
    EUR: {
      currency: 'EUR',
      style: 'currency',
    },
  },
};

export type SupportedLocale = 'de' | 'en' | 'it';
export type Messages = Record<string, string> | Record<string, MessageFormatElement[]>;

export interface VkwIntlContextProps {
  locale: SupportedLocale;
  setLocale: (locale: SupportedLocale) => void;
  messages?: { [key in SupportedLocale]?: Messages };
}

const VkwIntlContext = createContext<VkwIntlContextProps | null>(null);

const mergeMessages = (
  locale: SupportedLocale,
  baseMessages: { [key in SupportedLocale]?: Messages },
  additionalMessages?: { [key in SupportedLocale]?: Messages }
): Messages | undefined => {
  const addons = additionalMessages?.[locale] ? additionalMessages[locale] : {};

  return Object.assign(baseMessages[locale] ?? {}, addons);
};

export interface VkwIntlContextProviderProps {
  initLocale: SupportedLocale;
  additionalMessages?: { [key in SupportedLocale]?: Messages };
  children: ReactNode;
}

export const VkwIntlContextProvider = ({
  additionalMessages,
  children,
  initLocale,
}: VkwIntlContextProviderProps): ReactElement => {
  const [locale, setLocale] = useState(initLocale);
  const [actualMessages, setActualMessages] = useState<Messages | undefined>(
    mergeMessages(initLocale, messages, additionalMessages)
  );

  useEffect(() => {
    setActualMessages(mergeMessages(locale, messages, additionalMessages));
  }, [locale, additionalMessages]);

  const value = useMemo(
    () => ({
      locale,
      messages: actualMessages,
      setLocale,
    }),
    [locale, actualMessages]
  );

  vkwDateIo.locale = locale;

  return (
    <VkwIntlContext.Provider value={value}>
      <IntlProvider locale={locale} messages={actualMessages} formats={formats}>
        {children}
      </IntlProvider>
    </VkwIntlContext.Provider>
  );
};

export const useVkwIntl = (): VkwIntlContextProps => {
  const intl = useContext(VkwIntlContext);

  if (!intl) {
    throw new Error('Use "VkwIntlContextProvider" somewhere');
  }

  return intl;
};
