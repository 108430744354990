import React, { ReactElement, ReactNode } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { VkwHeader } from '../../components';
import { useVkwNavigation } from '../../contexts';
import { VkwTheme } from '../../themes';
import { VkwBaseLayout } from '../VkwBaseLayout';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(8),
      },

      width: '100%',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    footerLeft: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    footerRight: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  }),
  { index: 1 }
);

export interface VkwDefaultLayoutProps {
  /**
   * Inhalt des Layouts
   */
  children: ReactNode;
  /**
   * Wird eine abweichende Überschrift benötigt kann, über dieses render prop, eine übergeben werden.
   */
  renderHeader?: () => ReactNode;
}

export const VkwDefaultLayout = ({ children, renderHeader }: VkwDefaultLayoutProps): ReactElement => {
  const styles = useStyles();
  const navigation = useVkwNavigation();

  const renderDefaultHeader = () => {
    if (!navigation.activeRoute) {
      return null;
    }

    return <VkwHeader title={navigation.activeRoute.name} />;
  };

  return (
    <VkwBaseLayout>
      <Box className={styles.container}>
        <Box className={styles.content}>
          {renderHeader ? renderHeader() : renderDefaultHeader()}
          {children}
        </Box>
        <Box className={styles.footer}>
          <Box className={styles.footerRight}>
            {navigation.renderSocialLinks()}
            {navigation.renderCopyright()}
          </Box>
          <Box className={styles.footerLeft}>{navigation.renderFooter()}</Box>
        </Box>
      </Box>
    </VkwBaseLayout>
  );
};
