// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { ReactElement, ReactNode, RefObject, useEffect, useMemo, useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import Slider, { Settings } from 'react-slick';

import { useStyles } from './VkwSwiperStyles';
import { useVkwWindowSize } from '../../hooks';
import { VkwIconArrowLeft, VkwIconArrowRight } from '../../icons';
import { VkwIconButton } from '../VkwIconButton';

export interface VkwSwiperProps {
  /**
   * Übergabe der Elemente
   */
  elements: ReactNode[];
  /**
   * Soll der Swiper die maximal mögliche Anzahl Elemente anzeigen, mit dem aktiven Element in der Mitte?
   */
  showAllElements?: boolean;
  /**
   * Soll nach dem letzen Element im Swiper wieder das erste Element dargestellt werden (Endlosschleife)?
   */
  infinite?: boolean;
  /**
   * Sollen die Punkte, für die Darstellung und Bedienung der aktuellen Position, im Swiper dargestellt werden.
   */
  dots?: boolean;
  /**
   * Sollen die Pfeile links und rechts für die Bedienung des Swiper dargestellt werden?
   */
  arrows?: boolean;
  /**
   * Welches Element soll als erstes als aktives Element angezeigt werden?
   */
  initialSlide?: number;
  /**
   * Nach Abschluss der Positionsänderung des Swipers wird diese Methode aufgerufen.
   */
  afterChange?: (index: number) => void;
}

const NextArrow = (props: any): ReactElement => {
  const { className, onClick, style } = props;
  return (
    <div className={className} style={style}>
      <VkwIconButton onClick={onClick} disabled={className.includes('slick-disabled')}>
        <VkwIconArrowRight />
      </VkwIconButton>
    </div>
  );
};

const PrevArrow = (props: any): ReactElement => {
  const { className, onClick, style } = props;
  return (
    <div className={className} style={style}>
      <VkwIconButton onClick={onClick} disabled={className.includes('slick-disabled')}>
        <VkwIconArrowLeft />
      </VkwIconButton>
    </div>
  );
};

export const VkwSwiper = ({
  afterChange = () => {
    /* do nothing */
  },
  arrows = true,
  dots = true,
  elements,
  infinite = false,
  initialSlide = 0,
  showAllElements = false,
}: VkwSwiperProps): ReactElement => {
  const styles = useStyles();
  const windowSize = useVkwWindowSize();

  const [swiperElements, setSwiperElements] = useState([]);

  const swiperRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  const elementsRef = [];
  for (let i = 0; i < elements.length; i += 1) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    elementsRef.push(useRef<HTMLDivElement>(null));
  }

  const sliderSettings = useMemo(
    (): Settings => ({
      arrows,
      centerMode: showAllElements,
      dots,
      dotsClass: styles.sliderDots,
      focusOnSelect: true,
      infinite,
      initialSlide,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      slidesToScroll: 1,
      slidesToShow: 1,
      variableWidth: showAllElements,
    }),
    [styles, showAllElements]
  );

  useEffect(() => {
    setSwiperElements([]);
  }, [windowSize]);

  useEffect(() => {
    if (swiperRef.current && elementsRef.every(e => e.current)) {
      // Consider the margin for the swiper arrows as defined in .slick-slider
      const swiperWidth = swiperRef.current.offsetWidth - 50;

      let currentSwipeWidth = 0;
      let currentSwipeElements = [];
      const tempSwiperElements = [];

      if (showAllElements) {
        let totalLengthOfElements = 0;

        for (let i = 0; i < elementsRef.length; i += 1) {
          totalLengthOfElements += elementsRef[i].current?.offsetWidth as number;
        }

        if (totalLengthOfElements > swiperRef.current.offsetWidth) {
          elements.forEach((value, index) => {
            tempSwiperElements.push(
              <Box className={styles.elementWrapper} key={index}>
                <div ref={elementsRef[index]}>
                  <Box className={styles.element}>{value}</Box>
                </div>
              </Box>
            );
          });
        } else {
          tempSwiperElements.push(
            <Box className={styles.elementWrapper}>
              <Box display="flex" justifyContent="center">
                {elements.map((item, index) => (
                  <div ref={elementsRef[index]} key={index} className={styles.element}>
                    <Box>{item}</Box>
                  </div>
                ))}
              </Box>
            </Box>
          );
        }
      } else {
        for (let i = 0; i < elements.length; i += 1) {
          currentSwipeWidth += elementsRef[i].current?.offsetWidth as number;
          // Leave some space for padding
          currentSwipeWidth += 10;

          if (currentSwipeWidth < swiperWidth) {
            currentSwipeElements.push(
              <div ref={elementsRef[i]} key={i}>
                <Box className={styles.element}>{elements[i]}</Box>
              </div>
            );
          } else {
            tempSwiperElements.push(
              <Box className={styles.elementWrapper} key={i}>
                <Box display="flex" justifyContent="center">
                  {currentSwipeElements}
                </Box>
              </Box>
            );

            currentSwipeElements = [elements[i]];
            currentSwipeWidth = elementsRef[i].current?.offsetWidth as number;
            currentSwipeWidth += 10;
          }
        }

        // Add leftover elements
        tempSwiperElements.push(
          <Box className={styles.elementWrapper} key="leftover">
            <Box display="flex" justifyContent="center">
              {currentSwipeElements}
            </Box>
          </Box>
        );
      }

      setSwiperElements(tempSwiperElements);
    }
  }, [swiperElements.length === 0, elements]);

  if (sliderSettings.initialSlide && sliderSettings.initialSlide > swiperElements.length && swiperElements.length > 0) {
    sliderSettings.initialSlide = swiperElements.length - 1;
  }

  return (
    <div ref={swiperRef}>
      {swiperElements.length <= 1 ? (
        <Box display="flex" marginBottom="30px">
          {elements.map((item, index) => (
            <div ref={elementsRef[index]} key={index} className={styles.element}>
              <Box>{item}</Box>
            </div>
          ))}
        </Box>
      ) : (
        <Slider {...sliderSettings} afterChange={afterChange}>
          {swiperElements}
        </Slider>
      )}
    </div>
  );
};
