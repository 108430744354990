import { makeStyles } from '@material-ui/core';

import { getVkwTypographyStyle } from '../../helper/getVkwTypographyStyle';
import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    button: {
      '&&': {
        backgroundColor: theme.palette.type === 'light' ? 'transparent' : theme.palette.grey[700],
        border:
          theme.palette.type === 'light'
            ? `1px solid ${theme.palette.grey[200]}`
            : `2px solid ${theme.palette.grey[700]}`,
        borderRadius: 20,
        color: theme.palette.text.primary,
        fontWeight: 'normal',
      },
      '&:hover': {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[600],
        borderColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[600],
      },
    },
    buttonActive: {
      '&&': {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
        borderColor: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.common.white,
        fontWeight: 'bold',
      },
    },
    buttonOpen: {
      '&&': {
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
      },
    },
    filterContainer: {
      color: theme.palette.text.primary,
      width: '100%',
      ...getVkwTypographyStyle('text14', theme),

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 'calc(100vh - 32px)',
        maxWidth: '100%',
        padding: theme.spacing(1),
        width: 'auto',
      },
    },
    footer: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
    },
    header: {
      ...getVkwTypographyStyle('text12', theme),
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
    },
    menu: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),

      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 1,
        overflowY: 'hidden',
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      },
    },
    showDesktop: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    showMobile: {
      [theme.breakpoints.up('xs')]: {
        display: 'none',
      },
    },
    spacer: {
      width: theme.spacing(2),
    },
  }),
  { index: 1 }
);

export default useStyles;
