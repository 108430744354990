import React, { ReactElement, ReactNode, useState, MouseEvent } from 'react';

import { Popover, Grid, Button, Box } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './VkwBaseFilterStyles';
import { useVkwFormatMessage } from '../../hooks';
import { VkwIconArrowDown, VkwIconArrowUp, VkwIconCheck } from '../../icons';
import { useVkwTheme } from '../../themes';
import { VkwAccordion } from '../VkwAccordion';
import { VkwButton } from '../VkwButton';

export interface VkwBaseFilterProps {
  title: string;
  /**
   * Render Prop um das Popover mit der Filteransicht zu befüllen
   */
  renderMenu: () => ReactNode;
  /**
   * Umschaltung von Desktopansicht des Filters zur mobilen Ansicht als Accordion z.B. in einer Sidebar
   */
  mode: 'mobile' | 'desktop';
  active: boolean;
  /**
   * Render Prop um dem Popover für die Filteransicht eine zusätzliche Überschrift zu geben
   */
  renderAdditionalHeader?: () => ReactNode;
  onSave?: () => void;
  onReset?: () => void;
  onClose?: () => void;
}

export const VkwBaseFilter = ({
  active,
  mode,
  onClose,
  onReset,
  onSave,
  renderAdditionalHeader,
  renderMenu,
  title,
}: VkwBaseFilterProps): ReactElement => {
  const styles = useStyles();
  const vkwTheme = useVkwTheme();
  const formatMessage = useVkwFormatMessage();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleButtonClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const itemClass = clsx(styles.button, {
    [styles.buttonActive]: active,
    [styles.buttonOpen]: open,
  });

  const renderFilter = (): ReactNode => {
    return (
      <div className={styles.filterContainer}>
        {renderAdditionalHeader && <div className={styles.header}>{renderAdditionalHeader()}</div>}
        <div className={styles.menu}>{renderMenu()}</div>
        <div className={styles.footer}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <VkwButton onClick={onReset} color="default" fullWidth variant="contained" disabled={!onReset}>
                {formatMessage('Reset')}
              </VkwButton>
            </Grid>
            <Grid item xs={6}>
              <VkwButton
                onClick={() => {
                  setAnchorEl(null);
                  onSave?.();
                }}
                fullWidth
                variant="contained"
                color={vkwTheme.palette.mainButtonColor}
                disabled={!onSave}
              >
                {formatMessage('Save')}
              </VkwButton>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  if (mode === 'desktop') {
    return (
      <>
        <Button variant="outlined" color="primary" onClick={handleButtonClick} className={itemClass}>
          {title}
          <span className={styles.spacer} />
          {open ? <VkwIconArrowUp size={12} /> : <VkwIconArrowDown size={12} />}
        </Button>
        <span className={styles.spacer} />
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            onClose?.();
          }}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          className={styles.showDesktop}
        >
          {renderFilter()}
        </Popover>
      </>
    );
  }

  if (mode === 'mobile') {
    const renderMobileTitle = (): ReactNode => {
      return (
        <>
          {active ? <strong>{title}</strong> : title}
          {active && (
            <Box paddingX={1} component="span">
              <VkwIconCheck size={16} />
            </Box>
          )}
        </>
      );
    };

    return <VkwAccordion title={renderMobileTitle()}>{renderFilter()}</VkwAccordion>;
  }

  throw new Error('Unexpected mode given');
};
