import React, { createContext, ReactNode, useContext, useMemo, useState } from 'react';

export type StepReference = {
  ref: React.RefObject<any>;
  position: number;
  title: string;
  path: string;
};

type HelpWizardContextType = {
  addWizardStep: (stepRef: StepReference) => void;
  resetWizardStep: (path: string, position?: number) => void;
  wizardStepRefs: (path: string) => StepReference[];
  wizardOpen: boolean;
  setWizardOpen: (isOpen: boolean) => void;
};

const HelpWizardContext = createContext<HelpWizardContextType | null>(null);

/**
 * Retrieves the HelpWizard context from the HelpWizardProvider.
 * Throws an error if used outside of the provider.
 *
 * @returns {*} The HelpWizard context.
 * @throws {Error} If used outside of the HelpWizardProvider.
 */
export const useHelpWizardContext = () => {
  const context = useContext(HelpWizardContext);
  if (!context) {
    throw new Error('useHelpWizardContext must be used within a HelpWizardProvider');
  }
  return context;
};

/**
 * HelpWizardProvider component provides a context for managing step references
 * and resetting styles of referenced elements.
 *
 * @param {Object} props - Component props.
 * @param {ReactNode} props.children - The child elements to be rendered.
 * @returns {ReactElement} - The rendered component.
 */
export const HelpWizardProvider = ({ children }: { children: ReactNode }) => {
  const [refs, setRefs] = useState<StepReference[]>([]);
  const [wizardOpen, setWizardOpen] = useState(false);

  /**
   * Adds a StepReference to the existing list of references, if it meets the
   * given conditions. If the StepReference already exists in the list, it will
   * not be added again.
   *
   * @param {StepReference} stepRef - The StepReference to be added.
   */
  const addWizardStep = (stepRef: StepReference) => {
    if (
      refs.some(
        step =>
          step.ref !== null &&
          step.path === stepRef.path &&
          step.position === stepRef.position &&
          step.title === stepRef.title
      )
    ) {
      return;
    }

    setRefs(previousRefs =>
      [...previousRefs, stepRef].sort((a, b) => {
        if (a.position !== b.position) {
          return a.path < b.path ? -1 : 1;
        }
        return a.position - b.position;
      })
    );
  };

  /**
   * Resets the style of elements referenced by a given path and position (if provided).
   *
   * @param {string} path - The path of the elements to reset.
   * @param {number} [position] - The optional position of the elements to reset.
   * @returns {void}
   */
  const resetWizardStep = (path: string, position?: number) => {
    const elementsToReset = position
      ? refs.filter(r => r.path === path && r.position === position)
      : refs.filter(r => r.path === path);

    elementsToReset.map(element => {
      if (!element.ref.current) {
        return null;
      }

      const refElement = element.ref.current;
      refElement.style.zIndex = 1;
      refElement.style.backgroundColor = 'unset';
      return refElement;
    });
  };

  /**
   * Filters and sorts an array of references based on the provided path.
   *
   * @param {string} path - The path to filter references by.
   * @return {Array<Object>} - The filtered and sorted array of references.
   */
  const wizardStepRefs = (path: string) => {
    return refs.filter(r => r.path === path).sort((a, b) => a.position - b.position);
  };

  const contextValue: HelpWizardContextType = useMemo(
    () => ({
      addWizardStep,
      resetWizardStep,
      setWizardOpen,
      wizardOpen,
      wizardStepRefs,
    }),
    [addWizardStep, resetWizardStep, wizardStepRefs, wizardOpen, setWizardOpen]
  );

  return <HelpWizardContext.Provider value={contextValue}>{children}</HelpWizardContext.Provider>;
};
