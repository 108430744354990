import React, { ReactElement } from 'react';

import { makeStyles, Box, Link } from '@material-ui/core';
import clsx from 'clsx';

import { VkwNavigationItemConfig } from './VkwNavigationItemConfig';
import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    item: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      alignItems: 'center',
      color: theme.palette.grey[500],
      display: 'flex',
      flexWrap: 'nowrap',
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),

      paddingTop: theme.spacing(1),
    },
    itemActive: {
      color: theme.palette.activeColor,
    },
    itemDescription: {
      color: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[200],
      fontSize: theme.typography.pxToRem(14),
    },
    itemName: {
      color: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.grey[200],
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 'bold',
    },
  }),
  { index: 1 }
);

interface NavigationMenuItemProps {
  active: boolean;
  item: VkwNavigationItemConfig;
  onClose: () => void;
}

export const NavigationMenuItem = ({ active, item, onClose }: NavigationMenuItemProps): ReactElement => {
  const styles = useStyles();

  const itemClass = clsx(styles.item, {
    [styles.itemActive]: active,
  });

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!item.onClick) {
      return;
    }

    event.preventDefault();
    item.onClick();
    onClose();
  };

  const Icon = active ? item.activeIcon : item.icon;

  return (
    <Link
      key={item.key}
      onClick={item.onClick ? handleClick : undefined}
      target={item.onClick ? undefined : '_blank'}
      href={item.path}
      underline="none"
      title={item.name}
      className={itemClass}
    >
      <Icon size={20} />
      <Box display="flex" flexDirection="column" marginLeft={2}>
        <span className={styles.itemName}>{item.name}</span>
        {item.description && <span className={styles.itemDescription}>{item.description}</span>}
      </Box>
    </Link>
  );
};
