import React, { ReactElement } from 'react';

interface ClusterIconProps {
  count: number;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const ClusterOverlayView = ({ count, onClick }: ClusterIconProps): ReactElement => {
  return (
    <div
      onClick={onClick}
      className="group relative z-0 flex h-10 w-10 items-center justify-center rounded-full bg-gray-700 ring-[8px] ring-gray-700/20 transition ease-in hover:z-20 hover:scale-125 hover:bg-gray-800 dark:bg-gray-500 dark:ring-gray-600/20 hover:dark:bg-gray-600"
      role="presentation"
    >
      <span className="text-[15px] font-medium text-white">{count}</span>
      <span className="absolute -z-10 h-10 w-10 rounded-full bg-gray-800/50 group-hover:animate-ping dark:bg-gray-600/50" />
      <span className="absolute -z-10 h-10 w-10 rounded-full ring-[4px] ring-gray-700/40 dark:ring-gray-600/40" />
    </div>
  );
};
