import { makeStyles } from '@material-ui/core';

import { getVkwTypographyStyle } from '../../helper';
import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    footer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(1.5, 0),
    },
    headerCell: {
      borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
      padding: theme.spacing(1, 1.5),
      ...getVkwTypographyStyle('text12', theme),

      [theme.breakpoints.down('xs')]: {
        '&:first-child': {
          paddingLeft: 0,
        },

        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    headerRow: {},
    mobileContainer: {
      borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
      padding: theme.spacing(1, 0),

      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    noBorderBottomToolbar: {
      borderBottom: 'none',
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1, 1.5),
      },

      width: '100%',
    },
    pagination: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
    },
    root: {
      [theme.breakpoints.up('sm')]: {
        backgroundColor: theme.palette.type === 'light' ? 'transparent' : theme.palette.grey[700],
        border: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
        borderRadius: 2,
      },
    },
    tableBody: {},
    tableCell: {
      borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
      padding: theme.spacing(1, 1.5),

      [theme.breakpoints.down('xs')]: {
        '&:first-child': {
          paddingLeft: 0,
        },

        '&:last-child': {
          paddingRight: 0,
        },
      },
    },
    tableCellBold: {
      ...getVkwTypographyStyle('link14', theme),
    },
    tableCellCollapse: {
      border: 'none',
      margin: 0,
      minHeight: 0,
      padding: 0,
    },
    tableCellDefault: {
      ...getVkwTypographyStyle('text14', theme),
    },
    tableCellInner: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      minHeight: '34px',
      whiteSpace: 'nowrap',
    },
    tableContainer: {
      overflowX: 'auto',
    },
    tableHeadCellInner: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      minHeight: '34px',
      whiteSpace: 'nowrap',
    },
    toolbar: {
      borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
      padding: 0,

      [theme.breakpoints.down('xs')]: {
        backgroundColor: `${theme.palette.contentBackground}`,
        position: 'sticky',
        top: 0,
        zIndex: 3,
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1, 1.5),
      },
    },
    toolbarTitle: {
      flexGrow: 1,
      ...getVkwTypographyStyle('link14', theme),
    },
  }),
  { index: 1 }
);

export default useStyles;
