import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { FormattedDate, FormattedNumber } from 'react-intl';

import { TariffCard } from '../../components';
import { ChargingTariffContract, ChargingTariffContractSelfServiceOperation } from '../../contexts';
import {
  dataSectionDatastring,
  useVkwFormatMessage,
  VkwDataSection,
  VkwDataSectionItem,
  VkwTextField,
} from '../../library';

const MAX_LENGTH = 30;

interface TariffDetailProps {
  contractData: ChargingTariffContract;
  onSave: (contractData: ChargingTariffContract) => Promise<boolean>;
}

export const TariffDetail = ({ contractData, onSave }: TariffDetailProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();

  const [editVehicleData, setEditVehicleData] = useState(false);
  const [newVehicleAlias, setNewVehicleAlias] = useState('');

  const [editAlias, setEditAlias] = useState(false);
  const [newContractAlias, setNewContractAlias] = useState('');

  const [editChargingCardId, setEditChargingCardId] = useState(false);
  const [newChargingCardId, setNewChargingCardId] = useState('');

  const allowedToChangeChargingCardIdentification = contractData.allowedSelfServiceOperations?.includes(
    ChargingTariffContractSelfServiceOperation.CHANGE_IDENTIFICATION
  );

  useEffect(() => {
    setNewVehicleAlias(contractData.vehicleAlias ?? '');
  }, [contractData.vehicleAlias]);

  useEffect(() => {
    setNewContractAlias(contractData.contractAlias ?? '');
  }, [contractData.contractAlias]);

  useEffect(() => {
    setNewChargingCardId(contractData.authenticationCardUid ?? '');
  }, [contractData.authenticationCardUid]);

  const productDataItems: VkwDataSectionItem[] = useMemo(() => {
    const productDataItems: VkwDataSectionItem[] = [];

    if (editAlias) {
      productDataItems.push({
        lg: 12,
        md: 12,
        renderContent: function vehicleAliasInput() {
          return (
            <VkwTextField
              name="newContractAlias"
              value={newContractAlias}
              label={formatMessage('TariffAlias')}
              onChange={event => {
                if (event.target.value.length > MAX_LENGTH) {
                  return;
                }

                setNewContractAlias(event.target.value);
              }}
              maxLength={MAX_LENGTH}
              fullWidth
            />
          );
        },
        sm: 12,
        xs: 12,
      });
    } else {
      productDataItems.push({
        lg: 12,
        md: 12,
        renderContent: () => dataSectionDatastring(contractData.contractAlias),
        sm: 12,
        title: formatMessage('ChargingContractDesignation'),
        xs: 12,
      });
    }

    productDataItems.push({
      renderContent: () => dataSectionDatastring(contractData.productName),
      title: formatMessage('ProductName'),
    });

    productDataItems.push({
      renderContent: function creationDate() {
        if (contractData.productMonthlyFee) {
          return <FormattedNumber value={contractData.productMonthlyFee} currency="EUR" />;
        }

        return '-';
      },
      title: formatMessage('MonthlyFee'),
    });

    return productDataItems;
  }, [contractData, editAlias, newContractAlias]);

  const chargingCardDataItems: VkwDataSectionItem[] = useMemo(() => {
    const chargingCardDataItems: VkwDataSectionItem[] = [];

    if (editChargingCardId && allowedToChangeChargingCardIdentification) {
      chargingCardDataItems.push({
        renderContent: function vehicleAliasInput() {
          return (
            <VkwTextField
              name="newChargingCardId"
              value={newChargingCardId}
              label={formatMessage('ChargingContractUid')}
              onChange={event => {
                if (event.target.value.length > MAX_LENGTH) {
                  return;
                }

                setNewChargingCardId(event.target.value);
              }}
              maxLength={MAX_LENGTH}
              fullWidth
            />
          );
        },
      });
    } else {
      chargingCardDataItems.push({
        renderContent: () => dataSectionDatastring(contractData.authenticationCardUid),
        title: formatMessage('ChargingContractUid'),
      });
    }

    chargingCardDataItems.push({
      renderContent: () => dataSectionDatastring(contractData.authenticationEvcoId),
      title: formatMessage('EvcoId'),
    });

    return chargingCardDataItems;
  }, [contractData, editChargingCardId, newChargingCardId]);

  const vehicleDataItems: VkwDataSectionItem[] = useMemo(() => {
    const vehicleDataItems: VkwDataSectionItem[] = [];

    if (editVehicleData) {
      vehicleDataItems.push({
        renderContent: function vehicleAliasInput() {
          return (
            <VkwTextField
              name="vehicleAlias"
              value={newVehicleAlias}
              label={formatMessage('VehicleAlias')}
              onChange={event => {
                if (event.target.value.length > MAX_LENGTH) {
                  return;
                }

                setNewVehicleAlias(event.target.value);
              }}
              maxLength={MAX_LENGTH}
              fullWidth
            />
          );
        },
      });
    } else {
      vehicleDataItems.push({
        renderContent: () => dataSectionDatastring(newVehicleAlias),
        title: formatMessage('VehicleAlias'),
      });
    }

    vehicleDataItems.push({
      renderContent: () => dataSectionDatastring(contractData.vehicleLicensePlate),
      title: formatMessage('VehicleLicensePlate'),
    });

    vehicleDataItems.push({
      renderContent: () => formatMessage('NotAvailable'),
      title: formatMessage('PlugAndCharge'),
    });

    return vehicleDataItems;
  }, [contractData, editVehicleData, newVehicleAlias]);

  const contractDataItems: VkwDataSectionItem[] = useMemo(() => {
    const contractDataItems: VkwDataSectionItem[] = [];

    contractDataItems.push({
      renderContent: () => dataSectionDatastring(contractData.contractId),
      title: formatMessage('ContractNumber'),
    });

    contractDataItems.push({
      renderContent: function validToDate() {
        if (contractData.contractValidTo) {
          return <FormattedDate value={contractData.contractValidTo} year="numeric" month="2-digit" day="2-digit" />;
        }

        return '-';
      },
      title: formatMessage('ValidTo'),
    });

    contractDataItems.push({
      renderContent: () => dataSectionDatastring(contractData.contractInvoiceRecipientName),
      title: formatMessage('InvoiceRecipientName'),
    });

    contractDataItems.push({
      renderContent: () => dataSectionDatastring(contractData.contractIban),
      title: formatMessage('BankDetails'),
    });

    return contractDataItems;
  }, [contractData]);

  const handleSaveEditAlias = async (): Promise<void> => {
    const newContractData = {
      ...contractData,
      contractAlias: newContractAlias.length > 0 ? newContractAlias : null,
    };

    const saved = await onSave(newContractData);
    if (saved) {
      setEditAlias(false);
    }
  };

  const handleSaveEditChargingCardId = async (): Promise<void> => {
    const newContractData = {
      ...contractData,
      authenticationCardUid: newChargingCardId.length > 0 ? newChargingCardId : null,
    };

    const saved = await onSave(newContractData);
    if (saved) {
      setEditChargingCardId(false);
    }
  };

  const handleSaveEditVehicleData = async (): Promise<void> => {
    const newContractData = {
      ...contractData,
      vehicleAlias: newVehicleAlias.length > 0 ? newVehicleAlias : null,
    };

    const saved = await onSave(newContractData);
    if (saved) {
      setEditVehicleData(false);
    }
  };

  return (
    <>
      <TariffCard
        contractAlias={contractData.contractAlias ?? contractData.productName}
        vehicleType={contractData.vehicleType}
        vehicleLicensePlate={contractData.vehicleLicensePlate}
        contractId={contractData.contractId}
        authenticationCardUid={contractData.authenticationCardUid}
        cancellationDate={contractData.contractValidTo}
      />
      <VkwDataSection
        rootBoxProps={{
          paddingTop: 4,
        }}
        headline={formatMessage('ProductData')}
        items={productDataItems}
        ctaLabel={formatMessage('FormEdit', { section: formatMessage('Designation') })}
        editMode={editAlias}
        onCtaClick={() => setEditAlias(true)}
        onCancelClick={() => setEditAlias(false)}
        onSaveClick={handleSaveEditAlias}
      />
      <VkwDataSection
        rootBoxProps={{
          paddingTop: 4,
        }}
        headline={formatMessage('ChargingContract')}
        items={chargingCardDataItems}
        ctaLabel={
          allowedToChangeChargingCardIdentification
            ? formatMessage('FormEdit', { section: formatMessage('ChargingContract') })
            : undefined
        }
        editMode={allowedToChangeChargingCardIdentification ? editChargingCardId : undefined}
        onCtaClick={() => setEditChargingCardId(true)}
        onCancelClick={() => setEditChargingCardId(false)}
        onSaveClick={handleSaveEditChargingCardId}
      />
      <VkwDataSection
        rootBoxProps={{
          paddingTop: 4,
        }}
        headline={formatMessage('VehicleData')}
        items={vehicleDataItems}
        ctaLabel={formatMessage('FormEdit', { section: formatMessage('VehicleData') })}
        editMode={editVehicleData}
        onCtaClick={() => setEditVehicleData(true)}
        onCancelClick={() => setEditVehicleData(false)}
        onSaveClick={handleSaveEditVehicleData}
      />
      <VkwDataSection
        rootBoxProps={{
          paddingTop: 4,
        }}
        headline={formatMessage('ContractData')}
        items={contractDataItems}
      />
    </>
  );
};
