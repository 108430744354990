import React, { ReactElement } from 'react';

import { ChargeDetailRecordsStatistic } from '../../hooks';
import { timeConvert, useVkwFormatMessage, VkwSwiper } from '../../library';
import { StatisticTile } from '../StatisticTile';

interface ChargeDetailRecordStatisticProps {
  totalRecords: number;
  statistic: ChargeDetailRecordsStatistic;
}

export const ChargeDetailRecordStatistic = ({
  statistic,
  totalRecords,
}: ChargeDetailRecordStatisticProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();

  return (
    <VkwSwiper
      infinite
      showAllElements
      elements={[
        <StatisticTile key="totalRecords" title={formatMessage('TotalChargeDetailRecords')} value={totalRecords} />,
        <StatisticTile
          key="totalEnergy"
          title={formatMessage('TotalConsumedEnergy')}
          value={Math.round(statistic.consumedEnergy ?? 0)}
        />,
        <StatisticTile
          key="totalCo2Saving"
          title={formatMessage('TotalCo2Saving')}
          value={Math.round(statistic.co2Saving ?? 0)}
        />,
        <StatisticTile
          key="totalDuration"
          title={formatMessage('TotalDurationInHours')}
          value={timeConvert(statistic.totalDurationInSeconds ?? 0, false)}
        />,
      ]}
    />
  );
};
