import { useCallback, useEffect, useState } from 'react';

import Axios from 'axios';

import { useHandleAxiosResponse } from './useHandleAxiosResponse';
import { useUserContext } from '../contexts';

const PAGE_SIZE = 100;

interface AllEntriesLoader<T> {
  entries: T[];
  loading: boolean;
  load: () => void;
  clear: () => void;
}

export const useAllEntriesLoader = <T>(url: string): AllEntriesLoader<T> => {
  const [entries, setEntries] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const handleAxiosResponse = useHandleAxiosResponse();
  const userContext = useUserContext();

  const load = useCallback(() => {
    setLoading(true);
  }, []);

  const clear = useCallback(() => {
    setEntries([]);
  }, []);

  useEffect(() => {
    if (loading) {
      handleAxiosResponse(
        () =>
          Axios.get(url, {
            params: {
              customerId: userContext.selectedCustomer?.id,
              page,
              pageSize: PAGE_SIZE,
            },
          }),
        {
          success: response => {
            setEntries(page === 1 ? response.data.items : [...entries, ...response.data.items]);
            const pages = Math.ceil(response.data.total / response.data.pageSize);
            if (page < pages) {
              setPage(page + 1);
            } else {
              setLoading(false);
            }
          },
        }
      );
    }
  }, [loading, page]);

  return {
    clear,
    entries,
    load,
    loading,
  };
};
