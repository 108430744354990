import React, { ReactElement } from 'react';

import * as AvailableIcons from '.';
import { VkwIconSize } from './VkwIcon';

export type VkwIconNames = Exclude<keyof typeof AvailableIcons, 'VkwDynamicIcon' | 'VkwIcon'>;

export interface VkwDynamicIconProps {
  size: VkwIconSize;
  type: VkwIconNames;
}

export const VkwDynamicIcon = ({ size, type }: VkwDynamicIconProps): ReactElement => {
  const Icon = AvailableIcons[type];
  return <Icon size={size} />;
};
