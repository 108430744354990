import { JWT } from 'next-auth/jwt';

import { JwtUserPayload } from '@e-vo/types';

import { Customer } from '../contexts';
import { TicketSubject, TicketSubjectCca } from '../views';

const harmonizeUrl = (url: string): string => {
  if (url.endsWith('/') || url.includes('?') || url.match(/(.+)\.(htm|html|pdf)$/gm)) {
    return url;
  }

  return `${url}/`;
};

const errorMessage = (variable: string): string => `Set env Variable "${variable}" properly`;

const convertIdentificationLevelToPasswordRequestedStatus = (identificationLevel?: string) => {
  switch (identificationLevel) {
    case 'i2':
      return 'Completed';
    case 'i0':
    case 'i1':
    default:
      return 'RequestAllowed';
  }
};

export const getEnvProject = (): string => {
  if (!process.env.PROJECT) {
    throw new Error(errorMessage('PROJECT'));
  }

  return process.env.PROJECT;
};

export interface EnvUrls {
  baseUrl: string;
  backofficeUrl: string | null;
  addCustomerUrl: string | null;
  profileUrl: string | null;
  impressumUrl: string | null;
  privacyPolicyUrl: string | null;
  termsOfUseUrl: string | null;
  corporateWebsiteUrl: string;
  facebookUrl: string | null;
  twitterUrl: string | null;
  instagramUrl: string | null;
  priceInfoUrl: string | null;
}

export const getEnvUrls = (): EnvUrls => {
  if (!process.env.BASE_URL) {
    throw new Error(errorMessage('BASE_URL'));
  }

  if (!process.env.CORPORATE_WEBSITE_URL) {
    throw new Error(errorMessage('CORPORATE_WEBSITE_URL'));
  }

  return {
    addCustomerUrl: process.env.ADD_CUSTOMER_URL ? harmonizeUrl(process.env.ADD_CUSTOMER_URL) : null,
    backofficeUrl: process.env.BACKOFFICE_URL ? harmonizeUrl(process.env.BACKOFFICE_URL) : null,
    baseUrl: harmonizeUrl(process.env.BASE_URL),
    corporateWebsiteUrl: harmonizeUrl(process.env.CORPORATE_WEBSITE_URL),
    facebookUrl: process.env.FACEBOOK_URL ? harmonizeUrl(process.env.FACEBOOK_URL) : null,
    impressumUrl: process.env.IMPRESSUM_URL ? harmonizeUrl(process.env.IMPRESSUM_URL) : null,
    instagramUrl: process.env.INSTAGRAM_URL ? harmonizeUrl(process.env.INSTAGRAM_URL) : null,
    priceInfoUrl: process.env.PRICEINFO_URL ? harmonizeUrl(process.env.PRICEINFO_URL) : null,
    privacyPolicyUrl: process.env.PRIVACY_POLICY_URL ? harmonizeUrl(process.env.PRIVACY_POLICY_URL) : null,
    profileUrl: process.env.PROFILE_URL ? harmonizeUrl(process.env.PROFILE_URL) : null,
    termsOfUseUrl: process.env.TERMS_OF_USE_URL ? harmonizeUrl(process.env.TERMS_OF_USE_URL) : null,
    twitterUrl: process.env.TWITTER_URL ? harmonizeUrl(process.env.TWITTER_URL) : null,
  };
};

export const getEnvWebapiBaseUrl = (): string => {
  if (!process.env.WEBAPI_BASE_URL) {
    throw new Error(errorMessage('WEBAPI_BASE_URL'));
  }

  return process.env.WEBAPI_BASE_URL;
};

export const getEnvApiUrl = (): string => {
  if (!process.env.API_URL) {
    throw new Error(errorMessage('API_URL'));
  }

  return process.env.API_URL;
};

export const getSupportMailToAddress = (subject?: TicketSubject): string | undefined => {
  const supportMailToAddresses: { [key: string]: string } | undefined = process.env.SUPPORT_MAIL_TO_ADDRESSES
    ? JSON.parse(process.env.SUPPORT_MAIL_TO_ADDRESSES)
    : undefined;

  if (subject) {
    return supportMailToAddresses
      ? supportMailToAddresses[subject] ?? supportMailToAddresses.Default ?? process.env.SUPPORT_MAIL_TO_ADDRESS
      : process.env.SUPPORT_MAIL_TO_ADDRESS;
  }

  return process.env.SUPPORT_MAIL_TO_ADDRESS ?? supportMailToAddresses?.Default ?? undefined;
};

export const getSupportCcaMailToAddress = (subject?: TicketSubjectCca): string | undefined => {
  const supportCcaMailToAddresses: { [key: string]: string } | undefined = process.env.SUPPORT_CCA_MAIL_TO_ADDRESSES
    ? JSON.parse(process.env.SUPPORT_CCA_MAIL_TO_ADDRESSES)
    : undefined;

  if (subject) {
    return supportCcaMailToAddresses
      ? supportCcaMailToAddresses[subject] ??
          supportCcaMailToAddresses.Default ??
          process.env.SUPPORT_CCA_MAIL_TO_ADDRESS
      : process.env.SUPPORT_CCA_MAIL_TO_ADDRESS;
  }

  return process.env.SUPPORT_CCA_MAIL_TO_ADDRESS ?? supportCcaMailToAddresses?.Default ?? undefined;
};

export interface EnvFeatureFlags {
  enableApiMock: boolean;
  enableCookieConsentManager: boolean;
  enableDynamicMarkerIcons: boolean;
  enableInvoices: boolean;
  enableStartChargingSessions: boolean;
  enableTicketSystem: boolean;
}

export const getEnvFeatureFlags = (): EnvFeatureFlags => {
  const featureFlags: EnvFeatureFlags = {
    enableApiMock: false,
    enableCookieConsentManager: true,
    enableDynamicMarkerIcons: false,
    enableInvoices: false,
    enableStartChargingSessions: false,
    enableTicketSystem: true,
  };

  if (process.env.ENABLE_API_MOCK === 'true') {
    featureFlags.enableApiMock = true;
  }

  if (process.env.ENABLE_COOKIE_CONSENT_MANAGER === 'false') {
    featureFlags.enableCookieConsentManager = false;
  }

  if (process.env.ENABLE_DYNAMIC_MARKER_ICONS === 'true') {
    featureFlags.enableDynamicMarkerIcons = true;
  }

  if (process.env.ENABLE_INVOICES === 'true') {
    featureFlags.enableInvoices = true;
  }

  if (process.env.ENABLE_START_CHARGING_SESSIONS === 'true') {
    featureFlags.enableStartChargingSessions = true;
  }

  if (process.env.ENABLE_TICKETSYSTEM === 'false' || !getSupportMailToAddress()) {
    featureFlags.enableTicketSystem = false;
  }

  return featureFlags;
};

export const getEnvGTMKey = (): string | null => {
  const gtmKey = process.env.GTM_KEY;

  return gtmKey ?? null;
};

export interface UserPanelLinks {
  title?: string | null;
  links: UserPanelLink[];
}

export interface UserPanelLink {
  id: string;
  url: string;
  displayText: string;
  openInTab?: boolean;
}

export interface EnvUserPanelLinks {
  title?: string;
  links: EnvUserPanelLink[];
}

export interface EnvUserPanelLink {
  id: string;
  url: string;
  displayText: string;
  openInTab?: boolean;
}

export const getUserPanelLinks = (): UserPanelLinks | null => {
  if (!process.env.USER_PANEL_LINKS) {
    return null;
  }

  try {
    const envUserPanelLinks: EnvUserPanelLinks = JSON.parse(process.env.USER_PANEL_LINKS);

    const userPanelLinks: UserPanelLinks = { links: [], title: envUserPanelLinks.title ?? null };

    userPanelLinks.links.push(
      ...envUserPanelLinks.links.map(link => ({
        displayText: link.displayText,
        id: link.id,
        openInTab: link.openInTab ?? true,
        url: link.url,
      }))
    );

    return userPanelLinks;
  } catch {
    // eslint-disable-next-line no-console
    console.log('error', errorMessage('USER_NAVIGATION_LINKS'));

    return null;
  }
};

export const getEnvRolesClaimName = (): string => {
  if (process.env.ROLES_CLAIM_NAME) {
    return process.env.ROLES_CLAIM_NAME;
  }

  return 'roles';
};

export const getEnvDebugMode = (): boolean => {
  return process.env.DEBUG === '*';
};

export const getEnvGoogleMapsApiKey = (): string => {
  if (!process.env.GOOGLE_MAPS_API_KEY) {
    throw new Error(errorMessage('GOOGLE_MAPS_API_KEY'));
  }
  return process.env.GOOGLE_MAPS_API_KEY;
};

export const getCustomersFromToken = (token?: JWT | JwtUserPayload | null): Customer[] | null => {
  if (token && token.customerNumbers) {
    const claims = ([] as any[]).concat(token.customerNumbers);
    const customerNumbers: Customer[] = [];
    claims.forEach((item: Customer) => {
      customerNumbers.push({
        displayText: item.displayText,
        id: item.id,
        identifiedProductCategories: item.identifiedProductCategories,
        passwordRequestedState: convertIdentificationLevelToPasswordRequestedStatus(item.identificationLevel),
      });
    });

    return customerNumbers;
  }

  return null;
};
