import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconLocked = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 16 16">
        <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons-/-16-/-Locked" fill={fill}>
            <path
              d="M8,0 C5.8,0 4,1.8 4,4 L4,5.5 C2.8,6.6 2,8.2 2,10 C2,13.3 4.7,16 8,16 C11.3,16 14,13.3 14,10 C14,8.2 13.2,6.6 12,5.5 L12,4 C12,1.8 10.2,0 8,0 Z M9,10.7 L9,13 L7,13 L7,10.7 C6.4,10.4 6,9.7 6,9 C6,7.9 6.9,7 8,7 C9.1,7 10,7.9 10,9 C10,9.7 9.6,10.4 9,10.7 Z M10,4.4 C9.4,4.1 8.7,4 8,4 C7.3,4 6.6,4.1 6,4.4 L6,4 C6,2.9 6.9,2 8,2 C9.1,2 10,2.9 10,4 L10,4.4 Z"
              id="Shape"
            />
          </g>
        </g>
      </svg>
    </VkwIcon>
  );
};
