import React, { createContext, ReactElement, ReactNode, useContext, useMemo, useState } from 'react';

import { SupportedLocale } from '../library';
import { EnvFeatureFlags, EnvUrls, UserPanelLinks } from '../util/helpers';

export interface Config {
  chargeAtlasDefaultPositionLat: number;
  chargeAtlasDefaultPositionLng: number;
  currency: string;
  hideCommission: boolean;
  hidePrice: boolean;
  locale: SupportedLocale;
  ticketAcceptedMimeTypes: string[];
  operatorTokens: string[];
  operatorStationsFilterAvailable: boolean;
}

interface ConfigContextProps {
  envUrls: EnvUrls;
  userPanelLinks: UserPanelLinks | null;
  config: Config;
  featureFlags: EnvFeatureFlags;
  chargeAtlasDefaultPosition: google.maps.LatLngLiteral;
  googleMapsApiKey: string;
  backofficeUrl: string | null;
}

const ConfigContext = createContext<ConfigContextProps | null>(null);

interface ConfigContextProviderProps {
  envUrls: EnvUrls;
  userPanelLinks: UserPanelLinks | null;
  config: Config;
  featureFlags: EnvFeatureFlags;
  children: ReactNode;
  googleMapsApiKey: string;
  backofficeUrl: string | null;
}

export const ConfigContextProvider = ({
  backofficeUrl,
  children,
  config,
  envUrls,
  featureFlags,
  googleMapsApiKey,
  userPanelLinks,
}: ConfigContextProviderProps): ReactElement => {
  const [internalEnvUrls] = useState(envUrls);
  const [internalUserPanelLinks] = useState(userPanelLinks);
  const [internalConfig] = useState(config);
  const [internalFeatureFlags] = useState(featureFlags);
  const [internalGoogleMapsApiKey] = useState(googleMapsApiKey);
  const [internalBackofficeUrl] = useState(backofficeUrl);

  const value = useMemo(
    () => ({
      backofficeUrl: internalBackofficeUrl,
      chargeAtlasDefaultPosition: {
        lat: internalConfig.chargeAtlasDefaultPositionLat,
        lng: internalConfig.chargeAtlasDefaultPositionLng,
      },
      config: internalConfig,
      envUrls: internalEnvUrls,
      featureFlags: internalFeatureFlags,
      googleMapsApiKey: internalGoogleMapsApiKey,
      userPanelLinks: internalUserPanelLinks,
    }),
    [
      internalEnvUrls,
      internalUserPanelLinks,
      internalConfig,
      internalFeatureFlags,
      internalGoogleMapsApiKey,
      internalConfig.chargeAtlasDefaultPositionLat,
      internalConfig.chargeAtlasDefaultPositionLng,
      internalBackofficeUrl,
    ]
  );

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

export const useConfigContext = (): ConfigContextProps => {
  const config = useContext(ConfigContext);

  if (!config) {
    throw new Error('Use ConfigContextProvider somewhere!!');
  }

  return config;
};
