import { makeStyles } from '@material-ui/core';

import { getVkwTypographyStyle } from '../../helper';
import { VkwTheme } from '../../themes';

export const useStyles = makeStyles<VkwTheme>(
  theme => ({
    avatarButton: {
      minHeight: 48,
      minWidth: 48,
      ...getVkwTypographyStyle('text20', theme),
      backgroundColor: theme.palette.grey[500],
      boxShadow: theme.shadows[5],
      color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.background.paper,
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        minHeight: 40,
        minWidth: 40,
        ...getVkwTypographyStyle('text18', theme),
      },
    },
    content: {
      alignContent: 'center',
      background: theme.palette.background.default,
      border: theme.palette.type === 'dark' ? `1px solid ${theme.palette.grey[700]}` : `none`,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(3),
      position: 'relative',
    },
    data: {
      '&::-webkit-scrollbar': {
        height: '194px',
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.grey[400],
        borderRadius: '4px',
      },
      marginTop: theme.spacing(1),
      maxHeight: '240px',
      overflow: 'auto',
    },
    email: {
      paddingTop: theme.spacing(2),
      textTransform: 'lowercase',
      ...getVkwTypographyStyle('link16', theme),
    },
    entry: {
      ...getVkwTypographyStyle('text12', theme),
      color: theme.palette.grey[500],
    },
    infoText: {
      color: theme.palette.grey[400],
      ...getVkwTypographyStyle('h10', theme),
    },
    innerInput: {
      marginRight: theme.spacing(3),
    },
    input: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(2.25),
    },
    inputOverride: {
      color: theme.palette.text.primary,
      ...getVkwTypographyStyle('text16', theme),
    },
    item: {
      display: 'flex',
    },
    items: {
      flexDirection: 'column',
      minWidth: '100%',
      paddingLeft: theme.spacing(3),
    },
    letterIcon: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey[500],
      borderRadius: '50%',
      color: theme.palette.type === 'dark' ? theme.palette.text.secondary : theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      height: 64,
      justifyContent: 'center',
      textTransform: 'uppercase',
      width: 64,
      ...getVkwTypographyStyle('h4', theme),
    },
    noCustomerNumberText: {
      ...getVkwTypographyStyle('text16', theme),
    },
    noCustomerNumberTextBox: {
      marginBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    textBoldSmall: {
      ...getVkwTypographyStyle('h9', theme),
      fontWeight: 'bold',
    },
    textTitle: {
      ...getVkwTypographyStyle('h10', theme),
      fontWeight: 'bold',
    },
    userPopoverBackdrop: {
      zIndex: theme.zIndex.modal - 1,
    },
    wrapper: {
      marginTop: '5px',
      maxWidth: '100%',
      width: 360,
    },
  }),
  { index: 1 }
);
