import axios from 'axios';

const ISO_8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

const reviveDateTime = (_key: unknown, value: unknown): unknown => {
  if (typeof value === 'string' && ISO_8601.test(value)) {
    return new Date(Date.parse(value));
  }

  return value;
};

export const configureVkwAxios = (baseURL?: string): void => {
  axios.defaults.transformResponse = (data, headers) => {
    let transformedData = data;

    if (
      headers &&
      headers['content-type'] &&
      headers['content-type'].indexOf('application/json') > -1 &&
      typeof data === 'string'
    ) {
      try {
        transformedData = JSON.parse(data, reviveDateTime);
      } catch (e) {
        throw Error('Unexpected response');
      }
    }

    return transformedData;
  };

  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = baseURL;

  axios.defaults.validateStatus = undefined;
  axios.defaults.headers.common.accept = 'application/*, text/plain';
};
