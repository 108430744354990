import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugUnknown = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path d="M9.5,1 L9.5,6.5 L14.5,6.5 L14.5,1 L17.5,1 L17.5,6.5 L20,6.47881458 L20,12.7119552 C20,16.1789869 17.2476369,19.078897 13.5999366,19.7455373 L13.6,23 L10.4,23 L10.3991214,19.7453651 C6.84653946,19.0957063 4.14343284,16.3276868 4.00552747,12.9806785 L4,12.7119552 L4,6.47881458 L6.5,6.5 L6.5,1 L9.5,1 Z M12,16.6019344 C11.33733,16.6019344 10.8,17.1393126 10.8,17.8021762 C10.8,18.4650397 11.33733,19.002418 12,19.002418 C12.66267,19.002418 13.2,18.4650397 13.2,17.8021762 C13.2,17.1393126 12.66267,16.6019344 12,16.6019344 Z M11.9741718,7 C10.538122,7 9.09368571,7.71388082 8.74933472,9.17317598 C8.63695003,9.62013084 8.95477359,10.1495465 9.40915987,10.259773 C9.86354615,10.3699905 10.3583778,10.0618401 10.468651,9.60406432 C10.5677125,9.18417675 11.1123575,8.79849047 11.9741718,8.79849047 C12.8724761,8.79849047 13.4611352,9.38614723 13.4611352,9.96001556 C13.4611352,11.2112344 13.1484268,11.361828 12.5596785,11.9083892 C11.9709599,12.4549505 11.0819937,13.4106983 11.0819937,15.0932161 C11.0754511,15.5682873 11.5028044,16.0018135 11.9741718,16.0018135 C12.4455392,16.0018135 12.8730114,15.5682873 12.8663498,15.0932161 C12.8663498,13.9128368 13.167103,13.7868526 13.7678363,13.2291407 C14.3685397,12.6714887 15.2733867,11.69299 15.2733867,9.96001556 C15.2733867,8.25633552 13.7021125,7 11.9741718,7 Z" />
      </g>
    </VkwIcon>
  );
};
