import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

import { Box, FormControl, FormControlLabel, makeStyles, Slider, Switch, Typography } from '@material-ui/core';

import { Plug } from '@e-vo/types';

import { MIN_CHARGE_POWER_STEPS } from './const';
import { useConfigContext, useUserContext } from '../../contexts';
import { ChargeAtlasStore } from '../../hooks';
import {
  getVkwTypographyStyle,
  toPascalCase,
  useVkwFormatMessage,
  useVkwTheme,
  VkwButton,
  VkwDrawer,
  VkwTheme,
} from '../../library';
import { getPlugIcon } from '../../util/getPlugIcon';

const PLUGS = [
  'CCS_COMBO_2_PLUG_WITH_CABLE',
  'CHADEMO',
  'TYPE_F_SCHUKO',
  'TYPE_1_CONNECTOR_WITH_CABLE',
  'TYPE_2_OUTLET',
  'TYPE_2_CONNECTOR_WITH_CABLE',
];

const useStyles = makeStyles<VkwTheme>(theme => ({
  filterLabelText: {
    paddingRight: theme.spacing(2),
    ...getVkwTypographyStyle('h10', theme),
  },
  h9: {
    flexGrow: 1,
    ...getVkwTypographyStyle('h9', theme),
  },
  label: {
    color: theme.palette.grey[500],
    flexGrow: 1,
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(1.5),
  },
  plugFilterElementAll: {
    borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
  },
  plugsFilterLabelText: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    ...getVkwTypographyStyle('h10', theme),
  },
  section: {
    borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
    display: 'flex',

    flexDirection: 'column',
  },
  sectionHeader: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(3),
  },
  sliderMarks: {
    display: 'none',
  },
}));

interface FilterSectionProps {
  headerId: string;
  children: ReactNode;
}

const FilterSection = ({ children, headerId }: FilterSectionProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();
  return (
    <Box className={styles.section}>
      <Box className={styles.sectionHeader}>
        <Typography color="textPrimary">{formatMessage(headerId)}</Typography>
      </Box>
      <Box className={styles.sectionContent}>{children}</Box>
    </Box>
  );
};

interface FilterSidebarProps {
  onClose: () => void;
  store: ChargeAtlasStore;
}

export const FilterSidebar = ({ onClose, store }: FilterSidebarProps): ReactElement => {
  const styles = useStyles();
  const theme = useVkwTheme();
  const formatMessage = useVkwFormatMessage();
  const userContext = useUserContext();
  const configContext = useConfigContext();

  const [plugsFilter, setPlugsFilter] = useState<string[]>([...store.plugsFilter]);
  const [minChargePowerFilter, setMinChargePowerFilter] = useState(store.minChargePowerFilter);
  const [chargeStationsFilter, setChargeStationsFilter] = useState(store.chargeStationsFilter);

  useEffect(() => {
    setPlugsFilter([...store.plugsFilter]);
    setMinChargePowerFilter(store.minChargePowerFilter);
    setChargeStationsFilter(store.chargeStationsFilter);
  }, []);

  const handlePlugFilterChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const name = event.target.value;
    const { checked } = event.target;

    if (name === 'all') {
      setPlugsFilter([]);

      return;
    }

    setPlugsFilter(oldValue => {
      if (checked && !oldValue.includes(name)) {
        const newValue = [...oldValue];

        newValue.push(name);

        return newValue;
      }

      return oldValue.filter(value => value !== name);
    });
  };

  const handleApply = (): void => {
    store.setPlugsFilter(plugsFilter);
    store.setMinChargePowerFilter(minChargePowerFilter);
    store.setChargeStationsFilter(chargeStationsFilter);
    onClose();
  };

  const handleReset = (): void => {
    setPlugsFilter([]);
    setMinChargePowerFilter(MIN_CHARGE_POWER_STEPS[0].value);
    setChargeStationsFilter('allStations');
  };

  return (
    <VkwDrawer open onClose={onClose} headerText={formatMessage('Filter')}>
      <Box minWidth="330px">
        <FilterSection headerId="Plugs">
          <FormControl component="fieldset" fullWidth>
            <FormControlLabel
              control={
                <Switch
                  checked={plugsFilter.length === 0}
                  onChange={handlePlugFilterChange}
                  value="all"
                  color={theme.palette.mainButtonColor}
                />
              }
              label={
                <Box display="flex">
                  <Box height="24px" width="24px" />
                  <Typography color="textPrimary" className={styles.plugsFilterLabelText}>
                    {formatMessage('All')}
                  </Typography>
                </Box>
              }
              labelPlacement="start"
              classes={{ label: styles.label, root: styles.plugFilterElementAll }}
            />
            {PLUGS.map(PLUG => {
              const PlugIcon = getPlugIcon(PLUG as Plug);
              return (
                <FormControlLabel
                  className={styles.plugElement}
                  key={PLUG}
                  control={
                    <Switch
                      checked={plugsFilter.includes(PLUG)}
                      onChange={handlePlugFilterChange}
                      value={PLUG}
                      color={theme.palette.mainButtonColor}
                    />
                  }
                  label={
                    <Box display="flex">
                      <PlugIcon size={24} />
                      <Typography color="textPrimary" className={styles.plugsFilterLabelText}>
                        {formatMessage(`Plug${toPascalCase(PLUG)}`)}
                      </Typography>
                    </Box>
                  }
                  labelPlacement="start"
                  classes={{ label: styles.label }}
                />
              );
            })}
          </FormControl>
        </FilterSection>
        <FilterSection headerId="MinChargePower">
          <Slider
            track="inverted"
            value={
              MIN_CHARGE_POWER_STEPS.find(el => el.scaledValue === minChargePowerFilter)?.value ??
              MIN_CHARGE_POWER_STEPS[0].value
            }
            onChange={(_event, value) =>
              setMinChargePowerFilter(
                MIN_CHARGE_POWER_STEPS.find(el => el.value === value)?.scaledValue ??
                  (typeof value !== 'number' ? value[0] : value)
              )
            }
            step={null}
            marks={MIN_CHARGE_POWER_STEPS}
            max={MIN_CHARGE_POWER_STEPS[MIN_CHARGE_POWER_STEPS.length - 1].value}
            min={MIN_CHARGE_POWER_STEPS[0].value}
            color={theme.palette.mainSliderColor}
          />
        </FilterSection>
        <FilterSection headerId="AdditionalFilters">
          <FormControl component="fieldset" fullWidth>
            {userContext.account && (
              <FormControlLabel
                control={
                  <Switch
                    checked={chargeStationsFilter === 'operatorStations'}
                    onChange={(_event, checked) =>
                      setChargeStationsFilter(checked ? 'operatorStations' : 'allStations')
                    }
                    value="OperatorChargeStations"
                    color={theme.palette.mainButtonColor}
                  />
                }
                label={
                  <Box display="flex">
                    <Typography color="textPrimary" className={styles.filterLabelText}>
                      {formatMessage('OperatorChargeStations')}
                    </Typography>
                  </Box>
                }
                labelPlacement="start"
                classes={{ label: styles.label }}
                disabled={
                  !userContext.account?.info.hasChargingTariffContract &&
                  !configContext.config.operatorStationsFilterAvailable
                }
              />
            )}
            {userContext.account && (
              <FormControlLabel
                control={
                  <Switch
                    checked={chargeStationsFilter === 'ownStations'}
                    onChange={(_event, checked) => setChargeStationsFilter(checked ? 'ownStations' : 'allStations')}
                    value="OwnChargeStations"
                    color={theme.palette.mainButtonColor}
                  />
                }
                label={
                  <Box display="flex">
                    <Typography color="textPrimary" className={styles.filterLabelText}>
                      {formatMessage('OwnChargeStations')}
                    </Typography>
                  </Box>
                }
                labelPlacement="start"
                classes={{ label: styles.label }}
                disabled={!userContext.account?.info.hasInfrastructureContract}
              />
            )}
            {userContext.account && (
              <FormControlLabel
                control={
                  <Switch
                    checked={chargeStationsFilter === 'favoriteStations'}
                    onChange={(_event, checked) =>
                      setChargeStationsFilter(checked ? 'favoriteStations' : 'allStations')
                    }
                    value="Favorites"
                    color={theme.palette.mainButtonColor}
                  />
                }
                label={
                  <Box display="flex">
                    <Typography color="textPrimary" className={styles.filterLabelText}>
                      {formatMessage('Favorites')}
                    </Typography>
                  </Box>
                }
                labelPlacement="start"
                classes={{ label: styles.label }}
              />
            )}
            <FormControlLabel
              control={
                <Switch
                  checked={chargeStationsFilter === 'isNationalCalibrationLawCompliant'}
                  onChange={(_event, checked) =>
                    setChargeStationsFilter(checked ? 'isNationalCalibrationLawCompliant' : 'allStations')
                  }
                  value="IsNationalCalibrationLawCompliant"
                  color={theme.palette.mainButtonColor}
                />
              }
              label={
                <Box display="flex">
                  <Typography color="textPrimary" className={styles.filterLabelText}>
                    {formatMessage('NationalCalibrationLaw')}
                  </Typography>
                </Box>
              }
              labelPlacement="start"
              classes={{ label: styles.label }}
            />
          </FormControl>
        </FilterSection>
        <Box
          display="flex"
          justifyContent="flex-end"
          width="100%"
          paddingTop={`${theme.spacing(2)}px`}
          paddingBottom={`${theme.spacing(2)}px`}
          flexWrap="nowrap"
        >
          <VkwButton
            variant="contained"
            color="default"
            disabled={
              plugsFilter.length === 0 &&
              minChargePowerFilter === MIN_CHARGE_POWER_STEPS[0].value &&
              chargeStationsFilter === 'allStations'
            }
            onClick={handleReset}
          >
            {formatMessage('ResetAllFilters')}
          </VkwButton>
          <Box width={`${theme.spacing(2)}px`} />
          <VkwButton variant="contained" color={theme.palette.mainButtonColor} onClick={handleApply}>
            {formatMessage('Apply')}
          </VkwButton>
        </Box>
      </Box>
    </VkwDrawer>
  );
};
