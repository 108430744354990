import { z } from 'zod';
import { ChargeSession as ChargeSessionPrisma } from '@prisma/client';

export enum StartChargingSessionResult {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum StartChargingSessionErrorCode {
  CHARGE_POINT_IS_RESERVED = 'CHARGE_POINT_IS_RESERVED',
  CHARGE_POINT_NOT_AVAILABLE = 'CHARGE_POINT_NOT_AVAILABLE',
  CHARGE_POINT_NOT_REACHABLE = 'CHARGE_POINT_NOT_REACHABLE',
  CHARGE_POINT_REJECTED_CHARGING = 'CHARGE_POINT_REJECTED_CHARGING',
  CHARGING_NOT_PERMITTED = 'CHARGING_NOT_PERMITTED',
  COMMUNICATION_TO_ROAMING_SYSTEM_FAILED = 'COMMUNICATION_TO_ROAMING_SYSTEM_FAILED',
  COMMUNICATION_TO_ROAMING_SYSTEM_TIMEOUT = 'COMMUNICATION_TO_ROAMING_SYSTEM_TIMEOUT',
  GENERIC_ERROR = 'GENERIC_ERROR',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  NO_VALID_DRIVER_IDENTIFICATION = 'NO_VALID_DRIVER_IDENTIFICATION',
  NO_VALID_ROAMING_CONTRACT = 'NO_VALID_ROAMING_CONTRACT',
  NO_VEHICLE_CONNECTED_TO_CHARGE_POINT = 'NO_VEHICLE_CONNECTED_TO_CHARGE_POINT',
  OTHER_SESSION_ACTIVE = 'OTHER_SESSION_ACTIVE',
  PRICING_VALIDITY_TOKEN_INVALID = 'PRICING_VALIDITY_TOKEN_INVALID',
}

export interface StartChargingSession {
  sessionId: string;
  result: StartChargingSessionResult;
  errorCode: StartChargingSessionErrorCode;
}

export enum StopChargingSessionResult {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum StopChargingSessionErrorCode {
  CHARGE_POINT_NOT_REACHABLE = 'CHARGE_POINT_NOT_REACHABLE',
  CHARGE_POINT_REJECTED_STOP = 'CHARGE_POINT_REJECTED_STOP',
  CHARGING_SESSION_ALREADY_ENDED = 'CHARGING_SESSION_ALREADY_ENDED',
  CHARGING_SESSION_NOT_ACTIVE = 'CHARGING_SESSION_NOT_ACTIVE',
  CHARGING_SESSION_NOT_FOUND = 'CHARGING_SESSION_NOT_FOUND',
  COMMUNICATION_TO_ROAMING_SYSTEM_FAILED = 'COMMUNICATION_TO_ROAMING_SYSTEM_FAILED',
  COMMUNICATION_TO_ROAMING_SYSTEM_TIMEOUT = 'COMMUNICATION_TO_ROAMING_SYSTEM_TIMEOUT',
  GENERIC_ERROR = 'GENERIC_ERROR',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
}

export interface StopChargingSession {
  result: StartChargingSessionResult;
  consumedEnergy?: number;
  estimatedCost?: number;
  sessionStart?: string;
  sessionEnd?: string;
  errorCode?: StartChargingSessionErrorCode;
}

export interface ChargeSessionStopResponse {
  result: string;
  errorCode?: StopChargingSessionErrorCode;
  chargeSession?: ChargeSessionPrisma;
}

const ChargeSession = z.object({
  chargeDetailRecordExists: z.boolean(),
  consumedEnergy: z.number().optional(),
  createdAt: z.string(),
  currentPower: z.number().optional(),
  customerId: z.string().optional(),
  empSessionId: z.string(),
  errorCode: z.string().optional(),
  estimatedCost: z.number().optional(),
  evcoId: z.string(),
  evseId: z.string(),
  lastUpdatedAt: z.string(),
  operator: z.object({}).optional(),
  provider: z.object({}),
  sessionEnd: z.string().optional(),
  sessionId: z.string().optional(),
  sessionStart: z.string().optional(),
  stateOfCharge: z.number().optional(),
  status: z.string(),
});

export type ChargeSession = z.infer<typeof ChargeSession>;
