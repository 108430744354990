import React, { ReactElement } from 'react';

import { makeStyles, Link } from '@material-ui/core';
import clsx from 'clsx';

import { VkwNavigationItemConfig } from './VkwNavigationItemConfig';
import { VkwTheme } from '../../themes';

const BORDER_WIDTH = 3;

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    item: {
      '&:hover': {
        backgroundColor: theme.palette.action.hover,
      },
      borderTop: `${BORDER_WIDTH}px solid transparent`,
      color: theme.palette.grey[500],
      flexBasis: 0,
      flexGrow: 1,

      fontSize: theme.typography.pxToRem(10),

      textAlign: 'center',

      [theme.breakpoints.up('sm')]: {
        borderRight: `${BORDER_WIDTH}px solid transparent`,
        borderTop: 'none',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold',
        maxHeight: '48px',
      },
    },
    itemActive: {
      borderTop: `${BORDER_WIDTH}px solid ${theme.palette.secondary.main}`,
      color: theme.palette.activeColor,

      [theme.breakpoints.up('sm')]: {
        borderRight: `${BORDER_WIDTH}px solid ${theme.palette.secondary.main}`,
        borderTop: 'none',
      },
    },
    itemContent: {
      '&:hover': {
        [theme.breakpoints.up('sm')]: {
          transform: 'translateX(2px)',
        },
      },
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',

      flexWrap: 'nowrap',
      height: '100%',
      justifyContent: 'center',
      padding: theme.spacing(1),
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingBottom: theme.spacing(1.75),
        paddingLeft: theme.spacing(4.25),
        paddingRight: theme.spacing(4.25),
        paddingTop: theme.spacing(1.75),
        textAlign: 'left',
        transition: theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.short,
        }),
        width: '100%',
      },

      whiteSpace: 'nowrap',

      width: '100%',
    },
    itemName: {
      paddingTop: theme.spacing(0.25),

      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingTop: 0,
      },
    },
  }),
  { index: 1 }
);

interface NavigationItemProps {
  active: boolean;
  item: VkwNavigationItemConfig;
  showName: boolean;
  onItemClicked?: () => void;
}

export const NavigationItem = ({ active, item, onItemClicked, showName }: NavigationItemProps): ReactElement => {
  const styles = useStyles();

  const itemClass = clsx(styles.item, {
    [styles.itemActive]: active,
  });

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!item.onClick) {
      return;
    }

    event.preventDefault();
    item.onClick();

    if (onItemClicked) {
      onItemClicked();
    }
  };

  const Icon = active ? item.activeIcon : item.icon;

  return (
    <Link
      key={item.key}
      onClick={item.onClick ? handleClick : undefined}
      href={item.path}
      target={item.onClick ? undefined : '_blank'}
      underline="none"
      title={item.name}
      className={itemClass}
    >
      <div className={styles.itemContent}>
        <Icon size={[24, 20]} />
        {showName && <span className={styles.itemName}>{item.name}</span>}
      </div>
    </Link>
  );
};
