import React, { ReactElement } from 'react';

import { lighten, makeStyles } from '@material-ui/core';

import { VkwIconMoney, VkwTheme } from '../../library';

const ICON_COLOR = '#00757a';

const useStyles = makeStyles<VkwTheme>({
  iconWrapper: {
    alignItems: 'center',
    background: lighten(ICON_COLOR, 0.9),
    borderRadius: '50%',
    color: ICON_COLOR,
    display: 'flex',
    height: '33px',
    justifyContent: 'center',
    width: '33px',
  },
});

export const InvoiceIcon = (): ReactElement => {
  const styles = useStyles();

  return (
    <div className={styles.iconWrapper}>
      <VkwIconMoney size={16} />
    </div>
  );
};
