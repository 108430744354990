import React, { ReactNode, ReactElement, useState } from 'react';

import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  makeStyles,
} from '@material-ui/core';

import { getVkwTypographyStyle } from '../../helper';
import { VkwIconArrowDown } from '../../icons';
import { VkwTheme } from '../../themes';

const useAccordionStyles = makeStyles<VkwTheme>(
  theme => ({
    expanded: {},
    root: {
      '&$expanded': {
        margin: '0',
      },
      '&:before': {
        display: 'none',
      },

      background: 'none',

      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  }),
  { index: 1 }
);

interface AccordionProps {
  children: NonNullable<ReactNode>;
  defaultExpanded: boolean;
}

const Accordion = ({ children, defaultExpanded }: AccordionProps): ReactElement => {
  const styles = useAccordionStyles();
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <MuiAccordion
      square
      expanded={expanded}
      onChange={(_, newExpanded) => setExpanded(newExpanded)}
      classes={{
        expanded: styles.expanded,
        root: styles.root,
      }}
      elevation={0}
    >
      {children}
    </MuiAccordion>
  );
};

const useAccordionSummaryStyles = makeStyles<VkwTheme, { compact?: boolean }>(
  theme => ({
    content: {
      '&$expanded': {
        marginBottom: 0,
        marginTop: 0,
      },
      marginBottom: 0,

      marginTop: 0,
    },
    expandIcon: props => ({
      color: props.compact ? theme.palette.text.secondary : theme.palette.text.primary,
      paddingBottom: 0,
      paddingTop: 0,
    }),
    expanded: {
      marginBottom: 0,
      marginTop: 0,
    },
    root: props => ({
      '&.Mui-expanded': {
        minHeight: props.compact ? '32px' : '64px',
      },
      minHeight: props.compact ? '32px' : '64px',

      padding: 0,
    }),
  }),
  { index: 1 }
);

interface AccordionSummaryProps {
  compact?: boolean;
  children: ReactNode;
}

const AccordionSummary = ({ children, compact }: AccordionSummaryProps): ReactElement => {
  const styles = useAccordionSummaryStyles({ compact });

  return (
    <MuiAccordionSummary
      aria-controls="panel1d-content"
      expandIcon={<VkwIconArrowDown size={16} />}
      classes={{
        content: styles.content,
        expandIcon: styles.expandIcon,
        expanded: styles.expanded,
        root: styles.root,
      }}
    >
      {children}
    </MuiAccordionSummary>
  );
};

const useAccordionDetailsStyles = makeStyles<VkwTheme, { compact?: boolean }>(
  {
    root: props => ({
      paddingBottom: props.compact ? '10px' : '28px',
      paddingLeft: '0',
      paddingRight: '0',
      paddingTop: '0',
    }),
  },
  { index: 1 }
);

interface AccordionDetailsProps {
  compact?: boolean;
  children: ReactNode;
}

const AccordionDetails = ({ children, compact }: AccordionDetailsProps): ReactElement => {
  const styles = useAccordionDetailsStyles({ compact });

  return <MuiAccordionDetails classes={{ root: styles.root }}>{children}</MuiAccordionDetails>;
};

const useStyles = makeStyles<VkwTheme, { compact?: boolean }>(
  theme => ({
    title: props => ({
      ...getVkwTypographyStyle(props.compact ? 'text12' : 'h8', theme),
      color: props.compact ? theme.palette.text.secondary : theme.palette.text.primary,
    }),
  }),
  { index: 1 }
);

export interface VkwAccordionProps {
  /**
   * Inhalt im Accordion Header
   */
  title: ReactNode;
  /**
   * Inhalt im Accordion Body
   */
  children: ReactNode;
  variant?: 'default' | 'compact';
  defaultExpanded?: boolean;
}

export const VkwAccordion = ({
  children,
  defaultExpanded = false,
  title,
  variant = 'default',
}: VkwAccordionProps): ReactElement => {
  const compact = variant === 'compact';

  const styles = useStyles({ compact });

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary compact={compact}>
        <Box className={styles.title}>{title}</Box>
      </AccordionSummary>
      <AccordionDetails compact={compact}>{children}</AccordionDetails>
    </Accordion>
  );
};
