import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugTeslaSuperchargerOutline = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g>
        <defs>
          <path id="prefix__a" d="M0 0h24v20H0z" />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(0 2)">
          <mask id="prefix__b" fill={fill}>
            <path d="M0 0h24v20H0z" />
          </mask>
          <path
            d="M8.67 5.604c0-1.823-1.505-3.3-3.36-3.3-1.856 0-3.36 1.477-3.36 3.3 0 1.822 1.504 3.3 3.36 3.3 1.855 0 3.36-1.478 3.36-3.3m13.383 0c0-1.823-1.504-3.3-3.36-3.3-1.855 0-3.36 1.477-3.36 3.3 0 1.822 1.505 3.3 3.36 3.3 1.856 0 3.36-1.478 3.36-3.3m-8.708 9.839c0-.73-.601-1.32-1.343-1.32-.743 0-1.344.59-1.344 1.32 0 .729.601 1.32 1.344 1.32.742 0 1.343-.591 1.343-1.32m-5.477.208c0-.47-.386-.85-.863-.85s-.864.38-.864.85c0 .468.387.848.864.848s.863-.38.863-.848m9.995 0c0-.47-.387-.85-.864-.85s-.864.38-.864.85c0 .468.387.848.864.848s.864-.38.864-.848M15.387 1.137a29.59 29.59 0 0 0-6.77 0 .47.47 0 1 0-.251.832 4.66 4.66 0 0 1 1.41 5.361c-.971 2.423-3.757 3.614-6.224 2.66a1.208 1.208 0 0 0-1.556.665c-.102.256-.11.54-.023.8a10.338 10.338 0 0 0 2.204 3.72c4.61-3.494 11.04-3.494 15.65 0a10.35 10.35 0 0 0 2.203-3.72 1.174 1.174 0 0 0-.764-1.487 1.226 1.226 0 0 0-.815.022 4.854 4.854 0 0 1-5.458-1.384 4.657 4.657 0 0 1 .644-6.637.466.466 0 0 0 .064-.664.489.489 0 0 0-.314-.168m7.768 2.728a4.768 4.768 0 0 0-3.186-2.805 30.498 30.498 0 0 0-15.934 0A4.764 4.764 0 0 0 .849 3.865c-2.444 6.05.569 12.901 6.729 15.301 6.16 2.4 13.133-.559 15.577-6.61a11.59 11.59 0 0 0 0-8.69"
            fill={fill}
          />
        </g>
      </g>
    </VkwIcon>
  );
};
