import React, { ReactElement } from 'react';

import clsx from 'clsx';
import { round } from 'lodash';

import { ChargePointStatusEnum } from '@e-vo/types';

import { ChargeStation } from '../../hooks';

type ChargePointStatusWithNotAllowed = ChargePointStatusEnum | 'NOT_ALLOWED';

interface MaxChargePowerIconProps {
  maxChargePower: number;
}

const MaxChargePowerIcon = ({ maxChargePower }: MaxChargePowerIconProps): ReactElement => {
  return (
    <span
      className={clsx(
        'absolute -top-2 flex h-4 items-center justify-center rounded-2xl bg-white font-medium text-gray-700 drop-shadow-lg',
        maxChargePower.toString().length >= 3 ? '-right-1 w-12' : 'w-10'
      )}
    >
      {maxChargePower} kW
    </span>
  );
};
interface ListMarkerIconProps {
  chargeStationList: ChargeStation[];
  isActive: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const ListMarkerOverlayView = ({ chargeStationList, isActive, onClick }: ListMarkerIconProps): ReactElement => {
  let chargePointsCount = 0;
  let available = 0;
  let occupied = 0;
  let outOfService = 0;
  let reserved = 0;
  let allowed = 0;

  let maxChargePower = 0;

  chargeStationList.forEach(chargeStation => {
    if (chargeStation.chargePoints) {
      chargePointsCount += chargeStation.chargePoints.length;

      chargeStation.chargePoints.forEach(chargePoint => {
        if (typeof chargePoint.isChargingAllowed === 'undefined' || chargePoint.isChargingAllowed) {
          allowed++;
        }

        switch (chargePoint.status) {
          case 'AVAILABLE':
            available++;
            if (chargePoint.maxPower > maxChargePower) {
              maxChargePower = round(chargePoint.maxPower);
            }
            break;
          case 'OCCUPIED':
            occupied++;
            break;
          case 'RESERVED':
            reserved++;
            break;
          case 'OUT_OF_SERVICE':
            outOfService++;
            break;
          default:
            break;
        }
      });
    }
  });

  let chargeStationStatus: ChargePointStatusWithNotAllowed = ChargePointStatusEnum.UNKNOWN;
  if (allowed === 0) {
    chargeStationStatus = 'NOT_ALLOWED';
  } else if (available > 0) {
    chargeStationStatus = ChargePointStatusEnum.AVAILABLE;
  } else if (occupied > 0 || reserved > 0) {
    chargeStationStatus = ChargePointStatusEnum.OCCUPIED;
  } else if (outOfService > 0) {
    chargeStationStatus = ChargePointStatusEnum.OUT_OF_SERVICE;
  }

  const availablePercentage = (100 / chargePointsCount) * available;
  const occupiedPercentage = (100 / chargePointsCount) * occupied;
  const outOfServicePercentage = (100 / chargePointsCount) * outOfService;
  const reservedPercentage = (100 / chargePointsCount) * reserved;

  const strokeWidth = 3;

  return (
    <div
      className={clsx('group relative transition ease-in hover:z-20 hover:scale-125 ', isActive && 'z-30 !scale-150')}
      onClick={onClick}
      role="presentation"
    >
      <div
        className={clsx(
          'relative flex h-10 w-10 items-center justify-center rounded-full bg-gray-700 group-hover:bg-gray-800',
          isActive && 'bg-gray-800'
        )}
      >
        <span className={clsx('text-sm font-medium text-white')}>{available}</span>
        {isActive && (
          <span
            className={clsx('absolute top-0 -z-10 inline-flex h-full w-full animate-ping rounded-full opacity-75')}
          />
        )}
        <div className="absolute left-0 top-0 inline-flex items-center justify-center overflow-hidden rounded-full">
          <svg className="h-10 w-10">
            <circle
              className="text-gray-500"
              strokeWidth={strokeWidth}
              stroke="currentColor"
              fill="transparent"
              r="17"
              cx="20"
              cy="20"
            />
            {reservedPercentage && (
              <circle
                className="text-blue-600"
                strokeDasharray={`${
                  ((reservedPercentage + outOfServicePercentage + occupiedPercentage + availablePercentage) * 106.83) /
                  100
                } 106.83`}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                stroke="currentColor"
                fill="transparent"
                r="17"
                cx="20"
                cy="20"
              />
            )}
            {outOfServicePercentage && (
              <circle
                className="text-red-600"
                strokeDasharray={`${
                  ((outOfServicePercentage + occupiedPercentage + availablePercentage) * 106.83) / 100
                } 106.83`}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                stroke="currentColor"
                fill="transparent"
                r="17"
                cx="20"
                cy="20"
              />
            )}
            {occupiedPercentage && (
              <circle
                className="text-yellow-600"
                strokeDasharray={`${((occupiedPercentage + availablePercentage) * 106.83) / 100} 106.83`}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                stroke="currentColor"
                fill="transparent"
                r="17"
                cx="20"
                cy="20"
              />
            )}
            {availablePercentage && (
              <circle
                className="text-green-600"
                strokeDasharray={`${(availablePercentage * 106.82) / 100} 106.82`}
                strokeWidth={strokeWidth}
                strokeLinecap="round"
                stroke="currentColor"
                fill="transparent"
                r="17"
                cx="20"
                cy="20"
              />
            )}
          </svg>
        </div>
      </div>
      {isActive && (
        <span
          className={clsx(
            'absolute top-0 -z-10 inline-flex h-full w-full animate-ping rounded-full bg-gray-800 opacity-75'
          )}
        />
      )}
      {chargeStationStatus !== 'NOT_ALLOWED' && maxChargePower >= 50 && (
        <MaxChargePowerIcon maxChargePower={maxChargePower} />
      )}
    </div>
  );
};
