import React, { ReactElement, ReactNode } from 'react';

import { Box } from '@material-ui/core';
import { useRouter } from 'next/router';

import { Ticket } from './const';
import { TicketMobileItem } from './TicketMobileItem';
import { TicketStatusBadge } from '../../components';
import { useBaseDataStore, useSortableListSettings } from '../../hooks';
import {
  FormatMessage,
  VkwDataTable,
  VkwBaseTableCell,
  VkwDataTableFieldConfig,
  useVkwFormatMessage,
} from '../../library';

const renderStatusCell = (dataEntry: Ticket): ReactNode => {
  return (
    <VkwBaseTableCell key="status">
      {dataEntry.status && <TicketStatusBadge status={dataEntry.status} />}
    </VkwBaseTableCell>
  );
};

const getTableFieldsConfig = (formatMessage: FormatMessage): VkwDataTableFieldConfig<Ticket>[] => [
  {
    active: true,
    fixed: true,
    label: formatMessage('Status'),
    property: 'status',
    renderCustomCell: renderStatusCell,
  },
  {
    active: true,
    converter: 'date',
    fixed: true,
    label: formatMessage('Date'),
    property: 'creationDate',
  },
  {
    active: true,
    bold: true,
    fixed: true,
    label: formatMessage('Subject'),
    property: 'subject',
  },
  {
    active: true,
    label: formatMessage('TicketId'),
    property: 'ticketId',
  },
  {
    active: true,
    label: formatMessage('TicketUser'),
    property: 'ticketUser',
  },
];

export const Support = (): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const store = useBaseDataStore<Ticket>({ url: '/api/v1/tickets' });
  const dataTableSettingsProvider = useSortableListSettings('tickets-table');
  const router = useRouter();
  const changeRouteToTicketDetail = (ticket: Ticket): void => {
    router.push('/support/ticket-detail/[ticketInternalId]', `/support/ticket-detail/${ticket.ticketInternalId}`);
  };

  return (
    <Box marginTop={1}>
      <VkwDataTable
        initialized={store.initialized}
        loading={store.loading}
        loadAdditionalEntries={store.loadAdditionalEntries}
        page={store.page}
        pageSize={store.pageSize}
        pages={store.pages}
        data={store.data}
        totalRecords={store.totalRecords}
        hasMore={store.hasMore}
        setPage={store.setPage}
        setIsMobile={store.setIsMobile}
        defaultFiltersCount={6}
        tableFieldsConfig={getTableFieldsConfig(formatMessage)}
        noResultsDisplayText={formatMessage('NoResultsForTickets')}
        noResultsWithActiveFilterDisplayText={formatMessage('NoResultsWithActiveFilter')}
        onRowClick={changeRouteToTicketDetail}
        renderMobileItem={(dataEntry, i) => (
          <TicketMobileItem key={i} ticket={dataEntry} onClick={changeRouteToTicketDetail} />
        )}
        filters={store.filters}
        filterOptions={store.filterOptions}
        onChangeFilter={store.changeFilter}
        onClearFilters={store.clearFilters}
        settingsProvider={dataTableSettingsProvider}
      />
    </Box>
  );
};
