import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconMyAccount = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <svg viewBox="0 0 46 46" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>1FF18772-4C40-4388-A119-2C41DCFB0A1D@1x</title>
        <g
          id="Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g id="Icon_Zusammenfassung" transform="translate(-1273.000000, -3145.000000)">
            <g id="badge" transform="translate(1272.000000, 3144.000000)">
              <g transform="translate(2.000000, 2.000000)">
                <polyline id="Path" stroke={fill} strokeWidth="2" points="26 10 44 10 44 44 0 44 0 10 18 10" />
                <path
                  d="M26,14 L18,14 L18,4 C18,1.791 19.791,0 22,0 L22,0 C24.209,0 26,1.791 26,4 L26,14 Z"
                  id="Path"
                  stroke={fill}
                  strokeWidth="2"
                />
                <line x1="28" y1="25" x2="37" y2="25" id="Path" stroke={fill} strokeWidth="2" />
                <line x1="28" y1="33" x2="37" y2="33" id="Path" stroke={fill} strokeWidth="2" />
                <path
                  d="M22,37 L8,37 L8,37 C8,33.686 10.686,31 14,31 L16,31 C19.314,31 22,33.686 22,37 L22,37 Z"
                  id="Path"
                  stroke={fill}
                  strokeWidth="2"
                />
                <circle id="Oval" stroke={fill} strokeWidth="2" cx="15" cy="24" r="4" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </VkwIcon>
  );
};
