import React, { createContext, ReactElement, ReactNode, useContext, useEffect, useMemo } from 'react';

import { useUserContext } from '.';
import { useAllEntriesLoader } from '../hooks';

export enum ChargingTariffContractSelfServiceOperation {
  CHANGE_IDENTIFICATION = 'CHANGE_IDENTIFICATION',
}

export interface ChargingTariffContract {
  id: string;
  productName: string | null;
  productMonthlyFee: number | null;
  vehicleAlias: string | null;
  vehicleType: string | null;
  vehicleLicensePlate: string | null;
  contractAlias: string | null;
  contractId: string;
  contractInvoiceRecipientName: string | null;
  contractIban: string | null;
  contractValidTo: Date | null;
  authenticationCardUid: string | null;
  authenticationEvcoId: string | null;
  customerId: string | null;
  authorizationGroups: string[];
  allowedSelfServiceOperations: ChargingTariffContractSelfServiceOperation[];
}

export interface Commission {
  amount: number;
  commissionType: 'COMMISSION' | 'REFUND';
  currency: string;
  unitSize: number;
  unitType: 'TIME' | 'KWH' | 'START' | 'REMINDER';
}

export interface InfrastructureContractChargePoint {
  evseId: string;
  commissions: Commission[];
}

export interface InfrastructureContract {
  id: string;
  customerId: string | null;
  contractId: string | null;
  contractValidTo: Date | null;
  chargePoints: InfrastructureContractChargePoint[] | null;
  invoiceRecipientName: string | null;
  invoiceRecipientStreet: string | null;
  invoiceRecipientStreetNumber: string | null;
  invoiceRecipientStreetApartmentNumber: string | null;
  invoiceRecipientZipCode: string | null;
  invoiceRecipientCity: string | null;
  invoiceRecipientCountry: string | null;
  invoiceRecipientEMail: string | null;
  invoiceRecipientPhoneNumber: string | null;
  bankAccountIBAN: string | null;
  bankAccountAccountName: string | null;
  bankAccountBankName: string | null;
  bankAccountBIC: string | null;
  infrastructureContractAlias: string | null;
  products: string[] | null;
}

interface ChargingTariffContracts {
  entries: ChargingTariffContract[];
  loading: boolean;
}

interface InfrastructureContracts {
  entries: InfrastructureContract[];
  loading: boolean;
}

interface ContractContextProps {
  chargingTariffContracts: ChargingTariffContracts;
  infrastructureContracts: InfrastructureContracts;
}

const ContractsContext = createContext<ContractContextProps | null>(null);

interface ContractsContextProviderProps {
  children: ReactNode;
}

export const ContractsProvider = ({ children }: ContractsContextProviderProps): ReactElement => {
  const userContext = useUserContext();
  const chargingTariffContractsLoader = useAllEntriesLoader<ChargingTariffContract>(`/api/v1/ChargingTariffContracts`);
  const infrastructureContractsLoader = useAllEntriesLoader<InfrastructureContract>(`/api/v1/InfrastructureContracts`);

  useEffect(() => {
    if (userContext.account) {
      if (userContext.account.info.hasChargingTariffContract) {
        chargingTariffContractsLoader.load();
      } else {
        chargingTariffContractsLoader.clear();
      }
      if (userContext.account.info.hasInfrastructureContract) {
        infrastructureContractsLoader.load();
      } else {
        infrastructureContractsLoader.clear();
      }
    }
  }, [userContext.account]);

  const value = useMemo(
    () => ({
      chargingTariffContracts: {
        entries: chargingTariffContractsLoader.entries,
        loading: chargingTariffContractsLoader.loading,
      },
      infrastructureContracts: {
        entries: infrastructureContractsLoader.entries,
        loading: infrastructureContractsLoader.loading,
      },
    }),
    [
      chargingTariffContractsLoader.entries,
      chargingTariffContractsLoader.loading,
      infrastructureContractsLoader.entries,
      infrastructureContractsLoader.loading,
    ]
  );

  return <ContractsContext.Provider value={value}>{children}</ContractsContext.Provider>;
};

export const useContractsContext = (): ContractContextProps => {
  const contracts = useContext(ContractsContext);

  if (!contracts) {
    throw new Error('Use "ContractsContextProvider" somewhere!!');
  }

  return contracts;
};
