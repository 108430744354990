import React, { ReactElement } from 'react';

import { VkwStatusText, useVkwFormatMessage, toFirstUppercase } from '../../library';
import { TicketStatus } from '../../views';

interface TicketStatusBadgeProps {
  status: TicketStatus;
}

export const TicketStatusBadge = ({ status }: TicketStatusBadgeProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  return (
    <VkwStatusText
      label={formatMessage(`TicketStatus${toFirstUppercase(status)}`)}
      type={['Finished', 'Closed', 'Canceled'].includes(toFirstUppercase(status)) ? 'success' : 'warning'}
    />
  );
};
