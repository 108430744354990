import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconCheckboxSelected = ({ size }: VkwIconProps): ReactElement => {
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      <g fill="none" fillRule="evenodd">
        <rect width="24" height="24" fill="#143968" rx="2" />
        <path
          fillRule="nonzero"
          stroke="#FFF"
          strokeLinecap="square"
          strokeWidth={strokewidth}
          d="M6 11.581L10.507 16.088 18.728 7.867"
        />
      </g>
    </VkwIcon>
  );
};
