import React, { ReactElement } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { useConfigContext } from '../../contexts';
import { VkwIconCharging, VkwTheme, dateTimeDisplay, getVkwTypographyStyle, timeConvert } from '../../library';
import { Money } from '../Money';

const useStyles = makeStyles<VkwTheme>(theme => ({
  amount: {
    ...getVkwTypographyStyle('h9', theme),
    whiteSpace: 'nowrap',
  },
  duration: {
    background: theme.palette.primary.main,
    borderRadius: 8,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    padding: '0 4px',
    ...getVkwTypographyStyle('text10', theme),
    marginTop: -3,
  },
  location: {
    ...getVkwTypographyStyle('h9', theme),
  },
}));

interface ChargeDetailRecordSummaryProps {
  data: {
    durationInSeconds: number;
    displayName?: string;
    chargingPointLocation: string;
    startTime: Date;
    amount: number;
  };
  onClick?: () => void;
}

export const ChargeDetailRecordSummary = ({ data, onClick }: ChargeDetailRecordSummaryProps): ReactElement => {
  const { config } = useConfigContext();
  const styles = useStyles();

  return (
    <Box display="flex" alignItems="center" paddingY={2} onClick={onClick} role="button">
      <Box paddingRight={2}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <VkwIconCharging />
          <div className={styles.duration}>{timeConvert(data.durationInSeconds, false)}</div>
        </Box>
      </Box>
      <Box paddingRight={2} flexGrow={1}>
        {data.displayName && <div>{data.displayName}</div>}
        <div className={styles.location}>{data.chargingPointLocation}</div>
        <div>{dateTimeDisplay(data.startTime)}</div>
      </Box>
      {!config.hideCommission && (
        <div className={styles.amount}>
          <Money value={data.amount} />
        </div>
      )}
    </Box>
  );
};
