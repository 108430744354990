import React, { ReactElement, useCallback } from 'react';

import { makeStyles } from '@material-ui/core';
import { useRouter } from 'next/router';

import { InfrastructureChargeDetailRecord } from '.';
import { Money } from '../../components';
import {
  VkwButton,
  VkwDataSection,
  VkwTheme,
  dateTimeDisplay,
  getVkwTypographyStyle,
  timeConvert,
  useVkwFormatMessage,
} from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  location: {
    ...getVkwTypographyStyle('text16', theme),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(0.5),
  },
  totalCost: {
    ...getVkwTypographyStyle('h5', theme),
    whiteSpace: 'nowrap',
  },
}));

interface InfrastructureChargeDetailsRecordDetailProps {
  chargeDetailRecord: InfrastructureChargeDetailRecord;
}

export const InfrastructureChargeDetailsRecordDetail = ({
  chargeDetailRecord,
}: InfrastructureChargeDetailsRecordDetailProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();
  const router = useRouter();

  const showChargingStation = useCallback(() => {
    router.push({ pathname: '/', query: { evseId: chargeDetailRecord.chargingPointId } });
  }, [chargeDetailRecord]);

  const reportIssue = useCallback(() => {
    router.push({
      pathname: `/support/new-ticket`,
      query: {
        description: chargeDetailRecord.transactionId
          ? `${formatMessage('TransactionNumber')}: ${chargeDetailRecord.transactionId}`
          : '',
        subject: 'charging',
      },
    });
  }, [chargeDetailRecord]);
  return (
    <>
      <div className={styles.totalCost}>
        <Money value={chargeDetailRecord.totalAmount} />
      </div>
      <div className={styles.location}>{chargeDetailRecord.chargingPointLocation}</div>
      <VkwDataSection
        headline={formatMessage('Charge')}
        items={[
          {
            title: formatMessage('ChargingStartTime'),
            value: dateTimeDisplay(chargeDetailRecord.startTime),
            xs: 6,
          },
          {
            title: formatMessage('ChargingEndTime'),
            value: dateTimeDisplay(chargeDetailRecord.endTime),
            xs: 6,
          },
          {
            title: formatMessage('ChargingDuration'),
            value: timeConvert(chargeDetailRecord.durationInSeconds),
            xs: 6,
          },
          {
            title: formatMessage('ConsumedEnergy'),
            value: chargeDetailRecord.consumedEnergy.toLocaleString(),
            xs: 6,
          },
        ]}
      />
      <VkwDataSection
        rootBoxProps={{ paddingBottom: 5 }}
        headline={formatMessage('Details')}
        items={[
          {
            title: formatMessage('ProductName'),
            value: chargeDetailRecord.productName,
            xs: 12,
          },
          {
            title: formatMessage('ContractNumber'),
            value: chargeDetailRecord.contractId,
            xs: 6,
          },
          {
            title: formatMessage('InvoiceId'),
            value: chargeDetailRecord.invoiceId,
            xs: 6,
          },
        ]}
      />
      <VkwDataSection
        headline={formatMessage('ChargePoint')}
        rootBoxProps={{ paddingBottom: 5 }}
        ctaLabel={formatMessage('ShowChargeStation')}
        onCtaClick={showChargingStation}
        items={[
          {
            title: formatMessage('ChargingPointName'),
            value: chargeDetailRecord.chargingPointName,
            xs: 6,
          },
          {
            title: formatMessage('ChargingLocation'),
            value: chargeDetailRecord.chargingPointLocation,
            xs: 6,
          },
          {
            title: formatMessage('ChargingPointId'),
            value: chargeDetailRecord.chargingPointId,
            xs: 6,
          },
          {
            title: formatMessage('ChargingStationOperator'),
            value: chargeDetailRecord.chargingStationOperator,
            xs: 6,
          },
        ]}
      />
      <VkwButton color="primary" variant="contained" fullWidth onClick={reportIssue}>
        {formatMessage('ReportIssue')}
      </VkwButton>
    </>
  );
};
