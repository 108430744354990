import React, { ReactElement, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Axios from 'axios';

import { useUserContext } from '../../contexts';
import { useHandleAxiosResponse } from '../../hooks';
import { VkwDataSection, VkwLoader, VkwTheme, getVkwTypographyStyle, useVkwFormatMessage } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  operatorName: {
    ...getVkwTypographyStyle('link16', theme),
  },
}));

type EmobilityServiceProvider = {
  operatorToken: string;
  name: string;
};

type OperatorContractsData = {
  operator: {
    operatorToken: string;
    name: string;
  };
  emobilityServiceProviders: EmobilityServiceProvider[];
};

interface OperatorContractsProps {
  operatorToken: string | null;
  evseId: string | null;
}

export const OperatorContracts = ({ evseId, operatorToken }: OperatorContractsProps): ReactElement => {
  const [operatorContracts, setOperatorContracts] = useState<OperatorContractsData | null>(null);
  const [emobilityServiceProviders, setEmobilityServiceProviders] = useState<EmobilityServiceProvider[]>([]);
  const [noOperatorContractsFound, setNoOperatorContractsFound] = useState(false);

  const formatMessage = useVkwFormatMessage();
  const styles = useStyles();
  const handleAxiosResponse = useHandleAxiosResponse();
  const userContext = useUserContext();

  useEffect(() => {
    const url = userContext.account?.info.hasInfrastructureContract
      ? '/api/v1/OperatorContracts/GetForOwnChargePoints'
      : '/api/v1/OperatorContracts';

    const params = userContext.account?.info.hasInfrastructureContract
      ? { customerId: userContext.selectedCustomer?.id, evseId }
      : { operatorToken };
    let active = true;

    handleAxiosResponse(() => Axios.get<OperatorContractsData>(url, { params }), {
      notFound: () => {
        setNoOperatorContractsFound(true);
      },
      success: response => {
        if (active) {
          setOperatorContracts(response.data);
        }
      },
    });

    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    if (!operatorContracts) {
      return;
    }

    setEmobilityServiceProviders(
      operatorContracts.emobilityServiceProviders.sort((a, b) => {
        if (a.operatorToken < b.operatorToken) {
          return -1;
        }
        if (a.operatorToken > b.operatorToken) {
          return 1;
        }

        return 0;
      })
    );
  }, [operatorContracts?.emobilityServiceProviders]);

  if (operatorContracts === null && !noOperatorContractsFound) {
    return <VkwLoader />;
  }

  if (operatorContracts === null) {
    return (
      <VkwDataSection
        headline={formatMessage('OperatorContracts')}
        renderContent={() => <div className={styles.operatorName}> -</div>}
      />
    );
  }

  return (
    <VkwDataSection
      headline={formatMessage('OperatorContracts')}
      items={emobilityServiceProviders.map(emobilityServiceProvider => {
        return {
          renderContent: () => <div className={styles.operatorName}>{emobilityServiceProvider.name}</div>,
          title: emobilityServiceProvider.operatorToken,
          xs: 6,
        };
      })}
    />
  );
};
