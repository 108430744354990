import React, { ReactElement, useEffect, useMemo } from 'react';

import { ChargingTariffChargeDetailRecords, InfrastructureChargeDetailsRecords } from '../../containers';
import { useUserContext } from '../../contexts';
import { VkwTab, VkwTabItem, useVkwFormatMessage } from '../../library';

export type Views = 'chargingTariff' | 'infrastructure';

export interface ChargeDetailRecordsProps {
  view: Views | undefined;
  onViewChange: (view: Views) => void;
  initialFilters?: { [key: string]: string[] | number[] | Date[] };
}

export const ChargeDetailRecords = ({ initialFilters, onViewChange, view }: ChargeDetailRecordsProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const userContext = useUserContext();
  const showChargingTariff =
    userContext.account?.info.hasChargingTariffContract ||
    userContext.account?.info.hasSharedChargingTariffChargeDetailRecords;
  const showInfrastructure =
    userContext.account?.info.hasInfrastructureContract ||
    userContext.account?.info.hasSharedInfrastructureChargeDetailRecords;

  const renderChargingTariffChargeDetailRecords = (): ReactElement => {
    return <ChargingTariffChargeDetailRecords initialFilters={initialFilters} />;
  };

  const renderInfrastructureChargeDetailsRecords = (): ReactElement => {
    return <InfrastructureChargeDetailsRecords initialFilters={initialFilters} />;
  };

  const tabs: (VkwTabItem & { key: Views })[] = [];

  if (showChargingTariff) {
    tabs.push({
      key: 'chargingTariff',
      label: formatMessage('MyChargeDetailRecords'),
      renderContent: renderChargingTariffChargeDetailRecords,
    });
  }

  if (showInfrastructure) {
    tabs.push({
      key: 'infrastructure',
      label: formatMessage('ChargeDetailRecordsOnInfrastructure'),
      renderContent: renderInfrastructureChargeDetailsRecords,
    });
  }

  const tabIndex = useMemo(() => {
    const index = tabs.findIndex(value => value.key === view);

    return index !== -1 ? index : 0;
  }, [tabs, view]);

  const handleChange = (_: React.ChangeEvent<unknown>, index: number): void => {
    const tab = tabs.find((_, i) => i === index);

    if (!tab) {
      return;
    }

    onViewChange(tab.key);
  };

  useEffect(() => {
    if (view) {
      return;
    }

    const tab = tabs.find((_, i) => i === tabIndex);

    if (!tab) {
      return;
    }

    onViewChange(tab.key);
  }, [view]);

  return <VkwTab value={tabIndex} tabs={tabs} onChange={handleChange} />;
};
