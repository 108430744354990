import React, { ReactElement, useState } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import Axios from 'axios';
import clsx, { ClassValue } from 'clsx';

import { ChargePoint } from '@e-vo/types';

import { ChargePointItemDetailsOverlay } from './ChargePointItemDetailsOverlay';
import { PlugTooltip } from './PlugTooltip';
import { MoreMenu, MoreMenuItems } from '../../components';
import { useUserContext } from '../../contexts';
import { useChargeStationPin, useHandleAxiosResponse } from '../../hooks';
import {
  getVkwTypographyStyle,
  useVkwFormatMessage,
  VkwDialog,
  VkwIconButton,
  VkwIconChargingOutline,
  VkwIconInfo,
  VkwIconLocked,
  VkwIconThreeDots,
  VkwIconUnlock,
  VkwTheme,
} from '../../library';
import { ChargePointStatusWithNotAllowed } from '../../util/charge-stations';
import { getPlugIcon } from '../../util/getPlugIcon';

const useStyles = makeStyles<VkwTheme>(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  evseId: {
    ...getVkwTypographyStyle('text16', theme),
    padding: theme.spacing(0, 1),
  },
  pin: {
    padding: theme.spacing(0, 1, 0, 0),
  },
  plugs: {
    paddingRight: theme.spacing(0.5),
  },
  root: {
    display: 'flex',
    width: '100%',
  },
  statusAvailable: {
    background: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  statusError: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  statusReserved: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  statusUnknown: {
    background: theme.palette.grey[500],
    color: theme.palette.common.white,
  },
}));

interface ChargeStationPointProps {
  chargePoint: ChargePoint;
}

export const ChargePointItemWithActions = ({ chargePoint }: ChargeStationPointProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();
  const handleAxiosResponse = useHandleAxiosResponse();
  const chargeStationPin = useChargeStationPin([chargePoint], 'small');
  const [showMoreMenuConfig, setShowMoreMenuConfig] = useState<null | { chargePoint: ChargePoint; anchorEl: Element }>(
    null
  );
  const [showDialog, setShowDialog] = useState(false);
  const [actionLabel, setActionLabel] = useState<string>('');
  const userContext = useUserContext();
  const [detailsOpen, setDetailsOpen] = useState(false);

  const getMoreMenuItems = (): MoreMenuItems => {
    const moreMenuItems: MoreMenuItems = [
      {
        icon: <VkwIconLocked size={16} />,
        label: formatMessage('Unlock'),
        onClick: () => {
          setActionLabel('Unlock');
          setShowDialog(true);
        },
        type: 'item',
      },
      {
        icon: <VkwIconChargingOutline size={16} />,
        label: formatMessage('StopCharging'),
        onClick: () => {
          setActionLabel('StopCharging');
          setShowDialog(true);
        },
        type: 'item',
      },
    ];

    if (userContext.account?.info.isCallCenterAgent) {
      moreMenuItems.push({
        icon: <VkwIconUnlock size={16} />,
        label: formatMessage('Activate'),
        onClick: () => {
          setActionLabel('Activate');
          setShowDialog(true);
        },
        type: 'item',
      });
    }

    return moreMenuItems;
  };

  const onClickConfirmAction = (): void => {
    let axiosPath: string;
    switch (actionLabel) {
      case 'Unlock': {
        axiosPath = '/api-secured/v1/ChargePointsSecured/ExecuteUnlockCommand';
        break;
      }
      case 'Activate': {
        axiosPath = '/api-secured/v1/ChargePointsSecured/ExecuteStartFreeChargingSessionCommand';
        break;
      }
      case 'StopCharging': {
        axiosPath = '/api-secured/v1/ChargePointsSecured/ExecuteStopChargingSessionCommand';
        break;
      }
      default: {
        break;
      }
    }
    handleAxiosResponse(
      () =>
        Axios.post(
          axiosPath,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
            },
            params: {
              customerId: userContext.selectedCustomer?.id,
              evseId: chargePoint.evseId,
            },
          }
        ),
      {},
      {
        errorSnackbarMessage: formatMessage(`${actionLabel}Failed`),
        notFoundSnackbarMessage: formatMessage(`${actionLabel}Failed`),
        successSnackbarMessage: formatMessage(`${actionLabel}Successful`),
        tooManyRequestsSnackbarMessage: formatMessage('MaxNumberSupportFunctions'),
      }
    );
    setShowDialog(false);
    setShowMoreMenuConfig(null);
  };

  const bgColor: Record<ChargePointStatusWithNotAllowed, ClassValue[]> = {
    AVAILABLE: ['bg-green-600'],
    NOT_ALLOWED: ['bg-gray-300'],
    OCCUPIED: ['bg-yellow-600'],
    OUT_OF_SERVICE: ['bg-red-600'],
    RESERVED: ['bg-blue-600'],
    UNKNOWN: ['bg-gray-500'],
  };

  const rootClasses = clsx(styles.root, bgColor[chargePoint.status], 'text-white');

  return (
    <div className="">
      <div key={chargePoint.evseId} className={rootClasses}>
        <div className={clsx(styles.evseId, styles.content)}>{chargePoint.evseId}</div>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <div className="relative">
            <div
              className={clsx(
                'absolute inset-0 flex items-center justify-center',
                detailsOpen && 'motion-safe:animate-ping'
              )}
            >
              <VkwIconButton>
                <VkwIconInfo />
              </VkwIconButton>
            </div>
            <VkwIconButton onClick={event => setDetailsOpen(true)}>
              <VkwIconInfo />
            </VkwIconButton>
          </div>
          <VkwIconButton onClick={event => setShowMoreMenuConfig({ anchorEl: event.currentTarget, chargePoint })}>
            <VkwIconThreeDots />
          </VkwIconButton>
        </Box>

        {showMoreMenuConfig && (
          <MoreMenu
            anchorEl={showMoreMenuConfig.anchorEl}
            items={getMoreMenuItems()}
            onClose={() => setShowMoreMenuConfig(null)}
          />
        )}

        {showDialog && (
          <VkwDialog
            open={showDialog}
            onSave={onClickConfirmAction}
            onClose={() => {
              setShowMoreMenuConfig(null);
              setShowDialog(false);
            }}
            onCancel={() => {
              setShowMoreMenuConfig(null);
              setShowDialog(false);
            }}
            headline={formatMessage(`Confirm${actionLabel}`)}
            showCloseIcon
            saveButtonLabel={formatMessage(actionLabel)}
            renderContent={() => {
              return (
                <Box pt={1} pb={3.75}>
                  <Typography className={styles.infoText}>{formatMessage(`Info${actionLabel}`)}</Typography>
                  <Box pt={1} />
                  {showMoreMenuConfig && (
                    <Typography className={styles.warning}>{showMoreMenuConfig.chargePoint.evseId}</Typography>
                  )}
                  <Box pt={1} />
                  <Typography className={styles.infoText}>{formatMessage('AreYouSure')}</Typography>
                </Box>
              );
            }}
          />
        )}

        {detailsOpen && (
          <ChargePointItemDetailsOverlay evseId={chargePoint.evseId} detailsOpen setDetailsOpen={setDetailsOpen} />
        )}
      </div>
      <div
        className={clsx(
          bgColor[chargePoint.status],
          'flex w-full items-center justify-between border-t border-black/10 p-2 text-xs text-white'
        )}
      >
        <div className="flex items-center">
          <div className={styles.plugs}>
            {chargePoint.plugs.map((plug, i) => {
              const PlugIcon = getPlugIcon(plug);
              return (
                <span className={styles.plug} key={i}>
                  <PlugTooltip plug={plug}>
                    <PlugIcon />
                  </PlugTooltip>
                </span>
              );
            })}
          </div>
          <div className="opacity-75">
            {formatMessage('MaxPower')} {Math.round(chargePoint.maxPower)} kW
          </div>
        </div>
        <span className="opacity-75">{formatMessage(`ChargeStationStatus${chargeStationPin.status}`)}</span>
      </div>
    </div>
  );
};
