import React, { ReactElement, createContext, ReactNode, useContext, useState, useMemo, useEffect } from 'react';

import { apiFetch } from '../../utils/apiFetch';

export interface VkwStickyBannerContextProps {
  show: boolean;
  userClosed: boolean;
  title: string;
  body: string;
  setShow: (show: boolean) => void;
  setTitle: (title: string) => void;
  setBody: (body: string) => void;
  updateStatus: () => void;
  setUserClosed: (userClosed: boolean) => void;
}

const VkwStickyBannerContext = createContext<VkwStickyBannerContextProps | null>(null);

export interface VkwStickyBannerContextProviderProps {
  children: ReactNode;
}

export const VkwStickyBannerContextProvider = ({ children }: VkwStickyBannerContextProviderProps): ReactElement => {
  const [show, setShow] = useState(false);
  const [userClosed, setUserClosed] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  useEffect(() => {
    updateStatus();
  }, []);

  const updateStatus = () => {
    apiFetch('/api/next/v1/app-info/status')
      .then(response => response.json())
      .then(data => {
        if (!data.infoBanner) {
          setShow(false);
        } else {
          setShow(true);
          setUserClosed(false);
          setTitle(data.infoBanner.title);
          setBody(data.infoBanner.message);
        }
      })
      .catch(error => {
        console.error('Error fetching status:', error);
      });
  };

  const contextValue: VkwStickyBannerContextProps = useMemo(() => {
    return {
      body,
      setBody,
      setShow,
      setTitle,
      setUserClosed,
      show,
      title,
      updateStatus,
      userClosed,
    };
  }, [body, setBody, setShow, setTitle, show, title, setUserClosed, userClosed]);

  return <VkwStickyBannerContext.Provider value={contextValue}>{children}</VkwStickyBannerContext.Provider>;
};

export const useVkwStickyBanner = (): VkwStickyBannerContextProps => {
  const context = useContext(VkwStickyBannerContext);

  if (!context) {
    throw new Error('Use "VkwStickyBannerContextProvider" somewhere');
  }

  return context;
};
