import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconMinus = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeWidth={strokewidth}>
        <line fill="none" stroke={fill} x1="22" x2="2" y1="12" y2="12" />
      </g>
    </VkwIcon>
  );
};
