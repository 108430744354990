import React, { ReactElement } from 'react';

import { Pagination } from '@material-ui/lab/';

import useStyles from './VkwBaseTableStyles';

export interface VkwBaseTableFooterProps {
  /**
   * Aktuelle Seite
   */
  page: number;
  onPageChange: (page: number) => void;
  /**
   * Anzahl Seiten
   */
  count: number;
}

export const VkwBaseTableFooter = ({ count, onPageChange, page }: VkwBaseTableFooterProps): ReactElement => {
  const styles = useStyles();

  return (
    <div className={styles.footer}>
      <Pagination
        className={styles.pagination}
        page={page}
        count={count}
        shape="round"
        onChange={(_, page) => onPageChange(page)}
        siblingCount={0}
      />
    </div>
  );
};
