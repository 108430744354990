import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconInstagram = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;
  const strokewidth = 0.5;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill} stroke={secondaryfill} strokeWidth={strokewidth}>
        <path d="M18 0c3.084 0 6 2.916 6 6v12c0 3.084-2.916 6-6 6H6c-3.084 0-6-2.916-6-6V6c0-3.084 2.916-6 6-6h12zm0 3H6C4.598 3 3 4.598 3 6v12c0 1.43 1.57 3 3 3h12c1.402 0 3-1.598 3-3V6c0-1.402-1.598-3-3-3zm-6 3c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6zm0 3c-1.655 0-3 1.345-3 3s1.345 3 3 3 3-1.345 3-3-1.345-3-3-3zm6.218-4.602a1.44 1.44 0 1 1 0 2.88 1.44 1.44 0 0 1 0-2.88z" />
      </g>
    </VkwIcon>
  );
};
