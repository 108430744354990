import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconRemove = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize <= 12) {
          return (
            <path
              fill={fill}
              fillRule="evenodd"
              transform="scale(2)"
              d="M6 0c3.312.003 5.997 2.688 6 6 0 3.314-2.686 6-6 6S0 9.314 0 6s2.686-6 6-6zm0 2C3.79 2 2 3.79 2 6s1.79 4 4 4c1.06 0 2.078-.421 2.828-1.172C9.578 8.078 10 7.061 10 6c0-2.21-1.79-4-4-4zm2 1.5c.133 0 .26.052.354.146.094.094.147.221.147.354 0 .133-.053.26-.147.354L6.707 6l1.647 1.646c.126.126.176.31.13.484-.047.172-.182.307-.354.354-.173.046-.358-.004-.484-.13L6 6.707 4.354 8.354c-.196.196-.512.196-.708 0-.196-.196-.196-.512 0-.708L5.293 6 3.646 4.354c-.196-.196-.196-.512 0-.708.196-.196.512-.196.708 0L6 5.293l1.646-1.647c.094-.094.221-.147.354-.147z"
            />
          );
        }

        return (
          <g fill={secondaryfill}>
            <path
              d="M12,0A12,12,0,1,0,24,12,12.035,12.035,0,0,0,12,0Zm4.95,15.536L15.536,16.95,12,13.414,8.464,16.95,7.05,15.536,10.586,12,7.05,8.464,8.464,7.05,12,10.586,15.536,7.05,16.95,8.464,13.414,12Z"
              fill={fill}
            />
          </g>
        );
      }}
    </VkwIcon>
  );
};
