import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { AuthenticationStoreModel } from './AuthenticationStore';
import { ChargesStoreModel } from './ChargesStore';
import { InfrastructureStoreModel } from './InfrastructureStore';
import { MapStoreModel } from './MapStore';
import { SettingsStoreModel } from './SettingsStore';
import { TabBarStoreModel } from './TabBarStore';
import { UserStoreModel } from './UserStore';

/**
 * A RootStore model.
 */
export const RootStoreModel = types.model('RootStore').props({
  authenticationStore: types.optional(AuthenticationStoreModel, {}),
  chargesStore: types.optional(ChargesStoreModel, {}),
  infrastructureStore: types.optional(InfrastructureStoreModel, {}),
  mapStore: types.optional(MapStoreModel, {}),
  settingsStore: types.optional(SettingsStoreModel, {}),
  tabBarStore: types.optional(TabBarStoreModel, {}),
  userStore: types.optional(UserStoreModel, {}),
});

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>;
/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;

export function createEmptyRootStoreSnapshot(): RootStore {
  return {
    authenticationStore: AuthenticationStoreModel.create({}),
    chargesStore: ChargesStoreModel.create({}),
    infrastructureStore: InfrastructureStoreModel.create({}),
    mapStore: MapStoreModel.create({}),
    settingsStore: SettingsStoreModel.create({}),
    tabBarStore: TabBarStoreModel.create({}),
    userStore: UserStoreModel.create({}),
  };
}
