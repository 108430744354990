import React, { ReactElement, ReactNode } from 'react';

import { TableCell, TableCellProps } from '@material-ui/core';
import clsx from 'clsx';

import useStyles from './VkwBaseTableStyles';

export interface VkwBaseTableCellProps extends TableCellProps {
  bold?: boolean;
  collapse?: boolean;
  children?: ReactNode;
}

export const VkwBaseTableCell = ({ bold, children, collapse, ...rest }: VkwBaseTableCellProps): ReactElement => {
  const styles = useStyles();

  const itemClass = clsx(styles.tableCell, {
    [styles.tableCellBold]: bold,
    [styles.tableCellCollapse]: collapse,
    [styles.tableCellDefault]: !bold,
  });

  return (
    <TableCell className={itemClass} {...rest}>
      <div className={styles.tableCellInner}>{children}</div>
    </TableCell>
  );
};
