import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconMoney = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      {fontSize => {
        if (fontSize <= 16) {
          return (
            <g fill="none" fillRule="evenodd" transform="scale(1.5)">
              <path
                fill={fill}
                d="M9.633 0v1.584c.98.123 1.928.377 2.618.708l.217.112.876.482-.964 1.752-.876-.482c-.427-.235-1.121-.437-1.87-.553v3.749c2.21.811 3.098 1.591 3.098 3.38 0 1.994-1.196 3.233-3.098 3.628L9.633 16h-2v-1.515c-1.07-.058-2.222-.29-3.328-.654l-.364-.126-.941-.34.679-1.881.94.34c1.004.361 2.065.592 3.015.657L7.632 8.78C5.06 7.875 3.957 6.966 3.957 4.86c0-2.04 1.495-3.135 3.676-3.33V0h2zm0 9.506l.002 2.79c.75-.255 1.097-.745 1.097-1.564 0-.517-.261-.849-1.099-1.226zM5.957 4.86c0 .818.407 1.27 1.675 1.783V3.542c-1.142.134-1.675.564-1.675 1.319z"
              />
            </g>
          );
        }

        return (
          <g fill={fill}>
            <path
              d="M9.952,9.051a1.171,1.171,0,0,0,.313.835,3.175,3.175,0,0,0,1.129.659V7.812a2.052,2.052,0,0,0-1.073.431A1.032,1.032,0,0,0,9.952,9.051Z"
              fill={fill}
            />

            <path
              d="M12.562,12.98v2.8q1.548-.239,1.547-1.327a1.093,1.093,0,0,0-.356-.84A3.658,3.658,0,0,0,12.562,12.98Z"
              fill={fill}
            />

            <path
              d="M21,0H3A1,1,0,0,0,2,1V23a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V1A1,1,0,0,0,21,0ZM15.252,16.425a4.747,4.747,0,0,1-2.69,1.02v1.907H11.394V17.5a9.1,9.1,0,0,1-3.578-.668V14.975a9,9,0,0,0,1.767.62,8.042,8.042,0,0,0,1.811.259V12.567l-.739-.273a5.2,5.2,0,0,1-2.1-1.322,2.813,2.813,0,0,1-.663-1.9,2.477,2.477,0,0,1,.944-2,4.678,4.678,0,0,1,2.563-.949V4.648h1.168V6.1a9.668,9.668,0,0,1,3.384.721l-.641,1.608a9.423,9.423,0,0,0-2.743-.65v3.2l.668.255a6.113,6.113,0,0,1,2.312,1.353,2.507,2.507,0,0,1,.642,1.741A2.683,2.683,0,0,1,15.252,16.425Z"
              fill={fill}
            />
          </g>
        );
      }}
    </VkwIcon>
  );
};
