import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugCcsCombo1 = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M16.5 17a3.5 3.5 0 1 1 0 7h-9a3.5 3.5 0 1 1 0-7h9zM8 18.5a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 18.5zm8 0a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 18.5zM15 0v1.582a8 8 0 1 1-6-.001V0h6zm-3 11a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 11zm-4-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm8 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM8 4a2 2 0 1 0-.001 3.999A2 2 0 0 0 8 4zm8 0a2 2 0 1 0-.001 3.999A2 2 0 0 0 16 4z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </VkwIcon>
  );
};
