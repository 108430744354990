import React, { ReactElement, useMemo } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';
import { FormattedDate } from 'react-intl';

import { TicketResponse } from './const';
import { TicketStatusBadge } from '../../components';
import {
  VkwDataSectionItem,
  VkwDataSection,
  VkwLoader,
  VkwTheme,
  dataSectionDatastring,
  getVkwTypographyStyle,
  useVkwFormatMessage,
  toFirstUppercase,
} from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  description: {
    borderBottom: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
    borderTop: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  descriptionText: {
    wordWrap: 'break-word',
  },
  descriptionTitle: {
    color: theme.palette.text.secondary,
    ...getVkwTypographyStyle('text12', theme),
  },
  overview: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: '100%',
    minWidth: '100%',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },

    width: '100%',
  },
  overviewText: {
    color: theme.palette.text.primary,
    ...getVkwTypographyStyle('text14', theme),
  },
}));

interface TicketDetailProps {
  ticketData: TicketResponse | null;
}

export const TicketDetail = ({ ticketData }: TicketDetailProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const styles = useStyles();

  const dataSectionItems: VkwDataSectionItem[] = useMemo(() => {
    const dataSections: VkwDataSectionItem[] = [];

    if (!ticketData) {
      return dataSections;
    }

    dataSections.push({
      renderContent: () => dataSectionDatastring(ticketData.ticketUser),
      title: formatMessage('Customer'),
      xs: 12,
    });

    dataSections.push({
      renderContent: function statusContent() {
        return ticketData.status ? <TicketStatusBadge status={ticketData.status} /> : '-';
      },
      title: formatMessage('Status'),
      xs: 6,
    });

    dataSections.push({
      renderContent: () =>
        ticketData.paymentType ? formatMessage(`PaymentType${toFirstUppercase(ticketData.paymentType)}`) : '-',
      title: formatMessage('PaymentType'),
      xs: 6,
    });

    dataSections.push({
      renderContent: () => dataSectionDatastring(ticketData.otherProviderInfo),
      title: formatMessage('OtherProviderInfo'),
      xs: 6,
    });

    dataSections.push({
      renderContent: () => dataSectionDatastring(ticketData.evseId),
      title: formatMessage('EvseId'),
      xs: 6,
    });

    dataSections.push({
      renderContent: () => dataSectionDatastring(ticketData.ticketId),
      title: formatMessage('TicketId'),
      xs: 6,
    });

    dataSections.push({
      renderContent: function creationDate() {
        if (ticketData.creationDate) {
          return (
            <FormattedDate
              value={ticketData.creationDate}
              year="numeric"
              month="2-digit"
              day="2-digit"
              hour="2-digit"
              minute="2-digit"
            />
          );
        }

        return '-';
      },
      title: formatMessage('CreationDate'),
      xs: 6,
    });

    return dataSections;
  }, [ticketData]);

  if (!ticketData) {
    return <VkwLoader />;
  }

  return (
    <Box maxWidth="1000px">
      <Box className={styles.overview}>
        {ticketData.status && <TicketStatusBadge status={ticketData.status} />}

        <Box pl={1}>
          <Typography className={styles.overviewText}>
            {ticketData.modificationDate && (
              <FormattedDate
                value={ticketData.modificationDate}
                year="numeric"
                month="2-digit"
                day="2-digit"
                hour="2-digit"
                minute="2-digit"
              />
            )}
            {ticketData.ticketUser ? ' · ' : ''}
            {ticketData.ticketUser ?? ''}
          </Typography>
        </Box>
      </Box>

      <Box className={styles.description}>
        <Typography className={styles.descriptionTitle}>{formatMessage('Description')}</Typography>
        <Typography className={styles.descriptionText}>{ticketData.description}</Typography>
      </Box>

      <VkwDataSection headline="" items={dataSectionItems} />
    </Box>
  );
};
