import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugTyp1 = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M12 0c6.63 0 12 5.376 12 12s-5.37 12-12 12S0 18.624 0 12 5.37 0 12 0zm0 16a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-6-3a2 2 0 1 0-.001 3.999A2 2 0 0 0 6 13zm12 0a2 2 0 1 0-.001 3.999A2 2 0 0 0 18 13zM8 4a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm8 0a3 3 0 1 0 0 6 3 3 0 0 0 0-6z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </VkwIcon>
  );
};
