import React, { ReactNode, ReactElement } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { useVkwNavigation } from '../../contexts';
import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    content: {
      height: '100%',
      overflow: 'auto',
      width: '100%',
    },
    footer: {
      bottom: 0,
      display: 'flex',
      justifyContent: 'space-between',
      left: 0,
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      position: 'absolute',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },

      width: `calc(100% - ${theme.spacing(6)}px)`,
    },
    footerLeft: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    footerRight: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
    header: {
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(2),
      position: 'absolute',
      right: 0,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },

      top: 0,
    },
  }),
  { index: 1 }
);

export interface VkwFullLayoutProps {
  /**
   * Inhalt des Layouts
   */
  children: ReactNode;
}

export const VkwFullLayout = ({ children }: VkwFullLayoutProps): ReactElement => {
  const styles = useStyles();
  const navigation = useVkwNavigation();

  return (
    <Box height="100%" width="100%" position="relative">
      <Box className={styles.content}>{children}</Box>
      <Box className={styles.header}>{navigation.renderHeader()}</Box>
      <Box className={styles.footer}>
        <Box className={styles.footerRight}>
          {navigation.renderSocialLinks()}
          {navigation.renderCopyright()}
        </Box>
        <Box className={styles.footerLeft}>{navigation.renderFooter()}</Box>
      </Box>
    </Box>
  );
};
