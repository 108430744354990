import React, { forwardRef, ReactElement } from 'react';

import { TableRow, TableRowProps, makeStyles } from '@material-ui/core';

import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme, { clickable: boolean }>(
  theme => ({
    tableRow: props => {
      if (props.clickable) {
        return {
          '&:active': {
            backgroundColor: theme.palette.action.active,
          },
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
            cursor: 'pointer',
          },
        };
      }

      return {};
    },
  }),
  { index: 1 }
);

// eslint-disable-next-line react/display-name
export const VkwBaseTableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ children, onClick, ...rest }: TableRowProps, ref): ReactElement => {
    const styles = useStyles({ clickable: !!onClick });

    return (
      <TableRow className={styles.tableRow} onClick={onClick} {...rest} ref={ref}>
        {children}
      </TableRow>
    );
  }
);
