import { makeStyles } from '@material-ui/core';

import { getVkwTypographyStyle, VkwTheme } from '../../library';

export const useStyles = makeStyles<VkwTheme>(theme => ({
  chartTitle: {
    border: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
    padding: 0,
    ...getVkwTypographyStyle('link14', theme),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 1.5),
    },
  },
  chartTitleText: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  chartWrapper: {
    background: theme.palette.type === 'dark' ? theme.palette.common.black : 'transparent',
    border: `1px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]}`,
    borderTop: 'none',
    height: '600px',
  },
  filterBox: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  statisticTimespanLabel: {
    ...getVkwTypographyStyle('text14', theme),

    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  timespanFilter: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
}));

export const getGraphStyles = (
  theme: VkwTheme
): { fillOpacity: number; fill: string; stroke: string; strokeWidth: number } => {
  return {
    fill: theme.palette.detailGraphColor,
    fillOpacity: 0.2,
    stroke: theme.palette.detailGraphColor,
    strokeWidth: 2,
  };
};

export const getAxisStyles = (
  theme: VkwTheme
): {
  axis: { stroke: string };
  ticks: { stroke: string };
  tickLabels: { fill: string; fontFamily: string };
  axisLabel: { fill: string; angle: number; fontFamily: string };
} => {
  return {
    axis: { stroke: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black },
    axisLabel: {
      angle: 360,
      fill: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
      fontFamily: 'Inter',
    },
    tickLabels: {
      fill: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
      fontFamily: 'Inter',
    },

    ticks: { stroke: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black },
  };
};
