import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconCharging = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill}>
        <path
          d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12h1v-5h-2v2.95C5.954,21.447,2,17.177,2,12C2,6.486,6.486,2,12,2 s10,4.486,10,10c0,2.78-1.114,5.363-3.137,7.272l-0.727,0.687l1.373,1.455l0.727-0.687C22.664,18.436,24,15.336,24,12 C24,5.383,18.617,0,12,0z"
          fill={fill}
        />
        <path d="M16,5h-2v3h-4V5H8v3H6v4c0,3.309,2.691,6,6,6s6-2.691,6-6V8h-2V5z" />
      </g>
    </VkwIcon>
  );
};
