import React, { ReactElement, ChangeEvent, ReactNode, useEffect, useState, FocusEvent } from 'react';

import { Grid, Slider, Box } from '@material-ui/core';

import { useVkwTheme } from '../../../themes';
import { VkwBaseFilter } from '../../VkwBaseFilter';
import { VkwTextField } from '../../VkwTextField';

export interface VkwNumberBetweenFilterProps {
  values: number[];
  title: string;
  /**
   * Zusätzliche Überschrift innerhalb des Popovers / Filters
   */
  headline?: string;
  onChange: (value: number[]) => void;
  min: number;
  max: number;
  mode: 'desktop' | 'mobile';
}

const convertToNumber = (input: string | null): number => (input && input.length ? parseFloat(input) : 0);

export const VkwNumberBetweenFilter = ({
  headline,
  max,
  min,
  mode,
  onChange,
  title,
  values,
}: VkwNumberBetweenFilterProps): ReactElement => {
  const theme = useVkwTheme();
  const [newValues, setNewValues] = useState(values);

  const currentMinValue = newValues[0] ?? min;
  const currentMaxValue = newValues[1] ?? max;

  const processAndSetNewValues = (values: number[]) => {
    setNewValues(values[0] !== min || values[1] !== max ? values : []);
  };

  useEffect(() => {
    setNewValues(values);
  }, [values]);

  const validateMinValue = (valueString: string | null): number => {
    let value = convertToNumber(valueString);
    if (Number.isNaN(value) || value < min) {
      value = min;
    }

    if (value > max) {
      value = max;
    }

    return value;
  };

  const validateMaxValue = (valueString: string | null): number => {
    let value = convertToNumber(valueString);
    if (Number.isNaN(value) || value > max) {
      value = max;
    }

    if (value < min) {
      value = min;
    }

    return value;
  };

  const handleSliderChange = (_event: ChangeEvent<Record<string, unknown>>, newValue: number | number[]): void => {
    processAndSetNewValues(newValue as number[]);
  };

  const handleMinValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    processAndSetNewValues([convertToNumber(event.target.value), currentMaxValue]);
  };

  const handleMaxValueChange = (event: ChangeEvent<HTMLInputElement>): void => {
    processAndSetNewValues([currentMinValue, convertToNumber(event.target.value)]);
  };

  const handleMinValueBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const validatedValue = validateMinValue(event.target.value);
    processAndSetNewValues([validatedValue, currentMaxValue]);
  };

  const handleMaxValueBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const validatedValue = validateMaxValue(event.target.value);
    processAndSetNewValues([currentMinValue, validatedValue]);
  };

  const handleFilterChange = (): void => {
    onChange(newValues);
  };

  const handleFilterReset = (): void => {
    processAndSetNewValues([]);
  };

  const renderHeadline = (): ReactNode => {
    if (!headline) {
      return null;
    }
    return <div>{headline}</div>;
  };

  const renderMenu = (): ReactNode => {
    return (
      <>
        <Box paddingY={1}>
          <Slider
            value={[currentMinValue, currentMaxValue]}
            max={max}
            min={min}
            onChange={handleSliderChange}
            color={theme.palette.mainSliderColor}
          />
        </Box>
        <Box paddingY={1}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item>
              <VkwTextField
                fullWidth
                name="minValue"
                value={currentMinValue}
                onChange={handleMinValueChange}
                type="number"
                variant="outlined"
                inputProps={{
                  max: max.toString(),
                  min: min.toString(),
                  onBlur: handleMinValueBlur,
                }}
              />
            </Grid>
            <Grid item>
              <VkwTextField
                fullWidth
                name="maxValue"
                value={currentMaxValue}
                onChange={handleMaxValueChange}
                type="number"
                variant="outlined"
                inputProps={{
                  max: max.toString(),
                  min: min.toString(),
                  onBlur: handleMaxValueBlur,
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  const showSave = newValues[0] !== values[0] || newValues[1] !== values[1];
  const showReset = currentMinValue !== min || currentMaxValue !== max;

  return (
    <VkwBaseFilter
      onSave={showSave ? handleFilterChange : undefined}
      onClose={() => {
        setNewValues(values);
      }}
      active={values.length > 0}
      onReset={showReset ? handleFilterReset : undefined}
      title={title}
      renderAdditionalHeader={renderHeadline}
      renderMenu={renderMenu}
      mode={mode}
    />
  );
};
