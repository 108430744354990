import { makeStyles, lighten, darken } from '@material-ui/core';

import { VkwTheme, getVkwTypographyStyle } from '../../library';

const hackyStyles = (theme: VkwTheme) => ({
  '& .MuiButton-textPrimary': {
    '&:active': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? lighten(
              theme.palette.mainButtonColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
              0.2
            )
          : darken(
              theme.palette.mainButtonColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
              0.2
            ),
    },
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark'
          ? lighten(
              theme.palette.mainButtonColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
              0.1
            )
          : darken(
              theme.palette.mainButtonColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
              0.1
            ),
    },
    backgroundColor:
      theme.palette.mainButtonColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,

    color:
      theme.palette.mainButtonColor === 'primary'
        ? theme.palette.primary.contrastText
        : theme.palette.secondary.contrastText,

    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
  },
  '& .MuiButton-textPrimary:nth-child(1)': {
    '&:active': {
      backgroundColor:
        theme.palette.type === 'dark' ? lighten(theme.palette.grey[600], 0.2) : darken(theme.palette.grey[300], 0.2),
    },
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark' ? lighten(theme.palette.grey[600], 0.1) : darken(theme.palette.grey[300], 0.1),
    },
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[600] : theme.palette.grey[300],

    color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,

    padding: `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`,
  },

  '& .MuiDialog-paper': {
    background: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.common.white,
    border: theme.palette.type === 'dark' ? `1px solid ${theme.palette.grey[700]}` : `none`,
  },

  '& .MuiPickersCalendar-week .MuiIconButton-root': {
    borderRadius: '2px',
  },

  '& .MuiPickersCalendarHeader-iconButton': {
    '&:hover': {
      backgroundColor:
        theme.palette.type === 'dark' ? lighten(theme.palette.grey[800], 0.1) : darken(theme.palette.common.white, 0.1),
    },

    backgroundColor: theme.palette.type === 'dark' ? theme.palette.grey[800] : theme.palette.common.white,
  },

  '& .MuiPickersDay-current': {
    color: theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.common.black,
  },

  '& .MuiPickersDay-daySelected': {
    backgroundColor:
      theme.palette.mainSliderColor === 'primary' ? theme.palette.primary.main : theme.palette.secondary.main,
    color:
      theme.palette.mainSliderColor === 'primary'
        ? theme.palette.primary.contrastText
        : theme.palette.secondary.contrastText,
  },
});

export const useStyles = makeStyles<VkwTheme>(theme => ({
  button: {
    '&&': {
      backgroundColor: theme.palette.type === 'light' ? 'transparent' : theme.palette.grey[700],
      border:
        theme.palette.type === 'light'
          ? `1px solid ${theme.palette.grey[200]}`
          : `2px solid ${theme.palette.grey[700]}`,
      borderRadius: 20,
      color: theme.palette.text.primary,
      fontWeight: 'normal',
    },
    '&:hover': {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[600],
      borderColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[600],
    },
  },
  buttonActive: {
    '&&': {
      borderColor: theme.palette.type === 'light' ? theme.palette.grey[700] : theme.palette.common.white,
      fontWeight: 'bold',
    },
  },
  buttonOpen: {
    '&&': {
      backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
  },
  datePicker: hackyStyles(theme),
  filterContainer: {
    color: theme.palette.text.primary,
    ...getVkwTypographyStyle('text14', theme),
    maxWidth: '100%',
    padding: theme.spacing(1),
    width: 'auto',
  },
  footer: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  header: {
    ...getVkwTypographyStyle('text12', theme),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  input: {
    '&.Mui-focused': {
      paddingRight: 8,
    },
    '&.MuiInputBase-root': {
      ...getVkwTypographyStyle('text14', theme),
      paddingRight: 10,
    },
    '&.MuiInputBase-root input': {
      color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
    },
  },
  label: {
    '&.Mui-focused': {
      color: theme.palette.grey[500],
    },
    '&.MuiFormLabel-root': {
      ...getVkwTypographyStyle('text12', theme),
      color: theme.palette.grey[500],
    },
  },
  menu: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
}));
