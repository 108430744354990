import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugTyp13 = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M14.093 6.005a7 7 0 0 1 5.241 2.36l2.488 2.81c.67.756.67 1.894 0 2.65l-2.488 2.81a7 7 0 0 1-5.24 2.36H9.896a7.003 7.003 0 0 1-5.241-2.36l-2.487-2.81a1.998 1.998 0 0 1 0-2.65l2.487-2.81a7.003 7.003 0 0 1 5.241-2.36h4.196zM12 13a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 12 13zm5.5-3a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 17.5 10zm-11 0a1.5 1.5 0 1 0 .001 3.001A1.5 1.5 0 0 0 6.5 10z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </VkwIcon>
  );
};
