import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconClose = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;
  const strokewidth = 1;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill} stroke={secondaryfill} strokeWidth={strokewidth}>
        <path d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z" />
      </g>
    </VkwIcon>
  );
};
