import React, { ReactElement, ReactNode, useCallback, useState, ChangeEvent, FocusEvent } from 'react';

import { FormControl, InputLabel, MenuItem, Select, Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

import { getVkwTypographyStyle } from '../../helper';
import { useVkwIsMobile, useVkwFormatMessage } from '../../hooks';
import { VkwIconArrowDown, VkwIconError } from '../../icons';
import { VkwTheme } from '../../themes';

const useStyles = makeStyles<VkwTheme, { label: ReactNode }>(
  theme => ({
    dropDownIcon: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      pointerEvents: 'none',
      position: 'absolute',
      right: '7px',
    },
    errorIcon: props => ({
      color: theme.palette.error.main,
      height: theme.typography.pxToRem(15),
      pointerEvents: 'none',
      position: 'absolute',
      right: theme.spacing(4),
      top: props.label ? theme.spacing(3.1) : theme.spacing(2.1),
      width: theme.typography.pxToRem(15),
    }),
    errorMessage: {
      ...getVkwTypographyStyle('link14', theme),
      color: theme.palette.error.main,
      paddingTop: theme.spacing(0.5),
    },
    focused: {
      color: theme.palette.grey[500],
    },
    input: props => ({
      boxSizing: 'border-box',
      color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
      height: '55px',
      paddingBottom: props.label ? theme.spacing(1) : theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingTop: props.label ? theme.spacing(3.2) : theme.spacing(2),
      ...getVkwTypographyStyle('text14', theme),
      '&:focus-within': {
        marginBottom: '-2px',
      },
    }),
    label: {
      left: theme.spacing(2),
      ...getVkwTypographyStyle('text12', theme),
      color: theme.palette.grey[500],
      lineHeight: 1,
      pointerEvents: 'none',
    },
    popover: props => ({
      marginTop: props.label ? theme.spacing(1) : 0,
    }),
    select: {
      boxSizing: 'border-box',
      height: '55px',
      position: 'relative',
      ...getVkwTypographyStyle('text14', theme),
    },
    shrunk: {
      transform: 'translate(0, 13.5px) scale(0.73)',
    },
  }),
  { index: 1 }
);

type ValueType = string | number | string[];

export interface VkwSelectItem {
  label: ReactNode;
  value: ValueType;
}

export interface VkwSelectProps {
  name: string;
  /**
   * Text der Links-Oben in der Komponente angezeigt wird
   */
  label?: string;
  onChange?: (event: ChangeEvent<{ name?: string; value: unknown }>, child: ReactNode) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  /**
   * Selektierter Wert
   */
  value?: unknown;
  placeholder?: string;
  /**
   * Standardwert der gesendet wird wenn kein Wert ausgewählt wurde. Wenn dieser Wert nicht gesetzt ist wird der erste Wert aus items als Standardwert benutzt.
   */
  chooseOptionValue?: ValueType;
  /**
   * Auswählbare Items im Dropdown.
   */
  items?: VkwSelectItem[];
  tabIndex?: number;
  error?: string;
  disabled?: boolean;
  required?: boolean;
  /**
   * Wenn gesetzt, expandiert sich das Label der Komponente als Standardwert. Ohne Label ist die Komponente standardmässig leer.
   */
  noDefault?: boolean;
}

export const VkwSelect = ({
  chooseOptionValue,
  error,
  items = [],
  label,
  name,
  noDefault,
  onBlur,
  placeholder,
  required,
  ...rest
}: VkwSelectProps): ReactElement => {
  const mobile = useVkwIsMobile();
  const classes = useStyles({ label });
  const formatMessage = useVkwFormatMessage();
  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

  const renderDropDownElement = (value: ValueType, label: string | ReactNode, key: number, disabled?: boolean) => {
    const elementProps = { key, value };
    return mobile ? (
      <option {...elementProps} disabled={disabled} style={disabled ? { display: 'none' } : undefined}>
        {label}
      </option>
    ) : (
      <MenuItem {...elementProps} disabled={disabled}>
        {label}
      </MenuItem>
    );
  };

  const renderItems = () => {
    const menuitems = [];
    if (chooseOptionValue) {
      menuitems.push(renderDropDownElement(chooseOptionValue, formatMessage('ChooseOption'), 0));
    } else if (mobile && noDefault) {
      menuitems.push(renderDropDownElement('', '', 0, true));
    }
    menuitems.push(
      items.map((selectitem, i) => {
        return renderDropDownElement(selectitem.value, selectitem.label, i + 1);
      })
    );
    return menuitems;
  };

  const renderIcon = useCallback(
    () => (
      <Box className={clsx(classes.dropDownIcon, 'MuiSelect-iconFilled', dropDownOpen && 'MuiSelect-iconOpen')}>
        <VkwIconArrowDown size={12} />
      </Box>
    ),
    [dropDownOpen]
  );

  return (
    <FormControl className={classes.formControl} fullWidth>
      <InputLabel
        id={`select-${name}-label`}
        htmlFor={`select-${name}`}
        shrink={noDefault ? undefined : true}
        className={classes.label}
        classes={{ focused: classes.focused, shrink: classes.shrunk }}
        required={required}
      >
        {label}
      </InputLabel>
      <Select
        native={mobile}
        onBlur={onBlur}
        fullWidth
        variant="filled"
        className={classes.select}
        name={name}
        error={!!error}
        defaultValue={noDefault ? '' : chooseOptionValue ?? items[0].value ?? undefined}
        inputProps={{
          className: classes.input,
          id: `select-${name}`,
          name,
          placeholder,
        }}
        {...rest}
        MenuProps={{
          PopoverClasses: { root: classes.popover },
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          getContentAnchorEl: null,
          transformOrigin: {
            horizontal: 'left',
            vertical: 'top',
          },
        }}
        IconComponent={renderIcon}
        open={dropDownOpen}
        onOpen={() => setDropDownOpen(true)}
        onClose={() => setDropDownOpen(false)}
        labelId={`select-${name}-label`}
      >
        {renderItems()}
      </Select>
      {error && (
        <Box className={classes.errorIcon}>
          <VkwIconError size={16} />
        </Box>
      )}
      {error && <Box className={classes.errorMessage}>{error}</Box>}
    </FormControl>
  );
};
