import { useMemo } from 'react';

import { ChargeStation } from './stores';
import { useConfigContext } from '../contexts';

function calculateDistance(pos1: google.maps.LatLngLiteral, pos2: google.maps.LatLngLiteral): number {
  const R = 6371; // km (change this constant to get miles)
  const dLat = ((pos2.lat - pos1.lat) * Math.PI) / 180;
  const dLon = ((pos2.lng - pos1.lng) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((pos1.lat * Math.PI) / 180) *
      Math.cos((pos2.lat * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d;
}

export const useChargeStationDistance = (
  currentPosition: google.maps.LatLngLiteral,
  chargeStation: ChargeStation
): string => {
  const configContext = useConfigContext();

  const distance = useMemo(() => {
    const distance = calculateDistance(currentPosition, {
      lat: chargeStation.location.latitude,
      lng: chargeStation.location.longitude,
    });

    if (distance < 0.1) {
      return `${Math.round(distance * 1000).toLocaleString(configContext.config.locale)} m`;
    }

    if (distance < 10) {
      return `${(Math.round(distance * 10) / 10).toLocaleString(configContext.config.locale)} km`;
    }

    return `${Math.round(distance).toLocaleString(configContext.config.locale)} km`;
  }, [chargeStation.location]);

  return distance;
};
