import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconCenterLocation = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill={fill} fillRule="nonzero">
          <path d="M12,8.36363636 C9.99090909,8.36363636 8.36363636,9.99090909 8.36363636,12 C8.36363636,14.0090909 9.99090909,15.6363636 12,15.6363636 C14.0090909,15.6363636 15.6363636,14.0090909 15.6363636,12 C15.6363636,9.99090909 14.0090909,8.36363636 12,8.36363636 Z M20.1272727,11.0909091 C19.7090909,7.3 16.7,4.29090909 12.9090909,3.87272727 L12.9090909,2 L11.0909091,2 L11.0909091,3.87272727 C7.3,4.29090909 4.29090909,7.3 3.87272727,11.0909091 L2,11.0909091 L2,12.9090909 L3.87272727,12.9090909 C4.29090909,16.7 7.3,19.7090909 11.0909091,20.1272727 L11.0909091,22 L12.9090909,22 L12.9090909,20.1272727 C16.7,19.7090909 19.7090909,16.7 20.1272727,12.9090909 L22,12.9090909 L22,11.0909091 L20.1272727,11.0909091 Z M12,18.3636364 C8.48181818,18.3636364 5.63636364,15.5181818 5.63636364,12 C5.63636364,8.48181818 8.48181818,5.63636364 12,5.63636364 C15.5181818,5.63636364 18.3636364,8.48181818 18.3636364,12 C18.3636364,15.5181818 15.5181818,18.3636364 12,18.3636364 Z" />
        </g>
      </g>
    </VkwIcon>
  );
};
