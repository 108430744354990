import React, { ReactElement } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import { ChargeStationPlugs } from './ChargeStationPlugs';
import { ChargeStationRouteLink } from './ChargeStationRouteLink';
import { ChargeAtlasStore, ChargeStation } from '../../hooks';
import { VkwTheme, getVkwTypographyStyle } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  address: {
    ...getVkwTypographyStyle('text14', theme),
  },
  nearbyStations: {
    background: theme.palette.background.default,
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(2),
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    width: '100%',

    zIndex: theme.zIndex.speedDial,
  },
  stationName: {
    ...getVkwTypographyStyle('h9', theme),
  },
}));

interface ChargeStationSummaryProps {
  chargeStation: ChargeStation;
  currentPosition: google.maps.LatLngLiteral;
}

const ChargeStationSummary = ({ chargeStation, currentPosition }: ChargeStationSummaryProps): ReactElement => {
  const styles = useStyles();

  return (
    <Box paddingBottom={1.5}>
      <Box display="flex" alignItems="center" paddingTop={1.5}>
        <Box display="flex" flexDirection="column" alignItems="left" paddingRight={1} flexGrow={1}>
          <Typography variant="h6" className={styles.stationName}>
            {chargeStation.stationNameOriginal}
          </Typography>
          <Typography component="span" className={styles.address} color="textSecondary">
            {chargeStation.address}
          </Typography>
        </Box>
        <Box paddingLeft={2}>
          <ChargeStationRouteLink chargeStation={chargeStation} currentPosition={currentPosition} />
        </Box>
      </Box>
      <ChargeStationPlugs chargeStation={chargeStation} />
    </Box>
  );
};

interface ChargeStationsNearbyProps {
  store: ChargeAtlasStore;
}

export const ChargeStationsNearby = ({ store }: ChargeStationsNearbyProps): ReactElement => {
  const styles = useStyles();

  return (
    <Box className={styles.nearbyStations}>
      {store.chargeStationsNearby.map((chargeStation, i) => {
        if (chargeStation.type !== 'charge_station') {
          return null;
        }

        return <ChargeStationSummary key={i} currentPosition={store.currentPosition} chargeStation={chargeStation} />;
      })}
    </Box>
  );
};
