import React, { ReactElement, ReactNode } from 'react';

import { Box, Typography, makeStyles } from '@material-ui/core';

import { CommissionDetail } from './CommissionDetail';
import { getCommissionsFromInfrastructureContractChargePoints } from './getCommissionsFromInfrastructureContract';
import { Commission, InfrastructureContractChargePoint } from '../../contexts';
import { VkwAccordion, VkwTheme, getVkwTypographyStyle, useVkwFormatMessage } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  headline: {
    ...getVkwTypographyStyle('h9', theme),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
}));

interface ChargeStationCommissionsProps {
  chargePoints: InfrastructureContractChargePoint[];
}

const renderCommissions = (commissions: Commission[]): ReactNode => {
  return (
    <>
      {commissions.map((commission, key) => (
        <CommissionDetail key={key} commission={commission} />
      ))}
    </>
  );
};

export const ChargeStationCommissions = ({ chargePoints }: ChargeStationCommissionsProps): ReactElement | null => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();
  const commissions = getCommissionsFromInfrastructureContractChargePoints(chargePoints);

  return Object.keys(commissions).length > 0 ? (
    <>
      <Typography className={styles.headline}>{formatMessage('ChargingStationCommissionsTitle')}</Typography>

      {Object.keys(commissions)?.map((key, idx) => (
        <VkwAccordion key={idx} variant="compact" title={key} defaultExpanded={idx === 0}>
          <Box>{renderCommissions(commissions[key])}</Box>
        </VkwAccordion>
      ))}
    </>
  ) : null;
};
