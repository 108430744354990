import React, { ReactNode, useRef, useState } from 'react';

import { Badge, Box, ButtonBase, InputBase, InputBaseComponentProps, makeStyles, Popover } from '@material-ui/core';
import clxs from 'clsx';

import { EvseIdAutocomplete } from './EvseIdAutocomplete';
import { GoogleMapAutoComplete } from './GoogleMapAutoComplete';
import { HelpWizardStep } from '../../components/rn/HelpWizard/HelpWizardStep';
import { HelpWizardSteps } from '../../components/rn/HelpWizard/helpWizardSteps';
import { useUserContext } from '../../contexts';
import { Assets } from '../../globals/configureAssets';
import {
  toFirstUppercase,
  useVkwAssets,
  useVkwFormatMessage,
  VkwIconArrowDown,
  VkwIconChargingstation,
  VkwIconFilter,
  VkwIconPin,
  VkwTheme,
} from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  dropDown: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(1),
    textAlign: 'center',
  },
  filterButton: {
    color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
    padding: theme.spacing(2),
  },
  input: {
    color: theme.palette.type === 'light' ? theme.palette.common.black : theme.palette.common.white,
    padding: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      minWidth: '310px',
    },
  },
  inputContainer: {
    '& > *': {
      width: '100%',
    },
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  popoverButton: {
    display: 'flex',
    justifyContent: 'start',
    padding: theme.spacing(1.5),
  },
  popoverButtonSpacer: {
    background: theme.palette.grey[200],
    height: '1px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  popoverPaper: {
    borderRadius: '2px',
    width: 'auto',
  },
  positionContainer: {
    left: 0,
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    top: 0,
  },
  root: {
    background: theme.palette.background.default,
    borderRadius: '2px',
    boxShadow: theme.shadows[5],
    display: 'flex',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  spacer: {
    background: theme.palette.grey[200],
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: '1px',
  },
}));

interface ChargeAtlasSearchProps {
  onLocationChanged: (location: google.maps.LatLng | null) => void;
  onEvseIdChanged: (evseId: string | null) => void;
  activeFilterCount: number;
  onFilterClick: () => void;
}

const ChargeAtlasSearch = ({
  activeFilterCount,
  onEvseIdChanged,
  onFilterClick,
  onLocationChanged,
}: ChargeAtlasSearchProps) => {
  const classes = useStyles();
  const formatMessage = useVkwFormatMessage();
  const user = useUserContext();
  const [mode, setMode] = useState<'location' | 'evseId'>(user.account?.info.isCallCenterAgent ? 'evseId' : 'location');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const assets = useVkwAssets<Assets>();
  const dropdownButtonRef = useRef(null);
  const filterButtonRef = useRef(null);

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEl(event.currentTarget.parentElement);
  };

  const handleDropDownClose = (): void => {
    setAnchorEl(null);
  };

  const setLocationMode = (): void => {
    setMode('location');
    setAnchorEl(null);
  };

  const setEvseIdMode = (): void => {
    setMode('evseId');
    setAnchorEl(null);
  };

  const renderInput = (): ReactNode => {
    switch (mode) {
      case 'evseId':
        return (
          <EvseIdAutocomplete
            onEvseIdChanged={onEvseIdChanged}
            renderInput={params => {
              const inputProps: InputBaseComponentProps = { ...params.inputProps };
              inputProps.className = clxs(inputProps.className, classes.input);

              return (
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={inputProps}
                  fullWidth
                  placeholder={formatMessage(`SearchEvseIdPlaceholder${toFirstUppercase(assets.project)}`)}
                />
              );
            }}
          />
        );
      case 'location':
      default:
        return (
          <GoogleMapAutoComplete
            onLocationChanged={onLocationChanged}
            renderInput={params => {
              const inputProps: InputBaseComponentProps = { ...params.inputProps };
              inputProps.className = clxs(inputProps.className, classes.input);

              return (
                <InputBase
                  ref={params.InputProps.ref}
                  inputProps={inputProps}
                  fullWidth
                  placeholder={formatMessage('SearchLocationPlaceholder')}
                />
              );
            }}
          />
        );
    }
  };

  return (
    <div className={classes.positionContainer}>
      <div className={classes.root}>
        <HelpWizardStep step={HelpWizardSteps.searchBarLeft}>
          <ButtonBase className={classes.dropDown} onClick={handleDropDownOpen} ref={dropdownButtonRef}>
            <Box paddingX={0.5}>
              {mode === 'evseId' ? <VkwIconChargingstation size={16} /> : <VkwIconPin size={16} />}
            </Box>
            <Box paddingX={0.5}>
              <VkwIconArrowDown size={12} />
            </Box>
          </ButtonBase>
        </HelpWizardStep>
        <span className={classes.spacer} />
        <HelpWizardStep step={HelpWizardSteps.searchBar}>
          <Box className={classes.inputContainer}>{renderInput()}</Box>
        </HelpWizardStep>
        <HelpWizardStep step={HelpWizardSteps.searchBarRight}>
          <ButtonBase className={classes.filterButton} onClick={onFilterClick} ref={filterButtonRef}>
            <Badge badgeContent={activeFilterCount} color="primary">
              <VkwIconFilter size={16} />
            </Badge>
          </ButtonBase>
        </HelpWizardStep>
      </div>
      {Boolean(anchorEl) && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={handleDropDownClose}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
          marginThreshold={0}
          PaperProps={{
            className: classes.popoverPaper,
          }}
        >
          <Box display="flex" flexDirection="column">
            <ButtonBase className={classes.popoverButton} onClick={setLocationMode}>
              <VkwIconPin size={16} />
              <Box paddingLeft={1} component="span">
                {formatMessage('SearchLocation')}
              </Box>
            </ButtonBase>
            <span className={classes.popoverButtonSpacer} />
            <ButtonBase className={classes.popoverButton} onClick={setEvseIdMode}>
              <VkwIconChargingstation size={16} />
              <Box paddingLeft={1} component="span">
                {formatMessage('SearchEvseId')}
              </Box>
            </ButtonBase>
          </Box>
        </Popover>
      )}
    </div>
  );
};

ChargeAtlasSearch.displayName = 'ChargeAtlasSearch';

export default ChargeAtlasSearch;
