import React, { ReactElement, ReactNode } from 'react';

import { makeStyles, SvgIcon, useMediaQuery, useTheme } from '@material-ui/core';

import { VkwTheme } from '../themes';

export type VkwIconSize = 12 | 16 | 20 | 24 | 36 | 48 | 64;

type RenderChildren = (fontSize: VkwIconSize) => ReactNode;

export interface VkwIconProps {
  /**
   * Größe des Icons. Wird ein Array übergeben wird Index 0 für "Mobil" und Index 1 für "Desktop" (theme.breakpoints.up('sm')) verwendet.
   */
  size?: VkwIconSize | VkwIconSize[];
  /**
   * Übergabe des SVG als Code
   */
  children?: ReactNode | RenderChildren;
}

const useStyles = makeStyles<VkwTheme, { size: VkwIconSize | VkwIconSize[] }>(
  theme => ({
    children: {
      fontSize: props => (typeof props.size === 'number' ? props.size : props.size[0]),

      [theme.breakpoints.up('sm')]: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fontSize: props => (typeof props.size === 'number' ? props.size : props.size[1]),
      },
    },
  }),
  { index: 1 }
);

const useFontSize = (size: VkwIconSize | VkwIconSize[] = 24): VkwIconSize => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  if (typeof size === 'number') {
    return size;
  }

  if (!isDesktop) {
    return size[0];
  }

  return size[1];
};

export const VkwIcon = ({ children, size = 24 }: VkwIconProps): ReactElement => {
  const styles = useStyles({ size });

  const fontSize = useFontSize(size);

  return (
    <SvgIcon className={styles.children}>
      {typeof children === 'function' ? (children as RenderChildren)(fontSize) : children}
    </SvgIcon>
  );
};
