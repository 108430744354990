import { ComponentType } from 'react';

import { Plug } from '@e-vo/types';

import {
  VkwIconPlugCcsCombo1,
  VkwIconPlugCcsCombo2,
  VkwIconPlugCeeBlau,
  VkwIconPlugCeeRot,
  VkwIconPlugChAdeMo,
  VkwIconPlugSchuko,
  VkwIconPlugTeslaSuperchargerOutline,
  VkwIconPlugTyp1,
  VkwIconPlugTyp13,
  VkwIconPlugTyp2,
  VkwIconPlugTyp3A,
  VkwIconPlugUnknown,
  VkwIconProps,
} from '../library';
import { VkwIconPlugTyp2WithCable } from '../library/icons/VkwIconPlugTyp2WithCable';

export const getPlugIcon = (plug: Plug): ComponentType<VkwIconProps> => {
  switch (plug) {
    case 'AVCON_CONNECTOR': {
      return VkwIconPlugUnknown;
    }
    case 'CCS_COMBO_1_PLUG_WITH_CABLE': {
      return VkwIconPlugCcsCombo1;
    }
    case 'CCS_COMBO_2_PLUG_WITH_CABLE': {
      return VkwIconPlugCcsCombo2;
    }
    case 'CHADEMO': {
      return VkwIconPlugChAdeMo;
    }
    case 'IEC_60309_SINGLE_PHASE': {
      return VkwIconPlugCeeBlau;
    }
    case 'IEC_60309_THREE_PHASE': {
      return VkwIconPlugCeeRot;
    }
    case 'LARGE_PADDLE_INDUCTIVE': {
      return VkwIconPlugUnknown;
    }
    case 'NEMA_5_20': {
      return VkwIconPlugUnknown;
    }
    case 'SMALL_PADDLE_INDUCTIVE': {
      return VkwIconPlugUnknown;
    }
    case 'TESLA_CONNECTOR': {
      return VkwIconPlugTeslaSuperchargerOutline;
    }
    case 'TYPE_E_FRENCH_STANDARD': {
      return VkwIconPlugSchuko;
    }
    case 'TYPE_F_SCHUKO': {
      return VkwIconPlugSchuko;
    }
    case 'TYPE_G_BRITISH_STANDARD': {
      return VkwIconPlugUnknown;
    }
    case 'TYPE_J_SWISS_STANDARD': {
      return VkwIconPlugTyp13;
    }
    case 'TYPE_1_CONNECTOR_WITH_CABLE': {
      return VkwIconPlugTyp1;
    }
    case 'TYPE_2_OUTLET': {
      return VkwIconPlugTyp2;
    }
    case 'TYPE_2_CONNECTOR_WITH_CABLE': {
      return VkwIconPlugTyp2WithCable;
    }
    case 'TYPE_3_OUTLET': {
      return VkwIconPlugTyp3A;
    }
    case 'UNSPECIFIED':
    default: {
      return VkwIconPlugUnknown;
    }
  }
};
