import { vkwGoogleMapLightStyles } from './vkwGoogleMapStyles';
import {
  helveticaNowDisplayLight,
  helveticaNowDisplayMedium,
  helveticaNowTextBoldItalic,
  helveticaNowTextBold,
  helveticaNowTextItalic,
  helveticaNowTextRegular,
  helveticaNowTextMedium,
} from '../fonts/fontConfig';
import { createVkwTheme, VkwThemeCustomOptions } from '../library/themes/createVkwTheme';

const PRIMARY = '#143968';
const SECONDARY = '#e30613';
const WHITE = '#fff';
const BLACK = '#000';
const LABELTEXT = '#0054b1';

const greyPalette = {
  50: '#fafafa',
  100: '#f2f2f2',
  200: '#e6e6e6',
  300: '#d9d9d9',
  400: '#9c9c9b',
  500: '#636362',
  600: '#262626',
  700: '#1a1a1a',
  800: '#0d0d0d',
  900: '#050505',
  A100: '#cecece',
  A200: '#aaaaaa',
  A400: '#303030',
  A700: '#616161',
};

const options: VkwThemeCustomOptions = {
  activeColor: PRIMARY,
  activeTab: { indicator: PRIMARY, text: PRIMARY },
  black: BLACK,
  checkedColorSwitchThumb: WHITE,
  colorPrimary: PRIMARY,
  colorSecondary: SECONDARY,
  contentBackground: WHITE,
  detailGraphColor: PRIMARY,
  errorColor: SECONDARY,
  focusVisible: PRIMARY,
  fontFace: [
    helveticaNowDisplayLight,
    helveticaNowDisplayMedium,
    helveticaNowTextBoldItalic,
    helveticaNowTextBold,
    helveticaNowTextItalic,
    helveticaNowTextRegular,
    helveticaNowTextMedium,
  ],
  globalFontFamily: '"Helvetica Now Text", Helvetica, Arial',
  googleMapsStyles: vkwGoogleMapLightStyles,
  greyPalette,
  labelTextColor: LABELTEXT,
  mainButtonColor: 'primary',
  mainCounterColor: PRIMARY,
  mainSliderColor: 'primary',
  menuBackground: greyPalette[100],
  performanceGraphGoodColor: PRIMARY,
  primaryButton: { background: PRIMARY, text: WHITE },
  project: 'vkw',
  successColor: '#00757a',
  type: 'light',
  warningColor: '#ffd400',
  white: WHITE,
};

export const vkwTheme = createVkwTheme(options, theme => ({
  h1: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 42,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.18,
  },
  h2: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 42,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.18,
  },

  h3: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 34,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.18,
  },
  h4: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 30,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.2,
  },
  h5: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 30,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.33,
  },
  h6: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 26,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.23,
  },
  h7: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 24,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.33,
  },
  h8: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.33,
  },
  h9: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
  },
  h10: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.43,
  },
  link10: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 10,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.14,
  },
  link14: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.43,
  },
  link16: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,
  },
  link18: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.56,
  },
  text10: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 10,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.6,
  },
  text12: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.5,
  },
  text14: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.43,
  },
  text16: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.63,
  },
  text18: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 18,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.56,
  },
  text20: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 20,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.6,
  },
}));
