import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconShare = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;
  const strokewidth = 2;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeWidth={strokewidth}>
        <line fill="none" strokeLinecap="butt" x1="7.6" x2="16.4" y1="10.5" y2="5.5" />
        <line fill="none" strokeLinecap="butt" x1="7.6" x2="16.4" y1="13.5" y2="18.5" />
        <circle cx="5" cy="12" fill="none" r="3" stroke={fill} />
        <circle cx="19" cy="4" fill="none" r="3" stroke={fill} />
        <circle cx="19" cy="20" fill="none" r="3" stroke={fill} />
      </g>
    </VkwIcon>
  );
};
