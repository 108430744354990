import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react';

import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Axios from 'axios';

import { useVkwStickyBanner } from '@e-vo/mobility/providers';

import { useUserContext } from '../../contexts';
import { useHandleAxiosResponse } from '../../hooks';
import { getVkwTypographyStyle, useVkwFormatMessage, useVkwTheme, VkwTextField, VkwTheme } from '../../library';
import { ChargeAtlasStickyBannerPreview } from '../ChargeAtlas/ChargeAtlasStickyBanner';

const useStyles = makeStyles<VkwTheme>(theme => ({
  control: {
    margin: '0',
    maxWidth: '45%',
  },
  darkLabel: {
    backgroundColor: '#262626',

    border: `solid ${theme.palette.type === 'dark' ? '2px' : '1px'} ${
      theme.palette.type === 'dark' ? theme.palette.common.white : theme.palette.grey[300]
    }`,
  },
  label: {
    borderRadius: '6px',
    marginTop: theme.spacing(1),
    maxWidth: '255px',
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(3),
    width: '100%',
  },
  lightLabel: {
    backgroundColor: '#fff',

    border: `solid ${theme.palette.type === 'light' ? '2px' : '1px'} ${
      theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.grey[300]
    }`,
  },
  radio: {
    '&&': {
      color: theme.palette.type === 'dark' ? theme.palette.common.white : '',
    },
  },
  text14: {
    ...getVkwTypographyStyle('text14', theme),
  },
  themeSwitcher: {
    justifyContent: 'space-evenly',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    width: '100%',
  },
  themeSwitcherHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const INFOBANNER_TITLE_MAX_LENGTH = 50;
const INFOBANNER_MESSAGE_MAX_LENGTH = 200;

export const Admin = (): ReactElement => {
  const handleAxiosResponse = useHandleAxiosResponse();
  const formatMessage = useVkwFormatMessage();
  const userContext = useUserContext();
  const styles = useStyles();
  const theme = useVkwTheme();
  const { body: infoBannerMessage, show: infoBannerShow, title: infoBannerTitle, updateStatus } = useVkwStickyBanner();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  // Set initial values once.
  useEffect(() => {
    if ((title === '' && message === '') || infoBannerShow) {
      setTitle(infoBannerTitle);
      setMessage(infoBannerMessage);
    }
  }, [infoBannerTitle, infoBannerMessage, infoBannerShow]);

  const createBanner = () => {
    handleAxiosResponse(
      () =>
        Axios.post(`/api/next/v1/app-info/infobanner/new?customerId=${userContext.selectedCustomer?.id ?? ''}`, {
          message,
          title,
        }),
      {
        success: () => {
          updateStatus();
        },
      },
      {
        errorSnackbarMessage: formatMessage('InfoBannerCreatedError'),
        successSnackbarMessage: formatMessage('InfoBannerCreatedSuccess'),
        unauthorizedSnackbarMessage: formatMessage('InfoBannerCreatedError'),
        validationSnackbarMessage: formatMessage('InfoBannerCreatedError'),
      }
    );
  };

  const deactiveBanner = () => {
    handleAxiosResponse(
      () =>
        Axios.delete(
          `/api/next/v1/app-info/infobanner/deactivate?customerId=${userContext.selectedCustomer?.id ?? ''}`
        ),
      {
        success: () => {
          updateStatus();
          setTitle('');
          setMessage('');
        },
      },
      {
        errorSnackbarMessage: formatMessage('InfoBannerDeactivatedError'),
        successSnackbarMessage: formatMessage('InfoBannerDeactivatedSuccess'),
        unauthorizedSnackbarMessage: formatMessage('InfoBannerDeactivatedError'),
        validationSnackbarMessage: formatMessage('InfoBannerDeactivatedError'),
      }
    );
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box className={styles.themeSwitcherHeader}>
        <Typography variant="h6">{formatMessage('InfoBannerSettings')}</Typography>
      </Box>
      <Box paddingTop={`${theme.spacing(2)}px`}>
        <Typography className={styles.text14}>{formatMessage('InfoBannerUsageDescription')}</Typography>
      </Box>
      {infoBannerShow && (
        <Box paddingTop={`${theme.spacing(2)}px`}>
          <Alert icon={<CheckCircleIcon width={28} height={28} />} severity="info">
            <AlertTitle>{formatMessage('InfoBannerActiveTitle')}</AlertTitle>
            {formatMessage('InfoBannerActiveDescription')}
          </Alert>
        </Box>
      )}
      <Box paddingTop={`${theme.spacing(2)}px`}>
        <VkwTextField
          name="description"
          value={title}
          label={formatMessage('InfoBannerTitle')}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setTitle(event.target.value)}
          error={title === ''}
          maxLength={INFOBANNER_TITLE_MAX_LENGTH}
          fullWidth
          disabled={infoBannerShow}
        />
        <Box paddingTop={`${theme.spacing(2)}px`}>
          <VkwTextField
            name="description"
            value={message}
            label={formatMessage('InfoBannerMessage')}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setMessage(event.target.value)}
            error={message === ''}
            maxLength={INFOBANNER_MESSAGE_MAX_LENGTH}
            multiline
            fullWidth
            disabled={infoBannerShow}
          />
        </Box>
      </Box>
      <Box className={styles.themeSwitcherHeader} paddingTop={`${theme.spacing(4)}px`}>
        <Typography variant="h6">{formatMessage('InfoBannerPreview')}</Typography>
      </Box>
      <Box paddingTop={`${theme.spacing(2)}px`}>
        <Typography className={styles.text14}>{formatMessage('InfoBannerPreviewDescription')}</Typography>
      </Box>
      <Box paddingTop={`${theme.spacing(2)}px`} sx={{ display: 'inline-flex' }}>
        <ChargeAtlasStickyBannerPreview
          body={message.length > 0 ? message : formatMessage('InfoBannerPreviewTitle')}
          title={title.length > 0 ? title : formatMessage('InfoBannerPreviewMessage')}
        />
      </Box>
      <Box paddingTop={`${theme.spacing(4)}px`}>
        <Box paddingRight={`${theme.spacing(2)}px`} display="inline">
          <Button
            variant="contained"
            color="secondary"
            onClick={createBanner}
            disabled={infoBannerShow || title === '' || message === ''}
          >
            {formatMessage('Create')}
          </Button>
        </Box>
        <Button variant="contained" color="default" onClick={deactiveBanner} disabled={!infoBannerShow}>
          {formatMessage('Deactivate')}
        </Button>
      </Box>
    </Box>
  );
};
