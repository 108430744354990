import React, { ReactElement, useMemo } from 'react';

import { useRouter } from 'next/router';

import { InfrastructureChargeStationPerformanceElement } from '../../hooks';
import { useVkwFormatMessage, VkwSwiper } from '../../library';
import { PerformanceStatisticTile } from '../PerformanceStatisticTile';

export type InfrastructureChargingStationsPerformanceType =
  | 'totalCharges'
  | 'totalConsumedEnergy'
  | 'totalDurationInHours'
  | 'totalCo2Saving';

interface InfrastructureChargeStationsPerformanceProps {
  performance: InfrastructureChargeStationPerformanceElement[];
  active?: InfrastructureChargingStationsPerformanceType;
}

export const InfrastructureChargeStationsPerformance = ({
  active,
  performance,
}: InfrastructureChargeStationsPerformanceProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const router = useRouter();

  const { totalChargesValues, totalCo2SavingValues, totalConsumedEnergyValues, totalDurationInSecondsValues } =
    useMemo(() => {
      const totalChargesValues: number[] = [];
      const totalDurationInSecondsValues: number[] = [];
      const totalConsumedEnergyValues: number[] = [];
      const totalCo2SavingValues: number[] = [];

      performance.forEach(item => {
        totalChargesValues.push(item.totalCharges);
        totalDurationInSecondsValues.push(item.totalDurationInSeconds);
        totalConsumedEnergyValues.push(item.totalConsumedEnergy);
        totalCo2SavingValues.push(item.totalCo2Saving);
      });

      return {
        totalChargesValues,
        totalCo2SavingValues,
        totalConsumedEnergyValues,
        totalDurationInSecondsValues,
      };
    }, [performance]);
  const elements = [
    <PerformanceStatisticTile
      key="totalCharges"
      title={formatMessage('TotalCharges')}
      values={totalChargesValues}
      onClick={() => router.push({ pathname: '/infrastructure/performance', query: { type: 'totalCharges' } })}
      inactive={!!(active && active !== 'totalCharges')}
    />,
    <PerformanceStatisticTile
      key="totalConsumedEnergy"
      title={formatMessage('TotalConsumedEnergy')}
      values={totalConsumedEnergyValues}
      onClick={() => router.push({ pathname: '/infrastructure/performance', query: { type: 'totalConsumedEnergy' } })}
      inactive={!!(active && active !== 'totalConsumedEnergy')}
    />,
    <PerformanceStatisticTile
      key="totalDuration"
      title={formatMessage('TotalDurationInHours')}
      variant="time"
      values={totalDurationInSecondsValues}
      onClick={() => router.push({ pathname: '/infrastructure/performance', query: { type: 'totalDurationInHours' } })}
      inactive={!!(active && active !== 'totalDurationInHours')}
    />,
    <PerformanceStatisticTile
      key="totalCo2Saving"
      title={formatMessage('TotalCo2Saving')}
      values={totalCo2SavingValues}
      onClick={() => router.push({ pathname: '/infrastructure/performance', query: { type: 'totalCo2Saving' } })}
      inactive={!!(active && active !== 'totalCo2Saving')}
    />,
  ];
  return (
    <VkwSwiper
      infinite
      showAllElements
      elements={elements}
      initialSlide={elements.findIndex(x => !x.props.inactive)}
      afterChange={index => {
        if (active) {
          elements[index].props.onClick();
        }
      }}
    />
  );
};
