import React, { Fragment, MouseEvent, ReactElement, ReactNode, useState } from 'react';

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { Avatar, Backdrop, Box, Divider, List, Popover, Typography } from '@material-ui/core';

import { ContractPartnerList } from './ContractPartnerList';
import { useStyles } from './VkwUserPopoverStyles';
import { UserPanelLinks } from '../../../util/helpers';
import { useVkwFormatMessage } from '../../hooks';
import { useIsRenderedInIFrame } from '../../hooks/useIsRenderedInIFrame';
import { VkwContractPartner, VkwPopoverProps } from '../../types';
import { VkwButton } from '../VkwButton';
import { VkwCallToAction } from '../VkwCallToAction';
import { VkwListItem } from '../VkwListItem';

export interface VkwUserPopoverProps {
  /**
   * Email/Username des eingeloggten Users
   */
  email?: string;
  /**
   * URL der Profilbearbeitungsseite
   */
  profileUrl?: string;
  /**
   * URL oder Callback für Routing bei der Kundennummern hinzugefügt werden können
   */
  addContractPartner?: string | (() => void);
  /**
   * URL für den Backoffice Link
   */
  backofficeUrl?: string;
  /**
   * URL / Methode für den Logout
   */
  logout: string | (() => void);
  /**
   * URL / Methode für den Login
   */
  login: string | (() => void);
  onSelectContractPartner?: (data: VkwContractPartner) => void;
  /**
   * Ausgewählte Kundendaten
   */
  selectedContractPartner?: VkwContractPartner | null;
  /**
   * Verknüpfte Kundennummern
   */
  contractPartners?: VkwContractPartner[];
  popoverProps?: VkwPopoverProps;
  userPanelLinks?: UserPanelLinks | null;
}

export const VkwUserPopover = ({
  addContractPartner,
  backofficeUrl,
  contractPartners,
  email,
  login,
  logout,
  onSelectContractPartner,
  popoverProps,
  profileUrl,
  selectedContractPartner,
  userPanelLinks,
}: VkwUserPopoverProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();
  const hideLogin = useIsRenderedInIFrame();

  const renderButton = (): ReactNode => {
    if (!email) {
      if (hideLogin) {
        return null;
      }
      return (
        <VkwButton
          onClick={() => {
            if (typeof login === 'string') {
              window.location.href = login;
            } else {
              login();
            }
          }}
          color="default"
          variant="contained"
        >
          {formatMessage('Login')}
        </VkwButton>
      );
    }

    return (
      <Avatar
        className={styles.avatarButton}
        onClick={(event: MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget);
          if (popoverProps && popoverProps.onOpen) {
            popoverProps.onOpen(event);
          }
        }}
      >
        {email.substr(0, 1).toUpperCase()}
      </Avatar>
    );
  };

  const renderContractPartnerData = (contractPartners: VkwContractPartner[] | undefined): ReactNode => {
    return (
      <>
        <Divider />
        <Box display="flex" justifyContent="center" pt={1.5}>
          <Typography className={styles.textTitle}>{formatMessage('ConnectedCustomerNumber')}</Typography>
        </Box>
        {contractPartners && contractPartners.length > 0 ? (
          <ContractPartnerList
            listItemSelectable
            contractPartners={contractPartners}
            selectedContractPartner={selectedContractPartner}
            onSelectContractPartner={onSelectContractPartner}
          />
        ) : (
          <Box pb={1.5} px={3}>
            <Typography className={styles.infoText}>{formatMessage('ConnectCustomerNumber')}</Typography>
          </Box>
        )}
      </>
    );
  };

  const renderUserNavigation = (userPanelLinks: UserPanelLinks): ReactNode => {
    return (
      <>
        <Divider />
        {userPanelLinks.title && (
          <Box display="flex" justifyContent="center" pt={1.5}>
            <Typography className={styles.textTitle}>{userPanelLinks.title}</Typography>
          </Box>
        )}
        <List>
          {userPanelLinks.links.map(
            (link, index): ReactElement => (
              <Fragment key={`list-item${index}`}>
                <VkwListItem onClick={() => window.open(link.url, link.openInTab ? '_blank' : '_self')}>
                  <div className="flex w-full items-center justify-between pl-3">
                    <span>{link.displayText}</span>
                    <ArrowTopRightOnSquareIcon className="h-5 w-5 opacity-75" />
                  </div>
                </VkwListItem>
              </Fragment>
            )
          )}
        </List>
      </>
    );
  };

  const renderAddContractPartnerButton = (addContractPartner: string | (() => void)): ReactNode => {
    return (
      <Box display="flex" justifyContent="center" py={2}>
        <VkwButton
          variant="contained"
          color="primary"
          title={formatMessage('AddCustomerNumber')}
          onClick={() => {
            if (typeof addContractPartner === 'string') {
              window.location.href = addContractPartner;
            } else {
              addContractPartner();
            }
          }}
        >
          <Typography className={styles.textTitle}>{`+ ${formatMessage('AddCustomerNumber')}`}</Typography>
        </VkwButton>
      </Box>
    );
  };

  return (
    <>
      {renderButton()}
      {email && (
        <Popover
          open={!!anchorEl}
          onClose={(event, reason) => {
            setAnchorEl(null);
            if (popoverProps && popoverProps.onClose) {
              popoverProps?.onClose(event, reason);
            }
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          className={styles.wrapper}
          disablePortal={popoverProps?.disablePortal}
        >
          <div className={styles.content}>
            <Box display="flex" justifyContent="center">
              <div className={styles.letterIcon}>{email.substr(0, 1)}</div>
            </Box>
            <Box display="flex" justifyContent="center" pt={2}>
              <Typography className={styles.email}>{email}</Typography>
            </Box>
            {profileUrl && (
              <Box display="flex" justifyContent="center" pt={2}>
                <VkwCallToAction href={profileUrl} title={formatMessage('ManageMyAccount')} iconPosition="none" />
              </Box>
            )}
            {backofficeUrl && (
              <Box display="flex" justifyContent="center" pt={5}>
                <Typography>
                  <VkwCallToAction href={backofficeUrl} title={formatMessage('GoToBackoffice')} iconPosition="none" />
                </Typography>
              </Box>
            )}
            <Box display="flex" justifyContent="center" pt={4} pb={!contractPartners ? 4 : 1.5}>
              {!hideLogin && (
                <VkwButton
                  color="default"
                  variant="contained"
                  onClick={() => {
                    if (typeof logout === 'string') {
                      window.location.href = logout;
                    } else {
                      logout();
                    }
                  }}
                >
                  {formatMessage('Logout')}
                </VkwButton>
              )}
            </Box>
            {(contractPartners || addContractPartner) && renderContractPartnerData(contractPartners)}
            {addContractPartner && renderAddContractPartnerButton(addContractPartner)}
            {userPanelLinks && renderUserNavigation(userPanelLinks)}
          </div>
        </Popover>
      )}
      <Backdrop open={!!anchorEl} onClick={() => setAnchorEl(null)} className={styles.userPopoverBackdrop} />
    </>
  );
};
