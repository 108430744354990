'use client';

import { useState, useEffect } from 'react';

// Currently EBM and LAG are rendered in an iframe.
// If this changes, we need to find a system to display/hide different parts of the UI based on the iframe context and customer.
export const useIsRenderedInIFrame = (): boolean => {
  const [isRenderedInIFrame, setIsRenderedInIFrame] = useState(false);

  useEffect(() => {
    const checkIsRenderedInIFrame = typeof window !== 'undefined' && window.self !== window.top;
    setIsRenderedInIFrame(checkIsRenderedInIFrame);
  }, []);

  return isRenderedInIFrame;
};
