import { ikbGoogleMapDarkStyles } from './ikbGoogleMapStyles';
import {
  helveticaNowDisplayLight,
  helveticaNowDisplayMedium,
  helveticaNowTextBoldItalic,
  helveticaNowTextBold,
  helveticaNowTextItalic,
  helveticaNowTextRegular,
  helveticaNowTextMedium,
} from '../fonts/fontConfig';
import { createVkwTheme, VkwThemeCustomOptions } from '../library/themes/createVkwTheme';

const PRIMARY = '#242628';
const SECONDARY = '#ea222d';
const WHITE = '#fff';
const BLACK = '#000';
const SUCCESS = '#1c7a0e';

const greyPalette = {
  50: '#fafafa',
  100: '#f1f2f3',
  200: '#e5e6e7',
  300: '#d7d9db',
  400: '#989b9f',
  500: '#85898e',
  600: '#5f6367',
  700: '#242628',
  800: '#191a1b',
  900: '#0c0d0e',
  A100: '#cecece',
  A200: '#aaaaaa',
  A400: '#303030',
  A700: '#616161',
};

const options: VkwThemeCustomOptions = {
  activeColor: WHITE,
  activeTab: { indicator: SECONDARY, text: PRIMARY },
  black: BLACK,
  checkedColorSwitchThumb: WHITE,
  colorPrimary: PRIMARY,
  colorSecondary: SECONDARY,
  contentBackground: greyPalette[800],
  detailGraphColor: SUCCESS,
  errorColor: SECONDARY,
  focusVisible: WHITE,
  fontFace: [
    helveticaNowDisplayLight,
    helveticaNowDisplayMedium,
    helveticaNowTextBoldItalic,
    helveticaNowTextBold,
    helveticaNowTextItalic,
    helveticaNowTextRegular,
    helveticaNowTextMedium,
  ],
  globalFontFamily: '"Helvetica Now Text", Helvetica, Arial',
  googleMapsStyles: ikbGoogleMapDarkStyles,
  greyPalette,
  mainButtonColor: 'primary',
  mainCounterColor: WHITE,
  mainSliderColor: 'primary',
  menuBackground: greyPalette[900],
  performanceGraphGoodColor: SUCCESS,
  primaryButton: { background: PRIMARY, text: WHITE },
  project: 'ikb',
  successColor: SUCCESS,
  type: 'dark',
  warningColor: '#ffd400',
  white: WHITE,
};

export const ikbDarkTheme = createVkwTheme(options, theme => ({
  h1: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 42,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.18,
  },
  h2: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 42,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.18,
  },

  h3: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 34,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.18,
  },
  h4: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 30,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.2,
  },
  h5: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 30,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.33,
  },
  h6: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 26,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.23,
  },
  h7: {
    fontFamily: 'Helvetica Now Display',
    fontSize: 24,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.33,
  },
  h8: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.33,
  },
  h9: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.5,
  },
  h10: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.43,
  },
  link10: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 10,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.14,
  },
  link14: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.43,
  },
  link16: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.5,
  },
  link18: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 18,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.56,
  },
  text10: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 10,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.6,
  },
  text12: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: 1.5,
  },
  text14: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.43,
  },
  text16: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.63,
  },
  text18: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 18,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.56,
  },
  text20: {
    fontFamily: 'Helvetica Now Text',
    fontSize: 20,
    fontWeight: theme.typography.fontWeightRegular,
    letterSpacing: -0.2,
    lineHeight: 1.6,
  },
}));
