import { round } from 'lodash';
import RBush from 'rbush';

import { ChargePointStatusEnum } from '@e-vo/types';

import { ChargeStation } from '../hooks';

interface Rectangle extends ChargeStation {
  minX: number;
  minY: number;
  maxX: number;
  maxY: number;
}

const toRectangle = (chargeStation: ChargeStation, threshold: number): Rectangle => {
  const latDiff = threshold / 111000; // Convert threshold from meters to latitude degrees
  const lngDiff = threshold / (111000 * Math.cos((chargeStation.location.latitude * Math.PI) / 180)); // Convert threshold from meters to longitude degrees
  return {
    ...chargeStation,
    maxX: chargeStation.location.longitude + lngDiff / 2,
    maxY: chargeStation.location.latitude + latDiff / 2,
    minX: chargeStation.location.longitude - lngDiff / 2,
    minY: chargeStation.location.latitude - latDiff / 2,
  };
};

/**
 * Cluster given charge stations. The threshold is given in meter.
 *
 * @param chargeStations
 * @param threshold
 */
export const clusterChargeStations = (
  chargeStations: ChargeStation[],
  threshold: number
): (ChargeStation | ChargeStation[])[] => {
  const tree = new RBush<Rectangle>();

  // Insert all stations into the R-tree
  chargeStations.forEach(chargeStation => tree.insert(toRectangle(chargeStation, threshold)));

  const results: (ChargeStation | ChargeStation[])[] = [];

  // For each station, look for nearby stations, group them and remove from the tree
  chargeStations.forEach(chargeStation => {
    const nearbyChargeStations = tree.search(toRectangle(chargeStation, threshold));
    nearbyChargeStations.forEach(nearbyChargeStation => tree.remove(nearbyChargeStation));

    // If a station is part of a cluster, add the entire cluster to the result
    // If a station is not part of a cluster, add it as a single object
    if (nearbyChargeStations.length > 1) {
      results.push(nearbyChargeStations);
    } else if (nearbyChargeStations.length === 1) {
      results.push(nearbyChargeStations[0]);
    }
  });

  return results;
};

export type ChargePointStatusWithNotAllowed = ChargePointStatusEnum | 'NOT_ALLOWED';

export interface ChargePointsData {
  chargeStationStatus: ChargePointStatusWithNotAllowed;
  chargePointsCount: number;
  maxChargePower: number;
  available: number;
  occupied: number;
  reserved: number;
  outOfService: number;
  allowed: number;
}

export const getChargePointsData = (chargeStation: ChargeStation): ChargePointsData => {
  let chargeStationStatus: ChargePointStatusWithNotAllowed = ChargePointStatusEnum.UNKNOWN;
  const chargePointsCount = chargeStation.chargePoints.length;
  let maxChargePower = 0;

  let available = 0;
  let occupied = 0;
  let reserved = 0;
  let outOfService = 0;
  let allowed = 0;

  chargeStation?.chargePoints?.forEach(chargePoint => {
    if (typeof chargePoint.isChargingAllowed === 'undefined' || chargePoint.isChargingAllowed) {
      allowed++;
    }

    switch (chargePoint.status) {
      case 'AVAILABLE':
        available++;
        if (chargePoint.maxPower > maxChargePower) {
          maxChargePower = round(chargePoint.maxPower);
        }
        break;
      case 'OCCUPIED':
        occupied++;
        break;
      case 'RESERVED':
        reserved++;
        break;
      case 'OUT_OF_SERVICE':
        outOfService++;
        break;
      default:
        break;
    }
  });

  if (available > 0) {
    chargeStationStatus = ChargePointStatusEnum.AVAILABLE;
  } else if (occupied > 0) {
    chargeStationStatus = ChargePointStatusEnum.OCCUPIED;
  } else if (outOfService > 0) {
    chargeStationStatus = ChargePointStatusEnum.OUT_OF_SERVICE;
  } else if (reserved > 0) {
    chargeStationStatus = ChargePointStatusEnum.RESERVED;
  }

  return {
    allowed,
    available,
    chargePointsCount,
    chargeStationStatus,
    maxChargePower,
    occupied,
    outOfService,
    reserved,
  };
};
