import React, { ReactElement, useState } from 'react';

import { makeStyles, Typography } from '@material-ui/core';

import { VkwDataTableSettingsProvider } from './VkwDataTable';
import { useVkwFormatMessage } from '../../hooks';
import { useVkwTheme, VkwTheme } from '../../themes';
import { VkwButton } from '../VkwButton';
import { VkwDialog } from '../VkwDialog';
import { VkwSortableList, VkwSortableListItemConfig } from '../VkwSortableList';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    text: {
      maxWidth: '100%',
      paddingBottom: theme.spacing(5),
      paddingTop: theme.spacing(2),
      width: 420,
    },
  }),
  { index: 1 }
);

interface SettingsDialogProps {
  settingsProvider: VkwDataTableSettingsProvider;
  initialItems: VkwSortableListItemConfig[];
  onClose: () => void;
  defaultSettings?: VkwSortableListItemConfig[];
}

export const SettingsDialog = ({
  defaultSettings,
  initialItems,
  onClose,
  settingsProvider,
}: SettingsDialogProps): ReactElement => {
  const theme = useVkwTheme();
  const formatMessage = useVkwFormatMessage();
  const [userSettings, setUserSettings] = useState<VkwSortableListItemConfig[]>([]);
  const [items, setItems] = useState<VkwSortableListItemConfig[]>(initialItems);
  const defaultSettingsJson = defaultSettings ? JSON.stringify(defaultSettings) : undefined;
  const styles = useStyles();

  const resetSettingsPossible =
    defaultSettingsJson &&
    settingsProvider.settings &&
    userSettings.length > 0 &&
    JSON.stringify(userSettings) !== defaultSettingsJson;

  const resetSettings = () => {
    if (resetSettingsPossible) {
      setItems(JSON.parse(defaultSettingsJson));
      setUserSettings([]);
    }
  };

  return (
    <VkwDialog
      open
      headline={formatMessage('OrderColumnsTitle')}
      onCancel={onClose}
      onClose={onClose}
      showCloseIcon
      renderContent={() => (
        <>
          <Typography className={styles.text}>{formatMessage('OrderColumnsMessage')}</Typography>
          <VkwSortableList items={items} onChange={items => setUserSettings(items)} withFrame />
          <p>
            {resetSettingsPossible && (
              <VkwButton
                color={theme.palette.mainButtonColor}
                variant="contained"
                fullWidth
                onClick={() => resetSettings()}
              >
                {formatMessage('ResetColumnSettings')}
              </VkwButton>
            )}
          </p>
        </>
      )}
      onSave={() => {
        settingsProvider.updateSettings(userSettings);
        onClose();
      }}
    />
  );
};
