export enum IdentificationUpdateAction {
  ADD = 'ADD',
  DISABLE = 'DISABLE',
}

export enum IdentificationUpdateType {
  RFID = 'RFID',
  VEHICLE_ID = 'VEHICLE_ID',
}

export interface IdentificationUpdate {
  action: IdentificationUpdateAction;
  type: IdentificationUpdateType;
  value: string;
}

export interface DriverUpdate {
  evcoId: string;
  identifications: IdentificationUpdate[];
}
