import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugChAdeMo = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M12 0c2.463 0 4.753.742 6.657 2.014l.99-.989 2.828 2.829-.906.904A11.944 11.944 0 0 1 24 12c0 6.624-5.37 12-12 12S0 18.624 0 12c0-2.72.905-5.23 2.431-7.242l-.906-.904 2.829-2.829.989.99A11.93 11.93 0 0 1 12 0zm0 17a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 17zM6.5 8a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7zm11 0a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7zM12 3a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 3z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </VkwIcon>
  );
};
