import React, { ReactElement, useState, useEffect, FocusEvent } from 'react';

import { makeStyles } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { findAll } from 'highlight-words-core';
import { useDebounce } from 'use-debounce';

import { getVkwTypographyStyle } from '../../helper';
import { useVkwFormatMessage } from '../../hooks';
import { VkwTheme } from '../../themes';
import { VkwTextField } from '../VkwTextField';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    clearIndicator: {
      marginRight: theme.spacing(2),
    },
    optionsChunk: {
      color: theme.palette.text.secondary,
    },
    optionsChunkHighlight: {
      ...getVkwTypographyStyle('h9', theme),
      color: theme.palette.text.primary,
    },
    paper: {
      boxShadow: theme.shadows[8],
    },
  }),
  { index: 1 }
);

export interface VkwAutocompleteProps<T> {
  /**
   * Name des Eingabefeldes -> Notwendig als Referenz z.B. für Formik etc.
   */
  name: string;
  /**
   * Beschriftung des Eingabefelds für den User
   */
  label: string;
  value: T | null;
  setValue: (value: T | null) => void;
  /**
   * Methode um dem Autocomplete die Auswahloptionen bereitzustellen (Dies ist auch abhängig von der Eingabe des Users möglich, kann aber auch intern erledigt werden -> `filterInOptions`)
   */
  getOptions: (searchString: string) => Promise<T[]>;
  fullWidth?: boolean;
  /**
   * Error status des Eingabefeldes aktivieren z.B. bei einem Validierungsfehler
   */
  error?: boolean;
  /**
   * Hilfstext für den User wenn `error` gesetzt wird
   */
  helperText?: string;
  /**
   * Überschreiben des Textes der dargestellt werden soll, wenn keine Optionen verfügbar sind.
   */
  noOptionsText?: string;
  /**
   * Überschreiben des Textes der dargestellt werden soll, während die Daten geladen werden.
   */
  loadingText?: string;
  /**
   * Soll das Autocomplete je nach Eingabe des Users selbstständig die möglichen Optionen filtern?
   */
  filterInOptions?: boolean;
  /**
   * Sind die möglichen Options ein Objekt kann über diese Methode dem Autocomplete übergeben werden welches Feld des Objektes den String des Users beinhaltet
   */
  getOptionLabel?: (option: T) => string;
  /**
   * Methode um die einzelnen möglichen Optionen im Dropdown in ihrer Darstellung anzupassen
   */
  renderOption?: (option: T) => string;
  /**
   * Wenn die Optionen Objekte sind kann über diese Methode ein Vergleicher übergeben werden bei dem entschieden wird ob eine Option selektiert wird oder nicht
   */
  getOptionSelected?: (option: T, value: T) => boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

interface AutocompleteState<T> {
  items: T[];
  loading: boolean;
}

// eslint-disable-next-line react/function-component-definition
export function VkwAutocomplete<T>({
  error,
  filterInOptions,
  fullWidth,
  getOptionLabel,
  getOptionSelected,
  getOptions,
  helperText,
  label,
  loadingText,
  name,
  noOptionsText,
  onBlur,
  renderOption,
  setValue,
  value,
}: VkwAutocompleteProps<T>): ReactElement {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();

  const [options, setOptions] = useState<AutocompleteState<T>>({
    items: [],
    loading: false,
  });
  const [inputValue, setInputValue] = useState<string>('');
  const [searchString] = useDebounce(inputValue, 250);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const filter = createFilterOptions<T>();

  useEffect(() => {
    let active = true;
    if (dialogOpen) {
      setOptions({
        items: [],
        loading: true,
      });
      getOptions(searchString).then(options => {
        if (!active) {
          return;
        }
        setOptions({
          items: options,
          loading: false,
        });
      });
    }
    return () => {
      active = false;
    };
  }, [searchString, dialogOpen]);

  return (
    <Autocomplete
      classes={{ clearIndicator: error ? styles.clearIndicator : undefined, paper: styles.paper }}
      options={options.items}
      value={value}
      inputValue={inputValue}
      onInputChange={(_event, newValue) => {
        setInputValue(newValue);
      }}
      onChange={(_event, newValue) => {
        setValue(newValue);
      }}
      renderInput={params => (
        <VkwTextField name={name} label={label} error={error} helperText={helperText} {...params} onBlur={onBlur} />
      )}
      fullWidth={fullWidth}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      loading={options.loading}
      getOptionLabel={getOptionLabel}
      filterOptions={(options, params) => {
        const filtered = filterInOptions ? filter(options, params) : options;

        return filtered;
      }}
      renderOption={option => {
        let optionString: string;

        if (renderOption) {
          optionString = renderOption(option);
        } else if (typeof option === 'string') {
          optionString = option;
        } else {
          throw new Error('Object options need "renderOption" provided');
        }

        const chunks = findAll({
          autoEscape: true,
          caseSensitive: false,
          searchWords: inputValue.trim().split(' '),
          textToHighlight: optionString,
        });

        return (
          <div key={optionString}>
            {chunks.map((chunk, index) => {
              const { end, highlight, start } = chunk;
              const text = optionString.substr(start, end - start);

              if (highlight) {
                return (
                  <span key={index} className={styles.optionsChunkHighlight}>
                    {text}
                  </span>
                );
              }

              return (
                <span key={index} className={styles.optionsChunk}>
                  {text}
                </span>
              );
            })}
          </div>
        );
      }}
      getOptionSelected={getOptionSelected}
      noOptionsText={noOptionsText ?? formatMessage('NoOptions')}
      loadingText={loadingText ?? `${formatMessage('Loading')}...`}
      onOpen={() => {
        setDialogOpen(true);
      }}
      onClose={() => {
        setDialogOpen(false);
      }}
    />
  );
}
