import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugTyp2WithCable = ({ size }: VkwIconProps): ReactElement => {
  return (
    <VkwIcon size={size}>
      <g>
        <rect fill="#000" rx="5" ry="5" width="100%" height="100%" />
        <path
          d="m22.01,11.21c0,5.43 -4.48,9.85 -10,9.85s-10,-4.42 -10,-9.85c0,-3.42 1.78,-6.44 4.47,-8.21l11.07,0a10,9.84 0 0 1 4.47,8.21zm-12.92,3.28a2.5,2.46 0 1 0 0,4.92a2.5,2.46 0 0 0 0,-4.92zm6.25,0a2.5,2.46 0 1 0 0,4.92a2.5,2.46 0 0 0 0,-4.92zm-9.59,-5.74a2.5,2.46 0 1 0 0,4.92a2.5,2.46 0 0 0 0,-4.92zm6.25,0a2.5,2.46 0 1 0 0,4.92a2.5,2.46 0 0 0 0,-4.92zm6.25,0a2.5,2.46 0 1 0 0,4.92a2.5,2.46 0 0 0 0,-4.92zm-9.17,-4.1a1.67,1.64 0 1 0 0,3.28a1.67,1.64 0 0 0 0,-3.28zm5.84,0a1.67,1.64 0 1 0 0,3.28a1.67,1.64 0 0 0 0,-3.28z"
          fillOpacity="0"
          stroke="#fff"
          strokeOpacity="1"
        />
      </g>
    </VkwIcon>
  );
};
