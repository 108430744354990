import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconIdentificationUnknown = ({ size }: VkwIconProps): ReactElement => {
  return (
    <VkwIcon size={size}>
      <g id="Icons" stroke="none" strokeWidth="1" fill="#9C9C9B" fillRule="nonzero">
        <g>
          <path
            d="M24,12 C23.9975449,10.3146512 23.0219449,8.78233588 21.495949,8.06703992 C22.0711018,6.4820265 21.6767746,4.70735196 20.484515,3.51506807 C19.2922554,2.32278419 17.517617,1.92844899 15.9326358,2.50361347 C15.2190644,0.976150147 13.6856482,0 11.9997558,0 C10.3138633,0 8.78044713,0.976150147 8.06687574,2.50361347 C6.48189457,1.92844899 4.70725615,2.32278419 3.51499653,3.51506807 C2.32273691,4.70735196 1.92840974,6.4820265 2.50356251,8.06703992 C0.97613028,8.78062583 0,10.3140732 0,12 C0,13.6859268 0.97613028,15.2193742 2.50356251,15.9329601 C1.92840974,17.5179735 2.32273691,19.292648 3.51499653,20.4849319 C4.70725615,21.6772158 6.48189457,22.071551 8.06687574,21.4963865 C8.78044713,23.0238499 10.3138633,24 11.9997558,24 C13.6856482,24 15.2190644,23.0238499 15.9326358,21.4963865 C17.517617,22.071551 19.2922554,21.6772158 20.484515,20.4849319 C21.6767746,19.292648 22.0711018,17.5179735 21.495949,15.9329601 C23.0219449,15.2176641 23.9975449,13.6853488 24,12 Z"
            id="Path"
            fill="#9C9C9B"
            fillRule="nonzero"
          />
          <path
            d="M8.105,13 C8.93342712,13 9.605,13.6715729 9.605,14.5 C9.605,15.3284271 8.93342712,16 8.105,16 C7.27657288,16 6.605,15.3284271 6.605,14.5 C6.605,13.6715729 7.27657288,13 8.105,13 Z M8.105,1 C10.311,1 12.105,2.794 12.105,5 C12.105,6.69 11.073,7.431 10.319,7.972 C9.587,8.497 9.105,8.843 9.105,10 L9.105,10 L9.105,11 L7.105,11 L7.105,10 C7.105,7.817 8.289,6.967 9.153,6.347 C9.86,5.839 10.105,5.632 10.105,5 C10.105,3.897 9.208,3 8.105,3 C7.259,3 6.501,3.536 6.218,4.333 L6.218,4.333 L5.885,5.276 L4,4.609 L4.334,3.667 C4.897,2.072 6.414,1 8.105,1 Z"
            id="Shape"
            fill="#FFFFFF"
            transform="translate(4.000000, 4.000000)"
          />
        </g>
      </g>
    </VkwIcon>
  );
};
