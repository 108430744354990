import React, { ReactElement } from 'react';

import { TableRow, TableRowProps } from '@material-ui/core';

import useStyles from './VkwBaseTableStyles';

export const VkwBaseTableHeadRow = ({ children, ...rest }: TableRowProps): ReactElement => {
  const styles = useStyles();

  return (
    <TableRow className={styles.headerRow} {...rest}>
      {children}
    </TableRow>
  );
};
