import React, { ReactElement, ReactNode } from 'react';

import { Table, TableBody, TableHead, TableProps, Hidden } from '@material-ui/core';

import useStyles from './VkwBaseTableStyles';

export interface VkwBaseTableProps extends TableProps {
  /**
   * Render Prop um den Header zu befüllen
   */
  renderHeader?: () => ReactNode;
  /**
   * Render Prop um die Toolbar zu befüllen
   */
  renderToolbar?: () => ReactNode;
  /**
   * Render Prop um den Footer zu befüllen
   */
  renderFooter?: () => ReactNode;
  /**
   * Render Prop um die "Mobile" Datenansicht zu gestalten
   */
  renderMobile?: () => ReactNode;
  /**
   * Als `children` wird die "Desktop" Datenansicht übergeben
   */
  children?: ReactNode;
}

export const VkwBaseTable = ({
  children,
  renderFooter,
  renderHeader,
  renderMobile,
  renderToolbar,
  ...rest
}: VkwBaseTableProps): ReactElement => {
  const styles = useStyles();

  const renderTable = (): ReactNode => {
    const table = (
      <Table {...rest}>
        {renderHeader && <TableHead>{renderHeader()}</TableHead>}
        <TableBody className={styles.tableBody}>{children}</TableBody>
      </Table>
    );

    if (!renderMobile) {
      return table;
    }

    return (
      <Hidden xsDown implementation="css">
        {table}
      </Hidden>
    );
  };

  return (
    <div className={styles.root}>
      {renderToolbar?.()}
      <div className={styles.tableContainer}>{renderTable()}</div>
      {renderMobile && <div className={styles.mobileContainer}>{renderMobile()}</div>}
      {renderFooter?.()}
    </div>
  );
};
