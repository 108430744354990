import React, { useState } from 'react';

import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { Modal } from '@e-vo/ui';

import { useVkwFormatMessage } from '../../library';
import { IconCookie } from '../../library/icons/IconCookie';
import { CookieConsentCategory, hasCookieConsent, setCookieConsent } from '../../util/cookie-consent';
import { Button } from '../Button';
import { CookieConsentModalDisclosure } from '../CookieConsentModalDisclosure';

export const CookieConsentSettingsModalId = 'cookie-consent-settings';

export const CookieConsentModal = NiceModal.create(() => {
  const formatMessage = useVkwFormatMessage();
  const modal = useModal();

  const [consentCategories, setConsentCategories] = useState<CookieConsentCategory[]>([]);

  const handleCookieConsent = (allowAll = true) => {
    setCookieConsent(allowAll, consentCategories);
    modal.hide();
  };

  return (
    <Modal isOpen={modal.visible} onClose={isOpen => (isOpen ? null : modal.hide())} closable={hasCookieConsent()}>
      <Modal.Header>
        <IconCookie />
        <span className="ml-2">{formatMessage('CookieConsentSettings')}</span>
      </Modal.Header>
      <Modal.Body>
        <span className="text-gray-600 dark:text-gray-300">
          {formatMessage('CookieConsentModalDescription', {
            // eslint-disable-next-line react/no-unstable-nested-components
            br: (chunks: any) => (
              <>
                <br />
                <br />
              </>
            ),
          })}
        </span>
      </Modal.Body>
      <div>
        <CookieConsentModalDisclosure onChange={consentCategories => setConsentCategories(consentCategories)} />
      </div>
      <Modal.Footer>
        <div className="flex justify-between">
          <Button onPress={() => handleCookieConsent(false)} title={formatMessage('CookieConsentRejectOptional')} />
          <Button
            onPress={() => handleCookieConsent(true)}
            title={formatMessage('CookieConsentAllowAll')}
            type="primary"
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
});
