import React, { ReactElement, ReactNode } from 'react';

import { Popover, makeStyles, PopoverOrigin } from '@material-ui/core';

import { useOptionalVkwNavigation } from '../../contexts';
import { VkwIconClose } from '../../icons';
import { VkwTheme } from '../../themes';
import { VkwIconButton } from '../VkwIconButton';

const useStyles = makeStyles<VkwTheme>(
  theme => ({
    actions: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(1, 1.5),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1, 3, 2, 3),
    },
    inner: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
    },
    popOver: {
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        maxHeight: `calc(100vh - ${theme.navigationMobileHeight}px)`,
      },
    },
  }),
  { index: 1 }
);

export interface VkwOverlayProps {
  /**
   * Inhalt des Overlays
   */
  children: ReactNode;
  onClose?: () => void;
  /**
   * Hier kann das Ankerelement mitgegeben werden. Dies ist optional wenn die Komponente innerhalb des VkwNavigationContext aufgerufen wird. Dann wird der Content Bereich der Navigation als Ankerelement verwendet.
   */
  anchorEl?: HTMLElement;
  /**
   * Hier kann übergeben werden an welchen Punkt des Ankerelementes sich die Komponente ausrichten soll
   */
  anchorOrigin?: PopoverOrigin;
  open?: boolean;
  /**
   * Werden `actions` übergeben (VkwIconButton) erscheinen diese in der linken oberen Ecke
   */
  actions?: ReactNode;
}

export const VkwOverlay = ({
  actions,
  anchorEl,
  anchorOrigin = {
    horizontal: 'left',
    vertical: 'top',
  },
  children,
  onClose,
  open = false,
}: VkwOverlayProps): ReactElement => {
  const navigation = useOptionalVkwNavigation();
  const styles = useStyles();

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl ?? navigation?.mainElement}
      anchorOrigin={anchorOrigin}
      className={navigation ? styles.popOver : undefined}
    >
      <div className={styles.inner}>
        <div className={styles.actions}>
          {actions}
          <VkwIconButton type="button" onClick={onClose}>
            <VkwIconClose size={16} />
          </VkwIconButton>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </Popover>
  );
};
