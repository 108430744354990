import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconCards = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill}>
        <rect height="17" width="14" fill={fill} rx="1" ry="1" />
        <path d="M22.859,7.168,16.3,5.393l-.522,1.931,5.6,1.514L17.979,21.386,6.395,18.255l-.521,1.931,12.55,3.393a1,1,0,0,0,1.226-.705L23.563,8.394A1,1,0,0,0,22.859,7.168Z" />
      </g>
    </VkwIcon>
  );
};
