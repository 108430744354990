import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconTrash = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M4,21.3333333 C4,22.8 5.2,24 6.66666667,24 L17.3333333,24 C18.8,24 20,22.8 20,21.3333333 L20,5.33333333 L4,5.33333333 L4,21.3333333 Z M6.66666667,8 L17.3333333,8 L17.3333333,21.3333333 L6.66666667,21.3333333 L6.66666667,8 Z M16.6666667,1.33333333 L15.3333333,0 L8.66666667,0 L7.33333333,1.33333333 L3.00156115,1.33333333 L3.00156115,4 L21.0024425,4 L21.0024425,1.33333333 L16.6666667,1.33333333 Z"
            fill={fill}
            fillRule="nonzero"
          />
        </g>
      </g>
    </VkwIcon>
  );
};
