import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconFavorite = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill}>
        <path
          fill={fill}
          d="M12.746 1.464l3.11 6.3 6.954 1.012c.313.045.574.264.672.566.098.3.016.631-.211.852l-5.033 4.9 1.188 6.926c.053.312-.075.627-.331.814-.256.186-.596.21-.876.063L12 19.632 5.78 22.9c-.28.147-.62.122-.876-.065-.256-.186-.384-.501-.331-.813L5.761 15.1.728 10.2c-.227-.22-.309-.551-.21-.852.097-.302.358-.52.671-.566l6.954-1.017 3.11-6.3c.14-.284.43-.464.746-.464.317 0 .606.18.747.463z"
        />
      </g>
    </VkwIcon>
  );
};
