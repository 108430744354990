import React, { ReactElement } from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { InfrastructureChargeStationIcon } from './InfrastructureChargeStationIcon';
import { InfrastructureChargeStation } from '../../hooks';
import { VkwTheme, getVkwTypographyStyle } from '../../library';

const useStyles = makeStyles<VkwTheme>(theme => ({
  address: {
    ...getVkwTypographyStyle('text14', theme),
  },
  stationName: {
    ...getVkwTypographyStyle('h9', theme),
  },
}));

interface InfrastructureChargeStationMobileItemProps {
  infrastructureChargeStation: InfrastructureChargeStation;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const InfrastructureChargeStationMobileItem = ({
  infrastructureChargeStation,
  onClick,
}: InfrastructureChargeStationMobileItemProps): ReactElement => {
  const styles = useStyles();

  return (
    <Box display="flex" alignItems="center" paddingY={2} onClick={onClick} role="button">
      <InfrastructureChargeStationIcon infrastructureChargeStation={infrastructureChargeStation} />
      <Box paddingX={2} flexGrow={1}>
        <div className={styles.stationName}>{infrastructureChargeStation.stationName}</div>
        <div className={styles.address}>{infrastructureChargeStation.address}</div>
      </Box>
    </Box>
  );
};
