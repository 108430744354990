import React, { ReactElement, ReactNode, useState } from 'react';

import { Grid, Hidden, Box } from '@material-ui/core';

import { toFirstUppercase } from '../../helper/converter';
import { useVkwFormatMessage } from '../../hooks';
import { VkwCallToAction } from '../VkwCallToAction';
import { VkwDrawer } from '../VkwDrawer';
import { VkwMultiSelectFilter, VkwDateBetweenFilter, VkwNumberBetweenFilter } from '../VkwFilter';

const convertSecsToMins = (seconds: number): number => {
  return seconds / 60;
};

const convertMinsToSecs = (seconds: number): number => {
  return seconds * 60;
};

const convertDurationMin = (seconds: number): number => {
  return Math.floor(convertSecsToMins(seconds));
};

const convertDurationMax = (seconds: number): number => {
  return Math.ceil(convertSecsToMins(seconds));
};

export interface VkwFilterOption {
  column: string;
  type: 'MultiSelect' | 'IntegerBetween' | 'DecimalBetween' | 'DurationBetween' | 'DateTimeOffsetBetween';
  translationKey: string;
  hidden: boolean;
  configuration: {
    values?: string[];
    min?: number | Date;
    max?: number | Date;
    unit?: string;
    translationRequired?: boolean;
  };
}

export interface VkwFiltersProps {
  /**
   * Aktive Filter
   */
  filters: Map<string, string[] | number[] | Date[]>;
  /**
   * Welche Filtermöglichkeiten gibt es
   */
  filterOptions: VkwFilterOption[];
  /**
   * Wenn Änderungen an Filtern vorgenommen werden, wird diese Methode aufgerufen
   */
  onChangeFilter: (name: string, value: string[] | number[] | Date[]) => void;
  /**
   * Methode zum ablöschen aller Filter
   */
  onClearFilters: () => void;
  /**
   * Wieviele verschiedene Filter werden von Anfang an angezeigt
   */
  defaultFiltersCount: number;
  /**
   * Soll der Drawer für die mobilen Filter angezeigt werden?
   */
  showMobileFilter: boolean;
  /**
   * Event Handler wenn der Drawer für die mobilen Filter geschlossen wird
   */
  onMobileFilterClose: () => void;
  /**
   * Ermöglicht auch versteckte Filter anzuzeigen
   */
  showHiddenFilters?: boolean;
}

export const VkwFilters = ({
  defaultFiltersCount,
  filterOptions,
  filters,
  onChangeFilter,
  onClearFilters,
  onMobileFilterClose,
  showHiddenFilters,
  showMobileFilter,
}: VkwFiltersProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();

  const actualFilterOptions = showHiddenFilters
    ? filterOptions
    : filterOptions.filter(filterOption => !filterOption.hidden);

  const actualFilterColumns = actualFilterOptions.map(filterOption => filterOption.column);
  const filtersActive = Array.from(filters.keys()).some(filterKey => actualFilterColumns.includes(filterKey));

  const [showMoreFilters, setShowMoreFilters] = useState(filtersActive);

  const parsedFilterOptions =
    !showMoreFilters && actualFilterOptions.length > defaultFiltersCount
      ? actualFilterOptions.slice(0, defaultFiltersCount)
      : actualFilterOptions;

  const renderFilter = (
    filterOption: VkwFilterOption,
    onFilterChange: (name: string, value: string[] | number[] | Date[]) => void,
    mode: 'desktop' | 'mobile'
  ): ReactNode => {
    switch (filterOption.type) {
      case 'MultiSelect': {
        let values = filters.get(filterOption.column) as string[];
        if (!values) {
          values = [];
        }

        const getTitle = (option: string): string => {
          if (filterOption.configuration.translationRequired === true) {
            return formatMessage(`${filterOption.translationKey}${toFirstUppercase(option)}`);
          }

          return option;
        };

        const processedValues: string[] = [];
        const multiSelectFilterOptions = (filterOption.configuration.values as string[]).map(option => {
          processedValues.push(option);
          return {
            title: getTitle(option),
            value: option,
          };
        });

        values.forEach(value => {
          if (!processedValues.includes(value)) {
            // add not existing option
            multiSelectFilterOptions.unshift({
              title: getTitle(value),
              value,
            });
          }
        });

        return (
          <VkwMultiSelectFilter
            key={filterOption.column}
            title={formatMessage(filterOption.translationKey)}
            searchPlaceholder={formatMessage('Search')}
            options={multiSelectFilterOptions}
            values={values}
            onChange={values => onFilterChange(filterOption.column, values)}
            mode={mode}
          />
        );
      }
      case 'IntegerBetween':
      case 'DecimalBetween': {
        const min = filterOption.configuration.min ? (filterOption.configuration.min as number) : 0;
        const max = filterOption.configuration.max ? (filterOption.configuration.max as number) : 0;
        const values = filters.get(filterOption.column) as number[];
        const { unit } = filterOption.configuration;

        return (
          <VkwNumberBetweenFilter
            key={filterOption.column}
            title={formatMessage(filterOption.translationKey)}
            values={values ?? []}
            headline={unit ? formatMessage('ValueIn', { unit }) : undefined}
            onChange={values => onFilterChange(filterOption.column, values)}
            min={min}
            max={max}
            mode={mode}
          />
        );
      }
      case 'DurationBetween': {
        const min = filterOption.configuration.min ? (filterOption.configuration.min as number) : 0;
        const max = filterOption.configuration.max ? (filterOption.configuration.max as number) : 0;
        const values = filters.get(filterOption.column) as number[];

        return (
          <VkwNumberBetweenFilter
            key={filterOption.column}
            title={formatMessage(filterOption.translationKey)}
            values={values ? [convertDurationMin(values[0]), convertDurationMax(values[1])] : []}
            headline={formatMessage('ValueIn', { unit: 'min' })}
            onChange={values => onFilterChange(filterOption.column, values.map(convertMinsToSecs))}
            min={convertDurationMin(min)}
            max={convertDurationMax(max)}
            mode={mode}
          />
        );
      }
      // TODO: filterOption.type 'DateTimeOffsetBetween' not correct for this Filter but this change needs a change in the backend
      case 'DateTimeOffsetBetween': {
        const min = filterOption.configuration.min ? (filterOption.configuration.min as Date) : new Date();
        const max = filterOption.configuration.max ? (filterOption.configuration.max as Date) : new Date();
        const values = filters.get(filterOption.column) as Date[];

        return (
          <VkwDateBetweenFilter
            key={filterOption.column}
            title={formatMessage(filterOption.translationKey)}
            values={values ?? []}
            onChange={values => onFilterChange(filterOption.column, values)}
            min={min}
            max={max}
            mode={mode}
          />
        );
      }
      // TODO: When filterOption.type is fixed add VkwDateTimeBetweenFilter
      default:
        throw new Error(`Unsupported filter "${filterOption.type}" given`);
    }
  };

  const renderDesktopFilter = (): ReactNode => (
    <Grid container spacing={1} alignItems="center">
      {parsedFilterOptions.map(filterOption => (
        <Grid key={filterOption.column} item>
          {renderFilter(filterOption, onChangeFilter, 'desktop')}
        </Grid>
      ))}
      {!showMoreFilters && actualFilterOptions.length > defaultFiltersCount && (
        <Grid item>
          <VkwCallToAction
            title={formatMessage('ShowMoreFilters')}
            iconPosition="none"
            onClick={() => setShowMoreFilters(true)}
          />
        </Grid>
      )}
      {filtersActive && (
        <Grid item>
          <VkwCallToAction title={formatMessage('ResetAllFilters')} iconPosition="none" onClick={onClearFilters} />
        </Grid>
      )}
    </Grid>
  );

  const renderMobileFilter = (): ReactNode => {
    const handleMobileFilterChange = (name: string, value: string[] | number[] | Date[]): void => {
      onChangeFilter(name, value);
      onMobileFilterClose();
    };

    const handleMobileClearFilter = (): void => {
      onClearFilters();
      onMobileFilterClose();
    };

    return (
      <VkwDrawer open={showMobileFilter} headerText={formatMessage('Filter')} onClose={onMobileFilterClose}>
        {actualFilterOptions.map(filterOption => renderFilter(filterOption, handleMobileFilterChange, 'mobile'))}
        {filtersActive && (
          <Box paddingY={3}>
            <VkwCallToAction
              title={formatMessage('ResetAllFilters')}
              iconPosition="none"
              onClick={handleMobileClearFilter}
            />
          </Box>
        )}
      </VkwDrawer>
    );
  };

  return (
    <>
      <Hidden implementation="css" xsDown>
        {renderDesktopFilter()}
      </Hidden>
      <Hidden implementation="css" smUp>
        {renderMobileFilter()}
      </Hidden>
    </>
  );
};
