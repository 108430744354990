import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconTwitterBlock = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';
  const secondaryfill = fill;
  const strokewidth = 1;

  return (
    <VkwIcon size={size}>
      <g fill={secondaryfill} stroke={secondaryfill} strokeLinecap="square" strokeWidth={strokewidth}>
        <path d="M21 0c1.656 0 3 1.344 3 3v18c0 1.656-1.344 3-3 3H3c-1.656 0-3-1.344-3-3V3c0-1.656 1.344-3 3-3h18zm-6.114 5.904a3.08 3.08 0 0 0-3 3.78 8.737 8.737 0 0 1-6.342-3.216 3.085 3.085 0 0 0 .954 4.11 3.112 3.112 0 0 1-1.392-.384v.042a3.083 3.083 0 0 0 2.466 3.018 3 3 0 0 1-.81.108c-.198 0-.39-.018-.576-.054a3.077 3.077 0 0 0 2.874 2.136 6.17 6.17 0 0 1-3.822 1.32 5.92 5.92 0 0 1-.732-.042 8.752 8.752 0 0 0 4.71 1.374c5.658 0 8.754-4.692 8.754-8.754 0-.132 0-.264-.006-.396A6.268 6.268 0 0 0 19.5 7.35a6.236 6.236 0 0 1-1.77.486 3.095 3.095 0 0 0 1.356-1.704 6.12 6.12 0 0 1-1.956.744 3.07 3.07 0 0 0-2.244-.972z" />
      </g>
    </VkwIcon>
  );
};
