import React, { ReactElement, ReactNode } from 'react';

import { makeStyles, Tooltip } from '@material-ui/core';

import { Plug } from '@e-vo/types';

import { toPascalCase, useVkwFormatMessage, VkwTheme } from '../../library';

const useStyles = makeStyles<VkwTheme>({
  tooltipWrapper: {
    display: 'inline-block',
  },
});

interface PlugTooltipProps {
  plug: Plug;
  children: ReactNode;
}

export const PlugTooltip = ({ children, plug }: PlugTooltipProps): ReactElement => {
  const formatMessage = useVkwFormatMessage();
  const title = formatMessage(`Plug${toPascalCase(plug as string)}`);
  const styles = useStyles();
  return (
    <Tooltip title={title} arrow placement="bottom">
      <div className={styles.tooltipWrapper}>{children}</div>
    </Tooltip>
  );
};
