import { IncomingMessage, ServerResponse } from 'http';

import { CookieSerializeOptions, serialize, parse } from 'cookie';

export interface OptionsType extends CookieSerializeOptions {
  res?: ServerResponse;
  req?: IncomingMessage & {
    cookies?: { [key: string]: string } | Partial<{ [key: string]: string }>;
  };
}

export type TmpCookiesObj = { [key: string]: string } | Partial<{ [key: string]: string }>;
export type CookieValueTypes = string | boolean | undefined | null;

const filterCookiePrefix = '__Filter-';

const isClientSide = (): boolean => typeof window !== 'undefined';

const processValue = (value: string): CookieValueTypes => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  if (value === 'undefined') {
    return undefined;
  }
  if (value === 'null') {
    return null;
  }
  return value;
};

const stringify = (value = '') => {
  try {
    const result = JSON.stringify(value);
    return /^[{[]/.test(result) ? result : value;
  } catch (e) {
    return value;
  }
};

const decode = (str: string): string => {
  if (!str) {
    return str;
  }

  return str.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
};

export const getCookies = (options?: OptionsType): TmpCookiesObj => {
  let req;
  if (options) {
    req = options.req;
  }
  if (!isClientSide()) {
    // if cookie-parser is used in project get cookies from ctx.req.cookies
    // if cookie-parser isn't used in project get cookies from ctx.req.headers.cookie
    if (req && req.cookies) {
      return req.cookies;
    }
    if (req && req.headers && req.headers.cookie) {
      return parse(req.headers.cookie);
    }

    return {};
  }

  const cookies: TmpCookiesObj = {};
  const documentCookies = document.cookie ? document.cookie.split('; ') : [];

  for (let i = 0, len = documentCookies.length; i < len; i++) {
    const cookieParts = documentCookies[i].split('=');

    const cookie = cookieParts.slice(1).join('=');
    const name = cookieParts[0];

    cookies[name] = cookie;
  }

  return cookies;
};

export const getCookie = (key: string, options?: OptionsType): CookieValueTypes => {
  const cookies = getCookies(options);
  const value = cookies[key];

  if (value === undefined) {
    return undefined;
  }

  return processValue(decode(value));
};

export const setCookie = (key: string, data: any, options?: OptionsType): void => {
  let cookieOptions: any;
  let req;
  let res;

  if (options) {
    const { req: optionReq, res: optionRes, ...argOptions } = options;
    req = optionReq;
    res = optionRes;
    cookieOptions = argOptions;
  }

  const cookieStr = serialize(key, stringify(data), { path: '/', ...cookieOptions });

  if (!isClientSide()) {
    if (res && req) {
      let currentCookies = res.getHeader('Set-Cookie');

      if (!Array.isArray(currentCookies)) {
        currentCookies = !currentCookies ? [] : [String(currentCookies)];
      }
      res.setHeader('Set-Cookie', currentCookies.concat(cookieStr));

      if (req && req.cookies) {
        const { cookies } = req;

        if (data === '') {
          delete cookies[key];
        } else {
          cookies[key] = stringify(data);
        }
      }

      if (req && req.headers && req.headers.cookie) {
        const cookies = parse(req.headers.cookie);

        if (data === '') {
          delete cookies[key];
        } else {
          cookies[key] = stringify(data);
        }

        req.headers.cookie = Object.entries(cookies).reduce((accum, item) => {
          return accum.concat(`${item[0]}=${item[1]};`);
        }, '');
      }
    }
  } else {
    document.cookie = cookieStr;
  }
};

export const deleteCookie = (key: string, options?: OptionsType): void => {
  return setCookie(key, '', { ...options, maxAge: -1 });
};

export const hasCookie = (key: string, options?: OptionsType): boolean => {
  if (!key) {
    return false;
  }

  const cookie = getCookies(options);

  return Object.prototype.hasOwnProperty.call(cookie, key);
};

export const getFilterCookie = (key: string, options?: OptionsType): CookieValueTypes => {
  return getCookie(filterCookiePrefix + key, options);
};

export const setFilterCookie = (key: string, data: any, options?: OptionsType): void => {
  setCookie(filterCookiePrefix + key, data, options);
};

export const deleteFilterCookies = (options?: OptionsType): void => {
  const cookieKeys = Object.keys(getCookies());

  cookieKeys.forEach(key => {
    if (key.startsWith(filterCookiePrefix)) {
      deleteCookie(key, options);
    }
  });
};

export const deleteFilterCookie = (key: string, options?: OptionsType): void => {
  return setCookie(filterCookiePrefix + key, '', { ...options, maxAge: -1 });
};

export const hasFilterCookie = (key: string, options?: OptionsType): boolean => {
  if (!key) {
    return false;
  }

  return hasCookie(filterCookiePrefix + key, options);
};
