import { useEffect } from 'react';

import { useUserSettingsContext } from '../contexts';
import { VkwSortableListItemConfig, VkwDataTableSettingsProvider } from '../library';

export const useSortableListSettings = (settingsKey: string): VkwDataTableSettingsProvider => {
  const userSettingsContext = useUserSettingsContext();

  useEffect(() => {
    userSettingsContext.loadSortableListSettings(settingsKey);
  }, [settingsKey]);

  return {
    settings: userSettingsContext.sortableListSettings.get(settingsKey) ?? [],
    updateSettings: (settings: VkwSortableListItemConfig[]) =>
      userSettingsContext.updateSortableListSettings(settingsKey, settings),
  };
};
