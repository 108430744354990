import React, { ReactElement } from 'react';

import { VkwIcon, VkwIconProps } from './VkwIcon';

export const VkwIconPlugTyp2 = ({ size }: VkwIconProps): ReactElement => {
  const fill = 'currentColor';

  return (
    <VkwIcon size={size}>
      <g fill={fill}>
        <path
          d="M24 11c0 6.623-5.37 12-12 12S0 17.622 0 11C0 6.826 2.13 3.15 5.363 1h13.274A11.99 11.99 0 0 1 24 11zM8.5 15a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm7.5 0a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4.5 8a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM12 8a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm7.5 0a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-11-5a2 2 0 1 0-.001 3.999A2 2 0 0 0 8.5 3zm7 0a2 2 0 1 0-.001 3.999A2 2 0 0 0 15.5 3z"
          fill={fill}
          fillRule="evenodd"
        />
      </g>
    </VkwIcon>
  );
};
