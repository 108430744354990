import React, { ReactElement } from 'react';

import { useRouter } from 'next/router';

import { ContractTile } from '../../components';
import { InfrastructureContract } from '../../contexts';
import { useBaseDataStore } from '../../hooks';
import { useVkwFormatMessage, VkwDataGrid } from '../../library';

export const Contracts = (): ReactElement => {
  const router = useRouter();
  const formatMessage = useVkwFormatMessage();

  const store = useBaseDataStore<InfrastructureContract>({
    defaultSortColumn: 'contractValidTo',
    pageSize: 12,
    url: '/api/v1/InfrastructureContracts',
  });

  return (
    <VkwDataGrid
      initialized={store.initialized}
      loading={store.loading}
      loadAdditionalEntries={store.loadAdditionalEntries}
      page={store.page}
      pages={store.pages}
      data={store.data}
      totalRecords={store.totalRecords}
      hasMore={store.hasMore}
      setPage={store.setPage}
      setIsMobile={store.setIsMobile}
      noResultsDisplayText=""
      noResultsWithActiveFilterDisplayText={formatMessage('NoResultsWithActiveFilter')}
      renderElement={(dataEntry: InfrastructureContract) => {
        const { chargePoints, contractId, contractValidTo, id, infrastructureContractAlias } = dataEntry;

        return (
          <ContractTile
            contractAlias={infrastructureContractAlias}
            contractId={contractId}
            chargePointsCount={chargePoints?.length ?? null}
            validTo={contractValidTo}
            onClick={() => router.push(`/contracts/[id]`, `/contracts/${id}`)}
          />
        );
      }}
      filters={store.filters}
      filterOptions={store.filterOptions}
      onChangeFilter={store.changeFilter}
      onClearFilters={store.clearFilters}
    />
  );
};
