import React, { ReactElement, ReactNode, useEffect, useState, useCallback } from 'react';

import { Grid } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { DateTimeToolbar } from './DateTimeToolbar';
import useStyles from './VkwDateTimeBetweenFilterStyles';
import { VkwDateIoConfigType, vkwDateIo } from '../../../helper/VkwDateIo';
import { useVkwFormatMessage } from '../../../hooks';
import { VkwBaseFilter } from '../../VkwBaseFilter';

const startPickerDialogId = 'DateStartPickerDialog';
const endPickerDialogId = 'DateEndPickerDialog';

export interface VkwDateTimeBetweenFilterProps {
  values: Date[];
  title: string;
  onChange: (value: Date[]) => void;
  min: Date;
  max: Date;
  mode: 'desktop' | 'mobile';
}

export const VkwDateTimeBetweenFilter = ({
  max,
  min,
  mode,
  onChange,
  title,
  values,
}: VkwDateTimeBetweenFilterProps): ReactElement => {
  const styles = useStyles();
  const formatMessage = useVkwFormatMessage();
  const [newValues, setNewValues] = useState(values);
  const currentMinValue = newValues[0] ?? min;
  const currentMaxValue = newValues[1] ?? max;
  const dateFormat = `DD.MM.YYYY - HH:mm`;

  const processAndSetNewValues = (values: Date[]) => {
    if (values.length > 0) {
      setNewValues(values[0].getTime() !== min.getTime() || values[1].getTime() !== max.getTime() ? values : []);
    }
  };

  useEffect(() => {
    setNewValues(values);
  }, [values]);

  const clampValue = (date: VkwDateIoConfigType): Date => {
    const newdate = vkwDateIo.date(date);

    if (newdate.isBefore(min)) {
      return min;
    }

    if (newdate.isAfter(max)) {
      return max;
    }

    return newdate.toDate();
  };

  const validateMinValue = (minValue: VkwDateIoConfigType): Date => {
    const newMinValue = vkwDateIo.date(minValue);

    if (!newMinValue.isValid()) {
      return min;
    }

    return clampValue(newMinValue);
  };

  const validateMaxValue = (maxValue: VkwDateIoConfigType): Date => {
    const newMaxValue = vkwDateIo.date(maxValue);

    if (!newMaxValue.isValid) {
      return max;
    }

    return clampValue(newMaxValue);
  };

  const handleMinValueChange = (minValue: MaterialUiPickersDate): void => {
    processAndSetNewValues([minValue ? vkwDateIo.date(minValue).toDate() : vkwDateIo.date().toDate(), currentMaxValue]);
  };

  const handleMaxValueChange = (maxValue: MaterialUiPickersDate): void => {
    processAndSetNewValues([currentMinValue, maxValue ? vkwDateIo.date(maxValue).toDate() : vkwDateIo.date().toDate()]);
  };

  const handleFilterChange = (): void => {
    let validatedValues = newValues;
    if (newValues.length > 0) {
      validatedValues = [validateMinValue(newValues[0]), validateMaxValue(newValues[1])];
      if (validatedValues[0].getTime() > validatedValues[1].getTime()) {
        [validatedValues[0], validatedValues[1]] = [validatedValues[1], validatedValues[0]];
      }
      setNewValues(validatedValues);
    }
    onChange(validatedValues);
  };

  const handleFilterReset = (): void => {
    setNewValues([]);
  };

  const renderStartDateTimeToolbar = useCallback(
    (props: ToolbarComponentProps) => <DateTimeToolbar pickerDialogId={startPickerDialogId} toolbarProps={props} />,
    []
  );

  const renderEndDateTimeToolbar = useCallback(
    (props: ToolbarComponentProps) => <DateTimeToolbar pickerDialogId={endPickerDialogId} toolbarProps={props} />,
    []
  );

  const renderMenu = (): ReactNode => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <KeyboardDatePicker
            DialogProps={{
              PaperProps: {
                classes: {
                  root: styles.pickerPaperRoot,
                },
              },
              className: styles.datePicker,
              id: startPickerDialogId,
            }}
            variant="dialog"
            onChange={handleMinValueChange}
            value={currentMinValue}
            minDate={min}
            maxDate={max}
            placeholder={dateFormat}
            format={dateFormat}
            fullWidth
            invalidDateMessage={formatMessage('InvalidDate')}
            inputVariant="filled"
            label={formatMessage('DateFilterStart')}
            maxDateMessage={formatMessage('DateTimeFilterOverMax', {
              MaxDate: vkwDateIo.date(max).format(dateFormat),
            })}
            minDateMessage={formatMessage('DateTimeFilterUnderMin', {
              MinDate: vkwDateIo.date(min).format(dateFormat),
            })}
            InputProps={{
              classes: { focused: styles.inputFocused, input: styles.inputInput, root: styles.inputRoot },
            }}
            InputLabelProps={{ classes: { focused: styles.labelFocused, root: styles.labelRoot } }}
            ToolbarComponent={renderStartDateTimeToolbar}
            strictCompareDates
            cancelLabel={formatMessage('Cancel')}
            okLabel={formatMessage('Ok')}
          />
        </Grid>
        <Grid item xs={12}>
          <KeyboardDatePicker
            DialogProps={{
              PaperProps: {
                classes: {
                  root: styles.pickerPaperRoot,
                },
              },
              className: styles.datePicker,
              id: endPickerDialogId,
            }}
            variant="dialog"
            onChange={handleMaxValueChange}
            value={currentMaxValue}
            minDate={min}
            maxDate={max}
            placeholder={dateFormat}
            format={dateFormat}
            fullWidth
            invalidDateMessage={formatMessage('InvalidDate')}
            inputVariant="filled"
            label={formatMessage('DateFilterEnd')}
            maxDateMessage={formatMessage('DateTimeFilterOverMax', {
              MaxDate: vkwDateIo.date(max).format(dateFormat),
            })}
            minDateMessage={formatMessage('DateTimeFilterUnderMin', {
              MinDate: vkwDateIo.date(min).format(dateFormat),
            })}
            InputProps={{
              classes: { focused: styles.inputFocused, input: styles.inputInput, root: styles.inputRoot },
            }}
            InputLabelProps={{ classes: { focused: styles.labelFocused, root: styles.labelRoot } }}
            ToolbarComponent={renderEndDateTimeToolbar}
            strictCompareDates
            cancelLabel={formatMessage('Cancel')}
            okLabel={formatMessage('Ok')}
          />
        </Grid>
      </Grid>
    );
  };
  const disableReset = currentMinValue.getTime() === min.getTime() && currentMaxValue.getTime() === max.getTime();
  const disableSave =
    !vkwDateIo.date(newValues[0]).isValid() ||
    !vkwDateIo.date(newValues[1]).isValid() ||
    newValues[0] < min ||
    newValues[0] > max ||
    newValues[1] < min ||
    newValues[1] > max;

  return (
    <VkwBaseFilter
      onSave={!disableSave ? handleFilterChange : undefined}
      active={values.length > 0}
      onReset={!disableReset ? handleFilterReset : undefined}
      onClose={() => {
        setNewValues(values);
      }}
      title={title}
      renderMenu={renderMenu}
      mode={mode}
    />
  );
};
